import React, { useEffect, useState, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import Card from 'components/inputs/Card';
import Heatmaptest from 'view/dashboard/Heatmaptest';
import PropTypes from 'prop-types';
import Button from 'components/inputs/Button';
import DateTimePicker from 'components/inputs/DateTimePicker';
import moment from 'moment';
import OffCanvas from 'components/inputs/OffCanvas';
import { Form, Formik } from 'formik';
import * as _ from 'lodash';
import { MdClear } from 'react-icons/md';
import Select from 'components/inputs/Select';
import { FETCH_CHARGING_STATION } from 'actions/chargingStation';
import '../dashboard/Chartstyle.css';
import { useTranslation } from 'react-i18next';
import { FaFilter } from 'react-icons/fa';
import Footer from 'components/general/Footer';
import { GET_HEATMAP_REPORT } from 'components/common/constant';
function PcsUtilizationReport() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showSearchBar] = useState(false);
  const [changeStation, setChangeStation] = useState(false);
  const [advanceFilterData, setAdvanceFilterData] = useState({});
  const [showAdvanceFilterPopup, setShowAdvanceFilterPopup] = useState(false);
  const [startDate, setStartDate] = useState(moment().subtract(1, 'months').startOf('month'));
  const [endDate, setEndDate] = useState(moment().subtract(1, 'months').endOf('month'));
  const [selectedStation, setSelectedStation] = useState([]);
  const rolePermissionData = useSelector((state) => state.manageRole.manageRoles);
  const allStation = useSelector((state) => state.chargingStation.chargingStations);
  const profileData = useSelector((state) => state.profile.userProfile);

  const userProfileStations = profileData.charging_stations;
  const currentTenantId = profileData.tenant.id;

  const currentStation = profileData.charging_stations;
  const currentUserName = _.get(profileData, 'name', '');

  const onCloseAdvanceFilterPopup = () => setShowAdvanceFilterPopup(false);
  const handleAdvanceFilterData = (data) => setAdvanceFilterData(data);
  const initialValues = !_.isEmpty(advanceFilterData) ? { ...advanceFilterData } : { charging_station: '', type: '' };
  const stationList = allStation.filter((x) => userProfileStations.indexOf(x.id) !== -1);

  const HeatmapPermission =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === GET_HEATMAP_REPORT),
      (data) => data.name
    );

  const handleSelectStation = (val) => {
    setSelectedStation(val);
  };

  const getAllStation = useCallback((data = {}) => {
    const stationData = {
      ...data,
      request_all: true,
    };
    dispatch({ type: FETCH_CHARGING_STATION, payload: stationData });
  }, []);

  useEffect(() => {
    getAllStation();
  }, []);

  return (
    <>
      {showAdvanceFilterPopup && (
        <OffCanvas show={showAdvanceFilterPopup} onClose={onCloseAdvanceFilterPopup}>
          <Formik
            enableReinitialize={!_.isEmpty(advanceFilterData)}
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
              !values.charging_station && delete values['charging_station'];
              handleAdvanceFilterData(values);
              setSubmitting(false);
              handleSelectStation(values.charging_station);
              onCloseAdvanceFilterPopup();
            }}
          >
            {({ values, handleSubmit, setFieldValue, resetForm }) => (
              <Form onSubmit={handleSubmit}>
                <div className="mb-5">
                  <Select
                    label={t('filters.station')}
                    options={
                      !_.isEmpty(stationList)
                        ? !changeStation
                          ? !_.isEmpty(stationList) && [
                              ..._.map(stationList, (station) => {
                                return { label: station.name, value: station.id };
                              }),
                            ]
                          : []
                        : !changeStation
                        ? !_.isEmpty(allStation) && [
                            ..._.map(allStation, (station) => {
                              return { label: station.name, value: station.id };
                            }),
                          ]
                        : []
                    }
                    placeholder={t('placeHolder.selectStation')}
                    name="charging_station"
                    isMulti
                    value={values.charging_station}
                    onMenuScrollDown={true}
                    onChange={(val) => {
                      if (_.includes(val)) {
                        setChangeStation(true);
                        setFieldValue(`charging_station`, '');
                      } else if (_.isEmpty(val)) {
                        setFieldValue(`charging_station`, '');
                        setChangeStation(false);
                      } else {
                        setChangeStation(false);
                        setFieldValue('charging_station', val);
                      }
                    }}
                  />
                </div>
                <div className="canvas-btn__block">
                  <Button type="submit" className="apply--btn w-100">
                    {t('button.applyFilters')}
                  </Button>
                  <div
                    className="reset--block"
                    onClick={() => {
                      setAdvanceFilterData({});
                      setSelectedStation([]);
                      resetForm();
                      onCloseAdvanceFilterPopup();
                    }}
                  >
                    <MdClear size={24} className="reset--icon" />
                    {t('button.clear')}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </OffCanvas>
      )}
      <LayoutContainer>
        <AdminHeader title={t('header.pcsUtilization')} />
        <div className="page-content-wrapper scrollable">
          <div className="data-report--main">
            <Card>
              <div className="data-report__inner">
                <div className="data-report__box">
                  <Row className="data-report__row">
                    <Col xl={`${showSearchBar ? 10 : 10}`} md={`${showSearchBar ? 10 : 10}`}>
                      <div className="data-report-date_picker">
                        <DateTimePicker
                          id="my-datepicker"
                          onChangeOfStartDate={(date) => setStartDate(moment(date))}
                          onChangeOfEndDate={(date) => setEndDate(moment(date))}
                          initialValueOfStartDate={startDate}
                          initialValueOfEndDate={endDate}
                          inputProps={{ disabled: true }}
                        />
                      </div>
                    </Col>

                    <Col xl={2} md={3}>
                      <div className="report-search-excel-block">
                        <div className="advance-filter-btn" onClick={() => setShowAdvanceFilterPopup(true)}>
                          <Button className="report-search-button">
                            <FaFilter className="hide-lap" />
                            <span>{t('button.advancedFilter')}</span>
                          </Button>
                        </div>
                      </div>
                    </Col>
                    <div className="data-report__inner">
                      <div className="data-report__box">
                        <Row className="data-report__row">
                          <Heatmaptest
                            rolePermission={HeatmapPermission}
                            currentTenantId={currentTenantId}
                            currentStation={currentStation}
                            selectedStation={selectedStation}
                          />
                        </Row>
                      </div>
                    </div>
                  </Row>
                </div>
              </div>
            </Card>
          </div>
          {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
        </div>
      </LayoutContainer>
    </>
  );
}
PcsUtilizationReport.propTypes = {
  rolePermission: PropTypes.array,
  currentTenantId: PropTypes.string,
  currentStation: PropTypes.array,
  onSelectStation: PropTypes.array,
};

export default PcsUtilizationReport;
