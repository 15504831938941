import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header';
import DateTimePicker from 'components/inputs/DateTimePicker';
import React, { useCallback, useEffect, useState } from 'react';
import Card from 'components/inputs/Card';
import { FETCH_CUSTOMER_TRANSACTION, DOWNLOAD_CUSTOMER_TRANSACTION } from 'actions/customer';
import { useDispatch, useSelector } from 'react-redux';
import * as _ from 'lodash';
import ReactPagination from 'components/general/ReactPagination';
import { BiSort } from 'react-icons/bi';
import { roundOfDigit } from 'components/common/utils';
import moment from 'moment';
import { Col, Row } from 'react-bootstrap';
import { BsDownload } from 'react-icons/bs';
import fileDownload from 'js-file-download';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
const TransactionReport = () => {
  const dispatch = useDispatch();
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const { t } = useTranslation();
  const customerTransactionList = useSelector((state) => state.customerTransaction.customerTransaction);
  const page = useSelector((state) => state.customerTransaction.page);
  const totalData = useSelector((state) => state.customerTransaction.total);
  const limit = useSelector((state) => state.customerTransaction.limit);
  const totalPages = useSelector((state) => state.customerTransaction.totalPages);
  const isLoading = useSelector((state) => state.downloadingLoader.isLoading);
  const profileData = useSelector((state) => state.profile.userProfile);
  const userProfileId = profileData.id;

  const [startDate, setStartDate] = useState(moment().startOf('month'));
  const [endDate, setEndDate] = useState(moment(new Date()).endOf('day'));

  const getCustomerTransaction = useCallback(
    (data) => {
      const transactionData = {
        ...data,
        id: userProfileId,
        from: moment(startDate).utc().toDate(),
        to: moment(endDate).utc().toDate(),
      };
      dispatch({ type: FETCH_CUSTOMER_TRANSACTION, payload: transactionData });
    },
    [startDate, endDate, userProfileId]
  );

  useEffect(() => {
    userProfileId && getCustomerTransaction();
  }, [startDate, endDate, userProfileId]);

  const downloadCustomerTransactions = useCallback(
    (data = {}) => {
      const customerTransactionsData = {
        ...data,
        id: userProfileId,
        excel: true,
        from: moment(startDate).utc().toDate(),
        to: moment(endDate).utc().toDate(),
      };
      dispatch({
        type: DOWNLOAD_CUSTOMER_TRANSACTION,
        payload: customerTransactionsData,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              fileDownload(res.data, `${'Wallet Transactions Report'}.xlsx`);
            }
          }
        },
      });
    },
    [startDate, endDate, userProfileId]
  );

  const downloadFile = () => {
    downloadCustomerTransactions();
  };

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else if (startDate && endDate) {
        const data = {
          page: page.selected + 1,
          from: moment(startDate).utc().toDate(),
          to: moment(endDate).utc().toDate(),
        };
        getCustomerTransaction(data);
      } else {
        const data = {
          page: page.selected + 1,
        };
        getCustomerTransaction(data);
      }
    },
    [sortByItem, startDate, endDate]
  );

  const getSortByItem = useCallback(
    (name, order, page) => {
      const sortedOrder = order || 'asc';
      if (startDate && endDate && page) {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
          from: moment(startDate).utc().toDate(),
          to: moment(endDate).utc().toDate(),
        };
        endDate && getCustomerTransaction(data);
      } else if (page) {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
        };
        getCustomerTransaction(data);
      } else {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        };
        getCustomerTransaction(data);
      }
    },
    [startDate, endDate]
  );

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title={t('header.walletTransactionReport')} />
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
          <div className="page-content-wrapper scrollable">
            <div className="data-report--main">
              <Card>
                <div className="data-report__inner">
                  <div className="data-report__box">
                    <Row className="data-report__row">
                      <Col xl={'auto'} md={'auto'}>
                        <div className="data-report-date_picker">
                          <DateTimePicker
                            onChangeOfStartDate={(item) => setStartDate(item)}
                            onChangeOfEndDate={(item) => setEndDate(item)}
                            initialValueOfStartDate={moment().startOf('month')}
                            initialValueOfEndDate={moment(new Date()).endOf('day')}
                          />
                        </div>
                      </Col>
                      <Col xl={'auto'} lg={'auto'} md={'auto'} className="excel-icon--block">
                        {isLoading ? (
                          <div className="loader--block">
                            <Skeleton height={15} width={100} />
                          </div>
                        ) : (
                          <div className="report-excel-icon" onClick={downloadFile}>
                            <BsDownload title="Download" size={28} color={'#3c7cdd'} />
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                  <div className="data-report__table">
                    <div className="table-responsive">
                      <table className="record-list-table" id="table-to-xls">
                        <thead>
                          <tr>
                            <th>
                              <div className="sorting">
                                <span>{isLoading ? <Skeleton height={15} width={100} /> : t('dataReport.srNo')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoading ? <Skeleton height={15} width={100} /> : t('filters.paymentType')}</span>
                                <span className="ico" onClick={() => handleSorting('type', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoading ? <Skeleton height={15} width={100} /> : t('dataReport.reason')}</span>
                                <span className="ico" onClick={() => handleSorting('wallet_type', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoading ? <Skeleton height={15} width={100} /> : t('dataReport.bookingId')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoading ? <Skeleton height={15} width={100} /> : t('dataReport.previousBalance')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoading ? <Skeleton height={15} width={100} /> : t('dataReport.transactionAmount')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoading ? <Skeleton height={15} width={100} /> : t('dataReport.updatedBalance')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoading ? <Skeleton height={15} width={100} /> : t('dataReport.dateTime')}</span>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {_.isEmpty(customerTransactionList) ? (
                            <tr>
                              <td colSpan={10} className="border-0">
                                <div className="empty-data-block">{t('dataReport.noWalletTransactionData')}</div>
                              </td>
                            </tr>
                          ) : (
                            _.map(customerTransactionList, (item, index) => {
                              const serial_num = limit * (page - 1) + index;
                              return (
                                <tr key={`transaction-data-item-${serial_num}`}>
                                  <td>{isLoading ? <Skeleton height={15} width={100} /> : serial_num + 1}</td>
                                  <td>{isLoading ? <Skeleton height={15} width={100} /> : _.get(item, 'type', '-')}</td>
                                  <td>{isLoading ? <Skeleton height={15} width={100} /> : _.get(item, 'wallet_type', '-')}</td>
                                  <td>{isLoading ? <Skeleton height={15} width={100} /> : _.get(item, 'booking.id', '-')}</td>
                                  <td>
                                    {isLoading ? (
                                      <Skeleton height={15} width={100} />
                                    ) : _.get(item, 'current_balance', '') ? (
                                      roundOfDigit(_.get(item, 'current_balance', ''), 2)
                                    ) : (
                                      '-'
                                    )}
                                  </td>
                                  <td>
                                    {isLoading ? (
                                      <Skeleton height={15} width={100} />
                                    ) : _.get(item, 'amount', '') ? (
                                      roundOfDigit(_.get(item, 'amount', ''), 2)
                                    ) : (
                                      '-'
                                    )}
                                  </td>
                                  <td>
                                    {isLoading ? (
                                      <Skeleton height={15} width={100} />
                                    ) : _.get(item, 'type', '') === 'credit' ? (
                                      roundOfDigit(_.get(item, 'current_balance', 0) + _.get(item, 'amount', ''), 2)
                                    ) : (
                                      roundOfDigit(_.get(item, 'current_balance', 0) - _.get(item, 'amount', ''), 2)
                                    )}
                                  </td>
                                  <td>
                                    {isLoading ? (
                                      <Skeleton height={15} width={100} />
                                    ) : item.createdAt ? (
                                      moment(item.createdAt).utc(true).format('DD/MM/YYYY  H:mm:ss')
                                    ) : (
                                      '-'
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {!_.isEmpty(customerTransactionList) && (
                    <ReactPagination
                      currentPage={page}
                      limit={limit}
                      total={totalData}
                      handlePageClick={(pageVal) => handlePageClick(pageVal)}
                      totalPages={totalPages}
                      marginPagesDisplayed={1}
                    />
                  )}
                </div>
              </Card>
            </div>
          </div>
        </SkeletonTheme>
      </LayoutContainer>
    </React.Fragment>
  );
};
export default TransactionReport;
