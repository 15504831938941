export const FETCH_VEHICLE_MODEL = 'FETCH_VEHICLE_MODEL';
export const FETCH_VEHICLE_MODEL_SUCCESS = 'FETCH_VEHICLE_MODEL_SUCCESS';
export const FETCH_VEHICLE_MODEL_FAIL = 'FETCH_VEHICLE_MODEL_FAIL';

export const GET_VEHICLE_MODEL = 'GET_VEHICLE_MODEL_MAKE';
export const GET_VEHICLE_MODEL_SUCCESS = 'GET_VEHICLE_MODEL_SUCCESS';
export const GET_VEHICLE_MODEL_FAIL = 'GET_VEHICLE_MODEL_FAIL';

export const CREATE_VEHICLE_MODEL = 'CREATE_VEHICLE_MODEL';
export const CREATE_VEHICLE_MODEL_SUCCESS = 'CREATE_VEHICLE_MODEL_SUCCESS';
export const CREATE_VEHICLE_MODEL_FAIL = 'CREATE_VEHICLE_MODEL_FAIL';

export const UPDATE_VEHICLE_MODEL = 'UPDATE_VEHICLE_MODEL';
export const UPDATE_VEHICLE_MODEL_SUCCESS = 'UPDATE_VEHICLE_MODEL_SUCCESS';
export const UPDATE_VEHICLE_MODEL_FAIL = 'UPDATE_VEHICLE_MODEL_FAIL';

export const DELETE_VEHICLE_MODEL = 'DELETE_VEHICLE_MODEL';
export const DELETE_VEHICLE_MODEL_SUCCESS = 'DELETE_VEHICLE_MODEL_SUCCESS';
export const DELETE_VEHICLE_MODEL_FAIL = 'DELETE_VEHICLE_MODEL_FAIL';
