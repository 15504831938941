import { GET_CHARGER_LOG_GRAPH, GET_CHARGER_LOG_GRAPH_SUCCESS, GET_CHARGER_LOG_GRAPH_FAIL } from 'actions/chargerLogGraph';
const initialValues = {
  isLoading: true,
  graphDetail: '',
};

const chargerLogGraphReducer = (state = initialValues, { type, payload }) => {
  switch (type) {
    case GET_CHARGER_LOG_GRAPH: {
      return { ...state, isLoading: true };
    }

    case GET_CHARGER_LOG_GRAPH_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        graphDetail: payload,
      };
    }

    case GET_CHARGER_LOG_GRAPH_FAIL: {
      return { ...state, isLoading: false };
    }
    default:
      return { ...state };
  }
};

export default chargerLogGraphReducer;
