export const CREATE_USER_GROUP = 'CREATE_USER_GROUP';
export const CREATE_USER_GROUP_SUCCESS = 'CREATE_USER_GROUP_SUCCESS';
export const CREATE_USER_GROUP_FAIL = 'CREATE_USER_GROUP_FAIL';

export const FETCH_USER_GROUP = 'FETCH_USER_GROUP';
export const FETCH_USER_GROUP_SUCCESS = 'FETCH_USER_GROUP_SUCCESS';
export const FETCH_USER_GROUP_FAIL = 'FETCH_USER_GROUP_FAIL';

export const GET_USER_GROUP = 'GET_USER_GROUP';
export const GET_USER_GROUP_SUCCESS = 'GET_USER_GROUP_SUCCESS';
export const GET_USER_GROUP_FAIL = 'GET_USER_GROUP_FAIL';

export const DELETE_USER_GROUP = 'DELETE_USER_GROUP';
export const DELETE_USER_GROUP_SUCCESS = 'DELETE_USER_GROUP_SUCCESS';
export const DELETE_USER_GROUP_FAIL = 'DELETE_USER_GROUP_FAIL';

export const UPDATE_USER_GROUP = 'UPDATE_USER_GROUP';
export const UPDATE_USER_GROUP_SUCCESS = 'UPDATE_USER_GROUP_SUCCESS';
export const UPDATE_USER_GROUP_FAIL = 'UPDATE_USER_GROUP_FAIL';

export const ALL_USERS_IN_USER_GROUP = 'ALL_USERS_IN_USER_GROUP';
export const ALL_USERS_IN_USER_GROUP_SUCCESS = 'ALL_USERS_IN_USER_GROUP_SUCCESS';
export const ALL_USERS_IN_USER_GROUP_FAIL = 'ALL_USERS_IN_USER_GROUP_FAIL';

export const CREATE_BULK_USERS_TO_USER_GROUP = 'CREATE_BULK_USERS_TO_USER_GROUP';
export const CREATE_BULK_USERS_TO_USER_GROUP_SUCCESS = 'CREATE_BULK_USERS_TO_USER_GROUP_SUCCESS';
export const CREATE_BULK_USERS_TO_USER_GROUP_FAIL = 'CREATE_BULK_USERS_TO_USER_GROUP_FAIL';
