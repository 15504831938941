import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Card from 'components/inputs/Card';
import { Row, Col } from 'react-bootstrap';
import { Formik, Form, getIn } from 'formik';
import TextInput from 'components/inputs/Input';
import { MdClose } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import fileDownload from 'js-file-download';
import moment from 'moment';
import * as Yup from 'yup';
import { FETCH_REPORT_LIST, CREATE_REPORT_DOWNLOAD_REQUEST } from 'actions/downloadAdvanceReport';
import { GET_STATION_BY_CITY } from 'actions/chargingStation';
import { FETCH_TAGS } from 'actions/tagmanagement';
import { useTranslation } from 'react-i18next';
import Button from 'components/inputs/Button';
import * as _ from 'lodash';
import Select from 'components/inputs/Select';
import { toast } from 'react-toastify';

const DownloadReportForm = ({ onClose = _.noop(), downloadReport = _.noop() }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [type, setType] = useState('');

  const AdvanceReportSchema = Yup.object().shape({
    type: Yup.string().required(t('downloadReportForm.type')),
    name: Yup.string().required(t('downloadReportForm.name')),
    filter: Yup.object().shape({
      from: Yup.string().required(t('downloadReportForm.from')),
      to: Yup.string().required(t('downloadReportForm.to')),
    }),
  });

  const reportList = useSelector((state) => state.reportList.reportList);
  const allTags = useSelector((state) => state.tagmanagement.tags);
  const allStationByCity = useSelector((state) => state.stationByCity.stationByCities);
  const profileData = useSelector((state) => state.profile.userProfile);
  const userProfileStations = profileData.charging_stations;
  const stationList =
    userProfileStations && userProfileStations.length > 0
      ? allStationByCity.filter((x) => userProfileStations.indexOf(x.id) !== -1)
      : allStationByCity;

  const [fromDate, setFromDate] = useState('');

  const fetchReportList = useCallback((data) => {
    dispatch({
      type: FETCH_REPORT_LIST,
      payload: data,
    });
  }, []);

  const initialValues = {
    name: '',
    type: '',
    filter: {
      from: '',
      to: '',
    },
  };

  const createReportDownloadRequest = useCallback((data) => {
    const startTime = moment(data?.filter?.from);
    const endTime = moment(data?.filter?.to);
    const selectedTimeDiff = endTime.diff(startTime, 'days');
    const maxDays = data?.maxDay;
    const reportName = data?.name;
    data?.maxDay && delete data?.maxDay;
    dispatch({
      type: CREATE_REPORT_DOWNLOAD_REQUEST,
      payload: data,
      cb: (res) => {
        if (res) {
          console.log(res, 'response');
          if (_.get(res, 'status') === 200) {
            if (selectedTimeDiff > maxDays) {
              toast.success('Downloaded Successfully');
              setTimeout(() => {
                downloadReport({});
              }, 500);
            } else {
              fileDownload(res.data, `${reportName}.xlsx`);
              toast.success('Downloaded Successfully');
              setTimeout(() => {
                downloadReport({});
              }, 500);
            }
          }
        }
      },
    });
  }, []);

  const getAllStation = useCallback((data = {}) => {
    const stationData = {
      ...data,
      access_type: 'Public',
    };
    dispatch({ type: GET_STATION_BY_CITY, payload: stationData });
  }, []);

  const getTags = useCallback((data = {}) => {
    const tagData = {
      ...data,
    };
    dispatch({ type: FETCH_TAGS, payload: tagData });
  });

  useEffect(() => {
    fetchReportList();
    getAllStation();
    getTags({ limit: 999 });
  }, []);

  const handleFromDateChange = (val, setFieldValue) => {
    const dateValue = val.target.value;
    setFromDate(dateValue);
    setFieldValue('filter.from', dateValue);
  };

  return (
    <React.Fragment>
      <div className="advance-report-form-page__main">
        <Card>
          <div className="advance-report-form-header__block">
            &nbsp;
            <div className="advance-report-header-name">
              <div className="advance-report-form-text-align">{t('downloadReportForm.title')}</div>
            </div>
            <div className="advance-close-btn">
              <MdClose size={30} color="#be210b" onClick={onClose} />
            </div>
            &nbsp;
          </div>
          <div className="advance-report-form-body__block">
            <div className="advance-report-form--block">
              <Formik
                initialValues={initialValues}
                validationSchema={AdvanceReportSchema}
                onSubmit={(values, { setSubmitting }) => {
                  const selectedType = _.find(reportList, { id: values.type });

                  let reportData;
                  if (selectedType?.key === 'ALLDATAREPORT') {
                    reportData = {
                      ...values,
                      maxDay: selectedType?.max_days,
                      filter: {
                        from: moment(values.filter.from).startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
                        to: moment(values.filter.to).endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
                        report: 'alldatareport',
                        status: 'completed',
                        charging_station: values?.filter?.charging_station && values?.filter?.charging_station,
                        charger_id: values?.filter?.charger_id && values?.filter?.charger_id,
                      },
                    };
                  } else if (selectedType?.key === 'STATIONDOWNTIMEREPORT') {
                    reportData = {
                      ...values,
                      maxDay: selectedType?.max_days,
                      filter: {
                        from: moment(values.filter.from).startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
                        to: moment(values.filter.to).endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
                        charging_station: values?.filter?.charging_station && values?.filter?.charging_station,
                        tag: values?.filter?.tags && values?.filter?.tags,
                      },
                    };
                  } else {
                    reportData = {
                      ...values,
                      maxDay: selectedType?.max_days,
                      filter: {
                        from: moment(values.filter.from).startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
                        to: moment(values.filter.to).endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
                      },
                    };
                  }
                  createReportDownloadRequest(reportData);
                  setSubmitting(false);
                  onClose();
                }}
              >
                {({ values, handleSubmit, setFieldValue, handleChange, errors, touched }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col lg={6}>
                        <Select
                          isRequired
                          label={t('downloadReportForm.selectReport')}
                          options={[
                            ..._.map(reportList, (item) => {
                              return { label: item.name, value: item.id };
                            }),
                          ]}
                          placeholder={t('downloadReportForm.reportType')}
                          name="type"
                          value={values.type}
                          onMenuScrollDown={true}
                          onChange={(val) => {
                            setFieldValue(`type`, val);
                            const selectedType = _.find(reportList, { id: val });
                            setType(_.get(selectedType, 'key'));
                          }}
                          error={getIn(errors, 'type') && getIn(touched, 'type') ? getIn(errors, 'type') : null}
                        />
                      </Col>
                      <Col lg={6}>
                        <TextInput
                          isRequired
                          label={t('downloadReportForm.reportName')}
                          placeholder={t('downloadReportForm.enterName')}
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          error={getIn(errors, 'name') && getIn(touched, 'name') ? getIn(errors, 'name') : null}
                        />
                      </Col>
                      <Col lg={6}>
                        <span className="font-color">{t('downloadReportForm.startDateTime')}</span>
                        <div>&nbsp;</div>
                        <div className="time_sec">
                          <span className="custome_time">
                            <input
                              className="ct_selecter"
                              type="date"
                              value={values.filter.from}
                              max={moment().format('YYYY-MM-DD')}
                              onChange={(val) => handleFromDateChange(val, setFieldValue)}
                            />
                            <div style={{ color: '#dc4949', fontSize: 10 }}>
                              {getIn(errors, 'filter.from') && getIn(touched, 'filter.from') ? getIn(errors, 'filter.from') : null}
                            </div>
                          </span>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <span className="font-color">{t('downloadReportForm.endDateTime')}</span>
                        <div>&nbsp;</div>
                        <div className="time_sec">
                          <span className="custome_time">
                            <input
                              className="ct_selecter"
                              disabled={!fromDate}
                              type="date"
                              placeholder={t('downloadReportForm.selectDateTime')}
                              value={values.filter.to || ''}
                              min={fromDate}
                              max={moment().format('YYYY-MM-DD')}
                              onChange={(val) => {
                                setFieldValue(`filter.to`, val.target.value);
                              }}
                            />
                            <div style={{ color: '#dc4949', fontSize: 10 }}>
                              {getIn(errors, 'filter.to') && getIn(touched, 'filter.to') ? getIn(errors, 'filter.to') : null}
                            </div>
                          </span>
                        </div>
                      </Col>
                      {type === 'ALLDATAREPORT' || type === 'STATIONDOWNTIMEREPORT' ? (
                        <Col lg={6}>
                          <Select
                            label={t('placeHolder.selectStation')}
                            options={[
                              ..._.map(stationList, (station) => {
                                return { label: station.name, value: station.id };
                              }),
                            ]}
                            placeholder={`Station Name`}
                            name="filter.charging_station"
                            value={values.filter.charging_station}
                            isMulti
                            onMenuScrollDown={true}
                            onChange={(val) => {
                              setFieldValue(`filter.charging_station`, val);
                              setFieldValue(`filter.charger_id`, '');
                            }}
                          />
                        </Col>
                      ) : null}
                      {type === 'ALLDATAREPORT' ? (
                        <Col lg={6}>
                          <Select
                            label={t('Charger Id')}
                            options={
                              !_.isEmpty(stationList) && values.filter.charging_station
                                ? _.flatMap(
                                    stationList.filter((station) => values.filter.charging_station.includes(station.id)),
                                    (station) =>
                                      station.chargers && station.chargers.length > 0
                                        ? station.chargers
                                            .filter((charger) => !charger.is_deleted)
                                            .map((charger) => ({
                                              label: charger.charger_id,
                                              value: charger.id,
                                            }))
                                        : []
                                  )
                                : []
                            }
                            placeholder={t('Charger Id')}
                            name="filter.charger_id"
                            isMulti
                            value={values.filter.charger_id}
                            onMenuScrollDown={true}
                            onChange={(val) => {
                              setFieldValue('filter.charger_id', val);
                            }}
                          />
                        </Col>
                      ) : null}
                      {type === 'STATIONDOWNTIMEREPORT' ? (
                        <Col lg={6}>
                          <Select
                            label={t('filters.tags')}
                            options={_.map(allTags, (item) => {
                              return { label: item.name, value: item.id };
                            })}
                            placeholder={t('placeHolder.selectTags')}
                            name="filter.tags"
                            value={values.filter.tags}
                            isMulti
                            onMenuScrollDown={true}
                            getDataOnScrollDown={getTags}
                            onChange={(val) => {
                              setFieldValue(`filter.tags`, val);
                            }}
                          />
                        </Col>
                      ) : null}
                    </Row>
                    <div className="advance-report-input-box__block">
                      <Row>
                        <Col lg={6}>
                          <Button className="advance--btn cancel--btn" onClick={onClose}>
                            {t('button.cancel')}
                          </Button>
                        </Col>
                        <Col lg={6}>
                          <Button type="submit" className="advance--btn  remove--btn" onClick={handleSubmit}>
                            {t('downloadReportForm.button')}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};
DownloadReportForm.propTypes = {
  onClose: PropTypes.func,
  downloadReport: PropTypes.func,
};
export default DownloadReportForm;
