import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import {
  FETCH_CHARGER_BOOKING,
  FETCH_CHARGER_BOOKING_SUCCESS,
  FETCH_CHARGER_BOOKING_FAIL,
  DELETE_CHARGER_BOOKING_SUCCESS,
  DELETE_CHARGER_BOOKING_FAIL,
  DELETE_CHARGER_BOOKING,
  START_REMOTE,
  START_REMOTE_SUCCESS,
  START_REMOTE_FAIL,
  STOP_TRANSACTION,
  STOP_TRANSACTION_SUCCESS,
  STOP_TRANSACTION_FAIL,
  GET_CHARGER_BOOKING,
  GET_CHARGER_BOOKING_SUCCESS,
  GET_CHARGER_BOOKING_FAIL,
} from 'actions/chargerBooking';

function* getChargerBooking(action) {
  try {
    const response = yield call(API.getChargerBooking, action.payload);
    yield put({ type: FETCH_CHARGER_BOOKING_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_CHARGER_BOOKING_FAIL, payload: { error: e } });
  }
}

function* getChargerBookingById(action) {
  try {
    const response = yield call(API.getChargerBookingById, action.payload);
    yield put({ type: GET_CHARGER_BOOKING_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_CHARGER_BOOKING_FAIL, payload: { error: e } });
  }
}

function* createStartRemote(action) {
  try {
    yield call(API.addStartRemote, action.payload);
    yield put({ type: START_REMOTE_SUCCESS, payload: action.payload });
  } catch (e) {
    yield put({ type: START_REMOTE_FAIL, payload: { error: e } });
  }
}
function* createStopTransaction(action) {
  try {
    const response = yield call(API.stopTransaction, action.payload);
    yield put({ type: STOP_TRANSACTION_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: STOP_TRANSACTION_FAIL, payload: { error: e } });
  }
}

function* deleteChargerBooking(action) {
  try {
    yield call(API.deleteChargerBooking, action.payload);
    yield put({ type: DELETE_CHARGER_BOOKING_SUCCESS, payload: action.payload });
  } catch (e) {
    yield put({ type: DELETE_CHARGER_BOOKING_FAIL, payload: { error: e } });
  }
}

function* chargerBooking() {
  yield takeLatest(FETCH_CHARGER_BOOKING, getChargerBooking);
  yield takeLatest(GET_CHARGER_BOOKING, getChargerBookingById);
  yield takeLatest(DELETE_CHARGER_BOOKING, deleteChargerBooking);
  yield takeLatest(START_REMOTE, createStartRemote);
  yield takeLatest(STOP_TRANSACTION, createStopTransaction);
}
export default chargerBooking;
