import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import {
  CHANGE_ROLE,
  CHANGE_ROLE_FAIL,
  CHANGE_ROLE_SUCCESS,
  CREATE_USER,
  CREATE_USER_FAIL,
  CREATE_USER_SUCCESS,
  DELETE_USER,
  DELETE_USER_FAIL,
  DELETE_USER_SUCCESS,
  FETCH_USER,
  FETCH_USER_FAIL,
  FETCH_USER_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_FAIL,
  UPDATE_USER_SUCCESS,
  FETCH_FLEET_MEMBERS,
  FETCH_FLEET_MEMBERS_SUCCESS,
  FETCH_FLEET_MEMBERS_FAIL,
} from 'actions/user';

function* getUsers(action) {
  try {
    const response = yield call(API.getUsers, action.payload);
    yield put({ type: FETCH_USER_SUCCESS, payload: response });
  } catch (e) {
    yield put({ type: FETCH_USER_FAIL, payload: { error: e } });
  }
}

function* createUser(action) {
  try {
    const response = yield call(API.addUser, action.payload);
    yield put({ type: CREATE_USER_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_USER_FAIL, payload: { error: e } });
  }
}

function* changeRole(action) {
  try {
    const response = yield call(API.updateUser, action.payload);
    yield put({ type: CHANGE_ROLE_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CHANGE_ROLE_FAIL, payload: { error: e } });
  }
}

function* updateUser(action) {
  try {
    const response = yield call(API.updateUser, action.payload);
    yield put({ type: UPDATE_USER_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_USER_FAIL, payload: { error: e } });
  }
}

function* deleteUser(action) {
  try {
    yield call(API.deleteUser, action.payload);
    yield put({ type: DELETE_USER_SUCCESS, payload: action.payload });
  } catch (e) {
    yield put({ type: DELETE_USER_FAIL, payload: { error: e } });
  }
}

function* getFleetMembers(action) {
  try {
    const response = yield call(API.getFleetMembers, action.payload);
    yield put({ type: FETCH_FLEET_MEMBERS_SUCCESS, payload: response });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_FLEET_MEMBERS_FAIL, payload: { error: e } });
  }
}

function* user() {
  yield takeLatest(FETCH_USER, getUsers);
  yield takeLatest(CREATE_USER, createUser);
  yield takeLatest(DELETE_USER, deleteUser);
  yield takeLatest(UPDATE_USER, updateUser);
  yield takeLatest(CHANGE_ROLE, changeRole);
  yield takeLatest(FETCH_FLEET_MEMBERS, getFleetMembers);
}

export default user;
