import { FETCH_CUSTOMER_TRANSACTION, FETCH_CUSTOMER_TRANSACTION_SUCCESS, FETCH_CUSTOMER_TRANSACTION_FAIL } from 'actions/customer';

const initialState = {
  isLoading: false,
  customerTransaction: [],
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};
const customerTransactionReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_CUSTOMER_TRANSACTION: {
      return { ...state, isLoading: true };
    }

    case FETCH_CUSTOMER_TRANSACTION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        customerTransaction: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case FETCH_CUSTOMER_TRANSACTION_FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return { ...state };
  }
};
export default customerTransactionReducer;
