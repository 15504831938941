export const FETCH_CHARGING_STATION_ACCESS_TYPE = 'FETCH_CHARGING_STATION_ACCESS_TYPE';
export const FETCH_CHARGING_STATION_ACCESS_TYPE_SUCCESS = 'FETCH_CHARGING_STATION_ACCESS_TYPE_SUCCESS';
export const FETCH_CHARGING_STATION_ACCESS_TYPE_FAIL = 'FETCH_CHARGING_STATION_ACCESS_TYPE_FAIL';

export const GET_CHARGING_STATION_ACCESS_TYPE = 'GET_CHARGING_STATION_ACCESS_TYPE_MAKE';
export const GET_CHARGING_STATION_ACCESS_TYPE_SUCCESS = 'GET_CHARGING_STATION_ACCESS_TYPE_SUCCESS';
export const GET_CHARGING_STATION_ACCESS_TYPE_FAIL = 'GET_CHARGING_STATION_ACCESS_TYPE_FAIL';

export const CREATE_CHARGING_STATION_ACCESS_TYPE = 'CREATE_CHARGING_STATION_ACCESS_TYPE';
export const CREATE_CHARGING_STATION_ACCESS_TYPE_SUCCESS = 'CREATE_CHARGING_STATION_ACCESS_TYPE_SUCCESS';
export const CREATE_CHARGING_STATION_ACCESS_TYPE_FAIL = 'CREATE_CHARGING_STATION_ACCESS_TYPE_FAIL';

export const UPDATE_CHARGING_STATION_ACCESS_TYPE = 'UPDATE_CHARGING_STATION_ACCESS_TYPE';
export const UPDATE_CHARGING_STATION_ACCESS_TYPE_SUCCESS = 'UPDATE_CHARGING_STATION_ACCESS_TYPE_SUCCESS';
export const UPDATE_CHARGING_STATION_ACCESS_TYPE_FAIL = 'UPDATE_CHARGING_STATION_ACCESS_TYPE_FAIL';

export const DELETE_CHARGING_STATION_ACCESS_TYPE = 'DELETE_CHARGING_STATION_ACCESS_TYPE';
export const DELETE_CHARGING_STATION_ACCESS_TYPE_SUCCESS = 'DELETE_CHARGING_STATION_ACCESS_TYPE_SUCCESS';
export const DELETE_CHARGING_STATION_ACCESS_TYPE_FAIL = 'DELETE_CHARGING_STATION_ACCESS_TYPE_FAIL';
