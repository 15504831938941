import {
  FETCH_VEHICLE_MAKE,
  FETCH_VEHICLE_MAKE_SUCCESS,
  FETCH_VEHICLE_MAKE_FAIL,
  GET_VEHICLE_MAKE,
  GET_VEHICLE_MAKE_SUCCESS,
  GET_VEHICLE_MAKE_FAIL,
  CREATE_VEHICLE_MAKE,
  CREATE_VEHICLE_MAKE_SUCCESS,
  CREATE_VEHICLE_MAKE_FAIL,
  UPDATE_VEHICLE_MAKE,
  UPDATE_VEHICLE_MAKE_FAIL,
  UPDATE_VEHICLE_MAKE_SUCCESS,
  DELETE_VEHICLE_MAKE,
  DELETE_VEHICLE_MAKE_SUCCESS,
  DELETE_VEHICLE_MAKE_FAIL,
} from 'actions/vehicleMake';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';

function* fetchVehicleMake(action) {
  try {
    const response = yield call(API.getVehicleMake, action.payload);
    yield put({ type: FETCH_VEHICLE_MAKE_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_VEHICLE_MAKE_FAIL, payload: { error: e } });
  }
}

function* getVehicleMakeById(action) {
  try {
    const response = yield call(API.getVehicleMakeById, action.payload);
    yield put({ type: GET_VEHICLE_MAKE_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_VEHICLE_MAKE_FAIL, payload: { error: e } });
  }
}

function* createVehicleMake(action) {
  try {
    const response = yield call(API.addVehicleMake, action.payload);
    yield put({ type: CREATE_VEHICLE_MAKE_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_VEHICLE_MAKE_FAIL, payload: { error: e } });
  }
}

function* updateVehicleMake(action) {
  try {
    const response = yield call(API.updateVehicleMake, action.payload);
    yield put({ type: UPDATE_VEHICLE_MAKE_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_VEHICLE_MAKE_FAIL, payload: { error: e } });
  }
}

function* deleteVehicleMake(action) {
  try {
    const response = yield call(API.deleteVehicleMake, action.payload);
    yield put({ type: DELETE_VEHICLE_MAKE_SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_VEHICLE_MAKE_FAIL, payload: { error: e } });
  }
}

function* vehicleMake() {
  yield takeLatest(FETCH_VEHICLE_MAKE, fetchVehicleMake);
  yield takeLatest(GET_VEHICLE_MAKE, getVehicleMakeById);
  yield takeLatest(CREATE_VEHICLE_MAKE, createVehicleMake);
  yield takeLatest(UPDATE_VEHICLE_MAKE, updateVehicleMake);
  yield takeLatest(DELETE_VEHICLE_MAKE, deleteVehicleMake);
}

export default vehicleMake;
