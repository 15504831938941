import React, { useState, useCallback, useEffect, useContext } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header';
import Card from 'components/inputs/Card';
import { BsSearch } from 'react-icons/bs';
import Button from 'components/inputs/Button';
import { FiEdit2 } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { Row, Col, Spinner } from 'react-bootstrap';
import * as _ from 'lodash';
import SearchBox from 'components/general/SearchBox';
import { useDispatch, useSelector } from 'react-redux';
import ReactPagination from 'components/general/ReactPagination';
import { Modal } from 'react-bootstrap';
import { DELETE_TAGS, FETCH_TAGS, GET_TAG } from 'actions/tagmanagement';
import Tagform from './tagmanagementForm';
import DeleteModal from 'components/general/DeleteModal';
import { DELETE_STATUS_CODE } from 'components/common/constant';
import { BiSort } from 'react-icons/bi';
import Footer from 'components/general/Footer';
import { useTranslation } from 'react-i18next';
import { NavContext } from 'components/privateRoute/PrivateRoute';

const Tagmanagement = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isAllowed } = useContext(NavContext);

  const [showForm, setShowForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [tagId, setTagId] = useState('');
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });

  const page = useSelector((state) => state.tagmanagement.page);
  const totalData = useSelector((state) => state.tagmanagement.total);
  const limit = useSelector((state) => state.tagmanagement.limit);
  const totalPages = useSelector((state) => state.tagmanagement.totalPages);
  const tagList = useSelector((state) => state.tagmanagement.tags);
  const isLoader = useSelector((state) => state.tagmanagement.isLoading);
  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserName = _.get(profileData, 'name', '');

  const getTags = useCallback(
    (data = {}) => {
      if (searchText) {
        const tagsData = {
          ...data,
          search: searchText,
        };
        dispatch({ type: FETCH_TAGS, payload: tagsData });
      } else {
        dispatch({ type: FETCH_TAGS, payload: data });
      }
    },
    [searchText]
  );

  const getTagDetails = useCallback((id) => {
    dispatch({ type: GET_TAG, payload: id });
  }, []);

  const deleteTags = useCallback(
    () =>
      dispatch({
        type: DELETE_TAGS,
        payload: tagId,
        cb: (res) => {
          if (_.get(res, 'status', '') === DELETE_STATUS_CODE) {
            handleCloseDeleteModel();
            getTags({ page: 1 });
          }
        },
      }),
    [tagId]
  );

  useEffect(() => {
    getTags();
  }, []);

  const handleSearch = () => {
    getTags({});
  };

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else {
        const data = {
          page: page.selected + 1,
        };
        getTags(data);
      }
    },
    [sortByItem]
  );

  const handleCloseFormTags = () => {
    setShowForm(false);
    setIsEdit(false);
  };

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getTags(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getTags(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handleCloseDeleteModel = () => {
    setShowDeleteModal(false);
    setTagId('');
  };

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.tagManagement')} />
        <div className="page-content-wrapper scrollable">
          <div className="chargerStateList-page">
            <Card>
              <div className="chargerStateList-page-wrapper">
                <Row className="chargerStateList-search-box">
                  <Col xl={10} lg={9} md={8} className="chargerStateList-search-inputBox">
                    <SearchBox
                      value={searchText}
                      preIcon={<BsSearch />}
                      onChange={(e) => setSearchText(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          handleSearch();
                        }
                      }}
                    />
                  </Col>
                  {isAllowed('modifyTagManagement') && (
                    <Col xl={2} lg={3} md={4} className="chargerStateList-search-boxButton">
                      <Button
                        className="chargerStateList-search-box-Btn"
                        onClick={() => {
                          setShowForm(true);
                          setIsEdit(false);
                        }}
                      >
                        {t('button.addTags')}
                      </Button>
                    </Col>
                  )}
                </Row>
                <div className="chargerStateList-table">
                  <div className="table-responsive">
                    <table className="record-list-table">
                      <thead>
                        <tr>
                          <th>
                            <div className="sorting">
                              <span>{t('tagManagement.name')}</span>
                              <span className="ico" onClick={() => handleSorting('name', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          {isAllowed('modifyTagManagement') && (
                            <th className="action-col">
                              <span>{t('tagManagement.action')}</span>
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {isLoader ? (
                          <tr>
                            <td colSpan={10} className="border-0">
                              <div className="loader--block">
                                <Spinner as="span" animation="border" size="sm" role="status" />
                                <span className="ps-2">{t('placeHolder.loading')}</span>
                              </div>
                            </td>
                          </tr>
                        ) : _.isEmpty(tagList) ? (
                          <tr>
                            <td colSpan={3} className="border-0">
                              <div className="empty-data-block">{t('tagManagement.noData')}</div>
                            </td>
                          </tr>
                        ) : (
                          !(isLoader || _.isUndefined(isLoader)) &&
                          _.map(tagList, (item, key) => {
                            return (
                              <>
                                <tr key={`tag-${key}`}>
                                  <td>{_.get(item, 'name', '')}</td>
                                  {isAllowed('modifyTagManagement') && (
                                    <td>
                                      <span
                                        className="chargerStateList-table-editIcon"
                                        onClick={() => {
                                          setShowForm(true);
                                          getTagDetails(_.get(item, 'id', ''));
                                          setIsEdit(true);
                                        }}
                                      >
                                        <FiEdit2 title="Edit" />
                                      </span>
                                      <span
                                        className="chargerStateList-table-deleteIcon"
                                        onClick={() => {
                                          setShowDeleteModal(true);
                                          setTagId(_.get(item, 'id', ''));
                                        }}
                                      >
                                        <RiDeleteBinLine title="Delete" />
                                      </span>
                                    </td>
                                  )}
                                </tr>
                              </>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(tagList) && (
                  <ReactPagination
                    currentPage={page}
                    limit={limit}
                    total={totalData}
                    handlePageClick={(pageVal) => handlePageClick(pageVal)}
                    totalPages={totalPages}
                    marginPagesDisplayed={1}
                  />
                )}
              </div>
            </Card>
          </div>
        </div>
        {showForm && (
          <Modal show={showForm} size="lg" centered>
            <Tagform onClose={handleCloseFormTags} onSuccess={getTags} isEdit={isEdit} />
          </Modal>
        )}
        {showDeleteModal && (
          <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
            <DeleteModal title="Tag" onClose={handleCloseDeleteModel} onRemove={deleteTags} />
          </Modal>
        )}
        {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
      </LayoutContainer>
    </>
  );
};
export default Tagmanagement;
