import {
  CAPACITY_UTILISATION,
  CAPACITY_UTILISATION_SUCCESS,
  CAPACITY_UTILISATION_FAIL,
  DOWNLOAD_CAPACITY_DATA,
  DOWNLOAD_CAPACITY_DATA_SUCCESS,
  DOWNLOAD_CAPACITY_DATA_ERROR,
} from 'actions/dataReport';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';

function* getCapacityUtilisation(action) {
  try {
    const response = yield call(API.getCapacityUtilisation, action.payload);
    yield put({ type: CAPACITY_UTILISATION_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CAPACITY_UTILISATION_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* downloadCapacityUtilisation(action) {
  try {
    const response = yield call(API.downloadCapacityUtilisation, action.payload);
    yield put({ type: DOWNLOAD_CAPACITY_DATA_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_CAPACITY_DATA_ERROR, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* capacityUtilisation() {
  yield takeLatest(CAPACITY_UTILISATION, getCapacityUtilisation);
  yield takeLatest(DOWNLOAD_CAPACITY_DATA, downloadCapacityUtilisation);
}
export default capacityUtilisation;
