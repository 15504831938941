import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Card from 'components/inputs/Card';
import { MdClose } from 'react-icons/md';
import { Row, Col } from 'react-bootstrap';
import { Formik, Form, getIn } from 'formik';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import _ from 'lodash';
import * as Yup from 'yup';
import PhoneNumberInput from 'components/inputs/PhoneNumberInput';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_CONTACT_PERSON } from 'actions/chargingStation';
import { useTranslation } from 'react-i18next';

const ContactPersonForm = (props) => {
  const { onClose } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [phoneError, setPhoneError] = useState('');

  const chargingStationData = useSelector((state) => state.chargingStation.chargingStationDetail);

  const updateContactPerson = useCallback((data) => {
    dispatch({
      type: UPDATE_CONTACT_PERSON,
      payload: data,
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            setTimeout(() => {
              onClose();
            }, 1000);
          }
        }
      },
    });
  }, []);

  const ContactPersonSchema = Yup.object().shape({
    name: Yup.string().required(t('contactPersonForm.name')).trim(t('contactPersonForm.space')).strict(true),
    email: Yup.string().required(t('contactPersonForm.email')).trim(t('contactPersonForm.space')).strict(true),
    address: Yup.string().required(t('contactPersonForm.address')).trim(t('contactPersonForm.space')).strict(true),
    phone: Yup.string().required(t('contactPersonForm.phone')).trim(t('contactPersonForm.space')).strict(true),
  });

  return (
    <React.Fragment>
      <div className="contactPerson-form__main">
        <Card>
          <div className="contactPerson-form-header__block">
            <div className="contactPerson-header-name">
              <span>{t('contactPersonForm.title')}</span>
            </div>
            <div className="close-btn">
              <MdClose size={30} color="#be210b" onClick={onClose} />
            </div>
          </div>
          <div className="contactPerson-form-body__block">
            <div className="contactPerson-form--block">
              <Formik
                initialValues={{
                  name: _.get(chargingStationData, 'contact_person.name', ''),
                  email: _.get(chargingStationData, 'contact_person.email', ''),
                  address: _.get(chargingStationData, 'contact_person.address', ''),
                  phone: `+ ${_.get(chargingStationData, 'contact_person.country_code')} ${_.get(
                    chargingStationData,
                    'contact_person.phone'
                  )}`.toString(),
                }}
                validationSchema={ContactPersonSchema}
                onSubmit={(values, { setSubmitting }) => {
                  if (values.phone && !isValidPhoneNumber(values.phone)) {
                    setPhoneError('Phone number is not valid!');
                  } else {
                    // const countryCode = _.get(parsePhoneNumber(values.contact_person.phone), 'countryCallingCode', '');
                    // const phoneNumber = _.get(parsePhoneNumber(values.contact_person.phone), 'nationalNumber', '');
                    const contactPersonDetail = _.omit(values, ['phone']);
                    updateContactPerson({ ...contactPersonDetail, id: _.get(chargingStationData, 'contact_person.id', '') });
                  }
                  setSubmitting(false);
                }}
              >
                {({ values, errors, touched, handleSubmit, handleChange, setFieldValue, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col lg={6} md={12}>
                        <TextInput
                          label={t('contactPersonForm.fullName')}
                          name="name"
                          value={_.get(values, 'name', '')}
                          onChange={handleChange}
                          error={errors.name && touched.name ? errors.name : null}
                        />
                      </Col>
                      <Col lg={6} md={12}>
                        <PhoneNumberInput
                          disabled
                          label={t('contactPersonForm.phoneNo')}
                          name="phone"
                          value={_.get(values, 'phone')}
                          onChange={(val) => {
                            setFieldValue(`phone`, val);
                            if (!_.isEmpty(phoneError)) {
                              setPhoneError('');
                            }
                          }}
                          error={getIn(errors, 'phone') && getIn(touched, 'phone') ? getIn(errors, 'phone') : phoneError ? phoneError : null}
                        />
                      </Col>
                      <Col lg={6} md={12}>
                        <TextInput
                          label={t('contactPersonForm.emailAddress')}
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          error={errors.email && touched.email ? errors.email : null}
                        />
                      </Col>
                      <Col lg={6} md={12}>
                        <TextInput
                          label={t('contactPersonForm.addressLabel')}
                          name="address"
                          value={values.address}
                          onChange={handleChange}
                          error={errors.address && touched.address ? errors.address : null}
                        />
                      </Col>
                    </Row>
                    <div className="form_btn--block">
                      <Button className="form--btn cancel_btn" onClick={onClose}>
                        {t('button.cancel')}
                      </Button>
                      <Button type="submit" disabled={isSubmitting} className="form--btn">
                        {t('button.update')}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};

ContactPersonForm.propTypes = {
  onClose: PropTypes.func,
};

export default ContactPersonForm;
