import {
    CREATE_SCHEDULING_SETTING,
    CREATE_SCHEDULING_SETTING_SUCCESS,
    CREATE_SCHEDULING_SETTING_FAIL,
    FETCH_SCHEDULING_SETTING,
    FETCH_SCHEDULING_SETTING_SUCCESS,
    FETCH_SCHEDULING_SETTING_FAIL,
    GET_SCHEDULING_SETTING,
    GET_SCHEDULING_SETTING_SUCCESS,
    GET_SCHEDULING_SETTING_FAIL,
    UPDATE_SCHEDULING_SETTING,
    UPDATE_SCHEDULING_SETTING_FAIL,
    UPDATE_SCHEDULING_SETTING_SUCCESS,
    DELETE_SCHEDULING_SETTING,
    DELETE_SCHEDULING_SETTING_SUCCESS,
    DELETE_SCHEDULING_SETTING_FAIL,
  } from 'actions/schedulingSetting.js';
  import * as _ from 'lodash';
  import { toast } from 'react-toastify';

  const initialState = {
    isLoading: false,
    schedulingSetting: [],
    schedulingSettingDetails:{},
    page: 1,
    limit: 10,
    totalPages: 1,
    total: 1,
  };
  
  const schedulingSettingReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case FETCH_SCHEDULING_SETTING:
      case CREATE_SCHEDULING_SETTING:
      case GET_SCHEDULING_SETTING:
      case DELETE_SCHEDULING_SETTING: {
        return { ...state, isLoading: true, schedulingSettingDetails: {} };
      }
  
      case UPDATE_SCHEDULING_SETTING: {
        return { ...state, isLoading: true };
      }
  
      case FETCH_SCHEDULING_SETTING_SUCCESS: {
          return {
            ...state,
            isLoading: false,
            schedulingSetting:  payload.results.filter((data) => data.key_name === 'booking_settings'),
        }
      }
  
      case GET_SCHEDULING_SETTING_SUCCESS: {
        return {
          ...state,
          schedulingSettingDetails: payload,
          isLoading: false,
        };
      }
  
      case CREATE_SCHEDULING_SETTING_SUCCESS: {
        toast.success(' Scheduling Setting creaded successfully');
        return {
          ...state,
          schedulingSetting: _.concat(state.schedulingSetting, payload),
          isLoading: false,
        };
      }
  
      case UPDATE_SCHEDULING_SETTING_SUCCESS: {
        toast.success('Scheduling Setting updated successfully');
        console.log(state.schedulingSetting);
        return {
          ...state,
          isLoading: false,
          schedulingSetting: payload,
        };
      }
  
      case DELETE_SCHEDULING_SETTING_SUCCESS: {
        toast.success('Scheduling Setting deleted successfully');
        return {
          ...state,
          isLoading: false,
          schedulingSetting: state.schedulingSetting.filter((data) => data.id !== payload),
        };
      }
  
      case FETCH_SCHEDULING_SETTING_FAIL:
      case CREATE_SCHEDULING_SETTING_FAIL:
      case GET_SCHEDULING_SETTING_FAIL:
      case DELETE_SCHEDULING_SETTING_FAIL:
      case UPDATE_SCHEDULING_SETTING_FAIL: {
        return { ...state, isLoading: false };
      }
      default:
        return { ...state };
    }
  };
  export default schedulingSettingReducer;