export const FETCH_CURRENCY = 'FETCH_CURRENCY';
export const FETCH_CURRENCY_SUCCESS = 'FETCH_CURRENCY_SUCCESS';
export const FETCH_CURRENCY_FAIL = 'FETCH_CURRENCY_FAIL';

export const GET_CURRENCY = 'GET_CURRENCY';
export const GET_CURRENCY_SUCCESS = 'GET_CURRENCY_SUCCESS';
export const GET_CURRENCY_FAIL = 'GET_CURRENCY_FAIL';

export const CREATE_CURRENCY = 'CREATE_CURRENCY';
export const CREATE_CURRENCY_SUCCESS = 'CREATE_CURRENCY_SUCCESS';
export const CREATE_CURRENCY_FAIL = 'CREATE_CURRENCY_FAIL';

export const UPDATE_CURRENCY = 'UPDATE_CURRENCY';
export const UPDATE_CURRENCY_SUCCESS = 'UPDATE_CURRENCY_SUCCESS';
export const UPDATE_CURRENCY_FAIL = 'UPDATE_CURRENCY_FAIL';

export const DELETE_CURRENCY = 'DELETE_CURRENCY';
export const DELETE_CURRENCY_SUCCESS = 'DELETE_CURRENCY_SUCCESS';
export const DELETE_CURRENCY_FAIL = 'DELETE_CURRENCY_FAIL';

export const CURRENCY_UPDATE_FIRMWARE = 'CURRENCY_UPDATE_FIRMWARE';
export const CURRENCY_UPDATE_FIRMWARE_SUCCESS = 'CURRENCY_UPDATE_FIRMWARE_SUCCESS';
export const CURRENCY_UPDATE_FIRMWARE_FAIL = 'CURRENCY_UPDATE_FIRMWARE_FAIL';
