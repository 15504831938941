import {
  FETCH_CONNECTOR_TYPE,
  FETCH_CONNECTOR_TYPE_SUCCESS,
  FETCH_CONNECTOR_TYPE_FAIL,
  GET_CONNECTOR_TYPE,
  GET_CONNECTOR_TYPE_SUCCESS,
  GET_CONNECTOR_TYPE_FAIL,
  CREATE_CONNECTOR_TYPE,
  CREATE_CONNECTOR_TYPE_SUCCESS,
  CREATE_CONNECTOR_TYPE_FAIL,
  UPDATE_CONNECTOR_TYPE,
  UPDATE_CONNECTOR_TYPE_SUCCESS,
  UPDATE_CONNECTOR_TYPE_FAIL,
  DELETE_CONNECTOR_TYPE,
  DELETE_CONNECTOR_TYPE_SUCCESS,
  DELETE_CONNECTOR_TYPE_FAIL,
} from 'actions/connectorType';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  connectorTypes: [],
  connectorTypeDetail: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const connectorTypeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_CONNECTOR_TYPE:
    case GET_CONNECTOR_TYPE:
    case CREATE_CONNECTOR_TYPE:
    case DELETE_CONNECTOR_TYPE: {
      return { ...state, isLoading: true, connectorTypeDetail: {} };
    }

    case UPDATE_CONNECTOR_TYPE: {
      return { ...state, isLoading: true };
    }

    case FETCH_CONNECTOR_TYPE_SUCCESS: {
      if (payload.page === 1) {
        return {
          ...state,
          isLoading: false,
          connectorTypes: payload.results,
          page: payload.page,
          limit: payload.limit,
          totalPages: payload.totalPages,
          total: payload.totalResults,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          connectorTypes: _.concat(state.connectorTypes, payload.results),
          page: payload.page,
          limit: payload.limit,
          totalPages: payload.totalPages,
          total: payload.totalResults,
        };
      }
    }

    case GET_CONNECTOR_TYPE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        connectorTypeDetail: payload,
      };
    }

    case CREATE_CONNECTOR_TYPE_SUCCESS: {
      toast.success('Connector added successfully');
      return {
        ...state,
        isLoading: false,
        connectorTypes: _.concat(state.connectorTypes, payload),
      };
    }

    case UPDATE_CONNECTOR_TYPE_SUCCESS: {
      toast.success('Connector updated successfully');
      return {
        ...state,
        connectorTypes: state.connectorTypes.map((data) => (data.id === payload.id ? payload : data)),
        isLoading: false,
      };
    }

    case DELETE_CONNECTOR_TYPE_SUCCESS: {
      toast.success('Connector deleted successfully');
      return {
        ...state,
        isLoading: false,
        connectorTypes: state.connectorTypes.filter((data) => data.id !== payload),
      };
    }

    case FETCH_CONNECTOR_TYPE_FAIL:
    case GET_CONNECTOR_TYPE_FAIL:
    case CREATE_CONNECTOR_TYPE_FAIL:
    case UPDATE_CONNECTOR_TYPE_FAIL:
    case DELETE_CONNECTOR_TYPE_FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};
export default connectorTypeReducer;
