import React, { useState, useCallback, useEffect } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header';
import Card from 'components/inputs/Card';
import { BsSearch } from 'react-icons/bs';
import Button from 'components/inputs/Button';
import { FiEdit2 } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { Row, Col } from 'react-bootstrap';
import * as _ from 'lodash';
import SearchBox from 'components/general/SearchBox';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from 'components/general/Pagination';
import { Modal } from 'react-bootstrap';
import { startsWith } from 'components/common/utils';
import { DELETE_PRIVACY, FETCH_PRIVACY, GET_PRIVACY } from 'actions/privacy';
import PrivacyForm from './PrivacyForm';
import { useTranslation } from 'react-i18next';

const PrivacyList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [allPrivacyData, setAllPrivacyData] = useState([]);

  const page = useSelector((state) => state.privacy.page);
  const totalData = useSelector((state) => state.privacy.total);
  const limit = useSelector((state) => state.privacy.limit);
  const privacyList = useSelector((state) => state.privacy.privacy);

  const deletePrivacy = useCallback((id) => dispatch({ type: DELETE_PRIVACY, payload: id }), []);

  const getPrivacy = useCallback((data = {}) => {
    dispatch({ type: FETCH_PRIVACY, payload: data });
  }, []);

  const getPrivacyDetail = useCallback((id) => {
    dispatch({ type: GET_PRIVACY, payload: id });
  }, []);

  useEffect(() => {
    getPrivacy();
  }, []);

  const handleSearch = () => {
    getPrivacy({});
  };

  useEffect(() => {
    setAllPrivacyData(
      _.filter(privacyList, (item) => startsWith(_.get(item, 'title', ''), searchText) || startsWith(_.get(item, 'message', ''), searchText))
    );
  }, [searchText, privacyList]);

  const handlePageClick = (page) => {
    const data = {
      page: page,
    };
    getPrivacy(data);
  };
  const handleCloseFormPrivacy = () => {
    setShowForm(false);
    setIsEdit(false);
  };

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.privacy')} />
        <div className="page-content-wrapper scrollable">
          <div className="privacyList-page">
            <Card>
              <div className="privacyList-page-wrapper">
                <Row className="privacyList-search-box">
                  <Col xl={10} lg={9} md={8} className="privacyList-search-inputBox">
                    <SearchBox
                      value={searchText}
                      preIcon={<BsSearch />}
                      onChange={(e) => setSearchText(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          handleSearch();
                        }
                      }}
                    />
                  </Col>
                  <Col xl={2} lg={3} md={4} className="privacyList-search-boxButton">
                    <Button
                      className="privacyList-search-box-Btn"
                      onClick={() => {
                        setShowForm(true);
                        setIsEdit(false);
                      }}
                    >
                      {t('button.addPrivacy')}
                    </Button>
                  </Col>
                </Row>
                <div className="privacyList-table">
                  <div className="table-responsive">
                    <table className="record-list-table">
                      <thead>
                        <tr>
                          <th>
                            <div className="sorting">
                              <span>{t('privacyForm.orderLabel')}</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>{t('privacyForm.titleLabel')}</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>{t('privacyForm.messageLabel')}</span>
                            </div>
                          </th>
                          <th className="action-col">
                            <span>{t('privacyForm.action')}</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {_.isEmpty(allPrivacyData) ? (
                          <tr>
                            <td colSpan={3} className="border-0">
                              <div className="empty-data-block">{t('privacyForm.noData')}</div>
                            </td>
                          </tr>
                        ) : (
                          _.map(allPrivacyData, (item, key) => {
                            return (
                              <>
                                <tr key={`privacy-${key}`}>
                                  <td>{_.get(item, 'order', '')}</td>
                                  <td>{_.get(item, 'title', '')}</td>
                                  <td>{_.get(item, 'message', '')}</td>
                                  <td>
                                    <span
                                      className="privacyList-table-editIcon"
                                      onClick={() => {
                                        setShowForm(true);
                                        getPrivacyDetail(_.get(item, 'id', ''));
                                        setIsEdit(true);
                                      }}
                                    >
                                      <FiEdit2 title="Edit" />
                                    </span>
                                    <span className="privacyList-table-deleteIcon" onClick={() => deletePrivacy(_.get(item, 'id', ''))}>
                                      <RiDeleteBinLine title="Delete" />
                                    </span>
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {!_.isEmpty(allPrivacyData) && (
                  <Pagination currentPage={page} limit={limit} total={totalData} onPageChange={(pageVal) => handlePageClick(pageVal)} />
                )}
              </div>
            </Card>
          </div>
        </div>
        {showForm && (
          <Modal show={showForm} size="lg" centered>
            <PrivacyForm onClose={handleCloseFormPrivacy} isEdit={isEdit} />
          </Modal>
        )}
      </LayoutContainer>
    </>
  );
};
export default PrivacyList;
