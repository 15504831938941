import {
  FETCH_VEHICLE_MODEL,
  FETCH_VEHICLE_MODEL_SUCCESS,
  FETCH_VEHICLE_MODEL_FAIL,
  GET_VEHICLE_MODEL,
  GET_VEHICLE_MODEL_SUCCESS,
  GET_VEHICLE_MODEL_FAIL,
  CREATE_VEHICLE_MODEL,
  CREATE_VEHICLE_MODEL_SUCCESS,
  CREATE_VEHICLE_MODEL_FAIL,
  UPDATE_VEHICLE_MODEL,
  UPDATE_VEHICLE_MODEL_FAIL,
  UPDATE_VEHICLE_MODEL_SUCCESS,
  DELETE_VEHICLE_MODEL,
  DELETE_VEHICLE_MODEL_SUCCESS,
  DELETE_VEHICLE_MODEL_FAIL,
} from 'actions/vehicleModel';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';

function* fetchVehicleModel(action) {
  try {
    const response = yield call(API.getVehicleModel, action.payload);
    yield put({ type: FETCH_VEHICLE_MODEL_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_VEHICLE_MODEL_FAIL, payload: { error: e } });
  }
}

function* getVehicleModelById(action) {
  try {
    const response = yield call(API.getVehicleModelById, action.payload);
    yield put({ type: GET_VEHICLE_MODEL_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_VEHICLE_MODEL_FAIL, payload: { error: e } });
  }
}

function* createVehicleModel(action) {
  try {
    const response = yield call(API.addVehicleModel, action.payload);
    yield put({ type: CREATE_VEHICLE_MODEL_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_VEHICLE_MODEL_FAIL, payload: { error: e } });
  }
}

function* updateVehicleModel(action) {
  try {
    const response = yield call(API.updateVehicleModel, action.payload);
    yield put({ type: UPDATE_VEHICLE_MODEL_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_VEHICLE_MODEL_FAIL, payload: { error: e } });
  }
}

function* deleteVehicleModel(action) {
  try {
    const response = yield call(API.deleteVehicleModel, action.payload);
    yield put({ type: DELETE_VEHICLE_MODEL_SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_VEHICLE_MODEL_FAIL, payload: { error: e } });
  }
}

function* vehicleModel() {
  yield takeLatest(FETCH_VEHICLE_MODEL, fetchVehicleModel);
  yield takeLatest(GET_VEHICLE_MODEL, getVehicleModelById);
  yield takeLatest(CREATE_VEHICLE_MODEL, createVehicleModel);
  yield takeLatest(UPDATE_VEHICLE_MODEL, updateVehicleModel);
  yield takeLatest(DELETE_VEHICLE_MODEL, deleteVehicleModel);
}

export default vehicleModel;
