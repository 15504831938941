import { post, get, patch, deleteRequest } from 'utils/api';

export const addReserveSlot = (data) => {
  return post(`/reserve_slot`, data);
};

export const getReserveSlots = (data) => {
  return get(`/reserve_slot`, data);
};

export const updateReserveSlots = (data) => {
  const id = data.id;
  const { charger, startDate, endDate, timeTo, timeFrom } = data.data[0];
  const dataObj = { charger, startDate, endDate, timeTo, timeFrom };
  return patch(`/reserve_slot/${id}`, dataObj);
};

export const deleteYourReserveSlot = (id) => {
  return deleteRequest(`/reserve_slot/${id}`);
};

export const reserveSlot = {
  addReserveSlot,
  getReserveSlots,
  updateReserveSlots,
  deleteYourReserveSlot,
};
