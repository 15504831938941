import {
    CREATE_SCHEDULE,
    CREATE_SCHEDULE_SUCCESS,
    CREATE_SCHEDULE_FAIL,
    FETCH_SCHEDULE,
    FETCH_SCHEDULE_SUCCESS,
    FETCH_SCHEDULE_FAIL,
    GET_SCHEDULE,
    GET_SCHEDULE_SUCCESS,
    GET_SCHEDULE_FAIL,
    DELETE_SCHEDULE,
    DELETE_SCHEDULE_SUCCESS,
    DELETE_SCHEDULE_FAIL,
    UPDATE_SCHEDULE,
    UPDATE_SCHEDULE_FAIL,
    UPDATE_SCHEDULE_SUCCESS,
  } from 'actions/schedule';
  import * as _ from 'lodash';
  import { toast } from 'react-toastify';
  
  const initialState = {
    isLoading: false,
    schedule: [],
    scheduleDetail: {},
    page: 1,
    limit: 10,
    totalPages: 1,
    total: 1,
  };
  
  const scheduleReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case FETCH_SCHEDULE:
      case CREATE_SCHEDULE:
      case GET_SCHEDULE:
      case DELETE_SCHEDULE: {
        return { ...state, isLoading: true, scheduleDetail: {} };
      }
  
      case UPDATE_SCHEDULE: {
        return { ...state, isLoading: true };
      }
  
      case FETCH_SCHEDULE_SUCCESS: {
        if (payload.page === 1) {
          return {
            ...state,
            isLoading: false,
            schedule: payload.results,
            page: payload.page,
            limit: payload.limit,
            totalPages: payload.totalPages,
            total: payload.totalResults,
          };
        } else {
          return {
            ...state,
            isLoading: false,
            // schedule: _.concat(state.schedule, payload.results),
            schedule: payload.results,
            page: payload.page,
            limit: payload.limit,
            totalPages: payload.totalPages,
            total: payload.totalResults,
          };
        }
      }
  
      case GET_SCHEDULE_SUCCESS: {
        return {
          ...state,
          scheduleDetail: payload,
          isLoading: false,
        };
      }
  
      case CREATE_SCHEDULE_SUCCESS: {
        toast.success(' Schedule creaded successfully');
        return {
          ...state,
          schedule: _.concat(state.schedule, payload),
          isLoading: false,
        };
      }
  
      case UPDATE_SCHEDULE_SUCCESS: {
        toast.success('Schedule updated successfully');
        console.log(state.schedule);
        return {
          ...state,
          isLoading: false,
          // schedule: state.schedule.filter((data) => data.id === payload.scheduleId ? payload : data),
          schedule: state.schedule.map((data) => (data.id === payload.id ? payload : data)),
        };
      }
  
      case DELETE_SCHEDULE_SUCCESS: {
        toast.success(' Schedule deleted successfully');
        return {
          ...state,
          isLoading: false,
          schedule: state.schedule.filter((data) => data.id !== payload),
        };
      }
  
      case FETCH_SCHEDULE_FAIL:
      case CREATE_SCHEDULE_FAIL:
      case GET_SCHEDULE_FAIL:
      case DELETE_SCHEDULE_FAIL:
      case UPDATE_SCHEDULE_FAIL: {
        return { ...state, isLoading: false };
      }
      default:
        return { ...state };
    }
  };
  export default scheduleReducer;
  