import { FETCH_CUSTOMER_PROFILE, FETCH_CUSTOMER_PROFILE_SUCCESS, FETCH_CUSTOMER_PROFILE_FAIL } from 'actions/customer';

const initialState = {
  isLoading: false,
  customerProfile: {},
};
const customerProfileReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_CUSTOMER_PROFILE: {
      return { ...state, isLoading: true };
    }

    case FETCH_CUSTOMER_PROFILE_SUCCESS: {
      return {
        ...state,
        customerProfile: payload,
        isLoading: false,
      };
    }

    case FETCH_CUSTOMER_PROFILE_FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return { ...state };
  }
};
export default customerProfileReducer;
