import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import {
  CREATE_COMMUNICATION_MANAGER,
  CREATE_COMMUNICATION_MANAGER_SUCCESS,
  CREATE_COMMUNICATION_MANAGER_FAIL,
  FETCH_COMMUNICATION_MANAGER,
  FETCH_COMMUNICATION_MANAGER_SUCCESS,
  FETCH_COMMUNICATION_MANAGER_FAIL,
  DELETE_COMMUNICATION_MANAGER,
  DELETE_COMMUNICATION_MANAGER_SUCCESS,
  DELETE_COMMUNICATION_MANAGER_FAIL,
  UPDATE_COMMUNICATION_MANAGER,
  UPDATE_COMMUNICATION_MANAGER_SUCCESS,
  UPDATE_COMMUNICATION_MANAGER_FAIL,
} from 'actions/communicationManager';

function* fetchCommunicationManager(action) {
  try {
    const response = yield call(API.fetchCommunicationManager, action.payload);
    yield put({ type: FETCH_COMMUNICATION_MANAGER_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_COMMUNICATION_MANAGER_FAIL, payload: { error: e } });
  }
}

function* createCommunicationManager(action) {
  try {
    const response = yield call(API.createCommunicationManager, action.payload);
    yield put({ type: CREATE_COMMUNICATION_MANAGER_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_COMMUNICATION_MANAGER_FAIL, payload: { error: e } });
  }
}

function* updateCommunicationManager(action) {
  try {
    const response = yield call(API.updateCommunicationManager, action.payload);
    yield put({ type: UPDATE_COMMUNICATION_MANAGER_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_COMMUNICATION_MANAGER_FAIL, payload: { error: e } });
  }
}


function* deleteYourCommunicationManager(action) {
  try {
    const response = yield call(API.deleteYourCommunicationManager, action.payload);
    yield put({ type: DELETE_COMMUNICATION_MANAGER_SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_COMMUNICATION_MANAGER_FAIL, payload: { error: e } });
  }
}

function* communicationManager() {
  yield takeLatest(FETCH_COMMUNICATION_MANAGER, fetchCommunicationManager);
  yield takeLatest(CREATE_COMMUNICATION_MANAGER, createCommunicationManager);
  yield takeLatest(DELETE_COMMUNICATION_MANAGER, deleteYourCommunicationManager);
  yield takeLatest(UPDATE_COMMUNICATION_MANAGER, updateCommunicationManager);
}

export default communicationManager;
