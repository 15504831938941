import React, { useEffect, useState, useCallback, useContext } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
import * as _ from 'lodash';
import { BiSort } from 'react-icons/bi';
import { roundOfDigit } from 'components/common/utils';
import SearchBox from 'components/general/SearchBox';
import { RATING_REVIEW_SUMMARY, DOWNLOAD_RATING_REVIEW_SUMMARY } from 'actions/rating';
import { useSelector, useDispatch } from 'react-redux';

import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import Card from 'components/inputs/Card';
import Button from 'components/inputs/Button';
import { IoSearchOutline } from 'react-icons/io5';
import { BsDownload } from 'react-icons/bs';
// import fileDownload from 'js-file-download';
import ReactPagination from 'components/general/ReactPagination';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Footer from 'components/general/Footer';
import ReactStars from 'react-rating-stars-component';
import { BsStar, BsStarFill, BsStarHalf } from 'react-icons/bs';
import fileDownload from 'js-file-download';
import InformationBlock from 'components/general/InformationBlock';
import { NavContext } from 'components/privateRoute/PrivateRoute';

const Rating = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { navigateTo } = useContext(NavContext);
  const [searchText, setSearchText] = useState('');
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [showSearchBar, setShowSearchBar] = useState(false);

  const ratingReviewList = useSelector((state) => state.rating.rating);
  const isLoader = useSelector((state) => state.rating.isLoading);
  const page = useSelector((state) => state.rating.page);
  const totalData = useSelector((state) => state.rating.total);
  const limit = useSelector((state) => state.rating.limit);
  const totalPages = useSelector((state) => state.rating.totalPages);
  const isLoading = useSelector((state) => state.downloadingLoader.isLoading);
  const profileData = useSelector((state) => state.profile.userProfile);
  const averageRating = useSelector((state) => state.rating.avg_rating);
  const currentUserName = _.get(profileData, 'name', '');
  const userProfileStations = profileData.charging_stations;

  const ratingReview = useCallback(
    (value) => {
      if (searchText) {
        const ratingReviewData = {
          ...value,

          search: searchText,
        };
        dispatch({
          type: RATING_REVIEW_SUMMARY,
          payload: ratingReviewData,
        });
      } else {
        const data = {
          ...value,
        };
        dispatch({
          type: RATING_REVIEW_SUMMARY,
          payload: data,
        });
      }
    },
    [searchText]
  );

  const getSortByItem = useCallback((name, order, page, filterDateData) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
        charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
      };
      ratingReview(data);
    } else if (filterDateData) {
      const data = {
        ...filterDateData,
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
      };
      ratingReview(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
      };
      ratingReview(data);
    }
  }, []);

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const dateFilter = useCallback(() => {
    if (sortByItem.item && sortByItem.order) {
      const filterDateData = {
        charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
      };
      getSortByItem(sortByItem.item, sortByItem.order, '', filterDateData);
    } else if (!_.isEmpty(userProfileStations)) {
      const filterDateData = {
        charging_station: userProfileStations,
      };
      ratingReview(filterDateData);
    }
  }, [sortByItem, searchText]);

  const downloadRatingReview = useCallback(() => {
    const data = {
      excel: true,
    };
    dispatch({
      type: DOWNLOAD_RATING_REVIEW_SUMMARY,
      payload: data,
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            fileDownload(res.data, `${'Rating & Review'}.xlsx`);
          }
        }
      },
    });
  }, []);

  const downloadFile = () => {
    downloadRatingReview();
  };

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else if (!_.isEmpty(userProfileStations)) {
        const filterDateData = {
          charging_station: userProfileStations,
          page: page.selected + 1,
        };
        ratingReview(filterDateData);
      } else if (searchText) {
        const data = {
          page: page.selected + 1,
        };
        ratingReview(data);
      } else {
        const data = {
          page: page.selected + 1,
        };
        ratingReview(data);
      }
    },
    [sortByItem, searchText]
  );

  useEffect(() => {
    ratingReview();
  }, []);

  const searchHandler = (event) => {
    const value = event.target.value;
    setSearchText(value);
  };
  const handleSearch = () => {
    ratingReview({});
  };

  const search = (
    <SearchBox
      preIcon={<BsSearch />}
      value={searchText}
      onChange={searchHandler}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          handleSearch();
        }
      }}
    />
  );
  const searchIcon = <IoSearchOutline size={27} onClick={() => setShowSearchBar(true)} className="report-search-icon" />;

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.rating')} />
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
          <div className="page-content-wrapper scrollable">
            <div className="rating-review--main">
              <Card>
                <div className="rating-review__inner">
                  <div className="rating-review__box">
                    <Row className="rating-review__row">
                      <Col xl={'auto'} md={'auto'} className="rating-details--wrapper">
                        <InformationBlock
                          icon={isLoader ? <Skeleton circle="true" height={35} width={35} /> : roundOfDigit(averageRating, 3)}
                          infoTitle={
                            isLoader ? (
                              <Skeleton circle="true" height={35} width={100} />
                            ) : (
                              <ReactStars
                                edit={false}
                                a11y={true}
                                count={5}
                                value={averageRating}
                                size={15}
                                isHalf={true}
                                emptyIcon={<BsStar />}
                                halfIcon={<BsStarHalf />}
                                filledIcon={<BsStarFill />}
                                activeColor="#BE210B"
                                color="#BE210B"
                              />
                            )
                          }
                          infoText={isLoader ? <Skeleton circle="true" height={35} width={100} /> : t('ratingReview.averageRating')}
                        />
                      </Col>
                      <Col
                        xl={`${showSearchBar ? 'auto' : 'auto'}`}
                        md={`${showSearchBar ? 'auto' : 'auto'}`}
                        className={`${showSearchBar ? '' : 'rating-review-search-box'}`}
                      >
                        {showSearchBar ? search : searchIcon}
                      </Col>

                      <Col xl={'auto'} md={'auto'}>
                        <div className="report-search-box" onClick={dateFilter}>
                          <Button className="report-search-button">{t('button.search')}</Button>
                        </div>
                      </Col>

                      <Col xl={'auto'} md={'auto'} className="excel-icon--block" onClick={downloadFile}>
                        {isLoading ? (
                          <div className="loader--block">
                            <Spinner as="span" animation="border" size="sm" role="status" />
                          </div>
                        ) : (
                          <div className="report-excel-icon">
                            <BsDownload title="Download" size={28} color={'#3c7cdd'} />
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                  <div className="rating-review__table">
                    <div className="table-responsive">
                      <table className="record-list-table" id="table-to-xls">
                        <thead>
                          <tr>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('ratingReview.srNo')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('ratingReview.stationName')}</span>
                                <span className="ico" onClick={() => handleSorting('charging_station.name', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : 'Average Rating'}</span>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoader ? (
                            <tr>
                              <td colSpan={12} className="border-0">
                                <div className="loader--block">
                                  <Skeleton height={15} width={100} />
                                </div>
                              </td>
                            </tr>
                          ) : _.isEmpty(ratingReviewList) ? (
                            <tr>
                              <td colSpan={10} className="border-0">
                                <div className="empty-data-block">{t('ratingReview.noHistoryFound')}</div>
                              </td>
                            </tr>
                          ) : (
                            !(isLoader || _.isUndefined(isLoader)) &&
                            _.map(
                              _.filter(ratingReviewList, (item) => item !== null),
                              (item, key) => {
                                const serial_num = limit * (page - 1) + key;
                                return (
                                  <>
                                    <tr key={`rating-review-${serial_num}`}>
                                      <td>{isLoader ? <Skeleton height={15} width={100} /> : serial_num + 1}</td>
                                      <td
                                        className="station-link"
                                        onClick={() => navigateTo(`/ratingDetails/${_.get(item, 'id', '')}`, { backTo: 'ratingReview' })}
                                      >
                                        {isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'name', '-')}
                                      </td>
                                      <td>
                                        {isLoader ? (
                                          <Skeleton height={15} width={100} />
                                        ) : (
                                          <ReactStars
                                            edit={false}
                                            a11y={true}
                                            count={5}
                                            value={item.avg_rating}
                                            size={12}
                                            isHalf={true}
                                            emptyIcon={<BsStar />}
                                            halfIcon={<BsStarHalf />}
                                            filledIcon={<BsStarFill />}
                                            activeColor="#BE210B"
                                            color="#BE210B"
                                          />
                                        )}
                                      </td>
                                    </tr>
                                  </>
                                );
                              }
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(ratingReviewList) && (
                    <ReactPagination
                      currentPage={page}
                      limit={limit}
                      total={totalData}
                      handlePageClick={handlePageClick}
                      totalPages={totalPages}
                      marginPagesDisplayed={1}
                    />
                  )}
                </div>
              </Card>
            </div>
          </div>
        </SkeletonTheme>
        {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
      </LayoutContainer>
    </>
  );
};

export default Rating;
