import {
  FETCH_SMS,
  FETCH_SMS_SUCCESS,
  FETCH_SMS_FAIL,
  GET_SMS,
  GET_SMS_SUCCESS,
  GET_SMS_FAIL,
  CREATE_SMS,
  CREATE_SMS_SUCCESS,
  CREATE_SMS_FAIL,
  UPDATE_SMS,
  UPDATE_SMS_SUCCESS,
  UPDATE_SMS_FAIL,
  DELETE_SMS,
  DELETE_SMS_SUCCESS,
  DELETE_SMS_FAIL,
} from 'actions/sms';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';

function* fetchSms(action) {
  try {
    const response = yield call(API.getSms, action.payload);
    yield put({ type: FETCH_SMS_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_SMS_FAIL, payload: { error: e } });
  }
}

function* getSmsById(action) {
  try {
    const response = yield call(API.getSmsById, action.payload);
    yield put({ type: GET_SMS_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_SMS_FAIL, payload: { error: e } });
  }
}

function* createSms(action) {
  try {
    const response = yield call(API.addSms, action.payload);
    yield put({ type: CREATE_SMS_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_SMS_FAIL, payload: { error: e } });
  }
}

function* updateSms(action) {
  try {
    const response = yield call(API.updateSms, action.payload);
    yield put({ type: UPDATE_SMS_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_SMS_FAIL, payload: { error: e } });
  }
}

function* deleteSms(action) {
  try {
    const response = yield call(API.deleteSms, action.payload);
    yield put({ type: DELETE_SMS_SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_SMS_FAIL, payload: { error: e } });
  }
}

function* sms() {
  yield takeLatest(FETCH_SMS, fetchSms);
  yield takeLatest(GET_SMS, getSmsById);
  yield takeLatest(CREATE_SMS, createSms);
  yield takeLatest(UPDATE_SMS, updateSms);
  yield takeLatest(DELETE_SMS, deleteSms);
}
export default sms;
