export const CREATE_CHARGING_STATION = 'CREATE_CHARGING_STATION';
export const CREATE_CHARGING_STATION_SUCCESS = 'CREATE_CHARGING_STATION_SUCCESS';
export const CREATE_CHARGING_STATION_FAIL = 'CREATE_CHARGING_STATION_FAIL';

export const FETCH_CHARGING_STATION = 'FETCH_CHARGING_STATION';
export const FETCH_CHARGING_STATION_SUCCESS = 'FETCH_CHARGING_STATION_SUCCESS';
export const FETCH_CHARGING_STATION_FAIL = 'FETCH_CHARGING_STATION_FAIL';

export const GET_CHARGING_STATION = 'GET_CHARGING_STATION';
export const GET_CHARGING_STATION_SUCCESS = 'GET_CHARGING_STATION_SUCCESS';
export const GET_CHARGING_STATION_FAIL = 'GET_CHARGING_STATION_FAIL';

export const DELETE_CHARGING_STATION = 'DELETE_CHARGING_STATION';
export const DELETE_CHARGING_STATION_SUCCESS = 'DELETE_CHARGING_STATION_SUCCESS';
export const DELETE_CHARGING_STATION_FAIL = 'DELETE_CHARGING_STATION_FAIL';

export const UPDATE_CHARGING_STATION = 'UPDATE_CHARGING_STATION';
export const UPDATE_CHARGING_STATION_SUCCESS = 'UPDATE_CHARGING_STATION_SUCCESS';
export const UPDATE_CHARGING_STATION_FAIL = 'UPDATE_CHARGING_STATION_FAIL';

export const CHANGE_STATION_STATUS = 'CHANGE_STATION_STATUS';
export const CHANGE_STATION_STATUS_SUCCESS = 'CHANGE_STATION_STATUS_SUCCESS';
export const CHANGE_STATION_STATUS_FAIL = 'CHANGE_STATION_STATUS_FAIL';

export const FETCH_REVIEWS = 'FETCH_REVIEWS';
export const FETCH_REVIEWS_SUCCESS = 'FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_FAIL = 'FETCH_REVIEWS_FAIL';

export const UPDATE_CONTACT_PERSON = 'UPDATE_CONTACT_PERSON';
export const UPDATE_CONTACT_PERSON_SUCCESS = 'UPDATE_CONTACT_PERSON_SUCCESS';
export const UPDATE_CONTACT_PERSON_FAIL = 'UPDATE_CONTACT_PERSON_FAIL';

export const GET_STATION_BY_CITY = 'GET_STATION_BY_CITY';
export const GET_STATION_BY_CITY_SUCCESS = 'GET_STATION_BY_CITY_SUCCESS';
export const GET_STATION_BY_CITY_FAIL = 'GET_STATION_BY_CITY_FAIL';

export const GET_STATION_WIDGETS = 'GET_STATION_WIDGETS';
export const GET_STATION_WIDGETS_SUCCESS = 'GET_STATION_WIDGETS_SUCCESS';
export const GET_STATION_WIDGETS_FAIL = 'GET_STATION_WIDGETS_FAIL';

export const GET_CONSUMPTION = 'GET_CONSUMPTION';
export const GET_CONSUMPTION_SUCCESS = 'GET_CONSUMPTION_SUCCESS';
export const GET_CONSUMPTION_FAIL = 'GET_CONSUMPTION_FAIL';

export const GET_CHARGER_STATION = 'GET_CHARGER_STATION';
export const GET_CHARGER_STATION_SUCCESS = 'GET_CHARGER_STATION_SUCCESS';
export const GET_CHARGER_STATION_FAIL = 'GET_CHARGER_STATION_FAIL';
