import React, { useCallback } from 'react';
import Card from 'components/inputs/Card';
import { Formik, Form } from 'formik';
import { Row, Col } from 'react-bootstrap';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import * as Yup from 'yup';
import { DATA_TRANSFER_SERVER } from 'actions/chargerOcpp';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { GET_STATION_CHARGER_OPERATIONS, MANAGE_STATION_CHARGER_OPERATIONS } from 'components/common/constant';

const DataTransferServerForm = () => {
  const { chargerId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const rolePermissionData = useSelector((state) => state.manageRole.manageRoles);

  const getDataTransferServer = useCallback((data, resetForm) => {
    dispatch({
      type: DATA_TRANSFER_SERVER,
      payload: { data },
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            toast.success('Data transfer server successfully');
            resetForm();
          }
        }
      },
    });
  }, []);

  const getStationChargerOperations =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === GET_STATION_CHARGER_OPERATIONS),
      (data) => data.name
    );

  const manageStationChargerOperations =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === MANAGE_STATION_CHARGER_OPERATIONS),
      (data) => data.name
    );

  const DataTransferServerSchema = Yup.object().shape({
    vendorId: Yup.string()
      .required(t('dataTransferServerForm.vendorId'))
      .trim(t('dataTransferServerForm.space'))
      .strict(true)
      .max(100, t('dataTransferServerForm.maxlimit')),
    messageId: Yup.string()
      .required(t('dataTransferServerForm.messageId'))
      .trim(t('dataTransferServerForm.space'))
      .strict(true)
      .max(100, t('dataTransferServerForm.maxlimit')),
    data: Yup.string()
      .required(t('dataTransferServerForm.data'))
      .trim(t('dataTransferServerForm.space'))
      .strict(true)
      .max(100, t('dataTransferServerForm.maxlimit')),
  });

  const initialValues = {
    vendorId: '',
    messageId: '',
    data: '',
  };

  return (
    <React.Fragment>
      <div className="serverOperation-title-name">{t('dataTransferServerForm.formTitle')}</div>
      <div className="serverOperation-content-wrapper">
        <Card className="serverOperation-form-main">
          <div className="serverOperation-form__block">
            <Formik
              initialValues={initialValues}
              validationSchema={DataTransferServerSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                const formData = {
                  ...values,
                  id: chargerId,
                };
                getDataTransferServer(formData, resetForm);
                setSubmitting(false);
              }}
            >
              {({ values, errors, touched, handleSubmit, handleChange, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="serverOperation-form__inner">
                    <Row>
                      <Col md={6}>
                        <TextInput
                          isRequired
                          label={t('dataTransferServerForm.vendorLabel')}
                          placeholder={t('dataTransferServerForm.vendorLabel')}
                          value={values.vendorId}
                          name="vendorId"
                          onChange={handleChange}
                          error={errors.vendorId && touched.vendorId ? errors.vendorId : null}
                        />
                      </Col>
                      <Col md={6}>
                        <TextInput
                          isRequired
                          label={t('dataTransferServerForm.messageLabel')}
                          placeholder={t('dataTransferServerForm.messageLabel')}
                          value={values.messageId}
                          name="messageId"
                          onChange={handleChange}
                          error={errors.messageId && touched.messageId ? errors.messageId : null}
                        />
                      </Col>
                      <Col md={6}>
                        <TextInput
                          isRequired
                          label={t('dataTransferServerForm.dataLabel')}
                          placeholder={t('dataTransferServerForm.dataLabel')}
                          type="name"
                          value={values.data}
                          name="data"
                          onChange={handleChange}
                          error={errors.data && touched.data ? errors.data : null}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="serverOperation-save--btn--block">
                    {!_.isEmpty(manageStationChargerOperations) ||
                    (!_.isEmpty(getStationChargerOperations) && !_.isEmpty(manageStationChargerOperations)) ? (
                      <Button type="submit" className="serverOperation-save-btn save--btn" disabled={isSubmitting}>
                        {t('dataTransferServerForm.button')}
                      </Button>
                    ) : (
                      <Button className="serverOperation-save-btn save--btn">{t('dataTransferServerForm.button')}</Button>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};
export default DataTransferServerForm;
