export const FETCH_CHARGER_BOOKING = 'FETCH_CHARGER_BOOKING';
export const FETCH_CHARGER_BOOKING_SUCCESS = 'FETCH_CHARGER_BOOKING_SUCCESS';
export const FETCH_CHARGER_BOOKING_FAIL = 'FETCH_CHARGER_BOOKING_FAIL';

export const DELETE_CHARGER_BOOKING = 'DELETE_CHARGER_BOOKING';
export const DELETE_CHARGER_BOOKING_SUCCESS = 'DELETE_CHARGER_BOOKING_SUCCESS';
export const DELETE_CHARGER_BOOKING_FAIL = 'DELETE_CHARGER_BOOKING_FAIL';

export const START_REMOTE = 'START_REMOTE';
export const START_REMOTE_SUCCESS = 'START_REMOTE_SUCCESS';
export const START_REMOTE_FAIL = 'START_REMOTE_FAIL';

export const STOP_TRANSACTION = 'STOP_TRANSACTION';
export const STOP_TRANSACTION_SUCCESS = 'STOP_TRANSACTION_SUCCESS';
export const STOP_TRANSACTION_FAIL = 'STOP_TRANSACTION_FAIL';

export const GET_CHARGER_BOOKING = 'GET_CHARGER_BOOKING';
export const GET_CHARGER_BOOKING_SUCCESS = 'GET_CHARGER_BOOKING_SUCCESS';
export const GET_CHARGER_BOOKING_FAIL = 'GET_CHARGER_BOOKING_FAIL';
