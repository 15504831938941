import { MOST_VEHICLE_CHARGED_LIST, MOST_VEHICLE_CHARGED_LIST_SUCCESS, MOST_VEHICLE_CHARGED_LIST_FAIL } from 'actions/dataReport';

const initialState = {
  isLoading: false,
  mostVehicleChargedLists: [],
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const mostVehicleChargedListReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case MOST_VEHICLE_CHARGED_LIST: {
      return { ...state, isLoading: true };
    }

    case MOST_VEHICLE_CHARGED_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        mostVehicleChargedLists: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case MOST_VEHICLE_CHARGED_LIST_FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};
export default mostVehicleChargedListReducer;
