import {
  FETCH_MASTER_SETTING,
  FETCH_MASTER_SETTING_SUCCESS,
  FETCH_MASTER_SETTING_FAIL,
  GET_MASTER_SETTING,
  UPDATE_MATER_SETTING,
  GET_MASTER_SETTING_FAIL,
  UPDATE_MATER_SETTING_FAIL,
  GET_MASTER_SETTING_SUCCESS,
  UPDATE_MATER_SETTING_SUCCESS,
} from 'actions/masterSetting';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  masterSettings: [],
  masterSettingDetail: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const masterSettingReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_MASTER_SETTING:
    case GET_MASTER_SETTING:
    case UPDATE_MATER_SETTING: {
      return { ...state, isLoading: true };
    }

    case FETCH_MASTER_SETTING_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        masterSettings: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case GET_MASTER_SETTING_SUCCESS: {
      return {
        ...state,
        masterSettingDetail: payload,
        isLoading: false,
      };
    }

    case UPDATE_MATER_SETTING_SUCCESS: {
      toast.success('masterSettings updated successfully');
      return {
        ...state,
        isLoading: false,
        masterSettings: state.masterSettings.map((data) => (data.id === payload.id ? payload : data)),
      };
    }

    case FETCH_MASTER_SETTING_FAIL:
    case GET_MASTER_SETTING_FAIL:
    case UPDATE_MATER_SETTING_FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return {
        ...state,
      };
  }
};
export default masterSettingReducer;
