import React from 'react';
import PropTypes from 'prop-types';
import Sidebar from 'components/sidebar/index';

export const SidebarContext = React.createContext({});

const MainLayout = (props) => {
  return (
    <React.Fragment>
      <div className="layout-container main-layout-wrapper">
        <Sidebar />
        <SidebarContext.Provider value={{ showToggle: props.showToggle }}>
          <div className="content-layout-wrapper__right">{props.children}</div>
        </SidebarContext.Provider>
      </div>
    </React.Fragment>
  );
};

MainLayout.propTypes = {
  showToggle: PropTypes.bool,
  children: PropTypes.node,
};

export default MainLayout;
