import React, { useEffect, useCallback, useContext } from 'react';
import Card from 'components/inputs/Card';
import { useLocation } from 'react-router-dom';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header/index';
// import DummyImage from 'assets/images/icons/dummyImage.png';
// import Avatar from 'components/inputs/Avatar';
import Button from 'components/inputs/Button';
import Profile from './Profile';
import { useDispatch, useSelector } from 'react-redux';
import ChangePassword from './ChangePassword';
// import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { GET_PROFILE } from 'actions/profile';
import _ from 'lodash';
import { GET_CHANGE_PASSWORD, MANAGE_CHANGE_PASSWORD, MANAGE_MY_PROFILE } from 'components/common/constant';
import { Spinner } from 'react-bootstrap';
// import ProfilePicInput from 'components/inputs/ProfilePicInput';
import { useTranslation } from 'react-i18next';
import Footer from 'components/general/Footer';
import { NavContext } from 'components/privateRoute/PrivateRoute';

const Setting = () => {
  // const history = useHistory();
  const { navigateTo } = useContext(NavContext);
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const profileDetail = useSelector((state) => state.profile.userProfile);
  const isLoader = useSelector((state) => state.profile.isLoading);
  const rolePermissionData = useSelector((state) => state.manageRole.manageRoles);
  const currentUserName = _.get(profileDetail, 'name', '');

  const getProfile = useCallback(() => {
    dispatch({ type: GET_PROFILE });
  }, []);

  useEffect(() => {
    getProfile();
  }, []);

  const { tab } = queryString.parse(location.search);

  const setTab = (e, tab) => {
    e.preventDefault();
    navigateTo(`/setting?tab=${tab}`);
  };

  useEffect(() => {
    if (!tab) {
      navigateTo('/setting?tab=profile');
    }
  }, [tab]);

  const changePasswordPermissions =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === GET_CHANGE_PASSWORD || item.name === MANAGE_CHANGE_PASSWORD),
      (data) => data.name
    );

  const manageProfile =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === MANAGE_MY_PROFILE),
      (data) => data.name
    );

  return (
    <React.Fragment>
      <LayoutContainer className="layout-container-main-page">
        <AdminHeader title={t('header.settings')} />
        <div className="page-content-wrapper scrollable">
          {isLoader ? (
            <div className="setting-page__main">
              <div className="loader--block">
                <Spinner as="span" animation="border" size="lg" role="status" />
                <span className="ps-2">{t('placeHolder.loading')}</span>
              </div>
            </div>
          ) : (
            <div className="setting-page__main">
              <Card>
                <div className="page--info">
                  {/* <div className="page-avatar"> */}
                  {/* <Avatar imgSrc={DummyImage} name="userProfile" /> */}
                  <img src={profileDetail.picture} alt="" style={{ width: '85px', height: '85px', objectFit: 'cover' }} />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  {/* </div> */}
                  <div className="user-content">
                    <span className="user-name">{_.get(profileDetail, 'name', '')}</span>
                    <span className="user-info">{_.startCase(_.get(profileDetail, 'role', ''))}</span>
                  </div>
                </div>
                <div>
                  <div className="tab-block">
                    <div className="tab-nav-block">
                      <a
                        href="#"
                        className={`tab-nav__item setting-font ${tab === 'profile' ? 'active' : ''}`}
                        onClick={(e) => setTab(e, 'profile')}
                        data-content="tab-profile"
                      >
                        {t('profile.profile')}
                      </a>
                      {!_.isEmpty(changePasswordPermissions) && (
                        <a
                          href="#"
                          className={`tab-nav__item setting-font ${tab === 'changePassword' ? 'active' : ''}`}
                          onClick={(e) => setTab(e, 'changePassword')}
                          data-content="tab-changePassword"
                        >
                          {t('profile.changePassword')}
                        </a>
                      )}
                      {tab === 'profile' && !_.isEmpty(manageProfile) && (
                        <div className="tab-nav-block__right">
                          <Button onClick={() => navigateTo('/setting/profile')}>{t('button.editProfile')}</Button>
                        </div>
                      )}
                    </div>
                    <div className="tab-content-block">
                      <div className="tab-content__item active" id="setting-tab">
                        {tab === 'profile' && <Profile />}
                        {!_.isEmpty(changePasswordPermissions) && tab === 'changePassword' && <ChangePassword />}
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          )}
          {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
        </div>
      </LayoutContainer>
    </React.Fragment>
  );
};
export default Setting;
