import {
  FETCH_RFID,
  FETCH_RFID_SUCCESS,
  FETCH_RFID_FAIL,
  GET_RFID,
  GET_RFID_SUCCESS,
  GET_RFID_FAIL,
  CREATE_RFID,
  CREATE_RFID_SUCCESS,
  CREATE_RFID_FAIL,
  UPDATE_RFID,
  UPDATE_RFID_FAIL,
  UPDATE_RFID_SUCCESS,
  DELETE_RFID,
  DELETE_RFID_SUCCESS,
  DELETE_RFID_FAIL,
  CREATE_BULK_RFID,
  CREATE_BULK_RFID_SUCCESS,
  CREATE_BULK_RFID_FAIL,
  CREATE_BULK_RFID_FOR_FLEET,
  CREATE_BULK_RFID_FOR_FLEET_SUCCESS,
  CREATE_BULK_RFID_FOR_FLEET_FAIL,
  FETCH_RFID_FOR_FLEET,
  FETCH_RFID_FOR_FLEET_SUCCESS,
  FETCH_RFID_FOR_FLEET_FAIL,
  UPDATE_VEHICLE_RFID,
  UPDATE_VEHICLE_RFID_SUCCESS,
  UPDATE_VEHICLE_RFID_FAIL,
  DELETE_RFID_BY_FLEET,
  DELETE_RFID_BY_FLEET_SUCCESS,
  DELETE_RFID_BY_FLEET_FAIL,
} from 'actions/rfid';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';

function* fetchRfid(action) {
  try {
    const response = yield call(API.getRfid, action.payload);
    yield put({ type: FETCH_RFID_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_RFID_FAIL, payload: { error: e } });
  }
}

function* getRfidById(action) {
  try {
    const response = yield call(API.getRfidById, action.payload);
    yield put({ type: GET_RFID_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_RFID_FAIL, payload: { error: e } });
  }
}

function* createRfid(action) {
  try {
    const response = yield call(API.addRfid, action.payload);
    yield put({ type: CREATE_RFID_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_RFID_FAIL, payload: { error: e } });
  }
}

function* updateRfid(action) {
  try {
    const response = yield call(API.updateRfid, action.payload);
    yield put({ type: UPDATE_RFID_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_RFID_FAIL, payload: { error: e } });
  }
}

function* deleteRfid(action) {
  try {
    const response = yield call(API.deleteRfid, action.payload);
    yield put({ type: DELETE_RFID_SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_RFID_FAIL, payload: { error: e } });
  }
}

function* createBulkRfid(action) {
  try {
    const response = yield call(API.addBulkRfid, action.payload);
    yield put({ type: CREATE_BULK_RFID_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_BULK_RFID_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* fetchRfidForFleet(action) {
  try {
    const response = yield call(API.getRfidForFleet, action.payload);
    yield put({ type: FETCH_RFID_FOR_FLEET_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_RFID_FOR_FLEET_FAIL, payload: { error: e } });
  }
}

function* createBulkRfidForFleet(action) {
  try {
    const response = yield call(API.addBulkRfidForFleet, action.payload);
    yield put({ type: CREATE_BULK_RFID_FOR_FLEET_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_BULK_RFID_FOR_FLEET_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* updateVehicleRfid(action) {
  try {
    const response = yield call(API.updateVehicleRfid, action.payload);
    yield put({ type: UPDATE_VEHICLE_RFID_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_VEHICLE_RFID_FAIL, payload: { error: e } });
  }
}

function* deleteRfidByFleet(action) {
  try {
    const response = yield call(API.deleteRfidByFleet, action.payload);
    yield put({ type: DELETE_RFID_BY_FLEET_SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_RFID_BY_FLEET_FAIL, payload: { error: e } });
  }
}

function* rfid() {
  yield takeLatest(FETCH_RFID, fetchRfid);
  yield takeLatest(GET_RFID, getRfidById);
  yield takeLatest(CREATE_RFID, createRfid);
  yield takeLatest(UPDATE_RFID, updateRfid);
  yield takeLatest(DELETE_RFID, deleteRfid);
  yield takeLatest(CREATE_BULK_RFID, createBulkRfid);
  yield takeLatest(FETCH_RFID_FOR_FLEET, fetchRfidForFleet);
  yield takeLatest(CREATE_BULK_RFID_FOR_FLEET, createBulkRfidForFleet);
  yield takeLatest(UPDATE_VEHICLE_RFID, updateVehicleRfid);
  yield takeLatest(DELETE_RFID_BY_FLEET, deleteRfidByFleet);
}
export default rfid;
