export const FETCH_CHARGE_COIN = 'FETCH_CHARGE_COIN';
export const FETCH_CHARGE_COIN_SUCCESS = 'FETCH_CHARGE_COIN_SUCCESS';
export const FETCH_CHARGE_COIN_FAIL = 'FETCH_CHARGE_COIN_FAIL';

export const UPDATE_CHARGE_COIN = 'UPDATE_CHARGE_COIN';
export const UPDATE_CHARGE_COIN_SUCCESS = 'UPDATE_CHARGE_COIN_SUCCESS';
export const UPDATE_CHARGE_COIN_FAIL = 'UPDATE_CHARGE_COIN_FAIL';

export const UPDATE_CHARGE_COIN_LEVEL = 'UPDATE_CHARGE_COIN_LEVEL';
export const UPDATE_CHARGE_COIN_LEVEL_SUCCESS = 'UPDATE_CHARGE_COIN_LEVEL_SUCCESS';
export const UPDATE_CHARGE_COIN_LEVEL_FAIL = 'UPDATE_CHARGE_COIN_LEVEL_FAIL';

export const DELETE_CHARGE_COIN_LEVEL = 'DELETE_CHARGE_COIN_LEVEL';
export const DELETE_CHARGE_COIN_LEVEL_SUCCESS = 'DELETE_CHARGE_COIN_LEVEL_SUCCESS';
export const DELETE_CHARGE_COIN_LEVEL_FAIL = 'DELETE_CHARGE_COIN_LEVEL_FAIL';