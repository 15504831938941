import { get, post, patch, deleteRequest, downloadFile } from 'utils/api';

export const getOemErrorCode = (data) => {
  return get('/oem_code', data);
};

export const getOemErrorCodeById = (id) => {
  return get(`/oem_code/${id}`);
};

export const addOemErrorCode = (data) => {
  return post(`/oem_code`, data);
};

export const updateOemErrorCode = (data) => {
  const id = data.id;
  delete data['id'];
  return patch(`/oem_code/${id}`, data);
};

export const deleteOemErrorCode = (id) => {
  return deleteRequest(`/oem_code/${id}`);
};

export const getOcppError = () => {
  return get(`/ocpp_error_code`);
};

export const addBulkErrorCode = (data) => {
  return downloadFile(`/oem_code/bulk_insert`, data);
};

export const oemErrorCode = {
  getOcppError,
  getOemErrorCode,
  getOemErrorCodeById,
  addOemErrorCode,
  updateOemErrorCode,
  deleteOemErrorCode,
  addBulkErrorCode,
};
