export const FETCH_FAQ = 'FETCH_FAQ';
export const FETCH_FAQ_SUCCESS = 'FETCH_FAQ_SUCCESS';
export const FETCH_FAQ_FAIL = 'FETCH_FAQ_FAIL';

export const GET_FAQ = 'GET_FAQ';
export const GET_FAQ_SUCCESS = 'GET_FAQ_SUCCESS';
export const GET_FAQ_FAIL = 'GET_FAQ_FAIL';

export const CREATE_FAQ = 'CREATE_FAQ';
export const CREATE_FAQ_SUCCESS = 'CREATE_FAQ_SUCCESS';
export const CREATE_FAQ_FAIL = 'CREATE_FAQ_FAIL';

export const UPDATE_FAQ = 'UPDATE_FAQ';
export const UPDATE_FAQ_SUCCESS = 'UPDATE_FAQ_SUCCESS';
export const UPDATE_FAQ_FAIL = 'UPDATE_FAQ_FAIL';

export const DELETE_FAQ = 'DELETE_FAQ';
export const DELETE_FAQ_SUCCESS = 'DELETE_FAQ_SUCCESS';
export const DELETE_FAQ_FAIL = 'DELETE_FAQ_FAIL';
