export const FETCH_EMAIL = 'FETCH_EMAIL';
export const FETCH_EMAIL_SUCCESS = 'FETCH_EMAIL_SUCCESS';
export const FETCH_EMAIL_FAIL = 'FETCH_EMAIL_FAIL';

export const GET_EMAIL = 'GET_EMAIL';
export const GET_EMAIL_SUCCESS = 'GET_EMAIL_SUCCESS';
export const GET_EMAIL_FAIL = 'GET_EMAIL_FAIL';

export const CREATE_EMAIL = 'CREATE_EMAIL';
export const CREATE_EMAIL_SUCCESS = 'CREATE_EMAIL_SUCCESS';
export const CREATE_EMAIL_FAIL = 'CREATE_EMAIL_FAIL';

export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const UPDATE_EMAIL_SUCCESS = ' UPDATE_EMAIL_SUCCESS';
export const UPDATE_EMAIL_FAIL = 'UPDATE_EMAIL_FAIL';

export const DELETE_EMAIL = 'DELETE_EMAIL';
export const DELETE_EMAIL_SUCCESS = 'DELETE_EMAIL_SUCCESS';
export const DELETE_EMAIL_FAIL = ' DELETE_EMAIL_FAIL';
