import {
  FETCH_TAGS,
  FETCH_TAGS_SUCCESS,
  FETCH_TAGS_FAIL,
  CREATE_TAGS,
  CREATE_TAGS_SUCCESS,
  CREATE_TAGS_FAIL,
  DELETE_TAGS,
  DELETE_TAGS_SUCCESS,
  DELETE_TAGS_FAIL,
  UPDATE_TAGS,
  UPDATE_TAGS_FAIL,
  UPDATE_TAGS_SUCCESS,
  GET_TAG,
  GET_TAG_SUCCESS,
  GET_TAG_FAIL

} from 'actions/tagmanagement';
import { toast } from 'react-toastify';
import * as _ from 'lodash';

const initialState = {
  isLoading: false,
  tags: [],
  tagsDetail: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const tagmanagementreducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_TAGS:
    case CREATE_TAGS:
    case DELETE_TAGS:
    case GET_TAG:
      {
        return { ...state, isLoading: true, tagsDetail: {} };
      }

    case UPDATE_TAGS: {
      return { ...state, isLoading: true };
    }
    case FETCH_TAGS_SUCCESS: {
      // if (payload.page === 1) {
        return {
          ...state,
          isLoading: false,
          tags: payload.results,
          page: payload.page,
          limit: payload.limit,
          totalPages: payload.totalPages,
          total: payload.totalResults,
        };
      // } else {
      //   return {
      //     ...state,
      //     isLoading: false,
      //     tags: _.concat(state.countries, payload.results),
      //     page: payload.page,
      //     limit: payload.limit,
      //     totalPages: payload.totalPages,
      //     total: payload.totalResults,
      //   };
      // }
    }

    case GET_TAG_SUCCESS: {
      return {
        ...state,
        tagsDetails: payload,
        isLoading: false,
      }
    }
    case CREATE_TAGS_SUCCESS: {
      toast.success('Tag Added Successfully');
      return {
        ...state,
        isLoading: false,
        tags: _.concat(state.tags,payload),
      };
    }

    case UPDATE_TAGS_SUCCESS: {
      toast.success('Tag Updated Successfully');
      return {
        ...state,
        isLoading: false,
        tags: state.tags.map((data) =>  (data.id === payload.id ? payload : data)),
      };
    }

    case DELETE_TAGS_SUCCESS: {
      toast.success('Tag Deleted Successfully');
      return {
        ...state,
        isLoading:false,
        tags: state.tags.filter((data) => data.id !== payload),
      };
    }

    case FETCH_TAGS_FAIL:
    case CREATE_TAGS_FAIL:
    case DELETE_TAGS_FAIL:
    case UPDATE_TAGS_FAIL:
    case GET_TAG_FAIL: {
        return { ...state, isLoading: false };
      }

    default:
      return { ...state };
  }
};
export default tagmanagementreducer;
