import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import {
  CREATE_AMENITY,
  CREATE_AMENITY_FAIL,
  CREATE_AMENITY_SUCCESS,
  DELETE_AMENITY,
  DELETE_AMENITY_FAIL,
  DELETE_AMENITY_SUCCESS,
  FETCH_AMENITY,
  FETCH_AMENITY_FAIL,
  FETCH_AMENITY_SUCCESS,
  GET_AMENITY,
  GET_AMENITY_FAIL,
  GET_AMENITY_SUCCESS,
  UPDATE_AMENITY,
  UPDATE_AMENITY_FAIL,
  UPDATE_AMENITY_SUCCESS,
} from 'actions/amenity';

function* fetchAmenity(action) {
  try {
    const response = yield call(API.getAmenity, action.payload);
    yield put({ type: FETCH_AMENITY_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_AMENITY_FAIL, payload: { error: e } });
  }
}

function* getAmenityById(action) {
  try {
    const response = yield call(API.getAmenityById, action.payload);
    yield put({ type: GET_AMENITY_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_AMENITY_FAIL, payload: { error: e } });
  }
}

function* createAmenity(action) {
  try {
    const response = yield call(API.addAmenity, action.payload);
    yield put({ type: CREATE_AMENITY_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_AMENITY_FAIL, payload: { error: e } });
  }
}

function* updateAmenity(action) {
  try {
    const response = yield call(API.updateAmenity, action.payload);
    yield put({ type: UPDATE_AMENITY_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_AMENITY_FAIL, payload: { error: e } });
  }
}

function* deleteAmenity(action) {
  try {
    const response = yield call(API.deleteAmenity, action.payload);
    yield put({ type: DELETE_AMENITY_SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_AMENITY_FAIL, payload: { error: e } });
  }
}

function* amenity() {
  yield takeLatest(FETCH_AMENITY, fetchAmenity);
  yield takeLatest(GET_AMENITY, getAmenityById);
  yield takeLatest(CREATE_AMENITY, createAmenity);
  yield takeLatest(UPDATE_AMENITY, updateAmenity);
  yield takeLatest(DELETE_AMENITY, deleteAmenity);
}

export default amenity;
