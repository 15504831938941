import {
  FETCH_RFID_GROUP,
  FETCH_RFID_GROUP_SUCCESS,
  FETCH_RFID_GROUP_FAIL,
  GET_RFID_GROUP,
  GET_RFID_GROUP_SUCCESS,
  GET_RFID_GROUP_FAIL,
  CREATE_RFID_GROUP,
  CREATE_RFID_GROUP_SUCCESS,
  CREATE_RFID_GROUP_FAIL,
  UPDATE_RFID_GROUP,
  UPDATE_RFID_GROUP_FAIL,
  UPDATE_RFID_GROUP_SUCCESS,
  DELETE_RFID_GROUP,
  DELETE_RFID_GROUP_SUCCESS,
  DELETE_RFID_GROUP_FAIL,
} from 'actions/rfidGroup';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  rfidGroup: [],
  rfidGroupDetail: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const rfidGroupReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_RFID_GROUP:
    case GET_RFID_GROUP:
    case CREATE_RFID_GROUP:
    case DELETE_RFID_GROUP: {
      return { ...state, isLoading: true, rfidGroupDetail: {} };
    }

    case UPDATE_RFID_GROUP: {
      return { ...state, isLoading: true };
    }

    case FETCH_RFID_GROUP_SUCCESS: {
      if (payload.page === 1) {
        return {
          ...state,
          isLoading: false,
          rfidGroup: payload.results,
          page: payload.page,
          limit: payload.limit,
          totalPages: payload.totalPages,
          total: payload.totalResults,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          rfidGroup: _.concat(state.rfidGroup, payload.results),
          page: payload.page,
          limit: payload.limit,
          totalPages: payload.totalPages,
          total: payload.totalResults,
        };
      }
    }

    case GET_RFID_GROUP_SUCCESS: {
      return {
        ...state,
        rfidGroupDetail: payload,
        isLoading: false,
      };
    }

    case CREATE_RFID_GROUP_SUCCESS: {
      toast.success('RFID Group added successfully');
      return {
        ...state,
        rfidGroup: _.concat(state.rfidGroup, payload),
        isLoading: false,
      };
    }

    case UPDATE_RFID_GROUP_SUCCESS: {
      toast.success('RFID Group updated successfully');
      return {
        ...state,
        isLoading: false,
        rfidGroup: state.rfidGroup.map((data) => {
          if (data.id === payload.id) {
            return payload;
          } else {
            return data;
          }
        }),
      };
    }

    case DELETE_RFID_GROUP_SUCCESS: {
      toast.success('RFID Group deleted successfully');
      return {
        ...state,
        isLoading: false,
        rfidGroup: state.rfidGroup.filter((data) => data.id !== payload),
      };
    }

    case FETCH_RFID_GROUP_FAIL:
    case GET_RFID_GROUP_FAIL:
    case CREATE_RFID_GROUP_FAIL:
    case UPDATE_RFID_GROUP_FAIL:
    case DELETE_RFID_GROUP_FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return {
        ...state,
      };
  }
};
export default rfidGroupReducer;
