import {
  FETCH_VEHICLE_MODEL,
  FETCH_VEHICLE_MODEL_SUCCESS,
  FETCH_VEHICLE_MODEL_FAIL,
  GET_VEHICLE_MODEL,
  GET_VEHICLE_MODEL_SUCCESS,
  GET_VEHICLE_MODEL_FAIL,
  CREATE_VEHICLE_MODEL,
  CREATE_VEHICLE_MODEL_SUCCESS,
  CREATE_VEHICLE_MODEL_FAIL,
  UPDATE_VEHICLE_MODEL,
  UPDATE_VEHICLE_MODEL_FAIL,
  UPDATE_VEHICLE_MODEL_SUCCESS,
  DELETE_VEHICLE_MODEL,
  DELETE_VEHICLE_MODEL_SUCCESS,
  DELETE_VEHICLE_MODEL_FAIL,
} from 'actions/vehicleModel';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  vehicleModels: [],
  vehicleModelDetail: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const vehicleModelReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_VEHICLE_MODEL:
    case GET_VEHICLE_MODEL:
    case CREATE_VEHICLE_MODEL:
    case DELETE_VEHICLE_MODEL: {
      return { ...state, isLoading: true, vehicleModelDetail: {} };
    }

    case UPDATE_VEHICLE_MODEL: {
      return { ...state, isLoading: true };
    }

    case FETCH_VEHICLE_MODEL_SUCCESS: {
      if (payload.page === 1) {
        return {
          ...state,
          isLoading: false,
          vehicleModels: payload.results,
          page: payload.page,
          limit: payload.limit,
          totalPages: payload.totalPages,
          total: payload.totalResults,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          vehicleModels: _.concat(state.vehicleModels, payload.results),
          page: payload.page,
          limit: payload.limit,
          totalPages: payload.totalPages,
          total: payload.totalResults,
        };
      }
    }

    case GET_VEHICLE_MODEL_SUCCESS: {
      return {
        ...state,
        vehicleModelDetail: payload,
        isLoading: false,
      };
    }

    case CREATE_VEHICLE_MODEL_SUCCESS: {
      toast.success('EV model added successfully');
      return {
        ...state,
        vehicleModels: _.concat(state.vehicleModels, payload),
        isLoading: false,
      };
    }

    case UPDATE_VEHICLE_MODEL_SUCCESS: {
      toast.success('EV model updated successfully');
      return {
        ...state,
        vehicleModels: state.vehicleModels.map((data) => (data.id === payload.id ? payload : data)),
        isLoading: false,
      };
    }

    case DELETE_VEHICLE_MODEL_SUCCESS: {
      toast.success('EV model deleted successfully');
      return {
        ...state,
        isLoading: false,
        vehicleModels: state.vehicleModels.filter((data) => data.id !== payload),
      };
    }

    case FETCH_VEHICLE_MODEL_FAIL:
    case GET_VEHICLE_MODEL_FAIL:
    case CREATE_VEHICLE_MODEL_FAIL:
    case UPDATE_VEHICLE_MODEL_FAIL:
    case DELETE_VEHICLE_MODEL_FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return {
        ...state,
      };
  }
};
export default vehicleModelReducer;
