export const FETCH_AMENITY = 'FETCH_AMENITY';
export const FETCH_AMENITY_SUCCESS = 'FETCH_AMENITY_SUCCESS';
export const FETCH_AMENITY_FAIL = 'FETCH_AMENITY_FAIL';

export const GET_AMENITY = 'GET_AMENITY';
export const GET_AMENITY_SUCCESS = 'GET_AMENITY_SUCCESS';
export const GET_AMENITY_FAIL = 'GET_AMENITY_FAIL';

export const CREATE_AMENITY = 'CREATE_AMENITY';
export const CREATE_AMENITY_SUCCESS = 'CREATE_AMENITY_SUCCESS';
export const CREATE_AMENITY_FAIL = 'CREATE_AMENITY_FAIL';

export const UPDATE_AMENITY = 'UPDATE_AMENITY';
export const UPDATE_AMENITY_SUCCESS = ' UPDATE_AMENITY_SUCCESS';
export const UPDATE_AMENITY_FAIL = 'UPDATE_AMENITY_FAIL';

export const DELETE_AMENITY = 'DELETE_AMENITY';
export const DELETE_AMENITY_SUCCESS = 'DELETE_AMENITY_SUCCESS';
export const DELETE_AMENITY_FAIL = ' DELETE_AMENITY_FAIL';
