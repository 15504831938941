import { DOWNLOAD_JOB_VOUCHER, DOWNLOAD_JOB_VOUCHER_SUCCESS, DOWNLOAD_JOB_VOUCHER_FAIL } from 'actions/dataReport';

const initialValues = {
  isLoading: true,
  voucherDetail: '',
};

const JobVoucherReducer = (state = initialValues, { type, payload }) => {
  switch (type) {
    case DOWNLOAD_JOB_VOUCHER: {
      return { ...state, isLoading: true };
    }

    case DOWNLOAD_JOB_VOUCHER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        voucherDetail: payload,
      };
    }

    case DOWNLOAD_JOB_VOUCHER_FAIL: {
      return { ...state, isLoading: false };
    }
    default:
      return { ...state };
  }

};

export default JobVoucherReducer;
