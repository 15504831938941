import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import {
  FETCH_CHARGE_COIN,
  FETCH_CHARGE_COIN_SUCCESS,
  FETCH_CHARGE_COIN_FAIL,
  UPDATE_CHARGE_COIN,
  UPDATE_CHARGE_COIN_SUCCESS,
  UPDATE_CHARGE_COIN_FAIL,
  UPDATE_CHARGE_COIN_LEVEL,
  UPDATE_CHARGE_COIN_LEVEL_SUCCESS,
  UPDATE_CHARGE_COIN_LEVEL_FAIL,
  DELETE_CHARGE_COIN_LEVEL,
  DELETE_CHARGE_COIN_LEVEL_SUCCESS,
  DELETE_CHARGE_COIN_LEVEL_FAIL,
} from '../actions/chargeCoin';

function* fetchChargeCoin(action) {
  try {
    const response = yield call(API.fetchChargeCoin, action.payload);
    yield put({ type: FETCH_CHARGE_COIN_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_CHARGE_COIN_FAIL, payload: { error: e } });
  }
}

function* updateChargeCoin(action) {
  try {
    const response = yield call(API.updateChargeCoin, action.payload);
    yield put({ type: UPDATE_CHARGE_COIN_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_CHARGE_COIN_FAIL, payload: { error: e } });
  }
}

function* updateChargeCoinLevel(action) {
  try {
    const response = yield call(API.updateChargeCoinLevel, action.payload);
    yield put({ type: UPDATE_CHARGE_COIN_LEVEL_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_CHARGE_COIN_LEVEL_FAIL, payload: { error: e } });
  }
}

function* deleteChargeCoinLevel(action) {
  try {
    const response = yield call(API.deleteChargeCoinLevel, action.payload);
    yield put({ type: DELETE_CHARGE_COIN_LEVEL_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_CHARGE_COIN_LEVEL_FAIL, payload: { error: e } });
  }
}

function* chargeCoin() {
  yield takeLatest(FETCH_CHARGE_COIN, fetchChargeCoin);
  yield takeLatest(UPDATE_CHARGE_COIN, updateChargeCoin);
  yield takeLatest(DELETE_CHARGE_COIN_LEVEL, deleteChargeCoinLevel);
  yield takeLatest(UPDATE_CHARGE_COIN_LEVEL, updateChargeCoinLevel);
}

export default chargeCoin;
