import { FAULTY_BOOKING_REPORT, FAULTY_BOOKING_REPORT_SUCCESS, FAULTY_BOOKING_REPORT_FAIL } from 'actions/dataReport';

const initialState = {
  isLoading: false,
  faultyBookingReport: [],
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const faultyBookingReportReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FAULTY_BOOKING_REPORT: {
      return { ...state, isLoading: true };
    }
    case FAULTY_BOOKING_REPORT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        faultyBookingReport: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case FAULTY_BOOKING_REPORT_FAIL: {
      return { ...state, isLoading: false };
    }
    default:
      return { ...state };
  }
};
export default faultyBookingReportReducer;
