import { B2C_STATION_CHARGING_HISTORY, B2C_STATION_CHARGING_HISTORY_SUCCESS, B2C_STATION_CHARGING_HISTORY_FAIL } from 'actions/dataReport';

const initialState = {
  isLoading: false,
  b2cStationChargingHistory: [],
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const b2cStationChargingHistoryReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case B2C_STATION_CHARGING_HISTORY: {
      return { ...state, isLoading: true };
    }
    case B2C_STATION_CHARGING_HISTORY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        b2cStationChargingHistory: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case B2C_STATION_CHARGING_HISTORY_FAIL: {
      return { ...state, isLoading: false };
    }
    default:
      return { ...state };
  }
};
export default b2cStationChargingHistoryReducer;
