import { USER_CHARGING_HISTORY, USER_CHARGING_HISTORY_SUCCESS, USER_CHARGING_HISTORY_FAIL } from 'actions/dataReport';

const initialState = {
  isLoading: false,
  userChargingHistories: [],
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const userChargingHistoryReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case USER_CHARGING_HISTORY: {
      return { ...state, isLoading: true };
    }

    case USER_CHARGING_HISTORY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        userChargingHistories: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case USER_CHARGING_HISTORY_FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};
export default userChargingHistoryReducer;
