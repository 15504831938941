import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import {
  CREATE_USER_GROUP,
  CREATE_USER_GROUP_SUCCESS,
  CREATE_USER_GROUP_FAIL,
  FETCH_USER_GROUP,
  FETCH_USER_GROUP_SUCCESS,
  FETCH_USER_GROUP_FAIL,
  GET_USER_GROUP,
  GET_USER_GROUP_SUCCESS,
  GET_USER_GROUP_FAIL,
  DELETE_USER_GROUP,
  DELETE_USER_GROUP_SUCCESS,
  DELETE_USER_GROUP_FAIL,
  UPDATE_USER_GROUP,
  UPDATE_USER_GROUP_SUCCESS,
  UPDATE_USER_GROUP_FAIL,
  ALL_USERS_IN_USER_GROUP_SUCCESS,
  ALL_USERS_IN_USER_GROUP_FAIL,
  ALL_USERS_IN_USER_GROUP,
  CREATE_BULK_USERS_TO_USER_GROUP,
  CREATE_BULK_USERS_TO_USER_GROUP_SUCCESS,
  CREATE_BULK_USERS_TO_USER_GROUP_FAIL,
} from 'actions/userGroup';

function* getUserGroup(action) {
  try {
    const response = yield call(API.getUserGroup, action.payload);
    yield put({ type: FETCH_USER_GROUP_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_USER_GROUP_FAIL, payload: { error: e } });
  }
}

function* getUserGroupById(action) {
  try {
    const response = yield call(API.getUserGroupById, action.payload);
    yield put({ type: GET_USER_GROUP_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_USER_GROUP_FAIL, payload: { error: e } });
  }
}

function* createUserGroup(action) {
  try {
    const response = yield call(API.addUserGroup, action.payload);
    yield put({ type: CREATE_USER_GROUP_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_USER_GROUP_FAIL, payload: { error: e } });
  }
}

function* updateUserGroup(action) {
  try {
    const response = yield call(API.updateUserGroup, action.payload);
    yield put({ type: UPDATE_USER_GROUP_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_USER_GROUP_FAIL, payload: { error: e } });
  }
}

function* deleteUserGroup(action) {
  try {
    const response = yield call(API.deleteUserGroup, action.payload);
    yield put({ type: DELETE_USER_GROUP_SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_USER_GROUP_FAIL, payload: { error: e } });
  }
}

function* allUsersInUserGroup(action) {
  try {
    const response = yield call(API.allUsersInUserGroup, action.payload);
    yield put({ type: ALL_USERS_IN_USER_GROUP_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: ALL_USERS_IN_USER_GROUP_FAIL, payload: { error: e } });
  }
}

function* createBulkUsersToUserGroup(action) {
  try {
    const response = yield call(API.createBulkUsersToUserGroup, action.payload);
    yield put({ type: CREATE_BULK_USERS_TO_USER_GROUP_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_BULK_USERS_TO_USER_GROUP_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* userGroup() {
  yield takeLatest(FETCH_USER_GROUP, getUserGroup);
  yield takeLatest(CREATE_USER_GROUP, createUserGroup);
  yield takeLatest(GET_USER_GROUP, getUserGroupById);
  yield takeLatest(DELETE_USER_GROUP, deleteUserGroup);
  yield takeLatest(UPDATE_USER_GROUP, updateUserGroup);
  yield takeLatest(ALL_USERS_IN_USER_GROUP, allUsersInUserGroup);
  yield takeLatest(CREATE_BULK_USERS_TO_USER_GROUP, createBulkUsersToUserGroup);
}

export default userGroup;
