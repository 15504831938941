import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import {
  FETCH_CHARGER_LOG,
  FETCH_CHARGER_LOG_SUCCESS,
  FETCH_CHARGER_LOG_FAIL,
  FETCH_USER_ACTIVITY,
  FETCH_USER_ACTIVITY_SUCCESS,
  FETCH_USER_ACTIVITY_FAIL,
  DELETE_CHARGER_LOG_SUCCESS,
  DELETE_CHARGER_LOG_FAIL,
  DELETE_CHARGER_LOG,
  DOWNLOAD_CHARGER_LOG_DATA,
  DOWNLOAD_CHARGER_LOG_DATA_SUCCESS,
  DOWNLOAD_CHARGER_LOG_DATA_FAIL,
  DOWNLOAD_TABULAR_METERVALUE,
  DOWNLOAD_TABULAR_METERVALUE_FAIL,
  DOWNLOAD_TABULAR_METERVALUE_SUCCESS
} from 'actions/activityLog';

function* fetchActivityLog(action) {
  try {
    const response = yield call(API.getActivityLog, action.payload);
    yield put({ type: FETCH_CHARGER_LOG_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_CHARGER_LOG_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* fetchUserActivity(action) {
  try {
    const response = yield call(API.getUserActivity, action.payload);
    yield put({ type: FETCH_USER_ACTIVITY_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_USER_ACTIVITY_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* deleteActivityLog(action) {
  try {
    yield call(API.deleteActivityLog, action.payload);
    yield put({ type: DELETE_CHARGER_LOG_SUCCESS, payload: action.payload });
  } catch (e) {
    yield put({ type: DELETE_CHARGER_LOG_FAIL, payload: { error: e } });
  }
}

function* downloadChargerLog(action) {
  try {
    const response = yield call(API.downloadChargerLogData, action.payload);
    yield put({ type: DOWNLOAD_CHARGER_LOG_DATA_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_CHARGER_LOG_DATA_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* downloadTabMeterValue(action) {
  try {
    const response = yield call(API.downloadTabularMeterValue, action.payload);
    yield put({ type: DOWNLOAD_TABULAR_METERVALUE_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_TABULAR_METERVALUE_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* activityLog() {
  yield takeLatest(FETCH_CHARGER_LOG, fetchActivityLog);
  yield takeLatest(FETCH_USER_ACTIVITY, fetchUserActivity);
  yield takeLatest(DELETE_CHARGER_LOG, deleteActivityLog);
  yield takeLatest(DOWNLOAD_CHARGER_LOG_DATA, downloadChargerLog);
  yield takeLatest(DOWNLOAD_TABULAR_METERVALUE, downloadTabMeterValue)
}
export default activityLog;
