import {
  FETCH_OEM,
  FETCH_OEM_FAIL,
  FETCH_OEM_SUCCESS,
  GET_OEM,
  GET_OEM_SUCCESS,
  GET_OEM_FAIL,
  CREATE_OEM,
  CREATE_OEM_SUCCESS,
  CREATE_OEM_FAIL,
  UPDATE_OEM_SUCCESS,
  UPDATE_OEM_FAIL,
  DELETE_OEM,
  DELETE_OEM_SUCCESS,
  DELETE_OEM_FAIL,
  OEM_UPDATE_FIRMWARE_FAIL,
  OEM_UPDATE_FIRMWARE_SUCCESS,
} from 'actions/oem';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  oems: [],
  oemDetail: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const oemReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_OEM:
    case GET_OEM:
    case CREATE_OEM:
    case DELETE_OEM: {
      return { ...state, isLoading: true, oemDetail: {} };
    }

    case FETCH_OEM_SUCCESS: {
      if (payload.page === 1) {
        return {
          ...state,
          isLoading: false,
          oems: payload.results,
          page: payload.page,
          limit: payload.limit,
          totalPages: payload.totalPages,
          total: payload.totalResults,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          oems: _.concat(state.oems, payload.results),
          page: payload.page,
          limit: payload.limit,
          totalPages: payload.totalPages,
          total: payload.totalResults,
        };
      }
    }

    case GET_OEM_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        oemDetail: payload,
      };
    }

    case CREATE_OEM_SUCCESS: {
      toast.success('ChargeSpot added successfully');
      setTimeout(() => {
        window.location.href = '/ChargeSpot';
      }, 500);
      return {
        ...state,
        isLoading: false,
        oems: _.concat(state.oems, payload),
      };
    }

    case UPDATE_OEM_SUCCESS: {
      toast.success('ChargeSpot updated successfully');
      setTimeout(() => {
        window.location.href = '/ChargeSpot';
      }, 500);
      return {
        ...state,
        oems: state.oems.map((data) => (data.id === payload.id ? payload : data)),
        isLoading: false,
      };
    }

    case OEM_UPDATE_FIRMWARE_SUCCESS: {
      toast.success('Oem firmware updated successfully');
      return {
        ...state,
        oems: state.oems.map((data) => (data.id === payload.id ? payload : data)),
        isLoading: false,
      };
    }

    case DELETE_OEM_SUCCESS: {
      toast.success('ChargeSpot deleted successfully');
      return {
        ...state,
        oems: state.oems.filter((data) => data.id !== payload),
      };
    }

    case FETCH_OEM_FAIL:
    case GET_OEM_FAIL:
    case CREATE_OEM_FAIL:
    case UPDATE_OEM_FAIL:
    case DELETE_OEM_FAIL:
    case OEM_UPDATE_FIRMWARE_FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};
export default oemReducer;
