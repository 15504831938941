import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import {
  CREATE_SCHEDULE,
  CREATE_SCHEDULE_SUCCESS,
  CREATE_SCHEDULE_FAIL,
  FETCH_SCHEDULE,
  FETCH_SCHEDULE_SUCCESS,
  FETCH_SCHEDULE_FAIL,
  DELETE_SCHEDULE,
  DELETE_SCHEDULE_SUCCESS,
  DELETE_SCHEDULE_FAIL,
  UPDATE_SCHEDULE,
  UPDATE_SCHEDULE_SUCCESS,
  UPDATE_SCHEDULE_FAIL,
} from 'actions/schedule';

function* fetchSchedule(action) {
  try {
    const response = yield call(API.fetchSchedule, action.payload);
    yield put({ type: FETCH_SCHEDULE_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_SCHEDULE_FAIL, payload: { error: e } });
  }
}

function* createSchedule(action) {
  try {
    const response = yield call(API.createSchedule, action.payload);
    yield put({ type: CREATE_SCHEDULE_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_SCHEDULE_FAIL, payload: { error: e } });
  }
}

function* updateSchedule(action) {
  try {
    const response = yield call(API.updateSchedule, action.payload);
    yield put({ type: UPDATE_SCHEDULE_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_SCHEDULE_FAIL, payload: { error: e } });
  }
}


function* deleteYourSchedule(action) {
  try {
    const response = yield call(API.deleteYourSchedule, action.payload);
    yield put({ type: DELETE_SCHEDULE_SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_SCHEDULE_FAIL, payload: { error: e } });
  }
}

function* schedule() {
  yield takeLatest(FETCH_SCHEDULE, fetchSchedule);
  yield takeLatest(CREATE_SCHEDULE, createSchedule);
  yield takeLatest(DELETE_SCHEDULE, deleteYourSchedule);
  yield takeLatest(UPDATE_SCHEDULE, updateSchedule);
}

export default schedule;
