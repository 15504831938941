import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header/index';
import { BsSearch } from 'react-icons/bs';
import { RiEqualizerFill } from 'react-icons/ri';
import { MdClose, MdClear } from 'react-icons/md';
import { IoMdPricetag } from 'react-icons/io';
import { FaStar, FaMapMarkerAlt } from 'react-icons/fa';
import { FiZapOff } from 'react-icons/fi';
import ReactStars from 'react-rating-stars-component';
import { Offcanvas } from 'react-bootstrap';
import Select from 'components/inputs/Select';
import Button from 'components/inputs/Button';
import { useSelector, useDispatch } from 'react-redux';
import { FETCH_CHARGING_STATION, GET_STATION_WIDGETS } from 'actions/chargingStation';
import * as _ from 'lodash';
import { startsWith } from 'components/common/utils';
import SearchBox from 'components/general/SearchBox';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { FETCH_CITY, FETCH_COUNTRY, FETCH_STATE } from 'actions/address';
import { Form, Formik } from 'formik';
import { FETCH_CHARGE_SPEED } from 'actions/chargeSpeed';
import {
  stationBlueMarker,
  stationGreenMarker,
  stationGreyMarker,
  stationRedMarker,
  privateStationMarker,
  powerlossStationMarker,
} from 'utils/Constants/images';
// import { GET_STATIONS, MANAGE_STATIONS, UPDATE_STATUS_CODE } from 'components/common/constant';
import { UPDATE_STATUS_CODE } from 'components/common/constant';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import { BsInfoCircle } from 'react-icons/bs';
// import { FiPlusCircle } from 'react-icons/fi';
import { FETCH_TAGS } from 'actions/tagmanagement';
// import { MdPowerOff } from 'react-icons/md';
import SettingsInputSvideoIcon from '@mui/icons-material/SettingsInputSvideo';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { RiChargingPile2Line } from 'react-icons/ri';
import Footer from 'components/general/Footer';
import { NavContext } from 'components/privateRoute/PrivateRoute';
import L from 'leaflet';

const ChargerStationList = () => {
  const { navigateTo } = useContext(NavContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  const [showFilterPopup, setShowFilterPopup] = useState(false);
  const [allChargingStationData, setAllChargingStationData] = useState([]);
  const [activeMarker, setActiveMarker] = useState(false);
  const [mapCenter] = useState({ lat: 21.722475, lng: 76.6682639 });
  const [filterData, setFilterData] = useState({});
  const allChargingStations = useSelector((state) => state.chargingStation.chargingStations);
  const isLoader = useSelector((state) => state.chargingStation.isLoading);
  // const rolePermissionData = useSelector((state) => state.manageRole.manageRoles);
  const profileData = useSelector((state) => state.profile.userProfile);
  const available = useSelector((state) => state.chargingStation.available);
  const unavailable = useSelector((state) => state.chargingStation.unavailable);
  const comingsoon = useSelector((state) => state.chargingStation.comingsoon);
  const inUse = useSelector((state) => state.chargingStation.inUse);
  const powerLoss = useSelector((state) => state.chargingStation.powerLoss);
  const currentUserName = _.get(profileData, 'name', '');
  const underMaintenance = useSelector((state) => state.chargingStation.underMaintenance);
  const totalStations = useSelector((state) => state.chargingStation.totalCsCount);

  // const getStation =
  //   !_.isEmpty(rolePermissionData) &&
  //   _.map(
  //     _.filter(rolePermissionData[0].permissions, (item) => item.name === GET_STATIONS),
  //     (data) => data.name
  //   );

  // const manageStation =
  //   !_.isEmpty(rolePermissionData) &&
  //   _.map(
  //     _.filter(rolePermissionData[0].permissions, (item) => item.name === MANAGE_STATIONS),
  //     (data) => data.name
  //   );

  const getWidgets = useCallback(() => {
    const data = {
      charging_station: userProfileStations,
    };
    dispatch({
      type: GET_STATION_WIDGETS,
      payload: data,
    });
  }, []);

  const updateStationList = (availability) => {
    const stationData = {
      status: availability,
      request_all: true,
      is_active: true,
    };

    dispatch({
      type: FETCH_CHARGING_STATION,
      payload: stationData,
    });
  };

  useEffect(() => {
    getChargingStations();
    getWidgets();
  }, []);

  useEffect(() => {
    setAllChargingStationData(allChargingStations);
  }, [allChargingStations]);

  const getChargingStations = useCallback((data = {}) => {
    const stationData = {
      ...data,
      request_all: true,
    };
    dispatch({ type: FETCH_CHARGING_STATION, payload: stationData });
  }, []);

  const handleSearch = () => {
    getChargingStations({});
  };

  useEffect(() => {
    setAllChargingStationData(
      _.filter(
        allChargingStations,
        (item) =>
          startsWith(item.name, searchText) ||
          startsWith(item.city, searchText) ||
          startsWith([..._.map(item.chargers, (data) => _.get(data, 'charger_id', ''))].toString(), searchText) ||
          startsWith([..._.map(item.chargers, (data) => _.get(data, 'oem.name', ''))].toString(), searchText)
      )
    );
  }, [searchText, allChargingStations]);

  const onCloseFilterPopup = () => setShowFilterPopup(false);

  const handleActiveMarker = (marker) => {
    setActiveMarker(marker);
  };

  const userProfileStations = profileData.charging_stations;
  const stationList = allChargingStationData.filter((x) => userProfileStations.indexOf(x.id) !== -1);
  const colorCodePalette = [
    { icon: stationGreenMarker, status: 'Available' },
    { icon: stationRedMarker, status: 'Unavailable' },
    { icon: stationBlueMarker, status: 'Charging' },
    { icon: stationGreyMarker, status: 'Coming Soon' },
    { icon: powerlossStationMarker, status: 'PowerLoss' },
  ];
  const [showLegend, setShowLegend] = useState(false);

  // const markerIcon = new L.Icon({
  //   iconAnchor: [12, 41],
  //   popupAnchor: [1, -34],
  //   shadowUrl: null,
  //   shadowSize: null,
  //   shadowAnchor: null,
  //   iconSize: new L.Point(25, 41),
  //   className: 'leaflet-marker-icon',
  // });

  const getMarkerIcon = (status, accessType) => {
    if (accessType === 'Private') {
      return new L.Icon({
        iconUrl: privateStationMarker,
        iconSize: [30, 40],
      });
    }
    switch (status) {
      case 'Available':
        return new L.Icon({
          iconUrl: stationGreenMarker,
          iconSize: [30, 40],
        });
      case 'Unavailable':
      case 'Under Maintenance':
        return new L.Icon({
          iconUrl: stationRedMarker,
          iconSize: [30, 40],
        });
      case 'Coming Soon':
        return new L.Icon({
          iconUrl: stationGreyMarker,
          iconSize: [30, 40],
        });
      case 'PowerLoss':
        return new L.Icon({
          iconUrl: powerlossStationMarker,
          iconSize: [30, 40],
        });
      case 'In Use':
        return new L.Icon({
          iconUrl: stationBlueMarker,
          iconSize: [30, 40],
        });
      default:
        return new L.Icon({
          iconUrl: stationGreyMarker,
          iconSize: [30, 40],
        });
    }
  };

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title={t('header.stationsChargers')} />
        <div className="page-content-wrapper scrollable">
          <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
            <div className="status-blocks">
              <div className="status-block" title="Total Number of Stations">
                <div className="breating-area" style={{ background: '#14ac7f1a' }}>
                  {isLoader ? <Skeleton circle="true" height={24} width={24} /> : <RiChargingPile2Line color="#14AC7F" size={35} />}
                </div>
                <div className="status-info" onClick={() => updateStationList('Available')}>
                  <h1>{isLoader ? <Skeleton height={15} width={100} /> : available}</h1>
                  <p>{isLoader ? <Skeleton height={15} width={100} /> : t('stationDetails.available')}</p>
                </div>
              </div>

              <div className="status-block" title="Unavailabe Number of Stations">
                <div className="breating-area" style={{ background: '#ff8b001a' }}>
                  {isLoader ? <Skeleton circle="true" height={24} width={24} /> : <RiChargingPile2Line color="#FF8B00" size={35} />}
                </div>
                <div className="status-info" onClick={() => updateStationList('Unavailable')}>
                  <h1>{isLoader ? <Skeleton height={15} width={100} /> : unavailable}</h1>
                  <p>{isLoader ? <Skeleton height={15} width={100} /> : t('stationDetails.unavailable')}</p>
                </div>
              </div>

              <div className="status-block" title="InUse Stations">
                <div className="breating-area" style={{ background: '#3c7cdd1a' }}>
                  {isLoader ? <Skeleton circle="true" height={24} width={24} /> : <RiChargingPile2Line size={35} />}
                </div>
                <div className="status-info" onClick={() => updateStationList('In Use')}>
                  <h1>{isLoader ? <Skeleton height={15} width={100} /> : inUse}</h1>
                  <p>{isLoader ? <Skeleton height={15} width={100} /> : t('stationDetails.inUse')}</p>
                </div>
              </div>

              <div className="status-block" title="Coming Soon Stations">
                <div className="breating-area" style={{ background: '#8c8ea421' }}>
                  {isLoader ? <Skeleton circle="true" height={24} width={24} /> : <RiChargingPile2Line color="#8C8EA4" size={35} />}
                </div>
                <div className="status-info" onClick={() => updateStationList('Coming Soon')}>
                  <h1>{isLoader ? <Skeleton height={15} width={100} /> : comingsoon}</h1>
                  <p>{isLoader ? <Skeleton height={15} width={100} /> : t('stationDetails.comingsoon')}</p>
                </div>
              </div>

              <div className="status-block" title="No Power Availability Stations">
                <div className="breating-area" style={{ background: '#be210b21' }}>
                  {isLoader ? <Skeleton circle="true" height={24} width={24} /> : <FiZapOff color="#BE210B" size={35} />}
                </div>
                <div className="status-info" onClick={() => updateStationList('PowerLoss')}>
                  <h1>{isLoader ? <Skeleton height={15} width={100} /> : powerLoss}</h1>
                  <p>{isLoader ? <Skeleton height={15} width={100} /> : t('stationDetails.powerLoss')}</p>
                </div>
              </div>

              <div className="status-block" title="Stations under maintanence">
                <div className="breating-area" style={{ background: '#c9a11b1a' }}>
                  {isLoader ? <Skeleton circle="true" height={24} width={24} /> : <RiChargingPile2Line color="#C9A11B" size={35} />}
                </div>
                <div className="status-info" onClick={() => updateStationList('Under Maintenance')}>
                  <h1>{isLoader ? <Skeleton height={15} width={100} /> : underMaintenance}</h1>
                  <p>{isLoader ? <Skeleton height={15} width={100} /> : t('stationDetails.underMaintenance')}</p>
                </div>
              </div>
            </div>
          </SkeletonTheme>
          <div className="station-list-page__main">
            <div className="search--block">
              <SearchBox
                value={searchText}
                preIcon={<BsSearch />}
                postIcon={<RiEqualizerFill onClick={() => setShowFilterPopup(true)} title="Open Advance Filter" />}
                onChange={(e) => setSearchText(e.target.value)}
                title="Search"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleSearch();
                  }
                }}
              />
              {/* {!_.isEmpty(manageStation) || (!_.isEmpty(getStation) && !_.isEmpty(manageStation)) ? (
                <button
                  type="button"
                  onClick={() => navigateTo('/addStation')}
                  className="addchargingstationbtn primary-button btn btn-danger"
                  title="Add New Charging Station"
                >
                  <FiPlusCircle />
                  <span>{t('button.addChargingStation')}</span>
                </button>
              ) : (
                ''
              )} */}
            </div>
            <div className="map-station--outer-block">
              <div className="station-list__block-main">
                <div className="station__count-info">
                  <span>{t('addStationsChargers.allStations')}</span>
                  <div className="total-station" title="Total Number of Stations">
                    <span>{totalStations}</span>
                  </div>
                </div>
                <div className="station-list__block-inner scrollable">
                  {searchText && _.isEmpty(allChargingStationData) && _.isEmpty(userProfileStations) ? (
                    <div className="empty-data-block">{t('stationDetails.noData')}</div>
                  ) : (
                    _.map(
                      !_.isEmpty(userProfileStations) ? _.sortBy(stationList, ['name']) : _.sortBy(allChargingStationData, ['name']),
                      (station, index) => (
                        <StationInfoBlock
                          key={`charging-station-${index}`}
                          stationId={_.get(station, 'id', '')}
                          name={_.get(station, 'name', '')}
                          city={_.get(station, 'city', '')}
                          rating={_.get(station, 'avg_rating', 0)}
                          ratingByUsersCount={_.get(station, 'rating_count', 0)}
                          totalCharger={_.get(station, 'total_chargers', 0)}
                          is_active={_.get(station, 'is_active')}
                          is_external_station={_.get(station, 'is_external_station')}
                          tags={_.get(station, 'tags')}
                        />
                      )
                    )
                  )}
                </div>
              </div>
              {isLoader ? (
                <div className="map__block-main" style={{ width: '100%', height: '680px' }}>
                  <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
                    <Skeleton height={'100%'} />
                  </SkeletonTheme>
                </div>
              ) : (
                <div className="map__block-main">
                  <MapContainer center={[mapCenter.lat, mapCenter.lng]} zoom={5} style={{ height: '100%', width: '100%', borderRadius: '1rem' }}>
                    <TileLayer url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}@4x.png" />
                    {_.map(!_.isEmpty(userProfileStations) ? stationList : allChargingStationData, (item, index) => (
                      <Marker
                        key={index}
                        position={item.location && [_.get(item, 'location.lat', 0), _.get(item, 'location.lng', 0)]}
                        icon={getMarkerIcon(item.status, item.access_type)}
                        eventHandlers={{
                          mouseover: () => handleActiveMarker(item),
                        }}
                      >
                        {activeMarker === item && (
                          <Popup>
                            <div className="marker-text">
                              <b>
                                <p style={{ marginBottom: '0px', fontWeight: '600' }} onClick={() => navigateTo(`/stations/${_.get(item, 'id')}`)}>
                                  {_.get(item, 'name', '')}
                                </p>
                              </b>
                              <div style={{ fontSize: 'big', background: '#F7FDFE', padding: '10px', marginTop: '5px', borderRadius: '10px' }}>
                                {_.get(item, 'chargers', []).map((charger, chargerIndex) => (
                                  <div key={chargerIndex}>
                                    <div>
                                      <p style={{ color: 'Black', margin: '0', padding: '0px 0px', marginBottom: '15px' }}> {charger.charger_id}</p>
                                      <div style={{ display: 'flex', alignItems: 'center', fontSize: 'small' }}>
                                        {charger.plugs.map((plug, plugIndex) => (
                                          <div
                                            key={plugIndex}
                                            style={{ display: 'flex', alignItems: 'center', marginRight: '8px', marginTop: '-10px' }}
                                          >
                                            <SettingsInputSvideoIcon style={{ fontSize: '16px', marginRight: '4px' }} />
                                            <b>
                                              <span>{plug.name}</span>
                                              <span
                                                style={{
                                                  color: plug.status === 'Available' ? 'Teal' : plug.status === 'Charging' ? 'royalblue' : 'red',
                                                  marginLeft: '4px',
                                                }}
                                                onClick={() => {
                                                  navigateTo(`/stations/${_.get(item, 'id')}`);
                                                }}
                                              >
                                                ({plug.status})
                                              </span>
                                            </b>
                                          </div>
                                        ))}
                                      </div>
                                      <p style={{ margin: '5px 0px', color: 'darkgray', fontSize: 'smaller' }}>
                                        {t('stationDetails.lastUpdated')} {new Date(charger.updatedAt).toLocaleString()}
                                      </p>
                                      <p
                                        style={{ cursor: 'pointer', color: 'darkgray', textDecoration: 'underline', margin: '0', padding: '0px 0px' }}
                                        onClick={() => {
                                          navigateTo(`/charger/${charger.id}`);
                                        }}
                                      >
                                        {t('stationDetails.viewMore')}
                                      </p>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                            <div style={{ marginBottom: '5px', paddingLeft: '10px', marginTop: '5px' }}>
                              <p
                                style={{ cursor: 'pointer', color: 'black', textDecoration: 'underline', margin: '0', padding: '5px 0px' }}
                                onClick={() => {
                                  const latitude = _.get(item, 'location.lat');
                                  const longitude = _.get(item, 'location.lng');
                                  const url = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
                                  window.open(url, '_blank');
                                }}
                              >
                                {t('Open In Google Map')}
                              </p>
                            </div>
                          </Popup>
                        )}
                      </Marker>
                    ))}
                    <div
                      style={{
                        position: 'absolute',
                        bottom: '30px',
                        left: '20px',
                        zIndex: 1000,
                        display: 'flex',
                        flexDirection: 'column',
                        background: 'rgba(21, 33, 43, 0.9)',
                        padding: '10px',
                        fontSize: 'small',
                        borderRadius: '20px',
                        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',
                        color: 'white',
                        fontFamily: 'Arial, sans-serif',
                      }}
                      onMouseEnter={() => setShowLegend(true)}
                      onMouseLeave={() => setShowLegend(false)}
                    >
                      {showLegend ? (
                        <>
                          <h5
                            style={{
                              marginBottom: '15px',
                              fontSize: '1.0rem',
                              fontWeight: 'bold',
                              borderBottom: '1px solid rgba(255, 255, 255, 0.6)',
                              paddingBottom: '10px',
                            }}
                          >
                            {t('stationDetails.chargerStatus')}
                          </h5>
                          {colorCodePalette.map((item) => (
                            <div key={item.status} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                              <div style={{ marginRight: '10px' }}>
                                <img src={item.icon} alt={item.status} style={{ width: '20px', height: '25px', marginBottom: '2px' }} />
                              </div>
                              <span>{item.status}</span>
                            </div>
                          ))}
                        </>
                      ) : (
                        // Display the (i) icon when legend is hidden
                        <BsInfoCircle style={{ fontSize: '1.5rem', color: 'white' }} />
                      )}
                    </div>
                  </MapContainer>
                </div>
              )}
            </div>
          </div>
          {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
        </div>
      </LayoutContainer>
      {showFilterPopup && (
        <StationFilterBlock
          show={showFilterPopup}
          onClose={onCloseFilterPopup}
          filterData={filterData}
          handleFilterData={(data) => setFilterData(data)}
        />
      )}
    </React.Fragment>
  );
};

// ... (remaining code remains the same)
ChargerStationList.propTypes = {};
export default ChargerStationList;

export const StationInfoBlock = (props) => {
  const { stationId, name, city, tags = [], totalCharger, is_active, is_external_station, rating, ratingByUsersCount } = props;
  // const history = useHistory();
  const { navigateTo } = useContext(NavContext);
  const isLoader = useSelector((state) => state.chargingStation.isLoading);
  const formattedChargers = totalCharger > 0 ? totalCharger : 0;
  return (
    <div className="station-list-item__main" onClick={() => navigateTo(`/stations/${stationId}`)}>
      <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
        <div className="station-item__inner">
          <div className="station--icon-block">
            <div className="station--icon">{isLoader ? <Skeleton height={10} width={10} /> : formattedChargers}</div>
          </div>
          <div className="station--info-block">
            <div className="station--info_inner">
              <span className="station--name">{isLoader ? <Skeleton width={150} /> : name}</span>
              <div className="station--rating-block">
                <div className="rating-star__block">
                  <ReactStars
                    edit={false}
                    a11y={true}
                    count={1}
                    value={rating}
                    size={15}
                    isHalf={false}
                    char={<FaStar />}
                    activeColor="#fbaa26"
                    color="#fbaa26"
                  />
                </div>
                <span className="rating__count">
                  {rating} ({ratingByUsersCount})
                </span>
              </div>

              {isLoader ? (
                <Skeleton width={150} height={20} />
              ) : (
                <>
                  <div className="station--location-block">
                    <div className="location--name">
                      <FaMapMarkerAlt color="#3C7CDD" /> <span>{city}</span>
                    </div>
                  </div>
                </>
              )}
              {isLoader ? (
                <Skeleton width={150} height={20} />
              ) : (
                <>
                  <div className="station--location-block">
                    <div className="tags--name">
                      {tags && tags.length > 0 && (
                        <>
                          <IoMdPricetag color="#be210b" />
                          <span> {tags.map((tag) => tag.name).join(', ')}</span>
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
            {is_active === false && (
              <div className="station-active__main">
                <span className="station-active-text">{t('stationDetails.inDraft')}</span>
              </div>
            )}
            {is_external_station === true && (
              <div className="station-active__main">
                <span className="station-active-text">{t('stationDetails.partyId')}</span>
              </div>
            )}
          </div>
        </div>
        {is_external_station === true && (
          <div className="is-external-station">
            <div className="ribbon ribbon-top-right">
              <span>{t('stationDetails.external')}</span>
            </div>
          </div>
        )}
      </SkeletonTheme>
    </div>
  );
};
StationInfoBlock.propTypes = {
  stationId: PropTypes.string,
  name: PropTypes.string,
  city: PropTypes.string,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  rating: PropTypes.number,
  ratingByUsersCount: PropTypes.number,
  totalCharger: PropTypes.number,
  is_active: PropTypes.bool,
  is_external_station: PropTypes.bool,
};

export const StationFilterBlock = (props) => {
  const { show, onClose, filterData, handleFilterData } = props;
  const dispatch = useDispatch();
  // const [isAvailable, setIsAvailable] = useState(true);
  const allCountries = useSelector((state) => state.country.countries);
  const allState = useSelector((state) => state.state.states);
  const allCity = useSelector((state) => state.city.city);
  const countryPage = useSelector((state) => state.country.page);
  const statePage = useSelector((state) => state.state.page);
  const cityPage = useSelector((state) => state.city.page);
  const countryTotalPage = useSelector((state) => state.country.totalPages);
  const stateTotalPage = useSelector((state) => state.state.totalPages);
  const cityTotalPage = useSelector((state) => state.city.totalPages);
  const allTags = useSelector((state) => state.tagmanagement.tags);

  const initialValues = !_.isEmpty(filterData)
    ? { ...filterData }
    : { country: '', state: '', city: '', oem: '', charger_type: '', is_active: '', status: '', access_type: '' };

  const getAllChargingStations = useCallback((data = {}) => {
    const stationData = {
      ...data,
      request_all: true,
    };
    dispatch({
      type: FETCH_CHARGING_STATION,
      payload: stationData,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          onClose();
        }
      },
    });
  }, []);

  const getAllCountry = useCallback((data = {}) => {
    dispatch({ type: FETCH_COUNTRY, payload: data });
  }, []);

  const getStateByCountry = useCallback((data = {}) => {
    dispatch({ type: FETCH_STATE, payload: data });
  }, []);

  const getCityByState = useCallback((data = {}) => {
    dispatch({ type: FETCH_CITY, payload: data });
  }, []);

  const getTags = useCallback((data = {}) => {
    const tagData = {
      ...data,
    };
    dispatch({ type: FETCH_TAGS, payload: tagData });
  });

  useEffect(() => {
    getAllCountry();
    dispatch({ type: FETCH_CHARGE_SPEED });
    getTags({ limit: 999 });
  }, []);

  return (
    <Offcanvas show={show} placement="end" onHide={onClose} className="station-list-filter-canvas__container">
      <div className="filter-canvas__inner scrollable">
        <div className="canvas__header-block">
          <div className="header-name">{t('filters.advancedFilter')}</div>
          <div className="header-close-icon">
            <MdClose size={30} color="#be210b" onClick={onClose} />
          </div>
        </div>
        <div className="canvas__body-block">
          <div className="canvas__body-block--inner">
            <Formik
              enableReinitialize={!_.isEmpty(filterData)}
              initialValues={initialValues}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                !values.country && delete values['country'];
                !values.state && delete values['state'];
                !values.city && delete values['city'];
                !values.oem && delete values['oem'];
                !values.charger_type && delete values['charger_type'];
                !values.access_type && delete values['access_type'];
                !values.is_active && delete values['is_active'];
                !values.status && delete values['status'];
                const filterData = {
                  ...values,
                  is_active: values.is_active === 'Not In Use' ? false : true,
                };
                getAllChargingStations(filterData);
                handleFilterData();
                resetForm();
                setSubmitting(false);
              }}
            >
              {({ values, handleSubmit, setFieldValue, resetForm }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="mb-5">
                    <Select
                      label={t('filters.country')}
                      options={_.map(allCountries, (item) => {
                        return { label: item.name, value: item.name };
                      })}
                      className="filter-select-box"
                      placeholder={t('placeHolder.selectCountry')}
                      name="country"
                      value={values.country}
                      onMenuScrollDown={true}
                      getDataOnScrollDown={getAllCountry}
                      page={countryPage}
                      totalPage={countryTotalPage}
                      onChange={(val) => {
                        getStateByCountry({ country_name: val });
                        setFieldValue(`country`, val);
                        setFieldValue(`state`, '');
                        setFieldValue(`city`, '');
                      }}
                    />
                    <Select
                      label={t('filters.state')}
                      options={_.map(allState, (item) => {
                        return { label: item.name, value: item.name };
                      })}
                      className="filter-select-box"
                      placeholder={t('placeHolder.selectState')}
                      name="state"
                      value={values.state}
                      onMenuScrollDown={true}
                      getDataOnScrollDown={(data) => getStateByCountry({ ...data, country_name: values.country })}
                      page={statePage}
                      totalPage={stateTotalPage}
                      onChange={(val) => {
                        getCityByState({ state_name: val });
                        const currentState = _.find(allState, { name: val });
                        setFieldValue(`state`, currentState.name);
                        setFieldValue(`city`, '');
                      }}
                    />
                    <Select
                      label={t('filters.city')}
                      options={
                        values.state &&
                        _.map(allCity, (item) => {
                          return { label: item.name, value: item.name };
                        })
                      }
                      placeholder={t('placeHolder.selectCity')}
                      name="city"
                      value={values.city}
                      onMenuScrollDown={true}
                      getDataOnScrollDown={(data) => getCityByState({ ...data, state_name: values.state })}
                      page={cityPage}
                      totalPage={cityTotalPage}
                      onChange={(val) => {
                        const currentCity = _.find(allCity, { name: val });
                        setFieldValue('city', currentCity.name);
                      }}
                    />
                    <div className="canvas-selection__block">
                      <Select
                        label={t('filters.chargingStationType')}
                        options={[
                          { label: 'Public', value: 'Public' },
                          { label: 'Private', value: 'Private' },
                          { label: 'Commercial', value: 'Commercial' },
                        ]}
                        placeholder={t('placeHolder.selectType')}
                        name="access_type"
                        value={values.access_type}
                        onChange={(val) => setFieldValue(`access_type`, val)}
                      />
                      <Select
                        label={t('Station Status')}
                        options={[
                          { label: 'Unavailable', value: 'Unavailable' },
                          { label: 'Available', value: 'Available' },
                          { label: 'Under Maintenance', value: 'Under Maintenance' },
                          { label: 'Coming Soon', value: 'Coming Soon' },
                          { label: 'In Use', value: 'In Use' },
                        ]}
                        placeholder={t('placeHolder.selectStatus')}
                        name="status"
                        value={values.status}
                        onChange={(val) => setFieldValue(`status`, val)}
                      />
                    </div>

                    <Select
                      label={t('filters.tags')}
                      isMulti
                      options={_.map(allTags, (item) => {
                        return { label: item.name, value: item.name };
                      })}
                      className="filter-select-box"
                      placeholder={t('placeHolder.selectTags')}
                      name="tags"
                      value={values.tags}
                      onMenuScrollDown={true}
                      getDataOnScrollDown={getTags}
                      onChange={(val) => {
                        setFieldValue(`tags`, val);
                      }}
                    />
                    {/* <div className="canvas-selection__block">
                      <label htmlFor="available_charger" className="form-select-label">
                        Availability
                      </label>
                      <div id="available_charger" className="selection-block__inner">
                        <Row>
                          <Col>
                            <div className={`selection--btn ${values.is_active ? 'active' : ''}`} onClick={() => setFieldValue('is_active', true)}>
                              In use
                            </div>
                          </Col>
                          <Col>
                            <div className={`selection--btn ${values.is_active ? '' : 'active'}`} onClick={() => setFieldValue('is_active', false)}>
                              Not In use
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <div className="canvas-selection__block">
                      <label htmlFor="charger_status" className="form-select-label">
                        Status
                      </label>
                      <div id="charger_status" className="selection-block__inner">
                        <Row>
                          <Col>
                            <div
                              className={`selection--btn ${isAvailable ? 'active' : ''}`}
                              onClick={() => {
                                setFieldValue('charger_availibity', 'Available ');
                                setIsAvailable(true);
                              }}
                            >
                              Active
                            </div>
                          </Col>
                          <Col>
                            <div
                              className={`selection--btn ${isAvailable ? '' : 'active'}`}
                              onClick={() => {
                                setFieldValue('charger_availibity', 'Unavailable');
                                setIsAvailable(false);
                              }}
                            >
                              Inactive
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div> */}
                  </div>
                  <div className="canvas-btn__block">
                    <Button type="submit" className="apply--btn w-100">
                      {t('button.applyFilters')}
                    </Button>
                    <div
                      className="reset--block"
                      onClick={() => {
                        handleFilterData({});
                        getAllChargingStations();
                        resetForm();
                        onClose();
                      }}
                    >
                      <MdClear size={24} className="reset--icon" />
                      {t('button.clear')}
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Offcanvas>
  );
};
StationFilterBlock.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  filterData: PropTypes.object,
  handleFilterData: PropTypes.func,
};
