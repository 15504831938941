import {
  FETCH_ROLE,
  FETCH_ROLE_SUCCESS,
  FETCH_ROLE_FAIL,
  GET_ROLE,
  GET_ROLE_SUCCESS,
  GET_ROLE_FAIL,
  CREATE_ROLE,
  CREATE_ROLE_SUCCESS,
  CREATE_ROLE_FAIL,
  UPDATE_ROLE,
  UPDATE_ROLE_FAIL,
  UPDATE_ROLE_SUCCESS,
  DELETE_ROLE,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAIL,
  MANAGE_ROLE,
  MANAGE_ROLE_SUCCESS,
  MANAGE_ROLE_FAIL,
  GET_ROLE_BY_NAME_SUCCESS,
  GET_ROLE_BY_NAME_FAIL,
  GET_ROLE_BY_NAME,
} from 'actions/role';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';

function* fetchRole(action) {
  try {
    const response = yield call(API.getRole, action.payload);
    yield put({ type: FETCH_ROLE_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_ROLE_FAIL, payload: { error: e } });
  }
}

function* getRoleById(action) {
  try {
    const response = yield call(API.getRoleById, action.payload);
    yield put({ type: GET_ROLE_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_ROLE_FAIL, payload: { error: e } });
  }
}

function* createRole(action) {
  try {
    const response = yield call(API.addRole, action.payload);
    yield put({ type: CREATE_ROLE_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_ROLE_FAIL, payload: { error: e } });
  }
}

function* updateRole(action) {
  try {
    const response = yield call(API.updateRole, action.payload);
    yield put({ type: UPDATE_ROLE_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_ROLE_FAIL, payload: { error: e } });
  }
}

function* deleteRole(action) {
  try {
    const response = yield call(API.deleteRole, action.payload);
    yield put({ type: DELETE_ROLE_SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_ROLE_FAIL, payload: { error: e } });
  }
}

function* manageRole(action) {
  try {
    const response = yield call(API.manageRole, action.payload);
    yield put({ type: MANAGE_ROLE_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: MANAGE_ROLE_FAIL, payload: { error: e } });
  }
}

function* getRoleByName(action) {
  try {
    const response = yield call(API.manageRole, action.payload);
    yield put({ type: GET_ROLE_BY_NAME_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: GET_ROLE_BY_NAME_FAIL, payload: { error: e } });
  }
}

function* role() {
  yield takeLatest(FETCH_ROLE, fetchRole);
  yield takeLatest(GET_ROLE, getRoleById);
  yield takeLatest(CREATE_ROLE, createRole);
  yield takeLatest(UPDATE_ROLE, updateRole);
  yield takeLatest(DELETE_ROLE, deleteRole);
  yield takeLatest(MANAGE_ROLE, manageRole);
  yield takeLatest(GET_ROLE_BY_NAME, getRoleByName);
}
export default role;
