import {
  CHANGE_AVAILABILITY,
  CHANGE_AVAILABILITY_SUCCESS,
  CHANGE_AVAILABILITY_FAIL,
  GET_CONFIGURATION,
  GET_CONFIGURATION_SUCCESS,
  GET_CONFIGURATION_FAIL,
  GET_DIAGNOSTICS,
  GET_DIAGNOSTICS_SUCCESS,
  GET_DIAGNOSTICS_FAIL,
  CLEAR_CACHE_SUCCESS,
  CLEAR_CACHE_FAIL,
  CLEAR_CACHE,
  REMOTE_START_TRANSACTION_FAIL,
  REMOTE_START_TRANSACTION_SUCCESS,
  REMOTE_START_TRANSACTION,
  REMOTE_STOP_TRANSACTION_SUCCESS,
  REMOTE_STOP_TRANSACTION_FAIL,
  REMOTE_STOP_TRANSACTION,
  RESERVE_NOW_SUCCESS,
  RESERVE_NOW_FAIL,
  RESERVE_NOW,
  RESET_SUCCESS,
  RESET_FAIL,
  RESET,
  SEND_LOCAL_LIST_FAIL,
  SEND_LOCAL_LIST_SUCCESS,
  SEND_LOCAL_LIST,
  TRIGGER_MESSAGE_FAIL,
  TRIGGER_MESSAGE_SUCCESS,
  TRIGGER_MESSAGE,
  UNLOCK_CONNECTOR_FAIL,
  UNLOCK_CONNECTOR_SUCCESS,
  UNLOCK_CONNECTOR,
  UPDATE_FIRMWARE,
  UPDATE_FIRMWARE_FAIL,
  UPDATE_FIRMWARE_SUCCESS,
  DATA_TRANSFER_SERVER_FAIL,
  DATA_TRANSFER_SERVER_SUCCESS,
  GET_LOCAL_LIST,
  GET_LOCAL_LIST_SUCCESS,
  GET_LOCAL_LIST_FAIL,
  DATA_TRANSFER_SERVER,
  CANCEL_RESERVATION,
  CANCEL_RESERVATION_SUCCESS,
  CANCEL_RESERVATION_FAIL,
  CHANGE_CONFIGURATION,
  CHANGE_CONFIGURATION_SUCCESS,
  CHANGE_CONFIGURATION_FAIL,
  SET_CHARGING_PROFILE,
  SET_CHARGING_PROFILE_SUCCESS,
  SET_CHARGING_PROFILE_FAIL,
  CLEAR_CHARGING_PROFILE,
  CLEAR_CHARGING_PROFILE_SUCCESS,
  CLEAR_CHARGING_PROFILE_FAIL,
} from 'actions/chargerOcpp';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';

function* cancelReservation(action) {
  try {
    const response = yield call(API.cancelReservation, action.payload);
    yield put({ type: CANCEL_RESERVATION_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CANCEL_RESERVATION_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* changeAvailability(action) {
  try {
    const response = yield call(API.changeAvailability, action.payload);
    yield put({ type: CHANGE_AVAILABILITY_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CHANGE_AVAILABILITY_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* getConfiguration(action) {
  try {
    const response = yield call(API.getConfiguration, action.payload);
    yield put({ type: GET_CONFIGURATION_SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: GET_CONFIGURATION_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* changeConfiguration(action) {
  try {
    const response = yield call(API.changeConfiguration, action.payload);
    yield put({ type: CHANGE_CONFIGURATION_SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CHANGE_CONFIGURATION_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* getDiagnostics(action) {
  try {
    const response = yield call(API.getDiagnostics, action.payload);
    yield put({ type: GET_DIAGNOSTICS_SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: GET_DIAGNOSTICS_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* clearCache(action) {
  try {
    const response = yield call(API.clearCache, action.payload);
    yield put({ type: CLEAR_CACHE_SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CLEAR_CACHE_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* remoteStartTransaction(action) {
  try {
    const response = yield call(API.remoteStartTransaction, action.payload);
    yield put({ type: REMOTE_START_TRANSACTION_SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: REMOTE_START_TRANSACTION_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* remoteStopTransaction(action) {
  try {
    const response = yield call(API.remoteStopTransaction, action.payload);
    yield put({ type: REMOTE_STOP_TRANSACTION_SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: REMOTE_STOP_TRANSACTION_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* reserveNow(action) {
  try {
    const response = yield call(API.reserveNow, action.payload);
    yield put({ type: RESERVE_NOW_SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: RESERVE_NOW_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* reset(action) {
  try {
    const response = yield call(API.reset, action.payload);
    yield put({ type: RESET_SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: RESET_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* sendLocalList(action) {
  try {
    const response = yield call(API.sendLocalList, action.payload);
    yield put({ type: SEND_LOCAL_LIST_SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: SEND_LOCAL_LIST_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* triggerMessage(action) {
  try {
    const response = yield call(API.triggerMessage, action.payload);
    yield put({ type: TRIGGER_MESSAGE_SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: TRIGGER_MESSAGE_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* unlockConnector(action) {
  try {
    const response = yield call(API.unlockConnector, action.payload);
    yield put({ type: UNLOCK_CONNECTOR_SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UNLOCK_CONNECTOR_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* updateFirmware(action) {
  try {
    const response = yield call(API.updateFirmware, action.payload);
    yield put({ type: UPDATE_FIRMWARE_SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_FIRMWARE_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* dataTransferServer(action) {
  try {
    const response = yield call(API.dataTransferServer, action.payload);
    yield put({ type: DATA_TRANSFER_SERVER_SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DATA_TRANSFER_SERVER_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* getLocalListVersion(action) {
  try {
    const response = yield call(API.getLocalListVersion, action.payload);
    yield put({ type: GET_LOCAL_LIST_SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: GET_LOCAL_LIST_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* setChargingProfile(action) {
  try {
    const response = yield call(API.setChargingProfile, action.payload);
    yield put({ type: SET_CHARGING_PROFILE_SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: SET_CHARGING_PROFILE_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* clearChargingProfile(action) {
  try {
    const response = yield call(API.clearChargingProfile, action.payload);
    yield put({ type: CLEAR_CHARGING_PROFILE_SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CLEAR_CHARGING_PROFILE_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* chargerOcpp() {
  yield takeLatest(CANCEL_RESERVATION, cancelReservation);
  yield takeLatest(CHANGE_AVAILABILITY, changeAvailability);
  yield takeLatest(GET_CONFIGURATION, getConfiguration);
  yield takeLatest(CHANGE_CONFIGURATION, changeConfiguration);
  yield takeLatest(GET_DIAGNOSTICS, getDiagnostics);
  yield takeLatest(CLEAR_CACHE, clearCache);
  yield takeLatest(REMOTE_START_TRANSACTION, remoteStartTransaction);
  yield takeLatest(REMOTE_STOP_TRANSACTION, remoteStopTransaction);
  yield takeLatest(RESERVE_NOW, reserveNow);
  yield takeLatest(RESET, reset);
  yield takeLatest(SEND_LOCAL_LIST, sendLocalList);
  yield takeLatest(TRIGGER_MESSAGE, triggerMessage);
  yield takeLatest(UNLOCK_CONNECTOR, unlockConnector);
  yield takeLatest(UPDATE_FIRMWARE, updateFirmware);
  yield takeLatest(DATA_TRANSFER_SERVER, dataTransferServer);
  yield takeLatest(GET_LOCAL_LIST, getLocalListVersion);
  yield takeLatest(SET_CHARGING_PROFILE, setChargingProfile);
  yield takeLatest(CLEAR_CHARGING_PROFILE, clearChargingProfile);

}
export default chargerOcpp;
