import { toast } from 'react-toastify';
import {
  CREATE_CPO,
  CREATE_CPO_SUCCESS,
  CREATE_CPO_FAIL,
  GET_CPO,
  GET_CPO_SUCCESS,
  GET_CPO_FAIL,
  FETCH_CPO,
  FETCH_CPO_SUCCESS,
  FETCH_CPO_FAIL,
  UPDATE_CPO,
  UPDATE_CPO_SUCCESS,
  UPDATE_CPO_FAIL,
  DELETE_CPO,
  DELETE_CPO_SUCCESS,
  DELETE_CPO_FAIL,
} from 'actions/partnerManagement';

const initialState = {
  isLoading: false,
  partnerInfo: [],
  partnerDetails: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const partnerManagementReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CREATE_CPO:
    case FETCH_CPO:
    case DELETE_CPO:
    case GET_CPO: {
      return { ...state, isLoading: true, partnerDetails: {} };
    }

    case UPDATE_CPO: {
      return { ...state, isLoading: true };
    }

    case CREATE_CPO_SUCCESS: {
      toast.success('creaded successfully');
      return {
        ...state,
        isLoading: false,
        partnerInfo: payload,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case GET_CPO_SUCCESS: {
      return {
        ...state,
        partnerInfo: payload,
        isLoading: false,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case FETCH_CPO_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        partnerDetails: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case UPDATE_CPO_SUCCESS: {
      toast.success('updated successfully');
      return {
        ...state,
        isLoading: false,
        partnerInfo: payload,
      };
    }

    case DELETE_CPO_SUCCESS: {
      toast.success('delete successfully');
      return {
        ...state,
        isLoading: false,
        partnerInfo: payload,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case CREATE_CPO_FAIL:
    case UPDATE_CPO_FAIL:
    case FETCH_CPO_FAIL:
    case DELETE_CPO_FAIL:
    case GET_CPO_FAIL: {
      return { ...state, isLoading: false };
    }
    default:
      return { ...state };
  }
};
export default partnerManagementReducer;
