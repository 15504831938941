import { GET_AREA_BY_CITY, GET_AREA_BY_CITY_FAIL, GET_AREA_BY_CITY_SUCCESS } from 'actions/address';
import * as _ from 'lodash';

const initialState = {
  isLoading: false,
  areaByCities: [],
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const areaByCityReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_AREA_BY_CITY: {
      return { ...state, isLoading: true };
    }

    case GET_AREA_BY_CITY_SUCCESS: {
      if (payload.page === 1) {
        return {
          ...state,
          isLoading: false,
          areaByCities: payload.results,
          page: payload.page,
          limit: payload.limit,
          totalPages: payload.totalPages,
          total: payload.totalResults,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          areaByCities: _.concat(state.areaByCities, payload.results),
          page: payload.page,
          limit: payload.limit,
          totalPages: payload.totalPages,
          total: payload.totalResults,
        };
      }
    }

    case GET_AREA_BY_CITY_FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};
export default areaByCityReducer;
