export const FETCH_PRIVACY = 'FETCH_PRIVACY';
export const FETCH_PRIVACY_SUCCESS = 'FETCH_PRIVACY_SUCCESS';
export const FETCH_PRIVACY_FAIL = 'FETCH_PRIVACY_FAIL';

export const GET_PRIVACY = 'GET_PRIVACY';
export const GET_PRIVACY_SUCCESS = 'GET_PRIVACY_SUCCESS';
export const GET_PRIVACY_FAIL = 'GET_PRIVACY_FAIL';

export const CREATE_PRIVACY = 'CREATE_PRIVACY';
export const CREATE_PRIVACY_SUCCESS = 'CREATE_PRIVACY_SUCCESS';
export const CREATE_PRIVACY_FAIL = 'CREATE_PRIVACY_FAIL';

export const UPDATE_PRIVACY = 'UPDATE_PRIVACY';
export const UPDATE_PRIVACY_SUCCESS = ' UPDATE_PRIVACY_SUCCESS';
export const UPDATE_PRIVACY_FAIL = 'UPDATE_PRIVACY_FAIL';

export const DELETE_PRIVACY = 'DELETE_PRIVACY';
export const DELETE_PRIVACY_SUCCESS = 'DELETE_PRIVACY_SUCCESS';
export const DELETE_PRIVACY_FAIL = ' DELETE_PRIVACY_FAIL';
