export const FETCH_MASTER_SETTING = 'FETCH_MASTER_SETTING';
export const FETCH_MASTER_SETTING_SUCCESS = 'FETCH_MASTER_SETTING_SUCCESS';
export const FETCH_MASTER_SETTING_FAIL = 'FETCH_MASTER_SETTING_FAIL';

export const GET_MASTER_SETTING = 'GET_MASTER_SETTING';
export const GET_MASTER_SETTING_SUCCESS = 'GET_MASTER_SETTING_SUCCESS';
export const GET_MASTER_SETTING_FAIL = 'GET_MASTER_SETTING_FAIL';

export const UPDATE_MATER_SETTING = 'UPDATE_MATER_SETTING';
export const UPDATE_MATER_SETTING_SUCCESS = 'UPDATE_MATER_SETTING_SUCCESS';
export const UPDATE_MATER_SETTING_FAIL = 'UPDATE_MATER_SETTING_FAIL';
