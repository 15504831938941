import {
  DELETE_NOTIFICATION,
  DELETE_NOTIFICATION_FAIL,
  DELETE_NOTIFICATION_SUCCESS,
  FETCH_NOTIFICATION,
  FETCH_NOTIFICATION_FAIL,
  FETCH_NOTIFICATION_SUCCESS,
  UPDATE_FIRMWARE_NOTIFICATION,
  UPDATE_FIRMWARE_NOTIFICATION_SUCCESS,
  UPDATE_FIRMWARE_NOTIFICATION_FAIL,
  NOTIFICATION,
  REMOVE_NOTIFICATION,
} from 'actions/notification';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  notifications: [],
  notificationCount: 0,
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};
const notificationReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_NOTIFICATION:
    case DELETE_NOTIFICATION:
    case UPDATE_FIRMWARE_NOTIFICATION: {
      return { ...state, isLoading: true };
    }
    case FETCH_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        notifications: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }
    case NOTIFICATION: {
      return {
        ...state,
        notificationCount: payload,
      };
    }

    case REMOVE_NOTIFICATION: {
      return {
        ...state,
        notificationCount: 0,
      };
    }

    case UPDATE_FIRMWARE_NOTIFICATION_SUCCESS: {
      toast.success('Firmware update Successfully');
      return {
        ...state,
        // notifications: state.notifications.map((data) => (data.id === payload.id ? payload : data)),
      };
    }

    case DELETE_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        notifications: state.notifications.filter((data) => data.id !== payload),
      };
    }

    case FETCH_NOTIFICATION_FAIL:
    case UPDATE_FIRMWARE_NOTIFICATION_FAIL:
    case DELETE_NOTIFICATION_FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return { ...state };
  }
};
export default notificationReducer;
