import React, { useCallback, useEffect, useState } from 'react';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import { useParams } from 'react-router-dom';
import Card from 'components/inputs/Card';
import { FaChargingStation } from 'react-icons/fa';
import General from './General';
import Report from './Report';
import { useDispatch, useSelector } from 'react-redux';
import * as _ from 'lodash';
//import { Spinner } from 'react-bootstrap';
import { GET_CPO } from 'actions/partnerManagement';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const StationDetails = () => {
  const { ocpiId } = useParams();
  const [currentTab, setCurrentTab] = useState('general');
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.partnerManagement.isLoading);
  const ocpiData = useSelector((state) => state.partnerManagement.partnerInfo);

  const getEmspData = useCallback(() => {
    dispatch({ type: GET_CPO, payload: ocpiId });
  }, []);

  useEffect(() => {
    ocpiId && getEmspData();
  }, []);

  const setTab = (e, tab) => {
    e.preventDefault();
    setCurrentTab(tab);
  };

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={_.get(ocpiData, 'partyId', '')} backTo={'/OCPI-Partner'} />
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
          <div className="page-content-wrapper scrollable">
            {isLoading ? (
              <div className="station-details__main">
                <div className="loader--block">
                  <Skeleton height={15} width={100} />
                </div>
              </div>
            ) : (
              <div className="station-details__main">
                <Card>
                  <div className="station-info--box">
                    <div className="station-info-content">
                      <div className="station-info__inner">
                        <div className="station-icon--block">
                          <div className="station-icon">{isLoading ? <Skeleton height={30} width={30} circle="true" /> : <FaChargingStation />}</div>
                        </div>
                        <div className="station-info--wrapper">
                          <div className="station-status--block">
                            <span className="info-name">{isLoading ? <Skeleton height={15} width={100} /> : _.get(ocpiData, 'partyId', '')}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="tab-block">
                      <div className="tab-nav-block">
                        <a
                          href="#"
                          className={`tab-nav__item ${currentTab === 'general' ? 'active' : ''}`}
                          onClick={(e) => setTab(e, 'general')}
                          data-content="tab-general"
                        >
                          {isLoading ? <Skeleton height={15} width={100} /> : 'Station Charging Summary'}
                        </a>
                        <a
                          href="#"
                          className={`tab-nav__item ${currentTab === 'report' ? 'active' : ''}`}
                          onClick={(e) => setTab(e, 'report')}
                          data-content="tab-chargers"
                        >
                          {isLoading ? <Skeleton height={15} width={100} /> : 'Charging History Report'}
                        </a>
                      </div>
                      <div className="tab-content-block">
                        <div className="tab-content__item active" id="station-details-tab">
                          {currentTab === 'general' && <General />}
                          {currentTab === 'report' && <Report />}
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            )}
          </div>
        </SkeletonTheme>
      </LayoutContainer>
    </>
  );
};

export default StationDetails;
