import {
  FETCH_CHARGE_SPEED,
  FETCH_CHARGE_SPEED_SUCCESS,
  FETCH_CHARGE_SPEED_FAIL,
  GET_CHARGE_SPEED,
  GET_CHARGE_SPEED_SUCCESS,
  GET_CHARGE_SPEED_FAIL,
  CREATE_CHARGE_SPEED,
  CREATE_CHARGE_SPEED_SUCCESS,
  CREATE_CHARGE_SPEED_FAIL,
  UPDATE_CHARGE_SPEED,
  UPDATE_CHARGE_SPEED_SUCCESS,
  UPDATE_CHARGE_SPEED_FAIL,
  DELETE_CHARGE_SPEED,
  DELETE_CHARGE_SPEED_SUCCESS,
  DELETE_CHARGE_SPEED_FAIL,
} from 'actions/chargeSpeed';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';

function* fetchChargeSpeed(action) {
  try {
    const response = yield call(API.getChargeSpeed, action.payload);
    yield put({ type: FETCH_CHARGE_SPEED_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_CHARGE_SPEED_FAIL, payload: { error: e } });
  }
}

function* getChargeSpeedById(action) {
  try {
    const response = yield call(API.getChargeSpeedById, action.payload);
    yield put({ type: GET_CHARGE_SPEED_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_CHARGE_SPEED_FAIL, payload: { error: e } });
  }
}

function* createChargeSpeed(action) {
  try {
    const response = yield call(API.addChargeSpeed, action.payload);
    yield put({ type: CREATE_CHARGE_SPEED_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_CHARGE_SPEED_FAIL, payload: { error: e } });
  }
}

function* updateChargeSpeed(action) {
  try {
    const response = yield call(API.updateChargeSpeed, action.payload);
    yield put({ type: UPDATE_CHARGE_SPEED_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_CHARGE_SPEED_FAIL, payload: { error: e } });
  }
}

function* deleteChargeSpeed(action) {
  try {
    const response = yield call(API.deleteChargeSpeed, action.payload);
    yield put({ type: DELETE_CHARGE_SPEED_SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_CHARGE_SPEED_FAIL, payload: { error: e } });
  }
}

function* chargeSpeed() {
  yield takeLatest(FETCH_CHARGE_SPEED, fetchChargeSpeed);
  yield takeLatest(GET_CHARGE_SPEED, getChargeSpeedById);
  yield takeLatest(CREATE_CHARGE_SPEED, createChargeSpeed);
  yield takeLatest(UPDATE_CHARGE_SPEED, updateChargeSpeed);
  yield takeLatest(DELETE_CHARGE_SPEED, deleteChargeSpeed);
}
export default chargeSpeed;
