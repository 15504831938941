import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import {
  CREATE_CPO,
  CREATE_CPO_SUCCESS,
  CREATE_CPO_FAIL,
  UPDATE_CPO,
  UPDATE_CPO_SUCCESS,
  UPDATE_CPO_FAIL,
  FETCH_CPO,
  FETCH_CPO_SUCCESS,
  FETCH_CPO_FAIL,
  DELETE_CPO,
  DELETE_CPO_SUCCESS,
  DELETE_CPO_FAIL,
  GET_CPO,
  GET_CPO_SUCCESS,
  GET_CPO_FAIL,
  PULL_LOCATION,
  PULL_LOCATION_SUCCESS,
  PULL_LOCATION_FAIL,
  HANDSHAKE,
  HANDSHAKE_SUCCESS,
  HANDSHAKE_FAIL,
  // VIEW_ENDPOINTS,
  // VIEW_ENDPOINTS_SUCCESS,
  // VIEW_ENDPOINTS_FAIL,
  PULL_SESSIONS,
  PULL_SESSIONS_SUCCESS,
  PULL_SESSIONS_FAIL,
  PULL_TARIFF,
  PULL_TARIFF_SUCCESS,
  PULL_TARIFF_FAIL,
  PULL_TOKEN,
  PULL_TOKEN_SUCCESS,
  PULL_TOKEN_FAIL,
  PULL_CDRS,
  PULL_CDRS_SUCCESS,
  PULL_CDRS_FAIL,
  GET_ENDPOINT_SUCCESS,
  GET_ENDPOINT,
  GET_ENDPOINT_FAIL,
} from 'actions/partnerManagement';

function* createCpo(action) {
  try {
    const response = yield call(API.addCpo, action.payload);
    yield put({ type: CREATE_CPO_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_CPO_FAIL, payload: { error: e } });
  }
}

function* getCpo(action) {
  try {
    const response = yield call(API.getCpoData, action.payload);
    yield put({ type: GET_CPO_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: GET_CPO_FAIL, payload: { error: e } });
  }
}

function* fetchCpo(action) {
  try {
    const response = yield call(API.fetchCpoData, action.payload);
    yield put({ type: FETCH_CPO_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_CPO_FAIL, payload: { error: e } });
  }
}

function* updateCpo(action) {
  try {
    const response = yield call(API.updateCpo, action.payload);
    yield put({ type: UPDATE_CPO_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_CPO_FAIL, payload: { error: e } });
  }
}

function* deleteCpo(action) {
  try {
    const response = yield call(API.removeCpo, action.payload);
    yield put({ type: DELETE_CPO_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_CPO_FAIL, payload: { error: e } });
  }
}

// OCPI Admin operation

function* handshaking(action) {
  try {
    const response = yield call(API.handshake, action.payload);
    yield put({ type: HANDSHAKE_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: HANDSHAKE_FAIL, payload: { error: e } });
  }
}

function* getEndPointByToken(action) {
  try {
    const response = yield call(API.getEndpointByToken, action.payload);
    yield put({ type: GET_ENDPOINT_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: GET_ENDPOINT_FAIL, payload: { error: e } });
  }
}

function* pullLucation(action) {
  try {
    const response = yield call(API.pullLucations, action.payload);
    yield put({ type: PULL_LOCATION_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: PULL_LOCATION_FAIL, payload: { error: e } });
  }
}

function* pullTariff(action) {
  try {
    const response = yield call(API.pullTariffs, action.payload);
    yield put({ type: PULL_TARIFF_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: PULL_TARIFF_FAIL, payload: { error: e } });
  }
}

function* pullSession(action) {
  try {
    const response = yield call(API.pullSession, action.payload);
    yield put({ type: PULL_SESSIONS_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: PULL_SESSIONS_FAIL, payload: { error: e } });
  }
}

function* pullCdrs(action) {
  try {
    const response = yield call(API.pullCdrs, action.payload);
    yield put({ type: PULL_CDRS_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: PULL_CDRS_FAIL, payload: { error: e } });
  }
}

function* pullToken(action) {
  try {
    const response = yield call(API.token, action.payload);
    yield put({ type: PULL_TOKEN_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: PULL_TOKEN_FAIL, payload: { error: e } });
  }
}

function* partnerManagement() {
  yield takeLatest(CREATE_CPO, createCpo);
  yield takeLatest(GET_CPO, getCpo);
  yield takeLatest(FETCH_CPO, fetchCpo);
  yield takeLatest(UPDATE_CPO, updateCpo);
  yield takeLatest(DELETE_CPO, deleteCpo);
  yield takeLatest(PULL_LOCATION, pullLucation);
  yield takeLatest(HANDSHAKE, handshaking);
  yield takeLatest(GET_ENDPOINT, getEndPointByToken);
  yield takeLatest(PULL_SESSIONS, pullSession);
  yield takeLatest(PULL_TARIFF, pullTariff);
  yield takeLatest(PULL_TOKEN, pullToken);
  yield takeLatest(PULL_CDRS, pullCdrs);
}
export default partnerManagement;
