export const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
export const REACT_APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

export let BASE_URL = "";
export let CHART_IDS = {};

if (REACT_APP_ENVIRONMENT === 'production') {
    BASE_URL = "https://charts.mongodb.com/charts-project-0-ypyhz";
    CHART_IDS = Object.freeze({
        // Production Chart ID's

        BOOKING_MONTHLY: "98c76f93-eda2-4a0d-ae9f-5b2a2d5d279b",
        BOOKING_WEEKLY: "7443e52c-35f2-40a9-b281-bf951cbca183",
        CHARGERSTATUS: "224f5b21-7a7f-4ba4-9b58-6e1f83688a36",
        ENERGYSOLD_MONTHLY: "fba7c492-8bc0-4019-861d-9322d9a4784e",
        ENERGYSOLD_WEEKLY: "e902ba52-af61-44f3-83f8-1e357dd8f793",
        SESSIONS_TODAY: "6438d1f3-2306-4477-8df1-eb108452947d",
        TOTAL_CHARGESPOTS: "e5ac3813-1fcd-40b0-b110-857380dc46ac",
        TOTAL_REVENUE: "6438fd6b-0ee5-4637-87c0-65a48b476e7f",
        TOTAL_USERS: "643e2914-54ed-403b-8039-04ffb14fe0e3",
        CHARGER_TYPES: "c35cd55c-7475-432c-8572-08db89c0ebc0",
        UNITS_TODAY: "4332c894-70f2-4046-890a-44fba775c8c7",
        HEATMAP: "6455dab0-53b8-411f-8d67-984ac0aeb821",
    });
} else {

    //UAT CHART ID's
    BASE_URL = "https://charts.mongodb.com/charts-project-0-pfzuv";
    CHART_IDS = Object.freeze({
        BOOKING_MONTHLY: "6451fb9a-49cf-4016-8ed7-4a40e5783dd7",
        BOOKING_WEEKLY: "6451fb9a-49cf-4c93-849e-4a40e5783dd5",
        CHARGERSTATUS: "6451fb9a-49cf-49dc-8bd4-4a40e5783dc5",
        ENERGYSOLD_MONTHLY: "6451fb9a-49cf-4c55-8d05-4a40e5783dd3",
        ENERGYSOLD_WEEKLY: "6451fb9a-49cf-4338-8a27-4a40e5783dd1",
        SESSIONS_TODAY: "6451fb9a-49cf-4829-8b2b-4a40e5783dc9",
        TOTAL_CHARGESPOTS: "6451fb9a-49cf-4c7e-8cc0-4a40e5783dc7",
        TOTAL_REVENUE: "6451fb9a-49cf-4f7b-81de-4a40e5783dcb",
        TOTAL_USERS: "6451fb9a-49cf-4853-8465-4a40e5783dd9",
        CHARGER_TYPES: "6451fb9a-49cf-42a5-8310-4a40e5783dcf",
        UNITS_TODAY: "6451fb9a-49cf-4b98-89d7-4a40e5783dcd",
        HEATMAP: "6458d70d-d3ec-4740-811e-6b93ed95b763",
    });
}