import React, { useCallback, useEffect, useState, useContext } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header/index';
import { Col, Row } from 'react-bootstrap';
import { BsLightningCharge } from 'react-icons/bs';
import { RiChargingPile2Line, RiWalletLine } from 'react-icons/ri';
import { MdOutlinePeopleAlt } from 'react-icons/md';
import { GiCharging } from 'react-icons/gi';
import { BiRupee } from 'react-icons/bi';
import PropTypes from 'prop-types';
import { BsDot } from 'react-icons/bs';
import Avatar from 'components/inputs/Avatar';
import DummyImage from 'assets/images/icons/dummyImage.png';
import { GET_DASHBOARD_DATA } from 'actions/dashboard';
import { useDispatch, useSelector } from 'react-redux';
import Card from 'components/inputs/Card';
import * as _ from 'lodash';
import {
  SUPER_ADMIN,
  ADMIN,
  DOCO,
  FLEET_OWNER,
  GET_WIDGET_SESSIONS_TODAY,
  GET_WIDGET_TOTAL_REVENUE,
  GET_WIDGET_CHARGERS_IN_USE,
  GET_WIDGET_CHARGER_UNITS_FOR_TODAY,
  GET_WIDGET_TYPE_OF_CHARGER,
  GET_WIDGET_ENERGY_SOLD,
  GET_WIDGET_CHARGER_STATUS,
  GET_WIDGET_BOOKING_COUNT,
  GET_WIDGET_NUMBER_OF_TOTAL_USERS,
} from 'components/common/constant';
import { AiFillCar, AiOutlineCar } from 'react-icons/ai';
import moment from 'moment';
// import { useHistory } from 'react-router-dom';
import { roundOfDigit } from 'components/common/utils';
import { Chart as ChartJS, CategoryScale, ArcElement, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import ChargerStatuschart from './ChargerStatuschart';
import TotalChargeSpots from './TotalChargeSpots';
import SessionsToday from './SessionsToday';
import UnitsToday from './UnitsToday';
import TotalRevenue from './TotalRevenue';
import TypeOfCharger from './TypeOfCharger';
import EnergySoldWeekly from './EnergySoldWeekly';
import EnergySoldMonthly from './EnergySoldMonthly';
import BookingWeekly from './BookingWeekly';
import BookingMonthly from './BookingMonthly';
import TotalUsers from './TotalUsers';
import './Chartstyle.css';
import { useTranslation } from 'react-i18next';
import Footer from 'components/general/Footer';
import { NavContext } from 'components/privateRoute/PrivateRoute';
import { FaRegUser } from 'react-icons/fa';

ChartJS.register(ArcElement, CategoryScale, LinearScale, PointElement, BarElement, LineElement, Title, Tooltip, Legend);

const Dashboard = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // const history = useHistory();
  const { navigateTo, isAllowed } = useContext(NavContext);

  const dashboardList = useSelector((state) => state.dashboard.dashboard);
  const profileData = useSelector((state) => state.profile.userProfile);
  const rolePermissionData = useSelector((state) => state.manageRole.manageRoles);

  const currentUserRole = _.get(profileData, 'role', '');
  const currentUserName = _.get(profileData, 'name', '');
  const saasClientTotal = _.get(
    _.find(_.get(dashboardList, 'saasClients', []), (item) => _.has(item, 'total')),
    'total',
    0
  );
  const [currentScheduleOfBookingCount, setCurrentScheduleOfBookingCount] = useState('Weekly');
  const [currentScheduleOfEnergySold, setCurrentScheduleOfEnergySold] = useState('Weekly');

  const getDashboardData = useCallback(() => {
    dispatch({ type: GET_DASHBOARD_DATA });
  }, []);

  useEffect(() => {
    getDashboardData();
  }, []);

  const sessionTodayPermission =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === GET_WIDGET_SESSIONS_TODAY),
      (data) => data.name
    );

  const totalRevenuePermission =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === GET_WIDGET_TOTAL_REVENUE),
      (data) => data.name
    );

  const chargerInUsePermission =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === GET_WIDGET_CHARGERS_IN_USE),
      (data) => data.name
    );

  const chargerUnitsForTodayPermission =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === GET_WIDGET_CHARGER_UNITS_FOR_TODAY),
      (data) => data.name
    );

  const typeOfChargerPermission =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === GET_WIDGET_TYPE_OF_CHARGER),
      (data) => data.name
    );

  const energySoldPermission =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === GET_WIDGET_ENERGY_SOLD),
      (data) => data.name
    );

  const chargerStatusPermission =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === GET_WIDGET_CHARGER_STATUS),
      (data) => data.name
    );

  const bookingCountPermission =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === GET_WIDGET_BOOKING_COUNT),
      (data) => data.name
    );

  const numberOfTotalUsersPermission =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === GET_WIDGET_NUMBER_OF_TOTAL_USERS),
      (data) => data.name
    );

  const energySold =
    currentScheduleOfEnergySold === 'Weekly'
      ? {
          labels: _.map(dashboardList.weeklyEnergySold, (item) => item.weekDay),
          datasets: [
            {
              label: t('dashboard.energySoldWeekly'),
              data: _.map(dashboardList.weeklyEnergySold, (item) => item.total / 1000),
              borderColor: '#FED035',
            },
          ],
        }
      : {
          labels: _.map(dashboardList.monthlyEnergySold, (item) => item.month),
          datasets: [
            {
              label: t('dashboard.energySoldWeekly'),
              data: _.map(dashboardList.monthlyEnergySold, (item) => item.total / 1000),
              borderColor: '#f1cf78',
            },
          ],
        };

  const chargerBookingCount =
    currentScheduleOfBookingCount === 'Weekly'
      ? {
          labels: _.map(dashboardList.weeklySessionCount, (item) => item.weekDay),
          datasets: [
            {
              label: t('dashboard.weeklySessionCount'),
              data: _.map(dashboardList.weeklySessionCount, (item) => item.count),
              backgroundColor: '#8548B4',
              barThickness: 7,
              maxBarThickness: 8,
            },
          ],
        }
      : {
          labels: _.map(dashboardList.monthlySessionCount, (item) => item.month),
          datasets: [
            {
              label: t('dashboard.monthlySessionCount'),
              data: _.map(dashboardList.monthlySessionCount, (item) => item.count),
              backgroundColor: '#8548B4',
              barThickness: 7,
            },
          ],
        };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          boxWidth: 10,
          color: '#ffffff',
        },
      },
    },
  };

  const currentTenantId = profileData?.tenant?.id;
  const currentStation = profileData.charging_stations;

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title={t('header.dashboard')} />
        <div className="page-content-wrapper scrollable" id="temp">
          <div className="dashboard-details__main">
            <div className="dashboard-details--inner">
              <div className="dashboard-details__block">
                <Row>
                  {currentUserRole === FLEET_OWNER && (
                    <>
                      <Col xl={3} md={6}>
                        <DashboardCard
                          title={_.get(dashboardList, 'todaySession', '')}
                          icon={<BsLightningCharge className="icon--color" />}
                          subTitle={t('dashboard.sessionToday')}
                        />
                      </Col>
                      <Col xl={3} md={6}>
                        <DashboardCard
                          title={_.get(dashboardList, 'totalVehicleCount', '')}
                          icon={<AiOutlineCar className="icon--color" />}
                          subTitle={t('dashboard.totalVehicles')}
                        />
                      </Col>
                      <Col xl={3} md={6}>
                        <DashboardCard
                          title={_.get(dashboardList, 'totalFleetMember', '')}
                          icon={<FaRegUser className="icon--color" />}
                          subTitle={t('dashboard.totalFleetMembers')}
                        />
                      </Col>
                      <Col xl={3} md={6}>
                        <DashboardCard
                          title={roundOfDigit(_.get(dashboardList, 'availableWalletBalance', ''), 2)}
                          icon={<RiWalletLine className="icon--color" />}
                          subTitle={t('dashboard.availableWalletBalance')}
                        />
                      </Col>
                    </>
                  )}
                  <Col xl={3} md={6}>
                    {isAllowed('viewSessionsToday') && (
                      <DashboardCard
                        title={
                          <SessionsToday rolePermission={sessionTodayPermission} currentTenantId={currentTenantId} currentStation={currentStation} />
                        }
                        icon={<BsLightningCharge className="icon--color" />}
                        subTitle={t('dashboard.sessionToday')}
                      />
                    )}
                  </Col>
                  <Col xl={3} md={6}>
                    {isAllowed('viewTotalRevenue') && (
                      <DashboardCard
                        title={
                          <TotalRevenue rolePermission={totalRevenuePermission} currentTenantId={currentTenantId} currentStation={currentStation} />
                        }
                        metaIcon={<BiRupee className="icon--color" />}
                        subTitle={t('dashboard.todaysRevenue')}
                      />
                    )}
                  </Col>
                  <Col xl={3} md={6}>
                    {isAllowed('viewTotalChargePoint') && (
                      <DashboardCard
                        title={
                          <TotalChargeSpots
                            rolePermission={chargerInUsePermission}
                            currentTenantId={currentTenantId}
                            currentStation={currentStation}
                          />
                        }
                        icon={<RiChargingPile2Line className="icon--color" />}
                        subTitle={currentUserRole === DOCO ? t('dashboard.myChargePoints') : t('dashboard.totalChargepoints')}
                      />
                    )}
                  </Col>
                  <Col xl={3} md={6}>
                    {isAllowed('viewUnitConsumedToday') && (
                      <DashboardCard
                        title={
                          <UnitsToday
                            rolePermission={chargerUnitsForTodayPermission}
                            currentTenantId={currentTenantId}
                            currentStation={currentStation}
                          />
                        }
                        metaIcon={<GiCharging className="icon-inner-color" />}
                        subTitle={t('dashboard.unitsConsumedToday')}
                      />
                    )}
                  </Col>
                </Row>
              </div>
              <div className="dashboard-table__main">
                <Row>
                  {currentUserRole === FLEET_OWNER && (
                    <>
                      <Col xl={6} lg={12} md={12}>
                        <Card>
                          <div className="dashboard-energySold_chart_main">
                            <div className="dashboard-energySold_chart_inner">
                              <div className="dashboard-energySold_chart_block">
                                <div className="dashboard-energySold_name">{t('dashboard.energySold')}</div>
                              </div>
                              <div className="dashboard-energySold_chart_content">
                                <span
                                  className={`dashboard-energySold__days ${currentScheduleOfEnergySold === 'Weekly' ? 'active' : ''}`}
                                  onClick={() => setCurrentScheduleOfEnergySold('Weekly')}
                                >
                                  {t('dashboard.weekly')}
                                </span>
                                <span
                                  className={`dashboard-energySold_days ${currentScheduleOfEnergySold === 'Monthly' ? 'active' : ''}`}
                                  onClick={() => setCurrentScheduleOfEnergySold('Monthly')}
                                >
                                  {t('dashboard.monthly')}
                                </span>
                                <div></div>
                                <span className="dashboard-energySold_line" />
                              </div>
                            </div>
                            <div className="dashboard-energySold_chart-img">
                              <div className="energySold_chart-img">
                                <Line data={energySold} options={options} height="110px" />
                              </div>
                            </div>
                          </div>
                        </Card>
                      </Col>
                      <Col xl={6} lg={12} md={12}>
                        <Card>
                          <div className="dashboard-bookingCount_chart_main">
                            <div className="dashboard-bookingCount_chart_inner">
                              <div className="dashboard-bookingCount_chart_block">
                                <div className="dashboard-bookingCount_name">{t('dashboard.sessionCount')}</div>
                              </div>
                              <div className="dashboard-bookingCount_chart_content">
                                <span
                                  className={`dashboard-bookingCount__days ${currentScheduleOfBookingCount === 'Weekly' ? 'active' : ''}`}
                                  onClick={() => setCurrentScheduleOfBookingCount('Weekly')}
                                >
                                  {t('dashboard.weekly')}
                                </span>
                                <span
                                  className={`dashboard-bookingCount_days ${currentScheduleOfBookingCount === 'Monthly' ? 'active' : ''}`}
                                  onClick={() => setCurrentScheduleOfBookingCount('Monthly')}
                                >
                                  {t('dashboard.monthly')}
                                </span>
                              </div>
                            </div>
                            <div className="dashboard-bookingCount_chart-img">
                              <div className="bookingCount_chart-img">
                                <Bar data={chargerBookingCount} options={options} height="110px" />
                              </div>
                            </div>
                          </div>
                        </Card>
                      </Col>
                      <Col xl={12} lg={12} md={12}>
                        <Card>
                          <div className="dashboard-realTime_event_main">
                            <div className="dashboard-realTime_event_table">
                              <div className="realTime_event-header_name">
                                <span className="mb-3">{t('dashboard.realtimeEvents')}</span>
                              </div>
                              <div className="realTime_event-table scrollable">
                                {_.map(_.get(dashboardList, 'realTimeEvents', []), (data, index) => {
                                  return (
                                    <TableItem
                                      key={`realTimeEvents-${index}`}
                                      icon={<BsDot />}
                                      date={moment(data.createdAt).format('DD MMM YYYY - hh:mm A')}
                                      text={_.get(data, 'message', '')}
                                    />
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </Card>
                      </Col>
                    </>
                  )}
                  <Col xl={currentUserRole === ADMIN || currentUserRole === DOCO ? 12 : 8} lg={12} md={12}>
                    <Row>
                      {isAllowed('viewEnergySoldGraph') && (
                        <Col md={12}>
                          <Card>
                            <div className="dashboard-energySold_chart_main">
                              <div className="dashboard-energySold_chart_inner">
                                <div className="dashboard-energySold_chart_block">
                                  <div className="dashboard-energySold_name">{t('dashboard.energySold')}</div>
                                </div>
                                <div className="dashboard-energySold_chart_content">
                                  <span
                                    className={`dashboard-energySold__days ${currentScheduleOfEnergySold === 'Weekly' ? 'active' : ''}`}
                                    onClick={() => setCurrentScheduleOfEnergySold('Weekly')}
                                  >
                                    {t('dashboard.weekly')}
                                  </span>
                                  <span
                                    className={`dashboard-energySold_days ${currentScheduleOfEnergySold === 'Monthly' ? 'active' : ''}`}
                                    onClick={() => setCurrentScheduleOfEnergySold('Monthly')}
                                  >
                                    {t('dashboard.monthly')}
                                  </span>
                                  <div></div>
                                  <span className="dashboard-energySold_line" />
                                </div>
                              </div>
                              <div className="dashboard-energySold_chart-img">
                                <div className="energySold_chart-img">
                                  {currentScheduleOfEnergySold === 'Weekly' && (
                                    <EnergySoldWeekly
                                      rolePermission={energySoldPermission}
                                      currentTenantId={currentTenantId}
                                      currentStation={currentStation}
                                    />
                                  )}
                                  {currentScheduleOfEnergySold === 'Monthly' && (
                                    <EnergySoldMonthly
                                      rolePermission={energySoldPermission}
                                      currentTenantId={currentTenantId}
                                      currentStation={currentStation}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </Card>
                        </Col>
                      )}
                      {isAllowed('viewRealTimeEvents') && (
                        <Col md={12}>
                          <Card>
                            <div className="dashboard-realTime_event_main">
                              <div className="dashboard-realTime_event_table">
                                <div className="realTime_event-header_name">
                                  <span className="mb-3">Realtime events</span>
                                  {currentUserRole !== DOCO && (
                                    <div className="dashboard-action--block">
                                      <a className="dashboard-action--btn" onClick={() => navigateTo('/notification')}>
                                        {t('dashboard.seeAll')}
                                      </a>
                                    </div>
                                  )}
                                </div>
                                <div className="realTime_event-table scrollable">
                                  {_.map(_.get(dashboardList, 'realTimeEvents', []), (data, index) => {
                                    return (
                                      <TableItem
                                        key={`realTimeEvents-${index}`}
                                        icon={<BsDot />}
                                        date={moment(data.createdAt).format('DD MMM YYYY - hh:mm A')}
                                        text={_.get(data, 'message', '')}
                                      />
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          </Card>
                        </Col>
                      )}
                      <div id="flextemp">
                        {/* Total Bookings     */}
                        {isAllowed('viewSessionCountGraph') && (
                          <Col xl={6} lg={12} md={12} id="tempcard">
                            <Card>
                              <div className="dashboard-bookingCount_chart_main">
                                <div className="dashboard-bookingCount_chart_inner">
                                  <div className="dashboard-bookingCount_chart_block">
                                    <div className="dashboard-bookingCount_name">{t('dashboard.sessionCount')}</div>
                                  </div>
                                  <div className="dashboard-bookingCount_chart_content">
                                    <span
                                      className={`dashboard-bookingCount__days ${currentScheduleOfBookingCount === 'Weekly' ? 'active' : ''}`}
                                      onClick={() => setCurrentScheduleOfBookingCount('Weekly')}
                                    >
                                      {t('dashboard.weekly')}
                                    </span>
                                    <span
                                      className={`dashboard-bookingCount_days ${currentScheduleOfBookingCount === 'Monthly' ? 'active' : ''}`}
                                      onClick={() => setCurrentScheduleOfBookingCount('Monthly')}
                                    >
                                      {t('dashboard.monthly')}
                                    </span>
                                  </div>
                                </div>
                                <div className="dashboard-bookingCount_chart-img">
                                  <div className="bookingCount_chart-img">
                                    {/* <Bar data={chargerBookingCount} options={options} height="110px" /> */}
                                    {currentScheduleOfBookingCount === 'Weekly' && (
                                      <BookingWeekly
                                        rolePermission={bookingCountPermission}
                                        currentTenantId={currentTenantId}
                                        currentStation={currentStation}
                                      />
                                    )}
                                    {currentScheduleOfBookingCount === 'Monthly' && (
                                      <BookingMonthly
                                        rolePermission={bookingCountPermission}
                                        currentTenantId={currentTenantId}
                                        currentStation={currentStation}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </Card>
                          </Col>
                        )}
                        {isAllowed('viewUserGraph') && (
                          <Col xl={6} lg={12} md={12} id="tempcard">
                            <Card id="tempcard2">
                              <div className="dashboard-totalUser_chart_main">
                                <div className="dashboard-totalUser_chart_inner">
                                  <div className="dashboard-totalUser_chart_block">
                                    <div className="dashboard-totalUser_name">{t('dashboard.numberOfTotalNewUsers')}</div>
                                  </div>
                                  <div className="dashboard-totalUser_chart_content">
                                    <span className={`dashboard-totalUser_days active`}>{t('dashboard.monthly')}</span>
                                  </div>
                                </div>
                                <div className="dashboard-totalUser_chart-img">
                                  <div className="totalUser_chart-img">
                                    <TotalUsers rolePermission={numberOfTotalUsersPermission} currentTenantId={currentTenantId} />
                                  </div>
                                </div>
                              </div>
                            </Card>
                          </Col>
                        )}
                      </div>
                    </Row>
                  </Col>
                  <Col xl={4} lg={12} md={12}>
                    <Row>
                      {isAllowed('viewChargePointStatus') && (
                        <Col md={12}>
                          <Card>
                            <div className="dashboard-chart_main">
                              <div className="dashboard-chart_inner">
                                <div className="dashboard-chart_name">{t('dashboard.chargepointStatus')}</div>
                                <ChargerStatuschart
                                  rolePermission={chargerStatusPermission}
                                  currentTenantId={currentTenantId}
                                  currentStation={currentStation}
                                />
                              </div>
                            </div>
                          </Card>
                        </Col>
                      )}
                      {isAllowed('viewTypeOfStation') && (
                        <Col md={12}>
                          <Card>
                            <div className="dashboard-chart_main">
                              <div className="dashboard-chart_inner">
                                <div className="dashboard-chart_name">{t('dashboard.typeOfStation')}</div>
                                <TypeOfCharger
                                  rolePermission={typeOfChargerPermission}
                                  currentTenantId={currentTenantId}
                                  currentStation={currentStation}
                                />
                              </div>
                            </div>
                          </Card>
                        </Col>
                      )}
                      {currentUserRole === SUPER_ADMIN && (
                        <Col md={12}>
                          <Card>
                            <div className="dashboard__sassClient__main">
                              <div className="dashboard__saasClient_card">
                                <DashboardCard
                                  title={saasClientTotal}
                                  icon={<MdOutlinePeopleAlt color="#3C7CDD" className="icon--color" />}
                                  subTitle={t('dashboard.totalSaasClient')}
                                />
                              </div>
                              <div className="dashboard_inner_saasClient">
                                <div className="dashboard__top_name">{t('dashboard.top5')}</div>
                                {_.map(dashboardList.topFiveUsers, (data, index) => {
                                  return (
                                    <div key={index}>
                                      {_.map(data.user, (item) => {
                                        return (
                                          <div key={index}>
                                            <SaasClient
                                              imgSrc={_.get(item, 'picture', DummyImage)}
                                              text={_.get(item, 'name', '')}
                                              number={index + 1}
                                            />
                                          </div>
                                        );
                                      })}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </Card>
                        </Col>
                      )}
                      {isAllowed('viewRecentChargerBooking') && (
                        <Col md={12}>
                          <Card className="dashboard-recent--main">
                            <div className="dashboard-recent--inner">
                              <div className="dashboard-recent--block">
                                <span className="dashboard-recent--name">{t('dashboard.recentChargerBooking')}</span>
                              </div>
                              <div className="dashboard-recent--table_block">
                                {_.map(dashboardList.recentChargerBookings, (data, index) => {
                                  return (
                                    <div key={index}>
                                      <RecentTable
                                        text={_.get(data, 'user.name', '')}
                                        imgSrc={DummyImage}
                                        vehicleMake={_.get(data, 'vehicle.make', '')}
                                        vehicleModel={_.get(data, 'vehicle.model', '')}
                                        icon={<AiFillCar />}
                                        date={moment(_.get(data, 'schedule_datetime')).format('DD MMM')}
                                        time={moment(_.get(data, 'schedule_datetime')).format('hh:mm A')}
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </Card>
                        </Col>
                      )}
                      {/*{currentUserRole === CPO && (*/}
                      {/*  <Col md={12}>*/}
                      {/*    <Card className="dashboard-recent--main">*/}
                      {/*      <div className="dashboard-recent--inner">*/}
                      {/*        <div className="dashboard-recent--block">*/}
                      {/*          <span className="dashboard-recent--name">Recent Charges</span>*/}
                      {/*          <div className="dashboard-recent--block_content">*/}
                      {/*            <a>See all</a>*/}
                      {/*          </div>*/}
                      {/*        </div>*/}
                      {/*        <div className="dashboard-recent--table_block">*/}
                      {/*          <RecentTable*/}
                      {/*            text={'Clair Stanton'}*/}
                      {/*            imgSrc={DummyImage}*/}
                      {/*            subText={'Tesla Model S'}*/}
                      {/*            icon={<AiFillCar />}*/}
                      {/*            date={'23 Dec.'}*/}
                      {/*            time={'08:12 AM'}*/}
                      {/*          />*/}
                      {/*          <RecentTable*/}
                      {/*            text={'Clair Stanton'}*/}
                      {/*            imgSrc={DummyImage}*/}
                      {/*            subText={'Tesla Model S'}*/}
                      {/*            icon={<AiFillCar />}*/}
                      {/*            date={'23 Dec.'}*/}
                      {/*            time={'08:12 AM'}*/}
                      {/*          />*/}
                      {/*          <RecentTable*/}
                      {/*            text={'Clair Stanton'}*/}
                      {/*            imgSrc={DummyImage}*/}
                      {/*            subText={'Tesla Model S'}*/}
                      {/*            icon={<AiFillCar />}*/}
                      {/*            date={'23 Dec.'}*/}
                      {/*            time={'08:12 AM'}*/}
                      {/*          />*/}
                      {/*        </div>*/}
                      {/*      </div>*/}
                      {/*    </Card>*/}
                      {/*  </Col>*/}
                      {/*)}*/}
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          {currentUserRole === SUPER_ADMIN || currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
        </div>
      </LayoutContainer>
    </React.Fragment>
  );
};

Dashboard.propTypes = {};

export default Dashboard;

//card Component
export const DashboardCard = (props) => {
  const { icon, title, subTitle, metaIcon } = props;

  return (
    <div className="dashboard-card">
      <div className="dashboard-detail-box">
        <div className="dashboard-icon">
          {icon && <div className="session-icon">{icon}</div>}
          {metaIcon && <div className="session-meta-icon">{metaIcon}</div>}
        </div>
        <div className="dashboard__info">
          <div className="title-session">{title}</div>
          <div className="info-text">{subTitle}</div>
        </div>
      </div>
    </div>
  );
};

DashboardCard.propTypes = {
  /** card icon **/
  icon: PropTypes.node,
  metaIcon: PropTypes.node,
  /** Dashboard title **/
  title: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
  /** Dashboard sub-title **/
  subTitle: PropTypes.string,
};

//realTime Event table component
export const TableItem = (props) => {
  let { icon, text, date } = props;

  return (
    <div className="realTime-event-item__main">
      <div className="realTime-event-item--inner">
        <div className="realTime-event-icon--block">
          <div className="realTime-event_icon__inner">
            <div className="realTime-event---icon">{icon}</div>
          </div>
        </div>
        <div className="realTime-event-content__main-block">
          <div className="realTime-event-content--inner">
            <div className="realTime-event-details--content-block">
              <span className="realTime-event-timing">{date}</span>
              <div className="realTime-event-status--icon">{icon}</div>
              <div className="realTime-event-text">{text}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

TableItem.propTypes = {
  /** tableList dot icon */
  icon: PropTypes.object,
  /** tableList date */
  date: PropTypes.string,
  /** tableList text */
  text: PropTypes.node,
};

//Top 5 client component
export const SaasClient = (props) => {
  let { icon, text, number } = props;
  return (
    <div className="saasClient--item__main">
      <div className="saasClient--item__inner">
        <Avatar className="saasClient_img" />
        <div className="saasClient-text">{text}</div>
      </div>
      <div className="saasClient-content__main-block">
        <div className="saasClient---number">{number}</div>
        {icon && <div className="saasClient-details-icon">{icon}</div>}
      </div>
    </div>
  );
};
SaasClient.propTypes = {
  icon: PropTypes.object,
  text: PropTypes.node,
  number: PropTypes.number,
};

//recentTable component
const RecentTable = (props) => {
  let { icon, text, date, vehicleMake, vehicleModel, time } = props;
  return (
    <div className="recentTable--item__main">
      <div className="recentTable--item__inner">
        <div className="recentTable--item__inner_block">
          <div className="recentTable--item__img">
            <Avatar className="recentTable_img" />
          </div>
          <div className="recentTable--item_content">
            <div className="recentTable-text">{text}</div>
            <div className="recentTable--item__inner_content">
              <div className="recentTable-details-icon">{icon}</div>
              <span>{`${vehicleMake} ${vehicleModel}`}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="recentTable-content__block">
        <div className="recentTable---date">{date}</div>
        <div className="recentTable---time">{time}</div>
      </div>
    </div>
  );
};
RecentTable.propTypes = {
  icon: PropTypes.object,
  text: PropTypes.node,
  vehicleMake: PropTypes.string,
  vehicleModel: PropTypes.string,
  date: PropTypes.string,
  time: PropTypes.string,
};
