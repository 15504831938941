import React, { useEffect, useCallback, useContext } from 'react';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import * as _ from 'lodash';

import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_CHARGE_COIN, UPDATE_CHARGE_COIN } from 'actions/chargeCoin';
import { Formik, Form } from 'formik';
//import { Spinner } from 'react-bootstrap';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
import { NavContext } from 'components/privateRoute/PrivateRoute';

const Setting = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isAllowed } = useContext(NavContext);
  const chargeCoinDetails = useSelector((state) => (state.chargeCoin.results[0] ? state.chargeCoin.results[0] : state.chargeCoin.results));
  const isLoader = useSelector((state) => state.chargeCoin.isLoading);

  const getChargeCoinInfo = useCallback(() => {
    dispatch({ type: FETCH_CHARGE_COIN, payload: null });
  }, []);

  const updateChargeCoin = useCallback((data) => {
    dispatch({
      type: UPDATE_CHARGE_COIN,
      payload: data,
    });
  });

  useEffect(() => {
    getChargeCoinInfo();
  }, []);

  const initialValues = chargeCoinDetails
    ? { results: chargeCoinDetails }
    : {
        results: {
          ...chargeCoinDetails,
          key_value: { signup_amount: '', validity_in_days: '', levels: _.get(chargeCoinDetails, 'key_value.levels') },
        },
      };

  return (
    <div className="profile-details__main">
      <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
        <div className="profile-details-inner">
          {isLoader ? (
            <div className="setting-page__main">
              <div className="loader--block">
                <Skeleton height={15} width={100} />
              </div>
            </div>
          ) : (
            <div className="profile--content">
              <Row>
                <Formik
                  initialValues={initialValues}
                  onSubmit={(values) => {
                    if (isAllowed('modifyChargeCoinManagement')) {
                      updateChargeCoin(values);
                    }
                  }}
                >
                  {({ values, handleSubmit, handleChange }) => (
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col md={6}>
                          <TextInput
                            isRequired // * Symbol
                            required // "Field Required" Prompt
                            type="number"
                            label={t('chargeCoinManagement.signUpCoin')}
                            name="results.key_value.signup_amount"
                            value={_.get(values, 'results.key_value.signup_amount')}
                            onChange={handleChange}
                            //   error={errors.name && touched.name ? errors.name : null}
                          />
                        </Col>
                        <Col md={6}>
                          {isLoader ? (
                            <Skeleton height={15} width={100} />
                          ) : (
                            <TextInput
                              isRequired // * Symbol
                              required // "Field Required" Prompt
                              type="number"
                              label={t('chargeCoinManagement.validity')}
                              name="results.key_value.validity_in_days"
                              value={_.get(values, 'results.key_value.validity_in_days')}
                              onChange={handleChange}
                              //   error={errors.name && touched.name ? errors.name : null}
                            />
                          )}
                        </Col>
                      </Row>
                      <div className="charge_coin_btn_sec">
                        {isLoader ? (
                          <Skeleton height={15} width={100} />
                        ) : (
                          <Button type="submit" disabled={!isAllowed('modifyChargeCoinManagement')} className="form_btn">
                            {t('button.update')}
                          </Button>
                        )}
                      </div>
                    </Form>
                  )}
                </Formik>
              </Row>
            </div>
          )}
        </div>
      </SkeletonTheme>
    </div>
  );
};

export default Setting;
