import LayoutContainer from 'components/layout/LayoutContainer';
import React, { useState, useEffect, useCallback, useContext } from 'react';
import AdminHeader from 'components/header';
import { Col, Row, Modal } from 'react-bootstrap';
import Card from 'components/inputs/Card';
import { BsSearch } from 'react-icons/bs';
import Button from 'components/inputs/Button';
// import { useHistory } from 'react-router-dom';
import * as _ from 'lodash';
import { FETCH_USER_GROUP } from 'actions/userGroup';
import { useSelector, useDispatch } from 'react-redux';
import SearchBox from 'components/general/SearchBox';
import { DELETE_USER_GROUP } from 'actions/userGroup';
// import Pagination from 'components/general/Pagination';
import ReactPagination from 'components/general/ReactPagination';
import DeleteModal from 'components/general/DeleteModal';
import { DELETE_STATUS_CODE } from 'components/common/constant';
import { BiSort } from 'react-icons/bi';
import { FiEdit2, FiUser } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Footer from 'components/general/Footer';
import { NavContext } from 'components/privateRoute/PrivateRoute';

const UserGroupList = () => {
  // const history = useHistory();
  const { navigateTo, isAllowed } = useContext(NavContext);
  const [searchText, setSearchText] = useState('');
  // const [allUserGroupData, setAllUserGroupData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userGroupId, setUserGroupId] = useState('');
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [pageWiseUserGroupData, setPageWiseUserGroupData] = useState([]);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const allUserGroup = useSelector((state) => state.userGroup.userGroups);
  const isLoader = useSelector((state) => state.userGroup.isLoading);
  const page = useSelector((state) => state.userGroup.page);
  const totalData = useSelector((state) => state.userGroup.total);
  const limit = useSelector((state) => state.userGroup.limit);
  const totalPages = useSelector((state) => state.userGroup.totalPages);
  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserName = _.get(profileData, 'name', '');

  const userGroupData = allUserGroup.filter(({ id: id1 }) => pageWiseUserGroupData.some(({ id: id2 }) => id2 === id1));

  const getUserGroup = useCallback(
    (data) => {
      if (searchText) {
        const searchUserGroupData = {
          ...data,
          search: searchText,
        };
        dispatch({
          type: FETCH_USER_GROUP,
          payload: searchUserGroupData,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                setPageWiseUserGroupData(res.data.results);
              }
            }
          },
        });
      } else {
        dispatch({
          type: FETCH_USER_GROUP,
          payload: data ? data : {},
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                setPageWiseUserGroupData(res.data.results);
              }
            }
          },
        });
      }
    },
    [pageWiseUserGroupData, searchText]
  );

  // const allUsersInUserGroup = useCallback((id) => {
  //   const userGroupData = {
  //     user_group: id,
  //   };
  //   dispatch({ type: ALL_USERS_IN_USER_GROUP, payload: userGroupData });
  // }, []);

  const handleCloseDeleteModel = () => {
    setShowDeleteModal(false);
    setUserGroupId('');
  };

  const deleteUserGroup = useCallback(
    () =>
      dispatch({
        type: DELETE_USER_GROUP,
        payload: userGroupId,
        cb: (res) => {
          if (_.get(res, 'status', '') === DELETE_STATUS_CODE) {
            handleCloseDeleteModel();
            getUserGroup({ page: 1 });
          }
        },
      }),
    [userGroupId]
  );

  // useEffect(() => {
  //   setAllUserGroupData(_.filter(userGroupData, (item) => startsWith(_.get(item, 'name', ''), searchText)));
  // }, [searchText, allUserGroup, pageWiseUserGroupData]);

  useEffect(() => {
    getUserGroup();
  }, []);

  const handleSearch = () => {
    getUserGroup({});
  };

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else {
        const data = {
          page: page.selected + 1,
        };
        getUserGroup(data);
      }
    },
    [sortByItem]
  );

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getUserGroup(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getUserGroup(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title={t('header.userGroups')} />
        <div className="page-content-wrapper scrollable">
          <div className="user-group-page_main">
            <div className="user-group-main-block">
              <Card>
                <div className="user-group-block">
                  <div className="user-group-search-box">
                    <Row>
                      <Col xl={isAllowed('modifyUserGroups') ? 8 : 9}>
                        <SearchBox
                          preIcon={<BsSearch />}
                          value={searchText}
                          onChange={(e) => setSearchText(e.target.value)}
                          onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                              handleSearch();
                            }
                          }}
                        />
                      </Col>
                      <Col xl={'auto'} md={'auto'}>
                        <Button className="user-group-btn" onClick={handleSearch}>
                          {t('button.search')}
                        </Button>
                      </Col>
                      {isAllowed('modifyUserGroups') && (
                        <Col xl={2}>
                          <Button className="user-group-btn" onClick={() => navigateTo('/addUserGroup')}>
                            {t('button.addUserGroup')}
                          </Button>
                        </Col>
                      )}
                    </Row>
                  </div>
                  <div className="user-group-table">
                    <div className="table-responsive">
                      <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
                        <table className="record-list-table">
                          <thead>
                            <tr>
                              <th>
                                <div className="sorting">
                                  <span>{isLoader ? <Skeleton width={100} height={15} /> : t('addUserGroupList.srNo')}</span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>{isLoader ? <Skeleton width={100} height={15} /> : t('addUserGroupList.userGroupName')}</span>
                                  <span className="ico" onClick={() => handleSorting('name', sortByItem)}>
                                    <BiSort size={15} />
                                  </span>
                                </div>
                              </th>
                              {isAllowed('modifyUserGroups') && (
                                <th className="action-col">
                                  <div>
                                    <span>{isLoader ? <Skeleton width={100} height={15} /> : t('addUserGroupList.action')}</span>
                                  </div>
                                </th>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {isLoader ? (
                              <tr>
                                <td colSpan={10} className="border-0">
                                  <Skeleton height={15} />
                                </td>
                              </tr>
                            ) : _.isEmpty(userGroupData) ? (
                              <tr>
                                <td colSpan={8} className="border-0">
                                  <div className="empty-data-block">{t('addUserGroupList.noData')}</div>
                                </td>
                              </tr>
                            ) : (
                              !(isLoader || _.isUndefined(isLoader)) &&
                              _.map(userGroupData, (data, index) => {
                                const serial_num = limit * (page - 1) + index;
                                return (
                                  <tr key={`user-group-${serial_num}`}>
                                    <td>{isLoader ? <Skeleton width={100} height={15} /> : serial_num + 1}</td>
                                    <td>{isLoader ? <Skeleton width={100} height={15} /> : _.get(data, 'name', '')}</td>
                                    {isAllowed('modifyUserGroups') && (
                                      <td>
                                        <span className="user-group-icon" title="Users" onClick={() => navigateTo(`/user/${data.id}`)}>
                                          {isLoader ? <Skeleton circle="true" width={24} height={24} /> : <FiUser size={22} />}
                                        </span>
                                        <span
                                          className="user-group-table-editIcon"
                                          title="Edit"
                                          onClick={() => navigateTo(`/editUserGroup/${data.id}`)}
                                        >
                                          {isLoader ? <Skeleton circle="true" width={24} height={24} /> : <FiEdit2 title="Edit" />}
                                        </span>
                                        <span
                                          title="Delete"
                                          className="user-group-table-deleteIcon"
                                          onClick={() => {
                                            setShowDeleteModal(true);
                                            setUserGroupId(_.get(data, 'id', ''));
                                          }}
                                        >
                                          {isLoader ? <Skeleton circle="true" width={24} height={24} /> : <RiDeleteBinLine title="Delete" />}
                                        </span>
                                      </td>
                                    )}
                                  </tr>
                                );
                              })
                            )}
                          </tbody>
                        </table>
                      </SkeletonTheme>
                    </div>
                  </div>
                  {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(userGroupData) && (
                    <ReactPagination
                      currentPage={page}
                      limit={limit}
                      total={totalData}
                      handlePageClick={(pageVal) => handlePageClick(pageVal)}
                      totalPages={totalPages}
                      marginPagesDisplayed={1}
                    />
                  )}
                  {showDeleteModal && (
                    <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
                      <DeleteModal title="userGroup" onClose={handleCloseDeleteModel} onRemove={deleteUserGroup} />
                    </Modal>
                  )}
                </div>
              </Card>
            </div>
          </div>
          {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
        </div>
      </LayoutContainer>
    </React.Fragment>
  );
};

export default UserGroupList;
