export const UPLOAD_NO_LOAD_REPORT = 'UPLOAD_NO_LOAD_REPORT';
export const UPLOAD_NO_LOAD_REPORT_SUCCESS = 'UPLOAD_NO_LOAD_REPORT_SUCCESS';
export const UPLOAD_NO_LOAD_REPORT_FAIL = 'UPLOAD_NO_LOAD_REPORT_FAIL';

export const FETCH_NO_LOAD_REPORT = 'FETCH_NO_LOAD_REPORT';
export const FETCH_NO_LOAD_REPORT_SUCCESS = 'FETCH_NO_LOAD_REPORT_SUCCESS';
export const FETCH_NO_LOAD_REPORT_FAIL = 'FETCH_NO_LOAD_REPORT_FAIL';

export const DOWNLOAD_NO_LOAD_REPORT = 'DOWNLOAD_NO_LOAD_REPORT';
export const DOWNLOAD_NO_LOAD_REPORT_SUCCESS = 'DOWNLOAD_NO_LOAD_REPORT_SUCCESS';
export const DOWNLOAD_NO_LOAD_REPORT_FAIL = 'DOWNLOAD_NO_LOAD_REPORT_FAIL';