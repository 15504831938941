export const FETCH_CHARGER = 'FETCH_CHARGER';
export const FETCH_CHARGER_SUCCESS = 'FETCH_CHARGER_SUCCESS';
export const FETCH_CHARGER_FAIL = 'FETCH_CHARGER_FAIL';

export const GET_CHARGER = 'GET_CHARGER';
export const GET_CHARGER_SUCCESS = 'GET_CHARGER_SUCCESS';
export const GET_CHARGER_FAIL = 'GET_CHARGER_FAIL';

export const CREATE_CHARGER = 'CREATE_CHARGER';
export const CREATE_CHARGER_SUCCESS = 'CREATE_CHARGER_SUCCESS';
export const CREATE_CHARGER_FAIL = 'CREATE_CHARGER_FAIL';

export const DELETE_CHARGER = 'DELETE_CHARGER';
export const DELETE_CHARGER_SUCCESS = 'DELETE_CHARGER_SUCCESS';
export const DELETE_CHARGER_FAIL = 'DELETE_CHARGER_FAIL';

export const FETCH_CHARGER_BY_STATION = 'FETCH_CHARGER_BY_STATION';
export const FETCH_CHARGER_BY_STATION_SUCCESS = 'FETCH_CHARGER_BY_STATION_SUCCESS';
export const FETCH_CHARGER_BY_STATION_FAIL = 'FETCH_CHARGER_BY_STATION_FAIL';

export const FETCH_CHARGERS_LIST = "FETCH_CHARGERS_LIST"
export const FETCH_CHARGERS_LIST_SUCCESS = "FETCH_CHARGERS_LIST_SUCCESS"
export const FETCH_CHARGERS_LIST_FAIL = "FETCH_CHARGERS_LIST_FAIL"

export const UPDATE_CHARGER = 'UPDATE_CHARGER';
export const UPDATE_CHARGER_SUCCESS = 'UPDATE_CHARGER_SUCCESS';
export const UPDATE_CHARGER_FAIL = 'UPDATE_CHARGER_FAIL';

export const CHANGE_CHARGER_STATUS = 'CHANGE_CHARGER_STATUS';
export const CHANGE_CHARGER_STATUS_SUCCESS = 'CHANGE_CHARGER_STATUS_SUCCESS';
export const CHANGE_CHARGER_STATUS_FAIL = 'CHANGE_CHARGER_STATUS_FAIL';

export const CHANGE_CHARGER_IS_ACTIVE_STATUS = 'CHANGE_CHARGER_IS_ACTIVE_STATUS';
export const CHANGE_CHARGER_IS_ACTIVE_STATUS_SUCCESS = 'CHANGE_CHARGER_IS_ACTIVE_STATUS_SUCCESS';
export const CHANGE_CHARGER_IS_ACTIVE_STATUS_FAIL = 'CHANGE_CHARGER_IS_ACTIVE_STATUS_FAIL';



