import {
  FETCH_EMAIL,
  FETCH_EMAIL_SUCCESS,
  FETCH_EMAIL_FAIL,
  GET_EMAIL,
  GET_EMAIL_SUCCESS,
  GET_EMAIL_FAIL,
  UPDATE_EMAIL,
  UPDATE_EMAIL_SUCCESS,
  UPDATE_EMAIL_FAIL,
  CREATE_EMAIL,
  CREATE_EMAIL_SUCCESS,
  CREATE_EMAIL_FAIL,
  DELETE_EMAIL,
  DELETE_EMAIL_SUCCESS,
  DELETE_EMAIL_FAIL,
} from 'actions/email';
import { toast } from 'react-toastify';
import _ from 'lodash';

const initialState = {
  isLoading: false,
  email: [],
  emailDetail: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const emailReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_EMAIL:
    case GET_EMAIL:
    case CREATE_EMAIL:
    case DELETE_EMAIL: {
      return { ...state, isLoading: true };
    }
    case UPDATE_EMAIL: {
      return { ...state, isLoading: true };
    }

    case FETCH_EMAIL_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        email: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case GET_EMAIL_SUCCESS: {
      return {
        ...state,
        emailDetail: payload,
        isLoading: false,
      };
    }

    case CREATE_EMAIL_SUCCESS: {
      toast.success('Email added successfully');
      return {
        ...state,
        email: _.concat(state.email, payload),
        isLoading: false,
      };
    }

    case UPDATE_EMAIL_SUCCESS: {
      toast.success('Email updated successfully');
      return {
        ...state,
        email: state.email.map((data) => (data.id === payload.id ? payload : data)),
      };
    }

    case DELETE_EMAIL_SUCCESS: {
      toast.success('Email deleted successfully');
      return {
        ...state,
        email: state.email.filter((data) => data.id !== payload),
      };
    }
    case GET_EMAIL_FAIL:
    case FETCH_EMAIL_FAIL:
    case CREATE_EMAIL_FAIL:
    case UPDATE_EMAIL_FAIL:
    case DELETE_EMAIL_FAIL: {
      return { ...state, isLoading: false };
    }
    default:
      return { ...state };
  }
};
export default emailReducer;
