import {
  GET_ALL_MODULES,
  GET_ALL_MODULES_FAIL,
  GET_ALL_MODULES_SUCCESS,
  UPDATE_MODULE,
  UPDATE_MODULE_SUCCESS,
  UPDATE_MODULE_FAIL,
} from 'actions/tenantAdminAccess';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';

function* getAllModules(action) {
  try {
    const response = yield call(API.getAllModules, action.payload);
    yield put({ type: GET_ALL_MODULES_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: GET_ALL_MODULES_FAIL, payload: { error: e } });
  }
}

function* updateModule(action) {
  try {
    const response = yield call(API.updateModule, action.payload);
    yield put({ type: UPDATE_MODULE_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_MODULE_FAIL, payload: { error: e } });
  }
}

function* tenantAdminAccess() {
  yield takeLatest(GET_ALL_MODULES, getAllModules);
  yield takeLatest(UPDATE_MODULE, updateModule);
}
export default tenantAdminAccess;
