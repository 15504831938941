import React, { useState, useCallback, useEffect } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header';
import Card from 'components/inputs/Card';
import { BsSearch } from 'react-icons/bs';
import Button from 'components/inputs/Button';
import { FiEdit2 } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { Row, Col, Modal, Spinner } from 'react-bootstrap';
import * as _ from 'lodash';
import SearchBox from 'components/general/SearchBox';
import { useDispatch, useSelector } from 'react-redux';
import { DELETE_AREA, FETCH_AREA, GET_AREA } from 'actions/address';
// import Pagination from 'components/general/Pagination';
import ReactPagination from 'components/general/ReactPagination';
import AreaForm from './AreaForm';
import DeleteModal from 'components/general/DeleteModal';
import { DELETE_STATUS_CODE } from 'components/common/constant';
import { BiSort } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';

const AreaList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [searchText, setSearchText] = useState('');
  // const [allAreaData, setAllAreaData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [areaId, setAreaId] = useState('');
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [pageWiseAreaData, setPageWiseAreaData] = useState([]);

  const areaList = useSelector((state) => state.area.areas);
  const isLoader = useSelector((state) => state.area.isLoading);
  const page = useSelector((state) => state.area.page);
  const totalData = useSelector((state) => state.area.total);
  const limit = useSelector((state) => state.area.limit);
  const totalPages = useSelector((state) => state.area.totalPages);

  const areaData = areaList.filter(({ id: id1 }) => pageWiseAreaData.some(({ id: id2 }) => id2 === id1));

  const getArea = useCallback(
    (data = {}) => {
      if (searchText) {
        const searchAreaData = {
          ...data,
          search: searchText,
        };
        dispatch({
          type: FETCH_AREA,
          payload: searchAreaData,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                setPageWiseAreaData(res.data.results);
              }
            }
          },
        });
      } else {
        dispatch({
          type: FETCH_AREA,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                setPageWiseAreaData(res.data.results);
              }
            }
          },
        });
      }
    },
    [pageWiseAreaData, searchText]
  );

  const getAreaDetail = useCallback((id) => {
    dispatch({ type: GET_AREA, payload: id });
  }, []);

  const handleCloseDeleteModel = () => {
    setShowDeleteModal(false);
    setAreaId('');
  };

  const deleteArea = useCallback(
    () =>
      dispatch({
        type: DELETE_AREA,
        payload: areaId,
        cb: (res) => {
          if (_.get(res, 'status', '') === DELETE_STATUS_CODE) {
            handleCloseDeleteModel();
            getArea({ page: 1 });
          }
        },
      }),
    [areaId]
  );

  useEffect(() => {
    getArea();
  }, []);

  const handleSearch = () => {
    getArea({});
  };

  // useEffect(() => {
  //   setAllAreaData(
  //     _.filter(
  //       areaData,
  //       (item) =>
  //         startsWith(_.get(item, 'name', ''), searchText) ||
  //         startsWith(_.get(item, 'postal_code', ''), searchText) ||
  //         startsWith(_.get(item, 'city.name', ''), searchText) ||
  //         startsWith(_.get(item, 'city.state.name', ''), searchText) ||
  //         startsWith(_.get(item, 'city.state.country.name', ''), searchText)
  //     )
  //   );
  // }, [searchText, areaList, pageWiseAreaData]);

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else {
        const data = {
          page: page.selected + 1,
        };
        getArea(data);
      }
    },
    [sortByItem]
  );

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getArea(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getArea(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handleCloseFormArea = () => {
    setShowForm(false);
    setIsEdit(false);
  };
  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.area')} />
        <div className="page-content-wrapper scrollable">
          <div className="areaList-page">
            <Card>
              <div className="areaList-page-wrapper">
                <Row className="areaList-search-box">
                  <Col xl={10} lg={9} md={8} className="areaList-search-inputBox">
                    <SearchBox
                      value={searchText}
                      preIcon={<BsSearch />}
                      onChange={(e) => setSearchText(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          handleSearch();
                        }
                      }}
                    />
                  </Col>
                  <Col xl={2} lg={3} md={4} className="areaList-search-boxButton">
                    <Button
                      className="areaList-search-box-Btn"
                      onClick={() => {
                        setShowForm(true);
                        setIsEdit(false);
                      }}
                    >
                      {t('button.addArea')}
                    </Button>
                  </Col>
                </Row>
                <div className="areaList-table">
                  <div className="table-responsive">
                    <table className="record-list-table">
                      <thead>
                        <tr>
                          <th>
                            <div className="sorting">
                              <span>{t('areaList.name')}</span>
                              <span className="ico" onClick={() => handleSorting('name', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>{t('areaList.postalCode')}</span>
                              <span className="ico" onClick={() => handleSorting('postal_code', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>{t('areaList.cityName')}</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>{t('areaList.stateName')}</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>{t('areaList.countryName')}</span>
                            </div>
                          </th>
                          <th>
                            <div className="action-col">
                              <span>{t('areaList.action')}</span>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoader ? (
                          <tr>
                            <td colSpan={10} className="border-0">
                              <div className="loader--block">
                                <Spinner as="span" animation="border" size="sm" role="status" />
                                <span className="ps-2">{t('placeHolder.loading')}</span>
                              </div>
                            </td>
                          </tr>
                        ) : _.isEmpty(areaData) ? (
                          <tr>
                            <td colSpan={5} className="border-0">
                              <div className="empty-data-block">{t('areaList.noData')}</div>
                            </td>
                          </tr>
                        ) : (
                          !(isLoader || _.isUndefined(isLoader)) &&
                          _.map(areaData, (item, key) => {
                            return (
                              <>
                                <tr key={key}>
                                  <td>{_.get(item, 'name')}</td>
                                  <td>{_.get(item, 'postal_code')}</td>
                                  <td>{_.get(item, 'city.name')}</td>
                                  <td>{_.get(item, 'city.state.name')}</td>
                                  <td>{_.get(item, 'city.state.country.name')}</td>
                                  <td>
                                    <span
                                      className="areaList-table_editIcon"
                                      onClick={() => {
                                        setShowForm(true);
                                        getAreaDetail(_.get(item, 'id'));
                                        setIsEdit(true);
                                      }}
                                    >
                                      <FiEdit2 title="Edit" />
                                    </span>
                                    <span
                                      className="areaList-table-deleteIcon"
                                      onClick={() => {
                                        setShowDeleteModal(true);
                                        setAreaId(_.get(item, 'id', ''));
                                      }}
                                    >
                                      <RiDeleteBinLine title="Delete" />
                                    </span>
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(areaData) && (
                  <ReactPagination
                    currentPage={page}
                    limit={limit}
                    total={totalData}
                    handlePageClick={(pageVal) => handlePageClick(pageVal)}
                    totalPages={totalPages}
                    marginPagesDisplayed={1}
                  />
                  // <Pagination currentPage={page} limit={limit} total={totalData} onPageChange={(pageVal) => handlePageClick(pageVal)} />
                )}
              </div>
            </Card>
          </div>
        </div>
        {showForm && (
          <Modal show={showForm} size="lg" centered>
            <AreaForm onClose={handleCloseFormArea} isEdit={isEdit} onSuccess={getArea} />
          </Modal>
        )}
        {showDeleteModal && (
          <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
            <DeleteModal title="area" onClose={handleCloseDeleteModel} onRemove={deleteArea} />
          </Modal>
        )}
      </LayoutContainer>
    </>
  );
};
export default AreaList;
