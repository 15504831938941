import React, { useCallback, useEffect, useState } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header/index';
import Card from 'components/inputs/Card';
import { Row, Col } from 'react-bootstrap';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
// import PhoneNumberInput from 'components/inputs/PhoneNumberInput';
// import { isValidPhoneNumber } from 'react-phone-number-input';
import * as _ from 'lodash';
import { Formik, Form } from 'formik';
import RadioButton from 'components/inputs/RadioButton';
// import ProfilePicInput from 'components/inputs/ProfilePicInput';
import { GET_PROFILE, UPDATE_PROFILE } from 'actions/profile';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import moment from 'moment';
import DatePickerInput from 'components/inputs/DatePickerInput';
import Select from 'components/inputs/Select';
import PhoneInput from 'react-phone-number-input';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input';
import { useTranslation } from 'react-i18next';
import { MANAGE_MY_PROFILE } from 'components/common/constant';

const ProfileForm = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [phoneError, setPhoneError] = useState('');
  const [country, setCountry] = useState('IN');
  const profileDetail = useSelector((state) => state.profile.userProfile);
  const rolePermissionData = useSelector((state) => state.manageRole.manageRoles);

  useEffect(() => {
    dispatch({ type: GET_PROFILE });
  }, []);

  const updateUserProfile = useCallback((data) => {
    dispatch({ type: UPDATE_PROFILE, payload: data });
  }, []);

  const manageProfile =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === MANAGE_MY_PROFILE),
      (data) => data.name
    );

  const ProfileSchema = Yup.object().shape({
    email: Yup.string()
      .required(t('profileForm.email'))
      .strict(true)
      .trim(t('profileForm.space'))
      .email(t('profileForm.emailChk'))
      .max(100, t('profileForm.maxlimit')),
    name: Yup.string().required(t('profileForm.name')).trim(t('profileForm.space')).strict(true),
    // dob: Yup.string().required('birthDate is required'),
    // gender: Yup.string().required('gender is required'),
    // address: Yup.string().required('address is required').trim('Space is not allowed').strict(true),
    phoneNumber: Yup.string().required(t('profileForm.phoneNumber')),
  });

  const initialValues = profileDetail
    ? {
        name: _.get(profileDetail, 'name'),
        email: _.get(profileDetail, 'email'),
        dob: moment(_.get(profileDetail, 'dob')).format('YYYY-MM-DD'),
        address: _.get(profileDetail, 'address'),
        gender: _.get(profileDetail, 'gender'),
        phoneNumber: _.get(profileDetail, 'phone'),
        picture: _.get(profileDetail, 'picture'),
      }
    : {
        email: 'test@gmail.com',
        // picture: 'https://test.in/pics.jpg',
        gender: 'male',
        dob: moment(),
        address: 'abc',
        phoneNumber: '',
        name: '',
      };
  function fileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  return (
    <LayoutContainer>
      <AdminHeader title={t('header.editProfile')} backTo="/setting" />
      <div className="page-content-wrapper scrollable">
        <div className="profile-form-page__main">
          <Card>
            <div className="profile-form-page--inner">
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={ProfileSchema}
                onSubmit={async (values, { setSubmitting }) => {
                  // Check if values.picture is a File (an uploaded image)
                  if (values.picture instanceof File) {
                    // Convert the File to a base64 string
                    try {
                      const base64String = await fileToBase64(values.picture);
                      values.picture = base64String;
                    } catch (error) {
                      // Handle the error if the file conversion fails
                      console.error('Error converting file to base64:', error);
                    }
                  }

                  const profileData = {
                    name: values.name,
                    email: values.email,
                    dob: moment(values.dob).format('MM-DD-YYYY'),
                    address: values.address,
                    gender: values.gender,

                    picture: values.picture, // Include the new profile picture as a base64 string
                  };

                  updateUserProfile(profileData);
                  setSubmitting(false);
                }}
              >
                {({ values, handleSubmit, handleChange, setFieldValue, isSubmitting, errors, touched }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col xxl={2} xl={3} lg={5} md={4}>
                        <div className="d-flex justify-content-center">
                          <img id="profile-picture" src={values.picture} alt="" style={{ width: '150px', height: '150px', objectFit: 'cover' }} />
                        </div>
                        <br></br>
                        <div className="text-center">
                          {/* <label htmlFor="profile-picture">Upload Profile Picture</label> */}
                          <input
                            type="file"
                            id="profile-picture"
                            name="picture"
                            accept="image/*"
                            onChange={(e) => {
                              setFieldValue('picture', e.target.files[0]);
                              // Preview the selected image
                              if (e.target.files[0]) {
                                const reader = new FileReader();
                                reader.onload = (e) => {
                                  document.getElementById('profile-picture').src = e.target.result;
                                };
                                reader.readAsDataURL(e.target.files[0]);
                              }
                            }}
                          />
                        </div>
                      </Col>
                      <Col>
                        <div className="profile-form__block">
                          <Row>
                            <Col xl={6} lg={12}>
                              <TextInput
                                isRequired
                                label={t('profileForm.nameLabel')}
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                                error={errors.name && touched.name ? errors.name : null}
                              />
                            </Col>
                            <Col xl={6} lg={12}>
                              <TextInput
                                isRequired
                                label={t('profileForm.emailLabel')}
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                error={errors.email && touched.email ? errors.email : null}
                              />
                            </Col>
                            <Col xl={6} lg={12}>
                              <div className={'phone-number-input__form-group'}>
                                <label className="phone-number-input--label" htmlFor="phone-number">
                                  {t('profileForm.phoneNo')}
                                  <span className="require--star">*</span>
                                </label>
                                <div id="phone-number" className="phone-number-input_group">
                                  <Select
                                    isDisabled={true}
                                    value={country}
                                    className="country-select-box"
                                    onChange={setCountry}
                                    options={_.uniqBy(
                                      _.map(getCountries(), (country) => {
                                        return { label: `+${getCountryCallingCode(country)}`, value: country };
                                      }),
                                      'label'
                                    )}
                                  />
                                  <div className="vertical---line" />
                                  <PhoneInput
                                    disabled
                                    international
                                    className="PhoneInputInput"
                                    // defaultCountry={country}
                                    placeholder={t('profileForm.phoneNo')}
                                    name="phoneNumber"
                                    value={values.phoneNumber ? values.phoneNumber.toString() : ''}
                                    onChange={(val) => {
                                      if (val !== undefined) {
                                        setFieldValue(`phoneNumber`, val);
                                      }
                                      if (!_.isEmpty(phoneError)) {
                                        setPhoneError('');
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </Col>
                            {/* <Col xl={6} lg={12}>
                              <PhoneNumberInput
                                isRequired
                                disabled
                                label="Phone No."
                                name="phoneNumber"
                                value={values.phoneNumber}
                                onChange={(val) => {
                                  setFieldValue(`phoneNumber`, val);
                                  if (!_.isEmpty(phoneError)) {
                                    setPhoneError('');
                                  }
                                }}
                                error={errors.phoneNumber && touched.phoneNumber ? errors.phoneNumber : phoneError ? phoneError : null}
                              />
                            </Col> */}
                            <Col xl={6} lg={12}>
                              <DatePickerInput
                                label={t('profileForm.dob')}
                                value={values.dob}
                                onChange={(dateString) => setFieldValue('dob', dateString)}
                                // error={errors.dob && touched.dob ? errors.dob : null}
                              />
                            </Col>
                            <Col lg={6} md={12}>
                              <TextInput
                                label={t('profileForm.address')}
                                name="address"
                                value={values.address}
                                onChange={handleChange}
                                // error={errors.address && touched.address ? errors.address : null}
                              />
                            </Col>
                            <Col xl={6} lg={12}>
                              <div className="gender-field__block">
                                <RadioButton
                                  containerClassName="radio-field"
                                  checked={values.gender === 'male'}
                                  onChange={() => setFieldValue('gender', 'male')}
                                >
                                  {t('profileForm.male')}
                                </RadioButton>
                                <RadioButton
                                  containerClassName="radio-field"
                                  checked={values.gender === 'female'}
                                  onChange={() => setFieldValue('gender', 'female')}
                                >
                                  {t('profileForm.female')}
                                </RadioButton>
                              </div>
                            </Col>
                            <Col md={12}>
                              <div className="save-btn--block">
                                {!_.isEmpty(manageProfile) ? (
                                  <Button type="submit" className="profile-save-btn" disabled={isSubmitting}>
                                    {t('button.saveChanges')}
                                  </Button>
                                ) : (
                                  <Button className="profile-save-btn" disabled={isSubmitting}>
                                    {t('button.saveChanges')}
                                  </Button>
                                )}
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </div>
          </Card>
        </div>
      </div>
    </LayoutContainer>
  );
};
export default ProfileForm;
