import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Card from 'components/inputs/Card';
import { MdClose } from 'react-icons/md';
import { Row, Col } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import _ from 'lodash';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { CREATE_CONNECTOR_TYPE, UPDATE_CONNECTOR_TYPE } from 'actions/connectorType';
import Select from 'components/inputs/Select';
import axios from 'axios';
import { cookie } from 'utils/cookies/cookies';
import { toast } from 'react-toastify';
import queryString from 'query-string';
import { CREATE_STATUS_CODE, UPDATE_STATUS_CODE } from 'components/common/constant';
import { useTranslation } from 'react-i18next';

const ConnectorTypeForm = (props) => {
  const { isEdit, onClose = _.noop(), onSuccess = _.noop() } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const token = cookie.get('token');

  const connectorTypeDetail = useSelector((state) => state.connectorType.connectorTypeDetail);
  const isLoading = useSelector((state) => state.connectorType.isLoading);

  const addConnectorType = useCallback((data) => {
    dispatch({
      type: CREATE_CONNECTOR_TYPE,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === CREATE_STATUS_CODE) {
          onSuccess();
          onClose();
        }
      },
    });
  }, []);

  const updateConnectorType = useCallback((data) => {
    dispatch({
      type: UPDATE_CONNECTOR_TYPE,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          onClose();
        }
      },
    });
  }, []);

  const handleImage = (image, savePath, setFieldValue) => {
    const fileName = _.get(image, 'name', '');

    axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: process.env.REACT_APP_API_URL + `/util/fileupload?name=${fileName}`,
    })
      .then((res) => {
        if (res.status === 201) {
          const signedRequest = _.get(res, 'data.signedRequest', '');
          handleUploadFile(signedRequest, image, savePath, setFieldValue);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((e) => console.error('Error -> ', e));
  };

  const handleUploadFile = (url, imageData, savePath, setFieldValue) => {
    const fileType = _.get(imageData, 'type', '');

    axios({
      method: 'put',
      headers: {
        'Content-Type': fileType,
        'Content-Encoding': 'base64',
      },
      url: url,
      data: imageData,
    })
      .then((res) => {
        if (res.status === 200) {
          const imgUrlData = queryString.parseUrl(url);
          setFieldValue(savePath, imgUrlData.url);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((e) => console.error('Error -> ', e));
  };

  const ConnectorTypeSchema = Yup.object().shape({
    name: Yup.string()
      .required(t('connectorTypeForm.name'))
      .strict(true)
      .trim(t('connectorTypeForm.space'))
      .max(100, t('connectorTypeForm.maxlimit')),
    connector_mode: Yup.string().required(t('connectorTypeForm.connectorMode')),
    connector_standard: Yup.string().required(t('connectorTypeForm.connectorStandard')),
  });

  const initialValues = isEdit
    ? { ...connectorTypeDetail }
    : {
        name: '',
        icon: '',
        connector_mode: '',
        connector_standard: '',
        description: '',
      };
  return (
    <React.Fragment>
      <div className="connectorType-form-page__main">
        <Card>
          <div className="connectorType-form-header__block">
            <div className="connectorType-header-name">
              <span>
                {isEdit ? 'Edit' : 'Add'} {t('connectorTypeForm.title')}
              </span>
            </div>
            <div className="close___btn">
              <MdClose size={30} color="#be210b" onClick={onClose} />
            </div>
          </div>
          <div className="connectorType-form-body__block">
            <div className="connectorType-form--block">
              <Formik
                enableReinitialize={!!isEdit}
                initialValues={initialValues}
                validationSchema={ConnectorTypeSchema}
                onSubmit={(values, { setSubmitting }) => {
                  if (isEdit) {
                    updateConnectorType(values);
                  } else {
                    addConnectorType(values);
                  }
                  setSubmitting(false);
                }}
              >
                {({ values, errors, touched, handleSubmit, setFieldValue, handleChange, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col lg={12}>
                        <TextInput
                          isRequired
                          label={t('connectorTypeForm.title')}
                          placeholder={t('connectorTypeForm.title')}
                          name="name"
                          value={_.get(values, 'name', '')}
                          onChange={handleChange}
                          error={errors.name && touched.name ? errors.name : null}
                        />
                      </Col>
                      <Col lg={12}>
                        <Select
                          isRequired
                          label={t('connectorTypeForm.connectorLabel')}
                          options={[
                            { label: 'AC', value: 'AC' },
                            { label: 'DC', value: 'DC' },
                          ]}
                          placeholder={t('placeHolder.selectConnectorMode')}
                          name="connector_mode"
                          value={_.get(values, 'connector_mode')}
                          onChange={(val) => setFieldValue(`connector_mode`, val)}
                          error={errors.connector_mode && touched.connector_mode ? errors.connector_mode : null}
                        />
                      </Col>
                      <Col lg={12}>
                        <Select
                          isRequired
                          label={t('connectorTypeForm.connectorStandardLabel')}
                          options={[
                            { label: 'CHADEMO', value: 'CHADEMO' },
                            { label: 'IEC_60309_2_single_16', value: 'IEC_60309_2_single_16' },
                            { label: 'IEC_62196_T1', value: 'IEC_62196_T1' },
                            { label: 'IEC_62196_T2', value: 'IEC_62196_T2' },
                            { label: 'IEC_62196_T1_COMBO', value: 'IEC_62196_T1_COMBO' },
                            { label: 'IEC_62196_T2_COMBO', value: 'IEC_62196_T2_COMBO' },
                            { label: 'GB_T_20234_T3', value: 'GB_T_20234_T3' },
                          ]}
                          placeholder={t('placeHolder.selectConnectorStandard')}
                          name="connector_standard"
                          value={values.connector_standard}
                          onChange={(val) => {
                            setFieldValue(`connector_standard`, val);
                          }}
                          error={errors.connector_standard && touched.connector_standard ? errors.connector_standard : null}
                        />
                      </Col>
                      <Col lg={12}>
                        {isEdit ? (
                          <Col lg={12} md={12}>
                            <TextInput
                              type="file"
                              label={t('connectorTypeForm.icon')}
                              name="icon"
                              accept="image/*"
                              onChange={(e) => handleImage(e.currentTarget.files[0], 'icon', setFieldValue)}
                              image={values.icon}
                            />
                          </Col>
                        ) : (
                          <Col lg={12} md={12}>
                            <TextInput
                              type="file"
                              label={t('connectorTypeForm.icon')}
                              name="icon"
                              accept="image/*"
                              onChange={(e) => handleImage(e.currentTarget.files[0], 'icon', setFieldValue)}
                            />
                          </Col>
                        )}
                      </Col>
                      <Col lg={12}>
                        <TextInput
                          label={t('connectorTypeForm.description')}
                          as="textarea"
                          rows="4"
                          inputClass="scrollable"
                          placeholder={t('placeHolder.description')}
                          name="description"
                          value={_.get(values, 'description', '')}
                          onChange={handleChange}
                          error={errors.description && touched.description ? errors.description : null}
                        />
                      </Col>
                    </Row>
                    <div className="form_btn--block">
                      <Button className="form--btn cancel_btn" onClick={onClose}>
                        {t('button.cancel')}
                      </Button>
                      <Button type="submit" disabled={isSubmitting || isLoading} className="form--btn">
                        {isEdit ? 'Edit' : 'Add'}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};
ConnectorTypeForm.propTypes = {
  onSuccess: PropTypes.func,
  onClose: PropTypes.func,
  isEdit: PropTypes.bool,
};
export default ConnectorTypeForm;
