import React from 'react';
import {  MdOutlineGpsFixed,MdCalendarMonth,MdConfirmationNumber } from 'react-icons/md';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import * as _ from 'lodash';
// import moment from 'moment';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
const ChargerInfo = () => {
  const chargerDetailData = useSelector((state) => state.charger.chargerDetails);
  const isLoader = useSelector((state) => state.charger.isLoading);
  const { t } = useTranslation();

  return (
    <React.Fragment>
      {isLoader ? (
        <>
          <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
            <div className="charger-data-main">
              <Row className="charger-data-inner">
                <Col xl={3} md={6}>
                  <span className="charger-OEM">
                    <Skeleton height={15} width={100} />
                  </span>
                </Col>
                <Col xl={4} md={6}>
                  <span className="charger-OEM">
                    <Skeleton height={15} width={100} />
                  </span>
                </Col>
                <Col xl={2} md={6}>
                  <span className="charger-OEM">
                    <Skeleton height={15} width={100} />
                  </span>
                </Col>

                <Col xl={3} md={6}>
                  <span className="charger-OEM">
                    <Skeleton height={15} width={100} />
                  </span>
                </Col>
              </Row>
              <Row className="charger-data-inner">
                <Col xl={3} md={6}>
                  <span className="charger-OEM">
                    <Skeleton height={15} width={100} />
                  </span>
                </Col>
              </Row>
            </div>
            <div className="charger-box-pages_main">
              <Row>
                <Col xl={6} md={12} className="charger-box-pages_inner">
                  <div className="charger-box_block">
                    <div className="charger-time charger-status--info">
                      <div className="charging-time_left" style={{ display: 'flex' }}>
                        <span className="charger-Tltc" style={{ display: 'flex' }}>
                          <span className="charger-watch_icon">
                            <Skeleton height={15} width={15} circle="true" />
                          </span>
                          <Skeleton height={15} width={100} />
                        </span>
                        <Skeleton height={15} width={100} />
                      </div>
                      <div className="charger-energy" style={{ display: 'flex' }}>
                        <span className="charger-ectn" style={{ display: 'flex' }}>
                          <span className="charger-enery_icon">
                            <Skeleton height={15} width={15} circle="true" />
                          </span>
                          <Skeleton height={15} width={100} />
                        </span>
                        <Skeleton height={15} width={100} />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xl={6} md={12} className="charger-data-page-main">
                  <div className="charger-colom-inner ">
                    <div className="charger-four">
                      <span className="charger-pluge">
                        <Skeleton height={15} width={100} />
                      </span>
                      <div className="charger-four-btn">
                        <span>
                          <Skeleton height={15} width={100} />
                        </span>
                      </div>
                    </div>
                    <Row>
                      <Col lg={6}>
                        <Skeleton height={15} width={15} /> <Skeleton height={15} width={100} />
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col xl={6} md={12} className="charger-data-page-main">
                  <div className="charger-comments_notes">
                    <span className="charger-Comment">
                      <Skeleton height={15} width={100} />
                    </span>
                    <p>
                      <Skeleton height={15} width={100} />
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </SkeletonTheme>
        </>
      ) : (
        <>
          <div className="charger-data-main">
            <Row className="charger-data-inner">
              <Col xl={3} md={6}>
                <span className="charger-OEM">{t('chargerInfo.oem')}</span> {_.get(chargerDetailData, 'oem.vendor.name', '')}
              </Col>
              <Col xl={4} md={6}>
                <span className="charger-OEM">{t('chargerInfo.chargerSpotModel')}</span> {_.get(chargerDetailData, 'oem.name', '')}
              </Col>
              {/*<Col xl={3} md={6}>*/}
              {/*  <span className="charger-OEM">Type of charger : </span> {_.get(chargerDetailData, 'access_type', '')}*/}
              {/*</Col>*/}
              <Col xl={2} md={6}>
                <span className="charger-OEM">{t('chargerInfo.rate')} </span> {_.get(chargerDetailData, 'tarrif.default_price_per_unit')} / unit
              </Col>
              {/*<Col xl={3} md={6}>*/}
              {/*  <span className="charger-OEM">Maximum charging time : </span>*/}
              {/*  {_.get(chargerDetailData, 'maxChargingTime', 0)} mins*/}
              {/*</Col>*/}
              <Col xl={3} md={6}>
                <span className="charger-OEM">{t('chargerInfo.chargingSpeed')}</span>
                {_.get(chargerDetailData, 'charging_speed', '')}
              </Col>
            </Row>
            <Row className="charger-data-inner">
              <Col xl={3} md={6}>
                <span className="charger-OEM">{t('chargerInfo.parkingRestrictions')}</span>
                {_.get(chargerDetailData, 'parking_restrictions', false) ? 'Yes' : 'No'}
              </Col>
            </Row>
            {/*<Row className="charger-data-main_inner">*/}
            {/*<Col xl={3} md={6}>*/}
            {/*  <span className="Charger-speed">Cooldow time : </span>*/}
            {/*  {_.get(chargerDetailData, 'cooldown_time', '')}*/}
            {/*</Col>*/}
            {/*<Col xl={3} md={6}>*/}
            {/*  <span className="Charger-speed">Energy limit : </span>*/}
            {/*  {_.get(chargerDetailData, 'energy_limits', 0)} kWh*/}
            {/*</Col>*/}
            {/*<Col xl={3} md={6}>*/}
            {/*  <span className="Charger-speed">Parking Restrictions : </span>*/}
            {/*  {_.get(chargerDetailData, 'parking_restrictions', false) ? 'Yes' : 'No'}*/}
            {/*</Col>*/}
            {/*</Row>*/}
          </div>
          <div className="charger-box-pages_main">
            <Row>
            <Col xl={6} md={12} className="charger-box-pages_inner">
                <div className="charger-box_block">
                  <div className="charger-time charger-status--info">
                    <div className="charging-time_left">
                      <span className="charger-Tltc">
                        <span className="charger-watch_icon">
                          <MdConfirmationNumber className="watch_icon" />
                        </span>
                        {t('chargerInfo.serial_number')}
                      </span>
                      {_.get(chargerDetailData,"serial_number")??"-"}
                    </div>
                    <div className="charger-energy">
                      <span className="charger-ectn">
                        <span className="charger-enery_icon">
                          <MdCalendarMonth className="enery_icon" />
                        </span>
                        {t('chargerInfo.commissioning date')}
                      </span>
                      {
                        _.get(chargerDetailData,"installation_date")?                        moment(_.get(chargerDetailData,"installation_date")).format("DD/MM/YYYY")
                        :"-"
                      }
                    </div>
                  </div>
                </div>
              </Col>
              <Col xl={6} md={12} className="charger-data-page-main">
                <div className="charger-colom-inner">
                  <div className="charger-four">
                    <span className="charger-pluge">Plugs</span>
                    <div className="charger-four-btn">
                      <span>{_.size(_.get(chargerDetailData, 'plugs', []))}</span>
                    </div>
                  </div>
                  <Row>
                    {_.map(_.get(chargerDetailData, 'plugs', []), (item, index) => (
                      <Col lg={6} key={`plug-${index}`}>
                        <PlugInfo icon={<MdOutlineGpsFixed />} title={_.get(item, 'connector_id', '')} />
                      </Col>
                    ))}
                  </Row>
                </div>
              </Col>
              <Col xl={6} md={12} className="charger-data-page-main">
                <div className="charger-comments_notes">
                  <span className="charger-Comment">{t('chargerInfo.comments')}</span>
                  <p>{_.get(chargerDetailData, 'comments', '')}</p>
                </div>
              </Col>        
            </Row>
          </div>
        </>
      )}
    </React.Fragment>
  );
};
export default ChargerInfo;

export const PlugInfo = (props) => {
  const { icon, title } = props;
  return (
    <div className="charger-name_icon-data">
      <div className="charger-name--icon">
        <div className="charger-round_icon">{icon}</div>
        {title}
      </div>
    </div>
  );
};

PlugInfo.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.number,
};
