import {
  FETCH_OEM_VENDOR,
  FETCH_OEM_VENDOR_SUCCESS,
  FETCH_OEM_VENDOR_FAIL,
  GET_OEM_VENDOR,
  GET_OEM_VENDOR_SUCCESS,
  GET_OEM_VENDOR_FAIL,
  CREATE_OEM_VENDOR,
  CREATE_OEM_VENDOR_SUCCESS,
  CREATE_OEM_VENDOR_FAIL,
  UPDATE_OEM_VENDOR_SUCCESS,
  UPDATE_OEM_VENDOR_FAIL,
  DELETE_OEM_VENDOR,
  DELETE_OEM_VENDOR_SUCCESS,
  DELETE_OEM_VENDOR_FAIL,
} from 'actions/oemVendor';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  oemVendors: [],
  oemVendorDetail: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const oemVendorReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_OEM_VENDOR:
    case GET_OEM_VENDOR:
    case CREATE_OEM_VENDOR:
    case DELETE_OEM_VENDOR: {
      return { ...state, isLoading: true, oemVendorDetail: {} };
    }

    case FETCH_OEM_VENDOR_SUCCESS: {
      if (payload.page === 1) {
        return {
          ...state,
          isLoading: false,
          oemVendors: payload.results,
          page: payload.page,
          limit: payload.limit,
          totalPages: payload.totalPages,
          total: payload.totalResults,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          oemVendors: _.concat(state.oemVendors, payload.results),
          page: payload.page,
          limit: payload.limit,
          totalPages: payload.totalPages,
          total: payload.totalResults,
        };
      }
    }

    case GET_OEM_VENDOR_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        oemVendorDetail: payload,
      };
    }

    case CREATE_OEM_VENDOR_SUCCESS: {
      toast.success('OEM added successfully');
      return {
        ...state,
        isLoading: false,
        oemVendors: _.concat(state.oemVendors, payload),
      };
    }

    case UPDATE_OEM_VENDOR_SUCCESS: {
      toast.success('OEM updated successfully');
      return {
        ...state,
        oemVendors: state.oemVendors.map((data) => (data.id === payload.id ? payload : data)),
        isLoading: false,
      };
    }

    case DELETE_OEM_VENDOR_SUCCESS: {
      toast.success('OEM deleted successfully');
      return {
        ...state,
        oemVendors: state.oemVendors.filter((data) => data.id !== payload),
      };
    }

    case FETCH_OEM_VENDOR_FAIL:
    case GET_OEM_VENDOR_FAIL:
    case CREATE_OEM_VENDOR_FAIL:
    case UPDATE_OEM_VENDOR_FAIL:
    case DELETE_OEM_VENDOR_FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};
export default oemVendorReducer;
