import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Card from 'components/inputs/Card';
import { MdClose } from 'react-icons/md';
import { Row, Col } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import { useDispatch } from 'react-redux';
import { UPDATE_VRN } from 'actions/vehicle';
import _ from 'lodash';
import * as Yup from 'yup';
import { UPDATE_STATUS_CODE } from 'components/common/constant';
import { useTranslation } from 'react-i18next';
import { propTypes } from 'react-bootstrap/esm/Image';

const VRNForm = (props) => {
  const { onClose = _.noop(), isEdit, onSuccess = _.noop(), chargerID, connectorId, bookingID } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const charger = chargerID;

  console.log(bookingID);
  const addVRN = useCallback((data) => {
    const payload = {
      ...data,
    };

    if (charger) {
      payload.charger = charger;
    }

    if (connectorId) {
      payload.connectorId = connectorId;
    }

    if (bookingID) {
      payload.bookingId = bookingID;
    }

    dispatch({
      type: UPDATE_VRN,
      payload,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          onClose();
          onSuccess();
        }
      },
    });
  }, []);

  const initialValues = {
    vin_num: '',
  };

  const VRNSchema = Yup.object().shape({
    vin_num: Yup.string().required(t('pricingForm.name')),
  });

  return (
    <React.Fragment>
      <div className="pricing-form-page__main">
        <Card>
          <div className="pricing-form-header__block">
            <div className="pricing-header-name">
              <span>{t('header.addeditVinNumber')}</span>
            </div>
            <div className="close-btn">
              <MdClose size={30} color="#be210b" onClick={onClose} />
            </div>
          </div>
          <div className="pricing-form-body__block">
            <div className="pricing-form--block">
              <Formik
                enableReinitialize={!!isEdit}
                initialValues={initialValues}
                validationSchema={VRNSchema}
                onSubmit={(values, { setSubmitting }) => {
                  const addVRNData = values;
                  addVRN(addVRNData);

                  setSubmitting(false);
                }}
              >
                {({ values, handleSubmit, handleChange, errors, touched }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col lg={6}>
                        <TextInput
                          label={t('placeHolder.vinNumber')}
                          name="vin_num"
                          value={values.vin_num}
                          onChange={handleChange}
                          error={errors.vin_num && touched.vin_num ? errors.vin_num : null}
                        />
                      </Col>
                    </Row>
                    <div className="form-btn--block">
                      <Button className="pricing-form-btn cancel-btn" onClick={onClose}>
                        {t('button.cancel')}
                      </Button>

                      <Button className="pricing-form-btn create-btn" type="submit">
                        {t('button.submit')}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};
VRNForm.propTypes = {
  onClose: PropTypes.func,
  isEdit: PropTypes.bool,
  onSuccess: PropTypes.func,
  connectorId: propTypes.string,
  chargerID: propTypes.string,
  bookingID: propTypes.string,
  onFormSubmit: propTypes.bool,
};
export default VRNForm;
