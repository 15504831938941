import {
  UPLOAD_BULK_METER_VALUES_B2C,
  UPLOAD_BULK_METER_VALUES_B2C_SUCCESS,
  UPLOAD_BULK_METER_VALUES_B2C_FAIL,
  UPLOAD_BULK_METER_VALUES_B2B,
  UPLOAD_BULK_METER_VALUES_B2B_SUCCESS,
  UPLOAD_BULK_METER_VALUES_B2B_FAIL,
  UPLOAD_BULK_BOOKING_TIME,
  UPLOAD_BULK_BOOKING_TIME_SUCCESS,
  UPLOAD_BULK_BOOKING_TIME_FAIL,
  UPLOAD_BULK_SOC_VALUES,
  UPLOAD_BULK_SOC_VALUES_SUCCESS,
  UPLOAD_BULK_SOC_VALUES_FAIL,
  UPLOAD_BULK_STOP_TRANSACTION,
  UPLOAD_BULK_STOP_TRANSACTION_SUCCESS,
  UPLOAD_BULK_STOP_TRANSACTION_FAIL,
} from 'actions/updateBulkNegativeValue';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';

function* uploadBulkMeterValuesB2C(action) {
  try {
    const response = yield call(API.addBulkMeterValues, action.payload);
    yield put({ type: UPLOAD_BULK_METER_VALUES_B2C_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPLOAD_BULK_METER_VALUES_B2C_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* uploadBulkMeterValuesB2B(action) {
  try {
    const response = yield call(API.BulkBookingTime, action.payload);
    yield put({ type: UPLOAD_BULK_METER_VALUES_B2B_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPLOAD_BULK_METER_VALUES_B2B_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* uploadBulkBookings(action) {
  try {
    const response = yield call(API.BulkBookingTime, action.payload);
    yield put({ type: UPLOAD_BULK_BOOKING_TIME_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPLOAD_BULK_BOOKING_TIME_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* uploadBulkSOCValues(action) {
    try {
      const response = yield call(API.BulkBookingTime, action.payload);
      yield put({ type: UPLOAD_BULK_SOC_VALUES_SUCCESS, payload: response.data });
      action.cb && action.cb(response);
    } catch (e) {
      yield put({ type: UPLOAD_BULK_SOC_VALUES_FAIL, payload: { error: e } });
      action.cb && action.cb(e);
    }
  }
  
function* uploadBulkStopTransaction(action) {
    try {
      const response = yield call(API.bulkStopTransaction, action.payload);
      yield put({ type: UPLOAD_BULK_STOP_TRANSACTION_SUCCESS, payload: response.data });
      action.cb && action.cb(response);
    } catch (e) {
      yield put({ type: UPLOAD_BULK_STOP_TRANSACTION_FAIL, payload: { error: e } });
      action.cb && action.cb(e);
    }
  }
  
function* updateBulkNegativeValue() {
    yield takeLatest(UPLOAD_BULK_METER_VALUES_B2C, uploadBulkMeterValuesB2C);
    yield takeLatest(UPLOAD_BULK_METER_VALUES_B2B, uploadBulkMeterValuesB2B);
    yield takeLatest(UPLOAD_BULK_BOOKING_TIME, uploadBulkBookings);
    yield takeLatest(UPLOAD_BULK_SOC_VALUES, uploadBulkSOCValues);
    yield takeLatest(UPLOAD_BULK_STOP_TRANSACTION, uploadBulkStopTransaction);
  }
  export default updateBulkNegativeValue;