export const FETCH_NOTIFICATION = 'FETCH_NOTIFICATION';
export const FETCH_NOTIFICATION_SUCCESS = 'FETCH_NOTIFICATION_SUCCESS';
export const FETCH_NOTIFICATION_FAIL = 'FETCH_NOTIFICATION_FAIL';

export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS';
export const DELETE_NOTIFICATION_FAIL = 'DELETE_NOTIFICATION_FAIL';

export const UPDATE_FIRMWARE_NOTIFICATION = 'UPDATE_FIRMWARE_NOTIFICATION';
export const UPDATE_FIRMWARE_NOTIFICATION_SUCCESS = 'UPDATE_FIRMWARE_NOTIFICATION_SUCCESS';
export const UPDATE_FIRMWARE_NOTIFICATION_FAIL = 'UPDATE_FIRMWARE_NOTIFICATION_FAIL';

export const NOTIFICATION = 'NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

export const PUSH_NOTIFICATION = 'PUSH_NOTIFICATION';
export const PUSH_NOTIFICATION_SUCCESS = 'PUSH_NOTIFICATION_SUCCESS';
export const PUSH_NOTIFICATION_FAIL = 'PUSH_NOTIFICATION_FAIL';
