import React from 'react';
import accessDenied from '../../assets/images/icons/access-denied.png';
import { useHistory } from 'react-router-dom';
//import { useSelector } from 'react-redux';

const AccessDenied = () => {
  const history = useHistory();
  //const userRole = useSelector(state => state.profile.userProfile.role);
  const onClickDashboard = () => {
    history.push('/dashboard');
  };
  return (
    <div className="access-denied">
      <img src={accessDenied} alt="Access Denied" className="icon" />
      <h1>Access Denied</h1>
      <p>Access is Denied to this page by Administrator</p>
      <div onClick={onClickDashboard} className="back-dashboard">
        Go back to <span>Dashboard</span>
      </div>
    </div>
  );
};
export default AccessDenied;
