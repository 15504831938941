import {
  FETCH_MODULE,
  FETCH_MODULE_SUCCESS,
  FETCH_MODULE_FAIL,
  DELETE_MODULE_FAIL,
  UPDATE_MODULE_FAIL,
  UPDATE_MODULE_SUCCESS,
  CREATE_MODULE_SUCCESS,
  GET_MODULE_FAIL,
  GET_MODULE_SUCCESS,
  CREATE_MODULE_FAIL,
  CREATE_MODULE,
  DELETE_MODULE,
  GET_MODULE,
  UPDATE_MODULE,
  DELETE_MODULE_SUCCESS,
  FETCH_MODULE_SORTED,
  FETCH_MODULE_SORTED_SUCCESS,
  FETCH_MODULE_SORTED_FAIL,
} from 'actions/module';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';

function* fetchModuleSorted(action) {
  try {
    const response = yield call(API.getModulesSorted, action.payload);
    yield put({ type: FETCH_MODULE_SORTED_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_MODULE_SORTED_FAIL, payload: { error: e } });
  }
}

function* fetchModule(action) {
  try {
    const response = yield call(API.getAllModule, action.payload);
    yield put({ type: FETCH_MODULE_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_MODULE_FAIL, payload: { error: e } });
  }
}

function* getModuleById(action) {
  try {
    const response = yield call(API.getModuleById, action.payload);
    yield put({ type: GET_MODULE_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_MODULE_FAIL, payload: { error: e } });
  }
}

function* createModule(action) {
  try {
    const response = yield call(API.addModule, action.payload);
    yield put({ type: CREATE_MODULE_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_MODULE_FAIL, payload: { error: e } });
  }
}

function* updateModule(action) {
  try {
    const response = yield call(API.updateModuleSuper, action.payload);
    yield put({ type: UPDATE_MODULE_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_MODULE_FAIL, payload: { error: e } });
  }
}

function* deleteModule(action) {
  try {
    const response = yield call(API.deleteModule, action.payload);
    yield put({ type: DELETE_MODULE_SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_MODULE_FAIL, payload: { error: e } });
  }
}

function* module() {
  yield takeLatest(FETCH_MODULE_SORTED, fetchModuleSorted);
  yield takeLatest(FETCH_MODULE, fetchModule);
  yield takeLatest(GET_MODULE, getModuleById);
  yield takeLatest(CREATE_MODULE, createModule);
  yield takeLatest(UPDATE_MODULE, updateModule);
  yield takeLatest(DELETE_MODULE, deleteModule);
}
export default module;
