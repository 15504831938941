import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Card from 'components/inputs/Card';
import { MdClose } from 'react-icons/md';
import { Row, Col } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import _ from 'lodash';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { CREATE_FAQ, UPDATE_FAQ } from 'actions/faq';
import { CREATE_STATUS_CODE, UPDATE_STATUS_CODE } from 'components/common/constant';
import { useTranslation } from 'react-i18next';

const FaqForm = (props) => {
  const { isEdit, onClose = _.noop(), onSuccess = _.noop() } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const faqDetail = useSelector((state) => state.faq.faqDetail);
  const isLoading = useSelector((state) => state.faq.isLoading);

  const addFaq = useCallback((data) => {
    dispatch({
      type: CREATE_FAQ,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === CREATE_STATUS_CODE) {
          onSuccess();
          onClose();
        }
      },
    });
  }, []);

  const updateFaq = useCallback((data) => {
    dispatch({
      type: UPDATE_FAQ,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          onClose();
        }
      },
    });
  }, []);

  const FaqSchema = Yup.object().shape({
    message: Yup.string().required(t('faqForm.message')).strict(true).trim(t('faqForm.space')),
    title: Yup.string().required(t('faqForm.title')).strict(true).trim(t('faqForm.space')).max(100, t('faqForm.maxlimit')),
    order: Yup.number().required(t('faqForm.order')).positive(t('faqForm.positive')),
  });

  const initialValues = isEdit
    ? { ...faqDetail }
    : {
        order: '',
        title: '',
        message: '',
      };
  return (
    <React.Fragment>
      <div className="faq-form-page__main">
        <Card>
          <div className="faq-form-header__block">
            <div className="faq-header-name">
              <span>
                {isEdit ? 'Edit' : 'Add'} {t('faqForm.formTitle')}
              </span>
            </div>
            <div className="close___btn">
              <MdClose size={30} color="#be210b" onClick={onClose} />
            </div>
          </div>
          <div className="faq-form-body__block">
            <div className="faq-form--block">
              <Formik
                enableReinitialize={!!isEdit}
                initialValues={initialValues}
                validationSchema={FaqSchema}
                onSubmit={(values, { setSubmitting }) => {
                  if (isEdit) {
                    updateFaq(values);
                  } else {
                    addFaq(values);
                  }
                  setSubmitting(false);
                }}
              >
                {({ values, errors, touched, handleSubmit, handleChange, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col lg={6} md={6}>
                        <TextInput
                          isRequired
                          label={t('faqForm.orderLabel')}
                          placeholder={t('faqForm.orderLabel')}
                          type="number"
                          name="order"
                          value={_.get(values, 'order', '')}
                          onChange={handleChange}
                          error={errors.order && touched.order ? errors.order : null}
                        />
                      </Col>
                      <Col lg={6} md={6}>
                        <TextInput
                          isRequired
                          label={t('faqForm.titleLabel')}
                          placeholder={t('faqForm.titleLabel')}
                          name="title"
                          value={_.get(values, 'title', '')}
                          onChange={handleChange}
                          error={errors.title && touched.title ? errors.title : null}
                        />
                      </Col>
                      <Col lg={12} md={12}>
                        <TextInput
                          className="text-input form-control form-control-sm scrollable"
                          as="textarea"
                          rows="4"
                          isRequired
                          label={t('faqForm.messageLabel')}
                          placeholder={t('faqForm.messageLabel')}
                          name="message"
                          value={_.get(values, 'message', '')}
                          onChange={handleChange}
                          error={errors.message && touched.message ? errors.message : null}
                        />
                      </Col>
                    </Row>
                    <div className="form-btn__block">
                      <Button className="form_btn cancel____btn" onClick={onClose}>
                        {t('button.cancel')}
                      </Button>
                      <Button type="submit" disabled={isSubmitting || isLoading} className="form_btn">
                        {isEdit ? 'Edit' : 'Add'} {t('faqForm.formTitle')}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};
FaqForm.propTypes = {
  onSuccess: PropTypes.func,
  onClose: PropTypes.func,
  isEdit: PropTypes.bool,
};
export default FaqForm;
