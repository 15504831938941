export const ADD_TENANT = 'ADD_TENANT';
export const ADD_TENANT_SUCCESS = 'ADD_TENANT_SUCCESS';
export const ADD_TENANT_FAIL = 'ADD_TENANT_FAIL';

export const FETCH_TENANT = 'FETCH_TENANT';
export const FETCH_TENANT_SUCCESS = 'FETCH_TENANT_SUCCESS';
export const FETCH_TENANT_FAIL = 'FETCH_TENANT_FAIL';

export const GET_TENANT = 'GET_TENANT';
export const GET_TENANT_SUCCESS = 'GET_TENANT_SUCCESS';
export const GET_TENANT_FAIL = 'GET_TENANT_FAIL';

export const DELETE_TENANT = 'DELETE_TENANT';
export const DELETE_TENANT_SUCCESS = 'DELETE_TENANT_SUCCESS';
export const DELETE_TENANT_FAIL = 'DELETE_TENANT_FAIL';

export const UPDATE_TENANT = 'UPDATE_TENANT';
export const UPDATE_TENANT_SUCCESS = 'UPDATE_TENANT_SUCCESS';
export const UPDATE_TENANT_FAIL = 'UPDATE_TENANT_FAIL';

export const UPDATE_TENANT_CONTACT_INFORMATION = 'UPDATE_TENANT_CONTACT_INFORMATION';
export const UPDATE_TENANT_CONTACT_INFORMATION_SUCCESS = 'UPDATE_TENANT_CONTACT_INFORMATION_SUCCESS';
export const UPDATE_TENANT_CONTACT_INFORMATION_FAIL = 'UPDATE_TENANT_CONTACT_INFORMATION_FAIL';
