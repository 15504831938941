import React, { useCallback, useEffect } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header';
import Card from 'components/inputs/Card';
import { Form, Formik, getIn } from 'formik';
import { Col, Row } from 'react-bootstrap';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import * as Yup from 'yup';
import { GET_MASTER_SETTING, UPDATE_MATER_SETTING } from 'actions/masterSetting';
import { useDispatch, useSelector } from 'react-redux';
import * as _ from 'lodash';
import { useParams } from 'react-router-dom';
import { UPDATE_STATUS_CODE } from 'components/common/constant';
import { useTranslation } from 'react-i18next';

const RfidSetting = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { rfidSettingId } = useParams();
  const masterSettingDetail = useSelector((state) => state.masterSetting.masterSettingDetail);

  const initialValues = _.get(masterSettingDetail, 'key_value')
    ? { key_value: masterSettingDetail.key_value }
    : {
        key_value: {
          low_balance_amt: '',
          already_booked_time: '',
          stop_before_time: '',
          book_till_time: '',
          default_cashback: '',
        },
      };

  const getMasterSettingByID = useCallback(() => {
    dispatch({ type: GET_MASTER_SETTING, payload: rfidSettingId });
  }, [rfidSettingId]);

  useEffect(() => {
    getMasterSettingByID();
  }, []);

  const updateMasterSetting = useCallback((data = {}) => {
    dispatch({
      type: UPDATE_MATER_SETTING,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          getMasterSettingByID();
        }
      },
    });
  }, []);

  const RfidSettingSchema = Yup.object().shape({
    key_value: Yup.object().shape({
      low_balance_amt: Yup.number().required(t('rfidSetting.lowBalance')),
      already_booked_time: Yup.number().required(t('rfidSetting.alreadyBookedTime')),
      stop_before_time: Yup.number().required(t('rfidSetting.stopBeforeTime')),
      book_till_time: Yup.number().required(t('rfidSetting.bookTillTime')),
      default_cashback: Yup.number().required(t('rfidSetting.defaultCashback')),
    }),
  });

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.rfidSetting')} />
        <div className="page-content-wrapper scrollable">
          <div className="rfid-setting_main">
            <Card className="rfid-setting-form-card">
              <div className="rfid-setting-form__block">
                <Formik
                  enableReinitialize={true}
                  initialValues={initialValues}
                  validationSchema={RfidSettingSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    updateMasterSetting({ ...values, id: rfidSettingId, key_name: 'rfid_settings' });
                    setSubmitting(false);
                  }}
                >
                  {({ values, errors, touched, handleSubmit, handleChange, isSubmitting }) => (
                    <Form onSubmit={handleSubmit}>
                      <div className="rfid-setting-form__inner">
                        <Row>
                          <Col lg={6} md={12}>
                            <TextInput
                              isRequired
                              placeholder={t('rfidSetting.lowBalanceAmount')}
                              label={t('rfidSetting.lowBalanceAmount')}
                              type="number"
                              name="key_value.low_balance_amt"
                              value={_.get(values, 'key_value.low_balance_amt')}
                              onChange={handleChange}
                              error={
                                getIn(errors, 'key_value.low_balance_amt') && getIn(touched, 'key_value.low_balance_amt')
                                  ? getIn(errors, 'key_value.low_balance_amt')
                                  : null
                              }
                            />
                          </Col>
                          <Col lg={6} md={12}>
                            <TextInput
                              isRequired
                              placeholder={t('rfidSetting.bookedTime')}
                              label={t('rfidSetting.bookedTime')}
                              type="number"
                              name="key_value.already_booked_time"
                              value={_.get(values, 'key_value.already_booked_time')}
                              onChange={handleChange}
                              error={
                                getIn(errors, 'key_value.already_booked_time') && getIn(touched, 'key_value.already_booked_time')
                                  ? getIn(errors, 'key_value.already_booked_time')
                                  : null
                              }
                            />
                          </Col>
                          <Col lg={6} md={12}>
                            <TextInput
                              isRequired
                              placeholder={t('rfidSetting.stopBeforeTimeLabel')}
                              label={t('rfidSetting.stopBeforeTimeLabel')}
                              type="number"
                              name="key_value.stop_before_time"
                              value={_.get(values, 'key_value.stop_before_time')}
                              onChange={handleChange}
                              error={
                                getIn(errors, 'key_value.stop_before_time') && getIn(touched, 'key_value.stop_before_time')
                                  ? getIn(errors, 'key_value.stop_before_time')
                                  : null
                              }
                            />
                          </Col>
                          <Col lg={6} md={12}>
                            <TextInput
                              isRequired
                              placeholder={t('rfidSetting.bookTillTimeLabel')}
                              label={t('rfidSetting.bookTillTimeLabel')}
                              type="number"
                              name="key_value.book_till_time"
                              value={_.get(values, 'key_value.book_till_time')}
                              onChange={handleChange}
                              error={
                                getIn(errors, 'key_value.book_till_time') && getIn(touched, 'key_value.book_till_time')
                                  ? getIn(errors, 'key_value.book_till_time')
                                  : null
                              }
                            />
                          </Col>
                          <Col lg={6} md={12}>
                            <TextInput
                              isRequired
                              placeholder={t('rfidSetting.defaultCashbackLabel')}
                              label={t('rfidSetting.defaultCashbackLabel')}
                              type="number"
                              name="key_value.default_cashback"
                              value={_.get(values, 'key_value.default_cashback')}
                              onChange={handleChange}
                              error={
                                getIn(errors, 'key_value.default_cashback') && getIn(touched, 'key_value.default_cashback')
                                  ? getIn(errors, 'key_value.default_cashback')
                                  : null
                              }
                            />
                          </Col>
                        </Row>
                      </div>
                      <div className="rfid-setting-btn--block">
                        <Button type="submit" className="" disabled={isSubmitting}>
                          {t('button.update')}
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Card>
          </div>
        </div>
      </LayoutContainer>
    </>
  );
};

export default RfidSetting;
