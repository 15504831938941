export const FETCH_ACCOUNT_DELETE_REQUEST = 'FETCH_ACCOUNT_DELETE_REQUEST';
export const FETCH_ACCOUNT_DELETE_REQUEST_SUCCESS = 'FETCH_ACCOUNT_DELETE_REQUEST_SUCCESS';
export const FETCH_ACCOUNT_DELETE_REQUEST_FAIL = 'FETCH_ACCOUNT_DELETE_REQUEST_FAIL';

export const APPROVED_ACCOUNT_DELETE_REQUEST = 'APPROVED_ACCOUNT_DELETE_REQUEST';
export const APPROVED_ACCOUNT_DELETE_REQUEST_SUCCESS = 'APPROVED_ACCOUNT_DELETE_REQUEST_SUCCESS';
export const APPROVED_ACCOUNT_DELETE_REQUEST_FAIL = 'APPROVED_ACCOUNT_DELETE_REQUEST_FAIL';

export const REJECTED_ACCOUNT_DELETE_REQUEST = 'REJECTED_ACCOUNT_DELETE_REQUEST';
export const REJECTED_ACCOUNT_DELETE_REQUEST_SUCCESS = 'REJECTED_ACCOUNT_DELETE_REQUEST_SUCCESS';
export const REJECTED_ACCOUNT_DELETE_REQUEST_FAIL = 'REJECTED_ACCOUNT_DELETE_REQUEST_FAIL';