import React, { useCallback } from 'react';
import Card from 'components/inputs/Card';
import { Formik, Form } from 'formik';
import { Row, Col } from 'react-bootstrap';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import * as Yup from 'yup';
import { GET_DIAGNOSTICS } from 'actions/chargerOcpp';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as _ from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { GET_STATION_CHARGER_OPERATIONS, MANAGE_STATION_CHARGER_OPERATIONS } from 'components/common/constant';

const GetDiagnosticsForm = () => {
  const { chargerId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const rolePermissionData = useSelector((state) => state.manageRole.manageRoles);

  const getDiagnostics = useCallback((data, resetForm) => {
    dispatch({
      type: GET_DIAGNOSTICS,
      payload: { data },
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            toast.success('Diagnostics successfully');
            resetForm();
          }
        }
      },
    });
  }, []);

  const getStationChargerOperations =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === GET_STATION_CHARGER_OPERATIONS),
      (data) => data.name
    );

  const manageStationChargerOperations =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === MANAGE_STATION_CHARGER_OPERATIONS),
      (data) => data.name
    );

  const GetDiagnosticsSchema = Yup.object().shape({
    location: Yup.string().required(t('getDiagnosticsForm.location')).max(100, t('getDiagnosticsForm.maxlimit')),
    // retries: Yup.number(),
    // retryInterval: Yup.number(),
    // stopDate: Yup.string(),
    // startTimes: Yup.string(),
    // startDate: Yup.string(),
    // stopTimes: Yup.string(),
  });

  const initialValues = {
    stopDate: '',
    startTimes: '',
    startDate: '',
    stopTimes: '',
    retries: '',
    location: '',
    retryInterval: '',
  };

  return (
    <React.Fragment>
      <div className="serverOperation-title-name">{t('getDiagnosticsForm.formTitle')}</div>
      <div className="serverOperation-content-wrapper">
        <Card className="serverOperation-form-main">
          <div className="serverOperation-form__block">
            <Formik
              initialValues={initialValues}
              validationSchema={GetDiagnosticsSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                const startTime = moment(values.startDate + values.startTimes, 'YYYY-MM-DDLT');
                const stopTime = moment(values.stopDate + values.stopTimes, 'YYYY-MM-DDLT');
                const formData = { ...values, id: chargerId, startTime: startTime, stopTime: stopTime };
                if (formData.startTime._i && formData.stopTime._i) {
                  const diagnosticsFormData = _.omit(formData, ['stopDate', 'startTimes', 'startDate', 'stopTimes']);
                  getDiagnostics(diagnosticsFormData, resetForm);
                } else if (formData.startTime._i) {
                  const diagnosticsFormData = _.omit(formData, ['stopDate', 'startTimes', 'startDate', 'stopTimes', 'stopTime']);
                  getDiagnostics(diagnosticsFormData, resetForm);
                } else if (formData.stopTime._i) {
                  const diagnosticsFormData = _.omit(formData, ['stopDate', 'startTimes', 'startDate', 'stopTimes', 'startTime']);
                  getDiagnostics(diagnosticsFormData, resetForm);
                } else {
                  const diagnosticsFormData = _.omit(formData, ['stopDate', 'startTimes', 'startDate', 'stopTimes', 'startTime', 'stopTime']);
                  getDiagnostics(diagnosticsFormData, resetForm);
                }
                setSubmitting(false);
              }}
            >
              {({ values, errors, touched, handleSubmit, handleChange, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="serverOperation-form__inner">
                    <Row>
                      <Col md={12}>
                        <TextInput
                          isRequired
                          label={t('getDiagnosticsForm.locationLabel')}
                          value={values.location}
                          name="location"
                          onChange={handleChange}
                          error={errors.location && touched.location ? errors.location : null}
                        />
                      </Col>
                      <Col md={6}>
                        <TextInput
                          label={t('getDiagnosticsForm.retryInterval')}
                          type="number"
                          value={values.retryInterval}
                          name="retryInterval"
                          onChange={handleChange}
                          // error={errors.retryInterval && touched.retryInterval ? errors.retryInterval : null}
                        />
                      </Col>
                      <Col md={6}>
                        <TextInput
                          label={t('getDiagnosticsForm.retries')}
                          type="number"
                          value={values.retries}
                          name="retries"
                          onChange={handleChange}
                          // error={errors.retries && touched.retries ? errors.retries : null}
                        />
                      </Col>
                      <Col md={6}>
                        <TextInput
                          label={t('getDiagnosticsForm.startDate')}
                          type="date"
                          value={values.startDate}
                          name="startDate"
                          onChange={handleChange}
                          // error={errors.startDate && touched.startDate ? errors.startDate : null}
                        />
                      </Col>
                      <Col md={6}>
                        <TextInput
                          label={t('getDiagnosticsForm.startTime')}
                          type="time"
                          value={values.startTime}
                          name="startTimes"
                          onChange={handleChange}
                          // error={errors.startTimes && touched.startTimes ? errors.startTimes : null}
                        />
                      </Col>
                      <Col md={6}>
                        <TextInput
                          label={t('getDiagnosticsForm.stopDate')}
                          type="date"
                          value={values.stopDate}
                          name="stopDate"
                          onChange={handleChange}
                          // error={errors.stopDate && touched.stopDate ? errors.stopDate : null}
                        />
                      </Col>
                      <Col md={6}>
                        <TextInput
                          label={t('getDiagnosticsForm.stopTime')}
                          type="time"
                          value={values.stopTime}
                          name="stopTimes"
                          onChange={handleChange}
                          // error={errors.stopTimes && touched.stopTimes ? errors.stopTimes : null}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="serverOperation-save--btn--block">
                    {!_.isEmpty(manageStationChargerOperations) ||
                    (!_.isEmpty(getStationChargerOperations) && !_.isEmpty(manageStationChargerOperations)) ? (
                      <Button type="submit" className="serverOperation-save-btn save--btn" disabled={isSubmitting}>
                        {t('getDiagnosticsForm.button')}
                      </Button>
                    ) : (
                      <Button className="serverOperation-save-btn save--btn">{t('getDiagnosticsForm.button')}</Button>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};
export default GetDiagnosticsForm;
