import { FETCH_CHARGE_COIN_TRANSACTION, FETCH_CHARGE_COIN_TRANSACTION_SUCCESS, FETCH_CHARGE_COIN_TRANSACTION_FAIL } from 'actions/customer';

const initialState = {
  isLoading: false,
  chargeCoinTransaction: [],
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};
const chargeCoinTransactionReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_CHARGE_COIN_TRANSACTION: {
      return { ...state, isLoading: true };
    }

    case FETCH_CHARGE_COIN_TRANSACTION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        chargeCoinTransaction: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case FETCH_CHARGE_COIN_TRANSACTION_FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return { ...state };
  }
};
export default chargeCoinTransactionReducer;
