import { GET_CHARGER_STATION, GET_CHARGER_STATION_SUCCESS, GET_CHARGER_STATION_FAIL } from 'actions/chargingStation';

const initialState = {
  isLoading: false,
  page: 1,
  chargerList: [],
  limit: 10,
  totalPages: 1,
  total: 1,
};

const chargerStationReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_CHARGER_STATION: {
      return { ...state, isLoading: true };
    }
    case GET_CHARGER_STATION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        page: payload.page,
        chargerList: payload.results,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case GET_CHARGER_STATION_FAIL: {
      return { ...state, isLoading: false };
    }
    default:
      return { ...state };
  }
};
export default chargerStationReducer;
