import {
  FETCH_ELECTRICITY_SUPPLIER,
  FETCH_ELECTRICITY_SUPPLIER_FAIL,
  FETCH_ELECTRICITY_SUPPLIER_SUCCESS,
  GET_ELECTRICITY_SUPPLIER,
  GET_ELECTRICITY_SUPPLIER_SUCCESS,
  GET_ELECTRICITY_SUPPLIER_FAIL,
  CREATE_ELECTRICITY_SUPPLIER,
  CREATE_ELECTRICITY_SUPPLIER_SUCCESS,
  CREATE_ELECTRICITY_SUPPLIER_FAIL,
  UPDATE_ELECTRICITY_SUPPLIER,
  UPDATE_ELECTRICITY_SUPPLIER_SUCCESS,
  UPDATE_ELECTRICITY_SUPPLIER_FAIL,
  DELETE_ELECTRICITY_SUPPLIER,
  DELETE_ELECTRICITY_SUPPLIER_SUCCESS,
  DELETE_ELECTRICITY_SUPPLIER_FAIL,
} from 'actions/electricitySupplier';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  electricitySuppliers: [],
  supplierDetail: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const electricitySupplierReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_ELECTRICITY_SUPPLIER:
    case GET_ELECTRICITY_SUPPLIER:
    case CREATE_ELECTRICITY_SUPPLIER:
    case DELETE_ELECTRICITY_SUPPLIER: {
      return { ...state, isLoading: true, supplierDetail: {} };
    }

    case UPDATE_ELECTRICITY_SUPPLIER: {
      return { ...state, isLoading: true };
    }

    case FETCH_ELECTRICITY_SUPPLIER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        electricitySuppliers: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case GET_ELECTRICITY_SUPPLIER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        supplierDetail: payload,
      };
    }

    case CREATE_ELECTRICITY_SUPPLIER_SUCCESS: {
      toast.success('Supplier added successfully');
      return {
        ...state,
        isLoading: false,
        electricitySuppliers: _.concat(state.electricitySuppliers, payload),
      };
    }

    case UPDATE_ELECTRICITY_SUPPLIER_SUCCESS: {
      toast.success('Supplier updated successfully');
      return {
        ...state,
        electricitySuppliers: state.electricitySuppliers.map((data) => (data.id === payload.id ? payload : data)),
        isLoading: false,
      };
    }

    case DELETE_ELECTRICITY_SUPPLIER_SUCCESS: {
      toast.success('Supplier deleted successfully');
      return {
        ...state,
        isLoading: false,
        electricitySuppliers: state.electricitySuppliers.filter((data) => data.id !== payload),
      };
    }

    case FETCH_ELECTRICITY_SUPPLIER_FAIL:
    case GET_ELECTRICITY_SUPPLIER_FAIL:
    case CREATE_ELECTRICITY_SUPPLIER_FAIL:
    case UPDATE_ELECTRICITY_SUPPLIER_FAIL:
    case DELETE_ELECTRICITY_SUPPLIER_FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};
export default electricitySupplierReducer;
