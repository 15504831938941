import {
  FETCH_PRIVACY,
  FETCH_PRIVACY_SUCCESS,
  FETCH_PRIVACY_FAIL,
  GET_PRIVACY,
  GET_PRIVACY_SUCCESS,
  GET_PRIVACY_FAIL,
  CREATE_PRIVACY,
  CREATE_PRIVACY_SUCCESS,
  CREATE_PRIVACY_FAIL,
  UPDATE_PRIVACY,
  UPDATE_PRIVACY_FAIL,
  UPDATE_PRIVACY_SUCCESS,
  DELETE_PRIVACY,
  DELETE_PRIVACY_SUCCESS,
  DELETE_PRIVACY_FAIL,
} from 'actions/privacy';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  privacy: [],
  privacyDetail: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const privacyReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_PRIVACY:
    case GET_PRIVACY:
    case CREATE_PRIVACY:
    case DELETE_PRIVACY: {
      return { ...state, isLoading: true, privacyDetail: {} };
    }

    case UPDATE_PRIVACY: {
      return { ...state, isLoading: true };
    }

    case FETCH_PRIVACY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        privacy: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case GET_PRIVACY_SUCCESS: {
      return {
        ...state,
        privacyDetail: payload,
        isLoading: false,
      };
    }

    case CREATE_PRIVACY_SUCCESS: {
      toast.success('Privacy added successfully');
      return {
        ...state,
        privacy: _.concat(state.privacy, payload),
        isLoading: false,
      };
    }

    case UPDATE_PRIVACY_SUCCESS: {
      toast.success('Privacy updated successfully');
      return {
        ...state,
        isLoading: false,
        privacy: state.privacy.map((data) => {
          if (data.id === payload.id) {
            return payload;
          } else {
            return data;
          }
        }),
      };
    }

    case DELETE_PRIVACY_SUCCESS: {
      toast.success('Privacy deleted successfully');
      return {
        ...state,
        privacy: state.privacy.filter((data) => data.id !== payload),
      };
    }

    case FETCH_PRIVACY_FAIL:
    case GET_PRIVACY_FAIL:
    case CREATE_PRIVACY_FAIL:
    case UPDATE_PRIVACY_FAIL:
    case DELETE_PRIVACY_FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return {
        ...state,
      };
  }
};
export default privacyReducer;
