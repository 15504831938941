import {
    FETCH_OCPP_ERROR,
    FETCH_OCPP_ERROR_SUCCESS,
    FETCH_OCPP_ERROR_FAIL,
  } from 'actions/ocppErrorCode';
  // import * as _ from 'lodash';
  
  const initialState = {

    ocppErrorList:[],
   

  };
  
  const ocppErrorCodeReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case FETCH_OCPP_ERROR: {
        return { ...state };
      }
  
      case FETCH_OCPP_ERROR_SUCCESS: {
        
            return {
              ...state,
              ocppErrorList: payload,
            }
      }
  
      case FETCH_OCPP_ERROR_FAIL:{
        return { ...state, isLoading: false };
      }
  
      default:
        return { ...state };
    }
  };
  export default ocppErrorCodeReducer;
  