import { GET_DASHBOARD_DATA, GET_DASHBOARD_DATA_SUCCESS, GET_DASHBOARD_DATA_FAIL } from 'actions/dashboard';

const initialState = {
  isLoading: false,
  dashboard: {},
};

const dashboardReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_DASHBOARD_DATA: {
      return { ...state, isLoading: true };
    }

    case GET_DASHBOARD_DATA_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        dashboard: payload,
      };
    }

    case GET_DASHBOARD_DATA_FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};
export default dashboardReducer;
