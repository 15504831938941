import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import {
  CREATE_VEHICLE,
  CREATE_VEHICLE_SUCCESS,
  CREATE_VEHICLE_FAIL,
  CREATE_MULTIPLE_VEHICLE,
  CREATE_MULTIPLE_VEHICLE_SUCCESS,
  CREATE_MULTIPLE_VEHICLE_FAIL,
  FETCH_VEHICLE,
  FETCH_VEHICLE_SUCCESS,
  FETCH_VEHICLE_FAIL,
  GET_VEHICLE,
  GET_VEHICLE_SUCCESS,
  GET_VEHICLE_FAIL,
  DELETE_VEHICLE,
  DELETE_VEHICLE_SUCCESS,
  DELETE_VEHICLE_FAIL,
  UPDATE_VEHICLE,
  UPDATE_VEHICLE_SUCCESS,
  UPDATE_VEHICLE_FAIL,
  DOWNLOAD_EXCEL_VEHICLE,
  DOWNLOAD_EXCEL_VEHICLE_SUCCESS,
  DOWNLOAD_EXCEL_VEHICLE_FAIL,
  UPDATE_VRN,
  UPDATE_VRN_SUCCESS,
  UPDATE_VRN_FAIL,
} from 'actions/vehicle';

function* getVehicle(action) {
  try {
    const response = yield call(API.getVehicle, action.payload);
    yield put({ type: FETCH_VEHICLE_SUCCESS, payload: response });
  } catch (e) {
    yield put({ type: FETCH_VEHICLE_FAIL, payload: { error: e } });
  }
}

function* getVehicleById(action) {
  try {
    const response = yield call(API.getVehicleById, action.payload);
    yield put({ type: GET_VEHICLE_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_VEHICLE_FAIL, payload: { error: e } });
  }
}

function* createVehicle(action) {
  try {
    const response = yield call(API.addVehicle, action.payload);
    yield put({ type: CREATE_VEHICLE_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_VEHICLE_FAIL, payload: { error: e } });
  }
}

function* createMultipleVehicle(action) {
  try {
    const response = yield call(API.addMultipleVehicle, action.payload);
    yield put({ type: CREATE_MULTIPLE_VEHICLE_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_MULTIPLE_VEHICLE_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* updateVehicle(action) {
  try {
    const response = yield call(API.updateVehicle, action.payload);
    yield put({ type: UPDATE_VEHICLE_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_VEHICLE_FAIL, payload: { error: e } });
  }
}
function* deleteVehicle(action) {
  try {
    const response = yield call(API.deleteVehicle, action.payload);
    yield put({ type: DELETE_VEHICLE_SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_VEHICLE_FAIL, payload: { error: e } });
  }
}

function* downloadExcelVehicle(action) {
  try {
    const response = yield call(API.downloadExcelVehicle, action.payload);
    yield put({ type: DOWNLOAD_EXCEL_VEHICLE_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_EXCEL_VEHICLE_FAIL, payload: { error: e } });
  }
}

function* updateVRN(action) {
  try {
    const response = yield call(API.updateVRN, action.payload);
    yield put({ type: UPDATE_VRN_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_VRN_FAIL, payload: { error: e } });
  }
}

function* vehicle() {
  yield takeLatest(FETCH_VEHICLE, getVehicle);
  yield takeLatest(CREATE_VEHICLE, createVehicle);
  yield takeLatest(CREATE_MULTIPLE_VEHICLE, createMultipleVehicle);
  yield takeLatest(GET_VEHICLE, getVehicleById);
  yield takeLatest(DELETE_VEHICLE, deleteVehicle);
  yield takeLatest(UPDATE_VEHICLE, updateVehicle);
  yield takeLatest(DOWNLOAD_EXCEL_VEHICLE, downloadExcelVehicle);
  yield takeLatest(UPDATE_VRN, updateVRN);
}

export default vehicle;
