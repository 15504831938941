export const CHARGER_STATUS = 'CHARGER_STATUS';
export const CHARGER_STATUS_SUCCESS = 'CHARGER_STATUS_SUCCESS';
export const CHARGER_STATUS_FAIL = 'CHARGER_STATUS_FAIL';

export const FETCH_CHARGER_STATUS_WIDGETS = 'FETCH_CHARGER_STATUS_WIDGETS';
export const FETCH_CHARGER_STATUS_WIDGETS_SUCCESS = 'FETCH_CHARGER_STATUS_WIDGETS_SUCCESS';
export const FETCH_CHARGER_STATUS_WIDGETS_FAIL = 'FETCH_CHARGER_STATUS_WIDGETS_FAIL';

export const CHARGER_STATUS_DOWNLOAD = 'CHARGER_STATUS_DOWNLOAD';
export const CHARGER_STATUS_DOWNLOAD_SUCCESS = 'CHARGER_STATUS_DOWNLOAD_SUCCESS';
export const CHARGER_STATUS_DOWNLOAD_FAIL = 'CHARGER_STATUS_DOWNLOAD_FAIL';