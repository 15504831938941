import {
  FETCH_PERMISSION,
  FETCH_PERMISSION_SUCCESS,
  FETCH_PERMISSION_FAIL,
  GET_PERMISSION,
  GET_PERMISSION_SUCCESS,
  GET_PERMISSION_FAIL,
  CREATE_PERMISSION,
  CREATE_PERMISSION_SUCCESS,
  CREATE_PERMISSION_FAIL,
  UPDATE_PERMISSION,
  UPDATE_PERMISSION_FAIL,
  UPDATE_PERMISSION_SUCCESS,
  DELETE_PERMISSION,
  DELETE_PERMISSION_SUCCESS,
  DELETE_PERMISSION_FAIL,
  FETCH_ALL_PERMISSION_SUCCESS,
  FETCH_ALL_PERMISSION,
  FETCH_ALL_PERMISSION_FAIL,
} from 'actions/permissions';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  permissions: [],
  permissionDetail: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const permissionReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_PERMISSION:
    case FETCH_ALL_PERMISSION:
    case GET_PERMISSION:
    case CREATE_PERMISSION:
    case DELETE_PERMISSION: {
      return { ...state, isLoading: true, permissionDetail: {} };
    }

    case UPDATE_PERMISSION: {
      return { ...state, isLoading: true };
    }

    case FETCH_PERMISSION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        permissions: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case FETCH_ALL_PERMISSION_SUCCESS: {
      return {
        ...state,
        allPermissions: payload,
      };

      // for security empty all permissions on page unmount
    }

    case GET_PERMISSION_SUCCESS: {
      return {
        ...state,
        permissionDetail: payload,
        isLoading: false,
      };
    }

    case CREATE_PERMISSION_SUCCESS: {
      toast.success('Permission added successfully');
      return {
        ...state,
        permissions: _.concat(state.permissions, payload),
        isLoading: false,
      };
    }

    case UPDATE_PERMISSION_SUCCESS: {
      toast.success('Permission updated successfully');
      return {
        ...state,
        isLoading: false,
        permissions: state.permissions.map((data) => (data.id === payload.id ? payload : data)),
      };
    }

    case DELETE_PERMISSION_SUCCESS: {
      toast.success('permission deleted successfully');
      return {
        ...state,
        isLoading: false,
        permissions: state.permissions.filter((data) => data.id !== payload),
      };
    }

    case FETCH_PERMISSION_FAIL:
    case FETCH_ALL_PERMISSION_FAIL:
    case GET_PERMISSION_FAIL:
    case CREATE_PERMISSION_FAIL:
    case UPDATE_PERMISSION_FAIL:
    case DELETE_PERMISSION_FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return {
        ...state,
      };
  }
};
export default permissionReducer;
