import {
  PULL_LOCATION,
  PULL_LOCATION_SUCCESS,
  PULL_LOCATION_FAIL,
  HANDSHAKE,
  HANDSHAKE_SUCCESS,
  HANDSHAKE_FAIL,
  // VIEW_ENDPOINTS,
  // VIEW_ENDPOINTS_SUCCESS,
  // VIEW_ENDPOINTS_FAIL,
  PULL_SESSIONS,
  PULL_SESSIONS_SUCCESS,
  PULL_SESSIONS_FAIL,
  PULL_TARIFF,
  PULL_TARIFF_SUCCESS,
  PULL_TARIFF_FAIL,
  PULL_TOKEN,
  PULL_TOKEN_SUCCESS,
  PULL_TOKEN_FAIL,
  PULL_CDRS,
  PULL_CDRS_SUCCESS,
  PULL_CDRS_FAIL,
  GET_ENDPOINT_SUCCESS,
  GET_ENDPOINT_FAIL,
  GET_ENDPOINT,
} from 'actions/partnerManagement';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  pullData: '',
  pushData: '',
  credential: '',
  endpoints:'',
  tokenData:'',
};

const ocpiPullRequestReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case PULL_LOCATION:
    case PULL_SESSIONS:
    case PULL_TARIFF:
    case PULL_TOKEN:
    case PULL_CDRS:
    case HANDSHAKE:
    case GET_ENDPOINT: {
      return { ...state, isLoading: true };
    }

    case PULL_LOCATION_SUCCESS: {
      toast.success('Location pull successfully');
      return {
        ...state,
        isLoading: false,
        pullData: payload,
      };
    }

    case HANDSHAKE_SUCCESS: {
      toast.success('Handshaking successfully ');
      return {
        ...state,
        isLoading: false,
        credential: payload,
      };
    }

    case GET_ENDPOINT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        endpoints: payload,
      };
    }

    case PULL_SESSIONS_SUCCESS: {
      toast.success('Session pull successfully');
      return {
        ...state,
        isLoading: false,
        pullData: payload,
      };
    }
    case PULL_TARIFF_SUCCESS: {
      toast.success('Tariff pull successfully');
      return {
        ...state,
        isLoading: false,
        pullData: payload,
      };
    }
    case PULL_TOKEN_SUCCESS: {
      toast.success('Token pull successfully');
      return {
        ...state,
        isLoading: false,
        tokenData: payload,
      };
    }
    case PULL_CDRS_SUCCESS: {
      toast.success('Cdrs pull successfully');
      return {
        ...state,
        isLoading: false,
        pullData: payload,
      };
    }

    case PULL_LOCATION_FAIL:
    case PULL_SESSIONS_FAIL:
    case PULL_TARIFF_FAIL:
    case PULL_TOKEN_FAIL:
    case PULL_CDRS_FAIL:
    case HANDSHAKE_FAIL:
    case GET_ENDPOINT_FAIL: {
      return { ...state, isLoading: false };
    }
    default: {
      return { ...state };
    }
  }
};

export default ocpiPullRequestReducer;
