export const CREATE_CPO = 'CREATE_CPO';
export const CREATE_CPO_SUCCESS = 'CREATE_CPO_SUCCESS';
export const CREATE_CPO_FAIL = 'CREATE_CPO_FAIL';

export const FETCH_CPO = 'FETCH_CPO';
export const FETCH_CPO_SUCCESS = 'FETCH_CPO_SUCCESS';
export const FETCH_CPO_FAIL = 'FETCH_CPO_FAIL';

export const GET_CPO = 'GET_CPO';
export const GET_CPO_SUCCESS = 'GET_CPO_SUCCESS';
export const GET_CPO_FAIL = 'GET_CPO_FAIL';

export const UPDATE_CPO = 'UPDATE_CPO';
export const UPDATE_CPO_SUCCESS = 'UPDATE_CPO_SUCCESS';
export const UPDATE_CPO_FAIL = 'UPDATE_CPO_FAIL';

export const DELETE_CPO = 'DELETE_CPO';
export const DELETE_CPO_SUCCESS = 'DELETE_CPO_SUCCESS';
export const DELETE_CPO_FAIL = 'DELETE_CPO_FAIL';

export const HANDSHAKE = "HANDSHAKE";
export const HANDSHAKE_SUCCESS = "HANDSHAKE_SUCCESS";
export const HANDSHAKE_FAIL = "HANDSHAKE_FAIL";

export const VIEW_ENDPOINTS = "VIEW_ENDPOINTS";
export const VIEW_ENDPOINTS_SUCCESS = "VIEW_ENDPOINTS_SUCCESS";
export const VIEW_ENDPOINTS_FAIL = "VIEW_ENDPOINTS_FAIL";

export const GET_ENDPOINT = "GET_ENDPOINT";
export const GET_ENDPOINT_SUCCESS = "GET_ENDPOINT_SUCCESS";
export const GET_ENDPOINT_FAIL = "GET_ENDPOINT_FAIL";

export const PULL_LOCATION = 'PULL_LOCATION';
export const PULL_LOCATION_SUCCESS = 'PULL_LOCATION_SUCCESS';
export const PULL_LOCATION_FAIL='PULL_LOCATION_FAIL';

export const PULL_SESSIONS= 'PULL_SESSIONS';
export const PULL_SESSIONS_SUCCESS = 'PULL_SESSIONS_SUCCESS';
export const PULL_SESSIONS_FAIL='PULL_SESSIONS_FAIL';

export const PULL_TARIFF= 'PULL_TARIFF';
export const PULL_TARIFF_SUCCESS = 'PULL_TARIFF_SUCCESS';
export const PULL_TARIFF_FAIL='PULL_TARIFF_FAIL';

export const PULL_TOKEN= 'PULL_TOKEN';
export const PULL_TOKEN_SUCCESS = 'PULL_TOKEN_SUCCESS';
export const PULL_TOKEN_FAIL='PULL_TOKEN_FAIL';

export const PULL_CDRS= 'PULL_CDRS';
export const PULL_CDRS_SUCCESS = 'PULL_CDRS_SUCCESS';
export const PULL_CDRS_FAIL='PULL_CDRS_FAIL';
