export const FETCH_CHARGER_STATE = 'FETCH_CHARGER_STATE';
export const FETCH_CHARGER_STATE_SUCCESS = 'FETCH_CHARGER_STATE_SUCCESS';
export const FETCH_CHARGER_STATE_FAIL = 'FETCH_CHARGER_STATE_FAIL';

export const GET_CHARGER_STATE = 'GET_CHARGER_STATE';
export const GET_CHARGER_STATE_SUCCESS = 'GET_CHARGER_STATE_SUCCESS';
export const GET_CHARGER_STATE_FAIL = 'GET_CHARGER_STATE_FAIL';

export const CREATE_CHARGER_STATE = 'CREATE_CHARGER_STATE';
export const CREATE_CHARGER_STATE_SUCCESS = 'CREATE_CHARGER_STATE_SUCCESS';
export const CREATE_CHARGER_STATE_FAIL = 'CREATE_CHARGER_STATE_FAIL';

export const UPDATE_CHARGER_STATE = 'UPDATE_CHARGER_STATE';
export const UPDATE_CHARGER_STATE_SUCCESS = 'UPDATE_CHARGER_STATE_SUCCESS';
export const UPDATE_CHARGER_STATE_FAIL = 'UPDATE_CHARGER_STATE_FAIL';

export const DELETE_CHARGER_STATE = 'DELETE_CHARGER_STATE';
export const DELETE_CHARGER_STATE_SUCCESS = 'DELETE_CHARGER_STATE_SUCCESS';
export const DELETE_CHARGER_STATE_FAIL = 'DELETE_CHARGER_STATE_FAIL';
