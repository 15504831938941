import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import {FETCH_TAGS,FETCH_TAGS_SUCCESS,FETCH_TAGS_FAIL,CREATE_TAGS,CREATE_TAGS_SUCCESS,CREATE_TAGS_FAIL,DELETE_TAGS,DELETE_TAGS_SUCCESS,DELETE_TAGS_FAIL, UPDATE_TAGS, UPDATE_TAGS_SUCCESS, UPDATE_TAGS_FAIL, GET_TAG,GET_TAG_SUCCESS,GET_TAG_FAIL} from 'actions/tagmanagement';



function* getTags(action) {
    try {
        const response = yield call(API.getTags, action.payload);
        yield put({ type: FETCH_TAGS_SUCCESS, payload: response.data });
        action.cb && action.cb(response);
      } catch (e) {
        yield put({ type: FETCH_TAGS_FAIL, payload: { error: e } });
      }
}
function* createTags(action) {
  try {
      const response = yield call(API.addTags, action.payload);
      yield put({ type: CREATE_TAGS_SUCCESS, payload: response.data });
      action.cb && action.cb(response);
    } catch (e) {
      yield put({ type: CREATE_TAGS_FAIL, payload: { error: e } });
    }
}

function* deleteTags(action) {
  try {
      const response = yield call(API.deleteTags, action.payload);
      yield put({ type: DELETE_TAGS_SUCCESS, payload: response.data });
      action.cb && action.cb(response);
    } catch (e) {
      yield put({ type: DELETE_TAGS_FAIL, payload: { error: e } });
    }
}

function* updateTags(action) {
  try {
    const response = yield call(API.updateTags, action.payload);
    yield put({ type: UPDATE_TAGS_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_TAGS_FAIL, payload: { error: e } });
  }
}


function* getTagsbyID(action)  {
  try {
    const response = yield call(API.getTagsbyId, action.payload);
    yield put({ type: GET_TAG_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: GET_TAG_FAIL, payload: { error: e } });
  }
}


 function* tagmanagement() {
    yield takeLatest(FETCH_TAGS, getTags),
    yield takeLatest(CREATE_TAGS,createTags),
    yield takeLatest(DELETE_TAGS,deleteTags),
    yield takeLatest(UPDATE_TAGS,updateTags),
    yield takeLatest(GET_TAG,getTagsbyID)
 }

 export default tagmanagement;