export const CANCEL_RESERVATION = 'CANCEL_RESERVATION';
export const CANCEL_RESERVATION_SUCCESS = 'CANCEL_RESERVATION_SUCCESS';
export const CANCEL_RESERVATION_FAIL = 'CANCEL_RESERVATION_FAIL';

export const CHANGE_AVAILABILITY = 'CHANGE_AVAILABILITY';
export const CHANGE_AVAILABILITY_SUCCESS = 'CHANGE_AVAILABILITY_SUCCESS';
export const CHANGE_AVAILABILITY_FAIL = 'CREATE_CHANGE_AVAILABILITY_FAIL';

export const GET_CONFIGURATION = 'GET_CONFIGURATION';
export const GET_CONFIGURATION_SUCCESS = 'GET_CONFIGURATION_SUCCESS';
export const GET_CONFIGURATION_FAIL = 'GET_CONFIGURATION_FAIL';

export const GET_DIAGNOSTICS = 'GET_DIAGNOSTICS';
export const GET_DIAGNOSTICS_SUCCESS = 'GET_DIAGNOSTICS_SUCCESS';
export const GET_DIAGNOSTICS_FAIL = 'GET_DIAGNOSTICS_FAIL';

export const CLEAR_CACHE = 'CLEAR_CACHE';
export const CLEAR_CACHE_SUCCESS = 'CLEAR_CACHE_SUCCESS';
export const CLEAR_CACHE_FAIL = 'CLEAR_CACHE_FAIL';

export const REMOTE_START_TRANSACTION = 'REMOTE_START_TRANSACTION';
export const REMOTE_START_TRANSACTION_SUCCESS = 'REMOTE_START_TRANSACTION_SUCCESS';
export const REMOTE_START_TRANSACTION_FAIL = 'REMOTE_START_TRANSACTION_FAIL';

export const REMOTE_STOP_TRANSACTION = 'REMOTE_STOP_TRANSACTION';
export const REMOTE_STOP_TRANSACTION_SUCCESS = 'REMOTE_STOP_TRANSACTION_SUCCESS';
export const REMOTE_STOP_TRANSACTION_FAIL = 'REMOTE_STOP_TRANSACTION_FAIL';

export const RESERVE_NOW = 'RESERVE_NOW';
export const RESERVE_NOW_SUCCESS = 'RESERVE_NOW_SUCCESS';
export const RESERVE_NOW_FAIL = 'RESERVE_NOW_FAIL';

export const RESET = 'RESET';
export const RESET_SUCCESS = 'RESET_SUCCESS';
export const RESET_FAIL = 'RESET_FAIL';

export const SEND_LOCAL_LIST = 'SEND_LOCAL_LIST';
export const SEND_LOCAL_LIST_SUCCESS = 'SEND_LOCAL_LIST_SUCCESS';
export const SEND_LOCAL_LIST_FAIL = 'SEND_LOCAL_LIST_FAIL';

export const TRIGGER_MESSAGE = 'TRIGGER_MESSAGE';
export const TRIGGER_MESSAGE_SUCCESS = 'TRIGGER_MESSAGE_SUCCESS';
export const TRIGGER_MESSAGE_FAIL = 'TRIGGER_MESSAGE_FAIL';

export const UNLOCK_CONNECTOR = 'UNLOCK_CONNECTOR';
export const UNLOCK_CONNECTOR_SUCCESS = 'UNLOCK_CONNECTOR_SUCCESS';
export const UNLOCK_CONNECTOR_FAIL = 'UNLOCK_CONNECTOR_FAIL';

export const UPDATE_FIRMWARE = 'UPDATE_FIRMWARE';
export const UPDATE_FIRMWARE_SUCCESS = 'UPDATE_FIRMWARE_SUCCESS';
export const UPDATE_FIRMWARE_FAIL = 'UPDATE_FIRMWARE_FAIL';

export const DATA_TRANSFER_SERVER = 'DATA_TRANSFER_SERVER';
export const DATA_TRANSFER_SERVER_SUCCESS = 'DATA_TRANSFER_SERVER_SUCCESS';
export const DATA_TRANSFER_SERVER_FAIL = 'DATA_TRANSFER_SERVER_FAIL';

export const GET_LOCAL_LIST = 'GET_LOCAL_LIST';
export const GET_LOCAL_LIST_SUCCESS = 'GET_LOCAL_LIST_SUCCESS';
export const GET_LOCAL_LIST_FAIL = 'GET_LOCAL_LIST_FAIL';

export const CHANGE_CONFIGURATION = 'CHANGE_CONFIGURATION';
export const CHANGE_CONFIGURATION_SUCCESS = 'CHANGE_CONFIGURATION_SUCCESS';
export const CHANGE_CONFIGURATION_FAIL = 'CHANGE_CONFIGURATION_FAIL';


export const SET_CHARGING_PROFILE = 'SET_CHARGING_PROFILE';
export const SET_CHARGING_PROFILE_SUCCESS= 'SET_CHARGING_PROFILE_SUCCESS';
export const SET_CHARGING_PROFILE_FAIL= 'SET_CHARGING_PROFILE_FAIL';

export const CLEAR_CHARGING_PROFILE = 'CLEAR_CHARGING_PROFILE';
export const CLEAR_CHARGING_PROFILE_SUCCESS= 'CLEAR_CHARGING_PROFILE_SUCCESS';
export const CLEAR_CHARGING_PROFILE_FAIL= 'CLEAR_CHARGING_PROFILE_FAIL';