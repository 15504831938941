import { GET_CONSUMPTION, GET_CONSUMPTION_SUCCESS, GET_CONSUMPTION_FAIL } from '../actions/chargingStation';

const initialState = {
  isLoading: false,
  consumptions: [],
};

const consumptionReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_CONSUMPTION: {
      return { ...state, isLoading: true };
    }
    case GET_CONSUMPTION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        consumptions: payload,
      };
    }

    case GET_CONSUMPTION_FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};
export default consumptionReducer;
