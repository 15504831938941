import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import {
  CREATE_SCHEDULING_SETTING,
  CREATE_SCHEDULING_SETTING_SUCCESS,
  CREATE_SCHEDULING_SETTING_FAIL,
  FETCH_SCHEDULING_SETTING,
  FETCH_SCHEDULING_SETTING_SUCCESS,
  FETCH_SCHEDULING_SETTING_FAIL,
  GET_SCHEDULING_SETTING,
  GET_SCHEDULING_SETTING_SUCCESS,
  GET_SCHEDULING_SETTING_FAIL,
  UPDATE_SCHEDULING_SETTING,
  UPDATE_SCHEDULING_SETTING_SUCCESS,
  UPDATE_SCHEDULING_SETTING_FAIL,
  DELETE_SCHEDULING_SETTING,
  DELETE_SCHEDULING_SETTING_SUCCESS,
  DELETE_SCHEDULING_SETTING_FAIL,
} from 'actions/schedulingSetting.js';

function* getAllSchedulingSetting(action) {
  try {
    const response = yield call(API.getAllSchedulingSetting, action.payload);
    yield put({ type: FETCH_SCHEDULING_SETTING_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_SCHEDULING_SETTING_FAIL, payload: { error: e } });
  }
}

function* getAllSchedulingSettingById(action) {
  try {
    const response = yield call(API.getSchedulingSettingById, action.payload);
    yield put({ type: GET_SCHEDULING_SETTING_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_SCHEDULING_SETTING_FAIL, payload: { error: e } });
  }
}

function* createSchedulingSetting(action) {
  try {
    const response = yield call(API.createSchedulingSetting, action.payload);
    yield put({ type: CREATE_SCHEDULING_SETTING_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_SCHEDULING_SETTING_FAIL, payload: { error: e } });
  }
}

function* updateSchedulingSetting(action) {
  try {
    const response = yield call(API.updateSchedulingSetting, action.payload);
    yield put({ type: UPDATE_SCHEDULING_SETTING_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_SCHEDULING_SETTING_FAIL, payload: { error: e } });
  }
}

function* deleteSchedulingSetting(action) {
  try {
    const response = yield call(API.deleteSchedulingSetting, action.payload);
    yield put({ type: DELETE_SCHEDULING_SETTING_SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_SCHEDULING_SETTING_FAIL, payload: { error: e } });
  }
}

function* schedulingSetting() {
  yield takeLatest(FETCH_SCHEDULING_SETTING, getAllSchedulingSetting);
  yield takeLatest(CREATE_SCHEDULING_SETTING, createSchedulingSetting);
  yield takeLatest(GET_SCHEDULING_SETTING, getAllSchedulingSettingById);
  yield takeLatest(UPDATE_SCHEDULING_SETTING, updateSchedulingSetting);
  yield takeLatest(DELETE_SCHEDULING_SETTING, deleteSchedulingSetting);
}

export default schedulingSetting;
