import { ALL_USERS_IN_USER_GROUP, ALL_USERS_IN_USER_GROUP_SUCCESS, ALL_USERS_IN_USER_GROUP_FAIL } from 'actions/userGroup';

const initialState = {
  isLoading: false,
  allUserInUserGroup: [],
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const allUserInUserGroupReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ALL_USERS_IN_USER_GROUP: {
      return { ...state, isLoading: true };
    }

    case ALL_USERS_IN_USER_GROUP_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        allUserInUserGroup: payload.results,
      };
    }

    case ALL_USERS_IN_USER_GROUP_FAIL: {
      return { ...state, isLoading: false };
    }
    default:
      return { ...state };
  }
};
export default allUserInUserGroupReducer;
