import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import {
  FETCH_OEM,
  FETCH_OEM_FAIL,
  FETCH_OEM_SUCCESS,
  GET_OEM,
  GET_OEM_SUCCESS,
  GET_OEM_FAIL,
  CREATE_OEM,
  CREATE_OEM_SUCCESS,
  CREATE_OEM_FAIL,
  UPDATE_OEM,
  UPDATE_OEM_SUCCESS,
  UPDATE_OEM_FAIL,
  DELETE_OEM,
  DELETE_OEM_SUCCESS,
  DELETE_OEM_FAIL,
  OEM_UPDATE_FIRMWARE_SUCCESS,
  OEM_UPDATE_FIRMWARE_FAIL,
  OEM_UPDATE_FIRMWARE,
} from 'actions/oem';

function* fetchOem(action) {
  try {
    const response = yield call(API.getOems, action.payload);
    yield put({ type: FETCH_OEM_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_OEM_FAIL, payload: { error: e } });
  }
}

function* getOemById(action) {
  try {
    const response = yield call(API.getOemById, action.payload);
    yield put({ type: GET_OEM_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_OEM_FAIL, payload: { error: e } });
  }
}

function* createOem(action) {
  try {
    const response = yield call(API.addOem, action.payload);
    yield put({ type: CREATE_OEM_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: CREATE_OEM_FAIL, payload: { error: e } });
  }
}

function* updateOem(action) {
  try {
    const response = yield call(API.updateOem, action.payload);
    yield put({ type: UPDATE_OEM_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: UPDATE_OEM_FAIL, payload: { error: e } });
  }
}

function* updateOemFirmware(action) {
  try {
    const response = yield call(API.updateOemFirmware, action.payload);
    yield put({ type: OEM_UPDATE_FIRMWARE_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: OEM_UPDATE_FIRMWARE_FAIL, payload: { error: e } });
  }
}

function* deleteOem(action) {
  try {
    const response = yield call(API.deleteOem, action.payload);
    yield put({ type: DELETE_OEM_SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_OEM_FAIL, payload: { error: e } });
  }
}

function* oem() {
  yield takeLatest(FETCH_OEM, fetchOem);
  yield takeLatest(GET_OEM, getOemById);
  yield takeLatest(CREATE_OEM, createOem);
  yield takeLatest(UPDATE_OEM, updateOem);
  yield takeLatest(DELETE_OEM, deleteOem);
  yield takeLatest(OEM_UPDATE_FIRMWARE, updateOemFirmware);
}

export default oem;
