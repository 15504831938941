import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import {
  ADD_TENANT,
  ADD_TENANT_FAIL,
  ADD_TENANT_SUCCESS,
  DELETE_TENANT,
  DELETE_TENANT_SUCCESS,
  DELETE_TENANT_FAIL,
  FETCH_TENANT,
  FETCH_TENANT_FAIL,
  FETCH_TENANT_SUCCESS,
  GET_TENANT,
  GET_TENANT_FAIL,
  GET_TENANT_SUCCESS,
  UPDATE_TENANT,
  UPDATE_TENANT_SUCCESS,
  UPDATE_TENANT_FAIL,
  UPDATE_TENANT_CONTACT_INFORMATION,
  UPDATE_TENANT_CONTACT_INFORMATION_SUCCESS,
  UPDATE_TENANT_CONTACT_INFORMATION_FAIL,
} from 'actions/tenant';

function* fetchTenant(action) {
  try {
    const response = yield call(API.getTenant, action.payload);
    yield put({ type: FETCH_TENANT_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_TENANT_FAIL, payload: { error: e } });
  }
}

function* getTenantById(action) {
  try {
    const response = yield call(API.getTenantById, action.payload);
    yield put({ type: GET_TENANT_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_TENANT_FAIL, payload: { error: e } });
  }
}

function* createTenant(action) {
  try {
    const response = yield call(API.addTenant, action.payload);
    yield put({ type: ADD_TENANT_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: ADD_TENANT_FAIL, payload: { error: e } });
  }
}

function* updateTenant(action) {
  try {
    const response = yield call(API.updateTenant, action.payload);
    yield put({ type: UPDATE_TENANT_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_TENANT_FAIL, payload: { error: e } });
  }
}

function* updateTenantContactInformation(action) {
  try {
    const response = yield call(API.updateTenantContactInformation, action.payload);
    yield put({ type: UPDATE_TENANT_CONTACT_INFORMATION_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_TENANT_CONTACT_INFORMATION_FAIL, payload: { error: e } });
  }
}

function* deleteTenant(action) {
  try {
    const response = yield call(API.deleteTenant, action.payload);
    yield put({ type: DELETE_TENANT_SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_TENANT_FAIL, payload: { error: e } });
  }
}

function* tenant() {
  yield takeLatest(ADD_TENANT, createTenant);
  yield takeLatest(FETCH_TENANT, fetchTenant);
  yield takeLatest(GET_TENANT, getTenantById);
  yield takeLatest(UPDATE_TENANT, updateTenant);
  yield takeLatest(UPDATE_TENANT_CONTACT_INFORMATION, updateTenantContactInformation);
  yield takeLatest(DELETE_TENANT, deleteTenant);
}

export default tenant;
