import React, { useCallback, useEffect, useState, useContext } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header/index';
import Card from 'components/inputs/Card';
import SessionHeading from 'components/general/SessionHeading';
import { Row, Col } from 'react-bootstrap';
import TextInput from 'components/inputs/Input';
import PhoneNumberInput from 'components/inputs/PhoneNumberInput';
import Button from 'components/inputs/Button';
import { isValidPhoneNumber } from 'react-phone-number-input';
import * as _ from 'lodash';
import { Formik, Form, getIn, FieldArray } from 'formik';
import * as Yup from 'yup';
import { FETCH_CITY, FETCH_STATE, FETCH_COUNTRY, FETCH_AREA } from 'actions/address';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_TENANT, GET_TENANT, UPDATE_TENANT_CONTACT_INFORMATION } from 'actions/tenant';
import Select from 'components/inputs/Select';
import moment from 'moment';
import DatePickerInput from 'components/inputs/DatePickerInput';
import CheckBox from 'components/inputs/CheckBox';
import { useParams } from 'react-router-dom';
// import { useHistory } from 'react-router-dom';
import { UPDATE_STATUS_CODE } from 'components/common/constant';
import RadioButton from 'components/inputs/RadioButton';
import { BiPlus, BiTrash } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { cookie } from 'utils/cookies/cookies';
import { toast } from 'react-toastify';
import queryString from 'query-string';
import { NavContext } from 'components/privateRoute/PrivateRoute';

const TenantForm = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { tenantId } = useParams();
  // const history = useHistory();
  const { navigateTo } = useContext(NavContext);
  const [phoneError, setPhoneError] = useState('');
  const [value, setValue] = useState(false);
  // const [index, setIndex] = useState('');

  const tenantDetail = useSelector((state) => state.tenant.tenantDetail);
  const allCountries = useSelector((state) => state.country.countries);
  const allStates = useSelector((state) => state.state.states);
  const allCities = useSelector((state) => state.city.city);
  const allArea = useSelector((state) => state.area.areas);
  const countryPage = useSelector((state) => state.country.page);
  const statePage = useSelector((state) => state.state.page);
  const cityPage = useSelector((state) => state.city.page);
  const areaPage = useSelector((state) => state.area.page);
  const countryTotalPage = useSelector((state) => state.country.totalPages);
  const stateTotalPage = useSelector((state) => state.state.totalPages);
  const cityTotalPage = useSelector((state) => state.city.totalPages);
  const areaTotalPage = useSelector((state) => state.area.totalPages);

  const getTenant = useCallback((id) => {
    dispatch({ type: GET_TENANT, payload: id });
  }, []);

  const updateContactInformation = useCallback((data) => {
    dispatch({
      type: UPDATE_TENANT_CONTACT_INFORMATION,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          navigateTo('/tenantAdmin');
        }
      },
    });
  }, []);

  const editTenantDetail = useCallback((data) => {
    // Check if ocpp_party_id is blank (empty string)
    const partyId = {
      ...data,
      ocpp_party_id: data?.ocpp_party_id ? data?.ocpp_party_id : ' ', // Replace empty string with a single space
    };

    // if (data.ocpp_party_id.trim() === '') {
    //   data.ocpp_party_id = ' ';
    // }

    dispatch({
      type: UPDATE_TENANT,
      payload: partyId,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          toast.success('Tenant updated successfully', { autoClose: 3000 });
          setTimeout(() => {
            navigateTo('/tenantAdmin');
          }, 1000);
        }
      },
    });
  }, []);

  const getAllCountry = useCallback((data = {}) => {
    dispatch({ type: FETCH_COUNTRY, payload: data });
  }, []);

  const getStateByCountry = useCallback((data = {}) => {
    dispatch({ type: FETCH_STATE, payload: data });
  }, []);

  const getCityByState = useCallback((data = {}) => {
    dispatch({ type: FETCH_CITY, payload: data });
  }, []);

  const getAreaByCity = useCallback((data = {}) => {
    dispatch({ type: FETCH_AREA, payload: data });
  }, []);

  useEffect(() => {
    getAllCountry();
  }, []);

  useEffect(() => {
    if (tenantId && !_.isEmpty(tenantDetail)) {
      getStateByCountry({ country_name: tenantDetail.address.country, limit: 999 });
      getCityByState({ state_name: tenantDetail.address.state, limit: 999 });
      getAreaByCity({ city_name: tenantDetail.address.city });
    }
  }, [tenantId, tenantDetail]);

  useEffect(() => {
    if (tenantId) {
      getTenant(tenantId);
    }
  }, [tenantId]);

  const EditContactInformationSchema = Yup.object().shape({
    contact_person: Yup.object().shape({
      name: Yup.string()
        .required(t('editTenantForm.name'))
        .strict(true)
        .trim(t('editTenantForm.space'))
        .matches(/^[A-Za-z\s]+$/, t('editTenantForm.matches'))
        .max(100, t('editTenantForm.maxlimit')),
      email: Yup.string()
        .required(t('editTenantForm.email'))
        .strict(true)
        .trim(t('editTenantForm.space'))
        .email(t('editTenantForm.emailChk'))
        .max(100, t('editTenantForm.maxlimit')),
      // phone: Yup.string()
      //   .required('Phone Number is required!')
      //   .strict(true)
      //   .trim('Space is not allowed')
      //   .max(100, 'Text limit should be less than 100 characters.'),
      ocpp_party_id: Yup.string()
        .strict(true)
        .matches(/^[A-Za-z]+$/, t('tenantForm.matches'))
        .length(3, t('Text limit should be only 3 characters')),
      dob: Yup.string().required(t('editTenantForm.dob')).strict(true).trim(t('editTenantForm.space')).max(100, t('editTenantForm.maxlimit')),
      address: Yup.string().required(t('editTenantForm.address')).strict(true).trim(t('editTenantForm.space')).max(100, t('editTenantForm.maxlimit')),
    }),
  });

  const EditTenantSchema = Yup.object().shape({
    name: Yup.string()
      .required(t('editTenantForm.name'))
      .strict(true)
      .trim(t('editTenantForm.space'))
      .matches(/^[A-Za-z\s]/, t('editTenantForm.matches'))
      .max(100, t('editTenantForm.maxlimit')),
    logo: Yup.mixed().nullable(),
    charging_stations: Yup.number().required(t('editTenantForm.chargingStation')).min(1, t('editTenantForm.numchk')),
    chargers: Yup.number().required(t('editTenantForm.charger')).min(0, t('editTenantForm.numchk')),
    mode: Yup.string().required(t('editTenantForm.mode')),
    license_expiry: Yup.string().required(t('editTenantForm.licenseExpiry')),
    allow_remote_access: Yup.string().required(t('editTenantForm.remoteAccess')),
    max_user: Yup.number().required(t('editTenantForm.maxUser')).min(0, t('editTenantForm.numchk')),
    address: Yup.object().shape({
      country: Yup.string().required(t('editTenantForm.country')),
      state: Yup.string().required(t('editTenantForm.state')),
      city: Yup.string().required(t('editTenantForm.city')),
      area: Yup.string().required(t('editTenantForm.area')),
      pincode: Yup.string().required(t('editTenantForm.pincode')),
      details: Yup.string().required(t('editTenantForm.address')).strict(true).trim(t('editTenantForm.space')).max(100, t('editTenantForm.maxlimit')),
    }),
    details: Yup.object().shape({
      gst: Yup.array().of(
        Yup.object().shape({
          state: Yup.string().required(t('editTenantForm.state')),
          value: Yup.string()
            .required(t('editTenantForm.gst'))
            .matches(/\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/, t('editTenantForm.gstValidation')),
        })
      ),
    }),
    ocpp_party_id: Yup.string()
      .strict(true)
      .matches(/^[A-Z]+$/, t('tenantForm.matches'))
      .length(3, t('Text limit should be only 3 characters')),
  });

  const initialValues = {
    ...tenantDetail,
    license_expiry: moment(_.get(tenantDetail, 'license_expiry')).utcOffset(0, true).format('YYYY-MM-DD'),
    allow_remote_access: _.get(tenantDetail, 'allow_remote_access', '').toString(),
    max_user: _.get(tenantDetail, 'max_user', 0),
    details: {
      gst: _.map(_.get(tenantDetail, 'details.gst'), (item, i, array) => {
        return {
          is_default: array.length === 1 || item.is_default,
          state: item.state,
          value: item.value,
          address: item.address ? item.address : '',
          pincode: item.pincode ? item.pincode : '',
        };
      }),
      notes: _.get(tenantDetail, 'details.notes', ''),
    },
    logo: _.get(tenantDetail, 'logo', ''),
  };

  const contactInformationInitialValues = {
    contact_person: {
      name: _.get(tenantDetail, 'contact_person.name'),
      email: _.get(tenantDetail, 'contact_person.email'),
      dob: moment(_.get(tenantDetail, 'contact_person.dob')).format('YYYY-MM-DD'),
      address: _.get(tenantDetail, 'contact_person.address'),
      gender: _.get(tenantDetail, 'contact_person.gender'),
      phone: `+${_.get(tenantDetail, 'contact_person.country_code')} ${_.get(tenantDetail, 'contact_person.phone')}`.toString(),
    },
  };

  const token = cookie.get('token');
  const handleImage = (image, savePath, setFieldValue) => {
    const fileName = _.get(image, 'name', '');

    axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: process.env.REACT_APP_API_URL + `/util/fileupload?name=${fileName}`,
    })
      .then((res) => {
        if (res.status === 201) {
          const signedRequest = _.get(res, 'data.signedRequest', '');
          handleUploadFile(signedRequest, image, savePath, setFieldValue);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((e) => console.error('Error -> ', e));
  };
  const handleUploadFile = (url, imageData, savePath, setFieldValue) => {
    const fileType = _.get(imageData, 'type', '');

    axios({
      method: 'put',
      headers: {
        'Content-Type': fileType,
        'Content-Encoding': 'base64',
      },
      url: url,
      data: imageData,
    })
      .then((res) => {
        if (res.status === 200) {
          const imgUrlData = queryString.parseUrl(url);
          setFieldValue(savePath, imgUrlData.url);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((e) => console.error('Error -> ', e));
  };
  useEffect(() => console.log('initial Values: ', initialValues), [initialValues]);
  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title={`Edit Tenant`} backTo="/tenantAdmin" />
        <div className="page-content-wrapper scrollable">
          <div className="add-tenant-page__main">
            <Card className="tenant-form--card">
              <Formik
                enableReinitialize={!!tenantId}
                initialValues={initialValues}
                validationSchema={EditTenantSchema}
                onSubmit={(values, { setSubmitting }) => {
                  const tenantData = _.omit(values, ['contact_person', 'user_count', 'invoice_suffix']);
                  const editTenantData = {
                    ...tenantData,
                    license_expiry: moment(_.get(values, 'license_expiry', '')).format('MM-DD-YYYY'),
                    allow_remote_access: JSON.parse(_.get(values, 'allow_remote_access').toLowerCase()),
                  };
                  editTenantDetail(editTenantData);
                  setSubmitting(false);
                }}
              >
                {({ values, errors, touched, handleSubmit, handleChange, setFieldValue, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <div>
                      <SessionHeading text={t('editTenantForm.generalInfo')} />
                      <div className="tenant-form--block pb-4">
                        <Row>
                          <Col lg={6} md={6}>
                            <TextInput
                              isRequired
                              label={t('editTenantForm.companyName')}
                              placeholder={t('editTenantForm.companyName')}
                              name="name"
                              type="text"
                              value={values.name}
                              onChange={handleChange}
                              error={errors.name && touched.name ? errors.name : null}
                            />
                          </Col>
                          <Col lg={6} md={6}>
                            <Select
                              isRequired
                              label={t('editTenantForm.modeLabel')}
                              options={[
                                { label: 'B2B', value: 'b2b' },
                                { label: 'B2C', value: 'b2c' },
                                { label: 'Hybrid', value: 'hybrid' },
                              ]}
                              placeholder={t('editTenantForm.modeLabel')}
                              name="mode"
                              value={values.mode}
                              onChange={(val) => setFieldValue(`mode`, val)}
                              error={errors.mode && touched.mode ? errors.mode : null}
                            />
                          </Col>
                          <Col lg={6} md={6}>
                            <TextInput
                              type="file"
                              label={t('editTenantForm.companyLogo')}
                              name="logo"
                              accept="image/*"
                              onChange={(e) => handleImage(e.currentTarget.files[0], 'logo', setFieldValue)}
                              error={errors.logo && touched.logo ? errors.logo : null}
                              image={values.logo}
                            />
                          </Col>
                          <Col lg={6} md={6}>
                            <TextInput
                              isRequired
                              placeholder={t('editTenantForm.chargingStationLabel')}
                              label={t('editTenantForm.chargingStationLabel')}
                              name="charging_stations"
                              type="number"
                              value={values.charging_stations}
                              onChange={handleChange}
                              error={errors.charging_stations && touched.charging_stations ? errors.charging_stations : null}
                            />
                          </Col>
                          <Col lg={6} md={6}>
                            <TextInput
                              isRequired
                              placeholder={t('editTenantForm.chargers')}
                              label={t('editTenantForm.chargers')}
                              name="chargers"
                              type="number"
                              value={values.chargers}
                              onChange={handleChange}
                              error={errors.chargers && touched.chargers ? errors.chargers : null}
                            />
                          </Col>
                          <Col lg={6} md={6}>
                            <TextInput
                              isRequired
                              placeholder={t('editTenantForm.maximumUser')}
                              label={t('editTenantForm.maximumUser')}
                              name="max_user"
                              type="number"
                              value={values.max_user}
                              onChange={handleChange}
                              error={errors.max_user && touched.max_user ? errors.max_user : null}
                            />
                          </Col>
                          <Col lg={6} md={6}>
                            <DatePickerInput
                              isRequired
                              placeholder={t('editTenantForm.licenseExpiryLabel')}
                              label={t('editTenantForm.licenseExpiryLabel')}
                              name="license_expiry"
                              value={values.license_expiry}
                              onChange={(dateString) => setFieldValue('license_expiry', dateString)}
                              error={errors.license_expiry && touched.license_expiry ? errors.license_expiry : null}
                            />
                          </Col>
                          <Col lg={6} md={6}>
                            <TextInput
                              as="textarea"
                              rows="4"
                              label={t('editTenantForm.notes')}
                              placeholder={t('editTenantForm.notes')}
                              name="details.notes"
                              value={_.get(values, 'details.notes')}
                              onChange={handleChange}
                              // error={getIn(errors, 'details.notes') && getIn(touched, 'details.notes') ? getIn(errors, 'details.notes') : null}
                            />
                          </Col>
                          <Col lg={6} md={6}>
                            <Select
                              isRequired
                              label={t('editTenantForm.remoteAccessLabel')}
                              options={[
                                { label: 'True', value: 'true' },
                                { label: 'False', value: 'false' },
                              ]}
                              placeholder={t('editTenantForm.selectRemoteAccess')}
                              name="allow_remote_access"
                              value={values.allow_remote_access}
                              onChange={(val) => setFieldValue(`allow_remote_access`, val)}
                              error={errors.allow_remote_access && touched.allow_remote_access ? errors.allow_remote_access : null}
                            />
                            <CheckBox
                              id="support_cz_app_booking"
                              name="support_cz_app_booking"
                              checked={values.support_cz_app_booking}
                              onChange={(e) => {
                                setFieldValue('support_cz_app_booking', e.target.checked);
                              }}
                            >
                              {t('editTenantForm.supportCZApp')}
                            </CheckBox>
                          </Col>
                          <Col lg={6} md={12}>
                            <TextInput
                              label={t('editTenantForm.ocpiPartyId')}
                              placeholder={t('editTenantForm.ocpiPartyId')}
                              name="ocpp_party_id"
                              value={_.get(values, 'ocpp_party_id')}
                              onChange={(e) => setFieldValue('ocpp_party_id', e.target.value)}
                              error={errors.ocpp_party_id && touched.ocpp_party_id ? errors.ocpp_party_id : null}
                            />
                          </Col>
                          <Col lg={6} md={6}>
                            <div className="notification-setting__block">
                              <label htmlFor="notification_allow" className="notification--label">
                                {t('editTenantForm.notification')}
                              </label>
                              <div id="notification_allow" className="notification-setting--inner">
                                <div className="notification--item">
                                  <CheckBox
                                    id="email"
                                    name="email"
                                    checked={_.includes(_.get(values, 'settings.notification_allowed'), 'Email')}
                                    onChange={() => {
                                      if (_.includes(_.get(values, 'settings.notification_allowed'), 'Email')) {
                                        const newNotificationData = _.remove(
                                          _.get(values, 'settings.notification_allowed'),
                                          (item) => item !== 'Email'
                                        );
                                        setFieldValue('settings.notification_allowed', newNotificationData);
                                      } else {
                                        setFieldValue(
                                          'settings.notification_allowed',
                                          _.concat(_.get(values, 'settings.notification_allowed'), 'Email')
                                        );
                                      }
                                    }}
                                  >
                                    {t('editTenantForm.emailLabel')}
                                  </CheckBox>
                                </div>
                                <div className="notification--item">
                                  <CheckBox
                                    id="sms"
                                    name="sms"
                                    checked={_.includes(_.get(values, 'settings.notification_allowed'), 'SMS')}
                                    onChange={() => {
                                      if (_.includes(_.get(values, 'settings.notification_allowed'), 'SMS')) {
                                        const newNotificationData = _.remove(
                                          _.get(values, 'settings.notification_allowed'),
                                          (item) => item !== 'SMS'
                                        );
                                        setFieldValue('settings.notification_allowed', newNotificationData);
                                      } else {
                                        setFieldValue(
                                          'settings.notification_allowed',
                                          _.concat(_.get(values, 'settings.notification_allowed'), 'SMS')
                                        );
                                      }
                                    }}
                                  >
                                    {t('editTenantForm.sms')}
                                  </CheckBox>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col lg={6} md={6}>
                            <div className="generateEInvoice-field-main--block">
                              <div className="generateEInvoice-checkbox-input__block">
                                <label htmlFor="generateEInvoice" className="generateEInvoice--label">
                                  {t('editTenantForm.manageAutoEmail')}
                                </label>
                                <CheckBox
                                  name="is_email_invoice"
                                  checked={_.get(values, 'is_email_invoice')}
                                  onChange={(e) => setFieldValue('is_email_invoice', e.target.checked)}
                                />
                              </div>
                              <div className="generateNewSeriesInvoice-checkbox-input__block">
                                <label htmlFor="generateNewSeriesInvoice" className="generateNewSeriesInvoice--label">
                                  {t('editTenantForm.generateNewSeriesInvoice')}
                                </label>
                                <CheckBox
                                  name="generateNewSeriesInvoice"
                                  checked={_.get(values, 'generateNewSeriesInvoice')}
                                  onChange={(e) => setFieldValue('generateNewSeriesInvoice', e.target.checked)}
                                />
                              </div>
                            </div>
                          </Col>
                          <div className="mt-3">
                            <Col lg={12} md={12}>
                              <SessionHeading text={t('editTenantForm.gstDetails')} />
                              <div className="tenant-form-page__main">
                                <div className="gst-block__field-inputs">
                                  <FieldArray
                                    name="details.gst"
                                    render={(arrayHelpers) => (
                                      <>
                                        <Row>
                                          {_.map(values.details.gst, (field, index) => (
                                            <>
                                              <Col lg={12} key={`gst-${index}`}>
                                                <Row>
                                                  <Col lg={12} md={12}>
                                                    <TextInput
                                                      isRequired
                                                      label={t('editTenantForm.addressLabel')}
                                                      placeholder={t('editTenantForm.addressLabel')}
                                                      name="address"
                                                      value={field.address}
                                                      onChange={(e) => setFieldValue(`details.gst[${index}].address`, e.target.value)}
                                                      error={
                                                        getIn(errors, `details.gst[${index}].address`) &&
                                                        getIn(touched, `details.gst[${index}].address`)
                                                          ? getIn(errors, `details.gst[${index}].address`)
                                                          : null
                                                      }
                                                    />
                                                  </Col>
                                                  <Col lg={3} md={3}>
                                                    <Select
                                                      isRequired
                                                      className="mb-3"
                                                      label={t('filters.state')}
                                                      onFocus={() => getStateByCountry({ country_name: 'India' })}
                                                      options={_.map(allStates, (item) => {
                                                        return { label: item.name, value: item.name };
                                                      })}
                                                      placeholder={t('placeHolder.selectState')}
                                                      name="state"
                                                      value={_.get(field, 'state')}
                                                      onMenuScrollDown={true}
                                                      getDataOnScrollDown={(data) => getStateByCountry({ ...data, country_name: 'India' })}
                                                      page={statePage}
                                                      totalPage={stateTotalPage}
                                                      onChange={(val) => {
                                                        // getCityByState(val);
                                                        const currentState = _.find(allStates, { name: val });
                                                        setFieldValue(`details.gst[${index}].state`, currentState.name);
                                                      }}
                                                      error={
                                                        getIn(errors, `details.gst[${index}].state`) && getIn(touched, `details.gst[${index}].state`)
                                                          ? getIn(errors, `details.gst[${index}].state`)
                                                          : null
                                                      }
                                                    />
                                                  </Col>
                                                  <Col lg={3} md={3}>
                                                    <TextInput
                                                      isRequired
                                                      label={t('editTenantForm.pincode')}
                                                      placeholder={t('editTenantForm.pincode')}
                                                      name="pincode"
                                                      value={field.pincode}
                                                      onChange={(e) => setFieldValue(`details.gst[${index}].pincode`, e.target.value)}
                                                      error={
                                                        getIn(errors, `details.gst[${index}].pincode`) &&
                                                        getIn(touched, `details.gst[${index}].pincode`)
                                                          ? getIn(errors, `details.gst[${index}].pincode`)
                                                          : null
                                                      }
                                                    />
                                                  </Col>
                                                  <Col lg={3} md={3}>
                                                    <TextInput
                                                      isRequired
                                                      label={t('editTenantForm.gstNo')}
                                                      name="value"
                                                      value={field.value}
                                                      onChange={(e) => {
                                                        const inputValue = e.target.value.slice(0, 15); // Limit to 15 characters
                                                        setFieldValue(`details.gst[${index}].value`, inputValue);
                                                      }}
                                                      onBlur={(e) => {
                                                        if (e.target.value.length < 15) {
                                                          // Show a warning message or perform other actions
                                                          getIn(errors, `details.gst[${index}].value`) &&
                                                          getIn(touched, `details.gst[${index}].value`)
                                                            ? getIn(errors, `details.gst[${index}].value`)
                                                            : null;
                                                        }
                                                      }}
                                                      error={
                                                        getIn(errors, `details.gst[${index}].value`) && getIn(touched, `details.gst[${index}].value`)
                                                          ? getIn(errors, `details.gst[${index}].value`)
                                                          : null
                                                      }
                                                      maxLength={15} // Maximum number of characters allowed
                                                    />
                                                  </Col>
                                                  <Col lg={2} md={2}>
                                                    <div className="pt-5">
                                                      <RadioButton
                                                        containerClassName="radio-field"
                                                        checked={field.is_default === true}
                                                        value="true"
                                                        name="is_default"
                                                        onChange={(event) => {
                                                          _.map(values.details.gst, (item, i) => {
                                                            if (i === index) {
                                                              item.is_default = value;
                                                            } else {
                                                              item.is_default = false;
                                                            }
                                                          });
                                                          setFieldValue(`details.gst[${index}].is_default`, event.target.checked);
                                                          setValue(event.target.checked);
                                                          // setIndex(index);
                                                        }}
                                                      >
                                                        {t('editTenantForm.isDefault')}
                                                      </RadioButton>
                                                    </div>
                                                  </Col>
                                                  <Col xl={1} lg={1} md={1} className="d-flex align-items-center">
                                                    {index > 0 && (
                                                      <Button className="tenant-field--btn" onClick={() => arrayHelpers.remove(index)}>
                                                        <BiTrash size={25} />
                                                      </Button>
                                                    )}
                                                  </Col>
                                                </Row>
                                              </Col>
                                            </>
                                          ))}
                                        </Row>
                                        <div>
                                          <Button
                                            className="tenant-field--btn"
                                            onClick={() =>
                                              arrayHelpers.insert(values.details.gst.length, {
                                                state: '',
                                                value: '',
                                                is_default: false,
                                              })
                                            }
                                          >
                                            <BiPlus size={22} className="plus--icon" /> {t('editTenantForm.add')}
                                          </Button>
                                        </div>
                                      </>
                                    )}
                                  />
                                </div>
                              </div>
                            </Col>
                          </div>
                        </Row>
                      </div>
                    </div>
                    <div>
                      <SessionHeading text={t('editTenantForm.addressInfo')} />
                      <div className="tenant-form--block">
                        <Row>
                          <Col lg={12} md={12}>
                            <TextInput
                              isRequired
                              label={t('editTenantForm.addressLabel')}
                              placeholder={t('editTenantForm.addressLabel')}
                              name="address.details"
                              value={_.get(values, 'address.details')}
                              onChange={handleChange}
                              error={getIn(errors, 'address.details') && getIn(touched, 'address.details') ? getIn(errors, 'address.details') : null}
                            />
                          </Col>
                          <Col lg={6} md={6}>
                            <Select
                              isRequired
                              label={t('filters.country')}
                              options={_.map(allCountries, (item) => {
                                return { label: item.name, value: item.name };
                              })}
                              placeholder={t('placeHolder.selectCountry')}
                              onMenuScrollDown={true}
                              getDataOnScrollDown={getAllCountry}
                              page={countryPage}
                              totalPage={countryTotalPage}
                              name="address.country"
                              value={_.get(values, 'address.country')}
                              onChange={(val) => {
                                getStateByCountry({ country_name: val });
                                const currentCountry = _.find(allCountries, { name: val });
                                setFieldValue(`address.country`, currentCountry.name);
                                setFieldValue('address.state', '');
                                setFieldValue(`address.city`, '');
                                setFieldValue(`address.area`, '');
                                setFieldValue(`address.pincode`, '');
                              }}
                              error={getIn(errors, 'address.country') && getIn(touched, 'address.country') ? getIn(errors, 'address.country') : null}
                            />
                          </Col>
                          <Col lg={6} md={6}>
                            <Select
                              isRequired
                              className="mb-3"
                              label={t('filters.state')}
                              options={_.map(allStates, (item) => {
                                return { label: item.name, value: item.name };
                              })}
                              placeholder={t('placeHolder.selectState')}
                              name="address.state"
                              onMenuScrollDown={true}
                              getDataOnScrollDown={(data) => getStateByCountry({ ...data, country_name: _.get(values, 'address.country') })}
                              page={statePage}
                              totalPage={stateTotalPage}
                              value={_.get(values, 'address.state')}
                              onChange={(val) => {
                                getCityByState({ state_name: val });
                                const currentState = _.find(allStates, { name: val });
                                setFieldValue(`address.state`, currentState.name);
                                setFieldValue(`address.city`, '');
                                setFieldValue(`address.area`, '');
                                setFieldValue(`address.pincode`, '');
                              }}
                              error={getIn(errors, 'address.state') && getIn(touched, 'address.state') ? getIn(errors, 'address.state') : null}
                            />
                          </Col>
                          <Col lg={6} md={6}>
                            <Select
                              isRequired
                              className="mb-3"
                              label={t('filters.city')}
                              options={
                                _.get(values, 'address.state') &&
                                _.map(allCities, (item) => {
                                  return { label: item.name, value: item.name };
                                })
                              }
                              placeholder={t('placeHolder.selectCity')}
                              name="address.city"
                              value={_.get(values, 'address.city')}
                              onMenuScrollDown={true}
                              getDataOnScrollDown={(data) => getCityByState({ ...data, state_name: _.get(values, 'address.state') })}
                              page={cityPage}
                              totalPage={cityTotalPage}
                              onChange={(val) => {
                                getAreaByCity({ city_name: val });
                                const currentCity = _.find(allCities, { name: val });
                                setFieldValue(`address.city`, currentCity.name);
                                setFieldValue(`address.area`, '');
                                setFieldValue(`address.pincode`, '');
                              }}
                              error={getIn(errors, 'address.city') && getIn(touched, 'address.city') ? getIn(errors, 'address.city') : null}
                            />
                          </Col>
                          <Col lg={6} md={6}>
                            <Select
                              isRequired
                              label={t('filters.area')}
                              options={
                                _.get(values, 'address.city') &&
                                _.map(allArea, (item) => {
                                  return { label: item.name, value: item.name };
                                })
                              }
                              placeholder={t('placeHolder.selectArea')}
                              name="address.area"
                              value={_.get(values, 'address.area')}
                              onMenuScrollDown={true}
                              getDataOnScrollDown={(data) => getAreaByCity({ ...data, city_name: _.get(values, 'address.city') })}
                              page={areaPage}
                              totalPage={areaTotalPage}
                              onChange={(val) => {
                                const currentArea = _.find(allArea, { name: val });
                                setFieldValue(`address.area`, currentArea.name);
                                setFieldValue(`address.pincode`, currentArea.postal_code);
                              }}
                              error={getIn(errors, 'address.area') && getIn(touched, 'address.area') ? getIn(errors, 'address.area') : null}
                            />
                          </Col>
                          <Col lg={6} md={6}>
                            <TextInput
                              isRequired
                              disabled
                              label={t('editTenantForm.pincode')}
                              placeholder={t('editTenantForm.pincode')}
                              name="address.pincode"
                              value={_.get(values, 'address.pincode')}
                              onChange={handleChange}
                              error={getIn(errors, 'address.pincode') && getIn(touched, 'address.pincode') ? getIn(errors, 'address.pincode') : null}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>

                    <div>
                      <div>
                        <SessionHeading text={t('editTenantForm.invoiceSetting')} />
                        <div className="tenant-form--block">
                          <Row>
                            <Col lg={6} md={6}>
                              <TextInput
                                type="file"
                                label={t('editTenantForm.logo')}
                                name="invoice_setting.logo"
                                accept="image/*"
                                onChange={(e) => handleImage(e.currentTarget.files[0], 'invoice_setting.logo', setFieldValue)}
                                image={_.get(values, 'invoice_setting.logo')}
                              />
                            </Col>
                            <Col lg={6} md={6}>
                              <TextInput
                                label={t('editTenantForm.nameLabel')}
                                placeholder={t('editTenantForm.nameLabel')}
                                name="invoice_setting.name"
                                value={_.get(values, 'invoice_setting.name')}
                                onChange={handleChange}
                              />
                            </Col>
                            <Col lg={6} md={6}>
                              <TextInput
                                label={t('editTenantForm.emailAddress')}
                                placeholder={t('editTenantForm.emailAddress')}
                                name="invoice_setting.email"
                                value={_.get(values, 'invoice_setting.email')}
                                onChange={handleChange}
                              />
                            </Col>
                            <Col lg={6} md={6}>
                              <PhoneNumberInput
                                label={t('editTenantForm.phoneNumber')}
                                name="invoice_setting.phone"
                                value={_.get(values, 'invoice_setting.phone')}
                                onChange={(val) => {
                                  setFieldValue(`invoice_setting.phone`, val);
                                  if (!_.isEmpty(phoneError)) {
                                    setPhoneError('');
                                  }
                                }}
                              />
                            </Col>
                            <Col lg={6} md={12}>
                              <TextInput
                                label={t('editTenantForm.addressLabel')}
                                name="address"
                                value={_.get(values, 'invoice_setting.address')}
                                onChange={(e) => setFieldValue('invoice_setting.address', e.target.value)}
                              />
                            </Col>
                            <Col lg={6} md={12}>
                              <TextInput
                                label={t('editTenantForm.website')}
                                placeholder={t('editTenantForm.website')}
                                value={_.get(values, 'invoice_setting.website')}
                                onChange={(e) => setFieldValue('invoice_setting.website', e.target.value)}
                              />
                            </Col>
                            <Col lg={6} md={12}>
                              <TextInput
                                label={t('editTenantForm.tollFree')}
                                placeholder={t('editTenantForm.tollFree')}
                                name="invoice_setting.toll_free"
                                value={_.get(values, 'invoice_setting.toll_free')}
                                onChange={(e) => setFieldValue('invoice_setting.toll_free', e.target.value)}
                              />
                            </Col>
                            <Col lg={'auto'}>
                              <div className="generateEInvoice-field-main--block">
                                <div className="generateEInvoice-checkbox-input__block">
                                  <label htmlFor="generateEInvoice" className="generateEInvoice--label">
                                    {t('editTenantForm.generateEInvoice')}
                                  </label>
                                  <CheckBox
                                    name="invoice_setting.generateEInvoice"
                                    checked={_.get(values, 'invoice_setting.generateEInvoice')}
                                    onChange={(e) => setFieldValue('invoice_setting.generateEInvoice', e.target.checked)}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="add-cancel-btn-block">
                        <Button type="submit" className="add--btn" disabled={isSubmitting}>
                          {t('button.saveUpdate')}
                        </Button>
                        <span className="cancel-btn---block">
                          <Button className="cancel---btn" onClick={() => navigateTo(`/tenantAdmin`)}>
                            {t('button.cancel')}
                          </Button>
                        </span>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
              <div>
                <Formik
                  enableReinitialize={!!tenantId}
                  initialValues={contactInformationInitialValues}
                  validationSchema={EditContactInformationSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    if (!tenantId && values.contact_person.phone && !isValidPhoneNumber(values.contact_person.phone)) {
                      setPhoneError('Phone number is not valid!');
                    } else {
                      // const countryCode = _.get(parsePhoneNumber(values.contact_person.phone), 'countryCallingCode', '');
                      // const phoneNumber = _.get(parsePhoneNumber(values.contact_person.phone), 'nationalNumber', '');
                      const contactInformationData = {
                        ...values.contact_person,
                      };
                      updateContactInformation({ ..._.omit(contactInformationData, ['phone']), id: _.get(tenantDetail, 'contact_person.id', '') });
                    }
                    setSubmitting(false);
                  }}
                >
                  {({ values, errors, touched, handleSubmit, handleChange, setFieldValue, isSubmitting }) => (
                    <Form onSubmit={handleSubmit}>
                      <div>
                        <SessionHeading text={t('editTenantForm.contactInfo')} />
                        <div className="tenant-form--block">
                          <Row>
                            <Col lg={6} md={6}>
                              <TextInput
                                isRequired
                                label={t('editTenantForm.tenantAdmin')}
                                placeholder={t('editTenantForm.tenantAdmin')}
                                name="contact_person.name"
                                value={_.get(values, 'contact_person.name')}
                                onChange={handleChange}
                                error={
                                  getIn(errors, 'contact_person.name') && getIn(touched, 'contact_person.name')
                                    ? getIn(errors, 'contact_person.name')
                                    : null
                                }
                              />
                            </Col>
                            <Col lg={6} md={6}>
                              <TextInput
                                isRequired
                                label={t('editTenantForm.emailAddress')}
                                placeholder={t('editTenantForm.emailAddress')}
                                name="contact_person.email"
                                value={_.get(values, 'contact_person.email')}
                                onChange={handleChange}
                                error={
                                  getIn(errors, 'contact_person.email') && getIn(touched, 'contact_person.email')
                                    ? getIn(errors, 'contact_person.email')
                                    : null
                                }
                              />
                            </Col>
                            <Col lg={6} md={6}>
                              <PhoneNumberInput
                                disabled={true}
                                label={t('editTenantForm.phoneNumber')}
                                name="contact_person.phone"
                                value={_.get(values, 'contact_person.phone')}
                                onChange={(val) => {
                                  setFieldValue(`contact_person.phone`, val);
                                  if (!_.isEmpty(phoneError)) {
                                    setPhoneError('');
                                  }
                                }}
                                // error={
                                //   getIn(errors, 'contact_person.phone') && getIn(touched, 'contact_person.phone')
                                //     ? getIn(errors, 'contact_person.phone')
                                //     : phoneError
                                //     ? phoneError
                                //     : null
                                // }
                              />
                            </Col>
                            <Col xl={6} lg={12}>
                              <DatePickerInput
                                label={t('editTenantForm.dobLabel')}
                                value={_.get(values, 'contact_person.dob')}
                                onChange={(dateString) => setFieldValue('contact_person.dob', dateString)}
                                error={
                                  getIn(errors, 'contact_person.dob') && getIn(touched, 'contact_person.dob')
                                    ? getIn(errors, 'contact_person.dob')
                                    : null
                                }
                              />
                            </Col>
                            <Col lg={6} md={12}>
                              <TextInput
                                isRequired
                                label={t('editTenantForm.addressLabel')}
                                name="address"
                                value={_.get(values, 'contact_person.address')}
                                onChange={(e) => setFieldValue('contact_person.address', e.target.value)}
                                error={
                                  getIn(errors, 'contact_person.address') && getIn(touched, 'contact_person.address')
                                    ? getIn(errors, 'contact_person.address')
                                    : null
                                }
                              />
                            </Col>
                            <Col xl={6} lg={12}>
                              <div className="gender-field__block">
                                <RadioButton
                                  containerClassName="radio-field"
                                  checked={_.get(values, 'contact_person.gender') === 'male'}
                                  onChange={() => setFieldValue('contact_person.gender', 'male')}
                                >
                                  {t('editTenantForm.male')}
                                </RadioButton>
                                <RadioButton
                                  containerClassName="radio-field"
                                  checked={_.get(values, 'contact_person.gender') === 'female'}
                                  onChange={() => setFieldValue('contact_person.gender', 'female')}
                                >
                                  {t('editTenantForm.female')}
                                </RadioButton>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <div className="add-cancel-btn-block">
                        <Button type="submit" className="add--btn" disabled={isSubmitting}>
                          {t('button.updateContact')}
                        </Button>
                        <span className="cancel-btn---block">
                          <Button className="cancel---btn" onClick={() => navigateTo(`/tenantAdmin`)}>
                            {t('button.cancel')}
                          </Button>
                        </span>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Card>
          </div>
        </div>
      </LayoutContainer>
    </React.Fragment>
  );
};

export default TenantForm;
