import React, { useCallback, useEffect, useState } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header/index';
import Card from 'components/inputs/Card';
import * as _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { BiSort } from 'react-icons/bi';
import { FETCH_RFID_FOR_FLEET } from 'actions/rfid';
import moment from 'moment';
import ReactPagination from 'components/general/ReactPagination';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Footer from 'components/general/Footer';

const FleetRfidList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [pageWiseRfidData, setPageWiseRfidData] = useState([]);

  const isLoader = useSelector((state) => state.rfidGroup.isLoading);
  const allRfid = useSelector((state) => state.rfid.fleetRfids);
  const page = useSelector((state) => state.rfid.page);
  const totalData = useSelector((state) => state.rfid.total);
  const limit = useSelector((state) => state.rfid.limit);
  const totalPages = useSelector((state) => state.rfid.totalPages);
  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserName = _.get(profileData, 'name', '');
  const currentUserId = _.get(profileData, 'id', '');

  //Functionality of showing data pagination wise
  const rfidGroupData = allRfid.filter(({ id: id1 }) => pageWiseRfidData.some(({ id: id2 }) => id2 === id1));

  const getRfid = useCallback(
    (data = {}) => {
      dispatch({
        type: FETCH_RFID_FOR_FLEET,
        payload: { ...data, id: currentUserId },
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              setPageWiseRfidData(res.data.results);
            }
          }
        },
      });
    },
    [pageWiseRfidData, currentUserId]
  );

  useEffect(() => {
    currentUserId && getRfid();
  }, [currentUserId]);

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else {
        const data = {
          page: page.selected + 1,
          // group: rfidGroupId,
        };
        getRfid(data);
      }
    },
    [sortByItem /*, rfidGroupId*/]
  );

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getRfid(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getRfid(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  return (
    <React.Fragment>
      <LayoutContainer>
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
          <AdminHeader title={t('header.rfidList')} />
          <div className="page-content-wrapper scrollable">
            <div className="rfid-page-main">
              <Card>
                <div className="rfidList-table">
                  <div className="table-responsive">
                    <table className="record-list-table">
                      <thead>
                        <tr>
                          <th>
                            <div className="sorting">
                              <span>{isLoader ? <Skeleton height={15} width={100} /> : t('rfidEditList.srNo')}</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>{isLoader ? <Skeleton height={15} width={100} /> : t('rfidEditList.rfidCardNo')}</span>
                              <span className="ico" onClick={() => handleSorting('card_number', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>{isLoader ? <Skeleton height={15} width={100} /> : t('rfidEditList.rfidTag')}</span>
                              <span className="ico" onClick={() => handleSorting('code', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>{isLoader ? <Skeleton height={15} width={100} /> : t('rfidEditList.dateOfIssuance')}</span>
                              <span className="ico" onClick={() => handleSorting('date_of_issuance', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>{isLoader ? <Skeleton height={15} width={100} /> : t('rfidEditList.expiryDate')}</span>
                              <span className="ico" onClick={() => handleSorting('expiry_date', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th className="action-col">
                            <span>{isLoader ? <Skeleton height={15} width={100} /> : t('rfidEditList.user')}</span>
                          </th>
                          <th className="action-col">
                            <span>{isLoader ? <Skeleton height={15} width={100} /> : t('rfidEditList.vehicle')}</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoader ? (
                          <tr>
                            <td colSpan={10} className="border-0">
                              <div className="loader--block">
                                <Skeleton height={15} width={100} />
                              </div>
                            </td>
                          </tr>
                        ) : _.isEmpty(rfidGroupData) ? (
                          <tr>
                            <td colSpan={6} className="border-0">
                              <div className="empty-data-block">{t('rfidEditList.noData')}</div>
                            </td>
                          </tr>
                        ) : (
                          !(isLoader || _.isUndefined(isLoader)) &&
                          _.map(rfidGroupData, (item, key) => {
                            const serial_num = limit * (page - 1) + key;

                            let assignToVehicle;
                            if (item?.vehicle) {
                              assignToVehicle = `${_.get(item, 'vehicle.make', '')} (${_.get(item, 'vehicle.model', '')})`;
                            } else {
                              assignToVehicle = '-';
                            }

                            let assignToUser;
                            if (item?.user) {
                              assignToUser = _.get(item, 'user.name', '');
                            } else {
                              assignToUser = '-';
                            }

                            return (
                              <tr key={`rfid-${serial_num}`}>
                                <td>{isLoader ? <Skeleton height={15} width={100} /> : serial_num + 1}.</td>
                                <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'card_number', '')}</td>
                                <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'code', '')}</td>
                                <td>
                                  {isLoader ? (
                                    <Skeleton height={15} width={100} />
                                  ) : item.date_of_issuance ? (
                                    moment(_.get(item, 'date_of_issuance')).format('DD MMM YYYY')
                                  ) : (
                                    '-'
                                  )}
                                </td>
                                <td>
                                  {isLoader ? (
                                    <Skeleton height={15} width={100} />
                                  ) : item.expiry_date ? (
                                    moment(_.get(item, 'expiry_date')).format('DD MMM YYYY')
                                  ) : (
                                    '-'
                                  )}
                                </td>
                                <td>{isLoader ? <Skeleton height={15} width={100} /> : assignToUser}</td>
                                <td>{isLoader ? <Skeleton height={15} width={100} /> : assignToVehicle}</td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(rfidGroupData) && (
                  <ReactPagination
                    currentPage={page}
                    limit={limit}
                    total={totalData}
                    handlePageClick={(pageVal) => handlePageClick(pageVal)}
                    totalPages={totalPages}
                    marginPagesDisplayed={1}
                  />
                )}
              </Card>
            </div>
          </div>
        </SkeletonTheme>
        {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
      </LayoutContainer>
    </React.Fragment>
  );
};
export default FleetRfidList;
