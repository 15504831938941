import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import { UPLOAD_NO_LOAD_REPORT, UPLOAD_NO_LOAD_REPORT_SUCCESS, UPLOAD_NO_LOAD_REPORT_FAIL, FETCH_NO_LOAD_REPORT, FETCH_NO_LOAD_REPORT_SUCCESS, FETCH_NO_LOAD_REPORT_FAIL, DOWNLOAD_NO_LOAD_REPORT, DOWNLOAD_NO_LOAD_REPORT_SUCCESS, DOWNLOAD_NO_LOAD_REPORT_FAIL } from 'actions/noLoadReport';

function* uploadNoLoadReport(action) {
    try {
      const response = yield call(API.uploadNoLoadReport, action.payload);
      yield put({ type: UPLOAD_NO_LOAD_REPORT_SUCCESS, payload: response.data });
      action.cb && action.cb(response);
    } catch (e) {
      yield put({ type: UPLOAD_NO_LOAD_REPORT_FAIL, payload: { error: e } });
      action.cb && action.cb(e);
    }
  }
  
function* fetchNoLoadReport(action) {
    try {
      const response = yield call(API.fetchNoLoadReport, action.payload);
      yield put({ type: FETCH_NO_LOAD_REPORT_SUCCESS, payload: response.data });
      action.cb && action.cb(response);
    } catch (e) {
      yield put({ type: FETCH_NO_LOAD_REPORT_FAIL, payload: { error: e } });
    }
  }
  
function* downloadNoLoadReport(action) {
    try {
      const response = yield call(API.downloadNoLoadReport, action.payload);
      yield put({ type: DOWNLOAD_NO_LOAD_REPORT_SUCCESS, payload: response.data });
      action.cb && action.cb(response);
    } catch (e) {
      yield put({ type: DOWNLOAD_NO_LOAD_REPORT_FAIL, payload: { error: e } });
      action.cb && action.cb(e);
    }
  }

function* noLoadReport() {
    yield takeLatest(UPLOAD_NO_LOAD_REPORT, uploadNoLoadReport);
    yield takeLatest(FETCH_NO_LOAD_REPORT, fetchNoLoadReport);
    yield takeLatest(DOWNLOAD_NO_LOAD_REPORT, downloadNoLoadReport);
  }
  
  export default noLoadReport;