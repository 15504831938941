import {
  FETCH_CUSTOMER_BOOKING,
  FETCH_CUSTOMER_BOOKING_SUCCESS,
  FETCH_CUSTOMER_BOOKING_FAIL,
  CREATE_CUSTOMER_BOOKING,
  CREATE_CUSTOMER_BOOKING_SUCCESS,
  CREATE_CUSTOMER_BOOKING_FAIL,
  RESCHEDULE_CUSTOMER_BOOKING,
  RESCHEDULE_CUSTOMER_BOOKING_SUCCESS,
  RESCHEDULE_CUSTOMER_BOOKING_FAIL,
} from 'actions/customer';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  customerBooking: [],
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};
const customerBookingReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_CUSTOMER_BOOKING:
    case CREATE_CUSTOMER_BOOKING:
    case RESCHEDULE_CUSTOMER_BOOKING: {
      return { ...state, isLoading: true };
    }

    case CREATE_CUSTOMER_BOOKING_SUCCESS: {
      toast.success('Booking Created Successfully');
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      return {
        ...state,
        isLoading: false,
        customerBooking: _.concat(state.customerBooking, payload),
      };
    }

    case FETCH_CUSTOMER_BOOKING_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        customerBooking: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case RESCHEDULE_CUSTOMER_BOOKING_SUCCESS: {
      toast.success('Booking Rescheduled');
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      return {
        ...state,
        customerBooking: state.customerBooking.map((data) => {
          if (data.id === payload.id) {
            return payload;
          } else {
            return data;
          }
        }),
      };
    }

    case FETCH_CUSTOMER_BOOKING_FAIL:
    case CREATE_CUSTOMER_BOOKING_FAIL:
    case RESCHEDULE_CUSTOMER_BOOKING_FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return { ...state };
  }
};
export default customerBookingReducer;
