import React, { useState, useEffect, useCallback } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header';
import Card from 'components/inputs/Card';
import DateTimePicker from 'components/inputs/DateTimePicker';
import { GrDocumentPdf } from "react-icons/gr";
import { SiMicrosoftexcel } from 'react-icons/si';
import { Col, Row, Spinner } from 'react-bootstrap';
import DropDown from 'components/inputs/DropDown';
import { AiFillCaretDown } from 'react-icons/ai';
import moment from 'moment';
import * as _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { DOWNLOAD_JOB_VOUCHER, DOWNLOAD_GST_VOUCHER } from 'actions/dataReport';
import fileDownload from 'js-file-download';
import { GET_SMC_PAYMENT_GATEWAY } from 'actions/smcGateway';
import { useTranslation } from 'react-i18next';
import Footer from 'components/general/Footer';

const JobVoucher = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(moment(new Date()).startOf('day').utc());
  const [endDate, setEndDate] = useState(moment(new Date()).endOf('day').utc());
  const [report, setReport] = useState('Select_Voucher');
  const [fieldValue, setFieldValue] = useState('Select Gateway');
  const [gateway, setGateway] = useState(null);
  const [pdfLoading, setPdfLoading] = useState(false);
  const [excelLoading, setExcelLoading] = useState(false);

  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserName = _.get(profileData, 'name', '');

  const filterData = [
    { label: 'INCOME VOUCHER', value: 'JV' },
    { label: 'BRV WALLET DEPOSIT', value: 'BRV' },
    { label: 'GST_REPORT', value: 'GST' },
  ];

  const initialValues = {
    from: moment(startDate).utc(),
    to: moment(endDate).utc(),
    report: report,
    gatewayId: gateway,
  };

  const downloadReport = () => {
    if (report !== 'GST') {
      downloadInvoiceReport();
      setPdfLoading(true);
    } else {
      downloadGSTReport();
      setPdfLoading(true);
    }
  };

  const getSMCGateway = useCallback((data) => {
    dispatch({ type: GET_SMC_PAYMENT_GATEWAY, payload: data });
  }, []);

  useEffect(() => {
    getSMCGateway();
  }, []);

  const downloadInvoiceReport = (data) => {
    const payload = {
      ...data,
      from: moment(startDate).utc(),
      to: moment(endDate).utc(),
      report: initialValues.report,
    };

    if (report === 'BRV') {
      if (fieldValue === 'ICICI BANK') {
        payload.bank_name = 'ICICI';
        payload.gatewayId = 2;
      } else if (fieldValue === 'BillDesk (Kotak Bank)') {
        payload.bank_name = 'Kotak';
        payload.gatewayId = 1;
      } else if (fieldValue === 'HDFC BANK') {
        payload.bank_name = 'HDFC';
      } else if (fieldValue === 'All Bank Detail') {
        payload.bank_name = 'All';
      }
    }

    dispatch({
      type: DOWNLOAD_JOB_VOUCHER,
      payload: payload,
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            fileDownload(res.data, `${'Invoice'}.${payload.excel ? 'xlsx' : 'pdf'}`);
            if (payload.excel) {
              setExcelLoading(false);
            } else {
              setPdfLoading(false);
            }
          }
        }
      },
    });
  };

  const downloadGSTReport = (data) => {
    const payload = {
      ...data,
      report: 'GST',
      from: moment(startDate).utc(),
      to: moment(endDate).utc(),
    };

    dispatch({
      type: DOWNLOAD_GST_VOUCHER,
      payload: payload,
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            fileDownload(res.data, `gst_report.${payload.type ? 'xlsx' : 'pdf'}`);
            if (payload.type) {
              setExcelLoading(false);
            } else {
              setPdfLoading(false);
            }
          }
        }
      },
    });
  };

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.jobVoucher')} />
        <div className="page-content-wrapper scrollable">
          <div className="data-report--main">
            <Card>
              <div className="data-report__inner">
                <div className="data-report__box">
                  <Row className="data-report__row">
                    <Col lg={'auto'}>
                      <div className="data-report-date_picker">
                        <DateTimePicker
                          onChangeOfStartDate={(item) => setStartDate(item)}
                          onChangeOfEndDate={(item) => setEndDate(item)}
                          initialValueOfStartDate={moment(new Date()).startOf('day')}
                          initialValueOfEndDate={moment(new Date()).endOf('day')}
                          closeOnSelect={true}
                        />
                      </div>
                    </Col>
                    <Col lg={'auto'}>
                      <DropDown
                        popupClass="main-menu bottom scrollable"
                        trigger={
                          <>
                            <div className="job-voucher-filter-block">
                              <div className="job-voucher-filter-sec">
                                <span className="job-voucher-filter-label">{_.startCase(initialValues.report)}</span>
                              </div>
                              <div className="job-voucher-arrow__icon">
                                <AiFillCaretDown />
                              </div>
                            </div>
                          </>
                        }
                        triggerClass="customerBooking-filter-dropdown"
                      >
                        {_.map(filterData, (item, index) => (
                          <ul key={`${index}`}>
                            <li
                              className="item"
                              onClick={() => {
                                setReport(item.value);
                              }}
                            >
                              {_.startCase(item.label)}
                            </li>
                          </ul>
                        ))}
                      </DropDown>
                    </Col>
                    {report === 'BRV' && (
                      <Col lg={'auto'}>
                        <DropDown
                          popupClass="main-menu bottom scrollable"
                          trigger={
                            <>
                              <div className="job-voucher-filter-block">
                                <div className="job-voucher-filter-sec">
                                  <span className="job-voucher-filter-label">{fieldValue}</span>
                                </div>
                                <div className="job-voucher-arrow__icon">
                                  <AiFillCaretDown />
                                </div>
                              </div>
                            </>
                          }
                          triggerClass="customerBooking-filter-dropdown"
                        >
                          {[
                            { name: 'BillDesk (Kotak Bank)', id: 1 },
                            { name: 'ICICI BANK', id: 2 },
                            { name: 'HDFC BANK', id: 3 },
                            { name: 'All Bank Detail', id: null },
                          ].map((bank, index) => (
                            <ul key={`${index}`}>
                              <li
                                className="item"
                                onClick={() => {
                                  setFieldValue(bank.name);
                                  setGateway(bank.id); // Remove this line if 'All Bank Detail' shouldn't have gatewayId
                                }}
                              >
                                {bank.name}
                              </li>
                            </ul>
                          ))}
                        </DropDown>
                      </Col>
                    )}
                    {/* PDF Download Button */}
                    <Col lg={'auto'} className="excel-icon--block">
                      {pdfLoading ? (
                        <div className="loader--block">
                          <Spinner as="span" animation="border" size="sm" role="status" />
                        </div>
                      ) : (
                        <div className="report-excel-icon">
                          <GrDocumentPdf size={28} color={'#3c7cdd'} onClick={downloadReport} />
                        </div>
                      )}
                    </Col>
                    {/* Excel Download Button */}
                    <Col lg={'auto'} className="excel-icon--block">
                      {excelLoading ? (
                        <div className="loader--block">
                          <Spinner as="span" animation="border" size="sm" role="status" />
                        </div>
                      ) : (
                        <div className="report-excel-icon">
                          <SiMicrosoftexcel
                            size={28}
                            color={'#5dad5d'}
                            onClick={() => {
                              if (report !== 'GST') {
                                downloadInvoiceReport({ excel: true });
                                setExcelLoading(true);
                              } else {
                                downloadGSTReport({ type: 'excel' });
                                setExcelLoading(true);
                              }
                            }}
                          />
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
            </Card>
          </div>
          {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
        </div>
      </LayoutContainer>
    </>
  );
};

export default JobVoucher;
