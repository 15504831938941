export const RATING_REVIEW = 'RATING_REVIEW';
export const RATING_REVIEW_SUCCESS = 'RATING_REVIEW_SUCCESS';
export const RATING_REVIEW_FAIL = 'RATING_REVIEW_FAIL';

export const DOWNLOAD_RATING_REVIEW = 'DOWNLOAD_RATING_REVIEW';
export const DOWNLOAD_RATING_REVIEW_SUCCESS = 'DOWNLOAD_RATING_REVIEW_SUCCESS';
export const DOWNLOAD_RATING_REVIEW_FAIL = 'DOWNLOAD_RATING_REVIEW_FAIL';

export const RATING_REVIEW_SUMMARY = 'RATING_REVIEW_SUMMARY';
export const RATING_REVIEW_SUMMARY_SUCCESS = 'RATING_REVIEW_SUMMARY_SUCCESS';
export const RATING_REVIEW_SUMMARY_FAIL = 'RATING_REVIEW_SUMMARY_FAIL';

export const DOWNLOAD_RATING_REVIEW_SUMMARY = 'DOWNLOAD_RATING_REVIEW_SUMMARY';
export const DOWNLOAD_RATING_REVIEW_SUMMARY_SUCCESS = 'DOWNLOAD_RATING_REVIEW_SUMMARY_SUCCESS';
export const DOWNLOAD_RATING_REVIEW_SUMMARY_FAIL = 'DOWNLOAD_RATING_REVIEW_SUMMARY_FAIL';
