import React from 'react';
import ReactDOM from 'react-dom';
// import { createRoot } from 'react-dom/client';
import './assets/scss/main.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Store from 'utils/store';
import './i18n';
import 'react-loading-skeleton/dist/skeleton.css';

// const container = document.getElementById('root');
// const root = createRoot(container); // createRoot(container!) if you use TypeScript
// root.render(
//   <React.StrictMode>
//     <Store>
//       <App />
//     </Store>
//   </React.StrictMode>
// );
// /*
ReactDOM.render(
  <React.StrictMode>
    <Store>
      <App />
    </Store>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
