import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Card from 'components/inputs/Card';
import { MdClose } from 'react-icons/md';
import { Row, Col } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import _ from 'lodash';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'components/inputs/Select';
import { CREATE_OEM_ERROR_CODE, UPDATE_OEM_ERROR_CODE } from 'actions/oemErrorCode';
import { FETCH_OEM_VENDOR } from 'actions/oemVendor';
import { FETCH_OCPP_ERROR } from 'actions/ocppErrorCode';
import { CREATE_STATUS_CODE, UPDATE_STATUS_CODE } from 'components/common/constant';
import { FETCH_CHARGER_STATE } from 'actions/chargerState';
import { useTranslation } from 'react-i18next';
import { FETCH_OEM } from 'actions/oem';

const OemErrorCodeForm = (props) => {
  const { isEdit, onClose = _.noop(), onSuccess = _.noop() } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const oemErrorCodeDetail = useSelector((state) => state.oemErrorCode.oemErrorCodeDetail);
  const allOemVendor = useSelector((state) => state.oemVendor.oemVendors);

  const allOcppErrorCodes = useSelector((state) => state.ocppErrorCodes.ocppErrorList);
  const allChargerState = useSelector((state) => state.chargerState.chargerStates);
  const isLoading = useSelector((state) => state.oemErrorCode.isLoading);
  const statePage = useSelector((state) => state.chargerState.page);
  const stateTotalPages = useSelector((state) => state.chargerState.totalPages);
  const oemVendorPage = useSelector((state) => state.oemVendor.page);
  const oemVendorTotalPages = useSelector((state) => state.oemVendor.totalPages);
  const allOems = useSelector((state) => state.oem.oems);
  const oemTotalPages = useSelector((state) => state.oem.totalPages);
  const oemPage = useSelector((state) => state.oem.page);

  const addOemErrorCode = useCallback((data) => {
    dispatch({
      type: CREATE_OEM_ERROR_CODE,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === CREATE_STATUS_CODE) {
          onSuccess();
          onClose();
        }
      },
    });
  }, []);

  const updateOemErrorCode = useCallback((data) => {
    dispatch({
      type: UPDATE_OEM_ERROR_CODE,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          onSuccess();
          onClose();
        }
      },
    });
  }, []);

  const getChargeSpotByOem = useCallback((id) => {
    dispatch({ type: FETCH_OEM, payload: id });
  }, []);

  const getOcppError = useCallback((id) => {
    dispatch({ type: FETCH_OCPP_ERROR, payload: id });
  }, []);

  const getAllOemVendor = useCallback((data) => {
    dispatch({ type: FETCH_OEM_VENDOR, payload: data });
  }, []);

  const getAllOcppErrors = useCallback((data) => {
    dispatch({ type: FETCH_OCPP_ERROR, payload: data });
  }, []);

  const getAllChargerState = useCallback((data) => {
    dispatch({ type: FETCH_CHARGER_STATE, payload: data });
  }, []);

  useEffect(() => {
    getAllOemVendor({ limit: 999 });
    getAllOcppErrors({ limit: 999 });
    getAllChargerState({ limit: 999 });
  }, []);

  useEffect(() => {
    if (isEdit && !_.isEmpty(oemErrorCodeDetail) && !_.isEmpty(allOemVendor)) {
      const selectedOem = _.find(allOemVendor, { name: _.get(oemErrorCodeDetail, 'vendor.name', '') });
      if (selectedOem && !_.isEmpty(selectedOem) && _.has(selectedOem, 'id')) {
        getChargeSpotByOem({ vendor: selectedOem.id });
      }
    }
  }, [isEdit, oemErrorCodeDetail, allOemVendor]);

  const OemErrorCodeSchema = Yup.object().shape({
    code: Yup.string().required(t('oemErrorCodeForm.code')).strict(true).trim(t('oemErrorCodeForm.space')),
    // .matches(/^[A-Za-z0-9\s]+$/, t('oemErrorCodeForm.matchNumAlpha')),
    vendor: Yup.string().required(t('oemErrorCodeForm.vendor')).max(100, t('oemErrorCodeForm.maxlimit')),
    state: Yup.string().required(t('oemErrorCodeForm.state')),
    // errorCode: Yup.string().required(t('oemErrorCodeForm.errorCode')),
    oem: Yup.string().required(t('oemErrorCodeForm.oem')),
  });

  const initialValues = isEdit
    ? {
        ...oemErrorCodeDetail,
        errorCode: _.get(oemErrorCodeDetail, 'errorCode', ''),
        vendor: _.get(oemErrorCodeDetail, 'vendor.id', ''),
        state: _.get(oemErrorCodeDetail, 'state.id', ''),
      }
    : {
        code: '',
        vendor: '',
        state: '',
        errorCode: '',
        oem: '',
      };
  return (
    <React.Fragment>
      <div className="oemErrorCode-form-page__main">
        <Card>
          <div className="oemErrorCode-form-header__block">
            <div className="oemErrorCode-header-name">
              <span>
                {isEdit ? 'Edit' : 'Add'} {t('oemErrorCodeForm.title')}
              </span>
            </div>
            <div className="close___btn">
              <MdClose size={30} color="#be210b" onClick={onClose} />
            </div>
          </div>
          <div className="oemErrorCode-form-body__block">
            <div className="oemErrorCode-form--block">
              <Formik
                enableReinitialize={!!isEdit}
                initialValues={initialValues}
                validationSchema={OemErrorCodeSchema}
                onSubmit={(values, { setSubmitting }) => {
                  if (isEdit) {
                    updateOemErrorCode(values);
                  } else {
                    addOemErrorCode(values);
                  }
                  setSubmitting(false);
                }}
              >
                {({ values, errors, touched, handleSubmit, handleChange, setFieldValue, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col md={12}>
                        <TextInput
                          isRequired
                          label={t('oemErrorCodeForm.vendorCode')}
                          placeholder={t('oemErrorCodeForm.vendorCode')}
                          name="code"
                          value={_.get(values, 'code', '')}
                          onChange={handleChange}
                          error={errors.code && touched.code ? errors.code : null}
                        />
                      </Col>

                      <Col md={12}>
                        <Select
                          isRequired
                          label={t('oemErrorCodeForm.vendorCode')}
                          options={_.map(allOcppErrorCodes, (item) => {
                            return { label: item.errorCode, value: item.errorCode };
                          })}
                          placeholder={t('placeHolder.selectOcpp')}
                          name="errorCode"
                          onMenuScrollDown={true}
                          getDataOnScrollDown={getAllOcppErrors}
                          value={_.get(values, 'errorCode', '')}
                          onChange={(val) => {
                            setFieldValue('errorCode', val);
                            getOcppError(val);
                          }}
                          error={errors.ocppErrorCode && touched.ocppErrorCode ? errors.ocppErrorCode : null}
                        />
                      </Col>

                      <Col md={12}>
                        <Select
                          isRequired
                          label={t('oemErrorCodeForm.vendorName')}
                          options={_.map(allOemVendor, (item) => {
                            return { label: item.name, value: item.id };
                          })}
                          placeholder={t('placeHolder.selectVendor')}
                          name="vendor"
                          onMenuScrollDown={true}
                          getDataOnScrollDown={getAllOemVendor}
                          page={oemVendorPage}
                          totalPage={oemVendorTotalPages}
                          value={_.get(values, 'vendor', '')}
                          onChange={(val) => {
                            setFieldValue(`vendor`, val);
                            getChargeSpotByOem({ vendor: val });
                            setFieldValue(`oem`, '');
                          }}
                          error={errors.vendor && touched.vendor ? errors.vendor : null}
                        />
                      </Col>

                      <Col md={12}>
                        <Select
                          isRequired
                          label={t('oemErrorCodeForm.chargerSpotName')}
                          options={_.map(allOems, (item) => {
                            return { label: item.name, value: item.id };
                          })}
                          placeholder={t('placeHolder.selectModelName')}
                          name="oem"
                          onMenuScrollDown={true}
                          getDataOnScrollDown={(data) => getChargeSpotByOem({ ...data, vendor: values.vendor })}
                          page={oemPage}
                          totalPage={oemTotalPages}
                          value={_.get(values, 'oem', '')}
                          onChange={(val) => {
                            setFieldValue(`oem`, val);
                          }}
                          error={errors.oem && touched.oem ? errors.oem : null}
                        />
                      </Col>
                      <Col md={12}>
                        <Select
                          isRequired
                          label={t('oemErrorCodeForm.chargerStateName')}
                          options={_.map(allChargerState, (item) => {
                            return { label: item.name, value: item.id };
                          })}
                          placeholder={t('placeHolder.selectChargerState')}
                          name="state"
                          onMenuScrollDown={true}
                          getDataOnScrollDown={getAllChargerState}
                          page={statePage}
                          totalPage={stateTotalPages}
                          value={_.get(values, 'state')}
                          onChange={(val) => setFieldValue(`state`, val)}
                          error={errors.state && touched.state ? errors.state : null}
                        />
                      </Col>
                      <Col md={12}>
                        <TextInput
                          as="textarea"
                          rows="4"
                          inputClass="scrollable"
                          placeholder={t('oemErrorCodeForm.actions')}
                          label={t('oemErrorCodeForm.actions')}
                          name="actions"
                          value={_.get(values, 'actions')}
                          onChange={handleChange}
                          // error={errors.actions && touched.actions ? errors.actions : null}
                        />
                      </Col>
                      <Col md={12}>
                        <TextInput
                          as="textarea"
                          rows="4"
                          inputClass="scrollable"
                          placeholder={t('oemErrorCodeForm.reasons')}
                          label={t('oemErrorCodeForm.reasons')}
                          name="reasons"
                          value={_.get(values, 'reasons')}
                          onChange={handleChange}
                          // error={errors.reasons && touched.reasons ? errors.reasons : null}
                        />
                      </Col>
                    </Row>
                    <div className="form-btn__block">
                      <Button className="form_btn cancel____btn" onClick={onClose}>
                        {t('button.cancel')}
                      </Button>
                      <Button type="submit" disabled={isSubmitting || isLoading} className="form_btn">
                        {isEdit ? 'Update' : 'Add'}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};
OemErrorCodeForm.propTypes = {
  onSuccess: PropTypes.func,
  onClose: PropTypes.func,
  isEdit: PropTypes.bool,
};
export default OemErrorCodeForm;
