import React, { useEffect, useRef } from "react";
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import PropTypes from 'prop-types';
import { ObjectId } from 'bson';
import { BASE_URL, CHART_IDS } from './chartConstants';

const TotalChargeSpots = ({ rolePermission, currentTenantId, currentStation }) => {
  const sdk = new ChartsEmbedSDK({
    baseUrl: BASE_URL,
  });
  const chartDiv = useRef(null);

  TotalChargeSpots.propTypes = {
    rolePermission: PropTypes.object.isRequired,
    currentTenantId: PropTypes.string.isRequired,
    currentStation: PropTypes.array.isRequired
  };

  const filter = {
    "tenant": { "$eq": ObjectId(currentTenantId) }
  };

  if (currentStation && currentStation.length > 0) {
    filter["charging_station"] = { "$in": currentStation.map(id => ObjectId(id)) };
  }

  console.log("from sessiontoday component : ", currentStation);

  const chart = sdk.createChart({
    chartId: CHART_IDS.TOTAL_CHARGESPOTS,
    height: 58,
    // width: 445,
    showAttribution: false,
    padding: 0,
    theme: "dark",
    background: "$primary-bg-color",
    rolePermissions: {
      getWidgetChargersInUse: rolePermission // set the role permission for the 'getWidgetChargerStatus' action
    },
    filter,
    autoRefresh: false,
    maxDataAge: 300,
  });

  useEffect(() => {
    async function renderChart() {
      try {
        await chart.render(chartDiv.current);

      } catch (err) {
        console.log("Error during Charts rendering.", err);
      }
    }

    renderChart();
  }, []);

  return <div ref={chartDiv} style={{ height: "40px", overflowY: "hidden", transform: "translateX(-25px)" }}></div>;
};

export default TotalChargeSpots; 
