import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import { GET_SUBSCRIPTION, GET_SUBSCRIPTION_SUCCESS, GET_SUBSCRIPTION_FAIL } from 'actions/subscription';

function* getSubscription(action) {
  try {
    const response = yield call(API.getSubscription, action.payload);
    yield put({ type: GET_SUBSCRIPTION_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_SUBSCRIPTION_FAIL, payload: { error: e } });
  }
}

function* subscription() {
  yield takeLatest(GET_SUBSCRIPTION, getSubscription);
}
export default subscription;
