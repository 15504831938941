import {
  CREATE_USER_GROUP,
  CREATE_USER_GROUP_SUCCESS,
  CREATE_USER_GROUP_FAIL,
  FETCH_USER_GROUP,
  FETCH_USER_GROUP_SUCCESS,
  FETCH_USER_GROUP_FAIL,
  GET_USER_GROUP,
  GET_USER_GROUP_SUCCESS,
  GET_USER_GROUP_FAIL,
  DELETE_USER_GROUP,
  DELETE_USER_GROUP_SUCCESS,
  DELETE_USER_GROUP_FAIL,
  UPDATE_USER_GROUP,
  UPDATE_USER_GROUP_FAIL,
  UPDATE_USER_GROUP_SUCCESS,
} from 'actions/userGroup';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  userGroups: [],
  userGroupDetail: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const userGroupReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_USER_GROUP:
    case CREATE_USER_GROUP:
    case GET_USER_GROUP:
    case DELETE_USER_GROUP: {
      return { ...state, isLoading: true, userGroupDetail: {} };
    }

    case UPDATE_USER_GROUP: {
      return { ...state, isLoading: true };
    }

    case FETCH_USER_GROUP_SUCCESS: {
      if (payload.page === 1) {
        return {
          ...state,
          isLoading: false,
          userGroups: payload.results,
          page: payload.page,
          limit: payload.limit,
          totalPages: payload.totalPages,
          total: payload.totalResults,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          userGroups: _.concat(state.userGroups, payload.results),
          page: payload.page,
          limit: payload.limit,
          totalPages: payload.totalPages,
          total: payload.totalResults,
        };
      }
    }

    case GET_USER_GROUP_SUCCESS: {
      return {
        ...state,
        userGroupDetail: payload,
        isLoading: false,
      };
    }

    case CREATE_USER_GROUP_SUCCESS: {
      toast.success(' User group added successfully');
      return {
        ...state,
        userGroups: _.concat(state.userGroups, payload),
        isLoading: false,
      };
    }

    case UPDATE_USER_GROUP_SUCCESS: {
      toast.success(' User group updated successfully');
      return {
        ...state,
        isLoading: false,
        userGroups: state.userGroups.map((data) => (data.id === payload.id ? payload : data)),
      };
    }

    case DELETE_USER_GROUP_SUCCESS: {
      toast.success(' User group deleted successfully');
      return {
        ...state,
        isLoading: false,
        userGroups: state.userGroups.filter((data) => data.id !== payload),
      };
    }

    case FETCH_USER_GROUP_FAIL:
    case CREATE_USER_GROUP_FAIL:
    case GET_USER_GROUP_FAIL:
    case DELETE_USER_GROUP_FAIL:
    case UPDATE_USER_GROUP_FAIL: {
      return { ...state, isLoading: false };
    }
    default:
      return { ...state };
  }
};
export default userGroupReducer;
