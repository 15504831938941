export const FETCH_TARIFF = 'FETCH_TARIFF';
export const FETCH_TARIFF_SUCCESS = 'FETCH_TARIFF_SUCCESS';
export const FETCH_TARIFF_FAIL = 'FETCH_TARIFF_FAIL';

export const CREATE_TARIFF = 'CREATE_TARIFF';
export const CREATE_TARIFF_SUCCESS = 'CREATE_TARIFF_SUCCESS';
export const CREATE_TARIFF_FAIL = 'CREATE_TARIFF_FAIL';

export const DELETE_TARIFF = 'DELETE_TARIFF';
export const DELETE_TARIFF_SUCCESS = 'DELETE_TARIFF_SUCCESS';
export const DELETE_TARIFF_FAIL = 'DELETE_TARIFF_FAIL';

export const UPDATE_TARIFF = 'UPDATE_TARIFF';
export const UPDATE_TARIFF_SUCCESS = 'UPDATE_TARIFF_SUCCESS';
export const UPDATE_TARIFF_FAIL = 'UPDATE_TARIFF_FAIL';
export const UPDATE_TARIFF_SLOT = 'UPDATE_TARIFF_SLOT';
export const GET_TARIFF = 'GET_TARIFF';
export const GET_TARIFF_SUCCESS = 'GET_TARIFF_SUCCESS';
export const GET_TARIFF_FAIL = 'GET_TARIFF_FAIL';
