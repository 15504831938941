import { PLANT_LOAD_FACTOR_REPORT, PLANT_LOAD_FACTOR_REPORT_SUCCESS, PLANT_LOAD_FACTOR_REPORT_FAIL } from 'actions/dataReport';

const initialState = {
  isLoading: false,
  plantLoadFactorReports: [],
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const plantLoadFactorReportReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case PLANT_LOAD_FACTOR_REPORT: {
      return { ...state, isLoading: true };
    }

    case PLANT_LOAD_FACTOR_REPORT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        plantLoadFactorReports: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case PLANT_LOAD_FACTOR_REPORT_FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};
export default plantLoadFactorReportReducer;
