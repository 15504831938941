import {
  CREATE_AMENITY,
  CREATE_AMENITY_FAIL,
  CREATE_AMENITY_SUCCESS,
  DELETE_AMENITY,
  DELETE_AMENITY_FAIL,
  DELETE_AMENITY_SUCCESS,
  FETCH_AMENITY,
  FETCH_AMENITY_FAIL,
  FETCH_AMENITY_SUCCESS,
  GET_AMENITY,
  GET_AMENITY_FAIL,
  GET_AMENITY_SUCCESS,
  UPDATE_AMENITY,
  UPDATE_AMENITY_FAIL,
  UPDATE_AMENITY_SUCCESS,
} from 'actions/amenity';
import { toast } from 'react-toastify';
import * as _ from 'lodash';

const initialState = {
  isLoading: false,
  amenities: [],
  amenityDetail: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const amenityReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_AMENITY:
    case GET_AMENITY:
    case CREATE_AMENITY:
    case DELETE_AMENITY: {
      return { ...state, isLoading: true, amenityDetail: {} };
    }

    case UPDATE_AMENITY: {
      return { ...state, isLoading: true };
    }

    case FETCH_AMENITY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        amenities: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case GET_AMENITY_SUCCESS: {
      return {
        ...state,
        amenityDetail: payload,
        isLoading: false,
      };
    }

    case CREATE_AMENITY_SUCCESS: {
      toast.success('Amenity added successfully');
      return {
        ...state,
        amenities: _.concat(state.amenities, payload),
        isLoading: false,
      };
    }

    case UPDATE_AMENITY_SUCCESS: {
      toast.success('Amenity updated successfully');
      return {
        ...state,
        amenities: state.amenities.map((data) => (data.id === payload.id ? payload : data)),
        isLoading: false,
      };
    }

    case DELETE_AMENITY_SUCCESS: {
      toast.success('Amenity deleted successfully');
      return {
        ...state,
        isLoading: false,
        amenities: state.amenities.filter((data) => data.id !== payload),
      };
    }

    case FETCH_AMENITY_FAIL:
    case GET_AMENITY_FAIL:
    case UPDATE_AMENITY_FAIL:
    case CREATE_AMENITY_FAIL:
    case DELETE_AMENITY_FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};
export default amenityReducer;
