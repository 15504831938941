import LayoutContainer from 'components/layout/LayoutContainer';
import React, { useState, useEffect, useCallback, useContext } from 'react';
import AdminHeader from 'components/header';
import { Col, Row, Modal } from 'react-bootstrap';
import Card from 'components/inputs/Card';
import { BsSearch } from 'react-icons/bs';
import Button from 'components/inputs/Button';
// import { useHistory } from 'react-router-dom';
import * as _ from 'lodash';
import { FETCH_OCPI_PARTNER_GROUP } from 'actions/ocpiPartnerGroup';
import { useSelector, useDispatch } from 'react-redux';
import SearchBox from 'components/general/SearchBox';
import { DELETE_OCPI_PARTNER_GROUP } from 'actions/ocpiPartnerGroup';
// import Pagination from 'components/general/Pagination';
import ReactPagination from 'components/general/ReactPagination';
import DeleteModal from 'components/general/DeleteModal';
import {MANAGE_USER_GROUPS, UPDATE_STATUS_CODE, METHOD_NOT_ALLOWED } from 'components/common/constant';
import { BiSort } from 'react-icons/bi';
import { FiEdit2, FiUser } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Footer from 'components/general/Footer';
import { NavContext } from 'components/privateRoute/PrivateRoute';
import { toast } from 'react-toastify';

const OCPIPartnerGroup = () => {
  // const history = useHistory();
  const { navigateTo } = useContext(NavContext);
  const [searchText, setSearchText] = useState('');
  // const [allPartnerGroupData, setallPartnerGroupData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [partnerGroupId, setpartnerGroupId] = useState('');
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [pageWiseUserGroupData, setPageWiseUserGroupData] = useState([]);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const allPartnerGroup = useSelector((state) => state.ocpiPartnerGroup.partnerGroups);
  const isLoader = useSelector((state) => state.ocpiPartnerGroup.isLoading);
  const page = useSelector((state) => state.ocpiPartnerGroup.page);
  const totalData = useSelector((state) => state.ocpiPartnerGroup.total);
  const limit = useSelector((state) => state.ocpiPartnerGroup.limit);
  const totalPages = useSelector((state) => state.ocpiPartnerGroup.totalPages);
  const rolePermissionData = useSelector((state) => state.manageRole.manageRoles);
  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserName = _.get(profileData, 'name', '');

  const getPartnerGroup = useCallback(
    (data) => {
      if (searchText) {
        const searchUserGroupData = {
          ...data,
          search: searchText,
        };
        dispatch({
          type: FETCH_OCPI_PARTNER_GROUP,
          payload: searchUserGroupData,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                setPageWiseUserGroupData(res.data.results);
              }
            }
          },
        });
      } else {
        dispatch({
          type: FETCH_OCPI_PARTNER_GROUP,
          payload: data ? data : {},
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                setPageWiseUserGroupData(res.data.results);
              }
            }
          },
        });
      }
    },
    [pageWiseUserGroupData, searchText]
  );

  // const allUsersInUserGroup = useCallback((id) => {
  //   const userGroupData = {
  //     OCPI_PARTNER_GROUP: id,
  //   };
  //   dispatch({ type: ALL_USERS_IN_OCPI_PARTNER_GROUP, payload: userGroupData });
  // }, []);

  const handleCloseDeleteModel = () => {
    setShowDeleteModal(false);
    setpartnerGroupId('');
  };


  const deletePartnerGroup = useCallback(
    () => {
      const partnerGroupData = {
        id: partnerGroupId // Use the partnerGroupId state here
      };
      dispatch({
        type: DELETE_OCPI_PARTNER_GROUP,
        payload: partnerGroupData,
        cb: (res) => {
          if (_.get(res, 'status', '') === UPDATE_STATUS_CODE) {
            handleCloseDeleteModel();
            getPartnerGroup({ page: 1 });
          }
          else if(_.get(res, 'status', '') === METHOD_NOT_ALLOWED)
          {
            handleCloseDeleteModel();
            getPartnerGroup({ page: 1 });
            toast.error(res.message)
          }
        },
      });
      handleCloseDeleteModel()
    },
    [partnerGroupId] // Ensure partnerGroupId is included in dependencies
  );


  // useEffect(() => {
  //   setallPartnerGroupData(_.filter(userGroupData, (item) => startsWith(_.get(item, 'name', ''), searchText)));
  // }, [searchText, allPartnerGroup, pageWiseUserGroupData]);

  useEffect(() => {
    getPartnerGroup();
  }, []);

  const handleSearch = () => {
    getPartnerGroup({});
  };

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else {
        const data = {
          page: page.selected + 1,
        };
        getPartnerGroup(data);
      }
    },
    [sortByItem]
  );

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getPartnerGroup(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getPartnerGroup(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  // const getPartnerGroupPermissions =
  //   !_.isEmpty(rolePermissionData) &&
  //   _.map(
  //     _.filter(rolePermissionData[0].permissions, (item) => item.name === GET_OCPI_PARTNER_GROUP),
  //     (data) => data.name
  //   );

  const manageUserGroupPermissions =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === MANAGE_USER_GROUPS),
      (data) => data.name
    );

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title={t('header.ocpiPartnerGroup')} />
        <div className="page-content-wrapper scrollable">
          <div className="user-group-page_main">
            <div className="user-group-main-block">
              <Card>
                <div className="user-group-block">
                  <div className="user-group-search-box">
                    <Row>
                      <Col xl={!_.isEmpty(manageUserGroupPermissions) ? 8 : 9}>
                        <SearchBox
                          preIcon={<BsSearch />}
                          value={searchText}
                          onChange={(e) => setSearchText(e.target.value)}
                          onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                              handleSearch();
                            }
                          }}
                        />
                      </Col>
                      <Col xl={'auto'} md={'auto'}>
                        <Button className="user-group-btn" onClick={handleSearch}>
                          {t('button.search')}
                        </Button>
                      </Col>
                      <Col xl={2}>
                        <Button className="user-group-btn" onClick={() => navigateTo('/addPartnerGroup')}>
                          {t('button.addPertnerGroup')}
                        </Button>
                      </Col>
                    </Row>
                  </div>
                  <div className="user-group-table">
                    <div className="table-responsive">
                      <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
                        <table className="record-list-table">
                          <thead>
                            <tr>
                              <th>
                                <div className="sorting">
                                  <span>{isLoader ? <Skeleton width={100} height={15} /> : t('ocpiPartnerGroup.srNo')}</span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>{isLoader ? <Skeleton width={100} height={15} /> : t('ocpiPartnerGroup.userGroupName')}</span>
                                  <span className="ico" onClick={() => handleSorting('name', sortByItem)}>
                                    <BiSort size={15} />
                                  </span>
                                </div>
                              </th>
                              <th className="action-col">
                                <div>
                                  <span>{isLoader ? <Skeleton width={100} height={15} /> : t('ocpiPartnerGroup.action')}</span>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {isLoader ? (
                              <tr>
                                <td colSpan={10} className="border-0">
                                  <Skeleton height={15} />
                                </td>
                              </tr>
                            ) : _.isEmpty(allPartnerGroup) ? (
                              <tr>
                                <td colSpan={8} className="border-0">
                                  <div className="empty-data-block">{t('ocpiPartnerGroup.noData')}</div>
                                </td>
                              </tr>
                            ) : (
                              !(isLoader || _.isUndefined(isLoader)) &&
                              _.map(allPartnerGroup, (data, index) => {
                                const serial_num = limit * (page - 1) + index;
                                return (
                                  <tr key={`user-group-${serial_num}`}>
                                    <td>{isLoader ? <Skeleton width={100} height={15} /> : serial_num + 1}</td>
                                    <td>{isLoader ? <Skeleton width={100} height={15} /> : _.get(data, 'name', '')}</td>
                                    <td>
                                      <span className="user-group-icon" title="Users" onClick={() => navigateTo(`/party/${data.id}`)}>
                                        {isLoader ? <Skeleton circle="true" width={24} height={24} /> : <FiUser size={22} />}
                                      </span>
                                      <span className="user-group-table-editIcon" title="Edit" onClick={() => navigateTo(`/editPartnerGroup/${data.id}`)}>
                                        {isLoader ? <Skeleton circle="true" width={24} height={24} /> : <FiEdit2 title="Edit" />}
                                      </span>
                                      <span
                                        title="Delete"
                                        className="user-group-table-deleteIcon"
                                        onClick={() => {
                                          setShowDeleteModal(true);
                                          setpartnerGroupId(data.id); // This sets the partnerGroupId when delete icon is clicked
                                        }}
                                      >
                                        {isLoader ? <Skeleton circle="true" width={24} height={24} /> : <RiDeleteBinLine title="Delete" />}
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })
                            )}
                          </tbody>
                        </table>
                      </SkeletonTheme>
                    </div>
                  </div>
                  {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(allPartnerGroup) && (
                    <ReactPagination
                      currentPage={page}
                      limit={limit}
                      total={totalData}
                      handlePageClick={(pageVal) => handlePageClick(pageVal)}
                      totalPages={totalPages}
                      marginPagesDisplayed={1}
                    />
                  )}
                  {showDeleteModal && (
                    <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}> 
                      <DeleteModal title="userGroup" onClose={handleCloseDeleteModel} onRemove={deletePartnerGroup} />
                    </Modal>
                  )}
                </div>
              </Card>
            </div>
          </div>
          {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
        </div>
      </LayoutContainer>
    </React.Fragment>
  );
};

export default OCPIPartnerGroup;
