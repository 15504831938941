export const FETCH_RFID = 'FETCH_RFID';
export const FETCH_RFID_SUCCESS = 'FETCH_RFID_SUCCESS';
export const FETCH_RFID_FAIL = 'FETCH_RFID_FAIL';

export const GET_RFID = 'GET_RFID';
export const GET_RFID_SUCCESS = 'GET_RFID_SUCCESS';
export const GET_RFID_FAIL = 'GET_RFID_FAIL';

export const CREATE_RFID = 'CREATE_RFID';
export const CREATE_RFID_SUCCESS = 'CREATE_RFID_SUCCESS';
export const CREATE_RFID_FAIL = 'CREATE_RFID_FAIL';

export const UPDATE_RFID = 'UPDATE_RFID';
export const UPDATE_RFID_SUCCESS = ' UPDATE_RFID_SUCCESS';
export const UPDATE_RFID_FAIL = 'UPDATE_RFID_FAIL';

export const DELETE_RFID = 'DELETE_RFID';
export const DELETE_RFID_SUCCESS = 'DELETE_RFID_SUCCESS';
export const DELETE_RFID_FAIL = ' DELETE_RFID_FAIL';

export const CREATE_BULK_RFID = 'CREATE_BULK_RFID';
export const CREATE_BULK_RFID_SUCCESS = 'CREATE_BULK_RFID_SUCCESS';
export const CREATE_BULK_RFID_FAIL = 'CREATE_BULK_RFID_FAIL';

export const FETCH_RFID_FOR_FLEET = 'FETCH_RFID_FOR_FLEET';
export const FETCH_RFID_FOR_FLEET_SUCCESS = 'FETCH_RFID_FOR_FLEET_SUCCESS';
export const FETCH_RFID_FOR_FLEET_FAIL = 'FETCH_RFID_FOR_FLEET_FAIL';

export const CREATE_BULK_RFID_FOR_FLEET = 'CREATE_BULK_RFID_FOR_FLEET';
export const CREATE_BULK_RFID_FOR_FLEET_SUCCESS = 'CREATE_BULK_RFID_FOR_FLEET_SUCCESS';
export const CREATE_BULK_RFID_FOR_FLEET_FAIL = 'CREATE_BULK_RFID_FOR_FLEET_FAIL';

export const UPDATE_VEHICLE_RFID = 'UPDATE_VEHICLE_RFID';
export const UPDATE_VEHICLE_RFID_SUCCESS = ' UPDATE_VEHICLE_RFID_SUCCESS';
export const UPDATE_VEHICLE_RFID_FAIL = 'UPDATE_VEHICLE_RFID_FAIL';

export const DELETE_RFID_BY_FLEET = 'DELETE_RFID_BY_FLEET';
export const DELETE_RFID_BY_FLEET_SUCCESS = 'DELETE_RFID_BY_FLEET_SUCCESS';
export const DELETE_RFID_BY_FLEET_FAIL = ' DELETE_RFID_BY_FLEET_FAIL';