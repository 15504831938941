import {
  FETCH_OFFERS,
  FETCH_OFFERS_SUCCESS,
  FETCH_OFFERS_FAIL,
  GET_OFFERS,
  GET_OFFERS_SUCCESS,
  GET_OFFERS_FAIL,
  CREATE_OFFERS,
  CREATE_OFFERS_SUCCESS,
  CREATE_OFFERS_FAIL,
  UPDATE_OFFERS,
  UPDATE_OFFERS_SUCCESS,
  UPDATE_OFFERS_FAIL,
  DELETE_OFFERS,
  DELETE_OFFERS_SUCCESS,
  DELETE_OFFERS_FAIL,
} from 'actions/offers';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  offers: [],
  offerDetail: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const offersReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_OFFERS:
    case GET_OFFERS:
    case CREATE_OFFERS:
    case DELETE_OFFERS: {
      return { ...state, isLoading: true, offerDetail: {} };
    }

    case UPDATE_OFFERS: {
      return { ...state, isLoading: true };
    }

    case FETCH_OFFERS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        offers: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case GET_OFFERS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        offerDetail: payload,
      };
    }

    case CREATE_OFFERS_SUCCESS: {
      toast.success(' Offer added successfully');
      return {
        ...state,
        isLoading: false,
        offers: _.concat(state.offers, payload),
      };
    }

    case UPDATE_OFFERS_SUCCESS: {
      toast.success(' Offer updated successfully');
      return {
        ...state,
        offers: state.offers.map((data) => (data.id === payload.id ? payload : data)),
        isLoading: false,
      };
    }

    case DELETE_OFFERS_SUCCESS: {
      toast.success(' Offer deleted successfully');
      return {
        ...state,
        isLoading: false,
        offers: state.offers.filter((data) => data.id !== payload),
      };
    }

    case FETCH_OFFERS_FAIL:
    case GET_OFFERS_FAIL:
    case CREATE_OFFERS_FAIL:
    case UPDATE_OFFERS_FAIL:
    case DELETE_OFFERS_FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};
export default offersReducer;
