import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import {
  FETCH_NOTIFICATION,
  FETCH_NOTIFICATION_SUCCESS,
  FETCH_NOTIFICATION_FAIL,
  UPDATE_FIRMWARE_NOTIFICATION,
  UPDATE_FIRMWARE_NOTIFICATION_SUCCESS,
  UPDATE_FIRMWARE_NOTIFICATION_FAIL,
  DELETE_NOTIFICATION,
  DELETE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_FAIL,
  PUSH_NOTIFICATION,
  PUSH_NOTIFICATION_SUCCESS,
  PUSH_NOTIFICATION_FAIL,
} from 'actions/notification';

function* fetchNotification(action) {
  try {
    const response = yield call(API.getNotification, action.payload);
    yield put({ type: FETCH_NOTIFICATION_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_NOTIFICATION_FAIL, payload: { error: e } });
  }
}

function* deleteNotification(action) {
  try {
    yield call(API.deleteNotification, action.payload);
    yield put({ type: DELETE_NOTIFICATION_SUCCESS, payload: action.payload });
  } catch (e) {
    yield put({ type: DELETE_NOTIFICATION_FAIL, payload: { error: e } });
  }
}

function* updateFirmwareNotification(action) {
  try {
    const response = yield call(API.updateFirmwareNotification, action.payload);
    yield put({ type: UPDATE_FIRMWARE_NOTIFICATION_SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_FIRMWARE_NOTIFICATION_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* pushNotification(action) {
  try {
    const response = yield call(API.pushNotification, action.payload);
    yield put({ type: PUSH_NOTIFICATION_SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: PUSH_NOTIFICATION_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* notification() {
  yield takeLatest(FETCH_NOTIFICATION, fetchNotification);
  yield takeLatest(DELETE_NOTIFICATION, deleteNotification);
  yield takeLatest(PUSH_NOTIFICATION, pushNotification);
  yield takeLatest(UPDATE_FIRMWARE_NOTIFICATION, updateFirmwareNotification);
}
export default notification;
