import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Card from 'components/inputs/Card';
import { MdClose } from 'react-icons/md';
import { Row, Col } from 'react-bootstrap';
import { Formik, Form, FieldArray, getIn } from 'formik';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import * as Yup from 'yup';
import Select from 'components/inputs/Select';
import { BiPlus, BiTrash } from 'react-icons/bi';
import { FETCH_USER_GROUP } from 'actions/userGroup';
import { UPDATE_STATUS_CODE } from 'components/common/constant';
import { useParams } from 'react-router-dom';
import { UPDATE_TARIFF_SLOT, GET_TARIFF } from 'actions/tariff';
import { useTranslation } from 'react-i18next';

const SlotsForm = (props) => {
  const { slotId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { onClose = _.noop(), isEdit, userGroupIndex, getFormValues } = props;
  const [initialValues, setInitialValues] = useState({ ...getFormValues(), id: slotId });
  const tariffDetail = useSelector((state) => state.tariff.tariffDetail);
  const allUserGroup = useSelector((state) => state.userGroup.userGroups);
  const userGroupPage = useSelector((state) => state.userGroup.page);
  const userGroupTotalPage = useSelector((state) => state.userGroup.totalPages);

  const getAllUserGroup = useCallback((data = {}) => {
    dispatch({ type: FETCH_USER_GROUP, payload: data });
  }, []);

  useEffect(() => {
    getAllUserGroup();
  }, []);

  const getTariffDetail = useCallback((id) => {
    dispatch({ type: GET_TARIFF, payload: id });
  }, []);

  useEffect(() => {
    if (slotId) {
      getTariffDetail(slotId);
    }
  }, [slotId]);

  useEffect(() => {
    if (Object.keys(tariffDetail).length !== 0) {
      if (tariffDetail.schedule[userGroupIndex]?.user_group_condition === undefined) {
        const user_group_condition = {
          user_group_condition: [{ price_per_user_group: '', user_group: '' }],
        };
        tariffDetail.schedule[userGroupIndex] = user_group_condition;
        setInitialValues({
          ...tariffDetail,
        });
      } else if (
        tariffDetail.schedule[userGroupIndex].user_group_condition &&
        tariffDetail.schedule[userGroupIndex].user_group_condition.length === 0
      ) {
        tariffDetail.schedule[userGroupIndex].user_group_condition = [{ price_per_user_group: '', user_group: '' }];
        setInitialValues({
          ...tariffDetail,
        });
      } else {
        setInitialValues({
          ...tariffDetail,
        });
      }
    }
  }, [tariffDetail]);

  const updateTariff = useCallback((data) => {
    dispatch({
      type: UPDATE_TARIFF_SLOT,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          onClose();
          window.location.reload();
        }
      },
    });
  }, []);

  const AddTariffSchema = Yup.object().shape({
    user_group_condition: Yup.array().of(
      Yup.object().shape({
        price_per_user_group: Yup.number()
          .positive(t('slotsForm.defaultPricePerUnit'))
          .integer(t('slotsForm.defaultPricePerUnit'))
          .required(t('slotsForm.price')),
        user_group: Yup.string(),
      })
    ),
  });
  const EditTariffSchema = Yup.object().shape({
    user_group_condition: Yup.array().of(
      Yup.object().shape({
        price_per_user_group: Yup.number()
          .positive(t('slotsForm.defaultPricePerUnit'))
          .integer(t('slotsForm.defaultPricePerUnit'))
          .required(t('slotsForm.price')),
        user_group: Yup.string().required(t('slotsForm.fromTo')),
      })
    ),
  });

  return (
    <React.Fragment>
      <div className="pricing-form-page__main">
        <Card>
          <div className="pricing-form-header__block">
            <div className="pricing-header-name">
              <span>{`User group prices`}</span>
            </div>
            <div className="close-btn">
              <MdClose size={30} color="#be210b" onClick={onClose} />
            </div>
          </div>
          <div className="pricing-form-body__block">
            <div className="pricing-form--block">
              <Formik
                enableReinitialize={isEdit}
                initialValues={initialValues}
                validationSchema={slotId ? EditTariffSchema : AddTariffSchema}
                onSubmit={(values, { setSubmitting }) => {
                  let toSend = values.schedule.map((schedule) => {
                    schedule = _.omit(schedule, '_id');
                    schedule.user_group_condition = schedule.user_group_condition.map((condition) => _.omit(condition, '_id'));
                    schedule.ocpi_partner_condition = schedule.ocpi_partner_condition.map((condition) => _.omit(condition, '_id'));
                    return schedule;
                  });
                  const tariffData = {
                    ...values,
                    schedule: toSend,
                  };
                  const editTariffData = _.omit(tariffData, ['tenant', 'oem']);
                  if (isEdit) {
                    updateTariff(editTariffData);
                  }
                  setSubmitting(false);
                }}
              >
                {({ values, handleSubmit, errors, touched, setFieldValue, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <FieldArray
                      name={`schedule[${userGroupIndex}].user_group_condition`}
                      render={(arrayHelpers) => (
                        <>
                          {_.map(values.schedule[userGroupIndex].user_group_condition, (field, index) => (
                            <Row key={`user-group-${index}`}>
                              <Col lg={5}>
                                <TextInput
                                  type="number"
                                  label={t('slotsForm.pricePerUnit')}
                                  name="price_per_user_group"
                                  value={field.price_per_user_group}
                                  onChange={(e) =>
                                    setFieldValue(`schedule[${userGroupIndex}]user_group_condition[${index}].price_per_user_group`, e.target.value)
                                  }
                                  error={
                                    getIn(errors, `user_group_condition[${index}].price_per_user_group`) &&
                                    getIn(touched, `user_group_condition[${index}].price_per_user_group`)
                                      ? getIn(errors, `user_group_condition[${index}].price_per_user_group`)
                                      : null
                                  }
                                />
                              </Col>
                              <Col lg={4}>
                                <Select
                                  label={t('slotsForm.userGroup')}
                                  placeholder={t('slotsForm.selectUserGroup')}
                                  options={_.map(allUserGroup, (item) => {
                                    return { label: item.name, value: item.id };
                                  })}
                                  onMenuScrollDown={true}
                                  getDataOnScrollDown={getAllUserGroup}
                                  page={userGroupPage}
                                  totalPage={userGroupTotalPage}
                                  name="user_group"
                                  value={field.user_group}
                                  onChange={(val) =>
                                    setFieldValue(`schedule[${userGroupIndex}]user_group_condition[${index}].user_group`, val === null ? '' : val)
                                  }
                                />
                              </Col>
                              <Col md={1} lg={1} sm={1} className="d-flex align-items-center">
                                <Button className="field--btn" onClick={() => arrayHelpers.remove(index)}>
                                  <BiTrash size={26} />
                                </Button>
                              </Col>
                            </Row>
                          ))}
                          <div>
                            <Button
                              className="field--btn"
                              onClick={() => {
                                arrayHelpers.insert(values.schedule[userGroupIndex].user_group_condition, {
                                  price_per_user_group: '',
                                  user_group: '',
                                });
                              }}
                            >
                              <BiPlus size={22} className="plus--icon" /> {t('slots.add')}
                            </Button>
                          </div>
                        </>
                      )}
                    />
                    <div className="form-btn--block">
                      <Button className="pricing-form-btn cancel-btn" onClick={onClose}>
                        {t('slots.cancel')}
                      </Button>
                      <Button type="submit" disabled={isSubmitting} className="pricing-form-btn create-btn">
                        {t('slots.submit')}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};
SlotsForm.propTypes = {
  onClose: PropTypes.func,
  isEdit: PropTypes.bool,
  userGroupIndex: PropTypes.number,
  getFormValues: PropTypes.func,
};
export default SlotsForm;
