export const FETCH_CONNECTOR_TYPE = 'FETCH_CONNECTOR_TYPE';
export const FETCH_CONNECTOR_TYPE_SUCCESS = 'FETCH_CONNECTOR_TYPE_SUCCESS';
export const FETCH_CONNECTOR_TYPE_FAIL = 'FETCH_CONNECTOR_TYPE_FAIL';

export const GET_CONNECTOR_TYPE = 'GET_CONNECTOR_TYPE';
export const GET_CONNECTOR_TYPE_SUCCESS = 'GET_CONNECTOR_TYPE_SUCCESS';
export const GET_CONNECTOR_TYPE_FAIL = 'GET_CONNECTOR_TYPE_FAIL';

export const CREATE_CONNECTOR_TYPE = 'CREATE_CONNECTOR_TYPE';
export const CREATE_CONNECTOR_TYPE_SUCCESS = 'CREATE_CONNECTOR_TYPE_SUCCESS';
export const CREATE_CONNECTOR_TYPE_FAIL = 'CREATE_CONNECTOR_TYPE_FAIL';

export const UPDATE_CONNECTOR_TYPE = 'UPDATE_CONNECTOR_TYPE';
export const UPDATE_CONNECTOR_TYPE_SUCCESS = 'UPDATE_CONNECTOR_TYPE_SUCCESS';
export const UPDATE_CONNECTOR_TYPE_FAIL = 'UPDATE_CONNECTOR_TYPE_FAIL';

export const DELETE_CONNECTOR_TYPE = 'DELETE_CONNECTOR_TYPE';
export const DELETE_CONNECTOR_TYPE_SUCCESS = 'DELETE_CONNECTOR_TYPE_SUCCESS';
export const DELETE_CONNECTOR_TYPE_FAIL = 'DELETE_CONNECTOR_TYPE_FAIL';
