import {
  FETCH_ROLE,
  FETCH_ROLE_SUCCESS,
  FETCH_ROLE_FAIL,
  GET_ROLE,
  GET_ROLE_SUCCESS,
  GET_ROLE_FAIL,
  CREATE_ROLE,
  CREATE_ROLE_SUCCESS,
  CREATE_ROLE_FAIL,
  UPDATE_ROLE,
  UPDATE_ROLE_FAIL,
  UPDATE_ROLE_SUCCESS,
  DELETE_ROLE,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAIL,
  GET_ROLE_BY_NAME_SUCCESS,
  GET_ROLE_BY_NAME,
  GET_ROLE_BY_NAME_FAIL,
} from 'actions/role';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  roles: [],
  roleDetail: {},
  roleByName: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const roleReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_ROLE:
    case GET_ROLE:
    case CREATE_ROLE:
    case DELETE_ROLE: {
      return { ...state, isLoading: true, roleDetail: {} };
    }

    case GET_ROLE_BY_NAME:
    case UPDATE_ROLE: {
      return { ...state, isLoading: true };
    }

    case FETCH_ROLE_SUCCESS: {
      if (payload.page === 1) {
        return {
          ...state,
          isLoading: false,
          roles: payload.results,
          page: payload.page,
          limit: payload.limit,
          totalPages: payload.totalPages,
          total: payload.totalResults,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          roles: _.concat(state.roles, payload.results),
          page: payload.page,
          limit: payload.limit,
          totalPages: payload.totalPages,
          total: payload.totalResults,
        };
      }
    }

    case GET_ROLE_BY_NAME_SUCCESS: {
      return {
        ...state,
        roleByName: payload.results?.[0] ?? {},
        isLoading: false,
      };
    }

    case GET_ROLE_SUCCESS: {
      return {
        ...state,
        roleDetail: payload,
        isLoading: false,
      };
    }

    case CREATE_ROLE_SUCCESS: {
      toast.success('Role added successfully');
      return {
        ...state,
        roles: _.concat(state.roles, payload),
        isLoading: false,
      };
    }

    case UPDATE_ROLE_SUCCESS: {
      toast.success('Role updated successfully');
      return {
        ...state,
        isLoading: false,
        roles: state.roles.map((data) => {
          if (data.id === payload.id) {
            return payload;
          } else {
            return data;
          }
        }),
      };
    }

    case DELETE_ROLE_SUCCESS: {
      toast.success('Role deleted successfully');
      return {
        ...state,
        isLoading: false,
        roles: state.roles.filter((data) => data.id !== payload),
      };
    }

    case FETCH_ROLE_FAIL:
    case GET_ROLE_FAIL:
    case GET_ROLE_BY_NAME_FAIL:
    case CREATE_ROLE_FAIL:
    case UPDATE_ROLE_FAIL:
    case DELETE_ROLE_FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return {
        ...state,
      };
  }
};
export default roleReducer;
