import {
  FETCH_OEM_ERROR_CODE,
  FETCH_OEM_ERROR_CODE_FAIL,
  FETCH_OEM_ERROR_CODE_SUCCESS,
  GET_OEM_ERROR_CODE,
  GET_OEM_ERROR_CODE_SUCCESS,
  GET_OEM_ERROR_CODE_FAIL,
  CREATE_OEM_ERROR_CODE,
  CREATE_OEM_ERROR_CODE_SUCCESS,
  CREATE_OEM_ERROR_CODE_FAIL,
  UPDATE_OEM_ERROR_CODE,
  UPDATE_OEM_ERROR_CODE_SUCCESS,
  UPDATE_OEM_ERROR_CODE_FAIL,
  DELETE_OEM_ERROR_CODE,
  DELETE_OEM_ERROR_CODE_SUCCESS,
  DELETE_OEM_ERROR_CODE_FAIL,

} from 'actions/oemErrorCode';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  oemErrorCodes: [],
  oemErrorCodeDetail: {},
  ocppErrorCodes:[],
  ocppErrorCodeDetail: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const oemErrorCodeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_OEM_ERROR_CODE:
    case GET_OEM_ERROR_CODE:
    case CREATE_OEM_ERROR_CODE:
    case DELETE_OEM_ERROR_CODE: {
      return { ...state, isLoading: true, oemErrorCodeDetail: {} };
    }

  

    case UPDATE_OEM_ERROR_CODE: {
      return { ...state, isLoading: true };
    }

    case FETCH_OEM_ERROR_CODE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        oemErrorCodes: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }
    
    case GET_OEM_ERROR_CODE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        oemErrorCodeDetail: payload,
      };
    }

    case CREATE_OEM_ERROR_CODE_SUCCESS: {
      toast.success('Oem error code added successfully');
      return {
        ...state,
        isLoading: false,
        oemErrorCodes: _.concat(state.oemErrorCodes, payload),
      };
    }

    case UPDATE_OEM_ERROR_CODE_SUCCESS: {
      toast.success(' Oem error code updated successfully');
      return {
        ...state,
        oemErrorCodes: state.oemErrorCodes.map((data) => (data.id === payload.id ? payload : data)),
        isLoading: false,
      };
    }

    case DELETE_OEM_ERROR_CODE_SUCCESS: {
      toast.success('Oem error code deleted successfully');
      return {
        ...state,
        isLoading: false,
        oemErrorCodes: state.oemErrorCodes.filter((data) => data.id !== payload),
      };
    }

    case FETCH_OEM_ERROR_CODE_FAIL:
    case GET_OEM_ERROR_CODE_FAIL:
    case CREATE_OEM_ERROR_CODE_FAIL:
    case UPDATE_OEM_ERROR_CODE_FAIL:
    case DELETE_OEM_ERROR_CODE_FAIL:
   {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};
export default oemErrorCodeReducer;
