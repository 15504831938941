import React, { useCallback, useEffect, useState, useContext } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header/index';
import Card from 'components/inputs/Card';
import SessionHeading from 'components/general/SessionHeading';
import { Row, Col } from 'react-bootstrap';
import TextInput from 'components/inputs/Input';
import PhoneNumberInput from 'components/inputs/PhoneNumberInput';
import Button from 'components/inputs/Button';
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import * as _ from 'lodash';
import { Formik, Form, getIn, FieldArray } from 'formik';
import * as Yup from 'yup';
import { FETCH_CITY, FETCH_STATE, FETCH_COUNTRY, FETCH_AREA } from 'actions/address';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_TENANT, GET_TENANT } from 'actions/tenant';
import Select from 'components/inputs/Select';
import moment from 'moment';
import DatePickerInput from 'components/inputs/DatePickerInput';
import CheckBox from 'components/inputs/CheckBox';
import { useParams } from 'react-router-dom';
// import { useHistory } from 'react-router-dom';
import { CREATE_STATUS_CODE } from 'components/common/constant';
import RadioButton from 'components/inputs/RadioButton';
import { BiPlus, BiTrash } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { cookie } from 'utils/cookies/cookies';
import { toast } from 'react-toastify';
import queryString from 'query-string';
import { NavContext } from 'components/privateRoute/PrivateRoute';

const TenantForm = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { tenantId } = useParams();
  // const history = useHistory();
  const { navigateTo } = useContext(NavContext);
  const [phoneError, setPhoneError] = useState('');
  const [value, setValue] = useState(false);
  const [index, setIndex] = useState('');

  const tenantDetail = useSelector((state) => state.tenant.tenantDetail);
  const allCountries = useSelector((state) => state.country.countries);
  const allStates = useSelector((state) => state.state.states);
  const allCities = useSelector((state) => state.city.city);
  const allArea = useSelector((state) => state.area.areas);
  const countryPage = useSelector((state) => state.country.page);
  const statePage = useSelector((state) => state.state.page);
  const cityPage = useSelector((state) => state.city.page);
  const areaPage = useSelector((state) => state.area.page);
  const countryTotalPage = useSelector((state) => state.country.totalPages);
  const stateTotalPage = useSelector((state) => state.state.totalPages);
  const cityTotalPage = useSelector((state) => state.city.totalPages);
  const areaTotalPage = useSelector((state) => state.area.totalPages);

  const getTenant = useCallback((id) => {
    dispatch({ type: GET_TENANT, payload: id });
  }, []);

  const addTenant = useCallback((data) => {
    dispatch({
      type: ADD_TENANT,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === CREATE_STATUS_CODE) {
          navigateTo('/tenantAdmin');
        }
      },
    });
  }, []);

  const getAllCountry = useCallback((data = {}) => {
    dispatch({ type: FETCH_COUNTRY, payload: data });
  }, []);

  const getStateByCountry = useCallback((data = {}) => {
    dispatch({ type: FETCH_STATE, payload: data });
  }, []);

  const getCityByState = useCallback((data = {}) => {
    dispatch({ type: FETCH_CITY, payload: data });
  }, []);

  const getAreaByCity = useCallback((data = {}) => {
    dispatch({ type: FETCH_AREA, payload: data });
  }, []);

  useEffect(() => {
    getAllCountry();
  }, []);

  useEffect(() => {
    if (tenantId && !_.isEmpty(tenantDetail)) {
      getStateByCountry({ country_name: tenantDetail.address.country });
      getCityByState({ state_name: tenantDetail.address.state });
      getAreaByCity({ city_name: tenantDetail.address.city });
    }
  }, [tenantId, tenantDetail]);

  useEffect(() => {
    if (tenantId) {
      getTenant(tenantId);
    }
  }, [tenantId]);

  const AddTenantSchema = Yup.object().shape({
    name: Yup.string()
      .required(t('tenantForm.name'))
      .strict(true)
      .trim(t('tenantForm.space'))
      .matches(/^[A-Za-z\s]/, t('tenantForm.matches'))
      .max(100, t('tenantForm.maxlimit')),
    charging_stations: Yup.number().required(t('tenantForm.chargingStation')).min(1, t('tenantForm.numchk')),
    chargers: Yup.number().required(t('tenantForm.charger')).min(0, t('tenantForm.numchk')),
    mode: Yup.string().required(t('tenantForm.mode')),
    max_user: Yup.number().required(t('tenantForm.maxUser')).min(0, t('tenantForm.numchk')),
    license_expiry: Yup.string().required(t('tenantForm.licenseExpiry')),
    allow_remote_access: Yup.string().required(t('tenantForm.remoteAccess')),
    details: Yup.object().shape({
      // gst: Yup.string()
      //   .required('GST is required!')
      //   .strict(true)
      //   .trim('Space is not allowed')
      //   .matches(/^[A-Za-z0-9\s]+$/, 'Only alphabets and numbers are allowed')
      //   .max(100, 'Text limit should be less than 100 characters.'),
      // compliances: Yup.string().required('Compliances is required!'),
      // notes: Yup.string()
      //   .required('Note is required!')
      //   .strict(true)
      //   .trim('Space is not allowed')
      //   .max(100, 'Text limit should be less than 100 characters.'),
      gst: Yup.array().of(
        Yup.object().shape({
          state: Yup.string().required(t('tenantForm.state')),
          value: Yup.string().required(t('tenantForm.gst')),
        })
      ),
    }),
    address: Yup.object().shape({
      country: Yup.string().required(t('tenantForm.country')),
      state: Yup.string().required(t('tenantForm.state')),
      city: Yup.string().required(t('tenantForm.city')),
      area: Yup.string().required(t('tenantForm.area')),
      pincode: Yup.string()
        .required(t('tenantForm.pincode'))
        .strict(true)
        .trim(t('tenantForm.space'))
        .matches(/^[0-9\s]+$/, t('tenantForm.matches'))
        .max(32, t('tenantForm.pincodelength')),
      details: Yup.string().required(t('tenantForm.address')).strict(true).trim(t('tenantForm.space')).max(100, t('tenantForm.maxlimit')),
    }),
    contact_person: Yup.object().shape({
      name: Yup.string()
        .required(t('tenantForm.name'))
        .strict(true)
        .trim(t('tenantForm.space'))
        .matches(/^[A-Za-z\s]+$/, t('tenantForm.matches'))
        .max(100, t('tenantForm.maxlimit')),
      email: Yup.string()
        .required(t('tenantForm.email'))
        .strict(true)
        .trim(t('tenantForm.space'))
        .email(t('tenantForm.emailChk'))
        .max(100, t('tenantForm.maxlimit')),
      phone: Yup.string().required(t('tenantForm.phone')),
    }),
    ocpp_party_id: Yup.string()
      .strict(true)
      .matches(/^[A-Za-z]+$/, t('tenantForm.matches'))
      .length(3, t('Text limit should be only 3 characters')),
  });

  const initialValues = {
    charging_stations: 1,
    chargers: 0,
    name: '',
    mode: '',
    logo: '',
    max_user: 0,
    license_expiry: '',
    allow_remote_access: '',
    is_email_invoice: false,
    address: {
      country: '',
      state: '',
      city: '',
      area: '',
      pincode: '',
      details: '',
    },
    contact_person: {
      name: '',
      email: '',
      country_code: 91,
      phone: '',
      dob: '',
      address: '',
      gender: '',
    },
    details: {
      gst: [{ state: '', value: '', address: '', pincode: '', is_default: false }],
      // compliances: '',
      notes: '',
    },
    licenses: [],
    settings: {
      notification_allowed: ['Email', 'SMS'],
    },
    invoice_setting: {
      logo: '',
      name: '',
      email: '',
      country_code: 91,
      phone: '',
      address: '',
      toll_free: '',
      website: '',
      ocpp_party_id: '',
      generateEInvoice: true,
    },
    generateNewSeriesInvoice: false,
  };
  const token = cookie.get('token');
  const handleImage = (image, savePath, setFieldValue) => {
    const fileName = _.get(image, 'name', '');

    axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: process.env.REACT_APP_API_URL + `/util/fileupload?name=${fileName}`,
    })
      .then((res) => {
        if (res.status === 201) {
          const signedRequest = _.get(res, 'data.signedRequest', '');
          handleUploadFile(signedRequest, image, savePath, setFieldValue);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((e) => console.error('Error -> ', e));
  };
  const handleUploadFile = (url, imageData, savePath, setFieldValue) => {
    const fileType = _.get(imageData, 'type', '');

    axios({
      method: 'put',
      headers: {
        'Content-Type': fileType,
        'Content-Encoding': 'base64',
      },
      url: url,
      data: imageData,
    })
      .then((res) => {
        if (res.status === 200) {
          const imgUrlData = queryString.parseUrl(url);
          setFieldValue(savePath, imgUrlData.url);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((e) => console.error('Error -> ', e));
  };

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title={`Add Tenant`} backTo="/tenantAdmin" />
        <div className="page-content-wrapper scrollable">
          <div className="add-tenant-page__main">
            <Card className="tenant-form--card">
              <Formik
                initialValues={initialValues}
                validationSchema={AddTenantSchema}
                onSubmit={(values, { setSubmitting }) => {
                  if (values.contact_person.phone && !isValidPhoneNumber(values.contact_person.phone)) {
                    setPhoneError('Phone number is not valid!');
                  } else {
                    const countryCode = _.get(parsePhoneNumber(values.contact_person.phone), 'countryCallingCode', '');
                    const phoneNumber = _.get(parsePhoneNumber(values.contact_person.phone), 'nationalNumber', '');
                    _.map(values.details.gst, (item, i) => {
                      if (i === index) {
                        item.is_default = value;
                      } else {
                        item.is_default = false;
                      }
                    });
                    const newTenantData = {
                      ...values,
                      license_expiry: moment(values.license_expiry).format('MM-DD-YYYY'),
                      contact_person: { ...values.contact_person, country_code: countryCode, phone: phoneNumber },
                      allow_remote_access: JSON.parse(_.get(values, 'allow_remote_access').toLowerCase()),
                    };
                    addTenant(newTenantData);
                  }
                  setSubmitting(false);
                }}
              >
                {({ values, errors, touched, handleSubmit, handleChange, setFieldValue, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <div>
                      <SessionHeading text={t('editTenantForm.generalInfo')} />
                      <div className="tenant-form--block pb-4">
                        <Row>
                          <Col lg={6} md={6}>
                            <TextInput
                              isRequired
                              label={t('editTenantForm.companyName')}
                              placeholder={t('editTenantForm.companyName')}
                              name="name"
                              type="text"
                              value={values.name}
                              onChange={handleChange}
                              error={errors.name && touched.name ? errors.name : null}
                            />
                          </Col>
                          <Col lg={6} md={6}>
                            <Select
                              isRequired
                              label={t('editTenantForm.modeLabel')}
                              options={[
                                { label: 'B2B', value: 'b2b' },
                                { label: 'B2C', value: 'b2c' },
                                { label: 'Hybrid', value: 'hybrid' },
                              ]}
                              placeholder={t('editTenantForm.modeLabel')}
                              name="mode"
                              value={values.mode}
                              onChange={(val) => setFieldValue(`mode`, val)}
                              error={errors.mode && touched.mode ? errors.mode : null}
                            />
                          </Col>
                          <Col lg={6} md={6}>
                            <TextInput
                              type="file"
                              label={t('editTenantForm.companyLogo')}
                              name="logo"
                              accept="image/*"
                              onChange={(e) => handleImage(e.currentTarget.files[0], 'logo', setFieldValue)}
                              error={errors.logo && touched.logo ? errors.logo : null}
                              image={values.logo}
                            />
                          </Col>

                          <Col lg={6} md={6}>
                            <TextInput
                              isRequired
                              placeholder={t('editTenantForm.chargingStationLabel')}
                              label={t('editTenantForm.chargingStationLabel')}
                              name="charging_stations"
                              type="number"
                              value={values.charging_stations}
                              onChange={handleChange}
                              error={errors.charging_stations && touched.charging_stations ? errors.charging_stations : null}
                            />
                          </Col>
                          <Col lg={6} md={6}>
                            <TextInput
                              isRequired
                              placeholder={t('editTenantForm.chargers')}
                              label={t('editTenantForm.chargers')}
                              name="chargers"
                              type="number"
                              value={values.chargers}
                              onChange={handleChange}
                              error={errors.chargers && touched.chargers ? errors.chargers : null}
                            />
                          </Col>
                          <Col lg={6} md={6}>
                            <TextInput
                              isRequired
                              placeholder={t('editTenantForm.maximumUser')}
                              label={t('editTenantForm.maximumUser')}
                              name="max_user"
                              type="number"
                              value={values.max_user}
                              onChange={handleChange}
                              error={errors.max_user && touched.max_user ? errors.max_user : null}
                            />
                          </Col>
                          <Col lg={6} md={6}>
                            <DatePickerInput
                              isRequired
                              placeholder={t('editTenantForm.licenseExpiryLabel')}
                              label={t('editTenantForm.licenseExpiryLabel')}
                              name="license_expiry"
                              value={values.license_expiry}
                              onChange={(dateString) => setFieldValue('license_expiry', dateString)}
                              error={errors.license_expiry && touched.license_expiry ? errors.license_expiry : null}
                            />
                          </Col>
                          {/*<Col lg={6} md={6}>*/}
                          {/*  <TextInput*/}
                          {/*    label="GST"*/}
                          {/*    placeholder="GST "*/}
                          {/*    name="details.gst"*/}
                          {/*    value={_.get(values, 'details.gst')}*/}
                          {/*    onChange={handleChange}*/}
                          {/*    // error={getIn(errors, 'details.gst') && getIn(touched, 'details.gst') ? getIn(errors, 'details.gst') : null}*/}
                          {/*  />*/}
                          {/*</Col>*/}
                          {/* <Col lg={6} md={6}>
                            <TextInput
                              label="Compliances"
                              placeholder="Compliances"
                              name="details.compliances"
                              value={_.get(values, 'details.compliances')}
                              onChange={handleChange}
                              error={
                                getIn(errors, 'details.compliances') && getIn(touched, 'details.compliances')
                                  ? getIn(errors, 'details.compliances')
                                  : null
                              }
                            />
                          </Col> */}
                          <Col lg={6} md={6}>
                            <TextInput
                              as="textarea"
                              rows="4"
                              label={t('editTenantForm.notes')}
                              placeholder={t('editTenantForm.notes')}
                              name="details.notes"
                              value={_.get(values, 'details.notes')}
                              onChange={handleChange}
                              // error={getIn(errors, 'details.notes') && getIn(touched, 'details.notes') ? getIn(errors, 'details.notes') : null}
                            />
                          </Col>
                          <Col lg={6} md={6}>
                            <Select
                              isRequired
                              label={t('editTenantForm.remoteAccessLabel')}
                              options={[
                                { label: 'True', value: 'true' },
                                { label: 'False', value: 'false' },
                              ]}
                              placeholder={t('editTenantForm.selectRemoteAccess')}
                              name="allow_remote_access"
                              value={values.allow_remote_access}
                              onChange={(val) => setFieldValue(`allow_remote_access`, val)}
                              error={errors.allow_remote_access && touched.allow_remote_access ? errors.allow_remote_access : null}
                            />
                          </Col>
                          <Col lg={6} md={12}>
                            <TextInput
                              label={t('editTenantForm.ocpiPartyId')}
                              placeholder={t('editTenantForm.ocpiPartyId')}
                              name="ocpp_party_id"
                              value={_.get(values, 'ocpp_party_id')}
                              onChange={(e) => setFieldValue('ocpp_party_id', e.target.value)}
                              error={errors.ocpp_party_id && touched.ocpp_party_id ? errors.ocpp_party_id : null}
                            />
                          </Col>
                          <Col lg={6} md={6}>
                            <div className="notification-setting__block">
                              <label htmlFor="notification_allow" className="notification--label">
                                {t('editTenantForm.notification')}
                              </label>
                              <div id="notification_allow" className="notification-setting--inner">
                                <div className="notification--item">
                                  <CheckBox
                                    id="email"
                                    name="email"
                                    checked={_.includes(_.get(values, 'settings.notification_allowed'), 'Email')}
                                    onChange={() => {
                                      if (_.includes(_.get(values, 'settings.notification_allowed'), 'Email')) {
                                        const newNotificationData = _.remove(
                                          _.get(values, 'settings.notification_allowed'),
                                          (item) => item !== 'Email'
                                        );
                                        setFieldValue('settings.notification_allowed', newNotificationData);
                                      } else {
                                        setFieldValue(
                                          'settings.notification_allowed',
                                          _.concat(_.get(values, 'settings.notification_allowed'), 'Email')
                                        );
                                      }
                                    }}
                                  >
                                    {t('editTenantForm.emailLabel')}
                                  </CheckBox>
                                </div>
                                <div className="notification--item">
                                  <CheckBox
                                    id="sms"
                                    name="sms"
                                    checked={_.includes(_.get(values, 'settings.notification_allowed'), 'SMS')}
                                    onChange={() => {
                                      if (_.includes(_.get(values, 'settings.notification_allowed'), 'SMS')) {
                                        const newNotificationData = _.remove(
                                          _.get(values, 'settings.notification_allowed'),
                                          (item) => item !== 'SMS'
                                        );
                                        setFieldValue('settings.notification_allowed', newNotificationData);
                                      } else {
                                        setFieldValue(
                                          'settings.notification_allowed',
                                          _.concat(_.get(values, 'settings.notification_allowed'), 'SMS')
                                        );
                                      }
                                    }}
                                  >
                                    {t('editTenantForm.sms')}
                                  </CheckBox>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col lg={6} md={6}>
                            <div className="generateEInvoice-field-main--block">
                              <div className="generateEInvoice-checkbox-input__block">
                                <label htmlFor="generateEInvoice" className="generateEInvoice--label">
                                  {t('editTenantForm.manageAutoEmail')}
                                </label>
                                <CheckBox
                                  name="is_email_invoice"
                                  checked={_.get(values, 'is_email_invoice')}
                                  onChange={(e) => setFieldValue('is_email_invoice', e.target.checked)}
                                />
                              </div>
                              <div className="generateNewSeriesInvoice-checkbox-input__block">
                                <label htmlFor="generateNewSeriesInvoice" className="generateNewSeriesInvoice--label">
                                  {t('editTenantForm.generateNewSeriesInvoice')}
                                </label>
                                <CheckBox
                                  name="generateNewSeriesInvoice"
                                  checked={_.get(values, 'generateNewSeriesInvoice')}
                                  onChange={(e) => setFieldValue('generateNewSeriesInvoice', e.target.checked)}
                                />
                              </div>
                            </div>
                          </Col>
                          <div className="mt-3">
                            <Col lg={12} md={12}>
                              <SessionHeading text="GST Details" />
                              <div className="tenant-form-page__main">
                                <div className="gst-block__field-inputs">
                                  <FieldArray
                                    name="details.gst"
                                    render={(arrayHelpers) => (
                                      <>
                                        <Row>
                                          {_.map(values.details.gst, (field, index) => (
                                            <>
                                              <Col lg={12} key={`gst-${index}`}>
                                                <Row>
                                                  <Col lg={12} md={12}>
                                                    <TextInput
                                                      isRequired
                                                      label={t('editTenantForm.addressLabel')}
                                                      placeholder={t('editTenantForm.addressLabel')}
                                                      name="address"
                                                      value={field.address}
                                                      onChange={(e) => setFieldValue(`details.gst[${index}].address`, e.target.value)}
                                                      error={
                                                        getIn(errors, `details.gst[${index}].address`) &&
                                                        getIn(touched, `details.gst[${index}].address`)
                                                          ? getIn(errors, `details.gst[${index}].address`)
                                                          : null
                                                      }
                                                    />
                                                  </Col>
                                                  <Col lg={3} md={3}>
                                                    <Select
                                                      isRequired
                                                      className="mb-3"
                                                      label={t('filters.state')}
                                                      onFocus={() => getStateByCountry({ country_name: 'India' })}
                                                      options={_.map(allStates, (item) => {
                                                        return { label: item.name, value: item.name };
                                                      })}
                                                      placeholder={t('placeHolder.selectState')}
                                                      name="state"
                                                      value={_.get(field, 'state')}
                                                      onMenuScrollDown={true}
                                                      getDataOnScrollDown={(data) => getStateByCountry({ ...data, country_name: 'India' })}
                                                      page={statePage}
                                                      totalPage={stateTotalPage}
                                                      onChange={(val) => {
                                                        // getCityByState(val);
                                                        const currentState = _.find(allStates, { name: val });
                                                        setFieldValue(`details.gst[${index}].state`, currentState.name);
                                                      }}
                                                      error={
                                                        getIn(errors, `details.gst[${index}].state`) && getIn(touched, `details.gst[${index}].state`)
                                                          ? getIn(errors, `details.gst[${index}].state`)
                                                          : null
                                                      }
                                                    />
                                                  </Col>
                                                  <Col lg={3} md={3}>
                                                    <TextInput
                                                      isRequired
                                                      label={t('editTenantForm.pincode')}
                                                      placeholder={t('editTenantForm.pincode')}
                                                      name="pincode"
                                                      value={field.pincode}
                                                      onChange={(e) => setFieldValue(`details.gst[${index}].pincode`, e.target.value)}
                                                      error={
                                                        getIn(errors, `details.gst[${index}].pincode`) &&
                                                        getIn(touched, `details.gst[${index}].pincode`)
                                                          ? getIn(errors, `details.gst[${index}].pincode`)
                                                          : null
                                                      }
                                                    />
                                                  </Col>
                                                  <Col lg={3} md={3}>
                                                    <TextInput
                                                      isRequired
                                                      label={t('editTenantForm.gstNo')}
                                                      name="value"
                                                      value={field.value}
                                                      onChange={(e) => setFieldValue(`details.gst[${index}].value`, e.target.value)}
                                                      error={
                                                        getIn(errors, `details.gst[${index}].value`) && getIn(touched, `details.gst[${index}].value`)
                                                          ? getIn(errors, `details.gst[${index}].value`)
                                                          : null
                                                      }
                                                    />
                                                  </Col>
                                                  <Col lg={2} md={2}>
                                                    <div className="pt-5">
                                                      <RadioButton
                                                        containerClassName="radio-field"
                                                        checked={field.is_default === true}
                                                        value="true"
                                                        name="is_default"
                                                        onChange={(event) => {
                                                          setFieldValue(`details.gst[${index}].is_default`, JSON.parse(event.target.checked));
                                                          setValue(event.target.checked);
                                                          setIndex(index);
                                                        }}
                                                      >
                                                        {t('editTenantForm.isDefault')}
                                                      </RadioButton>
                                                    </div>
                                                  </Col>
                                                  <Col xl={1} lg={1} md={1} className="d-flex align-items-center">
                                                    {index > 0 && (
                                                      <Button className="tenant-field--btn" onClick={() => arrayHelpers.remove(index)}>
                                                        <BiTrash size={25} />
                                                      </Button>
                                                    )}
                                                  </Col>
                                                </Row>
                                              </Col>
                                            </>
                                          ))}
                                        </Row>
                                        <div>
                                          <Button
                                            className="tenant-field--btn"
                                            onClick={() =>
                                              arrayHelpers.insert(values.details.gst.length, {
                                                state: '',
                                                value: '',
                                                is_default: false,
                                              })
                                            }
                                          >
                                            <BiPlus size={22} className="plus--icon" />
                                            {t('editTenantForm.add')}
                                          </Button>
                                        </div>
                                      </>
                                    )}
                                  />
                                </div>
                              </div>
                            </Col>
                          </div>
                        </Row>
                      </div>
                    </div>
                    <div>
                      <SessionHeading text={t('editTenantForm.addressInfo')} />
                      <div className="tenant-form--block">
                        <Row>
                          <Col lg={12} md={12}>
                            <TextInput
                              isRequired
                              label={t('editTenantForm.addressLabel')}
                              placeholder={t('editTenantForm.addressLabel')}
                              name="address.details"
                              value={_.get(values, 'address.details')}
                              onChange={handleChange}
                              error={getIn(errors, 'address.details') && getIn(touched, 'address.details') ? getIn(errors, 'address.details') : null}
                            />
                          </Col>
                          <Col lg={6} md={6}>
                            <Select
                              isRequired
                              label={t('filters.country')}
                              options={_.map(allCountries, (item) => {
                                return { label: item.name, value: item.name };
                              })}
                              placeholder={t('placeHolder.selectCountry')}
                              onMenuScrollDown={true}
                              getDataOnScrollDown={getAllCountry}
                              page={countryPage}
                              totalPage={countryTotalPage}
                              name="address.country"
                              value={_.get(values, 'address.country')}
                              onChange={(val) => {
                                getStateByCountry({ country_name: val });
                                const currentCountry = _.find(allCountries, { name: val });
                                setFieldValue(`address.country`, currentCountry.name);
                                setFieldValue('address.state', '');
                                setFieldValue(`address.city`, '');
                                setFieldValue(`address.area`, '');
                                setFieldValue(`address.pincode`, '');
                              }}
                              error={getIn(errors, 'address.country') && getIn(touched, 'address.country') ? getIn(errors, 'address.country') : null}
                            />
                          </Col>
                          <Col lg={6} md={6}>
                            <Select
                              isRequired
                              className="mb-3"
                              label={t('filters.state')}
                              options={_.map(allStates, (item) => {
                                return { label: item.name, value: item.name };
                              })}
                              placeholder={t('placeHolder.selectState')}
                              name="address.state"
                              onMenuScrollDown={true}
                              getDataOnScrollDown={(data) => getStateByCountry({ ...data, country_name: _.get(values, 'address.country') })}
                              page={statePage}
                              totalPage={stateTotalPage}
                              value={_.get(values, 'address.state')}
                              onChange={(val) => {
                                getCityByState({ state_name: val });
                                const currentState = _.find(allStates, { name: val });
                                setFieldValue(`address.state`, currentState.name);
                                setFieldValue(`address.city`, '');
                                setFieldValue(`address.area`, '');
                                setFieldValue(`address.pincode`, '');
                              }}
                              error={getIn(errors, 'address.state') && getIn(touched, 'address.state') ? getIn(errors, 'address.state') : null}
                            />
                          </Col>
                          <Col lg={6} md={6}>
                            <Select
                              isRequired
                              className="mb-3"
                              label={t('filters.city')}
                              options={
                                values.address.state &&
                                _.map(allCities, (item) => {
                                  return { label: item.name, value: item.name };
                                })
                              }
                              placeholder={t('placeHolder.selectCity')}
                              name="address.city"
                              value={_.get(values, 'address.city')}
                              onMenuScrollDown={true}
                              getDataOnScrollDown={(data) => getCityByState({ ...data, state_name: _.get(values, 'address.state') })}
                              page={cityPage}
                              totalPage={cityTotalPage}
                              onChange={(val) => {
                                getAreaByCity({ city_name: val });
                                const currentCity = _.find(allCities, { name: val });
                                setFieldValue(`address.city`, currentCity.name);
                                setFieldValue(`address.area`, '');
                                setFieldValue(`address.pincode`, '');
                              }}
                              error={getIn(errors, 'address.city') && getIn(touched, 'address.city') ? getIn(errors, 'address.city') : null}
                            />
                          </Col>
                          <Col lg={6} md={6}>
                            <Select
                              isRequired
                              label={t('filters.area')}
                              options={
                                values.address.city &&
                                _.map(allArea, (item) => {
                                  return { label: item.name, value: item.name };
                                })
                              }
                              placeholder={t('placeHolder.selectArea')}
                              name="address.area"
                              value={_.get(values, 'address.area')}
                              onMenuScrollDown={true}
                              getDataOnScrollDown={(data) => getAreaByCity({ ...data, city_name: _.get(values, 'address.city') })}
                              page={areaPage}
                              totalPage={areaTotalPage}
                              onChange={(val) => {
                                const currentArea = _.find(allArea, { name: val });
                                setFieldValue(`address.area`, currentArea.name);
                                setFieldValue(`address.pincode`, currentArea.postal_code);
                              }}
                              error={getIn(errors, 'address.area') && getIn(touched, 'address.area') ? getIn(errors, 'address.area') : null}
                            />
                          </Col>
                          <Col lg={6} md={6}>
                            <TextInput
                              isRequired
                              disabled
                              label={t('editTenantForm.pincode')}
                              placeholder={t('editTenantForm.pincode')}
                              name="address.pincode"
                              value={_.get(values, 'address.pincode')}
                              onChange={handleChange}
                              error={getIn(errors, 'address.pincode') && getIn(touched, 'address.pincode') ? getIn(errors, 'address.pincode') : null}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <div>
                      <div>
                        <SessionHeading text={t('editTenantForm.invoiceSetting')} />
                        <div className="tenant-form--block">
                          <Row>
                            <Col lg={6} md={6}>
                              <TextInput
                                type="file"
                                label={t('editTenantForm.logo')}
                                name="invoice_setting.logo"
                                accept="image/*"
                                onChange={(e) => handleImage(e.currentTarget.files[0], 'invoice_setting.logo', setFieldValue)}
                                image={_.get(values, 'invoice_setting.logo')}
                              />
                            </Col>
                            <Col lg={6} md={6}>
                              <TextInput
                                label={t('editTenantForm.nameLabel')}
                                placeholder={t('editTenantForm.nameLabel')}
                                name="invoice_setting.name"
                                value={_.get(values, 'invoice_setting.name')}
                                onChange={handleChange}
                              />
                            </Col>
                            <Col lg={6} md={6}>
                              <TextInput
                                label={t('editTenantForm.emailAddress')}
                                placeholder={t('editTenantForm.emailAddress')}
                                name="invoice_setting.email"
                                value={_.get(values, 'invoice_setting.email')}
                                onChange={handleChange}
                              />
                            </Col>
                            <Col lg={6} md={6}>
                              <PhoneNumberInput
                                label={t('editTenantForm.phoneNumber')}
                                name="invoice_setting.phone"
                                value={_.get(values, 'invoice_setting.phone')}
                                onChange={(val) => {
                                  setFieldValue(`invoice_setting.phone`, val);
                                  if (!_.isEmpty(phoneError)) {
                                    setPhoneError('');
                                  }
                                }}
                              />
                            </Col>
                            <Col lg={6} md={12}>
                              <TextInput
                                label={t('editTenantForm.addressLabel')}
                                name="address"
                                value={_.get(values, 'invoice_setting.address')}
                                onChange={(e) => setFieldValue('invoice_setting.address', e.target.value)}
                              />
                            </Col>
                            <Col lg={6} md={12}>
                              <TextInput
                                label={t('editTenantForm.website')}
                                placeholder={t('editTenantForm.website')}
                                value={_.get(values, 'invoice_setting.website')}
                                onChange={(e) => setFieldValue('invoice_setting.website', e.target.value)}
                              />
                            </Col>
                            <Col lg={6} md={12}>
                              <TextInput
                                label={t('editTenantForm.tollFree')}
                                placeholder={t('editTenantForm.tollFree')}
                                name="invoice_setting.toll_free"
                                value={_.get(values, 'invoice_setting.toll_free')}
                                onChange={(e) => setFieldValue('invoice_setting.toll_free', e.target.value)}
                              />
                            </Col>
                            <Col lg={'auto'}>
                              <div className="generateEInvoice-field-main--block">
                                <div className="generateEInvoice-checkbox-input__block">
                                  <label htmlFor="generateEInvoice" className="generateEInvoice--label">
                                    {t('editTenantForm.generateEInvoice')}
                                  </label>
                                  <CheckBox
                                    name="invoice_setting.generateEInvoice"
                                    checked={_.get(values, 'invoice_setting.generateEInvoice')}
                                    onChange={(e) => setFieldValue('invoice_setting.generateEInvoice', e.target.checked)}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <SessionHeading text={t('editTenantForm.contactInfo')} />
                        <div className="tenant-form--block">
                          <Row>
                            <Col lg={6} md={6}>
                              <TextInput
                                isRequired
                                label={t('editTenantForm.tenantAdmin')}
                                placeholder={t('editTenantForm.tenantAdmin')}
                                name="contact_person.name"
                                value={_.get(values, 'contact_person.name')}
                                onChange={handleChange}
                                error={
                                  getIn(errors, 'contact_person.name') && getIn(touched, 'contact_person.name')
                                    ? getIn(errors, 'contact_person.name')
                                    : null
                                }
                              />
                            </Col>
                            <Col lg={6} md={6}>
                              <TextInput
                                isRequired
                                label={t('editTenantForm.emailAddress')}
                                placeholder={t('editTenantForm.emailAddress')}
                                name="contact_person.email"
                                value={_.get(values, 'contact_person.email')}
                                onChange={handleChange}
                                error={
                                  getIn(errors, 'contact_person.email') && getIn(touched, 'contact_person.email')
                                    ? getIn(errors, 'contact_person.email')
                                    : null
                                }
                              />
                            </Col>
                            <Col lg={6} md={6}>
                              <PhoneNumberInput
                                isRequired
                                label={t('editTenantForm.phoneNumber')}
                                name="contact_person.phone"
                                value={_.get(values, 'contact_person.phone')}
                                onChange={(val) => {
                                  setFieldValue(`contact_person.phone`, val);
                                  if (!_.isEmpty(phoneError)) {
                                    setPhoneError('');
                                  }
                                }}
                                error={
                                  getIn(errors, 'contact_person.phone') && getIn(touched, 'contact_person.phone')
                                    ? getIn(errors, 'contact_person.phone')
                                    : phoneError
                                    ? phoneError
                                    : null
                                }
                              />
                            </Col>
                            <Col xl={6} lg={12}>
                              <DatePickerInput
                                label={t('editTenantForm.dobLabel')}
                                value={_.get(values, 'contact_person.dob')}
                                onChange={(dateString) => setFieldValue('contact_person.dob', dateString)}
                              />
                            </Col>
                            <Col lg={6} md={12}>
                              <TextInput
                                label={t('editTenantForm.addressLabel')}
                                name="address"
                                value={_.get(values, 'contact_person.address')}
                                onChange={(e) => setFieldValue('contact_person.address', e.target.value)}
                              />
                            </Col>
                            <Col xl={6} lg={12}>
                              <div className="gender-field__block">
                                <RadioButton
                                  containerClassName="radio-field"
                                  checked={_.get(values, 'contact_person.gender') === 'male'}
                                  onChange={() => setFieldValue('contact_person.gender', 'male')}
                                >
                                  {t('editTenantForm.male')}
                                </RadioButton>
                                <RadioButton
                                  containerClassName="radio-field"
                                  checked={_.get(values, 'contact_person.gender') === 'female'}
                                  onChange={() => setFieldValue('contact_person.gender', 'female')}
                                >
                                  {t('editTenantForm.female')}
                                </RadioButton>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                    <div className="add-cancel-btn-block">
                      <Button type="submit" className="add--btn" disabled={isSubmitting}>
                        {t('button.addTenant')}
                      </Button>
                      <span className="cancel-btn---block">
                        <Button className="cancel---btn" onClick={() => navigateTo(`/tenantAdmin`)}>
                          {t('button.cancel')}
                        </Button>
                      </span>
                    </div>
                  </Form>
                )}
              </Formik>
            </Card>
          </div>
        </div>
      </LayoutContainer>
    </React.Fragment>
  );
};

export default TenantForm;
