import {
  CREATE_USER,
  CREATE_USER_FAIL,
  CREATE_USER_SUCCESS,
  DELETE_USER,
  DELETE_USER_FAIL,
  DELETE_USER_SUCCESS,
  FETCH_USER,
  FETCH_USER_FAIL,
  FETCH_USER_SUCCESS,
  CHANGE_ROLE,
  CHANGE_ROLE_SUCCESS,
  CHANGE_ROLE_FAIL,
  UPDATE_USER_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_FAIL,
} from 'actions/user';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  users: [],
  page_no: '',
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CREATE_USER:
    case UPDATE_USER:
    case CHANGE_ROLE:
    case DELETE_USER: {
      return { ...state, isLoading: true };
    }

    case FETCH_USER: {
      return { ...state, isLoading: true, page_no: '' };
    }

    case FETCH_USER_SUCCESS: {
      return {
        ...state,
        users: payload.data?.results,
        page_no: payload.config?.params?.page,
        isLoading: false,
        page: payload.data?.page,
        limit: payload.data?.limit,
        totalPages: payload.data?.totalPages,
        total: payload.data?.totalResults,
      };
    }

    case CREATE_USER_SUCCESS: {
      toast.success('Create User successfully');
      return {
        ...state,
        users: _.concat(state.users, payload),
        isLoading: false,
      };
    }

    case UPDATE_USER_SUCCESS:
    case CHANGE_ROLE_SUCCESS: {
      // toast.success(`User's role change successfully`);
      return {
        ...state,
        users: state.users.map((data) => {
          if (data.id === payload.id) {
            return payload;
          } else {
            return data;
          }
        }),
      };
    }

    case DELETE_USER_SUCCESS: {
      // window.location.reload();
      toast.success(`User deleted successfully`);
      return {
        ...state,
        users: state.users.filter((data) => data.id !== payload),
      };
    }

    case FETCH_USER_FAIL:
    case CREATE_USER_FAIL:
    case DELETE_USER_FAIL:
    case UPDATE_USER_FAIL:
    case CHANGE_ROLE_FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};
export default userReducer;
