import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import {
  SEND_OTP,
  SEND_OTP_FAIL,
  SEND_OTP_SUCCESS,
  VERIFY_OTP,
  VERIFY_OTP_FAIL,
  VERIFY_OTP_SUCCESS,
  RESEND_OTP,
  RESEND_OTP_SUCCESS,
  RESEND_OTP_FAIL,
} from '../actions/otp';

function* sendOtp(action) {
  try {
    const response = yield call(API.sendOtp, action.payload);
    yield put({ type: SEND_OTP_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: SEND_OTP_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* verifyOtp(action) {
  try {
    const response = yield call(API.verifyOtp, action.payload);
    action.cb(response);
    yield put({ type: VERIFY_OTP_SUCCESS, payload: response.data });
  } catch (e) {
    action.cb(e);
    yield put({ type: VERIFY_OTP_FAIL, payload: { error: e } });
  }
}

function* resendOtp(action) {
  try {
    const response = yield call(API.resendOtp, action.payload);
    yield put({ type: RESEND_OTP_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: RESEND_OTP_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* otp() {
  yield takeLatest(SEND_OTP, sendOtp);
  yield takeLatest(VERIFY_OTP, verifyOtp);
  yield takeLatest(RESEND_OTP, resendOtp);
}

export default otp;
