import {
    FETCH_REPORT_LIST,
    FETCH_REPORT_LIST_SUCCESS,
    FETCH_REPORT_LIST_FAIL,
  } from 'actions/downloadAdvanceReport';
  
  const initialState = {
    isLoading: false,
    reportList: [],
    page: 1,
    limit: 10,
    totalPages: 1,
    total: 1,
  };
  const reportListReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case FETCH_REPORT_LIST: {
        return { ...state, isLoading: true };
      }
      case FETCH_REPORT_LIST_SUCCESS: {
        return {
          ...state,
          isLoading: false,
          reportList: payload?.results,
          page: payload?.page,
          limit: payload?.limit,
          totalPages: payload?.totalPages,
          total: payload?.totalResults,
        };
      }
  
      case FETCH_REPORT_LIST_FAIL: {
        return { ...state, isLoading: false };
      }
  
      default:
        return { ...state };
    }
  };
  
  export default reportListReducer;
  