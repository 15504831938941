export const FETCH_VEHICLE_MAKE = 'FETCH_VEHICLE_MAKE';
export const FETCH_VEHICLE_MAKE_SUCCESS = 'FETCH_VEHICLE_MAKE_SUCCESS';
export const FETCH_VEHICLE_MAKE_FAIL = 'FETCH_VEHICLE_MAKE_FAIL';

export const GET_VEHICLE_MAKE = 'GET_VEHICLE_MAKE_MAKE';
export const GET_VEHICLE_MAKE_SUCCESS = 'GET_VEHICLE_MAKE_SUCCESS';
export const GET_VEHICLE_MAKE_FAIL = 'GET_VEHICLE_MAKE_FAIL';

export const CREATE_VEHICLE_MAKE = 'CREATE_VEHICLE_MAKE';
export const CREATE_VEHICLE_MAKE_SUCCESS = 'CREATE_VEHICLE_MAKE_SUCCESS';
export const CREATE_VEHICLE_MAKE_FAIL = 'CREATE_VEHICLE_MAKE_FAIL';

export const UPDATE_VEHICLE_MAKE = 'UPDATE_VEHICLE_MAKE';
export const UPDATE_VEHICLE_MAKE_SUCCESS = 'UPDATE_VEHICLE_MAKE_SUCCESS';
export const UPDATE_VEHICLE_MAKE_FAIL = 'UPDATE_VEHICLE_MAKE_FAIL';

export const DELETE_VEHICLE_MAKE = 'DELETE_VEHICLE_MAKE';
export const DELETE_VEHICLE_MAKE_SUCCESS = 'DELETE_VEHICLE_MAKE_SUCCESS';
export const DELETE_VEHICLE_MAKE_FAIL = 'DELETE_VEHICLE_MAKE_FAIL';
