export const CREATE_COMMUNICATION_MANAGER = 'CREATE_COMMUNICATION_MANAGER';
export const CREATE_COMMUNICATION_MANAGER_SUCCESS = 'CREATE_COMMUNICATION_MANAGER_SUCCESS';
export const CREATE_COMMUNICATION_MANAGER_FAIL = 'CREATE_COMMUNICATION_MANAGER_FAIL';

export const FETCH_COMMUNICATION_MANAGER = 'FETCH_COMMUNICATION_MANAGER';
export const FETCH_COMMUNICATION_MANAGER_SUCCESS = 'FETCH_COMMUNICATION_MANAGER_SUCCESS';
export const FETCH_COMMUNICATION_MANAGER_FAIL = 'FETCH_COMMUNICATION_MANAGER_FAIL';

export const GET_COMMUNICATION_MANAGER = 'GET_COMMUNICATION_MANAGER';
export const GET_COMMUNICATION_MANAGER_SUCCESS = 'GET_COMMUNICATION_MANAGER_SUCCESS';
export const GET_COMMUNICATION_MANAGER_FAIL = 'GET_COMMUNICATION_MANAGER_FAIL';

export const UPDATE_COMMUNICATION_MANAGER = 'UPDATE_COMMUNICATION_MANAGER';
export const UPDATE_COMMUNICATION_MANAGER_SUCCESS = 'UPDATE_COMMUNICATION_MANAGER_SUCCESS';
export const UPDATE_COMMUNICATION_MANAGER_FAIL = 'UPDATE_COMMUNICATION_MANAGER_FAIL';

export const DELETE_COMMUNICATION_MANAGER = 'DELETE_COMMUNICATION_MANAGER';
export const DELETE_COMMUNICATION_MANAGER_SUCCESS = 'DELETE_COMMUNICATION_MANAGER_SUCCESS';
export const DELETE_COMMUNICATION_MANAGER_FAIL = 'DELETE_COMMUNICATION_MANAGER_FAIL';