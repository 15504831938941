import {
  FETCH_PRIVACY,
  FETCH_PRIVACY_SUCCESS,
  FETCH_PRIVACY_FAIL,
  GET_PRIVACY,
  GET_PRIVACY_SUCCESS,
  GET_PRIVACY_FAIL,
  CREATE_PRIVACY,
  CREATE_PRIVACY_SUCCESS,
  CREATE_PRIVACY_FAIL,
  UPDATE_PRIVACY,
  UPDATE_PRIVACY_FAIL,
  UPDATE_PRIVACY_SUCCESS,
  DELETE_PRIVACY,
  DELETE_PRIVACY_SUCCESS,
  DELETE_PRIVACY_FAIL,
} from 'actions/privacy';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';

function* fetchPrivacy(action) {
  try {
    const response = yield call(API.getPrivacy, action.payload);
    yield put({ type: FETCH_PRIVACY_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_PRIVACY_FAIL, payload: { error: e } });
  }
}

function* getPrivacyById(action) {
  try {
    const response = yield call(API.getPrivacyById, action.payload);
    yield put({ type: GET_PRIVACY_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_PRIVACY_FAIL, payload: { error: e } });
  }
}

function* createPrivacy(action) {
  try {
    const response = yield call(API.addPrivacy, action.payload);
    yield put({ type: CREATE_PRIVACY_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_PRIVACY_FAIL, payload: { error: e } });
  }
}

function* updatePrivacy(action) {
  try {
    const response = yield call(API.updatePrivacy, action.payload);
    yield put({ type: UPDATE_PRIVACY_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_PRIVACY_FAIL, payload: { error: e } });
  }
}

function* deletePrivacy(action) {
  try {
    const response = yield call(API.deletePrivacy, action.payload);
    yield put({ type: DELETE_PRIVACY_SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_PRIVACY_FAIL, payload: { error: e } });
  }
}

function* privacy() {
  yield takeLatest(FETCH_PRIVACY, fetchPrivacy);
  yield takeLatest(GET_PRIVACY, getPrivacyById);
  yield takeLatest(CREATE_PRIVACY, createPrivacy);
  yield takeLatest(UPDATE_PRIVACY, updatePrivacy);
  yield takeLatest(DELETE_PRIVACY, deletePrivacy);
}

export default privacy;
