import {
  FETCH_CHARGER_BOOKING,
  FETCH_CHARGER_BOOKING_SUCCESS,
  FETCH_CHARGER_BOOKING_FAIL,
  DELETE_CHARGER_BOOKING_FAIL,
  DELETE_CHARGER_BOOKING_SUCCESS,
  DELETE_CHARGER_BOOKING,
  START_REMOTE,
  START_REMOTE_SUCCESS,
  START_REMOTE_FAIL,
  GET_CHARGER_BOOKING,
  GET_CHARGER_BOOKING_SUCCESS,
  GET_CHARGER_BOOKING_FAIL,
} from 'actions/chargerBooking';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  chargerBookings: [],
  chargerBookingDetail: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const chargerBookingReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_CHARGER_BOOKING:
    case DELETE_CHARGER_BOOKING:
    case GET_CHARGER_BOOKING:
    case START_REMOTE: {
      return { ...state, isLoading: true };
    }
    case FETCH_CHARGER_BOOKING_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        chargerBookings: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case GET_CHARGER_BOOKING_SUCCESS: {
      return {
        ...state,
        chargerBookingDetail: payload,
        isLoading: false,
      };
    }

    case START_REMOTE_SUCCESS: {
      toast.success('Remote added successfully');
      return {
        ...state,
        isLoading: false,
      };
    }
    case DELETE_CHARGER_BOOKING_SUCCESS: {
      return {
        ...state,
        chargerBookings: state.chargerBookings.filter((data) => data.id !== payload),
      };
    }

    case DELETE_CHARGER_BOOKING_FAIL:
    case FETCH_CHARGER_BOOKING_FAIL:
    case GET_CHARGER_BOOKING_FAIL:
    case START_REMOTE_FAIL: {
      return { ...state, isLoading: false };
    }
    default:
      return { ...state };
  }
};
export default chargerBookingReducer;
