import {
  FETCH_FAQ,
  FETCH_FAQ_SUCCESS,
  FETCH_FAQ_FAIL,
  GET_FAQ,
  GET_FAQ_SUCCESS,
  GET_FAQ_FAIL,
  CREATE_FAQ,
  CREATE_FAQ_SUCCESS,
  CREATE_FAQ_FAIL,
  UPDATE_FAQ,
  UPDATE_FAQ_SUCCESS,
  UPDATE_FAQ_FAIL,
  DELETE_FAQ,
  DELETE_FAQ_SUCCESS,
  DELETE_FAQ_FAIL,
} from 'actions/faq';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';

function* fetchFaq(action) {
  try {
    const response = yield call(API.getFaq, action.payload);
    yield put({ type: FETCH_FAQ_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_FAQ_FAIL, payload: { error: e } });
  }
}

function* getFaqById(action) {
  try {
    const response = yield call(API.getFaqById, action.payload);
    yield put({ type: GET_FAQ_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_FAQ_FAIL, payload: { error: e } });
  }
}

function* createFaq(action) {
  try {
    const response = yield call(API.addFaq, action.payload);
    yield put({ type: CREATE_FAQ_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_FAQ_FAIL, payload: { error: e } });
  }
}

function* updateFaq(action) {
  try {
    const response = yield call(API.updateFaq, action.payload);
    yield put({ type: UPDATE_FAQ_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_FAQ_FAIL, payload: { error: e } });
  }
}

function* deleteFaq(action) {
  try {
    const response = yield call(API.deleteFaq, action.payload);
    yield put({ type: DELETE_FAQ_SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_FAQ_FAIL, payload: { error: e } });
  }
}

function* faq() {
  yield takeLatest(FETCH_FAQ, fetchFaq);
  yield takeLatest(GET_FAQ, getFaqById);
  yield takeLatest(CREATE_FAQ, createFaq);
  yield takeLatest(UPDATE_FAQ, updateFaq);
  yield takeLatest(DELETE_FAQ, deleteFaq);
}

export default faq;
