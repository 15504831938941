import React, { useCallback, useEffect, useState, useContext } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header';
import Card from 'components/inputs/Card';
import { Col, Row, Modal } from 'react-bootstrap';
import SearchBox from 'components/general/SearchBox';
import { BsSearch } from 'react-icons/bs';
import { BiSort } from 'react-icons/bi';
import Button from 'components/inputs/Button';
import * as _ from 'lodash';
// import { useHistory } from 'react-router-dom';
import ReactPagination from 'components/general/ReactPagination';
import { useDispatch, useSelector } from 'react-redux';
import { DELETE_RFID_GROUP, FETCH_RFID_GROUP, GET_RFID_GROUP } from 'actions/rfidGroup';
//import DropDown from 'components/inputs/DropDown';
import RfidGroupForm from './RfidGroupForm';
import DeleteModal from 'components/general/DeleteModal';
import { DELETE_STATUS_CODE, GET_RFIDS, MANAGE_RFIDS } from 'components/common/constant';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { FiEdit2 } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { MdOutlineManageAccounts } from 'react-icons/md';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Footer from 'components/general/Footer';
import { NavContext } from 'components/privateRoute/PrivateRoute';

const RfidGroupList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // const history = useHistory();
  const { navigateTo } = useContext(NavContext);
  const [searchText, setSearchText] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [rfidGroupId, setRfidGroupId] = useState('');
  const [pageWiseRfidGroupData, setPageWiseRfidGroupData] = useState([]);

  const allRfidGroup = useSelector((state) => state.rfidGroup.rfidGroup);
  const isLoader = useSelector((state) => state.rfidGroup.isLoading);
  const page = useSelector((state) => state.rfidGroup.page);
  const totalData = useSelector((state) => state.rfidGroup.total);
  const limit = useSelector((state) => state.rfidGroup.limit);
  const totalPages = useSelector((state) => state.rfidGroup.totalPages);
  const rolePermissionData = useSelector((state) => state.manageRole.manageRoles);
  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserName = _.get(profileData, 'name', '');

  //Functionality of showing data pagination wise
  const rfidGroupData = allRfidGroup.filter(({ id: id1 }) => pageWiseRfidGroupData.some(({ id: id2 }) => id2 === id1));

  const getRfidGroup = useCallback(
    (data) => {
      if (searchText) {
        const rfidGroupData = {
          ...data,
          search: searchText,
        };
        dispatch({
          type: FETCH_RFID_GROUP,
          payload: rfidGroupData,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                setPageWiseRfidGroupData(res.data.results);
              }
            }
          },
        });
      } else {
        dispatch({
          type: FETCH_RFID_GROUP,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                setPageWiseRfidGroupData(res.data.results);
              }
            }
          },
        });
      }
    },
    [searchText, pageWiseRfidGroupData]
  );

  const deleteRfidGroup = useCallback(
    () =>
      dispatch({
        type: DELETE_RFID_GROUP,
        payload: rfidGroupId,
        cb: (res) => {
          if (_.get(res, 'status', '') === DELETE_STATUS_CODE) {
            handleCloseDeleteModel();
            getRfidGroup({ page: 1 });
          }
        },
      }),
    [rfidGroupId]
  );

  const getRfidDetail = useCallback((id) => {
    dispatch({ type: GET_RFID_GROUP, payload: id });
  }, []);

  useEffect(() => {
    getRfidGroup();
  }, [searchText]);

  const handleSearch = () => {
    getRfidGroup({});
  };

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else {
        const data = {
          page: page.selected + 1,
        };
        getRfidGroup(data);
      }
    },
    [sortByItem]
  );

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getRfidGroup(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getRfidGroup(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handleCloseFormRfidGroup = () => {
    setShowForm(false);
    setIsEdit(false);
  };

  const handleCloseDeleteModel = () => {
    setShowDeleteModal(false);
    setRfidGroupId('');
  };

  const getRfidGroupPermissions =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === GET_RFIDS),
      (data) => data.name
    );

  const manageRfidGroupPermissions =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === MANAGE_RFIDS),
      (data) => data.name
    );

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.rfidMaster')} />
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
          <div className="page-content-wrapper scrollable">
            <div className="groupList-page">
              <Card>
                <div className={'mb-3 mt-3'}>
                  <span className="groupList-header-text">{t('rfidGroupForm.rfidGroups')}</span>
                </div>
                <div className="groupList-page-wrapper">
                  <Row className="groupList-search-box">
                    <Col
                      xl={!_.isEmpty(manageRfidGroupPermissions) ? 10 : 12}
                      lg={!_.isEmpty(manageRfidGroupPermissions) ? 8 : 12}
                      md={!_.isEmpty(manageRfidGroupPermissions) ? 7 : 12}
                      className="groupList-search-inputBox"
                    >
                      <SearchBox
                        preIcon={<BsSearch />}
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            handleSearch();
                          }
                        }}
                      />
                    </Col>
                    {(!_.isEmpty(manageRfidGroupPermissions) || (!_.isEmpty(getRfidGroupPermissions) && !_.isEmpty(manageRfidGroupPermissions))) && (
                      <Col xl={2} lg={4} md={5} className="groupList-search-boxButton">
                        <Button
                          className="groupList-search-box-Btn"
                          onClick={() => {
                            setShowForm(true);
                            setIsEdit(false);
                          }}
                        >
                          {t('button.addGroup')}
                        </Button>
                      </Col>
                    )}
                  </Row>
                  <div className="groupList-table">
                    <div className="table-responsive">
                      <table className="record-list-table">
                        <thead>
                          <tr>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('rfidGroupForm.srNo')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('rfidGroupForm.groupName')}</span>
                                <span className="ico" onClick={() => handleSorting('name', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('rfidGroupForm.noOfRFIDCards')}</span>
                                <span className="ico" onClick={() => handleSorting('number_of_cards', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('rfidGroupForm.cashBackOffer')}</span>
                                <span className="ico" onClick={() => handleSorting('cashback_percent', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('rfidGroupForm.offerExpiryDate')}</span>
                                <span className="ico" onClick={() => handleSorting('offer_expiry_date', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th className="action-col">
                              <span>{isLoader ? <Skeleton height={15} width={100} /> : t('rfidGroupForm.action')}</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoader ? (
                            <tr>
                              <td colSpan={10} className="border-0">
                                <div className="loader--block">
                                  <Skeleton height={15} width={100} />
                                </div>
                              </td>
                            </tr>
                          ) : _.isEmpty(rfidGroupData) ? (
                            <tr>
                              <td colSpan={6} className="border-0">
                                <div className="empty-data-block">{t('rfidGroupForm.noData')}</div>
                              </td>
                            </tr>
                          ) : (
                            !(isLoader || _.isUndefined(isLoader)) &&
                            _.map(rfidGroupData, (item, key) => {
                              const serial_num = limit * (page - 1) + key;
                              return (
                                <tr key={`rfid-group-${serial_num}`}>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : serial_num + 1}.</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'name', '')}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'number_of_cards', '')}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'cashback_percent', '')} % </td>
                                  <td>
                                    {isLoader ? (
                                      <Skeleton height={15} width={100} />
                                    ) : item.offer_expiry_date ? (
                                      moment(_.get(item, 'offer_expiry_date', '')).format('DD MMM YYYY')
                                    ) : (
                                      '-'
                                    )}
                                  </td>
                                  <td>
                                    <div key={`action-${key}`} className="rfid-group">
                                      <span className="list-icon">
                                        <ul>
                                          {(!_.isEmpty(manageRfidGroupPermissions) ||
                                            (!_.isEmpty(getRfidGroupPermissions) && !_.isEmpty(manageRfidGroupPermissions))) && (
                                            <li
                                              className="item"
                                              onClick={() => {
                                                setShowForm(true);
                                                getRfidDetail(_.get(item, 'id', ''));
                                                setIsEdit(true);
                                              }}
                                            >
                                              {isLoader ? <Skeleton height={15} width={100} /> : <FiEdit2 title="Edit" size={22} color="#3c7cdd" />}
                                            </li>
                                          )}
                                          <li className="item" onClick={() => navigateTo(`/rfidList/${_.get(item, 'id')}`)}>
                                            {isLoader ? <Skeleton height={15} width={100} /> : <MdOutlineManageAccounts title="Configure" size={22} color="#87cefa" />}
                                          </li>
                                          {(!_.isEmpty(manageRfidGroupPermissions) ||
                                            (!_.isEmpty(getRfidGroupPermissions) && !_.isEmpty(manageRfidGroupPermissions))) && (
                                            <li
                                              className="item"
                                              onClick={() => {
                                                setShowDeleteModal(true);
                                                setRfidGroupId(_.get(item, 'id', ''));
                                              }}
                                            >
                                              {isLoader ? <Skeleton height={15} width={100} /> : <RiDeleteBinLine title="Delete" size={22} color="#be210b" />}
                                            </li>
                                          )}
                                        </ul>
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(rfidGroupData) && (
                    <ReactPagination
                      currentPage={page}
                      limit={limit}
                      total={totalData}
                      handlePageClick={(pageVal) => handlePageClick(pageVal)}
                      totalPages={totalPages}
                      marginPagesDisplayed={1}
                    />
                  )}
                </div>
              </Card>
            </div>
          </div>
        </SkeletonTheme>
        {showForm && (
          <Modal show={showForm} size="lg" centered>
            <RfidGroupForm onClose={handleCloseFormRfidGroup} isEdit={isEdit} onSuccess={getRfidGroup} />
          </Modal>
        )}
        {showDeleteModal && (
          <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
            <DeleteModal title="Group" onClose={handleCloseDeleteModel} onRemove={deleteRfidGroup} />
          </Modal>
        )}
        {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
      </LayoutContainer>
    </>
  );
};
export default RfidGroupList;
