import { post, get, put, patch, deleteRequest } from 'utils/api';

export const addCpo = (data) => {
  return post(`/ocpi_credential`, data);
};

export const getCpoData = (data) => {
  return get(`/ocpi_credential/${data}`);
};

export const fetchCpoData = (data) => {
  return get(`/ocpi_credential`, data);
};

export const updateCpo = (item) => {
  const { data, roleId } = item;
  return patch(`/ocpi_credential/${roleId}`, data);
};

export const removeCpo = (id) => {
  return deleteRequest(`/ocpi_credential/${id}`);
};

// OCPI Admin operation

export const handshake = (data) => {
  return post(`/ocpi_credential/handshake/${data}`);
};

export const getEndpointByToken = (data) => {
  return get(`/ocpi_client_endpoint/${data}`);
};

export const pullLucations = (data) => {
  return post(`/ocpi/syncLocations/${data}`);
};

export const pullTariffs = (data) => {
  return post(`/ocpi/syncTariffs/${data}`);
};

export const pullSession = (data) => {
  const { id, dateFrom, dateTo } = data;
  const item = { dateFrom, dateTo };
  return put(`/ocpi/syncSessions/${id}`, item);
};

export const pullCdrs = (data) => {
  const { id, dateFrom, dateTo } = data;
  const item = { dateFrom, dateTo };
  return post(`/ocpi/syncCdrs/${id}`, item);
};

export const token = (data) => {
  return post(`/ocpi_token/syncTokens/${data}`);
};

export const partnerManagement = {
  addCpo,
  getCpoData,
  fetchCpoData,
  updateCpo,
  removeCpo,
  handshake,
  getEndpointByToken,
  pullLucations,
  pullTariffs,
  pullSession,
  pullCdrs,
  token,
};
