import React, { useEffect, useState, useCallback } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { BsDownload, BsSearch } from 'react-icons/bs';
import * as _ from 'lodash';
import SearchBox from 'components/general/SearchBox';
import { DOWNLOAD_WALLET_TRANSACTION_REPORT, WALLET_TRANSACTION_REPORT } from 'actions/dataReport';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import Card from 'components/inputs/Card';
import Button from 'components/inputs/Button';
import { roundOfDigit } from 'components/common/utils';
import { IoSearchOutline } from 'react-icons/io5';
import DateTimePicker from 'components/inputs/DateTimePicker';
import { BiSort } from 'react-icons/bi';
import fileDownload from 'js-file-download';
import { FETCH_CHARGING_STATION } from 'actions/chargingStation';
import { MdClear } from 'react-icons/md';
import { Form, Formik } from 'formik';
import Select from 'components/inputs/Select';
import OffCanvas from 'components/inputs/OffCanvas';
import ReactPagination from 'components/general/ReactPagination';
import { UPDATE_STATUS_CODE } from 'components/common/constant';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { FaFilter } from 'react-icons/fa';
import Footer from 'components/general/Footer';

const WalletTransactionReport = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [searchText, setSearchText] = useState('');
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [startDate, setStartDate] = useState(moment().startOf('month'));
  const [endDate, setEndDate] = useState(moment(new Date()).endOf('day'));
  const [showAdvanceFilterPopup, setShowAdvanceFilterPopup] = useState(false);
  const [advanceFilterData, setAdvanceFilterData] = useState({});
  const [changeStation, setChangeStation] = useState(false);
  const [changeWalletType, setChangeWalletType] = useState(false);
  const [changePaymentType, setChangePaymentType] = useState(false);

  const walletTransactionReportList = useSelector((state) => state.walletTransactionReport.walletTransactionReports);
  const isLoader = useSelector((state) => state.walletTransactionReport.isLoading);
  const page = useSelector((state) => state.walletTransactionReport.page);
  const totalData = useSelector((state) => state.walletTransactionReport.total);
  const limit = useSelector((state) => state.walletTransactionReport.limit);
  const totalPages = useSelector((state) => state.walletTransactionReport.totalPages);
  const isLoading = useSelector((state) => state.downloadingLoader.isLoading);
  const profileData = useSelector((state) => state.profile.userProfile);
  const userProfileStations = profileData.charging_stations;
  const currentUserName = _.get(profileData, 'name', '');

  const walletTransactionReport = useCallback(
    (value) => {
      if (searchText) {
        const walletTransactionReportData = {
          ...value,
          search: searchText,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        dispatch({
          type: WALLET_TRANSACTION_REPORT,
          payload: walletTransactionReportData,
          cb: (res) => {
            if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
              onCloseAdvanceFilterPopup();
            }
          },
        });
      } else if (!_.isEmpty(userProfileStations)) {
        const data = {
          ...value,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          charging_station: userProfileStations,
        };
        dispatch({
          type: WALLET_TRANSACTION_REPORT,
          payload: data,
          cb: (res) => {
            if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
              onCloseAdvanceFilterPopup();
            }
          },
        });
      } else {
        const data = {
          ...value,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        dispatch({
          type: WALLET_TRANSACTION_REPORT,
          payload: data,
          cb: (res) => {
            if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
              onCloseAdvanceFilterPopup();
            }
          },
        });
      }
    },
    [startDate, endDate, searchText]
  );

  const dateFilter = useCallback(() => {
    if (sortByItem.item && sortByItem.order) {
      const filterDateData = {
        from: moment(startDate).utc(),
        to: moment(endDate).utc(),
        charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
      };
      getSortByItem(sortByItem.item, sortByItem.order, '', filterDateData);
    } else if (!_.isEmpty(advanceFilterData)) {
      if (advanceFilterData.charging_station === 'All' && advanceFilterData.wallet_type === 'All' && advanceFilterData.type === 'All') {
        const filterData = {
          ...advanceFilterData,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
          wallet_type: '',
          type: '',
        };
        const data = {
          ...filterData,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        walletTransactionReport(data);
      } else if (advanceFilterData.charging_station === 'All' && advanceFilterData.wallet_type === 'All') {
        const filterData = { ...advanceFilterData, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '', wallet_type: '' };
        const data = {
          ...filterData,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        walletTransactionReport(data);
      } else if (advanceFilterData.charging_station === 'All' && advanceFilterData.type === 'All') {
        const filterData = { ...advanceFilterData, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '', type: '' };
        const data = {
          ...filterData,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        walletTransactionReport(data);
      } else if (advanceFilterData.wallet_type === 'All' && advanceFilterData.type === 'All') {
        const filterData = { ...advanceFilterData, wallet_type: '', type: '' };
        const data = {
          ...filterData,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        walletTransactionReport(data);
      } else if (advanceFilterData.charging_station === 'All') {
        const filterData = { ...advanceFilterData, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
        const data = {
          ...filterData,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        walletTransactionReport(data);
      } else if (advanceFilterData.type === 'All') {
        const filterData = { ...advanceFilterData, type: '' };
        const data = {
          ...filterData,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        walletTransactionReport(data);
      } else if (advanceFilterData.wallet_type === 'All') {
        const filterData = { ...advanceFilterData, wallet_type: '' };
        const data = {
          ...filterData,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        walletTransactionReport(data);
      } else {
        const data = {
          ...advanceFilterData,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        walletTransactionReport(data);
      }
    } else if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
      const filterDateData = {
        from: moment(startDate).utc(),
        to: moment(endDate).utc(),
        charging_station: userProfileStations,
      };
      walletTransactionReport(filterDateData);
    } else {
      const filterDateData = {
        from: moment(startDate).utc(),
        to: moment(endDate).utc(),
      };
      walletTransactionReport(filterDateData);
    }
  }, [startDate, endDate, sortByItem, advanceFilterData, searchText]);

  const searchHandler = (event) => {
    const value = event.target.value;
    setSearchText(value);
  };

  const handleSearch = () => {
    walletTransactionReport({});
  };

  useEffect(() => {
    if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
      const data = {
        charging_station: userProfileStations,
      };
      walletTransactionReport(data);
    } else {
      walletTransactionReport();
    }
  }, []);

  const getSortByItem = useCallback(
    (name, order, page, filterDateData) => {
      const sortedOrder = order || 'asc';
      if (page && startDate && endDate) {
        const data = {
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        endDate && walletTransactionReport(data);
      } else if (page) {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        walletTransactionReport(data);
      } else if (filterDateData) {
        const data = {
          ...filterDateData,
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        endDate && walletTransactionReport(data);
      } else {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        walletTransactionReport(data);
      }
    },
    [startDate, endDate]
  );

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else if (!_.isEmpty(advanceFilterData)) {
        if (advanceFilterData.charging_station === 'All' && advanceFilterData.wallet_type === 'All' && advanceFilterData.type === 'All') {
          const filterData = {
            ...advanceFilterData,
            charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
            wallet_type: '',
            type: '',
          };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).utc(),
            to: moment(endDate).utc(),
          };
          walletTransactionReport(data);
        } else if (advanceFilterData.charging_station === 'All' && advanceFilterData.wallet_type === 'All') {
          const filterData = { ...advanceFilterData, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '', wallet_type: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).utc(),
            to: moment(endDate).utc(),
          };
          walletTransactionReport(data);
        } else if (advanceFilterData.charging_station === 'All' && advanceFilterData.type === 'All') {
          const filterData = { ...advanceFilterData, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '', type: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).utc(),
            to: moment(endDate).utc(),
          };
          walletTransactionReport(data);
        } else if (advanceFilterData.wallet_type === 'All' && advanceFilterData.type === 'All') {
          const filterData = { ...advanceFilterData, wallet_type: '', type: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).utc(),
            to: moment(endDate).utc(),
          };
          walletTransactionReport(data);
        } else if (advanceFilterData.charging_station === 'All') {
          const filterData = { ...advanceFilterData, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).utc(),
            to: moment(endDate).utc(),
          };
          walletTransactionReport(data);
        } else if (advanceFilterData.type === 'All') {
          const filterData = { ...advanceFilterData, type: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).utc(),
            to: moment(endDate).utc(),
          };
          walletTransactionReport(data);
        } else if (advanceFilterData.wallet_type === 'All') {
          const filterData = { ...advanceFilterData, wallet_type: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).utc(),
            to: moment(endDate).utc(),
          };
          walletTransactionReport(data);
        } else {
          const data = {
            ...advanceFilterData,
            page: page.selected + 1,
            from: moment(startDate).utc(),
            to: moment(endDate).utc(),
          };
          walletTransactionReport(data);
        }
      } else if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
        const filterDateData = {
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          charging_station: userProfileStations,
          page: page.selected + 1,
        };
        walletTransactionReport(filterDateData);
      } else if (startDate && endDate && searchText) {
        const data = {
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          search: searchText,
          page: page.selected + 1,
        };
        walletTransactionReport(data);
      } else if (startDate && endDate) {
        const data = {
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          page: page.selected + 1,
        };
        walletTransactionReport(data);
      } else {
        const data = {
          page: page.selected + 1,
        };
        walletTransactionReport(data);
      }
    },
    [sortByItem, startDate, endDate, advanceFilterData, searchText]
  );
  // Download Excel Functionality

  const downloadWalletTransactionReport = useCallback(() => {
    if (!_.isEmpty(advanceFilterData)) {
      if (advanceFilterData.charging_station === 'All' && advanceFilterData.wallet_type === 'All' && advanceFilterData.type === 'All') {
        const filterData = {
          ...advanceFilterData,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
          wallet_type: '',
          type: '',
        };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        dispatch({
          type: DOWNLOAD_WALLET_TRANSACTION_REPORT,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Wallet Transaction Report'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.charging_station === 'All' && advanceFilterData.wallet_type === 'All') {
        const filterData = { ...advanceFilterData, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '', wallet_type: '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        dispatch({
          type: DOWNLOAD_WALLET_TRANSACTION_REPORT,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Wallet Transaction Report'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.charging_station === 'All' && advanceFilterData.type === 'All') {
        const filterData = { ...advanceFilterData, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '', type: '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        dispatch({
          type: DOWNLOAD_WALLET_TRANSACTION_REPORT,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Wallet Transaction Report'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.wallet_type === 'All' && advanceFilterData.type === 'All') {
        const filterData = { ...advanceFilterData, wallet_type: '', type: '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        dispatch({
          type: DOWNLOAD_WALLET_TRANSACTION_REPORT,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Wallet Transaction Report'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.charging_station === 'All') {
        const filterData = { ...advanceFilterData, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        dispatch({
          type: DOWNLOAD_WALLET_TRANSACTION_REPORT,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Wallet Transaction Report'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.type === 'All') {
        const filterData = { ...advanceFilterData, type: '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        dispatch({
          type: DOWNLOAD_WALLET_TRANSACTION_REPORT,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Wallet Transaction Report'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.wallet_type === 'All') {
        const filterData = { ...advanceFilterData, wallet_type: '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        dispatch({
          type: DOWNLOAD_WALLET_TRANSACTION_REPORT,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Wallet Transaction Report'}.xlsx`);
              }
            }
          },
        });
      } else {
        const data = {
          ...advanceFilterData,
          excel: true,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        dispatch({
          type: DOWNLOAD_WALLET_TRANSACTION_REPORT,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Wallet Transaction Report'}.xlsx`);
              }
            }
          },
        });
      }
    } else if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
      const data = {
        excel: true,
        from: moment(startDate).utc(),
        to: moment(endDate).utc(),
        charging_station: userProfileStations,
      };
      dispatch({
        type: DOWNLOAD_WALLET_TRANSACTION_REPORT,
        payload: data,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              fileDownload(res.data, `${'Wallet Transaction Report'}.xlsx`);
            }
          }
        },
      });
    } else {
      const data = {
        excel: true,
        from: moment(startDate).utc(),
        to: moment(endDate).utc(),
      };
      dispatch({
        type: DOWNLOAD_WALLET_TRANSACTION_REPORT,
        payload: data,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              fileDownload(res.data, `${'Wallet Transaction Report'}.xlsx`);
            }
          }
        },
      });
    }
  }, [startDate, endDate, advanceFilterData]);

  const downloadFile = () => {
    downloadWalletTransactionReport();
  };

  const onCloseAdvanceFilterPopup = () => setShowAdvanceFilterPopup(false);

  const search = (
    <SearchBox
      preIcon={<BsSearch />}
      value={searchText}
      onChange={searchHandler}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          handleSearch();
        }
      }}
    />
  );
  const searchIcon = <IoSearchOutline size={27} onClick={() => setShowSearchBar(true)} className="report-search-icon" />;

  const allStation = useSelector((state) => state.chargingStation.chargingStations);
  const handleAdvanceFilterData = (data) => setAdvanceFilterData(data);
  const stationList = allStation.filter((x) => userProfileStations.indexOf(x.id) !== -1);

  const initialValues = !_.isEmpty(advanceFilterData) ? { ...advanceFilterData } : { charging_station: '', wallet_type: '', type: '' };

  const getAllStation = useCallback((data = {}) => {
    const stationData = {
      ...data,
      request_all: true,
    };
    dispatch({ type: FETCH_CHARGING_STATION, payload: stationData });
  }, []);

  useEffect(() => {
    getAllStation();
  }, []);

  const allWalletType = [
    { label: 'Book', value: 'Book' },
    { label: 'Booking Cancellation', value: 'Booking Cancellation' },
    { label: 'CMS Credit', value: 'CMS CREDIT' },
    { label: 'Refund', value: 'Refund' },
    { label: 'Online Credit', value: 'WalletTopup' },
    { label: 'Cash Back', value: 'Cashback' },
    { label: 'Extra Unit Consumed', value: 'ExtraUnits' },
  ];

  const allPaymentType = [
    { label: 'Debit', value: 'debit' },
    { label: 'Credit', value: 'credit' },
  ];

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.walletTransactionReport')} />
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
          <div className="page-content-wrapper scrollable">
            <div className="data-report--main">
              <Card>
                <div className="data-report__inner">
                  <div className="data-report__box">
                    <Row className="data-report__row">
                      <Col
                        xl={`${showSearchBar ? 'auto' : 'auto'}`}
                        md={`${showSearchBar ? 'auto' : 'auto'}`}
                        className={`${showSearchBar ? '' : 'data-report-search-box'}`}
                      >
                        {showSearchBar ? search : searchIcon}
                      </Col>
                      <Col xl={`${showSearchBar ? 'auto' : 'auto'}`} md={`${showSearchBar ? 'auto' : 'auto'}`}>
                        <div className="data-report-date_picker">
                          <DateTimePicker
                            onChangeOfStartDate={(item) => setStartDate(item)}
                            onChangeOfEndDate={(item) => setEndDate(item)}
                            initialValueOfStartDate={moment().startOf('month')}
                            initialValueOfEndDate={moment(new Date()).endOf('day')}
                          />
                        </div>
                      </Col>
                      <Col xl={'auto'} md={'auto'}>
                        <div className="report-search-box" onClick={dateFilter}>
                          <Button className="report-search-button">{t('button.search')}</Button>
                        </div>
                      </Col>
                      <Col xl={'auto'} md={'auto'}>
                        <div className="report-search-excel-block">
                          <div className="advance-filter-btn" onClick={() => setShowAdvanceFilterPopup(true)}>
                            <Button className="report-search-button">
                              <FaFilter className="hide-lap" />
                              <span>{t('button.advancedFilter')}</span>
                            </Button>
                          </div>
                        </div>
                      </Col>
                      <Col xl={'auto'} md={'auto'} className="excel-icon--block" onClick={downloadFile}>
                        {isLoading ? (
                          <div className="loader--block">
                            <Spinner as="span" animation="border" size="sm" role="status" />
                          </div>
                        ) : (
                          <div className="report-excel-icon">
                            <BsDownload title="Download" size={28} color={'#3c7cdd'} />
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                  <div className="data-report__table">
                    <div className="table-responsive">
                      <table className="record-list-table" id="table-to-xls">
                        <thead>
                          <tr>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addWalletTransactionReport.srNo')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addWalletTransactionReport.walletID')}</span>
                                <span className="ico" onClick={() => handleSorting('id', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addWalletTransactionReport.userName')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addWalletTransactionReport.mobileNo')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addWalletTransactionReport.stationName')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addWalletTransactionReport.chargerID')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addWalletTransactionReport.bookingId')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addWalletTransactionReport.paymentType')}</span>
                                <span className="ico" onClick={() => handleSorting('type', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addWalletTransactionReport.walletAmount')}</span>
                                <span className="ico" onClick={() => handleSorting('current_balance', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addWalletTransactionReport.transactionAmount')}</span>
                                <span className="ico" onClick={() => handleSorting('amount', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addWalletTransactionReport.balanceAmount')}</span>
                                <span className="ico" onClick={() => handleSorting('current_balance', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addWalletTransactionReport.walletType')}</span>
                                <span className="ico" onClick={() => handleSorting('wallet_type', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addWalletTransactionReport.transactionDate')}</span>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoader ? (
                            <tr>
                              <td colSpan={11} className="border-0">
                                <div className="loader--block">
                                  <Skeleton height={15} width={100} />
                                </div>
                              </td>
                            </tr>
                          ) : _.isEmpty(walletTransactionReportList) ? (
                            <tr>
                              <td colSpan={10} className="border-0">
                                <div className="empty-data-block">{t('addWalletTransactionReport.noHistoryFound')}</div>
                              </td>
                            </tr>
                          ) : (
                            !(isLoader || _.isUndefined(isLoader)) &&
                            _.map(walletTransactionReportList, (item, key) => {
                              const connectorData = _.find(item && item.booking ? _.get(item, 'booking.charger.plugs', '') : '', {
                                connector_id: item && item.booking ? item.booking.connectorId : '',
                              });
                              const serial_num = limit * (page - 1) + key;
                              return (
                                <>
                                  <tr key={`wallet-transaction-report-${serial_num}`}>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : serial_num + 1}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'id', '')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'user.name', 'Guest User')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'user.phone', '')}</td>
                                    <td>
                                      {isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'booking.charger.charging_station.name', '')}
                                    </td>
                                    <td>
                                      {isLoader ? (
                                        <Skeleton height={15} width={100} />
                                      ) : (
                                        <>
                                          {_.get(item, 'booking.charger.charger_id', '')} ({_.get(connectorData, 'name', '')})
                                        </>
                                      )}
                                    </td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'booking.id', '')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'type', '')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : roundOfDigit(_.get(item, 'current_balance', ''), 2)}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : roundOfDigit(_.get(item, 'amount', ''), 2)}</td>
                                    <td>
                                      {isLoader ? (
                                        <Skeleton height={15} width={100} />
                                      ) : (
                                        <>
                                          {' '}
                                          {_.get(item, 'type', '') === 'credit'
                                            ? roundOfDigit(_.get(item, 'current_balance', 0) + _.get(item, 'amount', ''), 2)
                                            : roundOfDigit(_.get(item, 'current_balance', 0) - _.get(item, 'amount', ''), 2)}
                                        </>
                                      )}
                                    </td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'wallet_type', '')}</td>
                                    <td>
                                      {isLoader ? (
                                        <Skeleton height={15} width={100} />
                                      ) : item.createdAt ? (
                                        moment(item.createdAt).format('DD/MM/YYYY  H:mm:ss')
                                      ) : (
                                        ''
                                      )}
                                    </td>
                                  </tr>
                                </>
                              );
                            })
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(walletTransactionReportList) && (
                    <ReactPagination
                      currentPage={page}
                      limit={limit}
                      total={totalData}
                      handlePageClick={(pageVal) => handlePageClick(pageVal)}
                      totalPages={totalPages}
                      marginPagesDisplayed={1}
                    />
                  )}
                </div>
              </Card>
            </div>
          </div>
        </SkeletonTheme>
        {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
      </LayoutContainer>

      {/* Report Advance Filter Functionality */}

      {showAdvanceFilterPopup && (
        <OffCanvas show={showAdvanceFilterPopup} onClose={onCloseAdvanceFilterPopup}>
          <Formik
            enableReinitialize={!_.isEmpty(advanceFilterData)}
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
              !values.charging_station && delete values['charging_station'];
              !values.wallet_type && delete values['wallet_type'];
              !values.type && delete values['type'];
              if (values.charging_station === 'All' && values.wallet_type === 'All' && values.type === 'All') {
                const walletTransactionReportData = {
                  ...values,
                  charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
                  wallet_type: '',
                  type: '',
                };
                walletTransactionReport(walletTransactionReportData);
              } else if (values.charging_station === 'All' && values.wallet_type === 'All') {
                const walletTransactionReportData = {
                  ...values,
                  charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
                  wallet_type: '',
                };
                walletTransactionReport(walletTransactionReportData);
              } else if (values.charging_station === 'All' && values.type === 'All') {
                const walletTransactionReportData = {
                  ...values,
                  charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
                  type: '',
                };
                walletTransactionReport(walletTransactionReportData);
              } else if (values.wallet_type === 'All' && values.type === 'All') {
                const walletTransactionReportData = { ...values, wallet_type: '', type: '' };
                walletTransactionReport(walletTransactionReportData);
              } else if (values.charging_station === 'All') {
                const walletTransactionReportData = { ...values, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
                walletTransactionReport(walletTransactionReportData);
              } else if (values.wallet_type === 'All') {
                const walletTransactionReportData = { ...values, wallet_type: '' };
                walletTransactionReport(walletTransactionReportData);
              } else if (values.type === 'All') {
                const walletTransactionReportData = { ...values, type: '' };
                walletTransactionReport(walletTransactionReportData);
              } else {
                walletTransactionReport(values);
              }
              handleAdvanceFilterData(values);
              setSubmitting(false);
            }}
          >
            {({ values, handleSubmit, setFieldValue, resetForm }) => (
              <Form onSubmit={handleSubmit}>
                <div className="mb-5">
                  <div className="canvas-selection__block">
                    <Select
                      label={t('filters.station')}
                      options={
                        !_.isEmpty(stationList)
                          ? !changeStation
                            ? !_.isEmpty(stationList) && [
                                { label: 'All', value: 'All' },
                                ..._.map(stationList, (station) => {
                                  return { label: station.name, value: station.id };
                                }),
                              ]
                            : !_.isEmpty(stationList) && [{ label: 'All', value: 'All' }]
                          : !changeStation
                          ? !_.isEmpty(allStation) && [
                              { label: 'All', value: 'All' },
                              ..._.map(allStation, (station) => {
                                return { label: station.name, value: station.id };
                              }),
                            ]
                          : !_.isEmpty(allStation) && [{ label: 'All', value: 'All' }]
                      }
                      placeholder={t('placeHolder.selectStation')}
                      name="charging_station"
                      isMulti
                      value={values.charging_station}
                      onMenuScrollDown={true}
                      onChange={(val) => {
                        if (_.includes(val, 'All')) {
                          setChangeStation(true);
                          setFieldValue(`charging_station`, 'All');
                        } else if (_.isEmpty(val)) {
                          setFieldValue(`charging_station`, '');
                          setChangeStation(false);
                        } else {
                          setChangeStation(false);
                          setFieldValue('charging_station', val);
                        }
                      }}
                    />
                    <Select
                      label={t('filters.walletType')}
                      options={
                        !changeWalletType
                          ? !_.isEmpty(allWalletType) && [
                              { label: 'All', value: 'All' },
                              ..._.map(allWalletType, (item) => {
                                return { label: item.label, value: item.value };
                              }),
                            ]
                          : !_.isEmpty(allWalletType) && [{ label: 'All', value: 'All' }]
                      }
                      placeholder={t('placeHolder.selectWalletType')}
                      name="wallet_type"
                      isMulti
                      value={values.wallet_type}
                      onMenuScrollDown={true}
                      onChange={(val) => {
                        if (_.includes(val, 'All')) {
                          setChangeWalletType(true);
                          setFieldValue(`wallet_type`, 'All');
                        } else if (_.isEmpty(val)) {
                          setFieldValue(`wallet_type`, '');
                          setChangeWalletType(false);
                        } else {
                          setChangeWalletType(false);
                          setFieldValue('wallet_type', val);
                        }
                      }}
                    />
                    <Select
                      label={t('filters.paymentType')}
                      options={
                        !changePaymentType
                          ? !_.isEmpty(allPaymentType) && [
                              { label: 'All', value: 'All' },
                              ..._.map(allPaymentType, (item) => {
                                return { label: item.label, value: item.value };
                              }),
                            ]
                          : !_.isEmpty(allPaymentType) && [{ label: 'All', value: 'All' }]
                      }
                      placeholder={t('placeHolder.selectPaymentType')}
                      name="type"
                      isMulti
                      value={values.type}
                      onMenuScrollDown={true}
                      onChange={(val) => {
                        if (_.includes(val, 'All')) {
                          setChangePaymentType(true);
                          setFieldValue(`type`, 'All');
                        } else if (_.isEmpty(val)) {
                          setFieldValue(`type`, '');
                          setChangePaymentType(false);
                        } else {
                          setChangePaymentType(false);
                          setFieldValue('type', val);
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="canvas-btn__block">
                  <Button type="submit" className="apply--btn w-100">
                    {t('button.applyFilters')}
                  </Button>
                  <div
                    className="reset--block"
                    onClick={() => {
                      setAdvanceFilterData({});
                      walletTransactionReport();
                      resetForm();
                      onCloseAdvanceFilterPopup();
                    }}
                  >
                    <MdClear size={24} className="reset--icon" />
                    {t('button.clear')}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </OffCanvas>
      )}
    </>
  );
};

export default WalletTransactionReport;
