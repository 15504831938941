import React, { useCallback, useEffect, useState, useContext } from 'react';
import * as _ from 'lodash';
import Select from 'components/inputs/Select';
import Avatar from 'components/inputs/Avatar';
import { RiDeleteBinLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_USER, CHANGE_ROLE, DELETE_USER } from 'actions/user';
// import Pagination from 'components/general/Pagination';
import ReactPagination from 'components/general/ReactPagination';
import { TENANT_ADMIN, SUPER_ADMIN } from 'components/common/constant';
import { Modal, Spinner } from 'react-bootstrap';
import DeleteModal from 'components/general/DeleteModal';
import { FETCH_ROLE } from 'actions/role';
import { BiSort } from 'react-icons/bi';
import { FaChargingStation } from 'react-icons/fa';
// import { useHistory } from 'react-router';
import { UPDATE_STATUS_CODE } from 'components/common/constant';
import { Col, Row } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
import SearchBox from 'components/general/SearchBox';
import Button from 'components/inputs/Button';
import { MdClear } from 'react-icons/md';
import { NavContext } from 'components/privateRoute/PrivateRoute';
import { useTranslation } from 'react-i18next';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header';
import Card from 'components/inputs/Card';
import { AiOutlinePlus } from 'react-icons/ai';

const AccessManagement = () => {
  const dispatch = useDispatch();
  // const history = useHistory();
  const { navigateTo, isAllowed } = useContext(NavContext);
  const [userId, setUserId] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const { t } = useTranslation();
  const profileData = useSelector((state) => state.profile.userProfile);
  const isLoader = useSelector((state) => state.user.isLoading);
  const allUsers = useSelector((state) => state.user.users);
  const allRoles = useSelector((state) => state.role.roles);
  const page = useSelector((state) => state.user.page);
  const totalData = useSelector((state) => state.user.total);
  const limit = useSelector((state) => state.user.limit);
  const totalPages = useSelector((state) => state.user.totalPages);

  const handleCloseDeleteModel = () => setShowDeleteModal(false);

  const getAllUsers = useCallback(
    (data = {}) => {
      if (searchText) {
        const userData = {
          ...data,
          search: searchText,
        };
        dispatch({
          type: FETCH_USER,
          payload: userData,
        });
      } else {
        dispatch({
          type: FETCH_USER,
          payload: data,
        });
      }
    },
    [searchText]
  );

  const getAllRoles = useCallback((data) => {
    const roleData = {
      ...data,
      limit: 50,
      is_hidden: false,
    };
    dispatch({ type: FETCH_ROLE, payload: roleData });
  }, []);

  const dataFilter = useCallback(() => {
    if (searchText) {
      const filterDateData = {
        search: searchText,
      };
      getAllUsers(filterDateData);
    } else {
      getAllUsers();
    }
  }, [searchText]);

  const deleteUser = useCallback((id) => {
    dispatch({ type: DELETE_USER, payload: id });
    handleCloseDeleteModel();
    getAllUsers();
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }, []);

  const editUserRole = useCallback(
    (role, userId) => {
      const userRole = {
        id: userId,
        role: role,
      };
      dispatch({
        type: CHANGE_ROLE,
        payload: userRole,
        cb: (res) => {
          if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
            getAllUsers({ page });
          }
        },
      });
    },
    [allUsers]
  );

  useEffect(() => {
    getAllUsers();
    getAllRoles();
  }, []);

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else if (searchText) {
        const data = {
          search: searchText,
          page: page.selected + 1,
        };
        getAllUsers(data);
      } else {
        const data = {
          page: page.selected + 1,
        };
        getAllUsers(data);
      }
    },
    [sortByItem, searchText]
  );

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getAllUsers(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getAllUsers(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handleReset = useCallback(() => {
    getAllUsers({});
  }, []);

  const handleSearch = () => {
    getAllUsers({});
  };

  return (
    <LayoutContainer className="layout-container-main-page">
      <AdminHeader title={t('header.accessManagement')} />
      <div className="page-content-wrapper scrollable">
        <div className="data-report--main">
          <Card>
            <div className="customer-details-search--block" style={{ border: 'none', borderRadius: 'none' }}>
              <Row>
                <Col xl={9} md={9}>
                  <SearchBox
                    preIcon={<BsSearch />}
                    postIcon={
                      <MdClear
                        onClick={() => {
                          setSearchText('');
                          handleReset();
                        }}
                      />
                    }
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        handleSearch();
                      }
                    }}
                  />
                </Col>
                <Col xl={'auto'} md={'auto'}>
                  <div className="customerList-add-boxButton" onClick={dataFilter}>
                    <Button className="customerList-add-box-Btn">{t('button.search')}</Button>
                  </div>
                </Col>
                {isAllowed('modifyAccessManagement') && (
                  <Col xl="auto" md="auto">
                    <div className="tab-nav-block__right">
                      <Button onClick={() => navigateTo('/accessManagement/addUser')}>
                        <span className="me-2">
                          <AiOutlinePlus color={'#FFFFFF'} />
                        </span>
                        {t('button.addUser')}
                      </Button>
                    </div>
                  </Col>
                )}
              </Row>
            </div>
            <div className="role-access-page__main">
              <div className="table-responsive">
                <table className="record-list-table">
                  <thead>
                    <tr>
                      <th>
                        <div className="sorting">
                          <span>{t('profileForm.nameLabel')}</span>
                          <span className="ico" onClick={() => handleSorting('name', sortByItem)}>
                            <BiSort size={15} />
                          </span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{t('profileForm.emailLabel')}</span>
                          <span className="ico" onClick={() => handleSorting('email', sortByItem)}>
                            <BiSort size={15} />
                          </span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{t('profileForm.phoneNo')}</span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{t('profileForm.role')}</span>
                        </div>
                      </th>
                      {isAllowed('modifyAccessManagement') && (
                        <th>
                          <div className="sorting">
                            <span>{t('profileForm.action')}</span>
                          </div>
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {isLoader ? (
                      <tr>
                        <td colSpan={10} className="border-0">
                          <div className="loader-block___">
                            <Spinner as="span" animation="border" size="sm" role="status" />
                            <span className="ps-2">{t('placeHolder.loading')}</span>
                          </div>
                        </td>
                      </tr>
                    ) : _.isEmpty(allUsers) ? (
                      <tr>
                        <td colSpan={8} className="border-0">
                          <div className="empty-data-block">No Users</div>
                        </td>
                      </tr>
                    ) : (
                      !(isLoader || _.isUndefined(isLoader)) &&
                      _.map(
                        _.filter(allUsers, (userData) => userData.role !== SUPER_ADMIN),
                        (user, key) => {
                          const isCurrentUser = _.get(profileData, 'id', '') === _.get(user, 'id', '');
                          return (
                            <tr key={`user-index-${key}`}>
                              <td>
                                <div className="role-access-name-list">
                                  <Avatar imgSrc={_.get(user, 'picture', '')} className="user-avatar" />
                                  <span>{_.get(user, 'name', '')}</span>
                                </div>
                              </td>
                              <td>{_.get(user, 'email', '')}</td>
                              <td>
                                +{_.get(user, 'country_code', '')}
                                {_.get(user, 'phone', 'NA')}
                              </td>
                              <td>
                                <div className="role-access-role">
                                  {isAllowed('modifyAccessManagement') ? (
                                    <Select
                                      isDisabled={_.get(profileData, 'role', '') !== TENANT_ADMIN || isCurrentUser}
                                      className="roles-select__box"
                                      value={_.get(user, 'role', '')}
                                      options={_.map(allRoles, (item) => {
                                        return { label: _.startCase(item.name), value: item.name };
                                      })}
                                      onChange={(val) => {
                                        editUserRole(val, user.id);
                                      }}
                                    />
                                  ) : (
                                    <Select
                                      isDisabled
                                      className="roles-select__box"
                                      value={_.get(user, 'role', '')}
                                      options={_.map(allRoles, (item) => {
                                        return { label: _.startCase(item.name), value: item.name };
                                      })}
                                      onChange={() => {
                                        // editUserRole(val, user.id);
                                      }}
                                    />
                                  )}
                                </div>
                              </td>
                              {isAllowed('modifyAccessManagement') && (
                                <td>
                                  <div>
                                    <span className="role-access-charging-station-icon">
                                      <FaChargingStation
                                        size={24}
                                        onClick={() => {
                                          navigateTo(`/stationWiseAccess/${_.get(user, 'id')}`);
                                        }}
                                      />
                                    </span>
                                    <span className="role-access-delete-icon">
                                      <RiDeleteBinLine
                                        title="Delete"
                                        onClick={() => {
                                          setUserId(_.get(user, 'id'));
                                          setShowDeleteModal(true);
                                        }}
                                      />
                                    </span>
                                  </div>
                                </td>
                              )}
                            </tr>
                          );
                        }
                      )
                    )}
                  </tbody>
                </table>
              </div>
              {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(allUsers) && (
                <ReactPagination
                  currentPage={page}
                  limit={limit}
                  total={totalData}
                  handlePageClick={(pageVal) => handlePageClick(pageVal)}
                  totalPages={totalPages}
                  marginPagesDisplayed={1}
                />
                // <Pagination currentPage={page} limit={limit} total={totalData} onPageChange={(pageVal) => handlePageClick(pageVal)} />
              )}
            </div>
            {showDeleteModal && (
              <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
                <DeleteModal title="user" onClose={handleCloseDeleteModel} onRemove={() => deleteUser(userId)} />
              </Modal>
            )}
          </Card>
        </div>
      </div>
    </LayoutContainer>
  );
};
export default AccessManagement;
