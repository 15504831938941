import {
  FETCH_CHARGING_STATION_ACCESS_TYPE,
  FETCH_CHARGING_STATION_ACCESS_TYPE_SUCCESS,
  FETCH_CHARGING_STATION_ACCESS_TYPE_FAIL,
  GET_CHARGING_STATION_ACCESS_TYPE,
  GET_CHARGING_STATION_ACCESS_TYPE_SUCCESS,
  GET_CHARGING_STATION_ACCESS_TYPE_FAIL,
  CREATE_CHARGING_STATION_ACCESS_TYPE,
  CREATE_CHARGING_STATION_ACCESS_TYPE_SUCCESS,
  CREATE_CHARGING_STATION_ACCESS_TYPE_FAIL,
  UPDATE_CHARGING_STATION_ACCESS_TYPE,
  UPDATE_CHARGING_STATION_ACCESS_TYPE_SUCCESS,
  UPDATE_CHARGING_STATION_ACCESS_TYPE_FAIL,
  DELETE_CHARGING_STATION_ACCESS_TYPE,
  DELETE_CHARGING_STATION_ACCESS_TYPE_SUCCESS,
  DELETE_CHARGING_STATION_ACCESS_TYPE_FAIL,
} from 'actions/stationAccessType';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  stationAccessTypes: [],
  stationAccessTypeDetail: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const stationAccessTypeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_CHARGING_STATION_ACCESS_TYPE:
    case GET_CHARGING_STATION_ACCESS_TYPE:
    case CREATE_CHARGING_STATION_ACCESS_TYPE:
    case DELETE_CHARGING_STATION_ACCESS_TYPE: {
      return { ...state, isLoading: true, stationAccessTypeDetail: {} };
    }

    case UPDATE_CHARGING_STATION_ACCESS_TYPE: {
      return { ...state, isLoading: true };
    }

    case FETCH_CHARGING_STATION_ACCESS_TYPE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        stationAccessTypes: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case GET_CHARGING_STATION_ACCESS_TYPE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        stationAccessTypeDetail: payload,
      };
    }

    case CREATE_CHARGING_STATION_ACCESS_TYPE_SUCCESS: {
      toast.success('Access type added successfully');
      return {
        ...state,
        isLoading: false,
        stationAccessTypes: _.concat(state.stationAccessTypes, payload),
      };
    }

    case UPDATE_CHARGING_STATION_ACCESS_TYPE_SUCCESS: {
      toast.success('Access type updated successfully');
      return {
        ...state,
        stationAccessTypes: state.stationAccessTypes.map((data) => (data.id === payload.id ? payload : data)),
        isLoading: false,
      };
    }

    case DELETE_CHARGING_STATION_ACCESS_TYPE_SUCCESS: {
      toast.success('Access type deleted successfully');
      return {
        ...state,
        isLoading: false,
        stationAccessTypes: state.stationAccessTypes.filter((data) => data.id !== payload),
      };
    }

    case FETCH_CHARGING_STATION_ACCESS_TYPE_FAIL:
    case GET_CHARGING_STATION_ACCESS_TYPE_FAIL:
    case CREATE_CHARGING_STATION_ACCESS_TYPE_FAIL:
    case UPDATE_CHARGING_STATION_ACCESS_TYPE_FAIL:
    case DELETE_CHARGING_STATION_ACCESS_TYPE_FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};
export default stationAccessTypeReducer;
