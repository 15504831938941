import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Toaster } from 'react-hot-toast';
import PropTypes from 'prop-types';

import PrivateRoute from 'components/privateRoute/PrivateRoute';
import Login from 'view/login/index';
import ForgotPassword from 'view/forgotPassword';
import AccessDenied from 'view/AccessDenied/AccessDenied';
import Loader from 'components/general/Loader';
export const MessagePopup = ({ title, description }) => {
  return (
    <div>
      <h5>{title}</h5>
      {description && <p className="m-0">{description}</p>}
    </div>
  );
};
MessagePopup.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

const App = () => {
  return (
    <React.Fragment>
      <BrowserRouter>
        <React.Suspense fallback={<Loader />}>
          <Switch>
            <Route exact path="/">
              <Redirect push to="/login" />
            </Route>
            <Route exact path="/login" component={Login} />
            <Route exact path="/forgotPassword" component={ForgotPassword} />
            <Route exact path="/accessdenied" component={AccessDenied} />
            <PrivateRoute />
          </Switch>
        </React.Suspense>
        <ToastContainer position="top-right" theme="colored" />
        <Toaster />
      </BrowserRouter>
    </React.Fragment>
  );
};

export default App;
