export const CREATE_SCHEDULING_SETTING = 'CREATE_SCHEDULING_SETTING';
export const CREATE_SCHEDULING_SETTING_SUCCESS = 'CREATE_SCHEDULING_SETTING_SUCCESS';
export const CREATE_SCHEDULING_SETTING_FAIL = 'CREATE_SCHEDULING_SETTING_FAIL';

export const FETCH_SCHEDULING_SETTING = 'FETCH_SCHEDULING_SETTING';
export const FETCH_SCHEDULING_SETTING_SUCCESS = 'FETCH_SCHEDULING_SETTING_SUCCESS';
export const FETCH_SCHEDULING_SETTING_FAIL = 'FETCH_SCHEDULING_SETTING_FAIL';

export const GET_SCHEDULING_SETTING = 'GET_SCHEDULING_SETTING';
export const GET_SCHEDULING_SETTING_SUCCESS = 'GET_SCHEDULING_SETTING_SUCCESS';
export const GET_SCHEDULING_SETTING_FAIL = 'GET_SCHEDULING_SETTING_FAIL';

export const UPDATE_SCHEDULING_SETTING = 'UPDATE_SCHEDULING_SETTING';
export const UPDATE_SCHEDULING_SETTING_SUCCESS = 'UPDATE_SCHEDULING_SETTING_SUCCESS';
export const UPDATE_SCHEDULING_SETTING_FAIL = 'UPDATE_SCHEDULING_SETTING_FAIL';

export const DELETE_SCHEDULING_SETTING = 'DELETE_SCHEDULING_SETTING';
export const DELETE_SCHEDULING_SETTING_SUCCESS = 'DELETE_SCHEDULING_SETTING_SUCCESS';
export const DELETE_SCHEDULING_SETTING_FAIL = 'DELETE_SCHEDULING_SETTING_FAIL';
