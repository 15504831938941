import {
  FETCH_OFFERS,
  FETCH_OFFERS_SUCCESS,
  FETCH_OFFERS_FAIL,
  GET_OFFERS,
  GET_OFFERS_SUCCESS,
  GET_OFFERS_FAIL,
  CREATE_OFFERS,
  CREATE_OFFERS_SUCCESS,
  CREATE_OFFERS_FAIL,
  UPDATE_OFFERS,
  UPDATE_OFFERS_SUCCESS,
  UPDATE_OFFERS_FAIL,
  DELETE_OFFERS,
  DELETE_OFFERS_SUCCESS,
  DELETE_OFFERS_FAIL,
} from 'actions/offers';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';

function* fetchOffers(action) {
  try {
    const response = yield call(API.getOffers, action.payload);
    yield put({ type: FETCH_OFFERS_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_OFFERS_FAIL, payload: { error: e } });
  }
}

function* getOffersById(action) {
  try {
    const response = yield call(API.getOffersById, action.payload);
    yield put({ type: GET_OFFERS_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_OFFERS_FAIL, payload: { error: e } });
  }
}

function* createOffers(action) {
  try {
    const response = yield call(API.addOffers, action.payload);
    yield put({ type: CREATE_OFFERS_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_OFFERS_FAIL, payload: { error: e } });
  }
}

function* updateOffers(action) {
  try {
    const response = yield call(API.updateOffers, action.payload);
    yield put({ type: UPDATE_OFFERS_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_OFFERS_FAIL, payload: { error: e } });
  }
}

function* deleteOffers(action) {
  try {
    const response = yield call(API.deleteOffers, action.payload);
    yield put({ type: DELETE_OFFERS_SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_OFFERS_FAIL, payload: { error: e } });
  }
}

function* Offers() {
  yield takeLatest(FETCH_OFFERS, fetchOffers);
  yield takeLatest(GET_OFFERS, getOffersById);
  yield takeLatest(CREATE_OFFERS, createOffers);
  yield takeLatest(UPDATE_OFFERS, updateOffers);
  yield takeLatest(DELETE_OFFERS, deleteOffers);
}

export default Offers;
