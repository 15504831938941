export const FETCH_OEM = 'FETCH_OEM';
export const FETCH_OEM_SUCCESS = 'FETCH_OEM_SUCCESS';
export const FETCH_OEM_FAIL = 'FETCH_OEM_FAIL';

export const GET_OEM = 'GET_OEM';
export const GET_OEM_SUCCESS = 'GET_OEM_SUCCESS';
export const GET_OEM_FAIL = 'GET_OEM_FAIL';

export const CREATE_OEM = 'CREATE_OEM';
export const CREATE_OEM_SUCCESS = 'CREATE_OEM_SUCCESS';
export const CREATE_OEM_FAIL = 'CREATE_OEM_FAIL';

export const UPDATE_OEM = 'UPDATE_OEM';
export const UPDATE_OEM_SUCCESS = 'UPDATE_OEM_SUCCESS';
export const UPDATE_OEM_FAIL = 'UPDATE_OEM_FAIL';

export const DELETE_OEM = 'DELETE_OEM';
export const DELETE_OEM_SUCCESS = 'DELETE_OEM_SUCCESS';
export const DELETE_OEM_FAIL = 'DELETE_OEM_FAIL';

export const OEM_UPDATE_FIRMWARE = 'OEM_UPDATE_FIRMWARE';
export const OEM_UPDATE_FIRMWARE_SUCCESS = 'OEM_UPDATE_FIRMWARE_SUCCESS';
export const OEM_UPDATE_FIRMWARE_FAIL = 'OEM_UPDATE_FIRMWARE_FAIL';
