import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Card from 'components/inputs/Card';
import { MdClose } from 'react-icons/md';
import { Row, Col } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'components/inputs/Select';
import * as Yup from 'yup';
import * as _ from 'lodash';
import { CREATE_AREA, UPDATE_AREA, FETCH_CITY, FETCH_COUNTRY, FETCH_STATE } from 'actions/address';
import { CREATE_STATUS_CODE, UPDATE_STATUS_CODE } from 'components/common/constant';
import { useTranslation } from 'react-i18next';

const AreaForm = (props) => {
  const { isEdit, onClose = _.noop(), onSuccess = _.noop() } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const areaDetail = useSelector((state) => state.area.areaDetail);
  const allCountries = useSelector((state) => state.country.countries);
  const allState = useSelector((state) => state.state.states);
  const allCities = useSelector((state) => state.city.city);
  const isLoading = useSelector((state) => state.area.isLoading);
  const countryPage = useSelector((state) => state.country.page);
  const statePage = useSelector((state) => state.state.page);
  const cityPage = useSelector((state) => state.city.page);
  const countryTotalPage = useSelector((state) => state.country.totalPages);
  const stateTotalPage = useSelector((state) => state.state.totalPages);
  const cityTotalPage = useSelector((state) => state.city.totalPages);

  const addArea = useCallback((data) => {
    dispatch({
      type: CREATE_AREA,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === CREATE_STATUS_CODE) {
          onSuccess();
          onClose();
        }
      },
    });
  }, []);

  const updateArea = useCallback((data) => {
    dispatch({
      type: UPDATE_AREA,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          onClose();
        }
      },
    });
  }, []);

  const getAllCountry = useCallback((data = {}) => {
    dispatch({ type: FETCH_COUNTRY, payload: data });
  }, []);

  const getStateByCountry = useCallback((data = {}) => {
    dispatch({ type: FETCH_STATE, payload: data });
  }, []);

  const getCityByState = useCallback((data = {}) => {
    dispatch({ type: FETCH_CITY, payload: data });
  }, []);

  useEffect(() => {
    getAllCountry();
  }, []);

  useEffect(() => {
    if (isEdit && !_.isEmpty(areaDetail)) {
      getStateByCountry({ country: areaDetail.city.state.country.id, limit: 999 });
      getCityByState({ state: areaDetail.city.state.id, limit: 999 });
    }
  }, [isEdit, areaDetail]);

  const AreaSchema = Yup.object().shape({
    name: Yup.string().required(t('areaForm.name')).strict(true).trim(t('areaForm.space')).max(100, t('areaForm.maxlimit')),
    // postal_code: Yup.string()
    //   .required('Area code is required')
    //   .strict(true)
    //   .trim('Space is not allowed')
    //   .matches(/^[0-9\s]+$/, 'Only numbers are allowed')
    //   .max(32, 'Maximum postal code should be 32 digits'),
    // city: Yup.string().required('Select city'),
    country: Yup.string().required(t('areaForm.country')),
    state: Yup.string().required(t('areaForm.state')),
  });

  const initialValues = isEdit
    ? {
        ...areaDetail,
        country: _.get(areaDetail, 'city.state.country.id', ''),
        state: _.get(areaDetail, 'city.state.id', ''),
        city: _.get(areaDetail, 'city.id', ''),
      }
    : {
        name: '',
        postal_code: '',
        city: '',
        country: '',
        state: '',
      };

  return (
    <React.Fragment>
      <div className="area-form-page__main">
        <Card>
          <div className="area-form-header__block">
            <div className="area-header-name">
              <span>
                {isEdit ? 'Edit' : 'Add'} {t('areaForm.title')}
              </span>
            </div>
            <div className="close-btn">
              <MdClose size={30} color="#be210b" onClick={onClose} />
            </div>
          </div>
          <div className="area-form-body__block">
            <div className="area-form--block">
              <Formik
                enableReinitialize={!!isEdit}
                initialValues={initialValues}
                validationSchema={AreaSchema}
                onSubmit={(values, { setSubmitting }) => {
                  const areaData = _.omit(values, ['country', 'state']);
                  if (isEdit) {
                    updateArea(areaData);
                  } else {
                    addArea(areaData);
                  }
                  setSubmitting(false);
                }}
              >
                {({ values, errors, touched, handleSubmit, handleChange, setFieldValue, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col lg={12}>
                        <Select
                          isRequired
                          label={t('areaForm.countryName')}
                          options={_.map(allCountries, (item) => {
                            return { label: item.name, value: item.id };
                          })}
                          placeholder={t('placeHolder.selectCountryName')}
                          name="country"
                          value={values.country}
                          onMenuScrollDown={true}
                          getDataOnScrollDown={getAllCountry}
                          page={countryPage}
                          totalPage={countryTotalPage}
                          onChange={(val) => {
                            getStateByCountry({ country: val });
                            setFieldValue(`country`, val);
                            setFieldValue(`state`, '');
                            setFieldValue(`city`, '');
                          }}
                          error={errors.country && touched.country ? errors.country : null}
                        />
                      </Col>
                      <Col lg={12}>
                        <Select
                          isRequired
                          label={t('dataReport.stateName')}
                          options={_.map(allState, (item) => {
                            return { label: item.name, value: item.id };
                          })}
                          placeholder={t('placeHolder.selectStateName')}
                          name="state"
                          value={values.state}
                          onMenuScrollDown={true}
                          getDataOnScrollDown={(data) => getStateByCountry({ ...data, country: values.country })}
                          page={statePage}
                          totalPage={stateTotalPage}
                          onChange={(val) => {
                            getCityByState({ state: val });
                            setFieldValue(`state`, val);
                            setFieldValue(`city`, '');
                          }}
                          error={errors.state && touched.state ? errors.state : null}
                        />
                      </Col>
                      <Col lg={12}>
                        <Select
                          isRequired
                          label={t('dataReport.cityName')}
                          options={
                            values.state &&
                            _.map(allCities, (item) => {
                              return { label: item.name, value: item.id };
                            })
                          }
                          placeholder={t('placeHolder.selectCityName')}
                          name="city"
                          onMenuScrollDown={true}
                          getDataOnScrollDown={(data) => getCityByState({ ...data, state: values.state })}
                          page={cityPage}
                          totalPage={cityTotalPage}
                          value={_.get(values, 'city')}
                          onChange={(val) => setFieldValue(`city`, val)}
                          error={errors.city && touched.city ? errors.city : null}
                        />
                      </Col>
                      <Col lg={12}>
                        <TextInput
                          isRequired
                          label={t('dataReport.areaName')}
                          placeholder={t('dataReport.areaName')}
                          name="name"
                          value={_.get(values, 'name')}
                          onChange={handleChange}
                          error={errors.name && touched.name ? errors.name : null}
                        />
                      </Col>
                      <Col lg={12}>
                        <TextInput
                          // isRequired
                          label={t('dataReport.postalCode')}
                          placeholder={t('dataReport.postalCode')}
                          name="postal_code"
                          value={_.get(values, 'postal_code')}
                          onChange={handleChange}
                          // error={errors.postal_code && touched.postal_code ? errors.postal_code : null}
                        />
                      </Col>
                    </Row>
                    <div className="form-btn-block">
                      <Button className="area-form-btn cancel_btn" onClick={onClose}>
                        {t('button.cancel')}
                      </Button>
                      <Button type="submit" disabled={isSubmitting || isLoading} className="area-form-btn create-btn">
                        {isEdit ? 'Update' : 'Add'}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};
AreaForm.propTypes = {
  onSuccess: PropTypes.func,
  onClose: PropTypes.func,
  isEdit: PropTypes.bool,
};
export default AreaForm;
