import React, { useState, useEffect, useCallback } from 'react';
import { Col, Row, Modal } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
import Button from 'components/inputs/Button';
import * as _ from 'lodash';
import { FETCH_SCHEDULE, DELETE_SCHEDULE } from 'actions/schedule';
import { useSelector, useDispatch } from 'react-redux';
import SearchBox from 'components/general/SearchBox';
import ReactPagination from 'components/general/ReactPagination';
import DeleteModal from 'components/general/DeleteModal';
import { DELETE_STATUS_CODE } from 'components/common/constant';
import { BiSort } from 'react-icons/bi';
import { FiEdit2 } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { startsWith } from 'components/common/utils';
import './Schedule.css';
import BookingSchedule from './BookingSchedule';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
const Schedule = () => {
  const [searchText, setSearchText] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [scheduleId, setScheduleId] = useState('');
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [scheduleModel, setScheduleModel] = useState({
    isVisible: false,
    payload: null,
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const schedule = useSelector((state) => state.schedule.schedule);
  const isLoader = useSelector((state) => state.schedule.isLoading);
  const page = useSelector((state) => state.schedule.page);
  const totalData = useSelector((state) => state.schedule.total);
  const limit = useSelector((state) => state.schedule.limit);
  const totalPages = useSelector((state) => state.schedule.totalPages);
  const [allschedule, setAllSchedule] = useState([]);

  const getschedule = useCallback((data) => {
    dispatch({
      type: FETCH_SCHEDULE,
      payload: data ? data : {},
    });
  }, []);

  const handleCloseDeleteModel = () => {
    setShowDeleteModal(false);
    setScheduleId('');
  };

  const deleteSchedule = useCallback(
    () =>
      dispatch({
        type: DELETE_SCHEDULE,
        payload: scheduleId,
        cb: (res) => {
          if (_.get(res, 'status', '') === DELETE_STATUS_CODE) {
            handleCloseDeleteModel();
            getschedule({ page: 1 });
          }
        },
      }),
    [scheduleId]
  );

  useEffect(() => {
    getschedule();
  }, []);

  const handleSearch = () => {
    getschedule({});
  };

  useEffect(() => {
    if (!searchText) {
      setAllSchedule(schedule);
      return;
    }
    setAllSchedule(_.filter(allschedule, (item) => startsWith(_.get(item, 'timeInterval', ''), searchText)));
  }, [searchText, schedule]);

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else {
        const data = {
          page: page.selected + 1,
        };
        getschedule(data);
      }
    },
    [sortByItem]
  );

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getschedule(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getschedule(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  return (
    <React.Fragment>
      <div className="user-group-block">
        <div className="user-group-search-box">
          <Row>
            <Col xl={10}>
              <SearchBox
                preIcon={<BsSearch />}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleSearch();
                  }
                }}
              />
            </Col>
            <Col xl={2}>
              <Button className="user-group-btn" onClick={() => setScheduleModel({ isVisible: true, payload: null })}>
                {t('button.schedule')}
              </Button>
            </Col>
          </Row>
        </div>
        <div className="user-group-table">
          <div className="table-responsive">
            <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
              <table className="record-list-table">
                <thead>
                  <tr>
                    <th>
                      <div className="sorting">
                        <span>{isLoader ? <Skeleton height={15} width={100} /> : t('bookingSchedule.timeInterval')}</span>
                        <span className="ico" onClick={() => handleSorting('timeInterval', sortByItem)}>
                          <BiSort size={15} />
                        </span>
                      </div>
                    </th>
                    <th>
                      <div className="sorting">
                        <span>{isLoader ? <Skeleton height={15} width={100} /> : t('bookingSchedule.timeFrom')}</span>
                        <span className="ico" onClick={() => handleSorting('timeFrom', sortByItem)}>
                          <BiSort size={15} />
                        </span>
                      </div>
                    </th>
                    <th>
                      <div className="sorting">
                        <span>{isLoader ? <Skeleton height={15} width={100} /> : t('bookingSchedule.timeTo')}</span>
                        <span className="ico" onClick={() => handleSorting('timeTo', sortByItem)}>
                          <BiSort size={15} />
                        </span>
                      </div>
                    </th>
                    <th className="action-col">
                      <div>
                        <span>{isLoader ? <Skeleton height={15} width={100} /> : t('bookingSchedule.actions')}</span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {isLoader ? (
                    <tr>
                      <td colSpan={10} className="border-0">
                        <div className="loader--block">
                          <Skeleton height={15} width={100} />
                        </div>
                      </td>
                    </tr>
                  ) : _.isEmpty(allschedule) ? (
                    <tr>
                      <td colSpan={8} className="border-0">
                        <div className="empty-data-block">No booked schedule yet</div>
                      </td>
                    </tr>
                  ) : (
                    !(isLoader || _.isUndefined(isLoader)) &&
                    allschedule.map((data) => {
                      return (
                        <tr key={`reserve-slot-${_.get(data, 'id')}`}>
                          <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(data, 'timeInterval')}</td>
                          <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(data, 'timeFrom')}</td>
                          <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(data, 'timeTo')}</td>
                          <td>
                            <span
                              className="user-group-table-editIcon"
                              onClick={() => {
                                setScheduleModel({
                                  ...scheduleModel,
                                  isVisible: true,
                                  payload: data,
                                });
                              }}
                            >
                              {isLoader ? <Skeleton height={15} width={100} circle="true" /> : <FiEdit2 title="Edit" />}
                            </span>
                            <span
                              className="user-group-table-deleteIcon"
                              onClick={() => {
                                setShowDeleteModal(true);
                                setScheduleId(data.id);
                              }}
                            >
                              {isLoader ? <Skeleton height={15} width={100} circle="true" /> : <RiDeleteBinLine title="Delete" />}
                            </span>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </SkeletonTheme>
          </div>
        </div>
        {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(allschedule) && (
          <ReactPagination
            currentPage={page}
            limit={limit}
            total={totalData}
            handlePageClick={(pageVal) => handlePageClick(pageVal)}
            totalPages={totalPages}
            marginPagesDisplayed={1}
          />
        )}

        <Modal show={scheduleModel.isVisible} size="xl" centered>
          <BookingSchedule
            onClose={() => {
              setScheduleModel({ ...scheduleModel, isVisible: false });
            }}
            payload={scheduleModel.payload}
          />
        </Modal>

        {showDeleteModal && (
          <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
            <DeleteModal title="booked schedule" onClose={handleCloseDeleteModel} onRemove={deleteSchedule} />
          </Modal>
        )}
      </div>
    </React.Fragment>
  );
};

export default Schedule;
