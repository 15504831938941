import { JSON_REQUEST_RESPONSE_REPORT_SUCCESS, JSON_REQUEST_RESPONSE_REPORT_FAIL, JSON_REQUEST_RESPONSE_REPORT } from 'actions/dataReport';

const initialState = {
  isLoading: false,
  jsonRequestResponseReports: [],
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const jsonRequestResponseReportReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case JSON_REQUEST_RESPONSE_REPORT: {
      return { ...state, isLoading: true };
    }

    case JSON_REQUEST_RESPONSE_REPORT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        jsonRequestResponseReports: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case JSON_REQUEST_RESPONSE_REPORT_FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};
export default jsonRequestResponseReportReducer;
