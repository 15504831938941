import {
  FETCH_CHARGER_STATE,
  FETCH_CHARGER_STATE_SUCCESS,
  FETCH_CHARGER_STATE_FAIL,
  GET_CHARGER_STATE,
  GET_CHARGER_STATE_SUCCESS,
  GET_CHARGER_STATE_FAIL,
  CREATE_CHARGER_STATE,
  CREATE_CHARGER_STATE_SUCCESS,
  CREATE_CHARGER_STATE_FAIL,
  UPDATE_CHARGER_STATE,
  UPDATE_CHARGER_STATE_SUCCESS,
  UPDATE_CHARGER_STATE_FAIL,
  DELETE_CHARGER_STATE,
  DELETE_CHARGER_STATE_SUCCESS,
  DELETE_CHARGER_STATE_FAIL,
} from 'actions/chargerState';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';

function* fetchChargerState(action) {
  try {
    const response = yield call(API.getChargerState, action.payload);
    yield put({ type: FETCH_CHARGER_STATE_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_CHARGER_STATE_FAIL, payload: { error: e } });
  }
}

function* getChargerStateById(action) {
  try {
    const response = yield call(API.getChargerStateById, action.payload);
    yield put({ type: GET_CHARGER_STATE_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_CHARGER_STATE_FAIL, payload: { error: e } });
  }
}

function* createChargerState(action) {
  try {
    const response = yield call(API.addChargerState, action.payload);
    yield put({ type: CREATE_CHARGER_STATE_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_CHARGER_STATE_FAIL, payload: { error: e } });
  }
}

function* updateChargerState(action) {
  try {
    const response = yield call(API.updateChargerState, action.payload);
    yield put({ type: UPDATE_CHARGER_STATE_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_CHARGER_STATE_FAIL, payload: { error: e } });
  }
}

function* deleteChargerState(action) {
  try {
    const response = yield call(API.deleteChargerState, action.payload);
    yield put({ type: DELETE_CHARGER_STATE_SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_CHARGER_STATE_FAIL, payload: { error: e } });
  }
}

function* chargerState() {
  yield takeLatest(FETCH_CHARGER_STATE, fetchChargerState);
  yield takeLatest(GET_CHARGER_STATE, getChargerStateById);
  yield takeLatest(CREATE_CHARGER_STATE, createChargerState);
  yield takeLatest(UPDATE_CHARGER_STATE, updateChargerState);
  yield takeLatest(DELETE_CHARGER_STATE, deleteChargerState);
}

export default chargerState;
