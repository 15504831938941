import { GET_DASHBOARD_DATA, GET_DASHBOARD_DATA_SUCCESS, GET_DASHBOARD_DATA_FAIL } from 'actions/dashboard';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';

function* getDashboardData(action) {
  try {
    const response = yield call(API.getDashboardData, action.payload);
    yield put({ type: GET_DASHBOARD_DATA_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_DASHBOARD_DATA_FAIL, payload: { error: e } });
  }
}

function* dashboard() {
  yield takeLatest(GET_DASHBOARD_DATA, getDashboardData);
}
export default dashboard;
