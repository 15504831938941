import LayoutContainer from 'components/layout/LayoutContainer';
import React, { useCallback, useEffect, useState, useContext } from 'react';
import AdminHeader from 'components/header';
import { Col, Row } from 'react-bootstrap';
import Card from 'components/inputs/Card';
import Button from 'components/inputs/Button';
import * as _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import ReactPagination from 'components/general/ReactPagination';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Footer from 'components/general/Footer';
import { NavContext } from 'components/privateRoute/PrivateRoute';
import { ALL_USERS_IN_OCPI_PARTNER_GROUP, DELETE_OCPI_PARTNER } from 'actions/ocpiPartnerGroup';
import { UPDATE_STATUS_CODE } from 'components/common/constant';
import { Modal } from 'react-bootstrap';
import DeleteModal from 'components/general/DeleteModal';

const AddPartyId = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { partnerGroupId } = useParams();
  const { navigateTo } = useContext(NavContext);
  const [searchText] = useState('');
  const [allUserInUserGroupListData, setAllUserInUserGroupListData] = useState();
  const [partnerId, setPartnerId] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [sortByItem] = useState({ item: '', order: '' });

  const allUserGroup = useSelector((state) => state.ocpiPartnerGroup.partnerGroups);
  const isLoader = useSelector((state) => state.partnerManagement.isLoading);
  const page = useSelector((state) => state.partnerManagement.page);
  const totalData = useSelector((state) => state.partnerManagement.total);
  const limit = useSelector((state) => state.partnerManagement.limit);
  const totalPages = useSelector((state) => state.partnerManagement.totalPages);
  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserName = _.get(profileData, 'name', '');

  const getAllPartyId = useCallback(() => {
    dispatch({
      type: ALL_USERS_IN_OCPI_PARTNER_GROUP,
      payload: { user_group: partnerGroupId, searchText }, // Use searchText here
      cb: (res) => {
        if (res && res.data) {
          setAllUserInUserGroupListData(res.data);
        }
      },
    });
  }, [dispatch, partnerGroupId, searchText]);

  useEffect(() => {
    getAllPartyId();
  }, [getAllPartyId]);


  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getAllPartyId({ ...data, user_group: partnerGroupId });
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getAllPartyId({ ...data, user_group: partnerGroupId });
    }
  };

  const handlePageClick = useCallback(
    (pageVal) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, pageVal);
      } else {
        const data = {
          page: pageVal.selected + 1,
        };
        getAllPartyId({ ...data, user_group: partnerGroupId });
      }
    },
    [getAllPartyId, partnerGroupId, sortByItem]
  );

  useEffect(() => {
    setAllUserInUserGroupListData(allUserGroup);
  }, [searchText, allUserGroup]);


  const deleteUser = useCallback(() => {
    const userData = {
      id: partnerId,
      user_group: partnerGroupId,
    };
    dispatch({
      type: DELETE_OCPI_PARTNER,
      payload: userData,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          setShowDeleteModal(false);
          getAllPartyId({ user_group: partnerGroupId });
        }
      },
    });
  }, [partnerId, partnerGroupId]);

  const handleCloseDeleteModel = () => {
    setShowDeleteModal(false);
  };

  // const getAllPartnerGroup = useCallback((data = {}) => {
  //   dispatch({ type: FETCH_OCPI_PARTNER_GROUP, payload: data });
  // }, []);


  // useEffect(() => {
  //   getAllPartnerGroup({ user_group: partnerGroupId });
  // }, []);


  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title={t('Add Party Id')} backTo="/OCPIPartnerGroup" />
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation={true}>
          <div className="page-content-wrapper scrollable">
            <div className="user-group-page_main">
              <div className="user-group-main-block">
                <Card>
                  <div className="user-group-block">
                    <div className="user-group-search-box">
                      <Row>
                        <Col xl={2} md={3}>
                          <Button className="user-group-btn" onClick={() => navigateTo(`/addParty/${partnerGroupId}`)}>
                            {t('Add Party Id')}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                    <div className="user-group-table">
                      <div className="table-responsive">
                        <table className="record-list-table">
                          <thead>
                            <tr>
                              <th>
                                <div className="sorting">
                                  <span>{isLoader ? <Skeleton width={100} height={15} /> : t('addPartyId.srNo')}</span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>{isLoader ? <Skeleton width={100} height={15} /> : t('addPartyId.partyId')}</span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>{isLoader ? <Skeleton width={100} height={15} /> : t('addPartyId.role')}</span>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {isLoader ? (
                              <tr>
                                <td colSpan={3} className="border-0">
                                  <Skeleton height={15} />
                                </td>
                              </tr>
                            ) : _.isEmpty(allUserInUserGroupListData) ? (
                              <tr>
                                <td colSpan={3} className="border-0">
                                  <div className="empty-data-block">{t('No Party Found')}</div>
                                </td>
                              </tr>
                            ) : (
                              _.map(allUserInUserGroupListData.ocpiCredentials, (item, index) => {
                                const serial_num = limit * (page - 1) + index;
                                return (
                                  <tr key={`user-group-${serial_num}`}>
                                    <td>{serial_num + 1}</td>
                                    <td>{_.get(item, 'partyId', '')}</td>
                                    <td>{_.get(item, 'role', '')}</td>
                                    <td>
                                      {isLoader ? (
                                        <Skeleton width={100} height={15} />
                                      ) : (
                                        <Button
                                          className="user-group-delete-Btn"
                                          onClick={() => {
                                            setShowDeleteModal(true);
                                            setPartnerId(_.get(item, 'id', ''));
                                          }}
                                        >
                                          {t('button.removeUser')}
                                        </Button>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(allUserInUserGroupListData) && (
                      <ReactPagination
                        currentPage={page}
                        limit={limit}
                        total={totalData}
                        handlePageClick={handlePageClick}
                        totalPages={totalPages}
                        marginPagesDisplayed={1}
                      />
                    )}
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </SkeletonTheme>
        {showDeleteModal && (
          <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
            <DeleteModal title="User" onClose={handleCloseDeleteModel} onRemove={deleteUser} />
          </Modal>
        )}
        {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
      </LayoutContainer>
    </React.Fragment>
  );
};

export default AddPartyId;