import React, { useState, useCallback, useEffect } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header';
import Card from 'components/inputs/Card';
import { BsSearch } from 'react-icons/bs';
import Button from 'components/inputs/Button';
import { FiEdit2 } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { Row, Col, Spinner } from 'react-bootstrap';
import * as _ from 'lodash';
import SearchBox from 'components/general/SearchBox';
import { useDispatch, useSelector } from 'react-redux';
// import Pagination from 'components/general/Pagination';
import ReactPagination from 'components/general/ReactPagination';
import { Modal } from 'react-bootstrap';
import { DELETE_CHARGING_STATION_ACCESS_TYPE, FETCH_CHARGING_STATION_ACCESS_TYPE, GET_CHARGING_STATION_ACCESS_TYPE } from 'actions/stationAccessType';
import StationAccessTypeListForm from './StationAccessTypeForm';
import DeleteModal from 'components/general/DeleteModal';
import { DELETE_STATUS_CODE } from 'components/common/constant';
import { BiSort } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';

const StationAccessTypeList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [searchText, setSearchText] = useState('');
  // const [allStationAccessTypeData, setAllStationAccessTypeData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [stationAccessTypeId, setStationAccessTypeId] = useState('');
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });

  const page = useSelector((state) => state.stationAccessType.page);
  const totalData = useSelector((state) => state.stationAccessType.total);
  const limit = useSelector((state) => state.stationAccessType.limit);
  const totalPages = useSelector((state) => state.stationAccessType.totalPages);
  const stationAccessTypeList = useSelector((state) => state.stationAccessType.stationAccessTypes);
  const isLoader = useSelector((state) => state.stationAccessType.isLoading);

  const getStationAccessType = useCallback(
    (data = {}) => {
      if (searchText) {
        const stationAccessTypeData = {
          ...data,
          search: searchText,
        };
        dispatch({ type: FETCH_CHARGING_STATION_ACCESS_TYPE, payload: stationAccessTypeData });
      } else {
        dispatch({ type: FETCH_CHARGING_STATION_ACCESS_TYPE, payload: data });
      }
    },
    [searchText]
  );

  const getStationAccessTypeDetail = useCallback((id) => {
    dispatch({ type: GET_CHARGING_STATION_ACCESS_TYPE, payload: id });
  }, []);

  const handleCloseDeleteModel = () => {
    setShowDeleteModal(false);
    setStationAccessTypeId('');
  };

  const deleteStationAccessType = useCallback(
    () =>
      dispatch({
        type: DELETE_CHARGING_STATION_ACCESS_TYPE,
        payload: stationAccessTypeId,
        cb: (res) => {
          if (_.get(res, 'status', '') === DELETE_STATUS_CODE) {
            handleCloseDeleteModel();
            getStationAccessType({ page: 1 });
          }
        },
      }),
    [stationAccessTypeId]
  );

  useEffect(() => {
    getStationAccessType();
  }, []);

  const handleSearch = () => {
    getStationAccessType({});
  };

  // useEffect(() => {
  //   setAllStationAccessTypeData(_.filter(stationAccessTypeList, (item) => startsWith(_.get(item, 'name', ''), searchText)));
  // }, [searchText, stationAccessTypeList]);

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else {
        const data = {
          page: page.selected + 1,
        };
        getStationAccessType(data);
      }
    },
    [sortByItem]
  );

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getStationAccessType(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getStationAccessType(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handleCloseFormStationAccessType = () => {
    setShowForm(false);
    setIsEdit(false);
  };

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.chargingStationAccessType')} />
        <div className="page-content-wrapper scrollable">
          <div className="stationAccessTypeList-page">
            <Card>
              <div className="stationAccessTypeList-page-wrapper">
                <Row className="stationAccessTypeList-search-box">
                  <Col xl={10} lg={9} md={8} className="stationAccessTypeList-search-inputBox">
                    <SearchBox
                      value={searchText}
                      preIcon={<BsSearch />}
                      onChange={(e) => setSearchText(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          handleSearch();
                        }
                      }}
                    />
                  </Col>
                  <Col xl={2} lg={3} md={4} className="stationAccessTypeList-search-boxButton">
                    <Button
                      className="stationAccessTypeList-search-box-Btn"
                      onClick={() => {
                        setShowForm(true);
                        setIsEdit(false);
                      }}
                    >
                      {t('button.addType')}
                    </Button>
                  </Col>
                </Row>
                <div className="stationAccessTypeList-table">
                  <div className="table-responsive">
                    <table className="record-list-table">
                      <thead>
                        <tr>
                          <th>
                            <div className="sorting">
                              <span>{t('stationAccessTypeListForm.nameLabel')}</span>
                              <span className="ico" onClick={() => handleSorting('name', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th className="action-col">
                            <div className="sorting">
                              <span>{t('stationAccessTypeListForm.action')}</span>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoader ? (
                          <tr>
                            <td colSpan={10} className="border-0">
                              <div className="loader--block">
                                <Spinner as="span" animation="border" size="sm" role="status" />
                                <span className="ps-2">{t('placeHolder.loading')}</span>
                              </div>
                            </td>
                          </tr>
                        ) : _.isEmpty(stationAccessTypeList) ? (
                          <tr>
                            <td colSpan={2} className="border-0">
                              <div className="empty-data-block">{t('stationAccessTypeListForm.noData')}</div>
                            </td>
                          </tr>
                        ) : (
                          !(isLoader || _.isUndefined(isLoader)) &&
                          _.map(stationAccessTypeList, (item, key) => {
                            return (
                              <>
                                <tr key={`stationAccessType-${key}`}>
                                  <td>{_.get(item, 'name', '')}</td>
                                  <td>
                                    <span
                                      className="stationAccessTypeList-table-editIcon"
                                      onClick={() => {
                                        setShowForm(true);
                                        getStationAccessTypeDetail(_.get(item, 'id', ''));
                                        setIsEdit(true);
                                      }}
                                    >
                                      <FiEdit2 title="Edit" />
                                    </span>
                                    <span
                                      className="stationAccessTypeList-table-deleteIcon"
                                      onClick={() => {
                                        setShowDeleteModal(true);
                                        setStationAccessTypeId(_.get(item, 'id', ''));
                                      }}
                                    >
                                      <RiDeleteBinLine title="Delete" />
                                    </span>
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                    {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(stationAccessTypeList) && (
                      <ReactPagination
                        currentPage={page}
                        limit={limit}
                        total={totalData}
                        handlePageClick={(pageVal) => handlePageClick(pageVal)}
                        totalPages={totalPages}
                        marginPagesDisplayed={1}
                      />
                      // <Pagination currentPage={page} limit={limit} total={totalData} onPageChange={(pageVal) => handlePageClick(pageVal)} />
                    )}
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
        {showForm && (
          <Modal show={showForm} size="lg" centered>
            <StationAccessTypeListForm onClose={handleCloseFormStationAccessType} isEdit={isEdit} onSuccess={getStationAccessType} />
          </Modal>
        )}
        {showDeleteModal && (
          <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
            <DeleteModal title="stationAccessType" onClose={handleCloseDeleteModel} onRemove={deleteStationAccessType} />
          </Modal>
        )}
      </LayoutContainer>
    </>
  );
};
export default StationAccessTypeList;
