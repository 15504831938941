import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header';
import Card from 'components/inputs/Card';
import DateTimePicker from 'components/inputs/DateTimePicker';
// import TextInput from 'components/inputs/Input';
import { GET_ELECTRICITY_BILL, DELETE_ELECTRICITY_BILL, DOWNLOAD_ELECTRICITY_BILL } from 'actions/electricityBillReport';
import { UPDATE_STATUS_CODE } from 'components/common/constant';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import * as _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import ReactPagination from 'components/general/ReactPagination';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Footer from 'components/general/Footer';
import { NavContext } from 'components/privateRoute/PrivateRoute';
import Button from 'components/inputs/Button';
import { Modal } from 'react-bootstrap';
import { FaCloudUploadAlt } from 'react-icons/fa';
import { SiMicrosoftexcel } from 'react-icons/si';
import { FiUpload } from 'react-icons/fi';
import { setShowErrorToast } from 'utils/api';
import FileSaver from 'file-saver';
import { UPLOAD_ELECTRICITY_BILL } from 'actions/electricityBillReport';
import { FiEdit2 } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import DeleteModal from 'components/general/DeleteModal';
import fileDownload from 'js-file-download';
import { FETCH_CHARGING_STATION } from 'actions/chargingStation';
import { formatAmount } from 'components/common/utils';


const ElectriciyBillReport = () => {
    const errorMessage = setShowErrorToast(false);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const fileInputRef = useRef(null);
    const { navigateTo } = useContext(NavContext);
    const [fromDate, setFromDate] = useState(moment(new Date()).startOf('month'));
    const [toDate, setToDate] = useState(moment(new Date()).endOf('day'));
    const [sortByItem] = useState({ item: '', order: '' });
    const [filePopupVisible, setFilePopupVisible] = useState(false); // State to manage file upload popup visibility
    const [selectedFileName, setSelectedFileName] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [billId, setBillId] = useState('');

    const electricityBillReportList = useSelector((state) => state.electricityBillReport.electricityBillReports);
    const isLoader = useSelector((state) => state.electricityBillReport.isLoading);
    const page = useSelector((state) => state.electricityBillReport.page);
    const totalData = useSelector((state) => state.electricityBillReport.total);
    const limit = useSelector((state) => state.electricityBillReport.limit);
    const totalPages = useSelector((state) => state.electricityBillReport.totalPages);
    const profileData = useSelector((state) => state.profile.userProfile);
    const currentUserName = _.get(profileData, 'name', '');
    const allStation = useSelector((state) => state.chargingStation.chargingStations.results || []);

    const deletebill = useCallback(
        () => {
            const billData = {
                id: billId
            };
            dispatch({
                type: DELETE_ELECTRICITY_BILL,
                payload: billData,
                cb: (res) => {
                    getelectricityBillReport({ page: 1 });
                    toast.success("Bill Details Deleted Succesfully")
                    if (_.get(res, 'status', '') === UPDATE_STATUS_CODE) {
                        handleCloseDeleteModel();
                        getelectricityBillReport({ page: 1 });
                        toast.success("Bill Details Deleted Succesfully")
                    }
                },
            });
            handleCloseDeleteModel()
        },
        [billId]
    );


    const getAllcharger = useCallback((data = {}) => {
        dispatch({ type: FETCH_CHARGING_STATION, payload: { ...data, limit: 999 } });
    }, [dispatch]);

    useEffect(() => {
        getAllcharger();
    }, [getAllcharger]);

    const downloadbillreport = useCallback(() => {

        const data = {
            from: moment(fromDate).utc(),
            to: moment(toDate).utc(),
            excel: true,
            report: "Electricity Bill Report",
            status: "completed"
        };

        dispatch({
            type: DOWNLOAD_ELECTRICITY_BILL,
            payload: data,
            cb: (res) => {
                if (res && _.get(res, 'status') === 200) {
                    fileDownload(res.data, `${'Electricity Bill Report'}.xlsx`);
                }
            },
        });
    }, [fromDate, toDate]);

    const downloadFile = () => {
        downloadbillreport();
    };

    const searchElectricityData = () => {
        getelectricityBillReport();
    };

    const uploadelectricityBillReport = useCallback((data) => {
        dispatch({
            type: UPLOAD_ELECTRICITY_BILL,
            payload: data,

            cb: (res) => {
                toast.success('Electricity Bill Data added successfully');
                getelectricityBillReport();
                setFilePopupVisible(false);
                setSelectedFileName('');
                if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
                    setTimeout(() => {
                    }, 1000);
                }
                if (_.get(res, 'status') === 400) {
                    {

                        errorMessage
                        toast.error(errorMessage)
                    }
                    const messagesArray = _.get(res, 'data.message').split(', ');
                    const singleMessage = messagesArray[0];
                    toast.error(singleMessage);
                    setTimeout(() => {
                        window.location.reload();
                        navigateTo('/electricityBillReport');
                    }, 1000);
                }
            },
        });
    }, []);


    const handlePageClick = useCallback(
        (page) => {
            if (fromDate && toDate) {
                const data = {
                    fromDate: moment(fromDate).utc(),
                    toDate: moment(toDate).utc(),
                    page: page.selected + 1,
                };
                getelectricityBillReport(data);
            } else {
                const data = {
                    page: page.selected + 1,
                };
                getelectricityBillReport(data);
            }
        },
        [sortByItem, fromDate, toDate]
    );

    const getelectricityBillReport = useCallback((data = {}) => {

        const billdata = {
            ...data,
            fromDate: moment(fromDate).toISOString(),
            toDate: moment(toDate).toISOString(),
        };
        dispatch({ type: GET_ELECTRICITY_BILL, payload: billdata })
    }, [fromDate,toDate]);

    useEffect(() => {
        getelectricityBillReport();
    }, []);

    const handleFileUpload = () => {
        setFilePopupVisible(true);
    };

    const closeFileUpload = () => {
        setFilePopupVisible(false);
        setSelectedFileName('');
    };

    const handleFileSelect = (e) => {
        const file = e.target.files[0];
        setSelectedFileName(file.name);
        if (file) {
            processExcel(file);
        }
    };

    const handleFileDrop = (e) => {
        e.preventDefault();
        const droppedFiles = e.dataTransfer.files[0];
        setSelectedFileName(droppedFiles.name);
        if (droppedFiles) {
            processExcel(droppedFiles);
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const processExcel = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: 'binary' });
            const firstSheet = workbook.SheetNames[0];
            const excelRows = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[firstSheet]);
    
            // Check if excelRows is empty
            if (excelRows.length === 0) {
                toast.error("Invalid File or Data");
                setFilePopupVisible(false);
                setSelectedFileName('');
                return;
            }
    
            // Extract existing data for comparison
            const existingData = electricityBillReportList.map(item => ({
                consumerNumber: String(item.consumerNumber),
                fromDate: item.fromDate,
                toDate: item.toDate,
                station_id: item.station_id
            }));
    
            const validateRow = (row) => {
                return row.consumerNumber !== undefined &&
                    row.toDate_DD_MM_YYYY &&
                    row.fromDate_DD_MM_YYYY &&
                    row.station_id &&
                    row.amount &&
                    row.unitConsumed !== undefined && row.unitConsumed !== null && row.unitConsumed !== '';
            };
    
            const hasMissingData = excelRows.some(row => !validateRow(row));
    
            if (hasMissingData) {
                toast.error("Please review data in excel. Check for any missing cell or 'unit consumed' must be filled and amount must be a positive number.");
                setFilePopupVisible(false);
                setSelectedFileName('');
                return;
            }
    
            // Extract station_ids from allstation for quick lookup
            const allstationIds = new Set(allStation.map(station => station.station_id));
    
            const errorStationIds = [];
            const invalidStationIds = [];
            const seenEntries = new Set();
            const duplicateEntries = new Set();
            const matchedEntries = [];
    
            const modifiedData = {
                bills: excelRows.map((item) => {
                    let fromDate, toDate;
    
                    // Handle Excel date formats
                    try {
                        if (typeof item.fromDate_DD_MM_YYYY === 'number') {
                            const excelDateObj = XLSX.SSF.parse_date_code(item.fromDate_DD_MM_YYYY);
                            fromDate = new Date(excelDateObj.y, excelDateObj.m - 1, excelDateObj.d);
                        } else {
                            fromDate = moment(item.fromDate_DD_MM_YYYY, 'DD/MM/YYYY').toDate();
                        }
    
                        if (typeof item.toDate_DD_MM_YYYY === 'number') {
                            const excelDateObj = XLSX.SSF.parse_date_code(item.toDate_DD_MM_YYYY);
                            toDate = new Date(excelDateObj.y, excelDateObj.m - 1, excelDateObj.d);
                        } else {
                            toDate = moment(item.toDate_DD_MM_YYYY, 'DD/MM/YYYY').toDate();
                        }
    
                        // Check if dates are valid
                        if (isNaN(fromDate.getTime()) || isNaN(toDate.getTime())) {
                            throw new Error('Invalid date format');
                        }
                    } catch (error) {
                        toast.error(`Invalid date format for station_id ${item.station_id}. Please check the date fields.`);
                        setFilePopupVisible(false);
                        setSelectedFileName('');
                        return null; // Skip this row
                    }
    
                    // Convert to ISO format
                    const fromDateISO = fromDate.toISOString();
                    const toDateISO = toDate.toISOString();
    
                    // Check if the station_id is valid
                    if (!allstationIds.has(item.station_id)) {
                        invalidStationIds.push(item.station_id);
                    }
    
                    if (new Date(toDateISO) < new Date(fromDateISO)) {
                        if (!errorStationIds.includes(item.station_id)) {
                            errorStationIds.push(item.station_id);
                        }
                    }
    
                    // Ensure consumerNumber is converted to a string and handle cases where it's `0` or an alphanumerical value
                    const consumerNumber = item.consumerNumber !== undefined ? String(item.consumerNumber) : '';
    
                    const entryKey = `${item.station_id}-${fromDateISO}-${toDateISO}-${consumerNumber}`;
                    if (seenEntries.has(entryKey)) {
                        duplicateEntries.add(entryKey);
                    } else {
                        seenEntries.add(entryKey);
                    }
    
                    // Check if the entry already exists in the electricityBillReportList
                    const entryExists = existingData.some(existingItem =>
                        existingItem.consumerNumber === consumerNumber &&
                        existingItem.fromDate === fromDateISO &&
                        existingItem.toDate === toDateISO &&
                        existingItem.station_id === item.station_id
                    );
    
                    if (entryExists) {
                        matchedEntries.push(entryKey);
                    }
    
                    return {
                        consumerNumber: consumerNumber,
                        toDate: toDateISO,
                        fromDate: fromDateISO,
                        station_id: item.station_id,
                        amount: item.amount,
                        unitConsumed: item.unitConsumed,
                        tenant: profileData.tenant.id
                    };
                }).filter(row => row !== null) // Remove any rows that were skipped due to errors
            };
    
            // Show a single toast with all the problematic station_ids
            if (errorStationIds.length > 0) {
                toast.error(`toDate is earlier than fromDate in station_id(s): ${errorStationIds.join(', ')}`);
                setFilePopupVisible(false);
                setSelectedFileName('');
                return;
            }
    
            // Show a single toast with all invalid station_ids
            if (invalidStationIds.length > 0) {
                const uniqueInvalidStationIds = [...new Set(invalidStationIds)]; // Remove duplicates
                toast.error(`Invalid station_id(s) found: ${uniqueInvalidStationIds.join(', ')}`);
                setFilePopupVisible(false);
                setSelectedFileName('');
                return;
            }
    
            // Show a single toast with all duplicate entries
            if (duplicateEntries.size > 0) {
                toast.error(`Duplicate entries found in Excel`);
                setFilePopupVisible(false);
                setSelectedFileName('');
                return;
            }
    
            // Show a single toast with all matched entries
            if (matchedEntries.length > 0) {
                toast.error(`Duplicate entries found in the Database`);
                setFilePopupVisible(false);
                setSelectedFileName('');
                return;
            }
    
            uploadelectricityBillReport(modifiedData);
        };
        reader.readAsBinaryString(file);
    };
    
    




    const handleDownload = () => {
        // Define the headers and sample data
        const headers = ['station_id', 'fromDate_DD_MM_YYYY', 'toDate_DD_MM_YYYY', 'amount', 'unitConsumed', 'consumerNumber'];
        const sampleData = [
            ['station_001', new Date('2024-08-01'), new Date('2024-08-31'), 100.00, 200, 'CN_12345'],
            ['station_002', new Date('2024-07-01'), new Date('2024-07-31'), 150.00, 300, 'CN_67890']
        ];

        const filename = 'SampleData';
        downloadExcelFile(headers, sampleData, filename);
    };

    const downloadExcelFile = (headers, data, filename) => {
        // Prepare data for the worksheet with headers
        const wsData = [headers, ...data];
        const ws = XLSX.utils.aoa_to_sheet(wsData);

        // Apply date formatting specifically for the date columns (B and C in this case)
        ws['B2:B100'] = { t: 'd', z: 'mm/dd/yyyy' };  // Column B is for fromDate
        ws['C2:C100'] = { t: 'd', z: 'mm/dd/yyyy' };  // Column C is for toDate

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        // Generate a binary string representation of the workbook
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary', cellDates: true });

        // Convert binary string to array buffer
        const buf = new ArrayBuffer(wbout.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < wbout.length; ++i) view[i] = wbout.charCodeAt(i) & 0xFF;

        // Create a Blob and trigger the download
        FileSaver.saveAs(new Blob([buf], { type: 'application/octet-stream' }), `${filename}.xlsx`);
    };



    const handleCloseDeleteModel = () => {
        setShowDeleteModal(false);
        setBillId('');
    };

    return (
        <>
            <LayoutContainer>
                <AdminHeader title={t('header.ElectriciyBillReport')} />
                <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
                    <div className="page-content-wrapper scrollable">
                        <div className="data-report--main">
                            <Card>
                                <div className="data-report__inner">
                                    <div className="data-report__box">
                                        <Row className="data-report__row">
                                            <Col lg={'auto'}>
                                                <div className="data-report-date_picker">
                                                    <DateTimePicker
                                                        onChangeOfStartDate={(item) => setFromDate(item)}
                                                        onChangeOfEndDate={(item) => setToDate(item)}
                                                        initialValueOfStartDate={moment().startOf('month')}
                                                        initialValueOfEndDate={moment(new Date()).endOf('day')}
                                                        closeOnSelect={true}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={'auto'} md={'auto'}>
                                                <div className="No-Load-block">
                                                    <Button onClick={searchElectricityData}>
                                                        {t('Search')}
                                                    </Button>
                                                </div>
                                            </Col>
                                            <Col lg={'auto'} md={'auto'}>
                                                <div className="No-Load-block">
                                                    <Button onClick={downloadFile}>
                                                        {t('Download')}
                                                    </Button>
                                                </div>
                                            </Col>
                                            <Col lg={'auto'} md={'auto'}>
                                                <div className="No-Load-block">
                                                    <Button onClick={handleFileUpload}>
                                                        <FiUpload size={20} /> {t('button.uploadFile')}
                                                    </Button>
                                                    <span className="download-sample--block">
                                                        <div
                                                            className="download-sample-file"
                                                            onClick={handleDownload}
                                                        >
                                                            {t('Download Sample')}
                                                        </div>
                                                    </span>
                                                    <Button onClick={() => navigateTo(`/AddElectricityBill`)}>
                                                        {t('Add Electricity Bill Details')}
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>

                                    {filePopupVisible && (
                                        <Modal show={filePopupVisible} centered onHide={closeFileUpload}>
                                            <div className="file-upload-popup">
                                                <div className="file-drop-area" onClick={handleClick} onDrop={handleFileDrop} onDragOver={handleDragOver}>
                                                    {selectedFileName ? (
                                                        <p className="small-text">
                                                            <SiMicrosoftexcel style={{ fontSize: '24px', marginBottom: '5px', color: '#5dad5d' }} />
                                                            <br />
                                                            {selectedFileName}
                                                        </p>
                                                    ) : (
                                                        <>
                                                            <p className="small-text">
                                                                <FaCloudUploadAlt style={{ fontSize: '24px', marginBottom: '5px' }} />
                                                                <br />
                                                                {t('dataReport.dragDrop')}
                                                            </p>
                                                            <input type="file" ref={fileInputRef} onChange={handleFileSelect} style={{ display: 'none' }} />
                                                        </>
                                                    )}
                                                </div>
                                                <Button onClick={closeFileUpload}> {t('button.cancel')}</Button>
                                            </div>
                                        </Modal>
                                    )}

                                    <div className="data-report__table">
                                        <div className="table-responsive">
                                            <table className="record-list-table" id="table-to-xls">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            <span>{isLoader ? <Skeleton height={15} width={100} /> : t('ElectriciyBillReport.srNo')}</span>
                                                        </th>
                                                        <th>
                                                            <span>{isLoader ? <Skeleton height={15} width={100} /> : t('ElectriciyBillReport.stationName')}</span>
                                                        </th>
                                                        <th>
                                                            <span>{isLoader ? <Skeleton height={15} width={100} /> : t('ElectriciyBillReport.stationId')}</span>
                                                        </th>
                                                        <th>
                                                            <span>{isLoader ? <Skeleton height={15} width={100} /> : t('ElectriciyBillReport.fromDate')}</span>
                                                        </th>
                                                        <th>
                                                            <span>{isLoader ? <Skeleton height={15} width={100} /> : t('ElectriciyBillReport.toDate')}</span>
                                                        </th>
                                                        <th>
                                                            <span>{isLoader ? <Skeleton height={15} width={100} /> : t('ElectriciyBillReport.amount')}</span>
                                                        </th>
                                                        <th>
                                                            <span>{isLoader ? <Skeleton height={15} width={100} /> : t('ElectriciyBillReport.unitConsumed')}</span>
                                                        </th>
                                                        <th>
                                                            <span>{isLoader ? <Skeleton height={15} width={100} /> : t('ElectriciyBillReport.customerNumber')}</span>
                                                        </th>
                                                        <th>
                                                            <span>{isLoader ? <Skeleton height={15} width={100} /> : t('ElectriciyBillReport.action')}</span>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {isLoader ? (
                                                        <tr>
                                                            <td colSpan={12} className="border-0">
                                                                <div className="loader--block">
                                                                    <Skeleton height={15} width={100} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ) : _.isEmpty(electricityBillReportList) ? (
                                                        <tr>
                                                            <td colSpan={10} className="border-0">
                                                                <div className="empty-data-block">{t('ElectriciyBillReport.nodata')}</div>
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        !(isLoader || _.isUndefined(isLoader)) &&
                                                        _.map(
                                                            _.filter(electricityBillReportList, (item) => item !== null),
                                                            (item, key) => {
                                                                const serial_num = limit * (page - 1) + key;
                                                                return (
                                                                    <>
                                                                        <tr key={`no-load-report-${serial_num}`}>
                                                                            <td>{isLoader ? <Skeleton height={15} width={100} /> : serial_num + 1}</td>
                                                                            <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'station.name', '-')}</td>
                                                                            <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'station_id', '-')}</td>
                                                                            <td>
                                                                                {isLoader ? (
                                                                                    <Skeleton height={15} width={100} />
                                                                                ) : _.get(item, 'fromDate', '-') ? (
                                                                                    moment(_.get(item, 'fromDate', '-')).format('DD/MM/YYYY')
                                                                                ) : (
                                                                                    '-'
                                                                                )}
                                                                            </td>
                                                                            <td>
                                                                                {isLoader ? (
                                                                                    <Skeleton height={15} width={100} />
                                                                                ) : _.get(item, 'toDate', '-') ? (
                                                                                    moment(_.get(item, 'toDate', '-')).format('DD/MM/YYYY')
                                                                                ) : (
                                                                                    '-'
                                                                                )}
                                                                            </td>
                                                                            <td>{isLoader ? <Skeleton height={15} width={100} /> : formatAmount(_.get(item, 'amount', '-'))}</td>
                                                                            <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'unitConsumed', '-')}</td>
                                                                            <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'consumerNumber', '-')}</td>
                                                                            <td>
                                                                                <span className="user-group-table-editIcon" title="Edit" onClick={() => navigateTo(`/EditElectricityBill/${_.get(item, 'id', '-')}`)}>
                                                                                    {isLoader ? <Skeleton circle="true" width={24} height={24} /> : <FiEdit2 title="Edit" />}
                                                                                </span>
                                                                                <span
                                                                                    title="Delete"
                                                                                    className="user-group-table-deleteIcon"
                                                                                    onClick={() => {
                                                                                        setShowDeleteModal(true);
                                                                                        setBillId(_.get(item, 'id', '-')); // This sets the partnerGroupId when delete icon is clicked
                                                                                    }}
                                                                                >
                                                                                    {isLoader ? <Skeleton circle="true" width={24} height={24} /> : <RiDeleteBinLine title="Delete" />}
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                );
                                                            }
                                                        )
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(electricityBillReportList) && (
                                        <ReactPagination
                                            currentPage={page}
                                            limit={limit}
                                            total={totalData}
                                            handlePageClick={(pageVal) => handlePageClick(pageVal)}
                                            totalPages={totalPages}
                                            marginPagesDisplayed={1}
                                        />
                                    )}
                                    {showDeleteModal && (
                                        <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
                                            <DeleteModal title="Electricity Bill Data" onClose={handleCloseDeleteModel} onRemove={deletebill} />
                                        </Modal>
                                    )}
                                </div>
                            </Card>
                        </div>
                        {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
                    </div>
                </SkeletonTheme>
            </LayoutContainer>
        </>
    );
};

export default ElectriciyBillReport;
