import * as _ from 'lodash';
import moment from 'moment';

export const startsWith = (sourceStr, search_string) => {
  if (!search_string) {
    return true;
  }

  return (sourceStr || '').toLowerCase().indexOf(search_string.toLowerCase()) >= 0;
};

export const millisecondsToHourMinute = (value) => {
  const d = moment.duration(value, 'milliseconds');
  const hours = Math.floor(d.asHours());
  const minute = Math.floor(d.asMinutes()) - hours * 60;
  return hours + ':' + minute;
};

export const millisecondsToHourMinuteSeconds = (value) => {
  const d = moment.duration(value, 'milliseconds');
  const hours = Math.floor(d.asHours());
  const minute = Math.floor(d.asMinutes()) - hours * 60;
  const seconds = Math.floor(d.asSeconds()) - minute * 60;
  return hours + ':' + minute + ':' + seconds;
};

export const roundOfDigit = (value, digit) => {
  return value === '-' ? value :_.round(value, digit).toFixed(2);
};

export const formatAmount = (value) => {
  if(value === '-' || typeof value == 'string') { 
    return value
  }
  return value.toFixed(2)
}