export const FETCH_TAGS = 'FETCH_TAGS';
export const FETCH_TAGS_SUCCESS = 'FETCH_TAGS_SUCCESS';
export const FETCH_TAGS_FAIL='FETCH_TAGS_FAIL';

export const CREATE_TAGS = 'CREATE_TAGS';
export const CREATE_TAGS_SUCCESS = 'CREATE_TAGS_SUCCESS';
export const CREATE_TAGS_FAIL='CREATE_TAGS_FAIL';

export const DELETE_TAGS = 'DELETE_TAGS';
export const DELETE_TAGS_SUCCESS = 'DELETE_TAGS_SUCCESS';
export const DELETE_TAGS_FAIL='DELETE_TAGS_FAIL';

export const UPDATE_TAGS = 'UPDATE_TAGS';
export const UPDATE_TAGS_SUCCESS= 'UPDATE_TAGS_SUCCESS';
export const UPDATE_TAGS_FAIL='UPDATE_TAGS_FAIL';

export const GET_TAG = 'GET_TAG';
export const GET_TAG_SUCCESS = 'GET_TAG_SUCCESS';
export const GET_TAG_FAIL = 'GET_TAG_FAIL';
