export const FETCH_CHARGER_LOG = 'FETCH_CHARGER_LOG';
export const FETCH_CHARGER_LOG_SUCCESS = 'FETCH_CHARGER_LOG_SUCCESS';
export const FETCH_CHARGER_LOG_FAIL = 'FETCH_CHARGER_LOG_FAIL';

export const DELETE_CHARGER_LOG = 'DELETE_CHARGER_LOG';
export const DELETE_CHARGER_LOG_SUCCESS = 'DELETE_CHARGER_LOG_SUCCESS';
export const DELETE_CHARGER_LOG_FAIL = 'DELETE_CHARGER_LOG_FAIL';

export const DOWNLOAD_CHARGER_LOG_DATA = 'DOWNLOAD_CHARGER_LOG_DATA';
export const DOWNLOAD_CHARGER_LOG_DATA_SUCCESS = 'DOWNLOAD_CHARGER_LOG_DATA_SUCCESS';
export const DOWNLOAD_CHARGER_LOG_DATA_FAIL = 'DOWNLOAD_CHARGER_LOG_DATA_FAIL';

export const DOWNLOAD_TABULAR_METERVALUE = 'DOWNLOAD_TABULAR_METERVALUE';
export const DOWNLOAD_TABULAR_METERVALUE_SUCCESS = 'DOWNLOAD_TABULAR_METERVALUE_SUCCESS';
export const DOWNLOAD_TABULAR_METERVALUE_FAIL = 'DOWNLOAD_TABULAR_METERVALUE_FAIL';

export const FETCH_USER_ACTIVITY = 'FETCH_USER_ACTIVITY';
export const FETCH_USER_ACTIVITY_SUCCESS = 'FETCH_USER_ACTIVITY_SUCCESS';
export const FETCH_USER_ACTIVITY_FAIL = 'FETCH_USER_ACTIVITY_FAIL';
