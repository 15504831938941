export const FETCH_CHARGE_SPEED = 'FETCH_CHARGE_SPEED';
export const FETCH_CHARGE_SPEED_SUCCESS = 'FETCH_CHARGE_SPEED_SUCCESS';
export const FETCH_CHARGE_SPEED_FAIL = 'FETCH_CHARGE_SPEED_FAIL';

export const GET_CHARGE_SPEED = 'GET_CHARGE_SPEED';
export const GET_CHARGE_SPEED_SUCCESS = 'GET_CHARGE_SPEED_SUCCESS';
export const GET_CHARGE_SPEED_FAIL = 'GET_CHARGE_SPEED_FAIL';

export const CREATE_CHARGE_SPEED = 'CREATE_CHARGE_SPEED';
export const CREATE_CHARGE_SPEED_SUCCESS = 'CREATE_CHARGE_SPEED_SUCCESS';
export const CREATE_CHARGE_SPEED_FAIL = 'CREATE_CHARGE_SPEED_FAIL';

export const UPDATE_CHARGE_SPEED = 'UPDATE_CHARGE_SPEED';
export const UPDATE_CHARGE_SPEED_SUCCESS = ' UPDATE_CHARGE_SPEED_SUCCESS';
export const UPDATE_CHARGE_SPEED_FAIL = 'UPDATE_CHARGE_SPEED_FAIL';

export const DELETE_CHARGE_SPEED = 'DELETE_CHARGE_SPEED';
export const DELETE_CHARGE_SPEED_SUCCESS = 'DELETE_CHARGE_SPEED_SUCCESS';
export const DELETE_CHARGE_SPEED_FAIL = ' DELETE_CHARGE_SPEED_FAIL';
