export const DELETE_ELECTRICITY_BILL = 'DELETE_ELECTRICITY_BILL';
export const DELETE_ELECTRICITY_BILL_SUCCESS = 'DELETE_ELECTRICITY_BILL_SUCCESS';
export const DELETE_ELECTRICITY_BILL_FAIL = 'DELETE_ELECTRICITY_BILL_FAIL';

export const GET_ELECTRICITY_BILL = 'GET_ELECTRICITY_BILL';
export const GET_ELECTRICITY_BILL_SUCCESS = 'GET_ELECTRICITY_BILL_SUCCESS';
export const GET_ELECTRICITY_BILL_FAIL = 'GET_ELECTRICITY_BILL_FAIL';

export const UPDATE_ELECTRICITY_BILL = 'UPDATE_ELECTRICITY_BILL';
export const UPDATE_ELECTRICITY_BILL_SUCCESS = 'UPDATE_ELECTRICITY_BILL_SUCCESS';
export const UPDATE_ELECTRICITY_BILL_FAIL = 'UPDATE_ELECTRICITY_BILL_FAIL';

export const UPLOAD_ELECTRICITY_BILL = 'UPLOAD_ELECTRICITY_BILL';
export const UPLOAD_ELECTRICITY_BILL_SUCCESS = 'UPLOAD_ELECTRICITY_BILL_SUCCESS';
export const UPLOAD_ELECTRICITY_BILL_FAIL = 'UPLOAD_ELECTRICITY_BILL_FAIL';

export const ADD_ELECTRICITY_BILL = 'ADD_ELECTRICITY_BILL';
export const ADD_ELECTRICITY_BILL_SUCCESS = 'ADD_ELECTRICITY_BILL_SUCCESS';
export const ADD_ELECTRICITY_BILL_FAIL = 'ADD_ELECTRICITY_BILL_FAIL';

export const DOWNLOAD_ELECTRICITY_BILL = 'DOWNLOAD_ELECTRICITY_BILL';
export const DOWNLOAD_ELECTRICITY_BILL_SUCCESS = 'DOWNLOAD_ELECTRICITY_BILL_SUCCESS';
export const DOWNLOAD_ELECTRICITY_BILL_FAIL = 'DOWNLOAD_ELECTRICITY_BILL_FAIL';