import {
    FETCH_TENANT_SETTING,
    FETCH_TENANT_SETTING_SUCCESS,
    FETCH_TENANT_SETTING_FAIL,
    GET_TENANT_SETTING,
    GET_TENANT_SETTING_SUCCESS,
    GET_TENANT_SETTING_FAIL, 
    UPDATE_TENANT_SETTING,
    UPDATE_TENANT_SETTING_SUCCESS,
    UPDATE_TENANT_SETTING_FAIL,
  } from 'actions/tenantSetting';
  import { toast } from 'react-toastify';
  
  const initialState = {
    isLoading: false,
    tenantSettings: [],
    page: 1,
    limit: 10,
    totalPages: 1,
    total: 1,
  };
  
  const tenantSettingReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case FETCH_TENANT_SETTING:
      case GET_TENANT_SETTING:
      case UPDATE_TENANT_SETTING: {
        return { ...state, isLoading: true };
      }
  
      case FETCH_TENANT_SETTING_SUCCESS: {
        return {
          ...state,
          isLoading: false,
          tenantSettings: payload.results,
          page: payload.page,
          limit: payload.limit,
          totalPages: payload.totalPages,
          total: payload.totalResults,
        };
      }
  
      case GET_TENANT_SETTING_SUCCESS: {
        return {
          ...state,
          isLoading: false,
        };
      }
  
      case UPDATE_TENANT_SETTING_SUCCESS: {
        toast.success('Tenant settings updated successfully');
        return {
          ...state,
          isLoading: false,
          tenantSettings: state.tenantSettings.map((data) => (data.id === payload.id ? payload : data)),
        };
      }
  
      case FETCH_TENANT_SETTING_FAIL:
      case GET_TENANT_SETTING_FAIL:
      case UPDATE_TENANT_SETTING_FAIL: {
        return {
          ...state,
          isLoading: false,
        };
      }
      default:
        return {
          ...state,
        };
    }
  };
  export default tenantSettingReducer;