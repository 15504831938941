import React, { useCallback, useEffect, useState } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import Card from 'components/inputs/Card';
import * as _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_FLEET_MEMBERS } from 'actions/user';
import ReactPagination from 'components/general/ReactPagination';
import { SUPER_ADMIN } from 'components/common/constant';
import { Spinner } from 'react-bootstrap';
import { BiSort } from 'react-icons/bi';
import { Col, Row } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
import SearchBox from 'components/general/SearchBox';
import Button from 'components/inputs/Button';
import { MdClear } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const MemberList = () => {
  const dispatch = useDispatch();
  const { fleetId } = useParams();
  const [searchText, setSearchText] = useState('');
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const { t } = useTranslation();
  const isLoader = useSelector((state) => state.fleetMember.isLoading);
  const allMember = useSelector((state) => state.fleetMember.FleetMember);
  const page = useSelector((state) => state.fleetMember.page);
  const totalData = useSelector((state) => state.fleetMember.total);
  const limit = useSelector((state) => state.fleetMember.limit);
  const totalPages = useSelector((state) => state.fleetMember.totalPages);

  const getAllUsers = useCallback(
    (data = {}) => {
      if (searchText) {
        const userData = {
          ...data,
          search: searchText,
          id: fleetId,
        };
        dispatch({
          type: FETCH_FLEET_MEMBERS,
          payload: userData,
        });
      } else {
        dispatch({
          type: FETCH_FLEET_MEMBERS,
          payload: { ...data, id: fleetId },
        });
      }
    },
    [searchText, fleetId]
  );

  const dataFilter = useCallback(() => {
    if (searchText) {
      const filterDateData = {
        search: searchText,
      };
      getAllUsers(filterDateData);
    } else {
      getAllUsers();
    }
  }, [searchText]);

  useEffect(() => {
    getAllUsers();
  }, []);

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else if (searchText) {
        const data = {
          search: searchText,
          page: page.selected + 1,
        };
        getAllUsers(data);
      } else {
        const data = {
          page: page.selected + 1,
        };
        getAllUsers(data);
      }
    },
    [sortByItem, searchText]
  );

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getAllUsers(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getAllUsers(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handleReset = useCallback(() => {
    getAllUsers({});
  }, []);

  const handleSearch = () => {
    getAllUsers({});
  };

  return (
    <LayoutContainer>
      <Card className="customerDetail-table">
        <div>
          <div className="customer-details-search--block" style={{ border: 'none', borderRadius: 'none' }}>
            <Row>
              <Col xl={11} md={11}>
                <SearchBox
                  preIcon={<BsSearch />}
                  postIcon={
                    <MdClear
                      onClick={() => {
                        setSearchText('');
                        handleReset();
                      }}
                    />
                  }
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleSearch();
                    }
                  }}
                />
              </Col>
              <Col xl={'auto'} md={'auto'}>
                <div className="customerList-add-boxButton" onClick={dataFilter}>
                  <Button className="customerList-add-box-Btn">{t('button.search')}</Button>
                </div>
              </Col>
            </Row>
          </div>
          <div className="role-access-page__main">
            <div className="table-responsive">
              <table className="record-list-table">
                <thead>
                  <tr>
                    <th>
                      <div className="sorting">
                        <span>{t('memberList.srNo')}</span>
                      </div>
                    </th>
                    <th>
                      <div className="sorting">
                        <span>{t('memberList.name')}</span>
                        <span className="ico" onClick={() => handleSorting('name', sortByItem)}>
                          <BiSort size={15} />
                        </span>
                      </div>
                    </th>
                    <th>
                      <div className="sorting">
                        <span>{t('memberList.email')}</span>
                        <span className="ico" onClick={() => handleSorting('email', sortByItem)}>
                          <BiSort size={15} />
                        </span>
                      </div>
                    </th>
                    <th>
                      <div className="sorting">
                        <span>{t('memberList.phoneNo')}</span>
                      </div>
                    </th>
                    <th>
                      <div className="sorting">
                        <span>{t('memberList.vehicleName')}</span>
                      </div>
                    </th>
                    <th>
                      <div className="sorting">
                        <span>{t('memberList.vehicleRegistrationNo')}</span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {isLoader ? (
                    <tr>
                      <td colSpan={10} className="border-0">
                        <div className="loader-block___">
                          <Spinner as="span" animation="border" size="sm" role="status" />
                          <span className="ps-2">{t('placeHolder.loading')}</span>
                        </div>
                      </td>
                    </tr>
                  ) : _.isEmpty(allMember) ? (
                    <tr>
                      <td colSpan={5} className="border-0">
                        <div className="empty-data-block">{t('memberList.noData')}</div>
                      </td>
                    </tr>
                  ) : (
                    !(isLoader || _.isUndefined(isLoader)) &&
                    _.map(
                      _.filter(allMember, (userData) => userData.role !== SUPER_ADMIN),
                      (user, key) => {
                        const serial_num = limit * (page - 1) + key;
                        return (
                          <tr key={`user-index-${serial_num}`}>
                            <td>{serial_num + 1}</td>
                            <td>{_.get(user, 'name', '')}</td>
                            <td>{_.get(user, 'email', '')}</td>
                            <td>
                              +{_.get(user, 'country_code', '')}
                              {_.get(user, 'phone', 'NA')}
                            </td>
                            <td>
                              {_.get(user, 'vehicles').length > 0 ? `${_.get(user, 'vehicles[0].make')} (${_.get(user, 'vehicles[0].model')})` : '-'}
                            </td>
                            <td>{_.get(user, 'vehicles').length > 0 ? _.get(user, 'vehicles[0].rto_no') : '-'}</td>
                          </tr>
                        );
                      }
                    )
                  )}
                </tbody>
              </table>
            </div>
            {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(allMember) && (
              <ReactPagination
                currentPage={page}
                limit={limit}
                total={totalData}
                handlePageClick={(pageVal) => handlePageClick(pageVal)}
                totalPages={totalPages}
                marginPagesDisplayed={1}
              />
            )}
          </div>
        </div>
      </Card>
    </LayoutContainer>
  );
};
export default MemberList;
