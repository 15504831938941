import React, { useState, useCallback, useEffect, useContext } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header';
import Card from 'components/inputs/Card';
import { BsSearch } from 'react-icons/bs';
import Button from 'components/inputs/Button';
import { FiEdit2 } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { Row, Col, Spinner } from 'react-bootstrap';
import * as _ from 'lodash';
import SearchBox from 'components/general/SearchBox';
import { useDispatch, useSelector } from 'react-redux';
// import Pagination from 'components/general/Pagination';
import ReactPagination from 'components/general/ReactPagination';
import { Modal } from 'react-bootstrap';
import { DELETE_ROLE, FETCH_ROLE, GET_ROLE } from 'actions/role';
import RoleForm from './RoleForm';
import DeleteModal from 'components/general/DeleteModal';
import { DELETE_STATUS_CODE } from 'components/common/constant';
import { BiSort } from 'react-icons/bi';
import { MdOutlineSettingsSuggest } from 'react-icons/md';
// import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { NavContext } from 'components/privateRoute/PrivateRoute';

const RoleList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // const history = useHistory();
  const { navigateTo } = useContext(NavContext);
  const [showForm, setShowForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [searchText, setSearchText] = useState('');
  // const [allRoleData, setAllRoleData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [roleId, setRoleId] = useState('');
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [pageWiseRoleData, setPageWiseRoleData] = useState([]);

  const page = useSelector((state) => state.role.page);
  const totalData = useSelector((state) => state.role.total);
  const limit = useSelector((state) => state.role.limit);
  const totalPages = useSelector((state) => state.role.totalPages);
  const roleList = useSelector((state) => state.role.roles);
  const isLoader = useSelector((state) => state.role.isLoading);

  const roleData = roleList.filter(({ id: id1 }) => pageWiseRoleData.some(({ id: id2 }) => id2 === id1));

  const getRole = useCallback(
    (data = {}) => {
      if (searchText) {
        const searchRoleData = {
          ...data,
          search: searchText,
          is_hidden: false,
        };
        dispatch({
          type: FETCH_ROLE,
          payload: searchRoleData,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                setPageWiseRoleData(res.data.results);
              }
            }
          },
        });
      } else {
        const roleData = {
          ...data,
          is_hidden: false,
        };
        dispatch({
          type: FETCH_ROLE,
          payload: roleData,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                setPageWiseRoleData(res.data.results);
              }
            }
          },
        });
      }
    },
    [pageWiseRoleData, searchText]
  );

  const getRoleDetail = useCallback((id) => {
    dispatch({ type: GET_ROLE, payload: id });
  }, []);

  const handleCloseDeleteModel = () => {
    setShowDeleteModal(false);
    setRoleId('');
  };

  const deleteRole = useCallback(
    () =>
      dispatch({
        type: DELETE_ROLE,
        payload: roleId,
        cb: (res) => {
          if (_.get(res, 'status', '') === DELETE_STATUS_CODE) {
            handleCloseDeleteModel();
            getRole({ page: 1 });
          }
        },
      }),
    [roleId]
  );

  useEffect(() => {
    getRole();
  }, []);

  const handleSearch = () => {
    getRole({});
  };

  // useEffect(() => {
  //   setAllRoleData(_.filter(roleData, (item) => startsWith(_.get(item, 'name', ''), searchText)));
  // }, [searchText, roleList, pageWiseRoleData]);

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else {
        const data = {
          page: page.selected + 1,
        };
        getRole(data);
      }
    },
    [sortByItem]
  );

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getRole(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getRole(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handleCloseFormRole = () => {
    setShowForm(false);
    setIsEdit(false);
  };

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.role')} />
        <div className="page-content-wrapper scrollable">
          <div className="roleList-page">
            <Card>
              <div className="roleList-page-wrapper">
                <Row className="roleList-search-box">
                  <Col xl={10} lg={9} md={8} className="roleList-search-inputBox">
                    <SearchBox
                      value={searchText}
                      preIcon={<BsSearch />}
                      onChange={(e) => setSearchText(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          handleSearch();
                        }
                      }}
                    />
                  </Col>
                  <Col xl={2} lg={3} md={4} className="roleList-search-boxButton">
                    <Button
                      className="roleList-search-box-Btn"
                      onClick={() => {
                        setShowForm(true);
                        setIsEdit(false);
                      }}
                    >
                      {t('button.addRole')}
                    </Button>
                  </Col>
                </Row>
                <div className="roleList-table">
                  <div className="table-responsive">
                    <table className="record-list-table">
                      <thead>
                        <tr>
                          <th>
                            <div className="sorting">
                              <span>{t('roleForm.roleName')}</span>
                              <span className="ico" onClick={() => handleSorting('name', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>{t('roleForm.configure')}</span>
                            </div>
                          </th>
                          <th className="action-col">
                            <span>{t('roleForm.action')}</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoader ? (
                          <tr>
                            <td colSpan={10} className="border-0">
                              <div className="loader--block">
                                <Spinner as="span" animation="border" size="sm" role="status" />
                                <span className="ps-2">{t('placeHolder.loading')}</span>
                              </div>
                            </td>
                          </tr>
                        ) : _.isEmpty(roleData) ? (
                          <tr>
                            <td colSpan={3} className="border-0">
                              <div className="empty-data-block">{t('roleForm.noData')}</div>
                            </td>
                          </tr>
                        ) : (
                          !(isLoader || _.isUndefined(isLoader)) &&
                          _.map(roleData, (item, key) => {
                            return (
                              <>
                                <tr key={`role-${key}`}>
                                  <td>{_.get(item, 'name', '')}</td>
                                  <td>
                                    <div className="configuration-icon" onClick={() => navigateTo(`/configureRole/${_.get(item, 'id', '')}`)}>
                                      <MdOutlineSettingsSuggest size={19} />
                                    </div>
                                  </td>
                                  <td>
                                    <span
                                      className="roleList-table-editIcon"
                                      onClick={() => {
                                        setShowForm(true);
                                        getRoleDetail(_.get(item, 'id', ''));
                                        setIsEdit(true);
                                      }}
                                    >
                                      <FiEdit2 title="Edit" />
                                    </span>
                                    <span
                                      className="roleList-table-deleteIcon"
                                      onClick={() => {
                                        setShowDeleteModal(true);
                                        setRoleId(_.get(item, 'id', ''));
                                      }}
                                    >
                                      <RiDeleteBinLine title="Delete" />
                                    </span>
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(roleData) && (
                  <ReactPagination
                    currentPage={page}
                    limit={limit}
                    total={totalData}
                    handlePageClick={(pageVal) => handlePageClick(pageVal)}
                    totalPages={totalPages}
                    marginPagesDisplayed={1}
                  />
                  // <Pagination currentPage={page} limit={limit} total={totalData} onPageChange={(pageVal) => handlePageClick(pageVal)} />
                )}
              </div>
            </Card>
          </div>
        </div>
        {showForm && (
          <Modal show={showForm} size="lg" centered>
            <RoleForm onClose={handleCloseFormRole} isEdit={isEdit} onSuccess={getRole} />
          </Modal>
        )}
        {showDeleteModal && (
          <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
            <DeleteModal title="role" onClose={handleCloseDeleteModel} onRemove={deleteRole} />
          </Modal>
        )}
      </LayoutContainer>
    </>
  );
};
export default RoleList;
