export const FETCH_MODULE = 'FETCH_MODULE';
export const FETCH_MODULE_SUCCESS = 'FETCH_MODULE_SUCCESS';
export const FETCH_MODULE_FAIL = 'FETCH_MODULE_FAIL';

export const GET_MODULE = 'GET_MODULE';
export const GET_MODULE_SUCCESS = 'GET_MODULE_SUCCESS';
export const GET_MODULE_FAIL = 'GET_MODULE_FAIL';

export const CREATE_MODULE = 'CREATE_MODULE';
export const CREATE_MODULE_SUCCESS = 'CREATE_MODULE_SUCCESS';
export const CREATE_MODULE_FAIL = 'CREATE_MODULE_FAIL';

export const UPDATE_MODULE = 'UPDATE_MODULE_SUPER';
export const UPDATE_MODULE_SUCCESS = ' UPDATE_MODULE_SUCCESS_SUPER';
export const UPDATE_MODULE_FAIL = 'UPDATE_MODULE_FAIL_SUPER';

export const DELETE_MODULE = 'DELETE_MODULE';
export const DELETE_MODULE_SUCCESS = 'DELETE_MODULE_SUCCESS';
export const DELETE_MODULE_FAIL = ' DELETE_MODULE_FAIL';

export const MANAGE_MODULE = 'MANAGE_MODULE';
export const MANAGE_MODULE_SUCCESS = 'MANAGE_MODULE_SUCCESS';
export const MANAGE_MODULE_FAIL = ' MANAGE_MODULE_FAIL';

export const FETCH_MODULE_SORTED = 'FETCH_MODULE_SORTED';
export const FETCH_MODULE_SORTED_SUCCESS = 'FETCH_MODULE_SORTED_SUCCESS';
export const FETCH_MODULE_SORTED_FAIL = 'FETCH_MODULE_SORTED_FAIL';
