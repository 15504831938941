export const FETCH_ELECTRICITY_SUPPLIER = 'FETCH_ELECTRICITY_SUPPLIER';
export const FETCH_ELECTRICITY_SUPPLIER_SUCCESS = 'FETCH_ELECTRICITY_SUPPLIER_SUCCESS';
export const FETCH_ELECTRICITY_SUPPLIER_FAIL = 'FETCH_ELECTRICITY_SUPPLIER_FAIL';

export const GET_ELECTRICITY_SUPPLIER = 'GET_ELECTRICITY_SUPPLIER_MAKE';
export const GET_ELECTRICITY_SUPPLIER_SUCCESS = 'GET_ELECTRICITY_SUPPLIER_SUCCESS';
export const GET_ELECTRICITY_SUPPLIER_FAIL = 'GET_ELECTRICITY_SUPPLIER_FAIL';

export const CREATE_ELECTRICITY_SUPPLIER = 'CREATE_ELECTRICITY_SUPPLIER';
export const CREATE_ELECTRICITY_SUPPLIER_SUCCESS = 'CREATE_ELECTRICITY_SUPPLIER_SUCCESS';
export const CREATE_ELECTRICITY_SUPPLIER_FAIL = 'CREATE_ELECTRICITY_SUPPLIER_FAIL';

export const UPDATE_ELECTRICITY_SUPPLIER = 'UPDATE_ELECTRICITY_SUPPLIER';
export const UPDATE_ELECTRICITY_SUPPLIER_SUCCESS = 'UPDATE_ELECTRICITY_SUPPLIER_SUCCESS';
export const UPDATE_ELECTRICITY_SUPPLIER_FAIL = 'UPDATE_ELECTRICITY_SUPPLIER_FAIL';

export const DELETE_ELECTRICITY_SUPPLIER = 'DELETE_ELECTRICITY_SUPPLIER';
export const DELETE_ELECTRICITY_SUPPLIER_SUCCESS = 'DELETE_ELECTRICITY_SUPPLIER_SUCCESS';
export const DELETE_ELECTRICITY_SUPPLIER_FAIL = 'DELETE_ELECTRICITY_SUPPLIER_FAIL';
