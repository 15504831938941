import {
  FETCH_CUSTOMER,
  FETCH_CUSTOMER_SUCCESS,
  FETCH_CUSTOMER_FAIL,
  FETCH_WIDGETS,
  FETCH_WIDGETS_SUCCESS,
  FETCH_WIDGETS_FAIL,
  GET_CUSTOMER,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_FAIL,
  CREATE_CUSTOMER,
  CREATE_CUSTOMER_SUCCESS,
  CREATE_CUSTOMER_FAIL,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAIL,
  DELETE_CUSTOMER,
  DELETE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_FAIL,
  FETCH_ROLES,
  FETCH_ROLES_SUCCESS,
  FETCH_ROLES_FAIL,
} from 'actions/customer';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  search: '',
  customers: [],
  customerDetail: {},
  role: [],
  page: 1,
  limit: 5,
  totalPages: 1,
  total: 1,
};

const customerReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_CUSTOMER:
    case CREATE_CUSTOMER:
    case DELETE_CUSTOMER:
    case FETCH_ROLES:
    case FETCH_WIDGETS: {
      return { ...state, isLoading: true };
    }
    case FETCH_CUSTOMER: {
      return { ...state, isLoading: true, search: '' };
    }
    case FETCH_CUSTOMER_SUCCESS: {
      // if (payload.page === 1) {
      //   return {
      //     ...state,
      //     isLoading: false,
      //     customers: payload.results,
      //     page: payload.page,
      //     limit: payload.limit,
      //     totalPages: payload.totalPages,
      //     total: payload.totalResults,
      //   };
      // } else {
      //   return {
      //     ...state,
      //     isLoading: false,
      //     customers: _.concat(state.customers, payload.results),
      //     page: payload.page,
      //     limit: payload.limit,
      //     totalPages: payload.totalPages,
      //     total: payload.totalResults,
      //   };
      // }
      return {
        ...state,
        isLoading: false,
        search: payload.config?.params?.search,
        customers: payload.data?.results,
        page: payload.data?.page,
        limit: payload.data?.limit,
        totalPages: payload.data?.totalPages,
        total: payload.data?.totalResults,
      };
    }

    case FETCH_ROLES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        role: payload?.data,
      };
    }

    case FETCH_WIDGETS_SUCCESS: {
      return {
        ...state,
        totalUserCount: payload.data?.userCount,
        activeUser: payload.data?.activeUsers,
        inactiveUser: payload.data?.inactiveUsers,
        androidUser: payload.data?.androidUsers,
        iosUser: payload.data?.iosUsers,
      };
    }

    case GET_CUSTOMER_SUCCESS: {
      return {
        ...state,
        customerDetail: payload,
        isLoading: false,
      };
    }

    case CREATE_CUSTOMER_SUCCESS: {
      toast.success('Customer added successfully');
      setTimeout(() => {
        window.location.href = '/customers';
      }, 500);
      return {
        ...state,
        customers: _.concat(state.customers, payload),
        isLoading: false,
      };
    }

    case UPDATE_CUSTOMER: {
      return { ...state, isLoading: true };
    }

    case UPDATE_CUSTOMER_SUCCESS: {
      // toast.success('Customer updated successfully');
      return {
        ...state,
        customers: state.customers.map((data) => {
          if (data.id === payload.id) {
            return payload;
          } else {
            return data;
          }
        }),
      };
    }

    case DELETE_CUSTOMER_SUCCESS: {
      toast.success('Customer deleted successfully');
      return {
        ...state,
        customers: state.customers.filter((data) => data.id !== payload),
      };
    }

    case FETCH_CUSTOMER_FAIL:
    case FETCH_WIDGETS_FAIL:
    case FETCH_ROLES_FAIL:
    case GET_CUSTOMER_FAIL:
    case CREATE_CUSTOMER_FAIL:
    case UPDATE_CUSTOMER_FAIL:
    case DELETE_CUSTOMER_FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};
export default customerReducer;
