export const FETCH_RFID_REQUEST = 'FETCH_RFID_REQUEST';
export const FETCH_RFID_REQUEST_SUCCESS = 'FETCH_RFID_REQUEST_SUCCESS';
export const FETCH_RFID_REQUEST_FAIL = 'FETCH_RFID_REQUEST_FAIL';

export const GET_RFID_REQUEST = 'GET_RFID_REQUEST';
export const GET_RFID_REQUEST_SUCCESS = 'GET_RFID_REQUEST_SUCCESS';
export const GET_RFID_REQUEST_FAIL = 'GET_RFID_REQUEST_FAIL';

export const UPDATE_RFID_REQUEST = 'UPDATE_RFID_REQUEST';
export const UPDATE_RFID_REQUEST_SUCCESS = ' UPDATE_RFID_REQUEST_SUCCESS';
export const UPDATE_RFID_REQUEST_FAIL = 'UPDATE_RFID_REQUEST_FAIL';

export const DELETE_RFID_REQUEST = 'DELETE_RFID_REQUEST';
export const DELETE_RFID_REQUEST_SUCCESS = 'DELETE_RFID_REQUEST_SUCCESS';
export const DELETE_RFID_REQUEST_FAIL = ' DELETE_RFID_REQUEST_FAIL';

export const DOWNLOAD_RFID_REQUEST = 'DOWNLOAD_RFID_REQUEST';
export const DOWNLOAD_RFID_REQUEST_SUCCESS = 'DOWNLOAD_RFID_REQUEST_SUCCESS';
export const DOWNLOAD_RFID_REQUEST_FAIL = 'DOWNLOAD_RFID_REQUEST_FAIL';
