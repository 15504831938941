import { SHOW_SIDEBAR } from 'actions/sidebar';

const initialState = {
  showSidebar: false,
};

const sidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_SIDEBAR: {
      return { ...state, showSidebar: !state.showSidebar };
    }

    default:
      return { ...state };
  }
};
export default sidebarReducer;
