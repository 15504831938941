import { UPDATE_PROFILE, UPDATE_PROFILE_FAIL, UPDATE_PROFILE_SUCCESS, GET_PROFILE, GET_PROFILE_SUCCESS, GET_PROFILE_FAIL } from '../actions/profile';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  userProfile: {},
};

const userProfileReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_PROFILE:
    case GET_PROFILE: {
      return { ...state, isLoading: true };
    }

    case UPDATE_PROFILE_SUCCESS: {
      toast.success('Profile updated successfully');
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      return { ...state, userProfile: payload, isLoading: false };
    }

    case GET_PROFILE_SUCCESS: {
      return {
        ...state,
        userProfile: payload,
        isLoading: false,
      };
    }

    case UPDATE_PROFILE_FAIL:
    case GET_PROFILE_FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};
export default userProfileReducer;
