export const FETCH_USER = 'FETCH_USER';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAIL = 'FETCH_USER_FAIL';

export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';

export const CHANGE_ROLE = 'CHANGE_ROLE';
export const CHANGE_ROLE_SUCCESS = 'CHANGE_ROLE_SUCCESS';
export const CHANGE_ROLE_FAIL = 'CHANGE_ROLE_FAIL';

export const FETCH_FLEET_MEMBERS = 'FETCH_FLEET_MEMBERS';
export const FETCH_FLEET_MEMBERS_SUCCESS = 'FETCH_FLEET_MEMBERS_SUCCESS';
export const FETCH_FLEET_MEMBERS_FAIL = 'FETCH_FLEET_MEMBERS_FAIL';