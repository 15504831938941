import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import { GET_SMC_PAYMENT_GATEWAY, GET_SMC_PAYMENT_GATEWAY_SUCCESS, GET_SMC_PAYMENT_GATEWAY_FAIL } from 'actions/smcGateway';

function* getSMCGateway(action) {
  try {
    const response = yield call(API.getSMCGateway, action.payload);
    yield put({ type: GET_SMC_PAYMENT_GATEWAY_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: GET_SMC_PAYMENT_GATEWAY_FAIL, payload: { error: e } });
  }
}

function* smcGateway() {
  yield takeLatest(GET_SMC_PAYMENT_GATEWAY, getSMCGateway);
}

export default smcGateway;
