import React, { useCallback, useEffect, useContext, useState } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header/index';
import Card from 'components/inputs/Card';
import { Formik, Form } from 'formik';
import { Row, Col } from 'react-bootstrap';
import TextInput from 'components/inputs/Input';
import Select from 'components/inputs/Select';
import Button from 'components/inputs/Button';
import * as Yup from 'yup';
import { CREATE_VEHICLE, GET_VEHICLE, UPDATE_VEHICLE } from 'actions/vehicle';
import { FETCH_USER } from 'actions/user';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as _ from 'lodash';
import { FETCH_VEHICLE_MAKE } from 'actions/vehicleMake';
import { FETCH_VEHICLE_MODEL } from 'actions/vehicleModel';
import { CREATE_STATUS_CODE, GET_ADD_ONS, GET_VEHICLES, MANAGE_ADD_ONS, MANAGE_VEHICLES, UPDATE_STATUS_CODE } from 'components/common/constant';
import { FETCH_RFID_FOR_FLEET } from 'actions/rfid';
import { FETCH_CUSTOMER } from 'actions/customer';
import { useTranslation } from 'react-i18next';
import Footer from 'components/general/Footer';
import { NavContext } from 'components/privateRoute/PrivateRoute';

const ManageVehicleForm = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { navigateTo } = useContext(NavContext);

  const { vehicleId } = useParams();
  const [searchText, setSearchText] = useState('');

  const vehicleDetail = useSelector((state) => state.vehicle.vehicleDetail);
  const allVehicleMake = useSelector((state) => state.vehicleMake.vehicleMakes);
  const allVehicleModel = useSelector((state) => state.vehicleModel.vehicleModels);
  const profileDetail = useSelector((state) => state.profile.userProfile);
  const tenantId = _.get(profileDetail, 'tenant', '');
  const currentUserId = _.get(profileDetail, 'id', '');
  const makePage = useSelector((state) => state.vehicleMake.page);
  const modelPage = useSelector((state) => state.vehicleModel.page);
  const makeTotalPage = useSelector((state) => state.vehicleMake.totalPages);
  const modelTotalPage = useSelector((state) => state.vehicleModel.totalPages);
  const rolePermissionData = useSelector((state) => state.manageRole.manageRoles);
  const getAllRfid = useSelector((state) => state.rfid.fleetRfids);
  const rfidPage = useSelector((state) => state.rfid.page);
  const rfidTotalPage = useSelector((state) => state.rfid.totalPages);
  const customers = useSelector((state) => state.customer.customers);

  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserName = _.get(profileData, 'name', '');

  const VehicleSchema = Yup.object().shape({
    type: Yup.string().required(t('vehicleForm.type')),
    make: Yup.string().required(t('vehicleForm.make')),
    model: Yup.string().required(t('vehicleForm.model')),
    rto_no: Yup.string()
      .required(t('vehicleForm.rtoNo'))
      .strict(true)
      .trim(t('vehicleForm.space'))
      .matches(/^[A-Za-z0-9\s]+$/, t('vehicleForm.matchNumAlpha'))
      .max(100, t('vehicleForm.maxlimit')),
    owner: Yup.string().required(t('vehicleForm.owner')),
  });

  const initialValues =
    vehicleId && !_.isEmpty(vehicleDetail)
      ? {
          ...vehicleDetail,
          owner: _.get(vehicleDetail, 'owner.id', ''),
          tenant: _.get(vehicleDetail, 'tenant.id', ''),
          ownership_type: _.get(vehicleDetail, 'ownership_type', 'b2b'),
          vin_num: _.get(vehicleDetail, 'vin_num', '').toString(),
          rfid_object: _.map(vehicleDetail.rfid_object, (item) => item && item?.id)[0],
        }
      : {
          vin_num: '',
          // name: '',
          type: '',
          make: '',
          model: '',
          charging_station: '',
          rto_no: '',
          RFID: '',
          max_charging_capacity: '',
          // insurance_expiry: moment(),
          owner: '',
          documents: [],
          ownership_type: 'b2c',
          // picture: 'http://jbsdjcj.png',
          rfid_object: '',
          evcatalog: '',
        };

  const getVehicleDetails = useCallback((id) => {
    dispatch({ type: GET_VEHICLE, payload: id });
  }, []);

  const getAllUsers = useCallback((data) => {
    dispatch({ type: FETCH_USER, payload: data ? data : {} });
  }, []);

  useEffect(() => {
    if (vehicleId) {
      getVehicleDetails(vehicleId);
    }
  }, [vehicleId]);

  const addVehicle = useCallback((data) => {
    dispatch({
      type: CREATE_VEHICLE,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === CREATE_STATUS_CODE) {
          setTimeout(() => {
            navigateTo('/Manage-Vehicles');
          }, 1000);
        }
      },
    });
  }, []);

  const editVehicleDetail = useCallback((data) => {
    dispatch({
      type: UPDATE_VEHICLE,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          setTimeout(() => {
            navigateTo('/Manage-Vehicles');
          }, 500);
        }
      },
    });
  }, []);

  const getAllVehicleMake = useCallback((data = {}) => {
    dispatch({ type: FETCH_VEHICLE_MAKE, payload: data });
  }, []);

  const getVehicleModelByMake = useCallback((data = {}) => {
    dispatch({ type: FETCH_VEHICLE_MODEL, payload: data });
  }, []);

  const getUsers = useCallback(
    (data = {}) => {
      if (searchText) {
        const customerData = {
          ...data,
          search: searchText,
        };
        dispatch({
          type: FETCH_CUSTOMER,
          payload: customerData,
        });
      } else {
        dispatch({
          type: FETCH_CUSTOMER,
          payload: data,
        });
      }
    },
    [searchText]
  );

  const getRfidForFleet = useCallback(
    (data = {}) => {
      if (searchText) {
        const rfidData = {
          ...data,
          search: searchText,
          id: currentUserId,
          isUnassigned: true,
        };
        dispatch({ type: FETCH_RFID_FOR_FLEET, payload: rfidData });
      } else {
        const rfidData = {
          ...data,
          id: currentUserId,
          isUnassigned: true,
        };
        dispatch({ type: FETCH_RFID_FOR_FLEET, payload: rfidData });
      }
    },
    [searchText, currentUserId]
  );

  const getAddOns =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === GET_ADD_ONS),
      (data) => data.name
    );

  const manageAddOns =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === MANAGE_ADD_ONS),
      (data) => data.name
    );

  const getVehiclePermissions =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === GET_VEHICLES),
      (data) => data.name
    );

  const manageVehiclePermissions =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === MANAGE_VEHICLES),
      (data) => data.name
    );

  useEffect(() => {
    getAllVehicleMake({ limit: 999 });
    getAllUsers();
    // RfidGroups();
    currentUserId && getRfidForFleet();
    getUsers();
  }, [currentUserId]);

  useEffect(() => {
    if (vehicleId && !_.isEmpty(vehicleDetail) && !_.isEmpty(allVehicleMake)) {
      const selectedMake = _.find(allVehicleMake, { name: _.get(vehicleDetail, 'make', '') });
      if (selectedMake && !_.isEmpty(selectedMake) && _.has(selectedMake, 'id')) {
        getVehicleModelByMake({ makeId: selectedMake.id });
      }
    }
  }, [vehicleId, vehicleDetail, allVehicleMake]);

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title={`${vehicleId ? 'Edit' : 'Add'} ${t('header.vehicle')}`} backTo="/Manage-Vehicles" />
        <div className="page-content-wrapper scrollable">
          <div className="add-vehicle-page-main">
            <Card className="vehicle-form-card">
              <div className="vehicle-form__block">
                <Formik
                  enableReinitialize={!!vehicleId}
                  initialValues={initialValues}
                  validationSchema={VehicleSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    if (vehicleId) {
                      // edit tenant api call....
                      const editVehicleData = _.omit(values, ['is_default', 'unit_consumed', 'group']);
                      const selectedVehicle = _.find(allVehicleModel, { model: values.model });
                      editVehicleDetail({
                        ...editVehicleData,
                        // delete below tenant, when vehicle-data have the tenant-data
                        tenant: values.tenant ? values.tenant : tenantId,
                        max_charging_capacity: selectedVehicle.max_charging_capacity.toString(),
                      });
                    } else {
                      //add tenant api call...
                      const addVehicleData = _.omit(values, ['group']);
                      const selectedVehicle = _.find(allVehicleModel, { model: values.model });
                      addVehicle({
                        ...addVehicleData,
                        max_charging_capacity: selectedVehicle.max_charging_capacity.toString(),
                      });
                    }
                    setSubmitting(false);
                  }}
                >
                  {({ values, errors, touched, handleSubmit, handleChange, setFieldValue, isSubmitting }) => (
                    <Form onSubmit={handleSubmit}>
                      <div className="vehicle-form__inner">
                        <Row>
                          <Col lg={6} md={12}>
                            <Select
                              isRequired
                              label={t('addVehicleForm.vehicleType')}
                              placeholder={t('placeHolder.selectVehicleType')}
                              options={[
                                { label: 'Bus', value: 'bus' },
                                { label: 'Car', value: 'car' },
                                { label: 'Two wheeler', value: 'two wheeler' },
                                { label: 'Three wheeler', value: 'three wheeler' },
                                { label: 'Other', value: 'other' },
                              ]}
                              name="type"
                              value={values.type}
                              onChange={(val) => setFieldValue('type', val)}
                              error={errors.type && touched.type ? errors.type : null}
                            />
                          </Col>
                          <Col lg={6} md={12}>
                            <Select
                              isRequired
                              className="mb-4"
                              label={t('addVehicleForm.vehicleMake')}
                              options={_.map(allVehicleMake, (item) => {
                                return { label: item.name, value: item.name };
                              })}
                              placeholder={t('placeHolder.selectMake')}
                              value={values.make}
                              name="make"
                              onMenuScrollDown={true}
                              getDataOnScrollDown={getAllVehicleMake}
                              page={makePage}
                              totalPage={makeTotalPage}
                              onChange={(val) => {
                                setFieldValue(`make`, val);
                                const currentMake = _.find(allVehicleMake, { name: val });
                                getVehicleModelByMake({ makeId: currentMake.id });
                              }}
                              error={errors.make && touched.make ? errors.make : null}
                            />
                          </Col>
                          <Col lg={6} md={12}>
                            <Select
                              isRequired
                              className="mb-4"
                              placeholder={t('placeHolder.selectModel')}
                              options={_.map(allVehicleModel, (item) => {
                                return { label: item.model, value: _.find(allVehicleModel, { id: item.id }).model };
                              })}
                              label={t('addVehicleForm.vehicleModel')}
                              name="model"
                              value={values.model}
                              onMenuScrollDown={true}
                              getDataOnScrollDown={(data) => getVehicleModelByMake({ ...data, makeId: values.make })}
                              page={modelPage}
                              totalPage={modelTotalPage}
                              onChange={(val) => {
                                const currentModel = _.find(allVehicleModel, { model: val });
                                setFieldValue(`model`, _.get(currentModel, 'model', ''));
                                setFieldValue(`evcatalog`, _.get(currentModel, 'id', ''));
                              }}
                              error={errors.model && touched.model ? errors.model : null}
                            />
                          </Col>
                          <Col lg={6} md={12}>
                            <TextInput
                              isRequired
                              placeholder={t('placeHolder.vehicleRegistrationNo')}
                              label={t('addVehicleForm.vehicleRegistrationNo')}
                              name="rto_no"
                              value={values.rto_no}
                              onChange={handleChange}
                              error={errors.rto_no && touched.rto_no ? errors.rto_no : null}
                            />
                          </Col>
                          <Col lg={6} md={12}>
                            <Select
                              isRequired
                              label={t('addVehicleForm.vehicleOwner')}
                              placeholder={t('addVehicleForm.selectOwner')}
                              options={[
                                { label: 'Self', value: currentUserId },
                                ..._.map(customers, (item) => {
                                  return { label: _.startCase(item.name), value: item.id };
                                }),
                              ]}
                              name="owner"
                              value={values.owner}
                              onChange={(val) => setFieldValue('owner', val)}
                              error={errors.owner && touched.owner ? errors.owner : null}
                            />
                          </Col>
                          {_.isEmpty(initialValues.rfid_object) ? (
                            <Col lg={6} md={12}>
                              <Select
                                label={t('rfidDetailsModal.rfidCardNo')}
                                options={_.map(getAllRfid, (item) => {
                                  return { label: _.get(item, 'card_number'), value: item.id };
                                })}
                                placeholder={t('placeHolder.selectRFIDCardNo')}
                                name="rfid_object"
                                value={values.rfid_object}
                                onMenuScrollDown={true}
                                page={rfidPage}
                                totalPage={rfidTotalPage}
                                onInputChange={(text) => setSearchText(text)}
                                onChange={(val) => {
                                  setFieldValue('rfid_object', val);
                                }}
                              />
                            </Col>
                          ) : null}
                        </Row>
                      </div>
                      <div className="vehicle-save--btn--block">
                        {!_.isEmpty(manageAddOns) ||
                        (!_.isEmpty(getAddOns) && !_.isEmpty(manageAddOns)) ||
                        !_.isEmpty(manageVehiclePermissions) ||
                        (!_.isEmpty(getVehiclePermissions) && !_.isEmpty(manageVehiclePermissions)) ? (
                          <Button type="submit" className="vehicle-save-btn save--btn" disabled={isSubmitting}>
                            {vehicleId ? 'Edit' : t('button.saveAdd')} {t('button.vehicle')}
                          </Button>
                        ) : (
                          <Button type="submit" className="vehicle-save-btn save--btn">
                            {vehicleId ? 'Edit' : 'Save and Add'} Vehicle
                          </Button>
                        )}
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Card>
          </div>
          {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
        </div>
      </LayoutContainer>
    </React.Fragment>
  );
};
export default ManageVehicleForm;
