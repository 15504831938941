import React, { useCallback } from 'react';
import Card from 'components/inputs/Card';
import { Formik, Form } from 'formik';
import { Row, Col } from 'react-bootstrap';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import * as _ from 'lodash';
import Select from 'components/inputs/Select';
import { REMOTE_START_TRANSACTION } from 'actions/chargerOcpp';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { GET_STATION_CHARGER_OPERATIONS, MANAGE_STATION_CHARGER_OPERATIONS } from 'components/common/constant';

const RemoteStartTransactionForm = () => {
  const { chargerId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const chargerDetailData = useSelector((state) => state.charger.chargerDetails);
  const rolePermissionData = useSelector((state) => state.manageRole.manageRoles);

  const RemoteStartTransactionSchema = Yup.object().shape({
    connectorId: Yup.number().required(t('remoteStartTransaction.connectorId')),
    idTag: Yup.string().required(t('remoteStartTransaction.idTag')).trim(t('remoteStartTransaction.space')).strict(true),
  });

  const initialValues = {
    connectorId: '',
    idTag: '',
    // chargingProfile: {
    //   chargingProfileId: 3,
    //   transactionId: 12345,
    //   stackLevel: 12,
    //   chargingProfilePurpose: 'ChargePointMaxProfile',
    //   chargingProfileKind: 'Absolute',
    //   recurrencyKind: 'Daily',
    //   validFrom: '2022-02-23T18:25:43.511Z',
    //   validTo: '2022-02-23T18:25:43.511Z',
    //   chargingSchedule: {
    //     duration: 3,
    //     startSchedule: '2022-02-23T18:25:43.511Z',
    //     chargingRateUnit: 'W',
    //     chargingSchedulePeriod: [
    //       {
    //         startPeriod: 3,
    //         limit: 2.1,
    //         numberPhases: 1,
    //       },
    //     ],
    //     minChargingRate: 13.3,
    //   },
    // },
  };

  const getRemoteStartTransaction = useCallback((data, resetForm) => {
    dispatch({
      type: REMOTE_START_TRANSACTION,
      payload: { data },
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            toast.success('Remote start transaction successfully');
            resetForm();
          }
        }
      },
    });
  }, []);

  const getStationChargerOperations =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === GET_STATION_CHARGER_OPERATIONS),
      (data) => data.name
    );

  const manageStationChargerOperations =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === MANAGE_STATION_CHARGER_OPERATIONS),
      (data) => data.name
    );

  return (
    <React.Fragment>
      <div className="serverOperation-title-name">{t('remoteStartTransaction.formTitle')}</div>
      <div className="serverOperation-content-wrapper">
        <Card className="serverOperation-form-main">
          <div className="serverOperation-form__block">
            <Formik
              initialValues={initialValues}
              validationSchema={RemoteStartTransactionSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                const formData = {
                  id: chargerId,
                  ...values,
                };
                getRemoteStartTransaction(formData, resetForm);
                setSubmitting(false);
              }}
            >
              {({ values, errors, touched, handleSubmit, handleChange, setFieldValue, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="serverOperation-form__inner">
                    <Row>
                      <Col xl={6} lg={12}>
                        <Select
                          isRequired
                          label={t('remoteStartTransaction.formTitle')}
                          placeholder={t('placeHolder.selectConnector')}
                          options={[
                            { label: '0', value: '0' },
                            ..._.map(_.get(chargerDetailData, 'plugs', []), (item) => {
                              return { label: `${item.name} - ${item.connector_id}`, value: `${item.connector_id}` };
                            })
                          ]}
                          name="connectorId"
                          value={`${values.connectorId}`}
                          onChange={(val) => setFieldValue('connectorId', parseInt(val, 10))}
                          error={errors.connectorId && touched.connectorId ? errors.connectorId : null}
                        />
                      </Col>
                      <Col xl={6} lg={12}>
                        <TextInput
                          isRequired
                          label={t('remoteStartTransaction.idLabel')}
                          placeholder={t('remoteStartTransaction.idLabel')}
                          value={values.idTag}
                          name="idTag"
                          onChange={handleChange}
                          error={errors.idTag && touched.idTag ? errors.idTag : null}
                        />
                      </Col>
                      {/* <Col md={6}>
                        <TextInput
                          label="Charging Profile Id"
                          name="chargingProfile.chargingProfileId"
                          placeholder="Charging profile id"
                          type="number"
                          value={values.chargingProfile.chargingProfileId}
                          onChange={handleChange}
                          error={
                            getIn(errors, 'chargingProfile.chargingProfileId') && getIn(touched, 'chargingProfile.chargingProfileId')
                              ? getIn(errors, 'chargingProfile.chargingProfileId')
                              : null
                          }
                        />
                      </Col>
                      <Col md={6}>
                        <TextInput
                          label="Transaction Id"
                          name="chargingProfile.transactionId"
                          placeholder="Transaction id"
                          type="number"
                          value={values.chargingProfile.transactionId}
                          onChange={handleChange}
                          error={
                            getIn(errors, 'chargingProfile.transactionId') && getIn(touched, 'chargingProfile.transactionId')
                              ? getIn(errors, 'chargingProfile.transactionId')
                              : null
                          }
                        />
                      </Col>
                      <Col md={6}>
                        <TextInput
                          label="Stack Level"
                          name="chargingProfile.stackLevel"
                          placeholder="Stack level"
                          type="number"
                          value={values.chargingProfile.stackLevel}
                          onChange={handleChange}
                          error={
                            getIn(errors, 'chargingProfile.stackLevel') && getIn(touched, 'chargingProfile.stackLevel')
                              ? getIn(errors, 'chargingProfile.stackLevel')
                              : null
                          }
                        />
                      </Col>
                      <Col md={6}>
                        <TextInput
                          label="Charging Profile Purpose"
                          name="chargingProfile.chargingProfilePurpose"
                          placeholder="Charging profile purpose"
                          value={values.chargingProfile.chargingProfilePurpose}
                          onChange={handleChange}
                          error={
                            getIn(errors, 'chargingProfile.chargingProfilePurpose') && getIn(touched, 'chargingProfile.chargingProfilePurpose')
                              ? getIn(errors, 'chargingProfile.chargingProfilePurpose')
                              : null
                          }
                        />
                      </Col>
                      <Col md={6}>
                        <TextInput
                          label="Charging Profile Kind"
                          name="chargingProfile.chargingProfileKind"
                          placeholder="Charging profile kind"
                          value={values.chargingProfile.chargingProfileKind}
                          onChange={handleChange}
                          error={
                            getIn(errors, 'chargingProfile.chargingProfileKind') && getIn(touched, 'chargingProfile.chargingProfileKind')
                              ? getIn(errors, 'chargingProfile.chargingProfileKind')
                              : null
                          }
                        />
                      </Col>
                      <Col md={6}>
                        <TextInput
                          label="Recurrency Kind"
                          name="chargingProfile.recurrencyKind"
                          placeholder="Recurrency kind"
                          value={values.chargingProfile.recurrencyKind}
                          onChange={handleChange}
                          error={
                            getIn(errors, 'chargingProfile.recurrencyKind') && getIn(touched, 'chargingProfile.recurrencyKind')
                              ? getIn(errors, 'chargingProfile.recurrencyKind')
                              : null
                          }
                        />
                      </Col>
                      <Col md={6}>
                        <TextInput
                          label="From"
                          name="chargingProfile.validFrom"
                          placeholder="From"
                          type='date'
                          value={values.chargingProfile.validFrom}
                          onChange={handleChange}
                          error={
                            getIn(errors, 'chargingProfile.validFrom') && getIn(touched, 'chargingProfile.validFrom')
                              ? getIn(errors, 'chargingProfile.validFrom')
                              : null
                          }
                        />
                      </Col>
                      <Col md={6}>
                        <TextInput
                          label="To"
                          name="chargingProfile.validTo"
                          placeholder="To"
                          type='date'
                          value={values.chargingProfile.validTo}
                          onChange={handleChange}
                          error={
                            getIn(errors, 'chargingProfile.validTo') && getIn(touched, 'chargingProfile.validTo')
                              ? getIn(errors, 'chargingProfile.validTo')
                              : null
                          }
                        />
                      </Col>
                      <Col md={6}>
                        <TextInput
                          label="Duration"
                          name="chargingProfile.chargingSchedule.duration"
                          placeholder="Duration"
                          type='number'
                          value={values.chargingProfile.chargingSchedule.duration}
                          onChange={handleChange}
                          error={
                            getIn(errors, 'chargingProfile.chargingSchedule.duration') && getIn(touched, 'chargingProfile.chargingSchedule.duration')
                              ? getIn(errors, 'chargingProfile.chargingSchedule.duration')
                              : null
                          }
                        />
                      </Col>
                      <Col md={6}>
                        <TextInput
                          label="Start Schedule"
                          name="chargingProfile.chargingSchedule.startSchedule"
                          placeholder="Start schedule"
                          type='date'
                          value={values.chargingProfile.chargingSchedule.startSchedule}
                          onChange={handleChange}
                          error={
                            getIn(errors, 'chargingProfile.chargingSchedule.startSchedule') && getIn(touched, 'chargingProfile.chargingSchedule.startSchedule')
                              ? getIn(errors, 'chargingProfile.chargingSchedule.startSchedule')
                              : null
                          }
                        />
                      </Col>
                      <Col md={6}>
                        <TextInput
                          label="Charging Rate Unit"
                          name="chargingProfile.chargingSchedule.chargingRateUnit"
                          placeholder="Charging rate unit"
                          value={values.chargingProfile.chargingSchedule.chargingRateUnit}
                          onChange={handleChange}
                          error={
                            getIn(errors, 'chargingProfile.chargingSchedule.chargingRateUnit') && getIn(touched, 'chargingProfile.chargingSchedule.chargingRateUnit')
                              ? getIn(errors, 'chargingProfile.chargingSchedule.chargingRateUnit')
                              : null
                          }
                        />
                      </Col>
                      <Col md={6}>
                        <TextInput
                          label="Min Charging Rate"
                          name="chargingProfile.chargingSchedule.minChargingRate"
                          placeholder="Min charging rate"
                          type='number'
                          value={values.chargingProfile.chargingSchedule.minChargingRate}
                          onChange={handleChange}
                          error={
                            getIn(errors, 'chargingProfile.chargingSchedule.minChargingRate') && getIn(touched, 'chargingProfile.chargingSchedule.minChargingRate')
                              ? getIn(errors, 'chargingProfile.chargingSchedule.minChargingRate')
                              : null
                          }
                        />
                      </Col> */}
                    </Row>
                  </div>
                  <div className="serverOperation-save--btn--block">
                    {!_.isEmpty(manageStationChargerOperations) ||
                      (!_.isEmpty(getStationChargerOperations) && !_.isEmpty(manageStationChargerOperations)) ? (
                      <Button type="submit" className="serverOperation-save-btn save--btn" disabled={isSubmitting}>
                        {t('remoteStartTransaction.button')}
                      </Button>
                    ) : (
                      <Button className="serverOperation-save-btn save--btn">{t('remoteStartTransaction.button')}</Button>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};
export default RemoteStartTransactionForm;
