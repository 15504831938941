import {
  RATING_REVIEW,
  RATING_REVIEW_SUCCESS,
  RATING_REVIEW_FAIL,
  DOWNLOAD_RATING_REVIEW,
  DOWNLOAD_RATING_REVIEW_SUCCESS,
  DOWNLOAD_RATING_REVIEW_FAIL,
  RATING_REVIEW_SUMMARY,
  RATING_REVIEW_SUMMARY_SUCCESS,
  RATING_REVIEW_SUMMARY_FAIL,
  DOWNLOAD_RATING_REVIEW_SUMMARY,
  DOWNLOAD_RATING_REVIEW_SUMMARY_SUCCESS,
  DOWNLOAD_RATING_REVIEW_SUMMARY_FAIL,
} from '../actions/rating';

import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';

function* fetchRating(action) {
  try {
    const response = yield call(API.getRating, action.payload);
    yield put({ type: RATING_REVIEW_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: RATING_REVIEW_FAIL, payload: { error: e } });
  }
}

function* downloadRatingReview(action) {
  try {
    const response = yield call(API.downloadRating, action.payload);
    yield put({ type: DOWNLOAD_RATING_REVIEW_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_RATING_REVIEW_FAIL, payload: { error: e } });
  }
}

function* fetchRatingSummary(action) {
  try {
    const response = yield call(API.getRatingSummary, action.payload);
    yield put({ type: RATING_REVIEW_SUMMARY_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: RATING_REVIEW_SUMMARY_FAIL, payload: { error: e } });
  }
}

function* downloadRatingReviewSummary(action) {
  try {
    const response = yield call(API.downloadRatingSummary, action.payload);
    yield put({ type: DOWNLOAD_RATING_REVIEW_SUMMARY_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_RATING_REVIEW_SUMMARY_FAIL, payload: { error: e } });
  }
}

function* rating() {
  yield takeLatest(RATING_REVIEW, fetchRating);
  yield takeLatest(DOWNLOAD_RATING_REVIEW, downloadRatingReview);
  yield takeLatest(RATING_REVIEW_SUMMARY, fetchRatingSummary);
  yield takeLatest(DOWNLOAD_RATING_REVIEW_SUMMARY, downloadRatingReviewSummary);
}
export default rating;
