export const FETCH_SMS = 'FETCH_SMS';
export const FETCH_SMS_SUCCESS = 'FETCH_SMS_SUCCESS';
export const FETCH_SMS_FAIL = 'FETCH_SMS_FAIL';

export const GET_SMS = 'GET_SMS';
export const GET_SMS_SUCCESS = 'GET_SMS_SUCCESS';
export const GET_SMS_FAIL = 'GET_SMS_FAIL';

export const CREATE_SMS = 'CREATE_SMS';
export const CREATE_SMS_SUCCESS = 'CREATE_SMS_SUCCESS';
export const CREATE_SMS_FAIL = 'CREATE_SMS_FAIL';

export const UPDATE_SMS = 'UPDATE_SMS';
export const UPDATE_SMS_SUCCESS = ' UPDATE_SMS_SUCCESS';
export const UPDATE_SMS_FAIL = 'UPDATE_SMS_FAIL';

export const DELETE_SMS = 'DELETE_SMS';
export const DELETE_SMS_SUCCESS = 'DELETE_SMS_SUCCESS';
export const DELETE_SMS_FAIL = ' DELETE_SMS_FAIL';
