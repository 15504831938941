export const FETCH_REPORT_LIST = 'FETCH_REPORT_LIST';
export const FETCH_REPORT_LIST_SUCCESS = 'FETCH_REPORT_LIST_SUCCESS';
export const FETCH_REPORT_LIST_FAIL = 'FETCH_REPORT_LIST_FAIL';

export const FETCH_REPORT_DOWNLOAD_REQUEST = 'FETCH_REPORT_DOWNLOAD_REQUEST';
export const FETCH_REPORT_DOWNLOAD_REQUEST_SUCCESS = 'FETCH_REPORT_DOWNLOAD_REQUEST_SUCCESS';
export const FETCH_REPORT_DOWNLOAD_REQUEST_FAIL = 'FETCH_REPORT_DOWNLOAD_REQUEST_FAIL';

export const CREATE_REPORT_DOWNLOAD_REQUEST = 'CREATE_REPORT_DOWNLOAD_REQUEST';
export const CREATE_REPORT_DOWNLOAD_REQUEST_SUCCESS = 'CREATE_REPORT_DOWNLOAD_REQUEST_SUCCESS';
export const CREATE_REPORT_DOWNLOAD_REQUEST_FAIL = 'CREATE_REPORT_DOWNLOAD_REQUEST_FAIL';