export const FETCH_OEM_ERROR_CODE = 'FETCH_OEM_ERROR_CODE';
export const FETCH_OEM_ERROR_CODE_SUCCESS = 'FETCH_OEM_ERROR_CODE_SUCCESS';
export const FETCH_OEM_ERROR_CODE_FAIL = 'FETCH_OEM_ERROR_CODE_FAIL';

export const GET_OEM_ERROR_CODE = 'GET_OEM_ERROR_CODE';
export const GET_OEM_ERROR_CODE_SUCCESS = 'GET_OEM_ERROR_CODE_SUCCESS';
export const GET_OEM_ERROR_CODE_FAIL = 'GET_OEM_ERROR_CODE_FAIL';

export const CREATE_OEM_ERROR_CODE = 'CREATE_OEM_ERROR_CODE';
export const CREATE_OEM_ERROR_CODE_SUCCESS = 'CREATE_OEM_ERROR_CODE_SUCCESS';
export const CREATE_OEM_ERROR_CODE_FAIL = 'CREATE_OEM_ERROR_CODE_FAIL';

export const UPDATE_OEM_ERROR_CODE = 'UPDATE_OEM_ERROR_CODE';
export const UPDATE_OEM_ERROR_CODE_SUCCESS = 'UPDATE_OEM_ERROR_CODE_SUCCESS';
export const UPDATE_OEM_ERROR_CODE_FAIL = 'UPDATE_OEM_ERROR_CODE_FAIL';

export const DELETE_OEM_ERROR_CODE = 'DELETE_OEM_ERROR_CODE';
export const DELETE_OEM_ERROR_CODE_SUCCESS = 'DELETE_OEM_ERROR_CODE_SUCCESS';
export const DELETE_OEM_ERROR_CODE_FAIL = 'DELETE_OEM_ERROR_CODE_FAIL';

export const CREATE_BULK_ERROR_CODE = 'CREATE_BULK_ERROR_CODE';
export const CREATE_BULK_ERROR_CODE_SUCCESS = 'CREATE_BULK_ERROR_CODE_SUCCESS';
export const CREATE_BULK_ERROR_CODE_FAIL = 'CREATE_BULK_ERROR_CODE_FAIL';