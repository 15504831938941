export const CREATE_RESERVE_SLOTS = 'CREATE_RESERVE_SLOTS';
export const CREATE_RESERVE_SLOTS_SUCCESS = 'CREATE_RESERVE_SLOTS_SUCCESS';
export const CREATE_RESERVE_SLOTS_FAIL = 'CREATE_RESERVE_SLOTS_FAIL';

export const FETCH_RESERVE_SLOTS = 'FETCH_RESERVE_SLOTS';
export const FETCH_RESERVE_SLOTS_SUCCESS = 'FETCH_RESERVE_SLOTS_SUCCESS';
export const FETCH_RESERVE_SLOTS_FAIL = 'FETCH_RESERVE_SLOTS_FAIL';

export const GET_RESERVE_SLOTS = 'GET_RESERVE_SLOTS';
export const GET_RESERVE_SLOTS_SUCCESS = 'GET_RESERVE_SLOTS_SUCCESS';
export const GET_RESERVE_SLOTS_FAIL = 'GET_RESERVE_SLOTS_FAIL';

export const DELETE_RESERVE_SLOTS = 'DELETE_RESERVE_SLOTS';
export const DELETE_RESERVE_SLOTS_SUCCESS = 'DELETE_RESERVE_SLOTS_SUCCESS';
export const DELETE_RESERVE_SLOTS_FAIL = 'DELETE_RESERVE_SLOTS_FAIL';

export const UPDATE_RESERVE_SLOTS = 'UPDATE_RESERVE_SLOTS';
export const UPDATE_RESERVE_SLOTS_SUCCESS = 'UPDATE_RESERVE_SLOTS_SUCCESS';
export const UPDATE_RESERVE_SLOTS_FAIL = 'UPDATE_RESERVE_SLOTS_FAIL';

export const ALL_USERS_IN_RESERVE_SLOTS = 'ALL_USERS_IN_RESERVE_SLOTS';
export const ALL_USERS_IN_RESERVE_SLOTS_SUCCESS = 'ALL_USERS_IN_RESERVE_SLOTS_SUCCESS';
export const ALL_USERS_IN_RESERVE_SLOTS_FAIL = 'ALL_USERS_IN_RESERVE_SLOTS_FAIL';

export const CREATE_BULK_USERS_TO_RESERVE_SLOTS = 'CREATE_BULK_USERS_TO_RESERVE_SLOTS';
export const CREATE_BULK_USERS_TO_RESERVE_SLOTS_SUCCESS = 'CREATE_BULK_USERS_TO_RESERVE_SLOTS_SUCCESS';
export const CREATE_BULK_USERS_TO_RESERVE_SLOTS_FAIL = 'CREATE_BULK_USERS_TO_RESERVE_SLOTS_FAIL';
