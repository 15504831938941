export const FETCH_ROLE = 'FETCH_ROLE';
export const FETCH_ROLE_SUCCESS = 'FETCH_ROLE_SUCCESS';
export const FETCH_ROLE_FAIL = 'FETCH_ROLE_FAIL';

export const GET_ROLE = 'GET_ROLE';
export const GET_ROLE_SUCCESS = 'GET_ROLE_SUCCESS';
export const GET_ROLE_FAIL = 'GET_ROLE_FAIL';

export const CREATE_ROLE = 'CREATE_ROLE';
export const CREATE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS';
export const CREATE_ROLE_FAIL = 'CREATE_ROLE_FAIL';

export const UPDATE_ROLE = 'UPDATE_ROLE';
export const UPDATE_ROLE_SUCCESS = ' UPDATE_ROLE_SUCCESS';
export const UPDATE_ROLE_FAIL = 'UPDATE_ROLE_FAIL';

export const DELETE_ROLE = 'DELETE_ROLE';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const DELETE_ROLE_FAIL = ' DELETE_ROLE_FAIL';

export const MANAGE_ROLE = 'MANAGE_ROLE';
export const MANAGE_ROLE_SUCCESS = 'MANAGE_ROLE_SUCCESS';
export const MANAGE_ROLE_FAIL = ' MANAGE_ROLE_FAIL';

export const GET_ROLE_BY_NAME = 'GET_ROLE_BY_NAME';
export const GET_ROLE_BY_NAME_SUCCESS = 'GET_ROLE_BY_NAME_SUCCESS';
export const GET_ROLE_BY_NAME_FAIL = ' GET_ROLE_BY_NAME_FAIL';
