import React, { useCallback, useEffect, useState, useContext } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header';
import Card from 'components/inputs/Card';
import { BsSearch } from 'react-icons/bs';
import Button from 'components/inputs/Button';
import { FiEdit2 } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
// import { useHistory } from 'react-router-dom';
// import DummyImage from 'assets/images/icons/dummyImage.png';
// import Avatar from 'components/inputs/Avatar';
import { Row, Col, Spinner } from 'react-bootstrap';
import * as _ from 'lodash';
// import { startsWith } from 'components/common/utils';
import SearchBox from 'components/general/SearchBox';
import { useDispatch, useSelector } from 'react-redux';
import { DELETE_TENANT, FETCH_TENANT, UPDATE_TENANT } from 'actions/tenant';
// import Pagination from 'components/general/Pagination';
import ReactPagination from 'components/general/ReactPagination';
import DeleteModal from 'components/general/DeleteModal';
import { Modal } from 'react-bootstrap';
import { DELETE_STATUS_CODE, UPDATE_STATUS_CODE } from 'components/common/constant';
import { toast } from 'react-toastify';
import { TENANT_ADMIN_LOGIN, TENANT_ADMIN_LOGIN_AS } from 'actions/authAction';
import { BiSort } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import { FaLock } from 'react-icons/fa';
import { FaLockOpen } from 'react-icons/fa';
import { FaUserCog } from 'react-icons/fa';
import Avatar from 'components/inputs/Avatar';
import { ImOffice } from 'react-icons/im';
import { AiFillPushpin, AiOutlinePushpin } from 'react-icons/ai';
import DropDown from 'components/inputs/DropDown';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { NavContext } from 'components/privateRoute/PrivateRoute';

const TenantAdminList = () => {
  // const history = useHistory();
  const { navigateTo } = useContext(NavContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [searchText, setSearchText] = useState('');
  // const [allTenantAdminData, setAllTenantAdminData] = useState([]);
  const [tenantAdminId, setTenantAdminId] = useState('');
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [showLockConfirmation, setShowLockConfirmation] = useState(false);
  const [lockingTenantAdminId, setLockingTenantAdminId] = useState('');
  const tenantAdminList = useSelector((state) => state.tenant.tenants);
  const isLoader = useSelector((state) => state.tenant.isLoading);
  const [page, setPage] = useState(1);
  const totalData = useSelector((state) => state.tenant.total);
  // const limit = useSelector((state) => state.tenant.limit);
  const profileData = useSelector((state) => state.profile.userProfile);
  const userRole = _.get(profileData, 'role', '');
  //const totalPages = useSelector((state) => state.tenant.totalPages);
  const chargers = tenantAdminList.map((item) => item.no_of_chargers);
  const totalChargers = chargers.reduce((sum, value) => sum + value, 0);
  const sessions = tenantAdminList.map((item) => item.no_of_session);
  const totalSessions = sessions.reduce((sum, value) => sum + value, 0);
  const energy = tenantAdminList.map((item) => item.total_energyServed);
  const totalEnergy = energy.reduce((sum, value) => sum + value, 0);
  const displayRoles = ['support_admin', 'network_admin', 'ZMT_admin', 'finance_admin'];
  const handleCloseDeleteModel = () => {
    setShowDeleteModal(false);
    setTenantAdminId('');
  };

  const superAdminLogin = useCallback((id) => {
    const tenantData = {
      tenant: id,
    };
    dispatch({ type: TENANT_ADMIN_LOGIN, payload: tenantData });
  }, []);

  const adminLoginAsSupport = useCallback((id) => {
    const tenantData = {
      tenant: id,
    };
    dispatch({ type: TENANT_ADMIN_LOGIN_AS, payload: tenantData });
  }, []);

  const deleteTenant = useCallback(
    () =>
      dispatch({
        type: DELETE_TENANT,
        payload: tenantAdminId,
        cb: (res) => {
          if (_.get(res, 'status', '') === DELETE_STATUS_CODE) {
            handleCloseDeleteModel();
            getTenant({ page: 1 });
          }
        },
      }),
    [tenantAdminId]
  );

  const getTenant = useCallback(
    (data = {}) => {
      if (searchText) {
        const tenantData = {
          ...data,
          name: searchText,
        };
        dispatch({ type: FETCH_TENANT, payload: tenantData });
      } else {
        dispatch({ type: FETCH_TENANT, payload: data });
      }
    },
    [searchText]
  );

  const editLockTenant = useCallback((id) => {
    setLockingTenantAdminId(id);
    setShowLockConfirmation(true);
  }, []);

  const performLockTenant = useCallback((id) => {
    const data = {
      id: id,
      is_active: 'false',
    };
    dispatch({
      type: UPDATE_TENANT,
      payload: data,
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
            toast.success('Tenant is not active');
          }
        }
      },
    });
  }, []);

  const editUnLockTenant = useCallback((id) => {
    const data = {
      id: id,
      is_active: 'true',
    };
    dispatch({
      type: UPDATE_TENANT,
      payload: data,
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
            toast.success('Tenant is active');
          }
        }
      },
    });
  }, []);

  useEffect(() => {
    getTenant();
  }, []);

  const handleSearch = () => {
    getTenant({});
  };

  // useEffect(() => {
  //   setAllTenantAdminData(
  //     _.filter(
  //       tenantAdminList,
  //       (item) =>
  //         startsWith(_.get(item, 'name', ''), searchText) ||
  //         startsWith(_.get(item, 'chargers').toString(), searchText) ||
  //         startsWith(_.get(item, 'charging_stations').toString(), searchText) ||
  //         startsWith(_.get(item, 'user_count').toString(), searchText)
  //     )
  //   );
  // }, [searchText, tenantAdminList]);
  const handlePageClick = (pageVal) => {
    setPage(pageVal.selected + 1); // ReactPaginate starts indexing from 0, so add 1 to get the current page.
  };
  // const handlePageClick = useCallback(
  //   (page) => {

  //     if (sortByItem.item && sortByItem.order) {
  //       getSortByItem(sortByItem.item, sortByItem.order, page);
  //     } else {
  //       const data = {
  //         page: page.selected + 1,
  //       };
  //       getTenant(data);
  //     }
  //   },
  //   [sortByItem]
  // );
  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getTenant(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getTenant(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const editPinned = useCallback(
    (id) => {
      const tenant = tenantAdminList.find((t) => t.id === id);
      const isPinned = _.get(tenant, 'is_pinned', false);
      const updatedIsPinned = !isPinned;
      dispatch({
        type: UPDATE_TENANT,
        payload: {
          id,
          is_pinned: updatedIsPinned,
        },
        cb: (res) => {
          if (res.status === 200) {
            toast.success(isPinned ? 'Unpinned' : 'Pinned');
          }
        },
      });
    },
    [tenantAdminList, dispatch]
  );

  const sortedTenantAdminList = [...tenantAdminList].sort((a, b) => {
    if (a.is_pinned && !b.is_pinned) {
      return -1; // a should appear before b
    } else if (!a.is_pinned && b.is_pinned) {
      return 1; // b should appear before a
    } else {
      return 0; // no change in order
    }
  });
  const limit1 = 10; // The number of items to display per page. You can adjust this as needed.
  const totalData1 = sortedTenantAdminList.length; // Replace 'capacityUtilisations' with the actual data array.
  const totalPages1 = Math.ceil(totalData / limit1);
  const startIndex = (page - 1) * limit1;
  const endIndex = Math.min(startIndex + limit1, totalData);
  const sortedData = _.orderBy(sortedTenantAdminList, [sortByItem.item], [sortByItem.order]);
  const dataToShow = sortedData.slice(startIndex, endIndex);
  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.tenantManagement')} />
        <div className="page-content-wrapper scrollable">
          <div className="status-blocks tenant-list-blocks">
            <div className="status-block">
              <div className="status-info">
                <h1>
                  {totalData >= 1000000
                    ? `${(totalData / 1000000).toFixed(1)}M`
                    : totalData >= 10000
                    ? `${(totalData / 1000).toFixed(1)}K`
                    : totalData}
                </h1>
                <p>{t('editTenantForm.totalTenants')}</p>
              </div>
            </div>
            <div className="status-block">
              <div className="status-info">
                <h1>
                  {totalSessions >= 1000000
                    ? `${(totalSessions / 1000000).toFixed(1)}M`
                    : totalSessions >= 10000
                    ? `${(totalSessions / 1000).toFixed(1)}K`
                    : totalSessions}
                </h1>
                <p>{t('editTenantForm.EVServed')}</p>
              </div>
            </div>
            <div className="status-block">
              <div className="status-info">
                <h1>
                  {totalEnergy >= 1000000
                    ? `${(totalEnergy / 1000000).toFixed(1)}M`
                    : totalEnergy >= 10000
                    ? `${(totalEnergy / 1000).toFixed(1)}K`
                    : totalEnergy}
                </h1>
                <p>{t('editTenantForm.energyDelivered')}</p>
              </div>
            </div>
            <div className="status-block">
              <div className="status-info">
                <h1>
                  {totalChargers >= 1000000
                    ? `${(totalChargers / 1000000).toFixed(1)}M`
                    : totalChargers >= 10000
                    ? `${(totalChargers / 1000).toFixed(1)}K`
                    : totalChargers}
                </h1>
                <p>{t('editTenantForm.totalCharger')}</p>
              </div>
            </div>
          </div>
          <div className="tenantAdminList-page">
            <Card>
              <div className="tenantAdminList-page-wrapper">
                <Row className="tenantAdminList-search-box">
                  <Col
                    xl={displayRoles.includes(userRole) ? 12 : 10}
                    lg={displayRoles.includes(userRole) ? 12 : 9}
                    md={displayRoles.includes(userRole) ? 12 : 8}
                    className="tenantAdminList-search-inputBox"
                  >
                    <SearchBox
                      value={searchText}
                      preIcon={<BsSearch />}
                      onChange={(e) => setSearchText(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          handleSearch();
                        }
                      }}
                    />
                  </Col>
                  {displayRoles.includes(userRole) ? null : (
                    <Col xl={2} lg={3} md={4} className="tenantAdminList-search-boxButton">
                      <Button className="tenantAdminList-search-box-Btn" onClick={() => navigateTo('/addTenant')}>
                        {t('button.addTenant')}
                      </Button>
                    </Col>
                  )}
                </Row>
                <div className="tenantAdminList-table">
                  <div className="table-responsive">
                    <table className="record-list-table">
                      <thead>
                        <tr>
                          <th>
                            <div className="sorting">
                              <span>{t('editTenantForm.logo')}</span>
                              {/* <span className="ico">
                                <BiSort size={15} />
                              </span> */}
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>{t('tenantManagementList.company')}</span>
                              <span className="ico" onClick={() => handleSorting('name', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>{t('tenantManagementList.noOfChargespots')}</span>
                              <span className="ico" onClick={() => handleSorting('no_of_chargers', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>{t('tenantManagementList.energydelivered')}</span>
                              <span className="ico" onClick={() => handleSorting('total_energyServed', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>{t('tenantManagementList.noofsessions')}</span>
                              <span className="ico" onClick={() => handleSorting('no_of_session', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>{t('tenantManagementList.type')}</span>
                              <span className="ico" onClick={() => handleSorting('mode', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>{t('tenantManagementList.action')}</span>
                            </div>
                          </th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {isLoader ? (
                          <tr>
                            <td colSpan={10} className="border-0">
                              <div className="loader--block">
                                <Spinner as="span" animation="border" size="sm" role="status" />
                                <span className="ps-2">{t('placeHolder.loading')}</span>
                              </div>
                            </td>
                          </tr>
                        ) : _.isEmpty(tenantAdminList) ? (
                          <tr>
                            <td colSpan={5} className="border-0">
                              <div className="empty-data-block">{t('tenantManagementList.noData')}</div>
                            </td>
                          </tr>
                        ) : (
                          !(isLoader || _.isUndefined(isLoader)) &&
                          _.map(dataToShow, (item, key) => {
                            return (
                              <tr key={key}>
                                <td>
                                  {_.get(item, 'logo') ? (
                                    <Avatar imgSrc={_.get(item, 'logo', '')} className="company-logo" />
                                  ) : (
                                    <ImOffice className="make-logo" />
                                  )}
                                </td>
                                <td>{_.get(item, 'name', 'NA')}</td>
                                <td>{_.get(item, 'no_of_chargers', '0')}</td>
                                <td>{_.get(item, 'total_energyServed', '0')}</td>
                                <td>{_.get(item, 'no_of_session', '0')}</td>
                                <td className="tenant-mode">{_.get(item, 'mode', '-')}</td>
                                <td className="tenant-action-btn">
                                  {_.get(item, 'allow_remote_access') === true ? (
                                    <Button
                                      id="tenant-admin-login"
                                      className="tenantAdminList-search-box-Btn"
                                      onClick={() => {
                                        if (displayRoles.includes(userRole)) {
                                          adminLoginAsSupport(_.get(item, 'id', ''));
                                        } else {
                                          superAdminLogin(_.get(item, 'contact_person.id', ''));
                                        }
                                      }}
                                    >
                                      {t('button.login')}
                                    </Button>
                                  ) : (
                                    ''
                                  )}
                                  {displayRoles.includes(userRole) ? null : (
                                    <DropDown
                                      trigger={
                                        <div className="dropdown-icon">
                                          <BsThreeDotsVertical size={23} />
                                        </div>
                                      }
                                      popupClass="bottom-left"
                                    >
                                      <ul className="tenantAdminList-icon-list">
                                        <li className="item">
                                          {_.get(item, 'is_active') ? (
                                            <>
                                              <div className="tenant-lock" onClick={() => editLockTenant(item.id)}>
                                                <span className="tenantAdminList-lock-unlock-icon">
                                                  <FaLockOpen size={23} />
                                                </span>
                                                <span className="tenantAdminList-text"> {t('button.lockUnlock')}</span>
                                              </div>
                                            </>
                                          ) : (
                                            <>
                                              <div className="tenant-lock" onClick={() => editUnLockTenant(item.id)}>
                                                <span className="tenantAdminList-lock-unlock-icon">
                                                  <FaLock size={23} />
                                                </span>
                                                <span className="tenantAdminList-text">{t('button.lockUnlock')}</span>
                                              </div>
                                            </>
                                          )}
                                        </li>
                                        <li className="item" onClick={() => navigateTo(`/editTenant/${item.id}`)}>
                                          <span className="tenantAdminList-table-editIcon">
                                            <FiEdit2 title="Edit" size={23} />
                                          </span>
                                          <span className="tenantAdminList-text">{t('button.editTenant')}</span>
                                        </li>
                                        <li className="item" onClick={() => navigateTo(`/tenantAdminAccess/${_.get(item, 'id', '')}`)}>
                                          <span className="tenantAdminList-lock-unlock-icon">
                                            <FaUserCog size={23} />
                                          </span>
                                          <span className="tenantAdminList-text">{t('button.configTenant')}</span>
                                        </li>
                                        <li
                                          className="item"
                                          onClick={() => {
                                            editPinned(item.id);
                                          }}
                                        >
                                          {_.get(item, 'is_pinned') ? (
                                            <span className="tenantAdminList-table-pinIcon">
                                              <AiFillPushpin size={23} color="#be210b" />
                                            </span>
                                          ) : (
                                            <span className="tenantAdminList-table-pinIcon">
                                              <AiOutlinePushpin size={23} color="rgba(255,255,255,0.3)" />
                                            </span>
                                          )}
                                          <span className="tenantAdminList-text">{t('button.pinUnpin')}</span>
                                        </li>
                                        <li
                                          className="item"
                                          onClick={() => {
                                            setShowDeleteModal(true);
                                            setTenantAdminId(_.get(item, 'id', ''));
                                          }}
                                        >
                                          <span className="tenantAdminList-table-deleteIcon">
                                            <RiDeleteBinLine title="Delete" size={23} />
                                          </span>
                                          <span className="tenantAdminList-text">{t('button.delete')}</span>
                                        </li>
                                      </ul>
                                    </DropDown>
                                  )}
                                </td>
                                <td></td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(tenantAdminList) && (
                  <ReactPagination
                    currentPage={page}
                    limit={limit1}
                    total={totalData1}
                    handlePageClick={(pageVal) => handlePageClick(pageVal)}
                    totalPages={totalPages1}
                    marginPagesDisplayed={1}
                  />
                  // <Pagination currentPage={page} limit={limit} total={totalData} onPageChange={(pageVal) => handlePageClick(pageVal)} />
                )}
              </div>
            </Card>
          </div>
        </div>
        {showDeleteModal && (
          <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
            <DeleteModal title="tenant admin" onClose={handleCloseDeleteModel} onRemove={() => deleteTenant(tenantAdminId)} />
          </Modal>
        )}

        {showLockConfirmation && (
          <Modal show={showLockConfirmation} centered onHide={() => setShowLockConfirmation(false)}>
            <Modal.Header closeButton style={{ background: 'transparent', border: 'none', color: 'white' }}>
              {/* Replace the title with the lock icon */}
              <Modal.Title>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <FaLock size={32} style={{ marginRight: '10px', marginLeft: '14rem' }} />
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ color: '#FFFFFF80' }}>
              <center>{t('tenantManagementList.lockTenant')}</center>
            </Modal.Body>
            <Modal.Footer style={{ justifyContent: 'center', borderTop: 'none' }}>
              <Button
                variant="secondary"
                onClick={() => setShowLockConfirmation(false)}
                style={{
                  borderRadius: '15px',
                  marginRight: '3rem',
                  width: '12rem',
                  padding: '0.7rem',
                  backgroundColor: '#182532',
                  color: 'white',
                  borderColor: '#182532',
                  transition: 'background-color 0.3s, color 0.3s', // Add smooth transition
                }}
                // Apply hover effect
                onMouseOver={(e) => {
                  e.target.style.backgroundColor = 'rgba(35, 58, 81, 0.5)';
                  e.target.style.borderColor = 'rgba(35, 58, 81, 0.5)';
                }}
                onMouseOut={(e) => {
                  e.target.style.backgroundColor = '#182532';
                  e.target.style.borderColor = '#182532';
                }}
              >
                {t('button.cancel')}
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  setShowLockConfirmation(false);
                  performLockTenant(lockingTenantAdminId);
                }}
                style={{
                  borderRadius: '15px',
                  backgroundColor: '#be210b',
                  color: 'white',
                  borderColor: 'rgb(190, 33, 11)',
                  width: '12rem',
                  padding: '0.7rem',
                  transition: 'background-color 0.3s, color 0.3s', // Add smooth transition
                }}
                // Apply hover effect
                onMouseOver={(e) => {
                  e.target.style.backgroundColor = '#bb2d3b';
                  e.target.style.color = 'white';
                }}
                onMouseOut={(e) => {
                  e.target.style.backgroundColor = '#be210b';
                  e.target.style.color = 'white';
                }}
              >
                {t('button.lock')}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </LayoutContainer>
    </>
  );
};

export default TenantAdminList;
