import React, { useState, useCallback, useEffect } from 'react';
import SearchBox from 'components/general/SearchBox';
import { Col, Row, Spinner } from 'react-bootstrap';
import { BsSearch, BsFilter } from 'react-icons/bs';
import * as _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { DOWNLOAD_CHARGER_LOG_DATA, DOWNLOAD_TABULAR_METERVALUE, FETCH_CHARGER_LOG } from 'actions/activityLog';
import DropDown from 'components/inputs/DropDown';
import { AiFillCaretDown } from 'react-icons/ai';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import ReactPagination from 'components/general/ReactPagination';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { SiMicrosoftexcel } from 'react-icons/si';
import Button from 'components/inputs/Button';
import { MdClose } from 'react-icons/md';
import 'react-datetime/css/react-datetime.css';
import DateTimePicker from 'components/inputs/DateTimePicker';
import { BiSort } from 'react-icons/bi';
import fileDownload from 'js-file-download';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { t } from 'i18next';

const UTC = 'UTC';
const IST = 'IST';

const LogInfo = (props) => {
  const { logFrom } = props;
  const { showDropdown = true } = props;
  const { chargerId } = useParams();
  const dispatch = useDispatch();
  const isChargerLog = logFrom === 'charger';

  const [type, setType] = useState('All');
  const [fieldValue, setFieldValue] = useState('All');
  const [startDate, setStartDate] = useState(moment(new Date()).startOf('day').utc());
  const [endDate, setEndDate] = useState(moment(new Date()).endOf('day').utc());
  const [searchText, setSearchText] = useState('');
  const [showSendLocalListModal, setShowSendLocalListModal] = useState(false);
  const [showMeterModal, setShowMeterModal] = useState(false);
  const [showGetConfigurationModal, setShowGetConfigurationModel] = useState(false);
  const [configurationDetail, setConfigurationDetail] = useState('');
  const [localList, setLocalList] = useState('');
  const [meterValue, setMeterValue] = useState('');
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });

  const { activeTab, searchBoxWidth } = props;

  LogInfo.propTypes = {
    showDropdown: PropTypes.bool, // Add this line to validate showDropdown prop
    activeTab: PropTypes.string.isRequired,
    searchBoxWidth: PropTypes.number,
  };

  const getSortByItem = (name, order, page, filterDateData) => {
    const sortedOrder = order || 'asc';
    if (page && startDate && endDate && type) {
      const data = {
        date_from: moment(startDate).utc().format('YYYY-MM-DD HH:mm:ss'),
        date_to: moment(endDate).utc().format('YYYY-MM-DD HH:mm:ss'),
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
        type: type,
      };
      const allTypeData = {
        date_from: moment(startDate).utc().format('YYYY-MM-DD HH:mm:ss'),
        date_to: moment(endDate).utc().format('YYYY-MM-DD HH:mm:ss'),
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      type === 'All' ? getActivityLog(allTypeData) : endDate && getActivityLog(data);
    } else if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
        type: type,
      };
      const allTypeData = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      type === 'All' ? getActivityLog(allTypeData) : endDate && getActivityLog(data);
    } else if (filterDateData) {
      const data = {
        ...filterDateData,
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        type: type,
      };
      const allTypeData = {
        ...filterDateData,
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      type === 'All' ? getActivityLog(allTypeData) : endDate && getActivityLog(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        type: type,
      };
      const allTypeData = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      type === 'All' ? getActivityLog(allTypeData) : endDate && getActivityLog(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        const filterDateData = {
          date_from: moment(startDate).utc().format('YYYY-MM-DD HH:mm:ss'),
          date_to: moment(endDate).utc().format('YYYY-MM-DD HH:mm:ss'),
        };
        endDate && getSortByItem(sortByItem.item, sortByItem.order, page, filterDateData);
      } else if (startDate && endDate) {
        const data = {
          date_from: moment(startDate).utc().format('YYYY-MM-DD HH:mm:ss'),
          date_to: moment(endDate).utc().format('YYYY-MM-DD HH:mm:ss'),
          page: page.selected + 1,
        };
        const typeData = {
          date_from: moment(startDate).utc().format('YYYY-MM-DD HH:mm:ss'),
          date_to: moment(endDate).utc().format('YYYY-MM-DD HH:mm:ss'),
          page: page.selected + 1,
          type: type,
        };
        type === 'All' ? getActivityLog(data) : getActivityLog(typeData);
      } else if (searchText) {
        const data = {
          page: page.selected + 1,
          search: searchText,
        };
        const typeData = {
          page: page.selected + 1,
          search: searchText,
          type: type,
        };
        type === 'All' ? getActivityLog(data) : getActivityLog(typeData);
      } else if (type) {
        const data = {
          page: page.selected + 1,
        };
        const typeData = {
          page: page.selected + 1,
          type: type,
        };
        type === 'All' ? getActivityLog(data) : getActivityLog(typeData);
      } else {
        const data = {
          page: page.selected + 1,
        };
        getActivityLog(data);
      }
    },
    [sortByItem, startDate, endDate, type, searchText]
  );

  const chargerLog = [
    { label: 'Boot notification', value: 'BootNotification' },
    { label: 'Heartbeat', value: 'Heartbeat' },
    { label: 'Authorize', value: 'Authorize' },
    { label: 'Data transfer', value: 'DataTransfer' },
    { label: 'Diagnostics status notification', value: 'DiagnosticsStatusNotification' },
    { label: 'Firmware status notification', value: 'FirmwareStatusNotification' },
    { label: 'Meter values', value: 'MeterValues' },
    { label: 'Start transaction', value: 'StartTransaction' },
    { label: 'Status notification detail', value: 'StatusNotification' },
    { label: 'Stop transaction', value: 'StopTransaction' },
    { label: 'All', value: 'All' },
  ];

  const serverLog = [
    { label: 'Cancel Reservation', value: 'CancelReservation' },
    { label: 'Change Availability', value: 'ChangeAvailability' },
    { label: 'Change Configuration', value: 'ChangeConfiguration' },
    { label: 'Get Configuration', value: 'GetConfiguration' },
    { label: 'Clear Cache', value: 'ClearCache' },
    { label: 'Data Transfer', value: 'DataTransfer' },
    { label: 'Get Diagnostics', value: 'GetDiagnostics' },
    { label: 'Get Local List Version', value: 'GetLocalListVersion' },
    { label: 'Remote Start Transaction', value: 'RemoteStartTransaction' },
    { label: 'Remote Stop Transaction', value: 'RemoteStopTransaction' },
    { label: 'Reserve Now', value: 'ReserveNow' },
    { label: 'Reset', value: 'Reset' },
    { label: 'Send Local List', value: 'SendLocalList' },
    { label: 'Trigger Message', value: 'TriggerMessage' },
    { label: 'Unlock Connector', value: 'UnlockConnector' },
    { label: 'Update Firmware', value: 'UpdateFirmware' },
    { label: 'All', value: 'All' },
  ];

  const chargerData = {
    All: [
      // {
      //   // columnName: 'OCPP Id',
      //   // key: 'charger.charger_id',
      // },
      {
        columnName: 'Request String',
        key: 'request_string',
        isJson: true,
      },
      {
        columnName: 'Response String',
        key: 'response_string',
        isJson: true,
      },
      {
        columnName: 'Requested Time (IST)',
        key: 'date_time.from',
        isDate: true,
        dateConvertTo: IST,
      },
      {
        columnName: 'Response Time (IST)',
        key: 'response_time',
        isDate: true,
        dateConvertTo: IST,
      },
    ],
    BootNotification: [
      {
        columnName: 'Vendor',
        key: 'message.chargePointVendor',
      },
      {
        columnName: 'Model',
        key: 'message.chargePointModel',
      },
      {
        columnName: 'Point Serial No',
        key: 'message.chargePointSerialNumber',
      },
      {
        columnName: 'Box Serial No',
        key: 'message.chargeBoxSerialNumber',
      },
      {
        columnName: 'Firm Version',
        key: 'message.firmwareVersion',
      },
      {
        columnName: 'Requested Time (IST)',
        key: 'date_time.from',
        isDate: true,
        dateConvertTo: IST,
      },
      {
        columnName: 'Response Time (IST)',
        key: 'response_time',
        isDate: true,
        dateConvertTo: IST,
      },
    ],
    Heartbeat: [
      {
        columnName: 'Requested Time (IST)',
        key: 'date_time.from',
        isDate: true,
        dateConvertTo: IST,
      },
      {
        columnName: 'Response Time (IST)',
        key: 'response_time',
        isDate: true,
        dateConvertTo: IST,
      },
    ],
    StatusNotification: [
      {
        columnName: 'Connector ID',
        key: 'message.connectorId',
      },
      {
        columnName: 'Error Code',
        key: 'message.errorCode',
      },
      {
        columnName: 'Status',
        key: 'message.status',
      },
      {
        columnName: 'Vendor Error Code',
        key: 'message.vendorErrorCode',
      },
      {
        columnName: 'Info',
        key: 'message.info',
      },
      {
        columnName: 'Charger Time (UTC)',
        key: 'message.timestamp',
        isDate: true,
        dateConvertTo: UTC,
      },

      {
        columnName: 'Charger Time (IST)',
        key: 'message.timestamp',
        isDate: true,
        dateConvertTo: IST,
      },
      {
        columnName: 'Requested Time(IST)',
        key: 'date_time.from',
        isDate: true,
        dateConvertTo: IST,
      },
      {
        columnName: 'Response Time (IST)',
        key: 'response_time',
        isDate: true,
        dateConvertTo: IST,
      },
    ],
    StartTransaction: [
      {
        columnName: 'Connector ID',
        key: 'message.connectorId',
      },
      {
        columnName: 'Id Tag',
        key: 'message.idTag',
      },
      {
        columnName: 'Meter Start',
        key: 'message.meterStart',
      },
      {
        columnName: 'Transaction Id',
        key: 'response.transactionId',
      },
      {
        columnName: 'Charger Time (UTC)',
        key: 'message.timestamp',
        isDate: true,
        dateConvertTo: UTC,
      },
      {
        columnName: 'Charger Time (IST)',
        key: 'message.timestamp',
        isDate: true,
        dateConvertTo: IST,
      },
      {
        columnName: 'Requested Time (IST)',
        key: 'date_time.from',
        isDate: true,
        dateConvertTo: IST,
      },
      {
        columnName: 'Response Time (IST)',
        key: 'response_time',
        isDate: true,
        dateConvertTo: IST,
      },
      {
        columnName: 'Status',
        key: 'response.idTagInfo.status',
      },
    ],
    StopTransaction: [
      {
        columnName: 'Connector ID',
        key: 'message.connectorId',
      },
      {
        columnName: 'Id Tag',
        key: 'message.idTag',
      },
      {
        columnName: 'Meter Stop',
        key: 'message.meterStop',
      },
      {
        columnName: 'Transaction Id',
        key: 'message.transactionId',
      },
      {
        columnName: 'Reason',
        key: 'message.reason',
      },
      {
        columnName: 'Charger Time (UTC)',
        key: 'message.timestamp',
        isDate: true,
        dateConvertTo: UTC,
      },
      {
        columnName: 'Charger Time (IST)',
        key: 'message.timestamp',
        isDate: true,
        dateConvertTo: IST,
      },
      {
        columnName: 'Requested Time(IST)',
        key: 'date_time.from',
        isDate: true,
        dateConvertTo: IST,
      },
      {
        columnName: 'Response Time (IST)',
        key: 'response_time',
        isDate: true,
        dateConvertTo: IST,
      },
      {
        columnName: 'Status',
        key: 'response.idTagInfo.status',
      },
    ],
    Authorize: [
      {
        columnName: 'Id Tag',
        key: 'message.idTag',
      },
      {
        columnName: 'Status',
        key: 'response.idTagInfo.status',
      },
      {
        columnName: 'Requested Time (IST)',
        key: 'date_time.from',
        isDate: true,
        dateConvertTo: IST,
      },
      {
        columnName: 'Response Time (IST)',
        key: 'response_time',
        isDate: true,
        dateConvertTo: IST,
      },
    ],
    DataTransfer: [
      {
        columnName: 'Vendor ID',
        key: 'message.vendorId',
      },
      {
        columnName: 'Data',
        key: 'message.data',
      },
      {
        columnName: 'Status',
        key: 'response.idTagInfo.status',
      },
      {
        columnName: 'Requested Time (IST)',
        key: 'date_time.from',
        isDate: true,
        dateConvertTo: IST,
      },
      {
        columnName: 'Response Time (IST)',
        key: 'response_time',
        isDate: true,
        dateConvertTo: IST,
      },
    ],
    DiagnosticsStatusNotification: [
      {
        columnName: 'Status',
        key: 'message.status',
      },
      {
        columnName: 'Requested Time (IST)',
        key: 'date_time.from',
        isDate: true,
        dateConvertTo: IST,
      },
      {
        columnName: 'Response Time (IST)',
        key: 'response_time',
        isDate: true,
        dateConvertTo: IST,
      },
    ],
    FirmwareStatusNotification: [
      {
        columnName: 'Status',
        key: 'message.status',
      },
      {
        columnName: 'Requested Time (IST)',
        key: 'date_time.from',
        isDate: true,
        dateConvertTo: IST,
      },
      {
        columnName: 'Response Time (IST)',
        key: 'response_time',
        isDate: true,
        dateConvertTo: IST,
      },
    ],
    MeterValues: [
      {
        columnName: 'Connector ID',
        key: 'message.connectorId',
      },
      {
        columnName: 'Transaction Id',
        key: 'message.transactionId',
      },
      {
        columnName: 'Message Id',
        key: 'message_id',
      },
      {
        columnName: 'Charger Time (UTC)',
        key: 'message.meterValue[0].timestamp',
        isDate: true,
        dateConvertTo: UTC,
      },
      {
        columnName: 'Charger Time (IST)',
        key: 'message.meterValue[0].timestamp',
        isDate: true,
        dateConvertTo: IST,
      },
      {
        columnName: 'Requested Time (IST)',
        key: 'date_time.from',
        isDate: true,
        dateConvertTo: IST,
      },
      {
        columnName: 'Response Time (IST)',
        key: 'response_time',
        isDate: true,
        dateConvertTo: IST,
      },
    ],
  };

  const serverData = {
    CancelReservation: [
      {
        columnName: 'Reservation Id',
        key: 'message.reservationId',
      },
      {
        columnName: 'Status',
        key: 'response.status',
      },
      {
        columnName: 'Request By',
        key: 'user_involved.name',
      },
      {
        columnName: 'Requested Time (IST)',
        key: 'date_time.from',
        isDate: true,
        dateConvertTo: IST,
      },
      {
        columnName: 'Response Time (IST)',
        key: 'response_time',
        isDate: true,
        dateConvertTo: IST,
      },
    ],
    ChangeAvailability: [
      {
        columnName: 'Connector Id',
        key: 'message.connectorId',
      },
      {
        columnName: 'Availability Type',
        key: 'message.type',
      },
      {
        columnName: 'Status',
        key: 'response.status',
      },
      {
        columnName: 'Request By',
        key: 'user_involved.name',
      },
      {
        columnName: 'Requested Time (IST)',
        key: 'date_time.from',
        isDate: true,
        dateConvertTo: IST,
      },
      {
        columnName: 'Response Time (IST)',
        key: 'response_time',
        isDate: true,
        dateConvertTo: IST,
      },
    ],
    ChangeConfiguration: [
      {
        columnName: 'Configuration Key',
        key: 'message.key',
      },
      {
        columnName: 'Configuration Value',
        key: 'message.value',
      },
      {
        columnName: 'Status',
        key: 'response.status',
      },
      {
        columnName: 'Request By',
        key: 'user_involved.name',
      },
      {
        columnName: 'Requested Time (IST)',
        key: 'date_time.from',
        isDate: true,
        dateConvertTo: IST,
      },
      {
        columnName: 'Response Time (IST)',
        key: 'response_time',
        isDate: true,
        dateConvertTo: IST,
      },
    ],
    GetConfiguration: [
      {
        columnName: 'Request By',
        key: 'user_involved.name',
      },
      {
        columnName: 'Requested Time (IST)',
        key: 'date_time.from',
        isDate: true,
        dateConvertTo: IST,
      },
      {
        columnName: 'Response Time (IST)',
        key: 'response_time',
        isDate: true,
        dateConvertTo: IST,
      },
    ],
    ClearCache: [
      {
        columnName: 'Status',
        key: 'response.status',
      },
      {
        columnName: 'Request By',
        key: 'user_involved.name',
      },
      {
        columnName: 'Requested Time (IST)',
        key: 'date_time.from',
        isDate: true,
        dateConvertTo: IST,
      },
      {
        columnName: 'Response Time (IST)',
        key: 'response_time',
        isDate: true,
        dateConvertTo: IST,
      },
    ],
    DataTransfer: [
      {
        columnName: 'User Name',
        key: 'user_involved.name',
      },
    ],
    GetDiagnostics: [
      {
        columnName: 'Location',
        key: 'message.location',
      },
      {
        columnName: 'Start Time (IST)',
        key: 'message.startTime',
        isDate: true,
        dateConvertTo: IST,
      },
      {
        columnName: 'Stop Time (IST)',
        key: 'message.stopTime',
        isDate: true,
        dateConvertTo: IST,
      },
      {
        columnName: 'File Name',
      },
      {
        columnName: 'Request By',
        key: 'user_involved.name',
      },
      {
        columnName: 'Requested Time (IST)',
        key: 'date_time.from',
        isDate: true,
        dateConvertTo: IST,
      },
      {
        columnName: 'Response Time (IST)',
        key: 'response_time',
        isDate: true,
        dateConvertTo: IST,
      },
    ],
    GetLocalListVersion: [
      {
        columnName: 'List Version',
        key: 'response.listVersion',
      },
      {
        columnName: 'Request By',
        key: 'user_involved.name',
      },
      {
        columnName: 'Requested Time (IST)',
        key: 'date_time.from',
        isDate: true,
        dateConvertTo: IST,
      },
      {
        columnName: 'Response Time (IST)',
        key: 'response_time',
        isDate: true,
        dateConvertTo: IST,
      },
    ],
    RemoteStartTransaction: [
      {
        columnName: 'Connector Id',
        key: 'message.connectorId',
      },
      {
        columnName: 'Id Tag',
        key: 'message.idTag',
      },
      {
        columnName: 'Status',
        key: 'response.status',
      },
      {
        columnName: 'Request By',
        key: 'user_involved.name',
      },
      {
        columnName: 'Requested Time (IST)',
        key: 'date_time.from',
        isDate: true,
        dateConvertTo: IST,
      },
      {
        columnName: 'Response Time (IST)',
        key: 'response_time',
        isDate: true,
        dateConvertTo: IST,
      },
    ],
    RemoteStopTransaction: [
      {
        columnName: 'Transaction Id',
        key: 'message.transactionId',
      },
      {
        columnName: 'Status',
        key: 'response.status',
      },
      {
        columnName: 'Request By',
        key: 'user_involved.name',
      },
      {
        columnName: 'Requested Time (IST)',
        key: 'date_time.from',
        isDate: true,
        dateConvertTo: IST,
      },
      {
        columnName: 'Response Time (IST)',
        key: 'response_time',
        isDate: true,
        dateConvertTo: IST,
      },
    ],
    ReserveNow: [
      {
        columnName: 'Connector Id',
        key: 'message.connectorId',
      },
      {
        columnName: 'Token No',
      },
      {
        columnName: 'Reservation Id',
        key: 'message.reservationId',
      },
      {
        columnName: 'Status',
        key: 'response.status',
      },
      {
        columnName: 'Request By',
        key: 'user_involved.name',
      },
      {
        columnName: 'Requested Time (IST)',
        key: 'date_time.from',
        isDate: true,
        dateConvertTo: IST,
      },
      {
        columnName: 'Response Time (IST)',
        key: 'response_time',
        isDate: true,
        dateConvertTo: IST,
      },
    ],
    Reset: [
      {
        columnName: 'Reset Type',
        key: 'message.type',
      },
      {
        columnName: 'Status',
        key: 'response.status',
      },
      {
        columnName: 'Request By',
        key: 'user_involved.name',
      },
      {
        columnName: 'Requested Time (IST)',
        key: 'date_time.from',
        isDate: true,
        dateConvertTo: IST,
      },
      {
        columnName: 'Response Time (IST)',
        key: 'response_time',
        isDate: true,
        dateConvertTo: IST,
      },
    ],
    SendLocalList: [
      {
        columnName: 'List Version',
        key: 'message.listVersion',
      },
      {
        columnName: 'Status',
        key: 'response.status',
      },
      {
        columnName: 'Update Type',
        key: 'message.updateType',
      },
      {
        columnName: 'User Name',
        key: 'user_involved.name',
      },
      {
        columnName: 'Response Time (IST)',
        key: 'response_time',
        isDate: true,
        dateConvertTo: IST,
      },
    ],
    TriggerMessage: [
      {
        columnName: 'Requested Message',
        key: 'message.requestedMessage',
      },
      {
        columnName: 'Connector Id',
        key: 'message.connectorId',
      },
      {
        columnName: 'Status',
        key: 'response.status',
      },
      {
        columnName: 'Request By',
        key: 'user_involved.name',
      },
      {
        columnName: 'Requested Time (IST)',
        key: 'date_time.from',
        isDate: true,
        dateConvertTo: IST,
      },
      {
        columnName: 'Response Time (IST)',
        key: 'response_time',
        isDate: true,
        dateConvertTo: IST,
      },
    ],
    UnlockConnector: [
      {
        columnName: 'Connector Id',
        key: 'message.connectorId',
      },
      {
        columnName: 'Status',
        key: 'response.status',
      },
      {
        columnName: 'Request By',
        key: 'user_involved.name',
      },
      {
        columnName: 'Requested Time (IST)',
        key: 'date_time.from',
        isDate: true,
        dateConvertTo: IST,
      },
      {
        columnName: 'Response Time (IST)',
        key: 'response_time',
        isDate: true,
        dateConvertTo: IST,
      },
    ],
    UpdateFirmware: [
      {
        columnName: 'Location',
        key: 'message.location',
      },
      {
        columnName: 'Request By',
        key: 'user_involved.name',
      },
      {
        columnName: 'Requested Time (IST)',
        key: 'date_time.from',
        isDate: true,
        dateConvertTo: IST,
      },
      {
        columnName: 'Response Time (IST)',
        key: 'response_time',
        isDate: true,
        dateConvertTo: IST,
      },
    ],
    All: [
      // {
      //   // columnName: 'OCPP Id',
      //   // key: 'charger.charger_id',
      // },
      {
        columnName: 'Request String',
        key: 'request_string',
        isJson: true,
      },
      {
        columnName: 'Response String',
        key: 'response_string',
        isJson: true,
      },
      {
        columnName: 'Requested Time (IST)',
        key: 'date_time.from',
        isDate: true,
        dateConvertTo: IST,
      },
      {
        columnName: 'Response Time (IST)',
        key: 'response_time',
        isDate: true,
        dateConvertTo: IST,
      },
    ],
  };
  const page = useSelector((state) => state.activityLog.page);
  const limit = useSelector((state) => state.activityLog.limit);
  const totalData = useSelector((state) => state.activityLog.total);
  const totalPages = useSelector((state) => state.activityLog.totalPages);
  const activityLogList = useSelector((state) => state.activityLog.activityLogs);
  const isLoader = useSelector((state) => state.activityLog.isLoading);
  const isLoading = useSelector((state) => state.downloadingLoader.isLoading);

  let serialNum = limit * (page - 1);

  const getActivityLog = useCallback(
    (data = {}) => {
      if (searchText) {
        const searchLogData = {
          charger: chargerId,
          from: logFrom,
          ...data,
          search: searchText,
        };
        dispatch({ type: FETCH_CHARGER_LOG, payload: searchLogData });
      } else {
        const logData = {
          charger: chargerId,
          from: logFrom,
          ...data,
        };
        dispatch({ type: FETCH_CHARGER_LOG, payload: logData });
      }
    },
    [searchText]
  );

  const downloadActivityLog = useCallback(
    (data = {}) => {
      const logData = {
        charger: chargerId,
        from: logFrom,
        excel: true,
        type: type,
        date_from: moment(startDate).utc().format('YYYY-MM-DD HH:mm:ss'),
        date_to: moment(endDate).utc().format('YYYY-MM-DD HH:mm:ss'),
        ...data,
      };
      const allData = {
        charger: chargerId,
        from: logFrom,
        excel: true,
        date_from: moment(startDate).utc().format('YYYY-MM-DD HH:mm:ss'),
        date_to: moment(endDate).utc().format('YYYY-MM-DD HH:mm:ss'),
        ...data,
      };
      const chargerLogData = type === 'All' ? allData : logData;
      dispatch({
        type: DOWNLOAD_CHARGER_LOG_DATA,
        payload: chargerLogData,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              fileDownload(res.data, `${type}.xlsx`);
            }
          }
        },
      });
    },
    [type, startDate, endDate]
  );

  const tabformat = useCallback(() => {
    const startDateTime = moment(startDate).utc().format('YYYY-MM-DD HH:mm:ss');
    const endDateTime = moment(endDate).utc().format('YYYY-MM-DD HH:mm:ss');
    const filterDateData = {
      date_from: startDateTime,
      date_to: endDateTime,
      charger: chargerId,
    };

    dispatch({
      type: DOWNLOAD_TABULAR_METERVALUE,
      payload: filterDateData,
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            fileDownload(res.data, `${'Meter Values'}.xlsx`);
          }
        }
      },
    });
  }, [type, startDate, endDate]);
  // useEffect(() => {
  //   const data = {
  //     type: type,
  //   };
  //   type === 'All' ? getActivityLog() : getActivityLog(data);
  // }, [type]);

  useEffect(() => {
    if (sortByItem.item && sortByItem.order) {
      const filterDateData = {
        date_from: moment(startDate).utc().format('YYYY-MM-DD HH:mm:ss'),
        date_to: moment(endDate).utc().format('YYYY-MM-DD HH:mm:ss'),
      };
      endDate && getSortByItem(sortByItem.item, sortByItem.order, '', filterDateData);
    } else {
      const data = {
        type: type,
        date_from: moment(startDate).utc().format('YYYY-MM-DD HH:mm:ss'),
        date_to: moment(endDate).utc().format('YYYY-MM-DD HH:mm:ss'),
      };
      const allData = {
        date_from: moment(startDate).utc().format('YYYY-MM-DD HH:mm:ss'),
        date_to: moment(endDate).utc().format('YYYY-MM-DD HH:mm:ss'),
      };
      type === 'All' ? getActivityLog(allData) : getActivityLog(data);
    }
  }, [startDate, endDate, sortByItem, type]);

  const dateFilter = useCallback(() => {
    const startDateTime = moment(startDate).utc().format('YYYY-MM-DD HH:mm:ss');
    const endDateTime = moment(endDate).utc().format('YYYY-MM-DD HH:mm:ss');
    const filterDateData = {
      from: logFrom,
      type: type,
      date_from: startDateTime,
      date_to: endDateTime,
    };
    const allFilterDateData = {
      from: logFrom,
      date_from: startDateTime,
      date_to: endDateTime,
    };
    type === 'All' ? getActivityLog(allFilterDateData) : getActivityLog(filterDateData);
  }, [type, startDate, endDate, searchText]);

  // const handlePageClick = (page) => {
  //   const data = {
  //     page: page,
  //   };
  //   const typeData = {
  //     page: page,
  //     type: type,
  //   };
  //   type === 'All' ? getActivityLog(data) : getActivityLog(typeData);
  // };

  const tableDataInfo = isChargerLog ? chargerData : serverData;

  const handleCloseTable = () => {
    setShowSendLocalListModal(false);
    setShowGetConfigurationModel(false);
    setShowMeterModal(false);
  };
  const downloadFile = () => {
    downloadActivityLog();
  };
  const handleSearch = () => {
    getActivityLog({});
  };

  return (
    <div className="log-list--main">
      <div className="log-list-box">
        <Row className="log-row">
          <Col xl={2} lg={8} md={8}>
            <div className="search-box--block">
              <div className="search--box" style={{ width: activeTab === 'sequentialLogs' ? `${searchBoxWidth}px` : '' }}>
                <SearchBox
                  value={searchText}
                  preIcon={<BsSearch />}
                  onChange={(e) => setSearchText(e.target.value)}
                  searchBoxWidth={searchBoxWidth}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleSearch();
                    }
                  }}
                />
              </div>
            </div>
          </Col>
          <Col xl={2} lg={4} md={4}>
            {showDropdown && (
              <DropDown
                popupClass="main-menu bottom scrollable"
                trigger={
                  <>
                    <div className="log-filter-block">
                      <div className="log-filter-icon">
                        <BsFilter />
                        <span className="log-filter-label">{fieldValue}</span>
                      </div>
                      <div className="log-arrow__icon">
                        <AiFillCaretDown />
                      </div>
                    </div>
                  </>
                }
                triggerClass="log-filter-dropdown"
              >
                {_.map(isChargerLog ? chargerLog : serverLog, (item, index) => (
                  <ul key={`${index}`}>
                    <li
                      className="item"
                      onClick={() => {
                        setFieldValue(item.label);
                        setType(item.value);
                      }}
                    >
                      {item.label}
                    </li>
                  </ul>
                ))}
              </DropDown>
            )}
          </Col>
          <Col xl={6} lg={10} md={10}>
            <div className="log-date-picker">
              {/*<DatePickerWithFloatingLabel*/}
              {/*  defaultValueOfStartDate={new Date()}*/}
              {/*  defaultValueOfEndDate={new Date()}*/}
              {/*  onChangeOfStartDate={(item) => setStartDate(item)}*/}
              {/*  onChangeOfEndDate={(item) => setEndDate(item)}*/}
              {/*/>*/}
              <DateTimePicker
                onChangeOfStartDate={(item) => setStartDate(item)}
                onChangeOfEndDate={(item) => setEndDate(item)}
                initialValueOfStartDate={moment(new Date()).startOf('day')}
                initialValueOfEndDate={moment(new Date()).endOf('day')}
              />
            </div>
          </Col>
          <Col xl={2} lg={2} md={2}>
            <div className="log-search-excel-block">
              <div className="log-search-box" onClick={dateFilter}>
                {/*<span>*/}
                {/*  <RiEqualizerFill />*/}
                {/*</span>*/}
                <Button className="log-search-button">Search</Button>
              </div>
              {type !== 'MeterValues' && type !== 'GetConfiguration' && type !== 'SendLocalList' && isLoading ? (
                <div className="loader--block">
                  <Spinner as="span" animation="border" size="sm" role="status" />
                </div>
              ) : (
                <div className="excel-icon" onClick={downloadFile}>
                  {/*<ReactHTMLTableToExcel*/}
                  {/*  id="test-table-xls-button"*/}
                  {/*  className="download-table-xls-button"*/}
                  {/*  table="table-to-xls"*/}
                  {/*  filename={fieldValue}*/}
                  {/*  sheet={fieldValue}*/}
                  {/*  buttonText={<SiMicrosoftexcel size={28} />}*/}
                  {/*/>*/}
                  <SiMicrosoftexcel size={28} color={'#5dad5d'} />
                </div>
              )}
              {type == 'MeterValues' ? (
                <div className="log-search-box" onClick={tabformat}>
                  <Button className="log-search-button">{t('logInfo.tabular')}</Button>
                </div>
              ) : (
                ''
              )}
            </div>
          </Col>
        </Row>
        <div className="table-responsive">
          {isLoader ? (
            <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
              <table className="record-list-table" id="table-to-xls">
                <thead>
                  <tr>
                    <th>
                      <Skeleton height={15} width={100} />
                    </th>

                    <th>
                      <Skeleton height={15} width={100} />
                    </th>

                    <th>
                      <Skeleton height={15} width={100} />
                    </th>
                    <th>
                      <Skeleton height={15} width={100} />
                    </th>
                    <th>
                      <Skeleton height={15} width={100} />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Skeleton height={15} width={100} />
                    </td>

                    <td>
                      <Skeleton height={15} width={100} />
                    </td>

                    <td>
                      <Skeleton height={15} width={100} />
                    </td>
                    <td>
                      <Skeleton height={15} width={100} />
                    </td>
                    <td>
                      <Skeleton height={15} width={100} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </SkeletonTheme>
          ) : (
            <table className="record-list-table" id="table-to-xls">
              <thead>
                <tr>
                  <th>{t('logInfo.srNo')}</th>
                  {_.map(_.get(tableDataInfo, `${type}`, []), (headerItem) => (
                    <th>
                      <div className="sorting">
                        <span>{_.get(headerItem, 'columnName', '')}</span>
                        <span className="ico" onClick={() => handleSorting(_.get(headerItem, 'key', ''), sortByItem)}>
                          <BiSort size={15} />
                        </span>
                      </div>
                    </th>
                  ))}
                  {type === 'ReserveNow' && <th>{t('logInfo.expiryDate')}</th>}
                  {type === 'ReserveNow' && <th>{t('logInfo.expiryTime')}</th>}
                  {(type === 'MeterValues' || type === 'GetConfiguration' || type === 'SendLocalList') && <th>{t('logInfo.action')}</th>}
                </tr>
              </thead>
              <tbody>
                {isLoader ? (
                  <tr>
                    <td colSpan={8} className="border-0">
                      <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
                        <Skeleton height={15} />
                      </SkeletonTheme>
                    </td>
                  </tr>
                ) : _.isEmpty(activityLogList) ? (
                  <tr>
                    <td colSpan={10} className="border-0">
                      {/* <div className="empty-data-block">{type === 'All' ? 'No Data Found' : `No ${fieldValue} Data Found`}</div> */}
                      <div className="empty-data-block">{t('logInfo.noData')}</div>
                    </td>
                  </tr>
                ) : (
                  !(isLoader || _.isUndefined(isLoader)) &&
                  _.map(activityLogList, (item, index) => (
                    <tr key={`log-${index}`}>
                      <td>{serialNum + (index + 1)}</td>
                      {_.map(_.get(tableDataInfo, `${type}`, []), (dataItem) => {
                        const isDate = dataItem.isDate;
                        const isJson = dataItem.isJson;
                        const dateInfo = _.get(item, `${_.get(dataItem, 'key', '')}`, '-');
                        const dateFormat =
                          dataItem.isDate && dateInfo && dataItem.dateConvertTo && dataItem.dateConvertTo === UTC
                            ? moment(dateInfo).utc().format('DD/MM/YYYY HH:mm:ss')
                            : moment(dateInfo).format('DD/MM/YYYY HH:mm:ss');
                        const jsonFormat = dataItem.isJson && _.get(item, `${_.get(dataItem, 'key', '')}`);
                        return (
                          <td className={`${isJson ? 'json-data__block' : ''}`}>
                            {isDate ? (
                              dateFormat === 'Invalid date' ? (
                                ' '
                              ) : (
                                dateFormat
                              )
                            ) : isJson ? (
                              <div className="json--data">{jsonFormat}</div>
                            ) : (
                              _.get(item, `${_.get(dataItem, 'key', '')}`, '-')
                            )}
                          </td>
                        );
                      })}
                      {type === 'ReserveNow' && <td>{moment(_.get(item, 'message.expiryDate', '-')).format('DD/MM/YYYY')} </td>}
                      {type === 'ReserveNow' && <td>{moment(_.get(item, 'message.expiryDate', '-')).format('HH:mm:ss')} </td>}
                      <td>
                        {(type === 'MeterValues' && _.get(item, 'message.meterValue', '-')) ||
                        (type === 'GetConfiguration' && _.get(item, 'response.configurationKey', '-')) ||
                        (type === 'SendLocalList' && _.get(item, 'message', '-')) ? (
                          <div>
                            <span className="eye-icon">
                              {(_.has(item, 'message') || _.has(item, 'response.configurationKey') || _.has(item, 'message')) && (
                                <BsEye
                                  onClick={() => {
                                    if (type === 'MeterValues') {
                                      setShowMeterModal(true);
                                      setMeterValue(_.get(item, 'message.meterValue', '-'));
                                    }
                                    if (type === 'GetConfiguration') {
                                      setShowGetConfigurationModel(true);
                                      setConfigurationDetail(_.get(item, 'response.configurationKey', '-'));
                                    }
                                    if (type === 'SendLocalList') {
                                      setShowSendLocalListModal(true);
                                      setLocalList(_.get(item, 'message.localAuthorizationList', '-'));
                                    }
                                  }}
                                />
                              )}
                            </span>
                            <span className="excel-icon" onClick={downloadFile}>
                              <SiMicrosoftexcel size={25} color={'#5dad5d'} />
                            </span>
                          </div>
                        ) : (
                          ((type === 'MeterValues' && !_.get(item, 'message.meterValue', '-')) ||
                            (type === 'GetConfiguration' && !_.get(item, 'response.configurationKey', '-'))) && (
                            <div>
                              <span className="eye-icon">
                                <BsEyeSlash />
                              </span>
                              <span className="excel-icon" onClick={downloadFile}>
                                <SiMicrosoftexcel size={25} color={'#5dad5d'} />
                              </span>
                            </div>
                          )
                        )}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
      {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(activityLogList) && (
        // <Pagination currentPage={page} limit={limit} total={totalData} onPageChange={(pageVal) => handlePageClick(pageVal)} />
        <ReactPagination
          currentPage={page}
          limit={limit}
          total={totalData}
          handlePageClick={(pageVal) => handlePageClick(pageVal)}
          totalPages={totalPages}
          marginPagesDisplayed={1}
        />
      )}
      {showSendLocalListModal && (
        <Modal show={showSendLocalListModal} size="xl" centered>
          <div className="model_main_content">
            <div className="title_name">{t('logInfo.localAuthorization')}</div>
            <div className="table-close-btn">
              <MdClose size={30} color="#be210b" onClick={handleCloseTable} />
            </div>
          </div>
          <div className="table-responsive">
            <table className="record-list-table">
              <thead>
                <tr>
                  <th>
                    <div className="sorting">
                      <span>{t('logInfo.srNo')}</span>
                    </div>
                  </th>
                  <th>
                    <div className="sorting">
                      <span>{t('logInfo.idTag')}</span>
                    </div>
                  </th>
                  <th>
                    <div className="sorting">
                      <span>{t('logInfo.expiry_Date')}</span>
                    </div>
                  </th>
                  <th>
                    <div className="sorting">
                      <span>{t('logInfo.status')}</span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {searchText && _.isEmpty(localList) ? (
                  <tr>
                    <td colSpan={4} className="border-0">
                      <div className="empty-data-block">{t('logInfo.noData')}</div>
                    </td>
                  </tr>
                ) : (
                  _.map(localList, (item, index) => {
                    return (
                      <tr key={`data-item-${index}`}>
                        <td>{index + 1}</td>
                        <td>{_.get(item, 'idTag', '-')}</td>
                        <td>{_.get(item, 'idTagInfo.expiryDate', '-')}</td>
                        <td>{_.get(item, 'idTagInfo.status', '-')}</td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </Modal>
      )}
      {showGetConfigurationModal && (
        <Modal show={showGetConfigurationModal} size="xl" centered>
          <div className="modal-content">
            <div className="model_main_content">
              <div className="title_name">{t('logInfo.configResponse')}</div>
              <div className="table-close-btn">
                <MdClose size={30} color="#be210b" onClick={handleCloseTable} />
              </div>
            </div>
            <div className="table-responsive">
              <table className="record-list-table">
                <thead>
                  <tr>
                    <th>
                      <div className="sorting">
                        <span>{t('logInfo.srNo')}</span>
                      </div>
                    </th>
                    <th>
                      <div className="sorting">
                        <span>{t('logInfo.configKey')}</span>
                      </div>
                    </th>
                    <th>
                      <div className="sorting">
                        <span>{t('logInfo.configReadonly')}</span>
                      </div>
                    </th>
                    <th>
                      <div className="sorting">
                        <span>{t('logInfo.configValue')}</span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {searchText && _.isEmpty(configurationDetail) ? (
                    <tr>
                      <td colSpan={4} className="border-0">
                        <div className="empty-data-block">{t('logInfo.noData')}</div>
                      </td>
                    </tr>
                  ) : (
                    _.map(configurationDetail, (item, index) => {
                      return (
                        <tr key={`configuration-data-item-${index}`}>
                          <td>{index + 1}</td>
                          <td>{_.get(item, 'key', '-')}</td>
                          <td>{_.get(item, 'readonly', '-').toString()}</td>
                          <td>{_.get(item, 'value', '-')}</td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Modal>
      )}
      {showMeterModal && (
        <Modal show={showMeterModal} size="xl" centered>
          <div className="model_main_content">
            <div className="title_name">{t('logInfo.meterValueResponse')}</div>
            <div className="table-close-btn">
              <MdClose size={30} color="#be210b" onClick={handleCloseTable} />
            </div>
          </div>
          <div className="table-responsive">
            <table className="record-list-table">
              <thead>
                <tr>
                  <th>
                    <div className="sorting">
                      <span>{t('logInfo.srNo')}</span>
                    </div>
                  </th>
                  <th>
                    <div className="sorting">
                      <span>{t('logInfo.measurand')}</span>
                    </div>
                  </th>
                  <th>
                    <div className="sorting">
                      <span>{t('logInfo.context')}</span>
                    </div>
                  </th>
                  <th>
                    <div className="sorting">
                      <span>{t('logInfo.phase')}</span>
                    </div>
                  </th>
                  <th>
                    <div className="sorting">
                      <span>{t('logInfo.location')}</span>
                    </div>
                  </th>
                  <th>
                    <div className="sorting">
                      <span>{t('logInfo.unit')}</span>
                    </div>
                  </th>
                  <th>
                    <div className="sorting">
                      <span>{t('logInfo.value')}</span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {searchText && _.isEmpty(meterValue) ? (
                  <tr>
                    <td colSpan={4} className="border-0">
                      <div className="empty-data-block">{t('logInfo.noData')}</div>
                    </td>
                  </tr>
                ) : (
                  _.map(meterValue, (item, index) => {
                    return (
                      _.get(item, 'sampledValue', '-') &&
                      _.map(_.get(item, 'sampledValue'), (data) => {
                        return (
                          <tr key={`data-item-${index}`}>
                            <td>{index + 1}</td>
                            <td>{_.get(data, 'measurand', '-')}</td>
                            <td>{_.get(data, 'context')}</td>
                            <td>{_.get(data, 'phase', '-')}</td>
                            <td>{_.get(data, 'location', '-')}</td>
                            <td>{_.get(data, 'unit', '-')}</td>
                            <td>{_.get(data, 'value', '-')}</td>
                          </tr>
                        );
                      })
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </Modal>
      )}
    </div>
  );
};
LogInfo.propTypes = {
  logFrom: PropTypes.string,
};
export default LogInfo;
