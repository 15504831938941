export const FETCH_INVOICE = 'FETCH_INVOICE';
export const FETCH_INVOICE_SUCCESS = 'FETCH_INVOICE_SUCCESS';
export const FETCH_INVOICE_FAIL = 'FETCH_INVOICE_FAIL';

export const DOWNLOAD_CHARGE_RCT = 'FETCH_CHARGE_RCT';
export const DOWNLOAD_CHARGE_RCT_SUCCESS = 'FETCH_CHARGE_RCT_SUCCESS';
export const DOWNLOAD_CHARGE_RCT_FAIL = 'FETCH_CHARGE_RCT_FAIL';

export const FETCH_INVOICE_WIDGETS = 'FETCH_INVOICE_WIDGETS';
export const FETCH_INVOICE_WIDGETS_SUCCESS = 'FETCH_INVOICE_WIDGETS_SUCCESS';
export const FETCH_INVOICE_WIDGETS_FAIL = 'FETCH_INVOICE_WIDGETS_FAIL';

export const DOWNLOAD_INVOICE = 'DOWNLOAD_INVOICE';
export const DOWNLOAD_INVOICE_SUCCESS = 'DOWNLOAD_INVOICE_SUCCESS';
export const DOWNLOAD_INVOICE_FAIL = 'DOWNLOAD_INVOICE_FAIL';

export const DOWNLOAD_SINGLE_INVOICE = 'DOWNLOAD_SINGLE_INVOICE';
export const DOWNLOAD_SINGLE_INVOICE_SUCCESS = 'DOWNLOAD_SINGLE_INVOICE_SUCCESS';
export const DOWNLOAD_SINGLE_INVOICE_FAIL = 'DOWNLOAD_SINGLE_INVOICE_FAIL';

export const DOWNLOAD_BULK_INVOICE = 'DOWNLOAD_BULK_INVOICE';
export const DOWNLOAD_BULK_INVOICE_SUCCESS = 'DOWNLOAD_BULK_INVOICE_SUCCESS';
export const DOWNLOAD_BULK_INVOICE_FAIL = 'DOWNLOAD_BULK_INVOICE_FAIL';
