import { FETCH_ACCOUNT_DELETE_REQUEST, FETCH_ACCOUNT_DELETE_REQUEST_SUCCESS, FETCH_ACCOUNT_DELETE_REQUEST_FAIL } from 'actions/accountDeleteRequest';

const initialState = {
  isLoading: false,
  accountDeleteRequest: [],
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const accountDeleteRequestReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_ACCOUNT_DELETE_REQUEST: {
      return { ...state, isLoading: true };
    }

    case FETCH_ACCOUNT_DELETE_REQUEST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        accountDeleteRequest: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case FETCH_ACCOUNT_DELETE_REQUEST_FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};
export default accountDeleteRequestReducer;
