import {
  FETCH_CHARGE_COIN,
  FETCH_CHARGE_COIN_SUCCESS,
  FETCH_CHARGE_COIN_FAIL,
  UPDATE_CHARGE_COIN,
  UPDATE_CHARGE_COIN_SUCCESS,
  UPDATE_CHARGE_COIN_FAIL,
  UPDATE_CHARGE_COIN_LEVEL,
  UPDATE_CHARGE_COIN_LEVEL_SUCCESS,
  UPDATE_CHARGE_COIN_LEVEL_FAIL,
  DELETE_CHARGE_COIN_LEVEL,
  DELETE_CHARGE_COIN_LEVEL_SUCCESS,
  DELETE_CHARGE_COIN_LEVEL_FAIL,
} from 'actions/chargeCoin';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  results: [],
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const chargeCoinReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_CHARGE_COIN:
    case UPDATE_CHARGE_COIN:
    case DELETE_CHARGE_COIN_LEVEL: 
    case UPDATE_CHARGE_COIN_LEVEL: {
      return { ...state, isLoading: true };
    }
    case FETCH_CHARGE_COIN_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        results: payload.results.filter((data) => data.key_name === 'chargecoin_settings'),
      };
    }
    case DELETE_CHARGE_COIN_LEVEL_SUCCESS: {
      toast.success('Level deleted successfully');
      return {
        ...state,
        isLoading: false,
        results: payload,
      };
    }
    case UPDATE_CHARGE_COIN_SUCCESS: {
      toast.success('ChargeCoin updated successfully');
      return {
        ...state,
        isLoading: false,
        results: payload,
      };
    }
    case UPDATE_CHARGE_COIN_LEVEL_SUCCESS: {
      toast.success('ChargeCoin level updated successfully');
      return {
        ...state,
        isLoading: false,
        results: payload,
      };
    }
    case FETCH_CHARGE_COIN_FAIL:
    case UPDATE_CHARGE_COIN_FAIL:
    case DELETE_CHARGE_COIN_LEVEL_FAIL: 
    case UPDATE_CHARGE_COIN_LEVEL_FAIL: {
      return { ...state, isLoading: false };
    }
    default:
      return { ...state };
  }
};

export default chargeCoinReducer;
