import {
  FETCH_MASTER_SETTING,
  FETCH_MASTER_SETTING_SUCCESS,
  FETCH_MASTER_SETTING_FAIL,
  GET_MASTER_SETTING,
  UPDATE_MATER_SETTING,
  GET_MASTER_SETTING_SUCCESS,
  GET_MASTER_SETTING_FAIL,
  UPDATE_MATER_SETTING_SUCCESS,
  UPDATE_MATER_SETTING_FAIL,
} from 'actions/masterSetting';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';

function* fetchMasterSetting(action) {
  try {
    const response = yield call(API.getAllMasterSetting, action.payload);
    yield put({ type: FETCH_MASTER_SETTING_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_MASTER_SETTING_FAIL, payload: { error: e } });
  }
}

function* getMasterSettingById(action) {
  try {
    const response = yield call(API.getMasterSettingById, action.payload);
    yield put({ type: GET_MASTER_SETTING_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_MASTER_SETTING_FAIL, payload: { error: e } });
  }
}

function* updateMasterSetting(action) {
  try {
    const response = yield call(API.updateMasterSetting, action.payload);
    yield put({ type: UPDATE_MATER_SETTING_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_MATER_SETTING_FAIL, payload: { error: e } });
  }
}

function* masterSetting() {
  yield takeLatest(FETCH_MASTER_SETTING, fetchMasterSetting);
  yield takeLatest(GET_MASTER_SETTING, getMasterSettingById);
  yield takeLatest(UPDATE_MATER_SETTING, updateMasterSetting);
}
export default masterSetting;
