import { FETCH_USER_ACTIVITY, FETCH_USER_ACTIVITY_SUCCESS, FETCH_USER_ACTIVITY_FAIL } from 'actions/activityLog';

const initialState = {
  isLoading: false,
  userActivities: [],
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const userActivityReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_USER_ACTIVITY: {
      return { ...state, isLoading: true };
    }
    case FETCH_USER_ACTIVITY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        userActivities: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case FETCH_USER_ACTIVITY_FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return { ...state };
  }
};
export default userActivityReducer;
