import React, { useCallback, useEffect } from 'react';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import * as _ from 'lodash';

import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_SCHEDULING_SETTING, UPDATE_SCHEDULING_SETTING } from 'actions/schedulingSetting';
import { Formik, Form } from 'formik';
// import { Spinner } from 'react-bootstrap';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
const Setting = () => {
  const dispatch = useDispatch();
  const schedulingSettingDetails = useSelector((state) =>
    state.schedulingSetting.schedulingSetting[0] ? state.schedulingSetting.schedulingSetting[0] : state.schedulingSetting.schedulingSetting
  );
  const isLoader = useSelector((state) => state.schedulingSetting.isLoading);
  const { t } = useTranslation();
  const getschedulingSetting = useCallback(() => {
    dispatch({ type: FETCH_SCHEDULING_SETTING, payload: null });
  }, []);

  const updateschedulingSetting = useCallback((data) => {
    dispatch({
      type: UPDATE_SCHEDULING_SETTING,
      payload: data,
    });
  });

  useEffect(() => {
    getschedulingSetting();
  }, []);

  const initialValues = schedulingSettingDetails
    ? { schedulingSetting: schedulingSettingDetails }
    : {
        schedulingSetting: {
          ...schedulingSettingDetails,
          key_value: { booking_buffer_time_minutes: '' },
        },
      };

  return (
    <div className="profile-details__main">
      <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
        <div className="profile-details-inner">
          {isLoader ? (
            <div className="setting-page__main">
              <div className="loader--block">
                <Skeleton height={15} width={100} />
              </div>
            </div>
          ) : (
            <div className="profile--content">
              <Row>
                <Formik
                  initialValues={initialValues}
                  onSubmit={(values) => {
                    updateschedulingSetting(values);
                  }}
                >
                  {({ values, handleSubmit, handleChange }) => (
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col md={12}>
                          <TextInput
                            type="number"
                            label={t('bookingSchedule.time')}
                            name="schedulingSetting.key_value.booking_buffer_time_minutes"
                            value={_.get(values, 'schedulingSetting.key_value.booking_buffer_time_minutes')}
                            onChange={handleChange}
                            //   error={errors.name && touched.name ? errors.name : null}
                          />
                        </Col>
                      </Row>
                      <div className="charge_coin_btn_sec">
                        <Button type="submit" className="form_btn">
                          {t('button.submit')}
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </Row>
            </div>
          )}
        </div>
      </SkeletonTheme>
    </div>
  );
};

export default Setting;
