export const FETCH_TENANT_SETTING = 'FETCH_TENANT_SETTING';
export const FETCH_TENANT_SETTING_SUCCESS = 'FETCH_TENANT_SETTING_SUCCESS';
export const FETCH_TENANT_SETTING_FAIL = 'FETCH_TENANT_SETTING_FAIL';

export const GET_TENANT_SETTING = 'GET_TENANT_SETTING';
export const GET_TENANT_SETTING_SUCCESS = 'GET_TENANT_SETTING_SUCCESS';
export const GET_TENANT_SETTING_FAIL = 'GET_TENANT_SETTING_FAIL';

export const UPDATE_TENANT_SETTING = 'UPDATE_TENANT_SETTING';
export const UPDATE_TENANT_SETTING_SUCCESS = 'UPDATE_TENANT_SETTING_SUCCESS';
export const UPDATE_TENANT_SETTING_FAIL = 'UPDATE_TENANT_SETTING_FAIL';