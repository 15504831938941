import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import {
  FETCH_CHARGING_STATION_ACCESS_TYPE,
  FETCH_CHARGING_STATION_ACCESS_TYPE_SUCCESS,
  FETCH_CHARGING_STATION_ACCESS_TYPE_FAIL,
  GET_CHARGING_STATION_ACCESS_TYPE,
  GET_CHARGING_STATION_ACCESS_TYPE_SUCCESS,
  GET_CHARGING_STATION_ACCESS_TYPE_FAIL,
  CREATE_CHARGING_STATION_ACCESS_TYPE,
  CREATE_CHARGING_STATION_ACCESS_TYPE_SUCCESS,
  CREATE_CHARGING_STATION_ACCESS_TYPE_FAIL,
  UPDATE_CHARGING_STATION_ACCESS_TYPE,
  UPDATE_CHARGING_STATION_ACCESS_TYPE_SUCCESS,
  UPDATE_CHARGING_STATION_ACCESS_TYPE_FAIL,
  DELETE_CHARGING_STATION_ACCESS_TYPE,
  DELETE_CHARGING_STATION_ACCESS_TYPE_SUCCESS,
  DELETE_CHARGING_STATION_ACCESS_TYPE_FAIL,
} from 'actions/stationAccessType';

function* fetchStationAccessType(action) {
  try {
    const response = yield call(API.getStationAccessType, action.payload);
    yield put({ type: FETCH_CHARGING_STATION_ACCESS_TYPE_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_CHARGING_STATION_ACCESS_TYPE_FAIL, payload: { error: e } });
  }
}

function* getStationAccessTypeById(action) {
  try {
    const response = yield call(API.getStationAccessTypeById, action.payload);
    yield put({ type: GET_CHARGING_STATION_ACCESS_TYPE_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_CHARGING_STATION_ACCESS_TYPE_FAIL, payload: { error: e } });
  }
}

function* createStationAccessType(action) {
  try {
    const response = yield call(API.addStationAccessType, action.payload);
    yield put({ type: CREATE_CHARGING_STATION_ACCESS_TYPE_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_CHARGING_STATION_ACCESS_TYPE_FAIL, payload: { error: e } });
  }
}

function* updateStationAccessType(action) {
  try {
    const response = yield call(API.updateStationAccessType, action.payload);
    yield put({ type: UPDATE_CHARGING_STATION_ACCESS_TYPE_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_CHARGING_STATION_ACCESS_TYPE_FAIL, payload: { error: e } });
  }
}

function* deleteStationAccessType(action) {
  try {
    const response = yield call(API.deleteStationAccessType, action.payload);
    yield put({ type: DELETE_CHARGING_STATION_ACCESS_TYPE_SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_CHARGING_STATION_ACCESS_TYPE_FAIL, payload: { error: e } });
  }
}

function* stationAccessType() {
  yield takeLatest(FETCH_CHARGING_STATION_ACCESS_TYPE, fetchStationAccessType);
  yield takeLatest(GET_CHARGING_STATION_ACCESS_TYPE, getStationAccessTypeById);
  yield takeLatest(CREATE_CHARGING_STATION_ACCESS_TYPE, createStationAccessType);
  yield takeLatest(UPDATE_CHARGING_STATION_ACCESS_TYPE, updateStationAccessType);
  yield takeLatest(DELETE_CHARGING_STATION_ACCESS_TYPE, deleteStationAccessType);
}

export default stationAccessType;
