import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Card from 'components/inputs/Card';
import { MdClose } from 'react-icons/md';
import { Row, Col } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import _ from 'lodash';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { CREATE_SMS, UPDATE_SMS } from 'actions/sms';
import { CREATE_STATUS_CODE, UPDATE_STATUS_CODE } from 'components/common/constant';
import { useTranslation } from 'react-i18next';

const SmsForm = (props) => {
  const { isEdit, onClose = _.noop(), onSuccess = _.noop() } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const smsDetail = useSelector((state) => state.sms.smsDetail);
  const isLoading = useSelector((state) => state.sms.isLoading);

  const addSms = useCallback((data) => {
    dispatch({
      type: CREATE_SMS,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === CREATE_STATUS_CODE) {
          onSuccess();
          onClose();
        }
      },
    });
  }, []);

  const updateSms = useCallback((data) => {
    dispatch({
      type: UPDATE_SMS,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          onClose();
        }
      },
    });
  }, []);

  const SmsSchema = Yup.object().shape({
    name: Yup.string().required(t('smsForm.name')).strict(true).trim(t('smsForm.space')).max(100, t('smsForm.maxlimit')),
    slug: Yup.string().required(t('smsForm.slug')).strict(true).trim(t('smsForm.space')).max(100, t('smsForm.maxlimit')),
    message: Yup.string().required(t('smsForm.message')).strict(true).trim(t('smsForm.space')).max(100, t('smsForm.maxlimit')),
    url: Yup.string().required(t('smsForm.url')),
  });

  const initialValues = isEdit
    ? { ...smsDetail }
    : {
        url: '',
        name: '',
        slug: '',
        message: '',
      };
  return (
    <React.Fragment>
      <div className="sms-form-page__main">
        <Card>
          <div className="sms-form-header__block">
            <div className="sms-header-name">
              <span>
                {isEdit ? 'Edit' : 'Add'} {t('smsForm.title')}
              </span>
            </div>
            <div className="close___btn">
              <MdClose size={30} color="#BE210B" onClick={onClose} />
            </div>
          </div>
          <div className="sms-form-body__block">
            <div className="sms-form--block">
              <Formik
                enableReinitialize={!!isEdit}
                initialValues={initialValues}
                validationSchema={SmsSchema}
                onSubmit={(values, { setSubmitting }) => {
                  if (isEdit) {
                    updateSms(values);
                  } else {
                    addSms(values);
                  }
                  setSubmitting(false);
                }}
              >
                {({ values, errors, touched, handleSubmit, handleChange, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col md={12}>
                        <TextInput
                          isRequired
                          label={t('smsForm.nameLabel')}
                          placeholder={t('smsForm.nameLabel')}
                          name="name"
                          value={_.get(values, 'name', '')}
                          onChange={handleChange}
                          error={errors.name && touched.name ? errors.name : null}
                        />
                      </Col>
                      <Col md={12}>
                        <TextInput
                          isRequired
                          label={t('smsForm.slugLabel')}
                          placeholder={t('smsForm.slugLabel')}
                          name="slug"
                          value={_.get(values, 'slug', '')}
                          onChange={handleChange}
                          error={errors.slug && touched.slug ? errors.slug : null}
                        />
                      </Col>
                      <Col md={12}>
                        <TextInput
                          isRequired
                          label={t('smsForm.urlLabel')}
                          placeholder={t('smsForm.urlLabel')}
                          name="url"
                          value={_.get(values, 'url', '')}
                          onChange={handleChange}
                          error={errors.url && touched.url ? errors.url : null}
                        />
                      </Col>
                      <Col md={12}>
                        <TextInput
                          isRequired
                          as="textarea"
                          label={t('smsForm.messageLabel')}
                          placeholder={t('smsForm.messageLabel')}
                          name="message"
                          value={_.get(values, 'message', '')}
                          onChange={handleChange}
                          error={errors.message && touched.message ? errors.message : null}
                        />
                      </Col>
                    </Row>
                    <div className="form-btn__block">
                      <Button className="form_btn cancel____btn" onClick={onClose}>
                        {t('button.cancel')}
                      </Button>
                      <Button type="submit" disabled={isSubmitting || isLoading} className="form_btn">
                        {isEdit ? 'Edit' : 'Add'}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};
SmsForm.propTypes = {
  onSuccess: PropTypes.func,
  onClose: PropTypes.func,
  isEdit: PropTypes.bool,
};
export default SmsForm;
