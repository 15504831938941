import React, { useEffect, useCallback, useState, useContext } from 'react';
import * as _ from 'lodash';
import { FETCH_CHARGER_BOOKING, STOP_TRANSACTION } from 'actions/chargerBooking';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import Avatar from 'components/inputs/Avatar';
import Button from 'components/inputs/Button';
import { START_REMOTE } from 'actions/chargerBooking';
import { BiSort } from 'react-icons/bi';
import { Col, Modal, Row } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import TextInput from 'components/inputs/Input';
import * as Yup from 'yup';
import { MdClose } from 'react-icons/md';
import Card from 'components/inputs/Card';
import { toast } from 'react-toastify';
import ReactPagination from 'components/general/ReactPagination';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { NavContext } from 'components/privateRoute/PrivateRoute';

const Reservations = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isAllowed } = useContext(NavContext);
  const { stationId } = useParams();
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [showStopTransaction, setShowStopTransaction] = useState(false);
  const [reservationList, setReservationList] = useState({});

  const chargerBookingList = useSelector((state) => state.chargerBooking.chargerBookings);
  const page = useSelector((state) => state.chargerBooking.page);
  const totalData = useSelector((state) => state.chargerBooking.total);
  const limit = useSelector((state) => state.chargerBooking.limit);
  const totalPages = useSelector((state) => state.chargerBooking.totalPages);
  const isLoader = useSelector((state) => state.chargerBooking.isLoading);

  const getChargerBooking = useCallback((data = {}) => {
    const stationData = {
      ...data,
      from: moment.utc(moment(new Date()).startOf('day')).format('YYYY-MM-DD HH:mm:ss'),
      to: moment.utc(moment(new Date()).endOf('day')).format('YYYY-MM-DD HH:mm:ss'),
      charging_station: stationId,
    };
    dispatch({ type: FETCH_CHARGER_BOOKING, payload: stationData });
  }, []);

  const addStartRemote = useCallback((bookingData) => {
    const remote = {
      id: _.get(bookingData, 'charger.id', ''),
      idTag: _.get(bookingData, 'idTag', '').toString(),
      connectorId: _.get(bookingData, 'connectorId', ''),
    };
    dispatch({ type: START_REMOTE, payload: { data: remote } });
  }, []);

  const stopTransaction = useCallback(
    (data) => {
      dispatch({
        type: STOP_TRANSACTION,
        payload: data,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              toast.success('Stop Transaction successfully');
              setShowStopTransaction(false);
              getChargerBooking({ page });
            }
          }
        },
      });
    },
    [page]
  );

  useEffect(() => {
    getChargerBooking();
  }, []);

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else {
        const data = {
          page: page.selected + 1,
        };
        getChargerBooking(data);
      }
    },
    [sortByItem]
  );

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getChargerBooking(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getChargerBooking(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handleClose = () => {
    setShowStopTransaction(false);
  };

  const StopTransactionSchema = Yup.object().shape({
    idTag: Yup.string().required(t('reservations.idTag')),
    timestamps: Yup.string().required(t('reservations.timestamps')),
    datestamps: Yup.string().required(t('reservations.datestamps')),
    transactionId: Yup.number().required(t('reservations.transactionId')),
    meterStop: Yup.number().required(t('reservations.meterStop')),
  });

  return (
    <div className="reservations-list--table">
      <div className="table-responsive">
        {isLoader ? (
          <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
            <table className="record-list-table">
              <thead>
                <tr>
                  <th>
                    <div className="sorting">
                      <span>
                        <Skeleton height={15} width={100} />
                      </span>
                    </div>
                  </th>
                  <th>
                    <div className="sorting">
                      <span>
                        <Skeleton height={15} width={100} />
                      </span>
                    </div>
                  </th>
                  <th>
                    <div className="sorting">
                      <span>
                        <Skeleton height={15} width={100} />
                      </span>
                    </div>
                  </th>
                  <th>
                    <div className="sorting">
                      <span>
                        <Skeleton height={15} width={100} />
                      </span>
                    </div>
                  </th>
                  <th>
                    <div className="sorting">
                      <span>
                        <Skeleton height={15} width={100} />
                      </span>
                    </div>
                  </th>
                  <th>
                    <div className="sorting">
                      <span>
                        <Skeleton height={15} width={100} />
                      </span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Skeleton height={15} width={100} />
                  </td>
                  <td>
                    <Skeleton height={15} width={100} />
                  </td>
                  <td>
                    <div className="reservations-name-list" style={{ display: 'flex' }}>
                      <Skeleton circle="true" height={24} width={24} />
                      <Skeleton height={15} width={100} />
                    </div>
                  </td>
                  <td>
                    <Skeleton height={15} width={100} />
                  </td>
                  <td>
                    <Skeleton height={15} width={100} />
                  </td>
                  <td>
                    <Skeleton height={15} width={100} />
                  </td>
                </tr>
              </tbody>
            </table>
          </SkeletonTheme>
        ) : (
          <table className="record-list-table">
            <thead>
              <tr>
                <th>
                  <div className="sorting">
                    <span>{t('reservations.date')}</span>
                  </div>
                </th>
                <th>
                  <div className="sorting">
                    <span>{t('reservations.status')}</span>
                    <span className="ico" onClick={() => handleSorting('status', sortByItem)}>
                      <BiSort size={15} />
                    </span>
                  </div>
                </th>
                <th>
                  <div className="sorting">
                    <span>{t('reservations.customerName')}</span>
                  </div>
                </th>

                <th>
                  <div className="sorting">
                    <span>{t('reservations.mobileNo')}</span>
                  </div>
                </th>

                <th>
                  <div className="sorting">
                    <span>{t('reservations.chargerID')}</span>
                  </div>
                </th>
                <th>
                  <div className="sorting">
                    <span>{t('reservations.vehicle')}</span>
                  </div>
                </th>
                {isAllowed('modifyStationListReservation') && (
                  <th>
                    <div className="sorting">
                      <span>{t('reservations.action')}</span>
                    </div>
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {_.isEmpty(chargerBookingList) ? (
                <tr>
                  <td colSpan={6} className="reservation--border">
                    <div className="empty-data-block">{t('reservations.noData')}</div>
                  </td>
                </tr>
              ) : (
                <>
                  {_.map(chargerBookingList, (item, index) => {
                    const connectorData = _.find(item && item.charger ? _.get(item, 'charger.plugs', '') : '', {
                      connector_id: item && item.connectorId ? item.connectorId : '',
                    });
                    return (
                      <tr key={`charging-booking-${index}`}>
                        <td>{moment(item.schedule_datetime).format('lll')}</td>
                        <td>{_.get(item, 'status')}</td>
                        <td>
                          <div className="reservations-name-list">
                            {_.get(item, 'customer_user_booked') && (
                              <>
                                <Avatar imgSrc={_.get(item, 'customer_user_booked.picture')} className="list-avatar" name={''} />
                                <span>{_.get(item, 'customer_user_booked.name', '')}</span>
                              </>
                            )}
                          </div>
                        </td>

                        <td>
                          <div className="reservations-name-list">
                            {_.get(item, 'customer_user_booked') && (
                              <>
                                <span>{_.get(item, 'customer_user_booked.phone', '')}</span>
                              </>
                            )}
                          </div>
                        </td>

                        <td>{_.get(item, 'charger.charger_id', '') + ' ' + (connectorData ? `(${_.get(connectorData, 'name')})` : '')}</td>
                        <td>
                          <div className="reservations-vehicle-list">
                            <span>{`${_.get(item, 'vehicle.make', '')} ${_.get(item, 'vehicle.model', '')}`}</span>
                          </div>
                        </td>
                        {isAllowed('modifyStationListReservation') && (
                          <td>
                            {_.get(item, 'status') === 'scheduled' && (
                              <Button onClick={() => addStartRemote(item)}> {t('reservations.startRemote')}</Button>
                            )}
                            {_.get(item, 'status') === 'in_progress' && (
                              <Button
                                onClick={() => {
                                  setShowStopTransaction(true);
                                  setReservationList(item);
                                }}
                              >
                                {t('reservations.stoptransaction')}
                              </Button>
                            )}
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </>
              )}
            </tbody>
          </table>
        )}
      </div>
      {!_.isEmpty(chargerBookingList) && (
        <ReactPagination
          currentPage={page}
          limit={limit}
          total={totalData}
          handlePageClick={(pageVal) => handlePageClick(pageVal)}
          totalPages={totalPages}
          marginPagesDisplayed={1}
        />
      )}
      {showStopTransaction && (
        <Modal show={showStopTransaction} size="lg" centered>
          <div className="stop-transaction_main">
            <Card>
              <div className="stop-transaction-header__block">
                <div className="stop-transaction-name">
                  <span>{t('reservations.stoptransaction')}</span>
                </div>
                <div className="close-btn">
                  <MdClose size={30} color="#be210b" onClick={handleClose} />
                </div>
              </div>
              <div className="stop-transaction_form">
                <Formik
                  initialValues={{
                    transactionId: _.get(reservationList, 'transaction_id', ''),
                    idTag: _.get(reservationList, 'idTag', ''),
                    timestamps: '',
                    datestamps: '',
                    meterStop: '',
                  }}
                  validationSchema={StopTransactionSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    const startTimeMomentObj = moment(values.timestamps + values.datestamps, 'YYYY-MM-DDLT');
                    const timestamp = startTimeMomentObj.utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
                    const adjustedTimestamp = moment(timestamp).subtract(5, 'hours').subtract(30, 'minutes').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
                    const formData = {
                      ...values,
                      id: _.get(reservationList, 'charger.id', ''),
                      timestamp: adjustedTimestamp,
                      transactionId: parseInt(values.transactionId),
                    };
                    const stopTransactionFormData = _.omit(formData, ['datestamps', 'timestamps']);
                    stopTransaction(stopTransactionFormData);
                    setSubmitting(false);
                  }}
                >
                  {({ values, errors, touched, handleSubmit, handleChange, isSubmitting }) => (
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col lg={6}>
                          <TextInput
                            isRequired
                            disabled
                            label={t('placeHolder.transactionId')}
                            type="number"
                            placeholder={t('placeHolder.transactionId')}
                            name="transactionId"
                            value={_.get(values, 'transactionId')}
                            onChange={handleChange}
                            error={errors.transactionId && touched.transactionId ? errors.transactionId : null}
                          />
                        </Col>
                        <Col lg={6}>
                          <TextInput
                            isRequired
                            disabled
                            label={t('placeHolder.idTag')}
                            placeholder={t('placeHolder.idTag')}
                            name="idTag"
                            value={_.get(values, 'idTag')}
                            onChange={handleChange}
                            error={errors.idTag && touched.idTag ? errors.idTag : null}
                          />
                        </Col>
                        <Col lg={6}>
                          <TextInput
                            isRequired
                            label={t('placeHolder.selectDate')}
                            type="date"
                            placeholder={t('placeHolder.timestamp')}
                            name="timestamps"
                            value={values.timestamps}
                            onChange={handleChange}
                            error={errors.timestamps && touched.timestamps ? errors.timestamps : null}
                          />
                        </Col>
                        <Col lg={6}>
                          <TextInput
                            isRequired
                            label={t('placeHolder.selectTime')}
                            type="time"
                            placeholder={t('placeHolder.timestamp')}
                            name="datestamps"
                            value={values.datestamps}
                            onChange={handleChange}
                            error={errors.datestamps && touched.datestamps ? errors.datestamps : null}
                          />
                        </Col>
                        <Col lg={6}>
                          <TextInput
                            isRequired
                            label={t('placeHolder.meterStop')}
                            type="number"
                            placeholder={t('placeHolder.meterStop')}
                            name="meterStop"
                            value={values.meterStop}
                            onChange={handleChange}
                            error={errors.meterStop && touched.meterStop ? errors.meterStop : null}
                          />
                        </Col>
                      </Row>
                      <div className="stop-transaction_button">
                        <Button className="stop-transaction-btn cancel_btn" onClick={handleClose}>
                          {t('button.cancel')}
                        </Button>
                        <Button type="submit" disabled={isSubmitting} className="stop-transaction-btn create-btn">
                          {t('button.submit')}
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Card>
          </div>
        </Modal>
      )}
    </div>
  );
};
export default Reservations;
