export const FETCH_RFID_GROUP = 'FETCH_RFID_GROUP';
export const FETCH_RFID_GROUP_SUCCESS = 'FETCH_RFID_GROUP_SUCCESS';
export const FETCH_RFID_GROUP_FAIL = 'FETCH_RFID_GROUP_FAIL';

export const GET_RFID_GROUP = 'GET_RFID_GROUP';
export const GET_RFID_GROUP_SUCCESS = 'GET_RFID_GROUP_SUCCESS';
export const GET_RFID_GROUP_FAIL = 'GET_RFID_GROUP_FAIL';

export const CREATE_RFID_GROUP = 'CREATE_RFID_GROUP';
export const CREATE_RFID_GROUP_SUCCESS = 'CREATE_RFID_GROUP_SUCCESS';
export const CREATE_RFID_GROUP_FAIL = 'CREATE_RFID_GROUP_FAIL';

export const UPDATE_RFID_GROUP = 'UPDATE_RFID_GROUP';
export const UPDATE_RFID_GROUP_SUCCESS = ' UPDATE_RFID_GROUP_SUCCESS';
export const UPDATE_RFID_GROUP_FAIL = 'UPDATE_RFID_GROUP_FAIL';

export const DELETE_RFID_GROUP = 'DELETE_RFID_GROUP';
export const DELETE_RFID_GROUP_SUCCESS = 'DELETE_RFID_GROUP_SUCCESS';
export const DELETE_RFID_GROUP_FAIL = ' DELETE_RFID_GROUP_FAIL';
