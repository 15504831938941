import React, { useCallback, useEffect, useContext } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header/index';
import Card from 'components/inputs/Card';
import DummyImage from 'assets/images/icons/dummyImage.png';
import Avatar from 'components/inputs/Avatar';
import SessionHeading from 'components/general/SessionHeading';
import Button from 'components/inputs/Button';
import { Row, Col } from 'react-bootstrap';
import { AiOutlineMail, AiOutlineFilePdf, AiOutlineDownload } from 'react-icons/ai';
import { BsTelephone, BsBag, BsDot } from 'react-icons/bs';
// import { MdKeyboardArrowRight } from 'react-icons/md';
import { BiUser, BiGasPump, BiPlug } from 'react-icons/bi';
import DatePicker from 'components/inputs/DatePicker';
import { useParams } from 'react-router-dom';
import InformationBlock from 'components/general/InformationBlock';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { GET_TENANT } from 'actions/tenant';
import * as _ from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { NavContext } from 'components/privateRoute/PrivateRoute';

const TenantAdminDetails = () => {
  const { tenantId } = useParams();
  // const history = useHistory();
  const { navigateTo } = useContext(NavContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const tenantData = useSelector((state) => state.tenant.tenantDetail);
  const tenantLicensesData = _.filter(tenantData.licenses, (item) => item);

  const getTenantData = useCallback((id) => {
    dispatch({ type: GET_TENANT, payload: id });
  }, []);

  useEffect(() => {
    getTenantData(tenantId);
  }, [tenantId]);

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.tenantsAdminDetail')} backTo="/tenantAdmin" />
        <div className="page-content-wrapper scrollable">
          <div className="tenantAdminDetails-page">
            <div className="tenantAdmin-profile">
              <Card>
                <div className="tenantAdmin-profile-content">
                  <Avatar imgSrc={DummyImage} name={'DummyImage'} />
                  <div className="tenantAdmin-profile-content-wrapper">
                    <div className="tenantAdmin-profileInfo">
                      <div className="tenantAdmin-profileInfo-wrapper">
                        <div className="tenantAdmin-profile-name">{_.get(tenantData, 'contact_person.name', '')}</div>
                        <div className="tenantAdmin-name">{_.get(tenantData, 'contact_person.role', '')}</div>
                      </div>
                    </div>
                    <div className="tenantAdmin-profileContact">
                      <div className="tenantAdmin-profileContact-wrapper">
                        <div className="me-4">
                          <AiOutlineMail />
                          <span className="tenantAdmin-emailName">{_.get(tenantData, 'contact_person.email', '')}</span>
                        </div>
                        <div className="ms-4">
                          <BsTelephone />
                          <span className="tenantAdmin-contactNumber">{_.get(tenantData, 'contact_person.phone', '')}</span>
                        </div>
                      </div>
                      <div className="tenantAdmin-profile-btn">
                        {/* <Button>Edit Profile</Button> */}
                        <Button onClick={() => navigateTo(`/editTenant/${_.get(tenantData, 'id', '')}`)}>{t('editTenantForm.editTenant')}</Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
            <div className="tenantAdmin-CompanyInfo">
              <Card>
                <SessionHeading text="Company Information" />
                <Row className="tenantAdmin-CompanyInfo-content">
                  <Col xl={3} md={6} className="tenantAdmin-CompanyInfo-content-wrapper">
                    <InformationBlock icon={<BsBag />} infoTitle={_.get(tenantData, 'name', '')} infoText={t('editTenantForm.companyName')} />
                  </Col>
                  <Col xl={3} md={6} className="tenantAdmin-CompanyInfo-content-wrapper">
                    <InformationBlock icon={<BiUser />} infoTitle={_.get(tenantData, 'user_count', 0)} infoText={t('editTenantForm.users')} />
                  </Col>
                  <Col xl={3} md={6} className="tenantAdmin-CompanyInfo-content-wrapper">
                    <InformationBlock
                      icon={<BiGasPump />}
                      infoTitle={_.get(tenantData, 'charging_stations', 0)}
                      infoText={t('editTenantForm.chargingStations')}
                    />
                  </Col>
                  <Col xl={3} md={6} className="tenantAdmin-CompanyInfo-content-wrapper">
                    <InformationBlock icon={<BiPlug />} infoTitle={_.get(tenantData, 'chargers', 0)} infoText={t('editTenantForm.chargers')} />
                  </Col>
                </Row>
              </Card>
            </div>
            <div className="tenantAdmin-Subscription-details">
              <Card>
                <div className="tenantAdmin-Subscription-details-wrapper">
                  <SessionHeading text="Subscription" />
                  {_.isEmpty(tenantLicensesData) ? (
                    <div className="empty-data-block">{t('editTenantForm.noData')} </div>
                  ) : (
                    <Row className="tenantAdmin-Subscription-content">
                      <Col xl={6} lg={12} md={12} className="tenantAdmin-Subscription-wrapper">
                        {/* <div className="tenantAdmin-proSubscription">PRO</div> */}
                        <div className="tenantAdmin-proSubscription">{_.get(_.head(tenantLicensesData), 'name', '')}</div>
                        <div className="tenantAdmin-Subscription-Plan">
                          <div className="tenantAdmin-Subscription-planText">
                            ${_.get(_.head(tenantLicensesData), 'amount', '')}
                            {/* <span className="tenantAdmin-Subscription-renewText">/month</span> */}
                          </div>
                          <div className="tenantAdmin-Subscription-renewText">
                            <span className="mx-2">
                              <BsDot />
                            </span>
                            {t('editTenantForm.renewOn')}
                            {_.get(_.head(tenantLicensesData), 'expiry_date', '')
                              ? moment(_.get(_.head(tenantLicensesData), 'expiry_date', '')).format('DD MMM, YYYY')
                              : ''}
                          </div>
                        </div>
                      </Col>
                      <Col xl={6} lg={12} md={12} className="tenantAdmin-Subscription-offerContent">
                        <a href="#" className="tenantAdmin-Subscription-offerContent-link">
                          {t('editTenantForm.changeOffer')}
                        </a>
                        <div className="tenantAdmin-Subscription-offerContent-btn">
                          <Button className="tenantAdmin-Subscription-offerContent-cancelSubscriptionBtn">
                            {t('editTenantForm.cancelSubscription')}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  )}
                </div>
                <div className="tenantAdmin-Invoices-wrapper">
                  <SessionHeading text="Invoices" />
                  <div>
                    <div className="empty-data-block"> {t('editTenantForm.noInvoiceData')}</div>
                    {/* <InvoiceDetails title={'January_subscription.pdf'} />
                    <InvoiceDetails title={'December_subscription.pdf'} /> */}
                  </div>
                  {/* <div className="mt-4">
                    <a href="#" className="tenantAdmin-Invoices-link">
                      See older Invoices
                    </a>
                  </div> */}
                </div>
              </Card>
            </div>
            <div className="tenantAdmin-CSMS">
              <Card>
                <div>
                  <SessionHeading text={t('editTenantForm.timeSpent')}>
                    <DatePicker defaultValueOfStartDate={new Date()} defaultValueOfEndDate={new Date()} />
                  </SessionHeading>
                </div>
                <div>
                  <div className="empty-data-block">{t('editTenantForm.noData')}</div>
                  {/* <span className="tenantAdmin-CSMS-text">159 hrs</span>
                  <span className="tenantAdmin-CSMS-timing">spent from 12 Dec.2021 to 20jan</span> */}
                </div>
              </Card>
            </div>
          </div>
        </div>
      </LayoutContainer>
    </>
  );
};

export default TenantAdminDetails;

export const InvoiceDetails = (props) => {
  const { title } = props;

  return (
    <div className="invoice__item-main">
      <div className="invoice-detail">
        <div className="invoice-detail--info">
          <div className="invoice-pdf-icon">
            <AiOutlineFilePdf />
          </div>
          <span className="invoice-file-name">{title}</span>
        </div>
        <div className="download-icon">
          <AiOutlineDownload />
        </div>
      </div>
    </div>
  );
};
InvoiceDetails.propTypes = {
  title: PropTypes.string,
};
