import React, { useCallback, useEffect } from 'react';
import Card from 'components/inputs/Card';
import Avatar from 'components/inputs/Avatar';
import DummyImage from 'assets/images/icons/dummyImage.png';
import ReactStars from 'react-rating-stars-component';
import { BsStar, BsStarFill, BsStarHalf } from 'react-icons/bs';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_REVIEWS } from 'actions/chargingStation';
import _ from 'lodash';
import ReactPagination from 'components/general/ReactPagination';
import { useParams } from 'react-router';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
const Reviews = () => {
  const dispatch = useDispatch();
  const { stationId } = useParams();
  const { t } = useTranslation();
  const allReview = useSelector((state) => state.review.results);
  const isLoader = useSelector((state) => state.review.isLoading);

  const page = useSelector((state) => state.review.page);
  const limit = useSelector((state) => state.review.limit);
  const totalData = useSelector((state) => state.review.total);
  const totalPages = useSelector((state) => state.review.totalPages);

  const getReview = useCallback((data = {}) => {
    dispatch({ type: FETCH_REVIEWS, payload: data });
  }, []);

  useEffect(() => {
    getReview({ id: stationId });
  }, [stationId]);

  const handlePageClick = useCallback(
    (page) => {
      const data = {
        id: stationId,
        page: page.selected + 1,
        limit: limit || 20,
      };
      getReview(data);
    },
    [stationId]
  );

  return (
    <div className="review-list__main">
      {isLoader ? (
        <div className="loader--block">
          <Spinner as="span" animation="border" size="sm" role="status" />
          {t('placeHolder.loading')}
        </div>
      ) : _.isEmpty(allReview) ? (
        <div className="empty-data-block">{t('ratingReview.noData')}</div>
      ) : (
        <>
          {_.map(allReview, (review, index) => (
            <ReviewDetails
              reviewDetailsImg={<Avatar imgSrc={DummyImage} name={'DummyImage'} className="review-avatar" />}
              key={`reviews-${index}`}
              name={_.get(review, 'customer.name', 'Guest user')}
              description={_.get(review, 'feedback', '')}
              rating={_.get(review, 'ratings')}
            />
          ))}
          {/* <Pagination className="mb-0" currentPage={page} limit={limit} total={totalData} onPageChange={(pageVal) => handlePageClick(pageVal)} /> */}
          <ReactPagination
            currentPage={page}
            limit={limit}
            total={totalData}
            handlePageClick={(pageVal) => handlePageClick(pageVal)}
            totalPages={totalPages}
            marginPagesDisplayed={1}
            showDataCount={true} // Set this prop to false to hide the data count
          />
        </>
      )}
    </div>
  );
};

const ReviewDetails = (props) => {
  const { name, description, reviewDetailsImg, rating } = props;

  return (
    <div className="review-list--block">
      <Card className="review-list-card">
        <div className="review-list-card-wrapper">
          <div>{reviewDetailsImg}</div>
          <div className="review-list-content">
            <div className="review-list-name">{name}</div>
            <div className="review--rating-block">
              <div className="review-rating-star__block">
                <ReactStars
                  edit={false}
                  a11y={true}
                  count={5}
                  value={rating}
                  size={12}
                  isHalf={true}
                  emptyIcon={<BsStar />}
                  halfIcon={<BsStarHalf />}
                  filledIcon={<BsStarFill />}
                  activeColor="#BE210B"
                  color="#BE210B"
                />
              </div>
              <span className="review-rating__count">{rating}/5</span>
            </div>
            <div className="review-list-text">{description}</div>
          </div>
        </div>
      </Card>
    </div>
  );
};

ReviewDetails.propTypes = {
  reviewDetailsImg: PropTypes.object,
  description: PropTypes.string,
  name: PropTypes.string,
  rating: PropTypes.number,
};
export default Reviews;
