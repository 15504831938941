import React, { useCallback, useEffect, useState, useContext } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header/index';
import Card from 'components/inputs/Card';
import { Row, Col } from 'react-bootstrap';
import TextInput from 'components/inputs/Input';
import Select from 'components/inputs/Select';
import Button from 'components/inputs/Button';
import PhoneNumberInput from 'components/inputs/PhoneNumberInput';
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import * as _ from 'lodash';
import { Formik, Form } from 'formik';
import ProfilePicInput from 'components/inputs/ProfilePicInput';
import OtpConfirmationModal from './OtpConfirmationModal';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { SEND_OTP } from 'actions/otp';
import * as Yup from 'yup';
import { CREATE_USER } from 'actions/user';
import { FETCH_ROLE } from 'actions/role';
// import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CREATE_STATUS_CODE, GET_ROLES, MANAGE_ROLES, MD_DEALER } from 'components/common/constant';
import queryString from 'query-string';
import axios from 'axios';
import { cookie } from 'utils/cookies/cookies';
import RadioButton from 'components/inputs/RadioButton';
import DatePickerInput from 'components/inputs/DatePickerInput';
import { useTranslation } from 'react-i18next';
import PasswordStrengthBar from 'components/inputs/PasswordStrengthBar';
import { NavContext } from 'components/privateRoute/PrivateRoute';

const UserForm = () => {
  const dispatch = useDispatch();
  // const history = useHistory();
  const { navigateTo } = useContext(NavContext);
  const { t } = useTranslation();

  const token = cookie.get('token');
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [phoneError, setPhoneError] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [phoneData, setPhoneData] = useState({});
  const [isOtpVerify, setIsOtpVerify] = useState(false);
  // const [browseFileName, setBrowseFileName] = useState('');
  const [isImgLoading, setIsImgLoading] = useState(false);

  const profileData = useSelector((state) => state.profile.userProfile);
  const allRoles = useSelector((state) => state.role.roles);
  const rolePage = useSelector((state) => state.role.page);
  const roleTotalPage = useSelector((state) => state.role.totalPages);
  const rolePermissionData = useSelector((state) => state.manageRole.manageRoles);
  const getCountryCode = _.get(parsePhoneNumber(countryCode), 'countryCallingCode', '');
  const getPhoneNumber = _.get(parsePhoneNumber(countryCode), 'nationalNumber', '');
  //const [password, setPassword] = useState('');

  const sendOtp = useCallback((data) => {
    dispatch({
      type: SEND_OTP,
      payload: data,
      cb: (res) => {
        if (res && res.status === 201) {
          toast.success('OTP sent to your phone number.');
        }
      },
    });
  }, []);

  const getAllRoles = useCallback((data = { is_hidden: false, limit: 50 }) => {
    dispatch({ type: FETCH_ROLE, payload: data });
  }, []);

  const addUser = useCallback((data) => {
    dispatch({
      type: CREATE_USER,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === CREATE_STATUS_CODE) {
          navigateTo('/accessManagement');
        }
      },
    });
  }, []);

  useEffect(() => {
    getAllRoles();
  }, []);

  const handleImage = (image, savePath, setFieldValue) => {
    const fileName = _.get(image, 'name', '');
    setIsImgLoading(true);

    axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: process.env.REACT_APP_API_URL + `/util/fileupload?name=${fileName}`,
    })
      .then((res) => {
        if (res.status === 201) {
          const signedRequest = _.get(res, 'data.signedRequest', '');
          handleUploadFile(signedRequest, image, savePath, setFieldValue);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((e) => console.error('Error -> ', e));
  };

  const handleUploadFile = (url, imageData, savePath, setFieldValue) => {
    const fileType = _.get(imageData, 'type', '');

    axios({
      method: 'put',
      headers: {
        'Content-Type': fileType,
        'Content-Encoding': 'base64',
      },
      url: url,
      data: imageData,
    })
      .then((res) => {
        if (res.status === 200) {
          setIsImgLoading(false);
          const imgUrlData = queryString.parseUrl(url);
          setFieldValue(savePath, imgUrlData.url);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((e) => console.error('Error -> ', e));
  };

  const getRolePermissions =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === GET_ROLES),
      (data) => data.name
    );

  const manageRolePermissions =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === MANAGE_ROLES),
      (data) => data.name
    );

  const UserFormSchema = Yup.object({
    phone: Yup.string().required(t('userForm.phone')),
    // address: Yup.string().required('address is required'),
    // gender: Yup.string().required('gender is required'),
    // dob: Yup.string().required('birthDate is required'),
    name: Yup.string()
      .required(t('userForm.name'))
      .strict(true)
      .trim(t('userForm.space'))
      .matches(/^[A-Za-z\s]+$/, t('userForm.matches'))
      .max(100, t('userForm.maxlimit')),
    password: Yup.string().required(t('userForm.password')).strict(true).trim(t('userForm.space')),
    email: Yup.string()
      .required(t('userForm.email'))
      .strict(true)
      .trim(t('userForm.space'))
      .email(t('userForm.emailChk'))
      .max(100, t('userForm.maxlimit')),
    role: Yup.string().required(t('userForm.role')),
  });

  return (
    <LayoutContainer>
      <AdminHeader title={t('header.addUser')} backTo="/accessManagement" />
      <div className="page-content-wrapper scrollable">
        <div className="profile-form-page__main">
          <Card>
            <div className="profile-form-page--inner">
              <Formik
                initialValues={{
                  name: '',
                  phone: '',
                  email: '',
                  role: 'admin',
                  // access: '',
                  country_code: '91',
                  password: '',
                  picture: '',
                  address: '',
                  gender: '',
                  dob: '',
                  username: '',
                }}
                validationSchema={UserFormSchema}
                onSubmit={(values, { setSubmitting }) => {
                  if (!isValidPhoneNumber(values.phone)) {
                    setPhoneError('Phone Number is invalid');
                  } else {
                    if (isOtpVerify) {
                      // send otp api calling...
                      const phoneNumberDetails = {
                        country_code: getCountryCode,
                        number: getPhoneNumber,
                      };
                      sendOtp(phoneNumberDetails);
                      setPhoneData(phoneNumberDetails);
                      setShowOtpModal(true);
                    } else {
                      //add user api
                      addUser({ ...values, country_code: getCountryCode, phone: getPhoneNumber });
                    }
                  }
                  setSubmitting(false);
                }}
              >
                {({ values, handleSubmit, handleChange, setFieldValue, isSubmitting, errors, touched }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col xxl={2} xl={3} lg={5} md={4}>
                        <div className="d-flex justify-content-center">
                          <ProfilePicInput
                            showAdd
                            name="picture"
                            path={values.picture}
                            showLoader={isImgLoading}
                            onChange={(e) => handleImage(e.currentTarget.files[0], 'picture', setFieldValue)}
                          />
                        </div>
                      </Col>
                      <Col>
                        <div className="profile-form__block">
                          <Row>
                            <Col xl={6} lg={12}>
                              <TextInput
                                isRequired
                                label={t('userForm.nameLabel')}
                                placeholder={t('userForm.userName')}
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                                error={errors.name && touched.name ? errors.name : null}
                              />
                            </Col>
                            <Col xl={6} lg={12}>
                              <TextInput
                                isRequired
                                label={t('userForm.emailId')}
                                placeholder={t('userForm.emailId')}
                                name="email"
                                type="email"
                                value={values.email}
                                onChange={handleChange}
                                error={errors.email && touched.email ? errors.email : null}
                              />
                            </Col>
                            <Col xl={6} lg={12}>
                              <TextInput
                                isRequired
                                label={t('userForm.userName')}
                                placeholder={t('userForm.userName')}
                                name="username"
                                value={values.username}
                                onChange={handleChange}
                                error={errors.username && touched.username ? errors.username : null}
                              />
                            </Col>
                            <Col xl={6} lg={12}>
                              <PhoneNumberInput
                                isRequired
                                label={t('userForm.phoneNo')}
                                name="phone"
                                value={values.phone}
                                onChange={(val) => {
                                  setFieldValue(`phone`, val);
                                  setCountryCode(val || '');
                                  if (!_.isEmpty(phoneError)) {
                                    setPhoneError('');
                                  }
                                }}
                                error={errors.phone && touched.phone ? errors.phone : phoneError ? phoneError : null}
                              />
                            </Col>
                            <Col xl={6} lg={12} className="password_div">
                              <TextInput
                                isRequired
                                label={t('userForm.passwordLabel')}
                                placeholder={t('userForm.passwordLabel')}
                                type="password"
                                name="password"
                                autoComplete="new-password"
                                value={values.password}
                                onChange={(e) => setFieldValue('password', e.target.value)}
                                error={errors.password && touched.password ? errors.password : null}
                              />
                              {values.password.length > 0 && <PasswordStrengthBar password={values.password} />}
                            </Col>
                            <Col xl={6} lg={12}>
                              {/* User role list are change when role is accepted from BE. */}
                              <Select
                                isRequired
                                label={t('userForm.roleLabel')}
                                name="role"
                                placeholder={t('userForm.role')}
                                value={_.get(profileData, 'role') !== 'md_admin' ? values.role : MD_DEALER}
                                options={_.map(allRoles, (item) => {
                                  return { label: _.startCase(item.name), value: item.name };
                                })}
                                onMenuScrollDown={true}
                                getDataOnScrollDown={getAllRoles}
                                page={rolePage}
                                totalPage={roleTotalPage}
                                onChange={(val) => setFieldValue(`role`, val)}
                                error={errors.role && touched.role ? errors.role : null}
                              />
                            </Col>
                            <Col xl={6} lg={12}>
                              <div className="gender-field__block">
                                <RadioButton
                                  containerClassName="radio-field"
                                  checked={values.gender === 'male'}
                                  onChange={() => setFieldValue('gender', 'male')}
                                >
                                  {t('userForm.male')}
                                </RadioButton>
                                <RadioButton
                                  containerClassName="radio-field"
                                  checked={values.gender === 'female'}
                                  onChange={() => setFieldValue('gender', 'female')}
                                >
                                  {t('userForm.female')}
                                </RadioButton>
                              </div>
                            </Col>
                            <Col xl={6} lg={12}>
                              <DatePickerInput
                                label={t('userForm.dob')}
                                name="dob"
                                value={values.dob}
                                onChange={(dateString) => setFieldValue('dob', dateString)}
                                // error={errors.dob && touched.dob ? errors.dob : null}
                              />
                            </Col>
                            <Col lg={6} md={12}>
                              <TextInput
                                label={t('userForm.address')}
                                placeholder={t('userForm.address')}
                                name="address"
                                value={values.address}
                                onChange={handleChange}
                                // error={errors.address && touched.address ? errors.address : null}
                              />
                            </Col>

                            <Col md={12}>
                              <span className="save-btn--block">
                                {!_.isEmpty(manageRolePermissions) || (!_.isEmpty(getRolePermissions) && !_.isEmpty(manageRolePermissions)) ? (
                                  <Button type="submit" className="profile-save-btn" disabled={isSubmitting}>
                                    {isOtpVerify ? 'Send OTP' : 'Save'}
                                  </Button>
                                ) : (
                                  <Button className="profile-save-btn">{isOtpVerify ? t('userForm.sendOTP') : t('userForm.save')}</Button>
                                )}
                              </span>
                              <span className="cancel-btn--block">
                                <Button className="cancel-btn" onClick={() => navigateTo(`/accessManagement`)}>
                                  {t('button.cancel')}
                                </Button>
                              </span>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </div>
            {showOtpModal && (
              <Modal show={showOtpModal} centered size="lg">
                <OtpConfirmationModal onClose={() => setShowOtpModal(false)} isVerifyOtp={(val) => setIsOtpVerify(val)} phoneData={phoneData} />
              </Modal>
            )}
          </Card>
        </div>
      </div>
    </LayoutContainer>
  );
};

export default UserForm;
