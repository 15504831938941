import React, { useCallback, useEffect } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header';
import Card from 'components/inputs/Card';
import { Form, Formik, getIn } from 'formik';
import { Col, Row } from 'react-bootstrap';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import * as Yup from 'yup';
import { FETCH_TENANT_SETTING, UPDATE_TENANT_SETTING } from 'actions/tenantSetting';
import { useDispatch, useSelector } from 'react-redux';
import * as _ from 'lodash';
import CheckBox from 'components/inputs/CheckBox';
import Select from 'components/inputs/Select';
import { useTranslation } from 'react-i18next';
import Footer from 'components/general/Footer';

const UpdateApp = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const tenantSettingDetail = useSelector((state) => state.tenantSetting.tenantSettings);
  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserName = _.get(profileData, 'name', '');

  const data = _.filter(tenantSettingDetail, (data) => data.key_name === 'app_settings');

  const UpdateAppSchema = Yup.object().shape({
    key_value: Yup.object().shape({
      build: Yup.string().required(t('updateApp.build')),
      force_update_for: Yup.string().required(t('updateApp.forceUpdate')),
      ios_build: Yup.string().required(t('updateApp.iosBuild')),
      ios_version: Yup.string().required(t('updateApp.iosVersion')),
      version: Yup.string().required(t('updateApp.version')),
      force_update_message: Yup.string().required(t('updateApp.forceUpdateMessage')),
      force_update_header: Yup.string().required(t('updateApp.forceUpdateHeader')),
    }),
  });

  const initialValues = !_.isEmpty(data)
    ? { key_value: _.get(data, '0.key_value') }
    : {
        key_value: {
          build: '',
          force_update_for: '',
          ios_build: '',
          ios_version: '',
          version: '',
          force_update_message: '',
          notification_type: '',
          revert_notification: false,
          force_update_header: '',
          rfid_feature: false,
        },
      };

  const getTenantSetting = useCallback(() => {
    dispatch({ type: FETCH_TENANT_SETTING });
  }, []);

  useEffect(() => {
    getTenantSetting();
  }, []);

  const updateTenantSetting = useCallback((data = {}) => {
    dispatch({
      type: UPDATE_TENANT_SETTING,
      payload: data,
    });
  }, []);

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.updateApp')} />
        <div className="page-content-wrapper scrollable">
          <div className="app-setting_main">
            <Card className="app-setting-form-card">
              <div className="app-setting-form__block">
                <Formik
                  enableReinitialize={true}
                  initialValues={initialValues}
                  validationSchema={UpdateAppSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    updateTenantSetting({ ...values, id: _.get(data, '0.id'), key_name: 'app_settings' });
                    setSubmitting(false);
                  }}
                >
                  {({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => (
                    <Form onSubmit={handleSubmit}>
                      <div className="app-setting-form__inner">
                        <Row>
                          <Col lg={6} md={12}>
                            <TextInput
                              isRequired
                              placeholder={t('appSetting.androidBuild')}
                              label={t('appSetting.androidBuild')}
                              name="key_value.build"
                              value={_.get(values, 'key_value.build')}
                              onChange={handleChange}
                              error={getIn(errors, 'key_value.build') && getIn(touched, 'key_value.build') ? getIn(errors, 'key_value.build') : null}
                            />
                          </Col>
                          <Col lg={6} md={12}>
                            <TextInput
                              isRequired
                              placeholder={t('appSetting.androidVersion')}
                              label={t('appSetting.androidVersion')}
                              name="key_value.version"
                              value={_.get(values, 'key_value.version')}
                              onChange={handleChange}
                              error={
                                getIn(errors, 'key_value.version') && getIn(touched, 'key_value.version') ? getIn(errors, 'key_value.version') : null
                              }
                            />
                          </Col>
                          <Col lg={6} md={12}>
                            <TextInput
                              isRequired
                              placeholder={t('appSetting.iosBuildLabel')}
                              label={t('appSetting.iosBuildLabel')}
                              name="key_value.ios_build"
                              value={_.get(values, 'key_value.ios_build')}
                              onChange={handleChange}
                              error={
                                getIn(errors, 'key_value.ios_build') && getIn(touched, 'key_value.ios_build')
                                  ? getIn(errors, 'key_value.ios_build')
                                  : null
                              }
                            />
                          </Col>
                          <Col lg={6} md={12}>
                            <TextInput
                              isRequired
                              placeholder={t('appSetting.iosVersionLabel')}
                              label={t('appSetting.iosVersionLabel')}
                              name="key_value.ios_version"
                              value={_.get(values, 'key_value.ios_version')}
                              onChange={handleChange}
                              error={
                                getIn(errors, 'key_value.ios_version') && getIn(touched, 'key_value.ios_version')
                                  ? getIn(errors, 'key_value.ios_version')
                                  : null
                              }
                            />
                          </Col>
                          <Col lg={6} md={12}>
                            <Select
                              isRequired
                              label={t('appSetting.updatefor')}
                              options={[
                                { label: 'Android', value: 'android' },
                                { label: 'IOS', value: 'ios' },
                                { label: 'Both', value: 'both' },
                              ]}
                              placeholder={t('appSetting.updatefor')}
                              name="key_value.force_update_for"
                              value={_.get(values, 'key_value.force_update_for')}
                              onChange={(val) => setFieldValue(`key_value.force_update_for`, val)}
                              error={
                                getIn(errors, 'key_value.force_update_for') && getIn(touched, 'key_value.force_update_for')
                                  ? getIn(errors, 'key_value.force_update_for')
                                  : null
                              }
                            />
                          </Col>
                          <Col lg={6} md={12}>
                            <TextInput
                              isRequired
                              placeholder={t('appSetting.updateHeader')}
                              label={t('appSetting.updateHeader')}
                              name="key_value.force_update_header"
                              value={_.get(values, 'key_value.force_update_header')}
                              onChange={handleChange}
                              error={
                                getIn(errors, 'key_value.force_update_header') && getIn(touched, 'key_value.force_update_header')
                                  ? getIn(errors, 'key_value.force_update_header')
                                  : null
                              }
                            />
                          </Col>
                          <Col lg={6} md={12}>
                            <Select
                              isRequired
                              label={t('appSetting.updateNotification')}
                              options={[
                                { label: 'Force', value: 'force' },
                                { label: 'Optional', value: 'optional' },
                              ]}
                              placeholder={t('appSetting.updatefor')}
                              name="key_value.notification_type"
                              value={_.get(values, 'key_value.notification_type')}
                              onChange={(val) => setFieldValue(`key_value.notification_type`, val)}
                              error={
                                getIn(errors, 'key_value.notification_type') && getIn(touched, 'key_value.notification_type')
                                  ? getIn(errors, 'key_value.notification_type')
                                  : null
                              }
                            />
                          </Col>
                          <Col lg={2} md={12}>
                            <div className="force-upgrade-field-main--block">
                              <div className="force-upgrade-checkbox-input__block">
                                <label htmlFor="force-upgrade" className="force-upgrade--label">
                                  {t('appSetting.revert')}
                                </label>
                                <CheckBox
                                  name="key_value.revert_notification"
                                  checked={_.get(values, 'key_value.revert_notification')}
                                  onChange={(e) => setFieldValue('key_value.revert_notification', e.target.checked)}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col lg={2} md={12}>
                            <div className="rfid-request-field-main--block">
                              <div className="rfid-request-checkbox-input__block">
                                <label htmlFor="rfid-request" className="rfid-request--label">
                                  {t('appSetting.rfid')}
                                </label>
                                <CheckBox
                                  name="key_value.rfid_feature"
                                  checked={_.get(values, 'key_value.rfid_feature')}
                                  onChange={(e) => setFieldValue('key_value.rfid_feature', e.target.checked)}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col lg={6} md={12}>
                            <TextInput
                              isRequired
                              as="textarea"
                              rows="4"
                              inputClass="scrollable"
                              placeholder={t('appSetting.updateMessage')}
                              label={t('appSetting.updateMessage')}
                              name="key_value.force_update_message"
                              value={_.get(values, 'key_value.force_update_message')}
                              onChange={handleChange}
                              error={
                                getIn(errors, 'key_value.force_update_message') && getIn(touched, 'key_value.force_update_message')
                                  ? getIn(errors, 'key_value.force_update_message')
                                  : null
                              }
                            />
                          </Col>
                        </Row>
                      </div>
                      <div className="app-setting-btn--block">
                        <Button type="submit">{t('button.update')}</Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Card>
          </div>
          {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
        </div>
      </LayoutContainer>
    </>
  );
};

export default UpdateApp;
