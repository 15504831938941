import {
  CREATE_VEHICLE,
  CREATE_VEHICLE_SUCCESS,
  CREATE_VEHICLE_FAIL,
  FETCH_VEHICLE,
  FETCH_VEHICLE_SUCCESS,
  FETCH_VEHICLE_FAIL,
  GET_VEHICLE,
  GET_VEHICLE_SUCCESS,
  GET_VEHICLE_FAIL,
  DELETE_VEHICLE,
  DELETE_VEHICLE_SUCCESS,
  DELETE_VEHICLE_FAIL,
  UPDATE_VEHICLE,
  UPDATE_VEHICLE_FAIL,
  UPDATE_VEHICLE_SUCCESS,
  UPDATE_VRN,
  UPDATE_VRN_SUCCESS,
  UPDATE_VRN_FAIL,
} from 'actions/vehicle';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  page_no: '',
  vehicles: [],
  vehicleDetail: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const vehicleReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CREATE_VEHICLE:
    case GET_VEHICLE:
    case DELETE_VEHICLE: {
      return { ...state, isLoading: true, vehicleDetail: {} };
    }

    case FETCH_VEHICLE: {
      return { ...state, isLoading: true, page_no: '' };
    }

    case UPDATE_VEHICLE:
    case UPDATE_VRN: {
      return { ...state, isLoading: true };
    }

    case FETCH_VEHICLE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        page_no: payload.config?.params?.page,
        vehicles: payload.data?.results,
        page: payload.data?.page,
        limit: payload.data?.limit,
        totalPages: payload.data?.totalPages,
        total: payload.data?.totalResults,
      };
    }

    case GET_VEHICLE_SUCCESS: {
      return {
        ...state,
        vehicleDetail: payload,
        isLoading: false,
      };
    }

    case CREATE_VEHICLE_SUCCESS: {
      toast.success('Vehicle added successfully');
      return {
        ...state,
        vehicles: _.concat(state.vehicles, payload),
        isLoading: false,
      };
    }

    case UPDATE_VEHICLE_SUCCESS: {
      toast.success('Vehicle updated successfully');
      return {
        ...state,
        isLoading: false,
        vehicles: state.vehicles.map((data) => (data.id === payload.id ? payload : data)),
      };
    }

    case UPDATE_VRN_SUCCESS: {
      toast.success('VIN Added Successfully');
      return {
        ...state,
      };
    }

    case DELETE_VEHICLE_SUCCESS: {
      toast.success('Vehicle deleted successfully');
      return {
        ...state,
        isLoading: false,
        vehicles: state.vehicles.filter((data) => data.id !== payload),
      };
    }

    case FETCH_VEHICLE_FAIL:
    case CREATE_VEHICLE_FAIL:
    case GET_VEHICLE_FAIL:
    case DELETE_VEHICLE_FAIL:
    case UPDATE_VEHICLE_FAIL:
    case UPDATE_VRN_FAIL: {
      return { ...state, isLoading: false };
    }
    default:
      return { ...state };
  }
};
export default vehicleReducer;
