export const FETCH_OEM_VENDOR = 'FETCH_OEM_VENDOR';
export const FETCH_OEM_VENDOR_SUCCESS = 'FETCH_OEM_VENDOR_SUCCESS';
export const FETCH_OEM_VENDOR_FAIL = 'FETCH_OEM_VENDOR_FAIL';

export const GET_OEM_VENDOR = 'GET_OEM_VENDOR';
export const GET_OEM_VENDOR_SUCCESS = 'GET_OEM_VENDOR_SUCCESS';
export const GET_OEM_VENDOR_FAIL = 'GET_OEM_VENDOR_FAIL';

export const CREATE_OEM_VENDOR = 'CREATE_OEM_VENDOR';
export const CREATE_OEM_VENDOR_SUCCESS = 'CREATE_OEM_VENDOR_SUCCESS';
export const CREATE_OEM_VENDOR_FAIL = 'CREATE_OEM_VENDOR_FAIL';

export const UPDATE_OEM_VENDOR = 'UPDATE_OEM_VENDOR';
export const UPDATE_OEM_VENDOR_SUCCESS = 'UPDATE_OEM_VENDOR_SUCCESS';
export const UPDATE_OEM_VENDOR_FAIL = 'UPDATE_OEM_VENDOR_FAIL';

export const DELETE_OEM_VENDOR = 'DELETE_OEM_VENDOR';
export const DELETE_OEM_VENDOR_SUCCESS = 'DELETE_OEM_VENDOR_SUCCESS';
export const DELETE_OEM_VENDOR_FAIL = 'DELETE_OEM_VENDOR_FAIL';
