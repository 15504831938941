import {
  FETCH_REPORT_DOWNLOAD_REQUEST,
  FETCH_REPORT_DOWNLOAD_REQUEST_SUCCESS,
  FETCH_REPORT_DOWNLOAD_REQUEST_FAIL,
} from 'actions/downloadAdvanceReport';

const initialState = {
  isLoading: false,
  reportDownloadRequest: [],
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};
const reportDownloadRequestReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_REPORT_DOWNLOAD_REQUEST: {
      return { ...state, isLoading: true };
    }

    case FETCH_REPORT_DOWNLOAD_REQUEST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        reportDownloadRequest: payload?.results,
        page: payload?.page,
        limit: payload?.limit,
        totalPages: payload?.totalPages,
        total: payload?.totalResults,
      };
    }

    case FETCH_REPORT_DOWNLOAD_REQUEST_FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};

export default reportDownloadRequestReducer;
