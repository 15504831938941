import {
  FETCH_EMAIL,
  FETCH_EMAIL_SUCCESS,
  FETCH_EMAIL_FAIL,
  GET_EMAIL,
  GET_EMAIL_SUCCESS,
  GET_EMAIL_FAIL,
  UPDATE_EMAIL,
  UPDATE_EMAIL_SUCCESS,
  UPDATE_EMAIL_FAIL,
  CREATE_EMAIL,
  CREATE_EMAIL_SUCCESS,
  CREATE_EMAIL_FAIL,
  DELETE_EMAIL,
  DELETE_EMAIL_SUCCESS,
  DELETE_EMAIL_FAIL,
} from 'actions/email';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';

function* fetchEmail(action) {
  try {
    const response = yield call(API.getEmail, action.payload);
    yield put({ type: FETCH_EMAIL_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_EMAIL_FAIL, payload: { error: e } });
  }
}

function* getEmailById(action) {
  try {
    const response = yield call(API.getEmailById, action.payload);
    yield put({ type: GET_EMAIL_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_EMAIL_FAIL, payload: { error: e } });
  }
}

function* createEmail(action) {
  try {
    const response = yield call(API.addEmail, action.payload);
    yield put({ type: CREATE_EMAIL_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_EMAIL_FAIL, payload: { error: e } });
  }
}

function* updateEmail(action) {
  try {
    const response = yield call(API.updateEmail, action.payload);
    yield put({ type: UPDATE_EMAIL_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_EMAIL_FAIL, payload: { error: e } });
  }
}

function* deleteEmail(action) {
  try {
    const response = yield call(API.deleteEmail, action.payload);
    yield put({ type: DELETE_EMAIL_SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_EMAIL_FAIL, payload: { error: e } });
  }
}

function* email() {
  yield takeLatest(FETCH_EMAIL, fetchEmail);
  yield takeLatest(GET_EMAIL, getEmailById);
  yield takeLatest(CREATE_EMAIL, createEmail);
  yield takeLatest(UPDATE_EMAIL, updateEmail);
  yield takeLatest(DELETE_EMAIL, deleteEmail);
}
export default email;
