import {
    GET_ALL_MODULES,
    GET_ALL_MODULES_FAIL,
    GET_ALL_MODULES_SUCCESS,
    UPDATE_MODULE,
    UPDATE_MODULE_SUCCESS,
    UPDATE_MODULE_FAIL,
  } from 'actions/tenantAdminAccess';
  
  const initialState = {
    isLoading: false,
    excluded_tenant:[],
    modules: [],
    page: 1,
    limit: 10,
    totalPages: 1,
    total: 1,
  };
  
  const tenantAdminAccessReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case GET_ALL_MODULES:
      case UPDATE_MODULE: {
        return { ...state, isLoading: true };
      }

      case GET_ALL_MODULES_SUCCESS: {
        return {
          ...state,
          modules: payload.results,
          isLoading: false,
        };
      }
  
      case UPDATE_MODULE_SUCCESS: {
        return {
          ...state,
          isLoading: false,
          excluded_tenant: state.excluded_tenant.filter((data) => (data.id === payload.id ? payload : data)),
        };
      }
  
      case GET_ALL_MODULES_FAIL:
      case UPDATE_MODULE_FAIL: {
        return { ...state, isLoading: false };
      }
  
      default:
        return { ...state };
    }
  };
  export default tenantAdminAccessReducer;
  