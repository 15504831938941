import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_MODULE } from 'actions/tenantAdminAccess';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header';
import * as _ from 'lodash';
import { Card, Col, Row } from 'react-bootstrap';
// import CheckBox from 'components/inputs/CheckBox';
import ToggleSwitch from 'components/inputs/ToggleSwitch';
import { useParams } from 'react-router-dom';
import { UPDATE_STATUS_CODE } from 'components/common/constant';
import { useTranslation } from 'react-i18next';
import { FETCH_MODULE_SORTED } from 'actions/module';
import Collapse from 'components/inputs/Accordion';
import { GET_ROLE_BY_NAME } from 'actions/role';

const TenantAdminAccess = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { roleId } = useParams();
  const modules = useSelector((state) => state.module.sortedModules);
  const tenantAdminModules = useSelector((state) => state.role.roleByName);
  const [allowedModules, setAllowedModules] = useState([]);
  const [moduleIds, setModuleIds] = useState([]);

  const getAllModule = useCallback(() => {
    dispatch({ type: FETCH_MODULE_SORTED });
  }, []);

  const updateModule = useCallback((data) => {
    dispatch({
      type: UPDATE_MODULE,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          getAllModule();
        }
      },
    });
  }, []);

  useEffect(() => {
    getAllModule();

    const data = {
      name: 'tenant_admin',
    };
    dispatch({ type: GET_ROLE_BY_NAME, payload: data });
  }, [roleId]);

  useEffect(() => {
    if (tenantAdminModules?.modules && tenantAdminModules?.modules?.length > 0 && modules?.length > 0) {
      let ids = tenantAdminModules?.modules.map((obj) => obj.id);
      setModuleIds([...ids]);
      modules.forEach((element) => {
        removeExcludedModules(element, ids);
      });
      setAllowedModules([...ids]);
    }
  }, [JSON.stringify(tenantAdminModules), JSON.stringify(modules)]);

  const handleOnToggleChange = (e, item) => {
    const isCheck = e.target.checked;
    const permissionId = _.get(item, 'id');
    const updateExcludedTenant = _.get(item, 'excluded_tenants');
    if (isCheck === false) {
      const i = allowedModules.indexOf(permissionId);
      allowedModules.splice(i, 1);
      setAllowedModules([...allowedModules]);
      updateModule({
        permissionId,
        key: item.key,
        excluded_tenants: [...updateExcludedTenant, roleId],
      });
    } else {
      setAllowedModules([...allowedModules, permissionId]);
      updateExcludedTenant.pop(roleId);
      updateModule({ permissionId, key: item.key, excluded_tenants: [...updateExcludedTenant] });
    }
  };

  const removeExcludedModules = (module, ids) => {
    if (module.excluded_tenants.includes(roleId)) {
      const i = ids.indexOf(module.id);
      ids.splice(i, 1);
    }
    if (module?.children !== undefined) {
      module.children.forEach((element) => removeExcludedModules(element, ids));
    }
  };

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.adminConfigureDetail')} backTo="/tenantAdmin" />
        <div className="page-content-wrapper scrollable">
          {_.map(modules, (data, index) => {
            return (
              <div key={index}>
                <Collapse
                  title={
                    <div className="flex items-center justify-between">
                      <span>{data.name}</span>
                      <ToggleSwitch
                        checked={allowedModules.includes(data.id)}
                        disabled={!moduleIds.includes(data.id)}
                        onChange={(e) => handleOnToggleChange(e, data)}
                      />
                    </div>
                  }
                  eventKey={index}
                >
                  {data?.children !== undefined && (
                    <Card>
                      <Card.Body>
                        <h5>{t('configurationRole.childModules')}</h5>
                        <Row>
                          {_.map(data.children, (childmodule, key) => {
                            return (
                              <Col key={`childmodule-${index}-${key}`} lg={12}>
                                {childmodule?.children !== undefined ? (
                                  <Collapse
                                    title={
                                      <div className="flex items-center justify-between">
                                        <span>{childmodule.name}</span>
                                        <ToggleSwitch
                                          checked={allowedModules.includes(childmodule.id)}
                                          disabled={!moduleIds.includes(childmodule.id)}
                                          onChange={(e) => handleOnToggleChange(e, childmodule)}
                                        />
                                      </div>
                                    }
                                    eventKey={key}
                                  >
                                    <Card>
                                      <Card.Body>
                                        <h6>{t('configurationRole.childModules')}</h6>
                                        <Row>
                                          {_.map(childmodule?.children, (childmodule3, key3) => {
                                            return (
                                              <Col key={`childmodule-${index}-${key}-${key3}`} lg={12}>
                                                <Row>
                                                  <Col>{_.get(childmodule3, 'name', '')}</Col>
                                                  <Col>
                                                    <ToggleSwitch
                                                      id={`childmodule-${index}-${key}-${key3}`}
                                                      name={`childmodule-${index}-${key}-${key3}`}
                                                      checked={allowedModules.includes(childmodule3.id)}
                                                      disabled={!moduleIds.includes(childmodule3.id)}
                                                      onChange={(e) => handleOnToggleChange(e, childmodule3)}
                                                      containerClassName="text-end pe-4 w-100"
                                                    />
                                                  </Col>
                                                </Row>
                                              </Col>
                                            );
                                          })}
                                        </Row>
                                      </Card.Body>
                                    </Card>
                                  </Collapse>
                                ) : (
                                  <Row>
                                    <Col>{_.get(childmodule, 'name', '')}</Col>
                                    <Col>
                                      <ToggleSwitch
                                        id={`childmodule-${index}-${key}`}
                                        name={`childmodule-${index}-${key}`}
                                        checked={allowedModules.includes(childmodule.id)}
                                        disabled={!moduleIds.includes(childmodule.id)}
                                        onChange={(e) => handleOnToggleChange(e, childmodule)}
                                        containerClassName="text-end pe-4 w-100"
                                      />
                                    </Col>
                                  </Row>
                                )}
                              </Col>
                            );
                          })}
                        </Row>
                      </Card.Body>
                    </Card>
                  )}
                </Collapse>
              </div>
            );
          })}
        </div>
      </LayoutContainer>
    </>
  );
};

export default TenantAdminAccess;
