import {
  FETCH_MODULE,
  FETCH_MODULE_SUCCESS,
  FETCH_MODULE_FAIL,
  CREATE_MODULE,
  UPDATE_MODULE,
  DELETE_MODULE,
  CREATE_MODULE_FAIL,
  UPDATE_MODULE_FAIL,
  DELETE_MODULE_FAIL,
  DELETE_MODULE_SUCCESS,
  UPDATE_MODULE_SUCCESS,
  CREATE_MODULE_SUCCESS,
  GET_MODULE,
  GET_MODULE_SUCCESS,
  GET_MODULE_FAIL,
  FETCH_MODULE_SORTED_SUCCESS,
  FETCH_MODULE_SORTED_FAIL,
  FETCH_MODULE_SORTED,
} from 'actions/module';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  modules: [],
};

const moduleReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_MODULE:
    case FETCH_MODULE_SORTED:
    case GET_MODULE:
    case CREATE_MODULE:
    case UPDATE_MODULE:
    case DELETE_MODULE: {
      return { ...state, isLoading: true };
    }

    case FETCH_MODULE_SORTED_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        sortedModules: payload,
      };
    }

    case FETCH_MODULE_SUCCESS: {
      const { results, ...rest } = payload;
      return {
        ...state,
        isLoading: false,
        modules: results,
        ...rest,
      };
    }

    case GET_MODULE_SUCCESS: {
      return {
        ...state,
        moduleDetail: payload,
        isLoading: false,
      };
    }

    case CREATE_MODULE_SUCCESS: {
      toast.success('Module added successfully');
      return {
        ...state,
        modules: _.concat(state.modules, payload),
        isLoading: false,
      };
    }

    case UPDATE_MODULE_SUCCESS: {
      toast.success('Module updated successfully');
      delete state.moduleDetail;

      return {
        ...state,
        isLoading: false,
        modules: state.modules.map((data) => {
          if (data.id === payload.id) {
            return payload;
          } else {
            return data;
          }
        }),
      };
    }

    case DELETE_MODULE_SUCCESS: {
      toast.success('Module deleted successfully');
      return {
        ...state,
        isLoading: false,
        modules: state.modules.filter((data) => data.id !== payload),
      };
    }

    case FETCH_MODULE_SORTED_FAIL:
    case FETCH_MODULE_FAIL:
    case GET_MODULE_FAIL:
    case CREATE_MODULE_FAIL:
    case UPDATE_MODULE_FAIL:
    case DELETE_MODULE_FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default:
      return {
        ...state,
      };
  }
};
export default moduleReducer;
