export const UPLOAD_BULK_METER_VALUES_B2C = ' UPLOAD_BULK_METER_VALUES_B2C';
export const UPLOAD_BULK_METER_VALUES_B2C_SUCCESS = ' UPLOAD_BULK_METER_VALUES_B2C_SUCCESS';
export const UPLOAD_BULK_METER_VALUES_B2C_FAIL = ' UPLOAD_BULK_METER_VALUES_B2C_FAIL';

export const UPLOAD_BULK_METER_VALUES_B2B = ' UPLOAD_BULK_METER_VALUES_B2B';
export const UPLOAD_BULK_METER_VALUES_B2B_SUCCESS = ' UPLOAD_BULK_METER_VALUES_B2B_SUCCESS';
export const UPLOAD_BULK_METER_VALUES_B2B_FAIL = ' UPLOAD_BULK_METER_VALUES_B2B_FAIL';

export const UPLOAD_BULK_BOOKING_TIME = 'UPLOAD_BULK_BOOKING_TIME';
export const UPLOAD_BULK_BOOKING_TIME_SUCCESS = 'UPLOAD_BULK_BOOKING_TIME_SUCCESS';
export const UPLOAD_BULK_BOOKING_TIME_FAIL = 'UPLOAD_BULK_BOOKING_TIME_FAIL';

export const UPLOAD_BULK_SOC_VALUES = 'UPLOAD_BULK_SOC_VALUES';
export const UPLOAD_BULK_SOC_VALUES_SUCCESS = 'UPLOAD_BULK_SOC_VALUES_SUCCESS';
export const UPLOAD_BULK_SOC_VALUES_FAIL = 'UPLOAD_BULK_SOC_VALUES_FAIL';

export const UPLOAD_BULK_STOP_TRANSACTION = 'UPLOAD_BULK_STOP_TRANSACTION';
export const UPLOAD_BULK_STOP_TRANSACTION_SUCCESS = 'UPLOAD_BULK_STOP_TRANSACTION_SUCCESS';
export const UPLOAD_BULK_STOP_TRANSACTION_FAIL = 'UPLOAD_BULK_STOP_TRANSACTION_FAIL';
