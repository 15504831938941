export const FETCH_OFFERS = 'FETCH_OFFERS';
export const FETCH_OFFERS_SUCCESS = 'FETCH_OFFERS_SUCCESS';
export const FETCH_OFFERS_FAIL = 'FETCH_OFFERS_FAIL';

export const GET_OFFERS = 'GET_OFFERS';
export const GET_OFFERS_SUCCESS = 'GET_OFFERS_SUCCESS';
export const GET_OFFERS_FAIL = 'GET_OFFERS_FAIL';

export const CREATE_OFFERS = 'CREATE_OFFERS';
export const CREATE_OFFERS_SUCCESS = 'CREATE_OFFERS_SUCCESS';
export const CREATE_OFFERS_FAIL = 'CREATE_OFFERS_FAIL';

export const UPDATE_OFFERS = 'UPDATE_OFFERS';
export const UPDATE_OFFERS_SUCCESS = 'UPDATE_OFFERS_SUCCESS';
export const UPDATE_OFFERS_FAIL = 'UPDATE_OFFERS_FAIL';

export const DELETE_OFFERS = 'DELETE_OFFERS';
export const DELETE_OFFERS_SUCCESS = 'DELETE_OFFERS_SUCCESS';
export const DELETE_OFFERS_FAIL = 'DELETE_OFFERS_FAIL';
