import {
  ADD_TENANT,
  ADD_TENANT_FAIL,
  ADD_TENANT_SUCCESS,
  FETCH_TENANT,
  FETCH_TENANT_FAIL,
  FETCH_TENANT_SUCCESS,
  GET_TENANT,
  GET_TENANT_FAIL,
  GET_TENANT_SUCCESS,
  DELETE_TENANT_SUCCESS,
  UPDATE_TENANT,
  UPDATE_TENANT_SUCCESS,
  UPDATE_TENANT_FAIL,
} from 'actions/tenant';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  tenants: [],
  tenantDetail: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const tenantReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_TENANT:
    case FETCH_TENANT:
    case GET_TENANT: {
      return { ...state, isLoading: true, tenantDetail: {} };
    }

    case UPDATE_TENANT: {
      return { ...state, isLoading: true };
    }

    case FETCH_TENANT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        tenants: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }
    case GET_TENANT_SUCCESS: {
      return {
        ...state,
        tenantDetail: payload,
        page: payload.page,
        limit: payload.limit,
        isLoading: false,
      };
    }

    case ADD_TENANT_SUCCESS: {
      toast.success('Tenant added successfully');
      return {
        ...state,
        tenants: _.concat(state.tenants, payload),
        isLoading: false,
      };
    }

    case UPDATE_TENANT_SUCCESS: {
      // toast.success('Tenant update successfully');
      return {
        ...state,
        isLoading: false,
        tenants: state.tenants.map((data) => (data.id === payload.id ? payload : data)),
      };
    }

    case DELETE_TENANT_SUCCESS: {
      toast.success('Tenant deleted successfully');
      return {
        ...state,
        isLoading: false,
        tenants: state.tenants.filter((data) => data.id !== payload),
      };
    }

    case UPDATE_TENANT_FAIL:
    case ADD_TENANT_FAIL:
    case FETCH_TENANT_FAIL:
    case GET_TENANT_FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};
export default tenantReducer;
