import React, { useCallback, useEffect, useState, useContext } from 'react';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_MODULE_SORTED } from 'actions/module';
import Collapse from 'components/inputs/Accordion';
import * as _ from 'lodash';
// import CheckBox from 'components/inputs/CheckBox';
import { GET_ROLE, UPDATE_ROLE } from 'actions/role';
import { useParams } from 'react-router-dom';
import Button from 'components/inputs/Button';
import { UPDATE_STATUS_CODE } from 'components/common/constant';
import { useTranslation } from 'react-i18next';
import { NavContext } from 'components/privateRoute/PrivateRoute';
import ToggleSwitch from 'components/inputs/ToggleSwitch';
import { Card, Col, Row } from 'react-bootstrap';

const ConfigureRole = () => {
  const { navigateTo } = useContext(NavContext);
  const { roleId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [allPermissions, setAllPermissions] = useState([]);
  const [checkedPermission, setCheckedPermission] = useState(true);
  const [checkedModules, setCheckedModules] = useState([]);
  const [checkedModulesObjs, setCheckedModulesObjs] = useState({});
  const modules = useSelector((state) => state.module.sortedModules);
  const roleDetail = useSelector((state) => state.role.roleDetail);

  const getAllModule = useCallback(() => {
    dispatch({ type: FETCH_MODULE_SORTED });
  }, []);

  const getAllRole = useCallback((id) => {
    dispatch({ type: GET_ROLE, payload: id });
  }, []);

  /* const updateRolePermissions = useCallback(() => {
    const PermissionData = {
      permissions: allPermissions,
      id: roleId,
    };
    dispatch({
      type: UPDATE_ROLE,
      payload: PermissionData,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          navigateTo('/role');
        }
      },
    });
  }, [allPermissions, roleId]); */

  useEffect(() => {
    if (Object.keys(roleDetail).length > 0 && roleDetail?.modules?.length > 0) {
      setRoleModules();
    }

    return () => {
      setCheckedModules([]);
      setCheckedModulesObjs({});
    };
  }, [JSON.stringify(roleDetail)]);

  const setRoleModules = () => {
    let ids = [];
    let objs = {};

    // roleDetails.modules have _id for object id, id which is module id
    roleDetail?.modules.forEach((module) => {
      ids.push(module.id);
      objs[module.id] = {
        id: module.id,
        key: module.key,
      };
    });

    setCheckedModules([...ids]);
    setCheckedModulesObjs({ ...objs });
  };

  const updateRolePermissions = () => {
    dispatch({
      type: UPDATE_ROLE,
      payload: {
        id: roleId,
        modules: Object.values(checkedModulesObjs),
      },
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          navigateTo('/role');
        }
      },
    });
  };

  useEffect(() => {
    getAllModule();
    getAllRole(roleId);
  }, [roleId]);

  const getPermissionDetails = _.filter(roleDetail.permissions, ({ id: id1 }) =>
    _.flattenDepth(
      _.map(
        _.filter(modules, (data) => !_.isEmpty(data.permissions)),
        (item) => item.permissions
      ),
      1
    ).some(({ id: id2 }) => id2 === id1)
  );

  useEffect(() => {
    if (!_.isEmpty(getPermissionDetails) && _.isEmpty(allPermissions) && checkedPermission) {
      setAllPermissions([...allPermissions, ..._.map(getPermissionDetails, (data) => data.id)]);
      setCheckedPermission(false);
    }
  }, [getPermissionDetails, allPermissions, checkedPermission]);

  const handleToggleModule = (module, checked, parent = undefined, grandParent = undefined) => {
    if (checked) {
      let checkModulesX = [...checkedModules];

      if (parent !== undefined && checkedModules.includes(parent.id) === false) {
        checkModulesX.push(parent.id);
        checkedModulesObjs[parent.id] = { id: parent.id, key: parent.key };
      }

      if (grandParent !== undefined && checkedModules.includes(grandParent.id) === false) {
        checkModulesX.push(grandParent.id);
        checkedModulesObjs[grandParent.id] = { id: grandParent.id, key: grandParent.key };
      }

      setCheckedModules([...checkModulesX, module.id]);
      checkedModulesObjs[module.id] = { id: module.id, key: module.key };
      setCheckedModulesObjs({ ...checkedModulesObjs });
    } else {
      const index = checkedModules.indexOf(module.id);
      checkedModules.splice(index, 1);
      delete checkedModulesObjs[module.id];

      // child
      if (module?.children !== undefined) {
        module.children.forEach((child) => {
          if (checkedModules.includes(child.id)) {
            const i = checkedModules.indexOf(child.id);
            checkedModules.splice(i, 1);
            delete checkedModulesObjs[child.id];

            // grand child
            if (child?.children !== undefined) {
              child.children.forEach((child2) => {
                if (checkedModules.includes(child2.id)) {
                  const j = checkedModules.indexOf(child2.id);
                  checkedModules.splice(j, 1);
                  delete checkedModulesObjs[child2.id];
                }
              });
            }
          }
        });
      }

      if (parent !== undefined && checkedModules.includes(parent.id)) {
        let checkParent = true;
        parent.children.forEach((parentChild) => {
          if (checkedModules.includes(parentChild.id)) {
            checkParent = false;
          }
        });

        if (checkParent) {
          const parentIndex = checkedModules.indexOf(parent.id);
          checkedModules.splice(parentIndex, 1);
          delete checkedModulesObjs[parent.id];
        }
      }

      if (grandParent !== undefined && checkedModules.includes(grandParent.id)) {
        let checkGrandParent = true;
        grandParent.children.forEach((grandParentChild) => {
          if (checkedModules.includes(grandParentChild.id)) {
            checkGrandParent = false;
          }
        });

        if (checkGrandParent) {
          const grandParentIndex = checkedModules.indexOf(grandParent.id);
          checkedModules.splice(grandParentIndex, 1);
          delete checkedModulesObjs[grandParent.id];
        }
      }

      setCheckedModules([...checkedModules]);
      setCheckedModulesObjs({ ...checkedModulesObjs });
    }
  };

  return (
    <LayoutContainer>
      <AdminHeader title={t('header.adminConfigureDetails')} backTo="/role" />
      <div className="page-content-wrapper scrollable">
        <div>
          {_.map(modules, (data, index) => {
            return (
              <div key={index}>
                <Collapse
                  title={
                    <div className="flex items-center justify-between">
                      <span>{data.name}</span>
                      <ToggleSwitch
                        id={`module-${index}`}
                        name={`module-${index}`}
                        checked={checkedModules.includes(data.id)}
                        onChange={(e) => handleToggleModule(data, e.target.checked)}
                      />
                    </div>
                  }
                  eventKey={index}
                >
                  {data?.children !== undefined && (
                    <Card>
                      <Card.Body>
                        <h5>{t('configurationRole.childModules')}</h5>
                        <Row>
                          {_.map(data.children, (childmodule, key) => {
                            return (
                              <Col key={`childmodule-${index}-${key}`} lg={12}>
                                {childmodule?.children !== undefined ? (
                                  <Collapse
                                    title={
                                      <div className="flex items-center justify-between">
                                        <span>{childmodule.name}</span>
                                        <ToggleSwitch
                                          id={`childmodule-${index}-${key}`}
                                          name={`childmodule-${index}-${key}`}
                                          checked={checkedModules.includes(childmodule.id)}
                                          onChange={(e) => handleToggleModule(childmodule, e.target.checked, data)}
                                        />
                                      </div>
                                    }
                                    eventKey={key}
                                  >
                                    <Card>
                                      <Card.Body>
                                        <h6>{t('configurationRole.childModules')}</h6>
                                        <Row>
                                          {_.map(childmodule?.children, (childmodule3, key3) => {
                                            return (
                                              <Col key={`childmodule-${index}-${key}-${key3}`} lg={12}>
                                                <Row>
                                                  <Col>{_.get(childmodule3, 'name', '')}</Col>
                                                  <Col>
                                                    <ToggleSwitch
                                                      id={`childmodule3-${index}-${key}-${key3}`}
                                                      name={`childmodule3-${index}-${key}-${key3}`}
                                                      checked={checkedModules.includes(childmodule3.id)}
                                                      onChange={(e) => handleToggleModule(childmodule3, e.target.checked, childmodule, data)}
                                                      containerClassName="text-end pe-4 w-100"
                                                    />
                                                  </Col>
                                                </Row>
                                              </Col>
                                            );
                                          })}
                                        </Row>
                                      </Card.Body>
                                    </Card>
                                  </Collapse>
                                ) : (
                                  <Row>
                                    <Col>{_.get(childmodule, 'name', '')}</Col>
                                    <Col>
                                      <ToggleSwitch
                                        id={`childmodule-${index}-${key}`}
                                        name={`childmodule-${index}-${key}`}
                                        checked={checkedModules.includes(childmodule.id)}
                                        onChange={(e) => handleToggleModule(childmodule, e.target.checked, data)}
                                        containerClassName="text-end pe-4 w-100"
                                      />
                                    </Col>
                                  </Row>
                                )}
                              </Col>
                            );
                          })}
                        </Row>
                      </Card.Body>
                    </Card>
                  )}
                </Collapse>
              </div>
            );
          })}
          <div className="configureRole-btn-block">
            <Button className="configureRole-form-btn create-btn" onClick={updateRolePermissions}>
              {t('button.saveUpdate')}
            </Button>
            <Button className="configureRole-form-btn cancel_btn" onClick={() => navigateTo('/role')}>
              {t('button.cancel')}
            </Button>
          </div>
        </div>
      </div>
    </LayoutContainer>
  );
};

export default ConfigureRole;
