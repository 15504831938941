import {
  FETCH_OEM_ERROR_CODE,
  FETCH_OEM_ERROR_CODE_FAIL,
  FETCH_OEM_ERROR_CODE_SUCCESS,
  GET_OEM_ERROR_CODE,
  GET_OEM_ERROR_CODE_SUCCESS,
  GET_OEM_ERROR_CODE_FAIL,
  CREATE_OEM_ERROR_CODE,
  CREATE_OEM_ERROR_CODE_SUCCESS,
  CREATE_OEM_ERROR_CODE_FAIL,
  UPDATE_OEM_ERROR_CODE,
  UPDATE_OEM_ERROR_CODE_SUCCESS,
  UPDATE_OEM_ERROR_CODE_FAIL,
  DELETE_OEM_ERROR_CODE,
  DELETE_OEM_ERROR_CODE_SUCCESS,
  DELETE_OEM_ERROR_CODE_FAIL,
  CREATE_BULK_ERROR_CODE,
  CREATE_BULK_ERROR_CODE_SUCCESS,
  CREATE_BULK_ERROR_CODE_FAIL
} from 'actions/oemErrorCode';

import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import { FETCH_OCPP_ERROR, FETCH_OCPP_ERROR_FAIL, FETCH_OCPP_ERROR_SUCCESS } from 'actions/ocppErrorCode';

function* fetchOemErrorCode(action) {
  try {
    const response = yield call(API.getOemErrorCode, action.payload);
    yield put({ type: FETCH_OEM_ERROR_CODE_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_OEM_ERROR_CODE_FAIL, payload: { error: e } });
  }
}

function* getOemErrorCodeById(action) {
  try {
    const response = yield call(API.getOemErrorCodeById, action.payload);
    yield put({ type: GET_OEM_ERROR_CODE_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_OEM_ERROR_CODE_FAIL, payload: { error: e } });
  }
}

function* createOemErrorCode(action) {
  try {
    const response = yield call(API.addOemErrorCode, action.payload);
    yield put({ type: CREATE_OEM_ERROR_CODE_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_OEM_ERROR_CODE_FAIL, payload: { error: e } });
  }
}

function* updateOemErrorCode(action) {
  try {
    const response = yield call(API.updateOemErrorCode, action.payload);
    yield put({ type: UPDATE_OEM_ERROR_CODE_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_OEM_ERROR_CODE_FAIL, payload: { error: e } });
  }
}

function* deleteOemErrorCode(action) {
  try {
    const response = yield call(API.deleteOemErrorCode, action.payload);
    yield put({ type: DELETE_OEM_ERROR_CODE_SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_OEM_ERROR_CODE_FAIL, payload: { error: e } });
  }
}


function* fetchOcppError(action) {
  try {
    const response = yield call(API.getOcppError, action.payload);
    yield put({ type: FETCH_OCPP_ERROR_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_OCPP_ERROR_FAIL, payload: { error: e } });
  }
}


function* createBulkErrorCode(action) {
  try {
    const response = yield call(API.addBulkErrorCode, action.payload);
    yield put({ type: CREATE_BULK_ERROR_CODE_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_BULK_ERROR_CODE_FAIL, payload: { error: e } });
  }
}

function* oemErrorCode() {
  yield takeLatest(FETCH_OEM_ERROR_CODE, fetchOemErrorCode);
  yield takeLatest(GET_OEM_ERROR_CODE, getOemErrorCodeById);
  yield takeLatest(CREATE_OEM_ERROR_CODE, createOemErrorCode);
  yield takeLatest(UPDATE_OEM_ERROR_CODE, updateOemErrorCode);
  yield takeLatest(DELETE_OEM_ERROR_CODE, deleteOemErrorCode);
  yield takeLatest(FETCH_OCPP_ERROR, fetchOcppError);
  yield takeLatest(CREATE_BULK_ERROR_CODE, createBulkErrorCode);
}
export default oemErrorCode;