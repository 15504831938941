import React, { useState, useCallback, useEffect } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header';
import Card from 'components/inputs/Card';
import { BsSearch } from 'react-icons/bs';
import Button from 'components/inputs/Button';
import { FiEdit2 } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { Row, Col, Spinner } from 'react-bootstrap';
import * as _ from 'lodash';
import SearchBox from 'components/general/SearchBox';
import { useDispatch, useSelector } from 'react-redux';
// import Pagination from 'components/general/Pagination';
import ReactPagination from 'components/general/ReactPagination';
import { Modal } from 'react-bootstrap';
import { DELETE_OEM_ERROR_CODE, FETCH_OEM_ERROR_CODE, GET_OEM_ERROR_CODE } from 'actions/oemErrorCode';
import OemErrorCodeForm from './OemErrorCodeForm';
import OemBulkErrorCodeForm from './OemBulkErrorCodeForm';
import DeleteModal from 'components/general/DeleteModal';
import { DELETE_STATUS_CODE } from 'components/common/constant';
import { BiSort } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
// import { BsUpload } from 'react-icons/bs';
// import { toast } from 'react-toastify';
// import moment from 'moment';
// import { CREATE_BULK_ERROR_CODE } from 'actions/oemErrorCode';
// import { CREATE_STATUS_CODE } from 'components/common/constant';

const OemErrorCodeList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [showForm, setShowForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [bulkForm, setbulkForm] = useState(false);
  const [bulkEdit, setBulkEdit] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  // const [allOemErrorCodeData, setAllOemErrorCodeData] = useState([]);
  const [oemErrorCodeId, setOemErrorCodeId] = useState('');
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });

  const page = useSelector((state) => state.oemErrorCode.page);
  const totalData = useSelector((state) => state.oemErrorCode.total);
  const limit = useSelector((state) => state.oemErrorCode.limit);
  const totalPages = useSelector((state) => state.oemErrorCode.totalPages);
  const oemErrorCodeList = useSelector((state) => state.oemErrorCode.oemErrorCodes);
  const isLoader = useSelector((state) => state.oemErrorCode.isLoading);

  const getOemErrorCode = useCallback(
    (data = {}) => {
      if (searchText) {
        const oemErrorCodeData = {
          ...data,
          search: searchText,
        };
        dispatch({ type: FETCH_OEM_ERROR_CODE, payload: oemErrorCodeData });
      } else {
        dispatch({ type: FETCH_OEM_ERROR_CODE, payload: data });
      }
    },
    [searchText]
  );

  const getOemErrorCodeDetail = useCallback((id) => {
    dispatch({ type: GET_OEM_ERROR_CODE, payload: id });
  }, []);

  const handleCloseDeleteModel = () => {
    setShowDeleteModal(false);
    setOemErrorCodeId('');
  };

  const deleteOemErrorCode = useCallback(
    () =>
      dispatch({
        type: DELETE_OEM_ERROR_CODE,
        payload: oemErrorCodeId,
        cb: (res) => {
          if (_.get(res, 'status', '') === DELETE_STATUS_CODE) {
            handleCloseDeleteModel();
            getOemErrorCode({ page: 1 });
          }
        },
      }),
    [oemErrorCodeId]
  );

  useEffect(() => {
    getOemErrorCode();
  }, []);

  const handleSearch = () => {
    getOemErrorCode({});
  };

  // useEffect(() => {
  //   setAllOemErrorCodeData(
  //     _.filter(
  //       oemErrorCodeList,
  //       (item) =>
  //         startsWith(_.get(item, 'vendor.name', ''), searchText) ||
  //         startsWith(_.get(item, 'state.name', ''), searchText) ||
  //         startsWith(_.get(item, 'code', ''), searchText)
  //     )
  //   );
  // }, [searchText, oemErrorCodeList]);

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else {
        const data = {
          page: page.selected + 1,
        };
        getOemErrorCode(data);
      }
    },
    [sortByItem]
  );

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getOemErrorCode(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getOemErrorCode(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handleCloseFormOemErrorCode = () => {
    setShowForm(false);
    setIsEdit(false);
    setbulkForm(false);
    setBulkEdit(false);
  };


  const sampleErrorCode = [
    {
      vendor_error_code: '',
      cpo_code: '',
      custom_error_code: '',
      error_code: '',
      // vendor_name: '',
      // chargespot_model: ''
    },
  ];

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const downloadSampleErrorCode = (file, fileName) => {
    // download static Excel file
    const ws = XLSX.utils.json_to_sheet(file);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };


  // const upload = () => {
  //   //upload a excel file and get data of uploaded excel
  //   const fileUpload = document.getElementById('file');
  //   const regex = /^([a-zA-Z0-9\s_\\.\-:()])+(.xls|.xlsx)$/;
  //   if (regex.test(fileUpload.value.toLowerCase())) {
  //     // let fileName = fileUpload.files[0].name;
  //     if (typeof FileReader !== 'undefined') {
  //       const reader = new FileReader();
  //       if (reader.readAsBinaryString) {
  //         reader.onload = () => {
  //           processExcel(reader.result);
  //         };
  //         reader.readAsBinaryString(fileUpload.files[0]);
  //       }
  //     } else {
  //       toast.error('This browser does not support HTML5.');
  //     }
  //   } else {
  //     toast.error('Please upload a valid Excel file.');
  //   }
  // };

  // const processExcel = (data) => {
  //   const workbook = XLSX.read(data, { type: 'binary' });
  //   const firstSheet = workbook.SheetNames[0];
  //   const excelRows = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[firstSheet]);
  //   _.map(excelRows, (data) => {
  //     data.date_of_issuance = data.date_of_issuance ? moment((data.date_of_issuance - 25569) * 86400000).format('YYYY/MM/DD') : '';
  //     data.expiry_date = data.expiry_date ? moment((data.expiry_date - 25569) * 86400000).format('YYYY/MM/DD') : '';
  //   });
  //   const result = excelRows.map((e) => ({ ...e, ...{ group: rfidGroupId } }));
  //   addBulkRfid(result);
  // };


  // const addBulkRfid = useCallback((data) => {
  //   dispatch({
  //     type: CREATE_BULK_ERROR_CODE,
  //     payload: data,
  //     cb: (res) => {
  //       if (_.get(res, 'status') === CREATE_STATUS_CODE) {
  //         toast.success('RFID added successfully');
  //       }
  //     },
  //   });
  // }, []);


  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.oemErrorCode')} />
        <div className="page-content-wrapper scrollable">
          <div className="oemErrorCodeList-page">
            <Card>
              <div className="oemErrorCodeList-page-wrapper">
                <Row className="oemErrorCodeList-search-box">
                  <Col xl={6} lg={9} md={8} className="oemErrorCodeList-search-inputBox">
                    <SearchBox
                      value={searchText}
                      preIcon={<BsSearch />}
                      onChange={(e) => setSearchText(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          handleSearch();
                        }
                      }}
                    />
                  </Col>

                  <Col xl={2} lg={3} md={4} className="oemErrorCodeList-search-boxButton">
                    <Button
                      className="oemErrorCodeList-search-box-Btn"
                      onClick={() => {
                        setShowForm(true);
                        setIsEdit(false);
                      }}
                    >
                      {t('button.addErrorCode')}
                    </Button>
                  </Col>

                  <Col xl={2} lg={3} md={4} className="oemErrorCodeList-search-boxButton">
                    <Button>
                      <div className="download-sample-file" onClick={() => downloadSampleErrorCode(sampleErrorCode, 'errorcodelist')}>
                        {t('Download Sample Excel Sheet')}
                      </div>
                    </Button>
                  </Col>

                  <Col xl={2} lg={3} md={4} className="oemErrorCodeList-search-boxButton">
                    <Button
                      className="oemErrorCodeList-search-box-Btn"
                      onClick={() => {
                        setbulkForm(true);
                        setBulkEdit(false);
                      }}
                    >
                      {t('Bulk Upload Error Code')}
                    </Button>
                  </Col>


                  {/* 
                  <Col xl={2} lg={3} md={4} className="oemErrorCodeList-search-boxButton">
                    <div className="upload-file-block">
                      <input onChange={upload} type="file" id="file" className="upload-file--input" />
                      <label className="upload-file-inner" htmlFor="file">
                        <div className="import-btn__block">
                          <div className="import_btn">
                            <span className="rfid-upload-file-box-icon">
                              <BsUpload />
                            </span>
                            <span className="rfid-upload-file-box-text">{t('rfidEditList.importData')}</span>
                          </div>
                        </div>
                      </label>
                    </div>
                  </Col> */}

                </Row>
                <div className="oemErrorCodeList-table">
                  <div className="table-responsive">
                    <table className="record-list-table">
                      <thead>
                        <tr>
                          <th>
                            <div className="sorting">
                              <span>{t('oemErrorCodeForm.vendorCode')}</span>
                              <span className="ico" onClick={() => handleSorting('code', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>{t('oemErrorCodeForm.title')}</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>{t('oemErrorCodeForm.vendorName')}</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>{t('oemErrorCodeForm.chargerSpotName')}</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>{t('oemErrorCodeForm.chargerStateName')}</span>
                            </div>
                          </th>
                          <th className="action-col">
                            <span>{t('oemErrorCodeForm.action')}</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoader ? (
                          <tr>
                            <td colSpan={10} className="border-0">
                              <div className="loader--block">
                                <Spinner as="span" animation="border" size="sm" role="status" />
                                <span className="ps-2">{t('placeHolder.loading')}</span>
                              </div>
                            </td>
                          </tr>
                        ) : _.isEmpty(oemErrorCodeList) ? (
                          <tr>
                            <td colSpan={3} className="border-0">
                              <div className="empty-data-block">{t('oemErrorCodeForm.noData')}</div>
                            </td>
                          </tr>
                        ) : (
                          !(isLoader || _.isUndefined(isLoader)) &&
                          _.map(oemErrorCodeList, (item, key) => {
                            return (
                              <>
                                <tr key={`oemErrorCode-${key}`}>
                                  <td>{_.get(item, 'code', '')}</td>
                                  <td>{_.get(item, 'errorCode', '')}</td>
                                  <td>{_.get(item, 'vendor.name', '')}</td>
                                  <td>{_.get(item, 'oem.name', '-')}</td>
                                  <td>{_.get(item, 'state.name', '')}</td>
                                  <td>
                                    <span
                                      className="oemErrorCodeList-table-editIcon"
                                      onClick={() => {
                                        setShowForm(true);
                                        getOemErrorCodeDetail(_.get(item, 'id', ''));
                                        setIsEdit(true);
                                      }}
                                    >
                                      <FiEdit2 title="Edit" />
                                    </span>
                                    <span
                                      className="oemErrorCodeList-table-deleteIcon"
                                      onClick={() => {
                                        setShowDeleteModal(true);
                                        setOemErrorCodeId(_.get(item, 'id', ''));
                                      }}
                                    >
                                      <RiDeleteBinLine title="Delete" />
                                    </span>
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(oemErrorCodeList) && (
                  <ReactPagination
                    currentPage={page}
                    limit={limit}
                    total={totalData}
                    handlePageClick={(pageVal) => handlePageClick(pageVal)}
                    totalPages={totalPages}
                    marginPagesDisplayed={1}
                  />
                  // <Pagination currentPage={page} limit={limit} total={totalData} onPageChange={(pageVal) => handlePageClick(pageVal)} />
                )}
              </div>
            </Card>
          </div>
        </div>
        {showForm && (
          <Modal show={showForm} size="lg" centered>
            <OemErrorCodeForm onClose={handleCloseFormOemErrorCode} onSuccess={getOemErrorCode} isEdit={isEdit} />
          </Modal>
        )}
        {bulkForm && (
          <Modal show={bulkForm} size="lg" centered>
            <OemBulkErrorCodeForm onClose={handleCloseFormOemErrorCode} onSuccess={getOemErrorCode} isEdit={bulkEdit} />
          </Modal>
        )}
        {showDeleteModal && (
          <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
            <DeleteModal title="Error Code" onClose={handleCloseDeleteModel} onRemove={deleteOemErrorCode} />
          </Modal>
        )}
      </LayoutContainer>
    </>
  );
};
export default OemErrorCodeList;