import React, { useCallback, useContext } from 'react';
import { Formik, Form } from 'formik';
import { Row, Col } from 'react-bootstrap';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import * as _ from 'lodash';
import { toast } from 'react-toastify';
import { CHANGE_PASSWORD } from 'actions/authAction';
import { cookie } from 'utils/cookies/cookies';
import { useTranslation } from 'react-i18next';
import PasswordStrengthBar from 'components/inputs/PasswordStrengthBar';
import { NavContext } from 'components/privateRoute/PrivateRoute';
import { GET_CHANGE_PASSWORD, MANAGE_CHANGE_PASSWORD } from 'components/common/constant';

const ChangePassword = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { navigateTo } = useContext(NavContext);

  const rolePermissionData = useSelector((state) => state.manageRole.manageRoles);

  const resetPassword = useCallback((data) => {
    dispatch({
      type: CHANGE_PASSWORD,
      payload: data,
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            toast.success('Password changed successfully');
            cookie.clean();
            setTimeout(() => {
              navigateTo('/login');
            }, 500);
          }
        }
      },
    });
  }, []);

  const getChangePasswordPermissions =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === GET_CHANGE_PASSWORD),
      (data) => data.name
    );

  const manageChangePasswordPermissions =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === MANAGE_CHANGE_PASSWORD),
      (data) => data.name
    );

  const ChangePasswordSchema = Yup.object().shape({
    old_password: Yup.string().required(t('changePassword.oldPassword')).trim(t('changePassword.space')).strict(true),
    new_password: Yup.string()
      .required(t('changePassword.newPassword'))
      .trim(t('changePassword.space'))
      .strict(true)
      .min(8, t('changePassword.passlenchk')),
    confirm_password: Yup.string()
      .required(t('changePassword.confirmPassword'))
      .strict(true)
      .oneOf([Yup.ref('new_password'), null], t('changePassword.passCheck'))
      .trim(t('changePassword.space'))
      .min(8, t('changePassword.passlenchk')),
  });

  return (
    <React.Fragment>
      <div className="change-password-form_main">
        <div className="change-password-form_block">
          <Formik
            initialValues={{
              old_password: '',
              new_password: '',
              confirm_password: '',
            }}
            validationSchema={ChangePasswordSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              resetPassword(values, resetForm);
              setSubmitting(false);
            }}
          >
            {({ values, errors, touched, handleSubmit, handleChange, setFieldValue, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <div className="change-password-form__inner">
                  <Row>
                    <Col lg={6} md={12}>
                      <TextInput
                        isRequired
                        label={t('changePassword.oldLabel')}
                        type="password"
                        value={values.old_password}
                        name="old_password"
                        onChange={handleChange}
                        error={errors.old_password && touched.old_password ? errors.old_password : null}
                      />
                    </Col>
                    <Col lg={6} md={12} className="password_div">
                      <TextInput
                        isRequired
                        label={t('changePassword.newLabel')}
                        type="password"
                        name="new_password"
                        value={values.new_password}
                        onChange={(e) => setFieldValue('new_password', e.target.value)}
                        error={errors.new_password && touched.new_password ? errors.new_password : null}
                      />
                      {values.new_password.length > 0 && <PasswordStrengthBar password={values.new_password} />}
                    </Col>
                    <Col lg={6} md={12} className="password_div">
                      <TextInput
                        isRequired
                        label={t('changePassword.confirmLabel')}
                        type="password"
                        name="confirm_password"
                        value={values.confirm_password}
                        onChange={(e) => setFieldValue('confirm_password', e.target.value)}
                        error={errors.confirm_password && touched.confirm_password ? errors.confirm_password : null}
                      />
                      {values.confirm_password.length > 0 && <PasswordStrengthBar password={values.confirm_password} />}
                    </Col>
                  </Row>
                </div>
                <div className="change-password-save--btn--block">
                  {!_.isEmpty(manageChangePasswordPermissions) ||
                  (!_.isEmpty(getChangePasswordPermissions) && !_.isEmpty(manageChangePasswordPermissions)) ? (
                    <Button type="submit" className="save--btn" disabled={isSubmitting}>
                      {t('button.saveChanges')}
                    </Button>
                  ) : (
                    <Button className="save--btn">{t('button.saveChanges')}</Button>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </React.Fragment>
  );
};
export default ChangePassword;
